import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMonthlyReportsStore } from "../../../../Store/ReportsStore/useMonthlyReports";
import Selector from "./Select";

function Heading() {
  const { branches } = useMonthlyReportsStore();
  const { t } = useTranslation();

  const optionsBranch = useMemo(() => {
    return (
      branches?.map((element) => ({
        label: element.title,
        value: element.id,
      })) || []
    );
  }, [branches]);

  const options = [
    { label: "2023-yil", value: 2023 },
    { label: "2024-yil", value: 2024 },
  ];

  return (
    <div
      style={{
        marginBottom: "10px",
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        width: "fit-content",
        gap: "20px",
      }}
    >
      <Selector options={options} property={t("Yillar")} />
      <Selector options={optionsBranch} property={t("Filiallar")} />
    </div>
  );
}

export default Heading;
