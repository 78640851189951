import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useStudentEarnedCardStore } from "../../../../Store/ReportsStore/StudentEarnedCardsStore";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "37px",
    minHeight: "37px",
    width: "200px",
    fontSize: "13px",
    fontFamily: "Inter",
    fontWeight: "400",
  }),
};

export default function Selector({ options, property }) {
  const { updateParams, loadItems, queryParams, setLoading } =
    useStudentEarnedCardStore();
  const { t } = useTranslation();

  const handleSelectChange = useCallback(async (e) => {
    setLoading(true);
    if (property == t("Kardlar")) {
      updateParams({
        card: e?.value == undefined ? "" : e?.value,
        page: 1,
      });
      await loadItems(
        new URLSearchParams({
          ...queryParams,
          card: e?.value == undefined ? "" : e?.value,
          page: 1,
        }).toString()
      );
    }
    if (property == t("Filiallar")) {
      updateParams({
        branch: e?.value == undefined ? "" : e?.value,
        page: 1,
      });
      await loadItems(
        new URLSearchParams({
          ...queryParams,
          branch: e?.value == undefined ? "" : e?.value,
          page: 1,
        }).toString()
      );
    }
    setLoading(false);
  });

  return (
    <div className="normal-select-bar">
      <Select
        className="basic-single manage_group_select"
        classNamePrefix="select"
        placeholder={property}
        styles={customStyles}
        options={options}
        isClearable
        onChange={handleSelectChange}
      />
    </div>
  );
}
