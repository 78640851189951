import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useRefundStore } from "../../../../../Store/CashControlStore/RefundStore";

export default function ColorToggleButton() {
  const { payment_types, updateSalary, salary } = useRefundStore();

  const handleChange = (event, newAlignment) => {
    updateSalary({ payment_type: newAlignment });
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={salary?.payment_type}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      size="small"
    >
      {payment_types?.map((element) => {
        return (
          <ToggleButton key={element.value} value={element.value}>
            {element.name}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}
