import React, { useState, useCallback } from "react";
import PrintIcon from "@mui/icons-material/Print";
import ReactLoading from "react-loading";

const PrintPDF = ({ paymentId }) => {
  const [loader, setLoader] = useState(false);

  const handlePrint = useCallback(async () => {
    setLoader(true);
    try {
      const accessToken = JSON.parse(localStorage.getItem("token")).access;
      const response = await fetch(
        `https://admin.smartportal.uz/api/finance/payments/cheque/?transaction_id=${paymentId}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      const data = await response.blob();
      const blobUrl = URL.createObjectURL(data);
      const printFrame = document.getElementById("printFrame");
      printFrame.src = blobUrl;
      printFrame.onload = function () {
        printFrame.contentWindow.print();
      };
    } catch (error) {
      console.error("Print error:", error);
    }
    setLoader(false);
  }, [paymentId]);

  return (
    <>
      <iframe
        id="printFrame"
        style={{ display: "none", padding: "0" }}
      ></iframe>
      <button
        type="button"
        onClick={handlePrint}
        disabled={loader || !paymentId}
        className={`cancelBtn ${!paymentId ? "print__check--disabled" : ""}`}
      >
        {loader ? (
          <ReactLoading
            type={"spin"}
            color="#ffffff"
            className="delete_loader"
            height={"20px"}
            width={"20px"}
          />
        ) : (
          <>
            <PrintIcon sx={{ fontSize: "18px", marginX: "5px" }} />
            Сhop etish
          </>
        )}
      </button>
    </>
  );
};

export default React.memo(PrintPDF);

// setLoader(true);
//  const data = await render(
//   <Printer type="XP-58">
//     <Text>Hello World</Text>
//   </Printer>
// );

// const port = await window.navigator.serial.requestPort();
// await port.open({ baudRate: 9600 });

// const writer = port.writable?.getWriter();
// if (writer != null) {
//   await writer.write(data);
//   writer.releaseLock();
// }

// const blob = new Blob([pdfData], { type: "application/pdf" });
// const fileURL = URL.createObjectURL(blob);
// const printWindow = window.open(fileURL);
// printWindow.onload = async () => {
//   printWindow.print();
//   URL.revokeObjectURL(fileURL);
//   useIncomeCashStore.setState({
//     acceptPayment: {
//       ...acceptPayment,
//       group: null,
//       amountPlaceholder: null,
//       amount: null,
//       payment_type: null,
//       discounts: [],
//     },
//     paymentId: null,
//   });
//   setLoading(true);
//   await getListItemDetail(acceptPayment.student);
//   setLoading(false);
// };

// const printWindow = window.open("", "_blank");
// printWindow.document.write(
//   "<html><head><title>Print</title></head><body>"
// );
// printWindow.document.write(
//   `<embed src="${pdfData}" type="application/pdf" width="100%" height="100%"/>`
// );
// printWindow.document.write("</body></html>");
// printWindow.document.close();
// printWindow.onload = () => {
//   printWindow.print();
//   printWindow.close();
// };
// try {
//   const response = await IncomeCashRepository.getCheque(
//     new URLSearchParams({ transaction_id: paymentId }).toString()
//   );
//   const pdfData = response.data;
//   const pdfBlob = new Blob([pdfData], { type: "application/json" });
//   const printFrame = document.getElementById("printFrame");
//   const pdfUrl = URL.createObjectURL(pdfBlob);
//   printFrame.src = pdfUrl;
//   printFrame.onload = function () {
//     printFrame.contentWindow.print();
//   };
// } catch (error) {
//   console.error("Failed to print PDF:", error);
// }
