import React from "react";
import { useTranslation } from "react-i18next";
import { useSalaryAdvanceStore } from "../../../../../Store/CashControlStore/SalaryAdvanceStore";

export default function PaymentHistory() {
  const { data } = useSalaryAdvanceStore();
  const { t } = useTranslation();

  return (
    <div className="cash-discount">
      <div className="cash-disount__list">
        <div className="cash-disount__item">
          <ul className="cash-disount__details">
            <li className="cash-disount__details-item history-tablehead number--payment">
              №
            </li>
            <li className="cash-disount__details-item history-tablehead">
              {t("To'lov turi")}
            </li>
            <li className="cash-disount__details-item history-tablehead">
              {t("Qiymati")}
            </li>
            <li className="cash-disount__details-item history-tablehead">
              {t("Kassir")}
            </li>
            <li className="cash-disount__details-item history-tablehead">
              {t("To'lov qilingan sana")}
            </li>
          </ul>
        </div>
        {data?.history?.map((item, index) => {
          return (
            <div key={item.id} className="cash-disount__item">
              <ul className="cash-disount__details">
                <li className="cash-disount__details-item number--payment">
                  {index + 1}
                </li>
                <li className="cash-disount__details-item">
                  {item.payment_type}
                </li>
                <li className="cash-disount__details-item">
                  {item.amount} so'm
                </li>
                <li className="cash-disount__details-item">{item.cashier}</li>
                <li className="cash-disount__details-item">{item.date}</li>
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
}
