import { useTranslation } from "react-i18next";
import { useDailyReportsStore } from "../../../../Store/ReportsStore/DaylyReportsStore";

function ExtraTable() {
  const { results } = useDailyReportsStore();
  const { t } = useTranslation();

  return (
    <table border={"1px"} className="reports-table">
      <thead>
        <tr className="reports-extra-table-head">
          <th>№</th>
          <th>{t("To'lov nomlari")}</th>
          <th>{t("Summa / Foiz")}</th>
        </tr>
      </thead>
      <tbody>
        <tr className="reports-extra-table-body">
          <td>1</td>
          <td style={{ textAlign: "start" }}>{t("To'lovlar")}</td>
          <td>{results?.payments_in} so'm</td>
        </tr>
        <tr className="reports-extra-table-body">
          <td>2</td>
          <td style={{ textAlign: "start" }}>
            {t("O'quvchilarga qaytarilgan pullar")}
          </td>
          <td>{results?.student_payments_out} so'm</td>
        </tr>
        <tr className="reports-extra-table-body">
          <td>3</td>
          <td style={{ textAlign: "start" }}>{t("Xarajatlar")}</td>
          <td>{results?.expenditures} so'm</td>
        </tr>
        <tr className="reports-extra-table-body">
          <td>4</td>
          <td style={{ textAlign: "start" }}>{t("Ish haqi uchun")}</td>
          <td>{results?.salary} so'm</td>
        </tr>
        <tr className="reports-extra-table-body">
          <td>5</td>
          <td style={{ textAlign: "start" }}>{t("Jami xarajatlar")}</td>
          <td>{results?.total_expenditures} so'm</td>
        </tr>
        <tr className="reports-extra-table-body">
          <td>6</td>
          <td style={{ textAlign: "start" }}>{t("Jami xarajatlar foizi")}</td>
          <td>{results?.total_expenditures_percent} %</td>
        </tr>
        <tr className="reports-extra-table-body">
          <td>7</td>
          <td style={{ textAlign: "start" }}>{t("Jami foyda")}</td>
          <td>{results?.profit} so'm</td>
        </tr>
        <tr className="reports-extra-table-body">
          <td>8</td>
          <td style={{ textAlign: "start" }}>{t("Jami foyda foizi")}</td>
          <td>{results?.profit_percent} %</td>
        </tr>
      </tbody>
    </table>
  );
}

export default ExtraTable;
