import { create } from "zustand";
import { toastError } from "../../Helpers/toasts";
import MainGroupsRepository from "../../Repositories/Groups/MainGroupsRepository";
import MonthlyReportRepository from "../../Repositories/Reports/MonthlyReportsRepository";

export const useMonthlyReportsStore = create((set) => ({
  results: null,
  total: null,
  last_year: null,
  branches: null,
  reasoning: null,
  isLoading: false,
  queryParams: {
    page: 1,
  },

  setLoading: (status) => {
    set({
      isLoading: status,
    });
  },

  updateParams: (paramItem) => {
    set((prevState) => ({
      queryParams: { ...prevState.queryParams, ...paramItem },
    }));
  },
  getBranches: async () => {
    const { data, status } = await MainGroupsRepository.getAllBranches()
    if (status) {
      set({
        branches: data,
      })
    }
  },

  onReload: async (queryString) => {
    const { data, status, nonFieldErrors } =
      await MonthlyReportRepository.studentsList(queryString);
    if (status) {
      set({
        results: data.months,
        total: data.total,
        last_year: data.last_year,
        queryParams: { page: 1 },
      });
    } else toastError(nonFieldErrors);
  },

  loadItems: async (queryString) => {
    const { data, status, nonFieldErrors } =
      await MonthlyReportRepository.studentsList(queryString);
    if (status) {
      set({
        results: data.months,
        total: data.total,
        last_year: data.last_year,
      });
    } else toastError(nonFieldErrors);
  },
}));
