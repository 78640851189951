import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BranchesContext } from "../../../../Contexts/ConfigContext/BranchesContext";
import { dateFormat } from "../../../../Helpers/dateFormat";
import UserPermissionWidget from "../../../../UserPermissionWidget";
import {
  deleteConfirmAction,
  openListTable,
  toggleContextMenu,
  updateAction,
} from "../actions";
import ListItemTable from "./modal/ListItemTable";

export default function ListItem({ branch, index }) {
  const { branches, dispatchBranches } = useContext(BranchesContext);
  const user = JSON.parse(localStorage.getItem("user"));
  const { t } = useTranslation();

  return (
    <div
      className={
        branches.listId === branch
          ? "lesson_item_full build-table--light lesson_item_active"
          : "lesson_item_full build-table--light"
      }
      onClick={() => openListTable(branches, branch, dispatchBranches)}
    >
      <ul className="building build-table build-table--light build-table__item lesson_ul">
        <li className="build-table__item build-table__item--1">{index + 1}</li>
        <li className="build-table__item build-table__item--2">
          {branch.title}
        </li>
        <li className="build-table__item build-table__item--3">
          {branch.address}
        </li>
        <li className="build-table__item build-table__item--4">
          {branch.available_rooms}
        </li>
        <li className="build-table__item build-table__item--5">
          {branch.short_title}
        </li>
        <li className="build-table__item build-table__item--6">
          {dateFormat(branch.created_at)}
        </li>
        <li
          className="build-table__item build-table__item--7"
          onClick={(e) =>
            toggleContextMenu(branch, dispatchBranches, e, branches)
          }
        >
          <ul
            className={
              branches.openContextMenuId === branch.id &&
              (user?.permissions?.update_branch ||
                user?.permissions?.delete_branch)
                ? "build-table__dropdown build-table__dropdown--active"
                : "build-table__dropdown"
            }
          >
            <UserPermissionWidget
              hasPermission={user?.permissions?.update_branch}
            >
              <li
                className="build-table__dropdown-item btn-edit"
                onClick={() => updateAction(branch, dispatchBranches)}
              >
                <button className="modalBtn">
                  <img src="/images/dropdown-edit.svg" alt="dropdown icon" />
                  {t("edit")}
                </button>
              </li>
            </UserPermissionWidget>
            <UserPermissionWidget
              hasPermission={user?.permissions?.delete_branch}
            >
              <li
                className="build-table__dropdown-item"
                onClick={() => deleteConfirmAction(branch, dispatchBranches)}
              >
                <img src="/images/dropdown-delete.svg" alt="dropdown icon" />
                {t("delete")}
              </li>
            </UserPermissionWidget>
          </ul>
        </li>
      </ul>
      <div
        className={
          branches.listId === branch
            ? "table_lesson table_branch table_lesson_active"
            : "table_lesson table_branch"
        }
      >
        <ListItemTable rooms={branch.rooms} />
      </div>
    </div>
  );
}
