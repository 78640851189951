import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AdminLayout from "../../../AdminLayout";
import TitleBar from "../../../Components/TitleBar";
import { useStudentTransferStore } from "../../../Store/GroupManagement/StudentTransferStore";
import CurrentGroupSection from "./CurrentGroupSection/CurrentGroupSection";
import TransferingGroupSection from "./TransferingGroupSection/TransferingGroupSection";

export default function StudentsTransfer() {
  useEffect(() => {
    useStudentTransferStore.setState({
      studentDetail: null,
      groupDetail: null,
    });
  }, []);
  const { t } = useTranslation();
  return (
    <AdminLayout>
      <TitleBar
        title={t("O‘quvchini boshqa guruhga olib o'tish")}
        createAction={false}
      />
      <div className="container">
        <div className="transfer">
          <CurrentGroupSection />
          <TransferingGroupSection />
        </div>
      </div>
    </AdminLayout>
  );
}
