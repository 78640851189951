import React, { useContext } from "react";
import MainGroupsActions from "../../../../Actions/GroupsActions/MainGroupsActions";
import { MainGroupsContext } from "../../../../Contexts/GroupsContext/MainGroupsContext";
import { loadItems } from "../actions";

export default function ActiveGroupButtons() {
  const { mainGroups, dispatchMainGroups } = useContext(MainGroupsContext);

  const lessonStyleHandler = async (element) => {
    dispatchMainGroups({
      type: MainGroupsActions.ADD_QUERY_PARAM,
      payload: {
        lesson_style:
          mainGroups?.queryParams?.lesson_style === element.value
            ? ""
            : element.value,
        page: 1,
      },
    });

    await loadItems(
      {
        lesson_style:
          mainGroups?.queryParams?.lesson_style === element.value
            ? ""
            : element.value,
        page: 1,
      },
      mainGroups,
      dispatchMainGroups
    );
  };

  return (
    <div className="lesson-type">
      {mainGroups.lesson_styles?.map((element) => {
        return (
          <div
            key={element.value}
            onClick={() => lessonStyleHandler(element)}
            className={`lesson-type__item ${
              mainGroups?.queryParams?.lesson_style == element.value &&
              "lesson-type__item_active"
            }`}
          >
            {element.verbose_name}
          </div>
        );
      })}
    </div>
  );
}
