import React from "react";
import { useTranslation } from "react-i18next";
import BlockItem from "../../Components/BlockItem";
import UserPermissionWidget from "../../UserPermissionWidget";

function RegistrationModule({ viewModule }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const { t } = useTranslation();

  return (
    <div className={`home-cards-box ${!viewModule ? "d-none" : ""}`}>
      <h1 className="cards__title">
        <img className="cards__img" src="images/cards-2.png" alt="card box" />
        {t("home_registration")}
      </h1>
      <section className="section-page config">
        <UserPermissionWidget hasPermission={user?.permissions?.view_staffs}>
          <BlockItem 
            link="/registration/employee-registration"
            title={t("home_teachers")}
            image="images/group-1.png"
          />
        </UserPermissionWidget>
        <UserPermissionWidget hasPermission={user?.permissions?.view_students}>
          <BlockItem
            link="/registration/student-registration"
            title={t("home_students")}
            image="/images/group-3.png"
          />
        </UserPermissionWidget>
      </section>
    </div>
  );
}

export default RegistrationModule;
