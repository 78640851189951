import { useFormik } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Select from "react-select";
import * as Yup from "yup";
import EmployeeRegistrationAction from "../../../../Actions/RegistrationActions/EmployeeRegistrationAction";
import { EmployeeRegistrationContext } from "../../../../Contexts/RegistrationContex/EmployeeRegistrationContext";
import CloudDownloadRoundedIcon from "@mui/icons-material/CloudDownloadRounded";
import DownloadFileComponent from "./DownloadFileComponent";

const FORMIK_RULES = {
  first_name: Yup.string()
    .min(0, "Xodimni ismini kiriting!")
    .max(50, "Belgilar soni ortiqcha!")
    .required("Xodimni ismini kiriting!"),
  last_name: Yup.string()
    .min(0, "Xodimni familiyasini kiriting!")
    .max(50, "Belgilar soni ortiqcha!")
    .required("Xodimni familiyasini kiriting!"),
  middle_name: Yup.string()
    .min(0, "Xodimni otasini ismi kiriting!")
    .max(50, "Belgilar soni ortiqcha!"),
  gender: Yup.number()
    .min(0, "Xodim jinsini tanlang!")
    .required("Xodim jinsini tanlang!"),
  birth_date: Yup.string()
    .min(0, "Tug'ilgan sanasini kiriting !")
    .required("Tug'ilgan sanasini kiriting !"),
  document_type: Yup.string().min(0, "Hujjat turini tanlang!"),
  passport_series: Yup.string().max(20, "Belgilar soni ortiqcha!"),
  passport_number: Yup.string()
    .min(0, "Passport raqamini kiriting!")
    .max(7, "Belgilar soni ortiqcha!"),
  working_places: Yup.array().min(1, "Bino turini tanlang!"),
  username: Yup.string()
    .min(0, "Xodim uchun login kiriting!")
    .required("Xodim uchun login kiriting!"),
  contract_number: Yup.string().min(
    0,
    "Buyuruq va mehnat shartnomasi kiritilsin!"
  ),
  password: Yup.string()
    .min(8, "Kamida 8 ta belgidan iborat parol kiriting!")
    .required("Xodim uchun parol kiriting!"),
};

export default function StaffInfo({ branch, position }) {
  const { employee, dispatchEmployee } = useContext(
    EmployeeRegistrationContext
  );
  const [formikRules, setFormikRules] = useState(FORMIK_RULES);
  const [defaultFileValue, setDefaultFilesValue] = useState([]);

  useEffect(() => {
    formik.resetForm();
  }, [employee.isOpenInfo]);

  useEffect(() => {
    if (employee.item === null) {
      setFormikRules(FORMIK_RULES);
    } else {
      setFormikRules({ ...FORMIK_RULES, username: null, password: null });
      for (const [key, value] of Object.entries(employee.item ?? {})) {
        switch (key) {
          case "working_places_detailed":
            const setBranchId = value.map((element) =>
              branch.find((item) => item?.value === element.id)
            );
            formik.setFieldValue("working_places", setBranchId, false);
            break;
          case "roles":
            const setPosition = value.map((element) =>
              position.find((item) => item?.value === element.id)
            );
            formik.setFieldValue("roles", setPosition, true);
            break;
          case "documents":
            const setDefaultFiles = value.map((element) => element?.id);
            formik.setFieldValue("documents", setDefaultFiles, true);
            setDefaultFilesValue([
              ...value.map((element) => ({
                name: element,
              })),
            ]);
            break;
          case "password":
            formik.setFieldValue(key, null, false);
            break;
          default:
            formik.setFieldValue(key, value ?? "", false);
        }
      }
    }
  }, [employee.item]);

  const initialValues = {
    first_name: "",
    last_name: "",
    middle_name: "",
    gender: "",
    birth_date: "",
    document_type: "",
    passport_series: "",
    passport_number: "",
    working_places: [],
    username: "",
    password: "",
    roles: [],
    contract_number: "",
    documents: [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object(formikRules),
  });

  const cancelAction = useCallback(() => {
    formik.resetForm();
    dispatchEmployee({
      type: EmployeeRegistrationAction.CANCEL_BUTTON_CLICKED,
    });
  });

  return (
    <div
      id="modal"
      className={
        employee.isOpenInfo ? "modal modal-select active" : "modal modal-select"
      }
    >
      <form
        onClick={(e) => e.stopPropagation()}
        onSubmit={formik.handleSubmit}
        className="modal-wrapper modal-wrapper_huge"
      >
        <div className="modal-inner">
          <h4 className="modal__title">Xodim ma'lumotlari</h4>
          <div className="modal-select__item modal-three">
            <div className="modal-select__box">
              <label htmlFor="first_name">Ismi</label>
              <input
                readOnly
                id="first_name"
                className="modal-input noneFocus"
                value={formik.values.first_name || ""}
                type="text"
                name="first_name"
              />
            </div>
            <div className="modal-select__box">
              <label htmlFor="birth_date">Tug’ilgan sanasi</label>
              <input
                readOnly
                id="birth_date"
                className="modal-input noneFocus"
                type="date"
                name="birth_date"
                value={formik.values.birth_date || ""}
              />
            </div>
            <div className="modal-select__box ">
              <label>Shaxsni tasdiqlovchi hujjat turi</label>
              <div className={"radio checkBox_style "}>
                <div className="student_modal_checkbox">
                  <input
                    readOnly
                    type="radio"
                    name="document_type"
                    id="pass"
                    value={1}
                    checked={formik.values.document_type == 1}
                  />
                  <label htmlFor="pass">Passport</label>
                </div>
                <div className="student_modal_checkbox">
                  <input
                    readOnly
                    type="radio"
                    name="document_type"
                    id="id"
                    value={2}
                    checked={formik.values.document_type == 2}
                  />
                  <label htmlFor="id">ID-Card</label>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-select__item modal-three">
            <div className="modal-select__box">
              <label htmlFor="surname">Familyasi</label>
              <input
                readOnly
                id="surname"
                className="modal-input noneFocus"
                value={formik.values.last_name || ""}
                type="text"
                name="last_name"
              />
            </div>
            <div className="modal-select__box">
              <label htmlFor="gender">Jinsi</label>
              <select
                name="gender"
                id="gender"
                value={formik.values.gender || ""}
                className="modal-input noneFocus"
              >
                <option
                  className="course_disabled_option"
                  disabled
                  hidden
                  value={""}
                ></option>
                <option value="1">Erkak</option>
                <option value="2">Ayol</option>
              </select>
            </div>
            <div className="modal-select__box">
              <label htmlFor="passport">Passport seriyasi va raqami</label>
              <div className="modal-passport">
                <div className="passport_error modal-select__box">
                  <input
                    readOnly
                    id="passport"
                    name="passport_series"
                    type="text"
                    className="modal-input noneFocus"
                    value={formik.values.passport_series || ""}
                  />
                </div>
                <div className="passport_error modal-select__box">
                  <input
                    readOnly
                    id="passport"
                    name="passport_number"
                    type="text"
                    className="modal-input noneFocus"
                    value={formik.values.passport_number || ""}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-select__item modal-three">
            <div className="modal-select__box">
              <label htmlFor="middle_name">Otasining ismi</label>
              <input
                readOnly
                id="middle_name"
                className="modal-input noneFocus"
                value={formik.values.middle_name || ""}
                type="text"
                name="middle_name"
              />
            </div>
            <div className="modal-select__box">
              <label htmlFor="contract_number">
                Buyuruq va mehnat shartnomasi
              </label>
              <input
                readOnly
                name="contract_number"
                id="contract_number"
                className="modal-input noneFocus"
                value={formik.values.contract_number || ""}
                type="text"
              />
            </div>
            <div
              className={employee.editingId ? "d-none" : "modal-select__box"}
            >
              <label htmlFor="login">Login</label>
              <input
                readOnly
                name="username"
                id="login"
                className="modal-input noneFocus"
                value={formik.values.username || ""}
                type="text"
              />
            </div>
          </div>
          <div className="modal-select__item modal-three">
            <div className="modal-select__box">
              <label htmlFor="workplace">Ish faoliyat joyi</label>
              <Select
                id="vakolat"
                className="modal-input multiselect"
                closeMenuOnSelect={false}
                isMulti
                options={branch}
                name="working_places"
                value={formik.values.working_places}
              />
            </div>
            <div className="modal-select__box">
              <label htmlFor="roles">Xodimning lavozimi</label>
              <Select
                id="vakolat"
                className="modal-input multiselect"
                closeMenuOnSelect={false}
                isMulti
                options={position}
                name="roles"
                value={formik.values.roles}
              />
            </div>
            <div
              className={employee.editingId ? "d-none" : "modal-select__box"}
            >
              <label htmlFor="password">Parol</label>
              <div className="password-wrapper">
                <input
                  readOnly
                  name="password"
                  id="password"
                  className="modal-input noneFocus"
                  value={formik.values.password || ""}
                  type="text"
                />
                <div className="password-icon"></div>
              </div>
            </div>
          </div>
          <DownloadFileComponent defaultFileValue={defaultFileValue} />
        </div>
        <div className="input-buttons">
          <button
            onClick={() => cancelAction()}
            id="modalClose"
            type="button"
            className="input-btn"
          >
            Bekor qilish
          </button>
        </div>
      </form>
    </div>
  );
}
