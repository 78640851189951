import React from "react";

export default function ListItem({ item, index }) {
  return (
    <div className="list--config">
      <ul className="attendance-table build-table build-table--bold build-table--light build-table__item">
        <li className="build-table__item">{index + 1}</li>
        <li className="build-table__item">{item.fullname}</li>
        <li className="build-table__item">{item.student_id}</li>
        <li className="build-table__item">
          {item.age_group} {item.group_number}
        </li>
        <li className="build-table__item">{item.subject}</li>
        <li className="build-table__item">{item.teachers}</li>
        <li className="build-table__item">{item.reason}</li>
        <li className="build-table__item">{item.comment}</li>
        <li className="build-table__item">{item.date}</li>
      </ul>
    </div>
  );
}
