import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Box } from "@mui/material";
import { useProfileConfigStore } from "../../../../Store/ProfileConfiguration/ProfileConfigStore";
import { useMemo } from "react";
import { useCallback } from "react";
import Select from "./Select";
import Selector from "./Select";

const headerContainer = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
  gap: "0px 20px",
};

export default function FilterSelects() {
  const { subjects, lesson_styles, age_groups } = useProfileConfigStore();

  const subjectOption = useMemo(() => {
    return (
      subjects?.map((element) => ({
        label: element.name,
        value: element.id,
      })) || []
    );
  }, [subjects]);
  const ageGroupOption = useMemo(() => {
    return (
      age_groups?.map((element) => ({
        label: element.name,
        value: element.value,
      })) || []
    );
  }, [age_groups]);
  const lessonStyleOption = useMemo(() => {
    return (
      lesson_styles?.map((element) => ({
        label: element.name,
        value: element.value,
      })) || []
    );
  }, [lesson_styles]);

  return (
    <Box sx={headerContainer}>
      <Selector options={subjectOption} property="Fanlar" />
      <Selector options={ageGroupOption} property="Guruh turi" />
      <Selector options={lessonStyleOption} property="Dars uslubi" />
    </Box>
  );
}
