import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
export default function ConfirmationModal({
  confirmationCallback,
  cancelCallback,
  showModal,
  title,
}) {
  const [loader, setLoader] = useState(false);

  const cancelBtnClicked = () => {
    cancelCallback();
    setLoader(false);
  };
  const handleSubmit = async () => {
    setLoader(true);
    await confirmationCallback();
    setLoader(false);
  };
  const { t } = useTranslation();

  return (
    <div
      id="modal"
      className={showModal ? "modal modal-select active" : "modal modal-select"}
    >
      <div className="modal-wrapper confirmation--modal">
        <div className="modal-inner blue_border">
          <h4 className="modal__title blue_background">{t("Tasdiqlash")}</h4>
          <h4 className="confirmation__title">{title}</h4>
        </div>
        <div className="input-buttons">
          <button
            id="modalClose"
            type="button"
            className="input-btn"
            onClick={() => cancelBtnClicked()}
          >
            {t("Bekor qilish")}
          </button>
          <button
            className="input-btn input-btn--submit blue_background"
            type="submit"
            disabled={loader}
            onClick={() => handleSubmit()}
          >
            {loader ? (
              <ReactLoading
                type={"spin"}
                color="#ffffff"
                className="delete_loader"
                height={"30px"}
                width={"30px"}
              />
            ) : (
              t("Ha,tasdiqlayman")
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
