import ArchivedGroupsActions from "../../../Actions/GroupsActions/ArchivedGroupsActions";
import ArchivedGroupsRepository from "../../../Repositories/Groups/ArchivedGroupsRepository";
import { toastError, toastSuccess } from "../../../Helpers/toasts";

export const onReload = async (context, dispatch) => {
  const subjectsData = await ArchivedGroupsRepository.getSubjects();
  const lessonTypesData = await ArchivedGroupsRepository.getLessonTypes();
  const lessonStylesData = await ArchivedGroupsRepository.getLessonStyles();
  const groupTypesData = await ArchivedGroupsRepository.getGroupTypes();

  const pageSize = localStorage.getItem("pageSize") ?? 10;
  const mainGroupsDate = await ArchivedGroupsRepository.getLessonHours(
    new URLSearchParams({
      ...context.queryParams,
      pageSize: pageSize,
    }).toString()
  );

  dispatch({
    type: ArchivedGroupsActions.PAGE_LOADED,
    payload: {},
  });
};

export const loadItems = async (payload, context, dispatch) => {
  const response = await ArchivedGroupsRepository.getMonthlyPayments(
    new URLSearchParams({ ...context.queryParams, ...payload }).toString()
  );
  dispatch({
    type: ArchivedGroupsActions.PAGE_LOADED,
    payload: {
      data: response.data,
    },
  });
};

export const createAction = (dispatch) => {
  dispatch({
    type: ArchivedGroupsActions.CREATE_BUTTON_CLICKED,
  });
};
export const toggleContextMenu = (group, dispatch) => {
  dispatch({
    type: ArchivedGroupsActions.TOGGLE_CONTEXT_MENU,
    payload: { id: group.id },
  });
};
export const deleteConfirmAction = (payment, dispatch) => {
  dispatch({
    type: ArchivedGroupsActions.DELETE_BUTTON_CLICKED,
    payload: { id: payment.id },
  });
};

export const updateAction = async (payment, dispatch) => {
  const response = await ArchivedGroupsRepository.getMonthlyPayment(payment.id);
  if (response.status === false) {
    toastError("Serverdan ma'lumot olib bo'lmadi");
    dispatch({ type: ArchivedGroupsActions.CANCEL_BUTTON_CLICKED });
    return;
  }
  dispatch({
    type: ArchivedGroupsActions.UPDATE_BUTTON_CLICKED,
    payload: { id: payment.id, item: response.data },
  });
};
export const cancelDeleteAction = (dispatch) => {
  dispatch({
    type: ArchivedGroupsActions.CANCEL_BUTTON_CLICKED,
  });
};

export const deleteAction = async (e, context, dispatch) => {
  e.preventDefault();
  const deleteResponse = await ArchivedGroupsRepository.deleteLessonHour(
    context.deletingId
  );
  if (deleteResponse.status === true) {
    toastSuccess("Dars soati o'chirib yuborildi");
  } else {
    toastError("Dars soati o'chirilmadi!");
  }
  loadItems({}, context, dispatch);
  cancelDeleteAction(dispatch);
};
export const openCategories = (e, dispatch) => {
  e.stopPropagation();
  dispatch({ type: ArchivedGroupsActions.TOGGLE_CATEGORIES });
};
export const openGroupInfo = (dispatch) => {
  dispatch({ type: ArchivedGroupsActions.OPEN_INFO_MODAL });
};
export const openArchiveInfo = (dispatch) => {
  dispatch({ type: ArchivedGroupsActions.OPEN_ARCHIVE_INFO_MODAL });
};
