import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AdminLayout from "../../../AdminLayout";
import EmptyContent from "../../../Components/Empty-content/EmptyContent";
import ItemsLoader from "../../../Components/Loader/basic loader/ItemsLoader";
import TitleBar from "../../../Components/TitleBar";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import { useMonthlyReportsStore } from "../../../Store/ReportsStore/useMonthlyReports";
import Heading from "./components/Heading";
import Table from "./components/Table";

function MonthlyReports() {
  const { onReload, results, isLoading, getBranches } =
    useMonthlyReportsStore();
  const { setLoader } = useLoaderStore();
  const { t } = useTranslation();

  const pageLoad = useCallback(async () => {
    setLoader(true);
    await Promise.all([onReload(), getBranches()]);
    setLoader(false);
  }, []);

  useEffect(() => {
    pageLoad();
  }, []);

  return (
    <AdminLayout>
      <TitleBar title={t("Oylik tushum hisobotlari")} />
      <div className="container">
        <Heading />
        {isLoading ? (
          <ItemsLoader size={70} />
        ) : results?.length > 0 ? (
          <Table />
        ) : (
          <EmptyContent />
        )}
      </div>
    </AdminLayout>
  );
}

export default MonthlyReports;
