import React, { useMemo } from "react";
import { useAttendanceStore } from "../../../../Store/AttendanceFormStore/AttendanceForm";
import { useLoaderStore } from "../../../../Store/Loader/LoaderStore";
import { getAttendanceListWithParam } from "../actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";

export default function MonthSelector({ groupId }) {
  const { data, updateAttendanceDates } = useAttendanceStore();
  const { setLoader } = useLoaderStore();

  const months = data?.filterset?.months;
  const defaultSelected = data?.meta?.lesson_month?.id;
  const filtered = months.find((element) => element.id == defaultSelected);

  useEffect(() => {
    formik.setFieldValue("month", filtered?.month);
  }, [data]);

  const initialValues = {
    month: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      month: Yup.string().min(1, "Qidiruvni kiriting!"),
    }),
    onSubmit: (values) => {},
  });

  const handleSelectChange = async (e) => {
    formik.setFieldValue("month", e.target.value);
    setLoader(true);
    await getAttendanceListWithParam(
      new URLSearchParams({
        sub_group: groupId,
        lesson_month: e.target.value,
      }).toString(),
      updateAttendanceDates
    );
    setLoader(false);
  };

  return (
    <div className="select_bar">
      <select
        id="times"
        value={formik.values.month}
        onChange={(e) => handleSelectChange(e)}
        className="modal-input attendanceListSelect grey-box"
      >
        <option
          className="course_disabled_option"
          disabled
          hidden
          value={""}
        ></option>
        {months?.map((element) => {
          return (
            <option key={element.id} value={element.month}>
              {element.verbose_name}
            </option>
          );
        })}
      </select>
    </div>
  );
}
