import { create } from "zustand";
import { toastError, toastSuccess } from "../../Helpers/toasts";
import SpentDiscountReportsRepository from "../../Repositories/Reports/SpentDiscountReportsRepository";

export const useSpentDiscountReportsStore = create((set, get) => ({
  results: null,
  branches_list: null,
  discounts_count: null,
  staffs_list: null,
  startDate: null,
  endDate: null,
  pagination: null,
  isLoading: false,
  queryParams: {
    page: 1,
  },

  setLoading: (status) => {
    set({
      isLoading: status,
    });
  },

  updateParams: (paramItem) => {
    set((prevState) => ({
      queryParams: { ...prevState.queryParams, ...paramItem },
    }));
  },

  onReload: async (queryString = "") => {
    const { data, status, nonFieldErrors } =
      await SpentDiscountReportsRepository.studentsList(queryString);

    if (status) {
      set({
        results: data.results.discounts,
        discounts_count: data.results.discounts_count,
        branches_list: data.results.filterset.branches,
        staffs_list: data.results.filterset.teachers,
        pagination: { page_count: data.pages_count || 1 },
        queryParams: { page: 1 },
      });
    } else toastError(nonFieldErrors);
  },

  loadItems: async (queryString) => {
    const { data, status, nonFieldErrors } =
      await SpentDiscountReportsRepository.studentsList(queryString);
    if (status) {
      set({
        results: data.results.discounts,
        discounts_count: data.results.discounts_count,
        pagination: { page_count: data.pages_count || 1 },
      });
    } else toastError(nonFieldErrors);
  },
}));
