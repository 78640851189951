import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { memo } from "react";
import DataTable from "react-data-table-component";
import { dateFormat } from "../../../Helpers/dateFormat";
import { toastError, toastSuccess } from "../../../Helpers/toasts";
import TrelloRepository from "../../../Repositories/Trello/Trellorepository";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTrelloStore } from "../../../Store/Trello/TrellosStore";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const customStyles = {
  rows: {
    style: {
      minHeight: "50px",
      fontSize: "13px",
    },
  },
  head: {
    style: {
      fontSize: "14px",
    },
  },
};

function TrelloTable() {
  const { backlog, loadItems } = useTrelloStore();
  const { setLoader } = useLoaderStore();
  const [loading, setLoading] = useState(false);
  const [deletedItem, setDeletedItem] = useState(false);
  const { t } = useTranslation();

  const deleteTask = async (id) => {
    setLoading(true);
    setDeletedItem(id);
    const { data, status, nonFieldErrors } = await TrelloRepository.deleteTask(
      id
    );
    setLoading(false);
    if (status) {
      setLoader(true);
      toastSuccess(t("Topshiriq o'chirildi"));
      await loadItems();
      setLoader(false);
    } else toastError(nonFieldErrors);
  };

  const columns = [
    {
      name: "№",
      maxWidth: "50px",
      minWidth: "0px",
      sortable: true,
      cell: (row, index) => <div>{index + 1}</div>,
    },
    {
      name: t("Topshiriq"),
      selector: (row) => row?.name,
      cell: (row) => <div>{row.name}</div>,
      sortable: true,
    },
    {
      name: t("Masul xodim"),
      selector: (row) => row.assignee?.name,
      cell: (row) => <div>{row.assignee?.name}</div>,
      sortable: true,
    },
    {
      name: t("Kiritilgan vaqti"),
      selector: (row) => row.created_at,
      cell: (row) => <div>{dateFormat(row.created_at)}</div>,
      sortable: true,
    },
    {
      name: t("Izoh"),
      selector: (row) => row.description,
      cell: (row) => <div>{row.description}</div>,
      sortable: true,
    },
    {
      name: t("Topshiriq muddati"),
      selector: (row) => row.deadline,
      cell: (row) => (
        <div>{row.deadline ? dateFormat(row.deadline) : "----"}</div>
      ),
      sortable: true,
    },
    {
      name: t("Topshiriq holati"),
      selector: (row) => row.priority?.name,
      cell: (row) => <div>{row.priority?.name}</div>,
      sortable: true,
    },
    {
      name: t("Qo'shish"),
      width: 100,
      display: "flex",
      cell: (row) => (
        <IconButton
          onClick={() =>
            useTrelloStore.setState({ isOpenModal: true, updateId: row })
          }
          color="primary"
          aria-label="add to shopping cart"
        >
          <ArrowCircleUpIcon
            style={{
              fontSize: "30px",
              textAlign: "center",
            }}
          />
        </IconButton>
      ),
    },
    {
      name: t("O'chirish"),
      width: 100,
      display: "flex",
      cell: (row) => (
        <Tooltip title={t("O'chirish uchun ikki marta bosing")}>
          <IconButton
            onDoubleClick={() => deleteTask(row.id)}
            color="error"
            aria-label="add to shopping cart"
          >
            {loading && row.id == deletedItem ? (
              <CircularProgress size={20} />
            ) : (
              <DeleteIcon
                style={{
                  fontSize: "30px",
                  textAlign: "center",
                }}
              />
            )}
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <div className="trello-table">
      <DataTable
        title={t("Bajaruvchisi belgilanmagan vazifalar")}
        data={backlog || []}
        highlightOnHover
        columns={columns || []}
        customStyles={customStyles}
        pagination
        fixedHeader
      />
    </div>
  );
}
export default memo(TrelloTable);
