import { useFormik } from "formik";
import React, { useCallback, useContext, useEffect } from "react";
import CategoriesBlock from "./CategoriesBlock";
import CommonHeading from "../../../../Components/CommonHeading";
import ExportBlock from "../../../../Components/ExportBlock";
import PageSizeChanger from "../../../../Components/PageSizeChanger";
import Searchbox from "../../../../Components/Searchbox";
import { loadItems } from "../actions";
import TableHead from "./TableHead";
import { ArchivedGroupsContext } from "../../../../Contexts/GroupsContext/ArchivedGroupsContext";
import ArchivedGroupsActions from "../../../../Actions/GroupsActions/ArchivedGroupsActions";

export default function Heading() {
  const { archivedGroups, dispatchArchivedGroups } = useContext(
    ArchivedGroupsContext
  );

  const changePageSize = useCallback((e) => {
    dispatchArchivedGroups({
      type: ArchivedGroupsActions.ADD_QUERY_PARAM,
      payload: { pageSize: e.target.value },
    });
    localStorage.setItem("pageSize", e.target.value);
    loadItems(
      { pageSize: e.target.value },
      archivedGroups,
      dispatchArchivedGroups
    );
  });

  const searchAction = useCallback((payload) => {
    dispatchArchivedGroups({
      type: ArchivedGroupsActions.ADD_QUERY_PARAM,
      payload: payload,
    });
    loadItems(payload, archivedGroups, dispatchArchivedGroups);
  });

  const formik = useFormik({
    initialValues: {
      term: "",
    },
    onSubmit: (values) => {
      searchAction({
        term: values.term,
      });
    },
  });

  useEffect(() => {
    const pageSize = localStorage.getItem("pageSize") ?? 10;
    dispatchArchivedGroups({
      type: ArchivedGroupsActions.ADD_QUERY_PARAM,
      payload: { pageSize: pageSize },
    });
  });

  return (
    <CommonHeading
      topItems={
        <>
          <Searchbox formik={formik} />
        </>
      }
      rightItems={
        <>
          <ExportBlock />
          <PageSizeChanger context={archivedGroups} callback={changePageSize} />
          <CategoriesBlock />
        </>
      }
      tableHead={<TableHead />}
    />
  );
}
