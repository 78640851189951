import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function BackBtn({ action }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      className="back_btn_modal"
      onClick={() => action()}
    >
      <IconButton
        size="medium"
        color="primary"
        aria-label="upload picture"
        component="label"
        className="btn_container"
      >
        <ArrowBackIcon className="back_icon" />
      </IconButton>
    </Stack>
  );
}
