import React from "react";

export default function ListItem({ group, index }) {
  const teachers = group?.teachers?.map((item, index) => {
    return (
      <p key={item.id}>
        <span>{index + 1}.</span>
        {item.fullname}
      </p>
    );
  });
  const days = group?.days?.map((item, index) => {
    return (
      <p key={index}>
        <span>{index + 1}.</span>
        {item.name}
      </p>
    );
  });

  return (
    <ul className="next-table build-table build-table--light build-table__item sticky_list">
      <li className="build-table__item sticky_table">
        <ul className="level_up_item">
          <li className="build-table__item  list_padding_sticky ">
            {index + 1}
          </li>
          <li className="build-table__item list_padding_sticky ">
            {group.number}
          </li>
          <li className="build-table__item list_padding_sticky level-teacher">
            <p>{group.teachers?.[0].fullname}</p>
            <div className="attendance-cards levelUp">{teachers}</div>
          </li>
        </ul>
      </li>
      <li className="build-table__item list_padding_sticky level-teacher">
        <p>{group.days?.[0].name}</p>
        <div className="attendance-cards levelUp">{days}</div>
      </li>
      <li className="build-table__item list_padding_sticky">{group.month}</li>
      <li className="build-table__item list_padding_sticky">
        {group.start_date}
      </li>
      <li className="build-table__item list_padding_sticky">
        {group.end_date}
      </li>
      <li className="build-table__item list_padding_sticky">
        {group.total_students_count}
      </li>
      <li className="build-table__item list_padding_sticky">
        {group.never_missed_students_count}
      </li>
      <li className="build-table__item list_padding_sticky">
        {group.missed_students_count}
      </li>
      <li className="build-table__item list_padding_sticky">
        {group.expelled_students_count}
      </li>
      <li className="build-table__item list_padding_sticky">
        {group.discount_earned_students_count}
      </li>
    </ul>
  );
}
