import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Box } from "@mui/material";
import { useMainGroupStore } from "../../../../../Store/ManageGroupsStore/MainGroupStore";
import { useTranslation } from "react-i18next";
const finishGroup = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  margin: "10px 0",
};
export default function CheckboxLabels() {
  const [isClosedPermanently, setClosedPermanently] = React.useState(false);
  const [isClosedTemporaryly, setClosedTemporaryly] = React.useState(false);

  const handleClosedPermanently = (e) => {
    if (!e.target.checked) {
      setClosedTemporaryly(false);
      useMainGroupStore.setState({
        closedTemporaryly: false,
      });
    }
    setClosedPermanently(!isClosedPermanently);
    useMainGroupStore.setState({
      closedTemporaryly: false,
      closedPermanently: !e.target.checked,
    });
  };
  const handleClosedTemporaryly = (e) => {
    if (!e.target.checked) {
      setClosedPermanently(false);
      useMainGroupStore.setState({
        closedPermanently: false,
      });
    }
    setClosedTemporaryly(!isClosedTemporaryly);
    useMainGroupStore.setState({
      closedTemporaryly: !e.target.checked,
    });
  };
  const { t } = useTranslation();
  return (
    <Box sx={finishGroup}>
      <FormControlLabel
        checked={isClosedPermanently}
        control={<Checkbox onClick={handleClosedPermanently} />}
        label={t("Guruhni butunlay yopish")}
      />
      <FormControlLabel
        checked={isClosedTemporaryly}
        control={<Checkbox onClick={handleClosedTemporaryly} />}
        label={t("Guruhni vaqtincha to'xtatib turish")}
      />
    </Box>
  );
}
