import { axiosInterceptor } from "../AxiosInterceptor";

export default class StudentTrasferRepository {
  static async getStudentsList(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        "/management/group/change/students/?" + queryString
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data.students };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: e.response.data
          ? e.response.data.data.error
          : "Ma'lumot olib bo'lmadi!",
      };
    }
    return result;
  }
  static async getStudentDetails(id) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        `/management/group/change/${id}/student-details/`
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data.details };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: "Ma'lumot olib bo'lmadi!",
      };
    }
    return result;
  }
  static async getGroupList(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        "/management/group/change/groups/?" + queryString
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data.groups };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: e.response.data
          ? e.response.data.data.error
          : "Ma'lumot olib bo'lmadi!",
      };
    }
    return result;
  }
  static async getGroupDetails(id) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        `/management/group/change/${id}/group-details/`
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data.details };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: "Ma'lumot olib bo'lmadi!",
      };
    }
    return result;
  }
  static async transferStudentAction(data) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.post(
        "/management/group/change/",
        data
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: "O'quvchini guruhini o'zgartirib bo'lmadi!",
      };
    }
    return result;
  }
  static async getTransferLists(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        "/management/group/changes/?" + queryString
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: e.response.data
          ? e.response.data.data.error
          : "Ma'lumot olib bo'lmadi!",
      };
    }
    return result;
  }
}
