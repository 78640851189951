export default class EmployeeRegistrationAction {
  static CREATE_BUTTON_CLICKED = "CREATE_BUTTON_CLICKED";
  static ADD_QUERY_PARAM = "ADD_QUERY_PARAM";
  static PAGE_LOADED = "PAGE_LOADED";
  static TOGGLE_CONTEXT_MENU = "TOGGLE_CONTEXT_MENU";
  static CLOSE_CONTEXT_MENU = "CLOSE_CONTEXT_MENU";
  static DELETE_BUTTON_CLICKED = "DELETE_BUTTON_CLICKED";
  static CANCEL_BUTTON_CLICKED = "CANCEL_BUTTON_CLICKED";
  static UPDATE_BUTTON_CLICKED = "UPDATE_BUTTON_CLICKED";
  static TOGGLE_FILTER_LIST = "TOGGLE_FILTER_LIST";
  static CLOSE_FILTER_LIST = "CLOSE_FILTER_LIST";
  static TOGGLE_CATEGORIES = "TOGGLE_CATEGORIES";
  static CLOSE_CATEGORIES = "CLOSE_CATEGORIES";
  static SHOW_DELETE_LOADER = "SHOW_DELETE_LOADER";
  static PERMISSION_BUTTON_CLICKED = "PERMISSION_BUTTON_CLICKED";
  static REMOVE_STAFF_BUTTON_CLICKED = "REMOVE_STAFF_BUTTON_CLICKED";
  static UPDATE_SALARY = "UPDATE_SALARY";
  static CATEGORY_LIST = "CATEGORY_LIST";
  static STAFF_INFO = "STAFF_INFO";
}
