import React from "react";
import { useTranslation } from "react-i18next";
import { useAttendanceStore } from "../../../../Store/AttendanceFormStore/AttendanceForm";
import { useLoaderStore } from "../../../../Store/Loader/LoaderStore";
import { getAttendanceListWithParam } from "../actions";

function InfoTag({ groupId }) {
  const { data, updateAttendanceDates } = useAttendanceStore();
  const { setLoader } = useLoaderStore();

  if (!data) {
    return null;
  }
  const { card_icons } = data;

  const handleSelectChange = async (month) => {
    // formik.setFieldValue("month", month);
    setLoader(true);
    await getAttendanceListWithParam(
      new URLSearchParams({
        sub_group: groupId,
        lesson_month: month,
      }).toString(),
      updateAttendanceDates
    );
    setLoader(false);
  };
  const { t } = useTranslation();
  return (
    <div className="journal-bottom">
      <div className="journal-icons journal-icons_feedback">
        {Object.entries(card_icons).map((element, index) => {
          return (
            <div key={index} className="journal-icon">
              <img src={element[1].icon} alt="journal icon" />
              {element[1].verbose_name}
            </div>
          );
        })}
        <div className="journal-icon">
          <img src="/images/darsga keldi.png" alt="journal icon" />
          {t("Darsga keldi")}
        </div>
        <div className="journal-icon">
          <img src="/images/sababli.png" alt="journal icon" />
          {t("Sababli kelmadi")}
        </div>
        <div className="journal-icon">
          <img src="/images/sababsiz.png" alt="journal icon" />
          {t("Sababsiz kelmadi")}
        </div>
      </div>
      <div className="next_prev_btns">
        <button
          onClick={() => handleSelectChange(data?.prev_lesson_month)}
          disabled={!data?.prev_lesson_month}
          className="btn btn-blue"
        >
          {t("Avvaligi oy")}
        </button>
        <button
          onClick={() => handleSelectChange(data?.next_lesson_month)}
          disabled={!data?.next_lesson_month}
          className="btn btn-blue"
        >
          {t("Keyingi oy")}
        </button>
      </div>
    </div>
  );
}
export default React.memo(InfoTag);
