import { createContext, useReducer } from "react";
import { archivedGroupsReducers } from "../.././Reducers/GroupsReducers/ArchivedGroupsReducers";

export const ArchivedGroupsContext = createContext();
const initialState = {
  data: {},
  item: {},
  deletingId: null,
  isOpenCategories: false,
  isInfoModalOpen: false,
  queryParams: {
    page: 1,
  },
};
export default function ArchivedGroupsProvider(props) {
  const [archivedGroups, dispatchArchivedGroups] = useReducer(
    archivedGroupsReducers,
    {
      ...initialState,
    }
  );
  return (
    <ArchivedGroupsContext.Provider
      value={{ archivedGroups, dispatchArchivedGroups }}
    >
      {props.children}
    </ArchivedGroupsContext.Provider>
  );
}
