import LoaderActions from "../../../Actions/ComponentsActions/LoaderActions";
import { toastError } from "../../../Helpers/toasts";
import AttendanceDetailsRepository from "../../../Repositories/AttendanceForm/AttendanceDetailsRepository";

export const onReload = async (
  id,
  updateStudents,
  updateAttendanceDates,
  dispatchLoader
) => {
  const groupId = id.groupId;
  const date = id.date;
  try {
    dispatchLoader({ type: LoaderActions.PAGE_LOADING });

    const { data } = await AttendanceDetailsRepository.getDetaildData(
      new URLSearchParams({
        sub_group: groupId,
        date: date,
      }).toString()
    );

    updateAttendanceDates({
      sub_group: data.sub_group,
      date: data.date,
      card_types: data.card_types,
      meta: data.meta,
      editable: data.editable,
    });
    updateStudents(data.students);
  } catch (error) {
    toastError("Guruh ma'lumotlarini olib bo'lmadi");
  } finally {
    dispatchLoader({ type: LoaderActions.PAGE_LOADED });
  }
};
export const saveAttendanceDetails = async (
  details,
  dispatchLoader,
  navigate
) => {
  dispatchLoader({ type: LoaderActions.PAGE_LOADING });
  const { data, status, nonFieldErrors } =
    await AttendanceDetailsRepository.saveDetails(details);
  if (!status) {
    toastError(nonFieldErrors);
    dispatchLoader({ type: LoaderActions.PAGE_LOADED });
  }
  navigate();
};
