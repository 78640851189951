import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React, { useCallback } from "react";
import { useAttendanceDetailsStore } from "../../../../Store/AttendanceFormStore/AttendanceDeatilsStore";
import Counter from "./CardCounter";
import CheckBoxComponent from "./checkboxs/Checkbox";
import CommentModal from "./CommentModal";

const formCheckbox = {
  border: "1px solid red",
  width: "100%",
  height: "100%",
  display: "flex",
  flexWrap: "wrap",
};

function ListItem({ student }) {
  const { data, students } = useAttendanceDetailsStore();
  const { card_types } = data;

  const handleChange = (studentId, studentIsMissed) => {
    const updatedStudents = students?.map((student) =>
      student.id === studentId
        ? {
            ...student,
            is_missed: !studentIsMissed,
            comment: studentIsMissed ? "" : student.comment,
            with_reason: (student.with_reason = false),
          }
        : student
    );
    useAttendanceDetailsStore.setState({
      id: studentIsMissed ? null : studentId,
      students: updatedStudents,
    });
  };
  const homeworkHandler = useCallback((key, studentId) => {
    const updatedStudents = students?.map((student) =>
      student.id === studentId
        ? {
            ...student,
            checkbox_cards: {
              ...student.checkbox_cards,
              [key]: student.checkbox_cards?.[key] > 0 ? 0 : 1,
            },
          }
        : student
    );
    useAttendanceDetailsStore.setState({
      students: updatedStudents,
    });
  });

  return (
    <div className="desk-config desk-table details" key={student.id}>
      <div className="desk-config__item">{student.id}</div>
      <div className="desk-config__item desk-fname">{student.fullname}</div>
      <div className="desk-config__item">{student.student_id}</div>
      <div className="desk-config__item desk-yellow_third detail_checkbox">
        <Checkbox
          color="success"
          checked={student.is_missed}
          onChange={() => handleChange(student.id, student.is_missed)}
        />
      </div>
      <div className="card_types">
        {card_types?.numeric?.map((element, index) => {
          return (
            <div
              key={index}
              className="desk-config__item  desk-yellow_third card"
            >
              <Counter img={element.icon} item={student} element={element} />
            </div>
          );
        })}
        <div className="desk-config__item  desk-yellow_third card esseyCheckBox">
          {card_types?.checkbox?.map((item, index) => {
            return (
              <CheckBoxComponent
                key={item.key}
                label={item.name}
                index={index}
                callback={() => homeworkHandler(item.key, student.id)}
                checked={student?.checkbox_cards?.[item.key] > 0 ? true : false}
              />
            );
          })}
        </div>
      </div>
      <CommentModal element={student} />
    </div>
  );
}
export default React.memo(ListItem);
