import ArchivedGroupsActions from "../../Actions/GroupsActions/ArchivedGroupsActions";
export const archivedGroupsReducers = (state, action) => {
  switch (action.type) {
    case ArchivedGroupsActions.CREATE_BUTTON_CLICKED:
      return {
        ...state,
        isModalOpen: true,
        isCreating: true,
        isEditing: false,
      };
    case ArchivedGroupsActions.ADD_QUERY_PARAM:
      const params = { ...state.queryParams, ...action.payload };
      return {
        ...state,
        queryParams: params,
      };
    case ArchivedGroupsActions.PAGE_LOADED:
      return {
        ...state,
        ...action.payload,
      };
    case ArchivedGroupsActions.TOGGLE_CONTEXT_MENU:
      return { ...state, openContextMenuId: action.payload.id };
    case ArchivedGroupsActions.CLOSE_CONTEXT_MENU:
      return {
        ...state,
        openContextMenuId: null,
      };
    case ArchivedGroupsActions.DELETE_BUTTON_CLICKED:
      return {
        ...state,
        isDeleteModalOpen: true,
        deletingId: action.payload.id,
      };
    case ArchivedGroupsActions.CANCEL_BUTTON_CLICKED:
      return {
        ...state,
        isModalOpen: false,
        isDeleteModalOpen: false,
        isCreating: false,
        isEditing: false,
        editingId: null,
        deletingId: null,
        isInfoModalOpen: false,
      };
    case ArchivedGroupsActions.OPEN_INFO_MODAL:
      return { ...state, isInfoModalOpen: true };
    case ArchivedGroupsActions.OPEN_ARCHIVE_INFO_MODAL:
      return { ...state, isModalOpen: true };
    case ArchivedGroupsActions.UPDATE_BUTTON_CLICKED:
      return {
        ...state,
        isCreating: false,
        isEditing: true,
        editingId: action.payload.id,
        item: action.payload.item,
      };
    case ArchivedGroupsActions.TOGGLE_CATEGORIES:
      return { ...state, isOpenCategories: !state.isOpenCategories };
    case ArchivedGroupsActions.CLOSE_CATEGORIES:
      return { ...state, isOpenCategories: false };
    default:
      return state;
  }
};
