import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AdminLayout from "../../../AdminLayout";
import TitleBar from "../../../Components/TitleBar";
import { useExpendatureCashStore } from "../../../Store/CashControlStore/ExpendatureCashStore";
import { useSalaryAdvanceStore } from "../../../Store/CashControlStore/SalaryAdvanceStore";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import BasicTabs from "./components/BasicTabs";

export default function ExpenditureCash() {
  const { onReload } = useExpendatureCashStore();
  const { onReloadSalary } = useSalaryAdvanceStore();
  const { setLoader } = useLoaderStore();

  useEffect(() => {
    const pageLoad = async () => {
      setLoader(true);
      await Promise.all([onReload(), onReloadSalary()]);
      setLoader(false);
    };

    pageLoad();
  }, []);

  const { t } = useTranslation();

  return (
    <AdminLayout>
      <TitleBar title={t("Chiqim kassa xarajat oynasi")} />
      <div className="container expenditure__container">
        <BasicTabs />
      </div>
    </AdminLayout>
  );
}
