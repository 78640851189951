function IconDiscount2(props) {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      viewBox="0 0 24 24"
      height="2em"
      width="2em"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <path d="M9 15l6-6" />
      <path
        fill="currentColor"
        d="M10 9.5 A0.5 0.5 0 0 1 9.5 10 A0.5 0.5 0 0 1 9 9.5 A0.5 0.5 0 0 1 10 9.5 z"
      />
      <path
        fill="currentColor"
        d="M15 14.5 A0.5 0.5 0 0 1 14.5 15 A0.5 0.5 0 0 1 14 14.5 A0.5 0.5 0 0 1 15 14.5 z"
      />
      <path d="M5 7.2A2.2 2.2 0 017.2 5h1a2.2 2.2 0 001.55-.64l.7-.7a2.2 2.2 0 013.12 0l.7.7a2.2 2.2 0 001.55.64h1a2.2 2.2 0 012.2 2.2v1a2.2 2.2 0 00.64 1.55l.7.7a2.2 2.2 0 010 3.12l-.7.7a2.2 2.2 0 00-.64 1.55v1a2.2 2.2 0 01-2.2 2.2h-1a2.2 2.2 0 00-1.55.64l-.7.7a2.2 2.2 0 01-3.12 0l-.7-.7a2.2 2.2 0 00-1.55-.64h-1a2.2 2.2 0 01-2.2-2.2v-1a2.2 2.2 0 00-.64-1.55l-.7-.7a2.2 2.2 0 010-3.12l.7-.7A2.2 2.2 0 005 8.2v-1" />
    </svg>
  );
}

export default IconDiscount2;
