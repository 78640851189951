import React from "react";
import BackButton from "../../../../Components/BackButton";
import BasicButtons from "./BasicButtons";
import BasicSelect from "./BasicSelect";
import DateSection from "./DateSection";

export default function ReportsTitleBar({ title, createAction = null }) {
  return (
    <div className="build-title">
      <div className="title_bar_item">
        <BackButton />
        <h3 className="page-title">{title}</h3>
      </div>
      <div className="reports_btns">
        <BasicButtons />
        <BasicSelect />
        <DateSection />
      </div>
    </div>
  );
}
