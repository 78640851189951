import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useExpendatureCashStore } from "../../../../../Store/CashControlStore/ExpendatureCashStore";

export default function ColorToggleButton() {
  const { payment_types, updateFixedCosts, fixedCosts } =
    useExpendatureCashStore();

  const handleChange = (event, newAlignment) => {
    updateFixedCosts("payment_type", newAlignment);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={fixedCosts.payment_type}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      size="small"
    >
      {payment_types?.map((element) => {
        return (
          <ToggleButton key={element.value} value={element.value}>
            {element.name}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}
