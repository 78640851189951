import { memo } from "react";
import DataTable from "react-data-table-component";
import { useProfileConfigStore } from "../../../../Store/ProfileConfiguration/ProfileConfigStore";
import "../muiTableStyle.css";

const customStyles = {
  rows: {
    fontSize: "15px",
  },
  headCells: {
    style: {
      width: "auto",
      fontSize: "15px",
    },
  },
  cells: {
    style: {
      width: "auto",
    },
  },
};

function Table() {
  const { finance } = useProfileConfigStore();
  const payments = finance?.payments || [];

  const columns = [
    {
      name: "№",
      cell: (row, index) => <div>{index + 1}</div>,
    },
    {
      name: "Guruhi",
      selector: (row) => row.group,
      cell: (row) => <div>{row.group}</div>,
    },
    {
      name: "Jami o'quvchi",
      selector: (row) => row.students_count,
      cell: (row) => <div>{row.students_count} ta</div>,
    },
    {
      name: "Hisoblangan tahminiy oylik",
      cell: (row) => <div>{row.total_salary} so'm</div>,
      selector: (row) => row.total_salary,
    },
    {
      name: "Jamg'arildi",
      cell: (row) => <div>{row.collected_salary} so'm</div>,
      selector: (row) => row.collected_salary,
    },
  ];

  return (
    <div className="profileDataTable">
      <DataTable
        data={payments}
        columns={columns}
        highlightOnHover
        customStyles={customStyles}
      />
    </div>
  );
}
export default memo(Table);
