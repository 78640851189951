import React from "react";
import { Link } from "react-router-dom";

export default function BlockItem({ link, title, image }) {
  return (
    <Link className="config-box" to={link}>
      <img className="config-img" src={image} alt="config img" />
      <h4 className="config-title">{title}</h4>
    </Link>
  );
}
