import { axiosInterceptor } from "../AxiosInterceptor";

export default class GroupLevelUpRepository {
  static async getGroups(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        "management/statistics/group-stages-history/?" + queryString
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: e.response.data
          ? e.response.data.data.error
          : "Ma'lumot olib bo'lmadi!",
      };
    }
    return result;
  }
}
