import { axiosInterceptor } from "../AxiosInterceptor";

export default class MainGroupsRepository {
  static async getAllSubjects() {
    let result = {
      status: false,
      data: null,
    };

    const response = await axiosInterceptor.get(
      "/management/course/course-data/"
    );
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data };
    }
    return result;
  }
  static async getAllGroups(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        "/management/group/sub-groups/?" + queryString
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: e.response.data.data.detail
          ? e.response.data.data.detail
          : "Ma'lumot olib bo'lmadi!",
      };
    }
    return result;
  }
  static async getAllBranches() {
    let result = {
      status: false,
      data: null,
    };

    const response = await axiosInterceptor.get(
      "/management/selectables/branches/"
    );
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data.branches };
    }
    return result;
  }
  static async groupWeekDays() {
    let result = {
      status: false,
      data: null,
    };

    const response = await axiosInterceptor.get(
      "/management/selectables/weekdays/"
    );
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data.days };
    }
    return result;
  }
  static async getAllStaffs() {
    let result = {
      status: false,
      data: null,
    };

    const response = await axiosInterceptor.get("/register/staff/");
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data };
    }
    return result;
  }
  static async getGroupById(id) {
    let result = {
      status: false,
      data: null,
    };

    const response = await axiosInterceptor.get(`/management/group/${id}`);
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data };
    }
    return result;
  }
  static async createGroup(data) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.post("/management/group/", data);
      if (response.status === 201) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      if (e.response.data.data) {
        result = {
          ...result,
          nonFieldErrors: e.response.data.data.time_range?.[0],
        };
      } else
        result = { ...result, nonFieldErrors: "Yangi guruh ochib bo'lmadi!" };
    }
    return result;
  }
  static async deleteGroup(id) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.delete(
        `/management/group/${id}/`
      );
      if (response.status === 204) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {}
    return result;
  }
  static async addStudents(data, id) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.post(
        `/management/group/${id}/assign-students/`,
        data
      );

      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (error) {
      result = {
        ...result,
        nonFieldErrors: "Guruhga O'quvchi qo'shib bo'lmadi bo'lmadi!",
      };
    }
    return result;
  }
  static async updateCourse(data, id) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.patch(
        `/management/group/${id}/`,
        data
      );

      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (error) {
      result = {
        ...result,
        nonFieldErrors: "Guruh turini yangilab bo'lmadi!",
      };
    }
    return result;
  }
  static async getBranchRooms(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    const response = await axiosInterceptor.get(
      `/management/selectables/room/?` + queryString
    );
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data };
    }
    return result;
  }
  static async filterGroups(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    const response = await axiosInterceptor.get(
      `/management/group/sub-groups/?` + queryString
    );
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data };
    }
    return result;
  }
  static async groupStage(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        `/management/group/stage/?` + queryString
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {}
    return result;
  }
  static async stageNextMonth(data) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.post(
        "/management/group/stage/",
        data
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: e.response.data
          ? e.response.data.data.error
          : "Gruhni keyingi oyga o'tkazib bo'lmadi!",
      };
    }
    return result;
  }
  static async updateGroup(data) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.post(
        "/management/group/update/",
        data
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: e.response.data
          ? e.response.data.data.error
          : "Gruhni yangilab bo'lmadi!",
      };
    }
    return result;
  }
  static async groupUpdateTeacher(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        `/management/group/update/?` + queryString
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: e.response.data
          ? e.response.data.data.error
          : "Ma'lumot olib bo'lmadi!",
      };
    }
    return result;
  }
  static async groupOrganizeExam(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        `/management/group/organize-exam/?` + queryString
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: e.response.data
          ? e.response.data.data.error
          : "Ma'lumot olib bo'lmadi!",
      };
    }
    return result;
  }
  static async saveGroupOrganizeExam(data) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.post(
        `/management/group/organize-exam/`,
        data
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: e.response.data
          ? e.response.data.data.error
          : "Imtixon so'rovi qabul qilinmad!",
      };
    }
    return result;
  }
  static async statusUpdate(data) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.post(
        `/management/group/status/`,
        data
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: e.response.data
          ? e.response.data.data.error
          : "Ma'lumotni saqlab bo'lmadi!",
      };
    }
    return result;
  }
  static async finishGroup(id) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.post(
        `/management/group/${id}/finish/`
      );
      if (response.status === 200) {
        result = {
          ...result,
          status: true,
          data: response.data,
        };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: e.response.data
          ? e.response.data.data.error
          : "Ma'lumotni saqlab bo'lmadi!",
      };
    }
    return result;
  }
  static async continueGroup(id) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.post(
        `/management/group/${id}/activate/`
      );
      if (response.status === 200) {
        result = {
          ...result,
          status: true,
          data: response.data,
        };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: e.response.data
          ? e.response.data.data.error
          : "Ma'lumotni saqlab bo'lmadi!",
      };
    }
    return result;
  }
}
