import LoaderActions from "../../Actions/ComponentsActions/LoaderActions";
import StudentAttachmentAction from "../../Actions/StudentAttachment/StudentAttachmentAction";
import { toastError } from "../../Helpers/toasts";
import CommonRepository from "../../Repositories/Config/CommonRepository";
import StudentAttachmentRepository from "../../Repositories/StudentAttachment/StudentAttachmentRepository";

export const onReload = async (id, context, dispatch, dispatchLoader) => {
  dispatchLoader({ type: LoaderActions.PAGE_LOADING });
  const [
    response,
    groupStudents,
    getAllBranches,
    staffs,
    subjectsData,
    lessonDaysData,
    lessonTimesData,
    groupTypesData,
  ] = await Promise.all([
    StudentAttachmentRepository.getAllStudents(),
    StudentAttachmentRepository.getGroupStudents(id),
    StudentAttachmentRepository.getAllBranches(),
    StudentAttachmentRepository.getAllStaffs(),
    CommonRepository.getSubjects(),
    CommonRepository.getLessonDays(),
    CommonRepository.getAvailableTime(),
    CommonRepository.getGroupTypes(),
  ]);
  dispatch({
    type: StudentAttachmentAction.PAGE_LOADED,
    payload: {
      filteredData: response.data.data.results,
      data: response.data.data.results,
      groupData: groupStudents.data,
      groupStudents: groupStudents.data.students,
      filteredGroupData: groupStudents.data.students,
      modalSubjects: subjectsData.data,
      modalGroupTypes: groupTypesData.data.data,
      branches: getAllBranches.data,
      modalLessonDays: lessonDaysData.data,
      modalLessonTimes: lessonTimesData.data,
      staffs: staffs.data.data.results,
      pagination: { pages_count: response.data.data.pages_count },
    },
  });

  dispatchLoader({ type: LoaderActions.PAGE_LOADED });
};

export const loadItems = async (
  payload,
  context,
  dispatch = nul,
  dispatchLoader
) => {
  dispatchLoader({ type: LoaderActions.PAGE_LOADING });
  const response = await StudentAttachmentRepository.getAllStudents(
    new URLSearchParams({ ...context.queryParams, ...payload }).toString()
  );
  if (dispatch) {
    dispatch({
      type: StudentAttachmentAction.PAGE_LOADED,
      payload: {
        data: response.data.data.results,
        filteredData: response.data.data.results,
        pagination: { pages_count: response.data.data.pages_count },
      },
    });
  }
  dispatchLoader({ type: LoaderActions.PAGE_LOADED });
  // return results;
};

export const changeGroupStudent = (element, dispatch) => {
  dispatch({ type: StudentAttachmentAction.STUDENT_ADDED, payload: element });
};
export const changeStudentsData = (element, dispatch) => {
  dispatch({
    type: StudentAttachmentAction.CHANGE_FILTERED_DATA,
    payload: element,
  });
};
export const openFilterList = (dispatch) => {
  dispatch({ type: StudentAttachmentAction.TOGGLE_FILTER_LIST });
};

export const filterGroups = async (data, dispatch, dispatchLoader) => {
  dispatchLoader({ type: LoaderActions.PAGE_LOADING });

  try {
    const response = await StudentAttachmentRepository.getAllStudents(
      new URLSearchParams({ ...data }).toString()
    );
    if (!response.status) {
      throw new Error("Serverdan ma'lumot olishda xatolik yuz berdi!");
    } else
      dispatch({
        type: StudentAttachmentAction.PAGE_LOADED,
        payload: {
          data: response.data.data.results,
          filteredData: response.data.data.results,
          pagination: { pages_count: response.data.data.pages_count },
        },
      });
  } catch (error) {
    toastError(error.message);
  } finally {
    dispatchLoader({
      type: LoaderActions.PAGE_LOADED,
    });
  }
};
