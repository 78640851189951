import { Box } from "@mui/system";
import React from "react";
import SaveButton from "../SaveButton";
import CheckboxLabels from "./CheckboxForm";
import TextArea from "../TextArea";
import { useMainGroupStore } from "../../../../../Store/ManageGroupsStore/MainGroupStore";
import { toastError, toastSuccess } from "../../../../../Helpers/toasts";
import MainGroupsRepository from "../../../../../Repositories/Groups/MainGroupsRepository";
import { useTranslation } from "react-i18next";

const container = {
  padding: "0 25px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};
const button = {
  width: "100%",
  marginTop: "10px",
  display: "flex",
  justifyContent: "end",
};

export default function ModalContainer() {
  const {
    groupID,
    closedPermanently,
    closedTemporaryly,
    statusComment,
    isLoadingSave,
  } = useMainGroupStore();

  const { t } = useTranslation();

  const handleSubmit = async () => {
    const statusData = {
      group_id: groupID,
      closed_permanently: closedPermanently,
      closed_temporaryly: closedTemporaryly,
      comment: statusComment,
    };
    useMainGroupStore.setState({ isLoadingSave: true });
    const response = await MainGroupsRepository.statusUpdate(statusData);
    if (!response.status) {
      toastError(response.nonFieldErrors);
    }
    if (response.status) {
      toastSuccess(t("Guruh faoliyati to'xtatildi"));
    }
    useMainGroupStore.setState({ isLoadingSave: false });
  };

  return (
    <Box sx={container}>
      <CheckboxLabels />
      <TextArea property="fineshGroup" />
      <Box sx={button}>
        <SaveButton isloading={isLoadingSave} callback={handleSubmit}>
          {t("Saqlash")}
        </SaveButton>
      </Box>
    </Box>
  );
}
