import { useCallback, useContext, useEffect, useState } from "react";
import MainGroupsActions from "../../../Actions/GroupsActions/MainGroupsActions";
import AdminLayout from "../../../AdminLayout";
import DeleteConfirmation from "../../../Components/DeleteConfirmation";
import EmptyContent from "../../../Components/Empty-content/EmptyContent";
import ItemsLoader from "../../../Components/Loader/basic loader/ItemsLoader";
import PaginationComponent from "../../../Components/Pagination";
import TitleBar from "../../../Components/TitleBar";
import { MainGroupsContext } from "../../../Contexts/GroupsContext/MainGroupsContext";
import { LoaderContext } from "../../../Contexts/LoaderContext";
import { useMainGroupStore } from "../../../Store/ManageGroupsStore/MainGroupStore";
import UserPermissionWidget from "../../../UserPermissionWidget";
import ConfirmationModal from "../../Cash-control/Components/ConfirmationModal";
import VerticalTabs from "./UpdateGroupModel/UpdatedGroupModal";
import {
  cancelDeleteAction,
  createAction,
  deleteAction,
  loadItems,
  onReload,
} from "./actions";
import ActiveGroupButtons from "./components/ActiveGroupButtons";
import CreateUpdateModal from "./components/CreateUpdateModal";
import FilteredModal from "./components/FilteredModal";
import Heading from "./components/Heading";
import ListItem from "./components/ListItem";
import Statistics from "./components/Statistics";
import { useTranslation } from "react-i18next";

export default function MainGroups() {
  const { mainGroups, dispatchMainGroups } = useContext(MainGroupsContext);
  const { dispatchLoader } = useContext(LoaderContext);
  const [days, setDays] = useState([]);
  const {
    finishId,
    finishGroup,
    continueId,
    continueGroup,
    isLoadingSubGroups,
  } = useMainGroupStore();

  const pageReload = async () => {
    dispatchMainGroups({
      type: MainGroupsActions.ADD_QUERY_PARAM,
      payload: {
        pageSize: 10,
        page: 1,
      },
    });
    useMainGroupStore.setState({
      finishId: null,
      continueId: null,
    });
    await onReload(mainGroups, dispatchMainGroups, dispatchLoader);
  };

  useEffect(() => {
    localStorage.setItem("pageSize", 10);
    dispatchMainGroups({
      type: MainGroupsActions.ADD_QUERY_PARAM,
      payload: { pageSize: 10 },
    });
    pageReload();
    document.body.addEventListener("click", handleClickClosePanelFromOutside);
    return () => {
      document.body.removeEventListener(
        "click",
        handleClickClosePanelFromOutside
      );
    };
  }, []);

  useEffect(() => {
    const handleChange = (day) => {
      return {
        value: day.recurring_days,
        label: day.verbose_name,
      };
    };
    const createDaysObject = mainGroups.weekDays?.map((day) =>
      handleChange(day)
    );
    setDays(createDaysObject);
  }, [mainGroups.weekDays]);

  const paginateTo = useCallback((page) => {
    dispatchMainGroups({
      type: MainGroupsActions.ADD_QUERY_PARAM,
      payload: { page: page },
    });
    loadItems({ page: page }, mainGroups, dispatchMainGroups);
  });

  const handleClickClosePanelFromOutside = (e) => {
    if (!e.target.className.includes("build-table__item--7")) {
      dispatchMainGroups({ type: MainGroupsActions.CLOSE_CONTEXT_MENU });
    }
    if (!e.target.className.includes("categories")) {
      dispatchMainGroups({ type: MainGroupsActions.CLOSE_CATEGORIES });
    }
  };

  const confirmationCallback = useCallback(async () => {
    await finishGroup(finishId);
    loadItems({}, mainGroups, dispatchMainGroups);
    cancelCallback();
  }, [finishId]);

  const confirmationContinueCallback = useCallback(async () => {
    await continueGroup(continueId);
    loadItems({}, mainGroups, dispatchMainGroups);
    cancelContinueCallback();
  }, [continueId]);

  const cancelCallback = useCallback(() => {
    useMainGroupStore.setState({
      finishId: null,
    });
  }, [finishId]);

  const cancelContinueCallback = useCallback(() => {
    useMainGroupStore.setState({
      continueId: null,
    });
  }, [continueId]);

  const user = JSON.parse(localStorage.getItem("user"));

  const { t } = useTranslation();

  return (
    <AdminLayout>
      <TitleBar
        title={t("Yangi guruh jurnalini ochish va sozlash")}
        createAction={() => createAction(dispatchMainGroups)}
        groupBtns={<ActiveGroupButtons />}
        permission={user?.permissions?.create_new_group}
      />
      <div className="container remove_scroll">
        <Statistics />
        <Heading />
        {isLoadingSubGroups ? (
          <ItemsLoader size={70} />
        ) : mainGroups.data?.length ? (
          mainGroups.data.map((group, index) => (
            <ListItem group={group} key={group.id} index={index} />
          ))
        ) : (
          <EmptyContent />
        )}
        <PaginationComponent context={mainGroups} callback={paginateTo} />
      </div>
      <UserPermissionWidget hasPermission={user?.permissions?.create_new_group}>
        <CreateUpdateModal days={days} />
      </UserPermissionWidget>
      <FilteredModal />
      <VerticalTabs />
      <DeleteConfirmation
        deleteCallback={(e) => deleteAction(e, mainGroups, dispatchMainGroups)}
        cancelCallback={() => cancelDeleteAction(dispatchMainGroups)}
        showModal={mainGroups}
      />
      <ConfirmationModal
        title={t("Guruh muvaffaqiyat yakunlansinmi?")}
        confirmationCallback={() => confirmationCallback()}
        cancelCallback={() => cancelCallback()}
        showModal={finishId}
      />
      <ConfirmationModal
        title={t("Guruh faollashtirilsinmi?")}
        confirmationCallback={() => confirmationContinueCallback()}
        cancelCallback={() => cancelContinueCallback()}
        showModal={continueId}
      />
    </AdminLayout>
  );
}
