import { create } from "zustand";
import { toastError, toastSuccess } from "../../Helpers/toasts";
import MainGroupsRepository from "../../Repositories/Groups/MainGroupsRepository";

export const useMainGroupStore = create((set) => ({
  data: null,
  sub_groups: null,
  finishId: null,
  continueId: null,
  months: null,
  groupID: null,
  updateTeacherData: null,
  subGroupsTeacher: null,
  monthsTeacher: null,
  organizeExam: null,
  examMonthId: null,
  examComment: "",
  closedPermanently: false,
  closedTemporaryly: false,
  statusComment: "",
  isLoading: false,
  isLoadingTabs: false,
  isLoadingSave: false,
  isLoadingSubGroup: false,
  isOpenModal: false,
  setModalToggler: (status) => {
    set({ isOpenModal: status });
  },
  finishGroup: async (id) => {
    const { status, nonFieldErrors } = await MainGroupsRepository.finishGroup(
      id
    );
    if (status) {
      toastSuccess("Guruh yakunlandi");
    } else toastError(nonFieldErrors);
  },
  continueGroup: async (id) => {
    const { status, nonFieldErrors } = await MainGroupsRepository.continueGroup(
      id
    );
    if (status) {
      toastSuccess("Guruh faollashdi");
    } else toastError(nonFieldErrors);
  },
}));
