import { axiosInterceptor } from "../AxiosInterceptor";

export default class IncomeCashRepository {
  static async getStudentShortLists(queryString = "") {
    let result = {
      status: false,
      data: null,
    };

    try {
      const response = await axiosInterceptor.get(
        "/finance/students/?" + queryString
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      if (e.response.data.data.student) {
        result = {
          ...result,
          nonFieldErrors: e.response.data.data.student,
        };
      } else result = { ...result, nonFieldErrors: "Ma'lumot olib bo'lmadi!" };
    }
    return result;
  }
  static async getListItemDetail(id) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        `/finance/students/${id}/financial_details/`
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      if (e.response?.data?.data?.student) {
        result = {
          ...result,
          nonFieldErrors: e.response.data.data.student,
        };
      } else result = { ...result, nonFieldErrors: "Ma'lumot olib bo'lmadi!" };
    }
    return result;
  }
  static async getCheque(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        "/finance/payments/cheque/?" + queryString
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      result = { ...result, nonFieldErrors: "Checkni olib bo'lmadi!" };
    }
    return result;
  }
  static async savePayment(data) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.post(
        "/finance/payments/accept/",
        data
      );

      if (response.status === 200) {
        result = {
          ...result,
          status: true,
          data: response.data.id,
          nonFieldErrors: response.data.message,
        };
      }
    } catch (error) {
      if (error.response.data.data) {
        result = {
          ...result,
          nonFieldErrors: error.response.data.data.error,
        };
      } else
        result = { ...result, nonFieldErrors: "To'lovni saqlab bo'lmadi!" };
    }
    return result;
  }
}
