import React, { useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import StudentsRegistrationActions from "../../../../Actions/RegistrationActions/StudentsRegistrationActions";
import { LoaderContext } from "../../../../Contexts/LoaderContext";
import { StudentsRegistrationContext } from "../../../../Contexts/RegistrationContex/StudentRegistrationContext";
import { dateRangeFormat } from "../../../../Helpers/dateFormat";
import { filterGroups } from "../actions";

const DateSection = () => {
  const { students, dispatchStudents } = useContext(
    StudentsRegistrationContext
  );
  const { dispatchLoader } = useContext(LoaderContext);
  const changeDateRange = async (date) => {
    dispatchStudents({
      type: StudentsRegistrationActions.ADD_QUERY_PARAM,
      payload: {
        start_date: dateRangeFormat(date?.[0]) ?? "",
        end_date: dateRangeFormat(date?.[1]) ?? "",
        page: 1,
      },
    });
    dispatchStudents({
      type: StudentsRegistrationActions.PAGE_LOADED,
      payload: {
        startDate: date[0] ?? "",
        endDate: date[1] ?? "",
      },
    });
    if ((date[0] && date[1]) || (!date[0] && !date[1]))
      await filterGroups(
        {
          ...students?.queryParams,
          start_date: dateRangeFormat(date[0]) ?? "",
          end_date: dateRangeFormat(date[1]) ?? "",
          page: 1,
        },
        dispatchStudents,
        dispatchLoader
      );
  };

  return (
    <DatePicker
      className="student-registr--date"
      placeholderText="mm/dd/yy - mm/dd/yy"
      selectsRange={true}
      startDate={students?.startDate}
      endDate={students?.endDate}
      isClearable
      clearButtonClassName="date_clear-btn"
      onChange={(date) => changeDateRange(date)}
    />
  );
};

export default React.memo(DateSection);
