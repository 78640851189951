import { useTranslation } from "react-i18next";
import { useAgeGroupReportStore } from "../../../../Store/ReportsStore/AgeGroupReportStore";

function TableFooter() {
  const { ageGroupsList, totalСounts } = useAgeGroupReportStore();
  const { t } = useTranslation();

  return (
    <tr className="reports-table-footer">
      <td>{t("Jami")}</td>
      {ageGroupsList?.map((element) => {
        return <td>{totalСounts[element.value]}</td>;
      })}
      <td>{totalСounts?.groups_count}</td>
      <td>{totalСounts?.students_count}</td>
    </tr>
  );
}

export default TableFooter;
