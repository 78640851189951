import { useFormik } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Searchbar from "../../../../Components/Searchbar";
import { useGroupLevelStore } from "../../../../Store/GroupManagement/GroupLevelUpStore";
import { loadItems } from "../actions";
import DateSection from "./DateSection";
import Selector from "./Select";
import * as Yup from "yup";
import { useCallback } from "react";
import { useLoaderStore } from "../../../../Store/Loader/LoaderStore";

export default function TableHead() {
  const { months, branches, subjects, updateParams, queryParams } =
    useGroupLevelStore();
  const { setLoader } = useLoaderStore();

  const { t } = useTranslation();

  const monthsOptions = useMemo(() => {
    return (
      months?.map((element) => ({
        label: element.name,
        value: element.month,
      })) || []
    );
  }, [months]);

  const branchesOptions = useMemo(() => {
    return (
      branches?.map((element) => ({
        label: element.title,
        value: element.id,
      })) || []
    );
  }, [branches]);

  const subjectsOptions = useMemo(() => {
    return (
      subjects?.map((element) => ({
        label: element.name,
        value: element.id,
      })) || []
    );
  }, [subjects]);

  const searchAction = useCallback(async (payload) => {
    updateParams({ search: payload.term, page: 1 });
    setLoader(true);
    await loadItems(
      new URLSearchParams({
        ...queryParams,
        page: 1,
        search: payload.term,
      }).toString()
    );
    setLoader(false);
  });

  const initialValues = {
    term: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      term: Yup.string().min(1, t("Qidiruvni kiriting!")),
    }),
    onSubmit: (values) => {
      searchAction({
        term: values.term,
      });
    },
  });

  const handleChange = (e) => {
    formik.setFieldValue("term", e.target.value);
  };

  return (
    <div className="build-search build-search_teacher sticky_build_search">
      <div className="build-search__btn split-slider">
        <Searchbar
          value={formik.values.term}
          handleChange={(e) => handleChange(e)}
          handleSubmit={formik.handleSubmit}
        />
        <Selector
          property={t("Oylar")}
          options={monthsOptions}
          value={monthsOptions?.find(
            (element) => element.value == queryParams.month || null
          )}
        />
        <Selector
          property={t("Filiallar")}
          options={branchesOptions}
          value={branchesOptions?.find(
            (element) => element.value == queryParams.branch || null
          )}
        />
        <Selector
          property={t("subject")}
          options={subjectsOptions}
          value={subjectsOptions?.find(
            (element) => element.value == queryParams.subject || null
          )}
        />
        <DateSection />
      </div>
      <ul className="next-table build-table build-table--bold ">
        <li className="build-table__item sticky_padding sticky_table ">
          <ul className="level_up_item">
            <li className="build-table__item ">№</li>
            <li className="build-table__item ">{t("Guruh raqami")}</li>
            <li className="build-table__item ">{t("Asosiy o‘qituvchi")}</li>
          </ul>
        </li>
        <li className="build-table__item sticky_padding">
          {t("Dars kuni/soat")}
        </li>
        <li className="build-table__item sticky_padding">
          {t("Yakunlangan oy")}
        </li>
        <li className="build-table__item sticky_padding">
          {t("Boshlangan sana")}
        </li>
        <li className="build-table__item sticky_padding">
          {t("Yakunlangan sana")}
        </li>
        <li className="build-table__item sticky_padding">
          {t("Jami o’quvchilar")}
        </li>
        <li className="build-table__item sticky_padding">
          {t("Faol o’quvchilar")}
        </li>
        <li className="build-table__item sticky_padding">
          {t("Dars qoldirgan o’quvchilar")}
        </li>
        <li className="build-table__item sticky_padding">
          {t("Chetlatilgan o'quvchilar")}
        </li>
        <li className="build-table__item sticky_padding">Chegirma olganlar</li>
      </ul>
    </div>
  );
}
