import { useEffect } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import AdminLayout from "../../../AdminLayout";
import EmptyContent from "../../../Components/Empty-content/EmptyContent";
import ItemsLoader from "../../../Components/Loader/basic loader/ItemsLoader";
import TitleBar from "../../../Components/TitleBar";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import { useExpenditureReportsStore } from "../../../Store/ReportsStore/ExpenditureReportsStore";
import ExtraTable from "./components/ExtraTable";
import Heading from "./components/Heading";

function ExpenditureReport() {
  const { onReload, isLoading, data } = useExpenditureReportsStore();
  const { setLoader } = useLoaderStore();

  const pageLoad = useCallback(async () => {
    setLoader(true);
    await onReload();
    setLoader(false);
  }, []);

  const { t } = useTranslation();

  useEffect(() => {
    pageLoad();
  }, []);

  return (
    <AdminLayout>
      <TitleBar title={t("Xarajatlar hisobotlari")} />
      <div className="container">
        <Heading />
        {isLoading ? (
          <ItemsLoader size={70} />
        ) : data ? (
          <div className="daily-finance-tables">
            <ExtraTable />
          </div>
        ) : (
          <EmptyContent />
        )}
      </div>
    </AdminLayout>
  );
}

export default ExpenditureReport;
