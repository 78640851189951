import { create } from "zustand";

export const useExamDetailsStore = create((set, get) => ({
  data: null,
  students: null,
  exam_types: null,
  examiners: null,
  subject: null,
  can_access: null,
  isSubmitLoading: false,
  setSubmitLoader: (status) => {
    set({
      isSubmitLoading: status,
    });
  },
  setExamTypes: (event, key, property) => {
    const { exam_types } = get() || [];
    const updated = exam_types?.map((item) =>
      item.code_name === key
        ? {
            ...item,
            [property]:
              property === "examiner"
                ? event.value
                : parseInt(event.target.value),
          }
        : item
    );
    set({ exam_types: updated });
  },
  setStudentsScore: (event, studentId, property) => {
    const { students } = get() || [];
    const updated = students?.map((item) =>
      item.id === studentId
        ? {
            ...item,
            [property]: Number(event) || 0,
          }
        : item
    );
    set({ students: updated });
  },
}));
