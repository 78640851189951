import axiosInstance from "../AxiosInstance";

export default class CommonRepository {
  static async getSubjects() {
    let result = {
      status: false,
      data: null,
    };
    const response = await axiosInstance.get(
      "/management/selectables/subjects/"
    );
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data.subjects };
    }
    return result;
  }

  static async getLessonStyles() {
    let result = {
      status: false,
      data: null,
    };
    const response = await axiosInstance.get(
      "/management/constants/lesson-styles/"
    );
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data };
    }
    return result;
  }

  static async getGroupTypes() {
    let result = {
      status: false,
      data: null,
    };
    const response = await axiosInstance.get(
      "/management/constants/age-groups/"
    );
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data };
    } else {
      result = {
        ...result,
        nonFieldErrors: "Serverdan ma'lumot olishda xatolik!",
      };
    }
    return result;
  }
  static async getLessonDays() {
    let result = {
      status: false,
      data: null,
    };
    const response = await axiosInstance.get(
      "/register/constants/lesson-days/?objects=true"
    );
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data.days };
    } else {
      result = {
        ...result,
        nonFieldErrors: "Serverdan ma'lumot olishda xatolik!",
      };
    }
    return result;
  }
  static async getAvailableTime(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    const response = await axiosInstance.get(
      "/management/selectables/time-slots/?" + queryString
    );
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data.time_slots };
    } else {
      result = {
        ...result,
        nonFieldErrors: "Serverdan ma'lumot olishda xatolik!",
      };
    }
    return result;
  }
  static async getRooms(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    const response = await axiosInstance.get(
      "/management/selectables/rooms/?" + queryString
    );
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data.rooms };
    } else {
      result = {
        ...result,
        nonFieldErrors: "Serverdan ma'lumot olishda xatolik!",
      };
    }
    return result;
  }
  static async getTeacher() {
    let result = {
      status: false,
      data: null,
    };
    const response = await axiosInstance.get(
      "/management/selectables/teachers/"
    );
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data.teachers };
    } else {
      result = {
        ...result,
        nonFieldErrors: "Serverdan ma'lumot olishda xatolik!",
      };
    }
    return result;
  }
  static async getConnectionStatus() {
    let result = {
      status: false,
      data: null,
    };
    const response = await axiosInstance.get(
      "/register/constants/connection-statuses/"
    );
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data };
    } else {
      result = {
        ...result,
        nonFieldErrors: "Serverdan ma'lumot olishda xatolik!",
      };
    }
    return result;
  }
}
