import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import ReactLoading from "react-loading";
import * as Yup from "yup";
import Select from "react-select";
import ColorToggleButton from "./ToggleButton";
import { useTrelloStore } from "../../../Store/Trello/TrellosStore";
import { useMemo } from "react";
import TrelloRepository from "../../../Repositories/Trello/Trellorepository";
import { toastError, toastSuccess } from "../../../Helpers/toasts";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import UserPermissionWidget from "../../../UserPermissionWidget";
import { useTranslation } from "react-i18next";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "40px",
    width: "100%",
    minWidth: "100%",
    margin: "10px 0",
    minHeight: "40px",
    fontSize: "14px",
    fontWeight: "400",
    borderRadius: "8px",
  }),
};

let initialValues = {
  assignee: "",
  deadline: "",
  time: "",
  name: "",
  priority: "",
  description: "",
  director_accepted: false,
};

export default function PermissionConfirmation({ cancelCallback, showModal }) {
  const { staffs_list, updateId, loadItems, cardDetails } = useTrelloStore();
  const { setLoader } = useLoaderStore();
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const { t } = useTranslation();

  useEffect(() => {
    formik.resetForm();
    for (const [key, value] of Object.entries(updateId ?? cardDetails ?? {})) {
      if (key === "assignee") {
        if (value?.value == "-") {
          formik.setFieldValue("assignee", null);
        } else
          formik.setFieldValue(
            "assignee",
            options?.find((item) => item.value == value?.value)
          );
      } else if (key === "priority") {
        formik.setFieldValue("priority", value?.value);
      } else if (key === "deadline") {
        formik.setFieldValue("deadline", value === null ? "" : value);
      } else if (key === "description") {
        formik.setFieldValue("description", value === null ? "" : value);
      } else if (key === "name") {
        formik.setFieldValue("name", value === null ? "" : value);
      } else formik.setFieldValue(key, value === null ? "" : value);
    }
  }, [updateId, cardDetails]);

  const validationSchema = Yup.object().shape({
    assignee: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable()
      .test("required", t("Xodim tanlansin"), function (value) {
        if (
          updateId !== null &&
          (value === null || value === "" || value.value === undefined)
        ) {
          return false;
        }
        return true;
      }),
    deadline: Yup.string().test(
      "required",
      t("Muddatini belgilang"),
      function (value) {
        if (
          updateId !== null &&
          (value === null || value === "" || value === undefined)
        ) {
          return false;
        }
        return true;
      }
    ),
    time: Yup.string().test(
      "required",
      t("Vaqtni belgilang"),
      function (value) {
        if (
          updateId !== null &&
          (value === null || value === "" || value === undefined)
        ) {
          return false;
        }
        return true;
      }
    ),
    director_accepted: Yup.boolean(),
    description: Yup.string(),
    name: Yup.string(),
    priority: Yup.string(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      if (!cardDetails && !updateId) {
        await saveTask(values);
      } else await updateTask(values);
      setLoading(false);
    },
  });

  const saveTask = useCallback(
    async (values) => {
      const formData = { ...values, ...{ assignee: values.assignee.value } };
      const { status, nonFieldErrors } = await TrelloRepository.saveTask(
        formData
      );
      if (status) {
        toastSuccess(nonFieldErrors);
        cancelCallback();
        formik.resetForm();
        setLoader(true);
        await loadItems();
        setLoader(false);
      } else {
        toastError(nonFieldErrors);
      }
    },
    [formik.values]
  );

  const updateTask = useCallback(
    async (values) => {
      delete values.status;
      const formData = {
        ...values,
        ...{ assignee: values.assignee?.value, status: "to_do" },
      };
      const cardUpdateData = {
        ...values,
        ...{ assignee: values.assignee?.value },
      };

      const { status, nonFieldErrors } = await TrelloRepository.updateTask(
        cardDetails ? cardUpdateData : formData,
        cardDetails ? cardDetails.id : updateId?.id
      );
      if (status) {
        toastSuccess(nonFieldErrors);
        cancelCallback();
        formik.resetForm();
        setLoader(true);
        await loadItems();
        setLoader(false);
      } else {
        toastError(nonFieldErrors);
      }
    },
    [formik.values]
  );

  const deleteTask = async (id) => {
    const { data, status, nonFieldErrors } = await TrelloRepository.deleteTask(
      id
    );
    if (status) {
      toastSuccess(t("Topshiriq o'chirildi"));
      await loadItems();
      cancelBtn();
    } else toastError(nonFieldErrors);
  };

  const cancelBtn = () => {
    cancelCallback();
    setLoading(false);
    formik.resetForm();
  };

  const options = useMemo(() => {
    return (
      staffs_list?.map((item) => ({
        label: item.fullname,
        value: item.id,
      })) || []
    );
  }, [staffs_list]);

  const handleChecked = (e) => {
    formik.setFieldValue("director_accepted", e.target.checked);
  };

  return (
    <div
      id="modal"
      className={showModal ? "modal modal-select active" : "modal modal-select"}
    >
      <form
        onClick={(e) => e.stopPropagation()}
        onSubmit={formik.handleSubmit}
        action=""
        className="modal-wrapper trello-modal"
      >
        <div className="modal-inner blue_border">
          {updateId ? (
            <h4 className="modal__title blue_background">
              {t("Topshiriqni joylash")}
            </h4>
          ) : (
            <h4 className="modal__title blue_background">
              {t("Topshiriqni kiritish")}
            </h4>
          )}
          <div className="modal-select__box remove_staff_modal">
            {formik.errors.assignee ? (
              <label className="trello-error-massage">
                {formik.errors.assignee.label}
              </label>
            ) : (
              <label htmlFor="login">{t("Xodimlar")}</label>
            )}
            <Select
              classNamePrefix="select"
              styles={customStyles}
              isClearable
              options={options ?? []}
              name="assignee"
              value={formik.values.assignee || null}
              onChange={(option) => {
                formik.setFieldValue("assignee", option ?? "");
              }}
            />
          </div>
          <div className="modal-select__box remove_staff_modal">
            {formik.touched.priority && formik.errors.priority ? (
              <label className="trello-error-massage">
                {formik.errors.priority}
              </label>
            ) : (
              <label htmlFor="login">{t("Topshiriq holati")}</label>
            )}
            <ColorToggleButton formik={formik} />
          </div>
          <div className="modal-select__box remove_staff_modal">
            {formik.touched.name && formik.errors.name ? (
              <label className="trello-error-massage">
                {formik.errors.name}
              </label>
            ) : (
              <label htmlFor="name">{t("Topshiriq nomi")}</label>
            )}
            <input
              className="trello-input"
              name="name"
              id="name"
              value={formik.values.name || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="text"
              autoComplete="off"
            />
          </div>
          <div className="modal-select__box remove_staff_modal">
            <label htmlFor="">{t("Batafsil izoh")}</label>
            <textarea
              className="trello-input"
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
              onBlur={formik.handleBlur}
            ></textarea>
          </div>
          <div className="modal-select__box remove_staff_modal">
            {formik.errors.deadline ? (
              <label className="trello-error-massage ">
                {formik.errors.deadline}
              </label>
            ) : (
              <label htmlFor="active">{t("Muddati")}</label>
            )}
            <input
              className="trello-input"
              id="active"
              name="deadline"
              type="date"
              onChange={formik.handleChange}
              value={formik.values.deadline}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="modal-select__box remove_staff_modal">
            {formik.errors.time ? (
              <label className="trello-error-massage ">
                {formik.errors.time}
              </label>
            ) : (
              <label htmlFor="time">{t("Vaqti")}</label>
            )}
            <input
              className="trello-input"
              id="time"
              name="time"
              type="time"
              onChange={formik.handleChange}
              value={formik.values.time}
              onBlur={formik.handleBlur}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <FormControlLabel
              labelPlacement="end"
              label={t("Direktor qabul qildi")}
              control={
                <Checkbox
                  id="director_accepted"
                  name="director_accepted"
                  checked={formik.values.director_accepted}
                  onChange={handleChecked}
                  onBlur={formik.handleBlur}
                />
              }
            />
            <UserPermissionWidget
              hasPermission={user?.permissions?.delete_task}
            >
              {cardDetails && (
                <Button
                  onClick={() => deleteTask(cardDetails.id)}
                  variant="outlined"
                  color="error"
                >
                  {t("O'chirish")}
                </Button>
              )}
            </UserPermissionWidget>
          </div>
        </div>
        <div className="input-buttons">
          <button
            id="modalClose"
            type="button"
            className="input-btn"
            onClick={() => cancelBtn()}
          >
            {t("Bekor qilish")}
          </button>
          <button
            className="input-btn input-btn--submit blue_background"
            type="submit"
            onClick={formik.handleSubmit}
            disabled={loading || !formik.isValid}
          >
            {loading ? (
              <ReactLoading
                type={"spin"}
                color="#ffffff"
                className="delete_loader"
                height={"30px"}
                width={"30px"}
              />
            ) : updateId ? (
              t("Joylash")
            ) : (
              t("Kiritish")
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
