import React from "react";
import { useTranslation } from "react-i18next";

export default function TableHead() {
  const { t } = useTranslation();
  return (
    <div className="build-search_teacher">
      <ul className="attendance-table build-table build-table--bold ">
        <li className="build-table__item">№</li>
        <li className="build-table__item">{t("Yosh guruhi")}</li>
        <li className="build-table__item">{t("Guruhlar soni")}</li>
        <li className="build-table__item">{t("O‘quvchi talabalar")}</li>
        <li className="build-table__item">{t("Faol o’quvchilar")}</li>
        <li className="build-table__item">{t("Sababli dars qoldirganlar")}</li>
        <li className="build-table__item">{t("Sababsiz dars qoldirganlar")}</li>
        <li className="build-table__item">
          {t("Guruhdan chiqarilgan talabalar")}{" "}
        </li>
        <li className="build-table__item">{t("Qora ro’yxatga tushganlar")}</li>
      </ul>
    </div>
  );
}
