import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AdminLayout from "../../../AdminLayout";
import EmptyContent from "../../../Components/Empty-content/EmptyContent";
import ItemsLoader from "../../../Components/Loader/basic loader/ItemsLoader";
import PaginationComponent from "../../../Components/Pagination";
import { useEstimatedSalaryStore } from "../../../Store/CashControlStore/EstimatedSalaryaStore";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import Heading from "./components/Heading";
import ListItem from "./components/ListItem";
import TitleBar from "./components/TitleBar";

export default function EstimatedEmployeesSalary() {
  const {
    results,
    onReload,
    isLoading,
    loadItems,
    setLoading,
    pagination,
    updateParams,
    queryParams,
  } = useEstimatedSalaryStore();
  const { setLoader } = useLoaderStore();

  const pageLoad = useCallback(async () => {
    setLoading(false);
    const queryString = new URLSearchParams({
      ...queryParams,
    }).toString();
    setLoader(true);
    await onReload(queryString);
    setLoader(false);
  }, []);

  useEffect(() => {
    pageLoad();
  }, []);

  const paginateTo = useCallback(
    async (page) => {
      const queryString = new URLSearchParams({
        ...queryParams,
        page: page,
      }).toString();
      setLoading(true);
      updateParams({ page: page });
      await loadItems(queryString);
      setLoading(false);
    },
    [queryParams]
  );

  const { t } = useTranslation();

  return (
    <AdminLayout>
      <TitleBar title={t("Xodimlarga oylik ishlash")} />
      <div className="container">
        <Heading />
        {isLoading ? (
          <ItemsLoader size={70} />
        ) : results?.length > 0 ? (
          results.map((result, index) => {
            return <ListItem key={index} result={result} index={index} />;
          })
        ) : (
          <EmptyContent />
        )}
        <PaginationComponent
          context={{ pagination: pagination, queryParams: queryParams }}
          callback={paginateTo}
        />
      </div>
    </AdminLayout>
  );
}
