import React from "react";
import { useTranslation } from "react-i18next";
import BlockItem from "../../Components/BlockItem";
import UserPermissionWidget from "../../UserPermissionWidget";

function CashControl({ viewModule }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const { t } = useTranslation();

  return (
    <div className={`home-cards-box ${!viewModule ? "d-none" : ""}`}>
      <h1 className="cards__title">
        <img className="cards__img" src="images/cards-5.png" alt="card box" />
        {t("home_cash_config")}
      </h1>
      <section className="section-page config">
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_student_payment_section}
        >
          <BlockItem
            link="/cash-control/income-cash"
            title={t("home_icome_cash")}
            image="/images/config-img-1.png"
          />
        </UserPermissionWidget>
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_accepted_payments_view}
        >
          <BlockItem
            link="/cash-control/students-payment-transactions"
            title={t("home_icome_cash_history")}
            image="/images/config-img-2.png"
          />
        </UserPermissionWidget>
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_debting_students_list}
        >
          <BlockItem
            link="/cash-control/students-billing"
            title={t("home_list_of_students_in_debt")}
            image="/images/config-img-3.png"
          />
        </UserPermissionWidget>
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_expenditures_page}
        >
          <BlockItem
            link="/cash-control/expenditure-cash"
            title={t("home_expenses")}
            image="/images/config-img-6.png"
          />
        </UserPermissionWidget>
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_expenditures_list}
        >
          <BlockItem
            link="/cash-control/expenditure-list"
            title={t("home_list_of_expenses")}
            image="/images/config-img-2.png"
          />
        </UserPermissionWidget>
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_payrolls_list}
        >
          <BlockItem
            link="/cash-control/salary-list"
            title={t("home_list_of_salary")}
            image="/images/kassa-config-9.png"
          />
        </UserPermissionWidget>
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_calculated_monthly_salary_list}
        >
          <BlockItem
            link="/cash-control/estimated-employees-salary"
            title={t("home_paying_employees")}
            image="/images/kassa-config-8.png"
          />
        </UserPermissionWidget>
      </section>
    </div>
  );
}

export default CashControl;
