import React, { useCallback, useContext } from "react";
import CourseTemplateActions from "../Actions/ConfigActions/CourseTemplateActions";
import { CourseTemplateContext } from "../Contexts/ConfigContext/CourseTemplateContext";

export default function ExportBlock() {
  const { courseTemplate, dispatchCourseTemplate } = useContext(
    CourseTemplateContext
  );

  const openExportBlock = useCallback(
    (e) => {
      e.stopPropagation();
      dispatchCourseTemplate({
        type: CourseTemplateActions.TOGGLE_EXPORT_BLOCK,
      });
    },
    [dispatchCourseTemplate]
  );

  return (
    <ul className="build-export" onClick={openExportBlock}>
      <li className="build-export__item">Export</li>
      <ul
        className={
          courseTemplate.isOpenExportBlock
            ? "build-export__dropdown build-export__dropdown_show"
            : "build-export__dropdown"
        }
      >
        <li>
          <a href="#">
            <img src="/images/word.svg" alt="export icon" />
            Word
          </a>
        </li>
        <li>
          <a href="#">
            <img src="/images/excel.svg" alt="export icon" />
            Excel
          </a>
        </li>
        <li>
          <a href="#">
            <img src="/images/pdf.svg" alt="export icon" />
            Pdf
          </a>
        </li>
      </ul>
    </ul>
  );
}
