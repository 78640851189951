import React from "react";
import { StudentsRegistrationContext } from "../../../../Contexts/RegistrationContex/StudentRegistrationContext";
import { closeStudentInfo } from "../actions";

export default function StudentInfoModal() {
  const { students, dispatchStudents } = React.useContext(
    StudentsRegistrationContext
  );
  const data = students.studentInfo;

  return (
    <div
      id="modal"
      className={
        students.studentInfo
          ? "modal active modal-select"
          : "modal  modal-select"
      }
    >
      <form
        action="#"
        className="modal-wrapper modal-wrapper_huge modal-wrapper-view modal-space"
      >
        <div className="modal-inner modal-inner_view">
          <h4 className="modal__title">
            {data?.first_name} {data?.last_name} {data?.middle_name}{" "}
          </h4>

          <div className="view">
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">Ismi:</div>
                <div className="view-info">{data?.first_name ?? ""}</div>
              </div>
              <div className="view-box">
                <div className="view-title">Bilim darajasi:</div>
                <div className="view-info">{data?.level}</div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">Familyasi:</div>
                <div className="view-info">{data?.last_name}</div>
              </div>
              <div className="view-box">
                <div className="view-title">
                  Talabaga ma’qul o’qish kunlari:
                </div>
                <div className="view-info">
                  {data?.suitable_days_readable[0].name} {data?.suitable_time}
                </div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">Otasining ismi:</div>
                <div className="view-info">{data?.middle_name}</div>
              </div>
              <div className="view-box">
                <div className="view-title">Gruppani yoshga doir holati:</div>
                <div className="view-info">{data?.group_type_readable}</div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">Jinsi:</div>
                <div className="view-info">
                  {data?.gender === 1 ? "Erkak" : "Ayol"}
                </div>
              </div>
              <div className="view-box">
                <div className="view-title">Yashash joyi manzili:</div>
                <div className="view-info">{data?.address}</div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">ID-kodi:</div>
                <div className="view-info">{data?.student_id}</div>
              </div>
              <div className="view-box">
                <div className="view-title">Qabulga yozilgan sanasi:</div>
                <div className="view-info">{data?.registered_date}</div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">Ro’yxatdan o’tgan sana:</div>
                <div className="view-info">{data?.registered_date}</div>
              </div>
              <div className="view-box">
                <div className="view-title">Telefon raqamlari:</div>
                <div className="view-info">{data?.phone_number}</div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">Tug’ilgan sana:</div>
                <div className="view-info">{data?.birth_date}</div>
              </div>
              <div className="view-box">
                <div className="view-title">Login:</div>
                <div className="view-info">{data?.user?.username}</div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">Talabaning yoshi:</div>
                <div className="view-info">{data?.age}</div>
              </div>
              <div className="view-box">
                <div className="view-title">Aloqa:</div>
                <div className="view-info">
                  {data?.connection_status_readable}
                </div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">Taqdim qilgan hujjat turi:</div>
                <div className="view-info">{data?.document_type}</div>
              </div>
              <div className="view-box">
                <div className="view-title">Passport seriyasi va raqami: </div>
                <div className="view-info">
                  {data?.document_series} {data?.document_number}
                </div>
              </div>
            </div>
            <div className="view__item"></div>
          </div>
        </div>

        <div className="input-buttons input-export">
          <div
            onClick={() => closeStudentInfo(dispatchStudents)}
            id="modal-close"
            className="input-btn"
          >
            Ortga qaytish
          </div>
        </div>
      </form>
    </div>
  );
}
