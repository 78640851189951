import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

export default function DocumentsToggle() {
  return (
    <Stack gap="10px" direction="row" flexWrap="wrap" margin="10px 0">
      <Button variant="outlined">Nizomlar</Button>
      <Button variant="outlined">Nizomlar</Button>
      <Button variant="outlined">Nizomlar</Button>
    </Stack>
  );
}
