import DoNotDisturbOffOutlinedIcon from "@mui/icons-material/DoNotDisturbOffOutlined";
import { useSalaryAdvanceListStore } from "../../../../Store/CashControlStore/SalaryAdvanceListStore";
import UserPermissionWidget from "../../../../UserPermissionWidget";
import { useTranslation } from "react-i18next";

export default function ListItem({ item, index }) {
  const { open_context_id } = useSalaryAdvanceListStore();
  const user = JSON.parse(localStorage.getItem("user"));
  const { t } = useTranslation();

  return (
    <div className="list--config">
      <ul className="expenditure-list--grid build-table build-table--light build-table__item">
        <li className="build-table__item">{index + 1}</li>
        <li className="build-table__item">{item.staff}</li>
        <li className="build-table__item">{item.amount} so'm</li>
        <li className="build-table__item">{item.payment_type}</li>
        <li className="build-table__item">{item.payment_mode}</li>
        <li className="build-table__item">{item.branch}</li>
        <li className="build-table__item">{item.cashier}</li>
        <li className="build-table__item">{item.date}</li>
        <li
          className="build-table__item  build-table__item--7"
          onClick={() => {
            useSalaryAdvanceListStore.setState({
              open_context_id: item.id,
            });
          }}
        >
          <UserPermissionWidget
            hasPermission={user?.permissions?.cancel_payroll_transaction}
          >
            <ul
              className={
                open_context_id == item.id
                  ? "build-table__dropdown build-table__dropdown--active staff_menu"
                  : "build-table__dropdown"
              }
            >
              {item.cancelable ? (
                <li
                  className="build-table__dropdown-item btn-edit"
                  onClick={() => {
                    useSalaryAdvanceListStore.setState({
                      deletingId: item.id,
                    });
                  }}
                >
                  <DoNotDisturbOffOutlinedIcon
                    sx={{ marginRight: "10px", fontSize: "22px" }}
                  />
                  {t("Ushbu to'lovni bekor qilish")}
                </li>
              ) : (
                <li className="build-table__dropdown-item expenditure-expired--color">
                  {t("To'lovlarni faqat 1 kun ichida qaytarish mumkin")}
                </li>
              )}
            </ul>
          </UserPermissionWidget>
        </li>
      </ul>
    </div>
  );
}
