import axiosInstance from "../AxiosInstance";
import { axiosInterceptor } from "../AxiosInterceptor";

export default class EmployeeRepository {
  static async getAllStaffs(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    const response = await axiosInterceptor.get(
      "/register/staff/?" + queryString
    );
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data };
    }
    return result;
  }
  static async getAllBranches() {
    let result = {
      status: false,
      data: null,
    };
    const response = await axiosInstance.get("/config/branch/");
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data };
    }
    return result;
  }
  static async addStaff(data) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.post("/register/staff/", data);
      if (response.status === 201) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      if (e.response.data.data) {
        result = {
          ...result,
          nonFieldErrors: e.response.data.data.username?.[0],
        };
      } else
        result = { ...result, nonFieldErrors: "Yangi xodim qo'shib bo'lmadi!" };
    }
    return result;
  }
  static async getStaffTypes() {
    let result = {
      status: false,
      data: null,
    };
    const response = await axiosInstance.get("/auth/roles/");
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data };
    }
    return result;
  }
  static async deleteStaff(id) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInstance.delete(`/register/staff/${id}/`);
      if (response.status === 204) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {}
    return result;
  }
  static async getStaffById(id) {
    let result = {
      status: false,
      data: null,
    };

    const response = await axiosInstance.get(`/register/staff/${id}/`);
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data };
    }
    return result;
  }
  static async updateStaffSalary(data, id) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.patch(
        `/register/staff/${id}/update-salary/`,
        data
      );

      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (error) {
      result = {
        ...result,
        nonFieldErrors: "Xodim turini yangilab bo'lmadi!",
      };
    }
    return result;
  }
  static async updateStaff(data, id) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.patch(
        `/register/staff/${id}/`,
        data
      );

      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (error) {
      result = {
        ...result,
        nonFieldErrors: "Xodim turini yangilab bo'lmadi!",
      };
    }
    return result;
  }
  static async uploadFile(data) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.post(
        "/register/staff/upload/",
        data
      );
      if (response.status === 202) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: e.response.data.data.file
          ? e.response.data.data.file[0]
          : "File ni yuklab bo'lmadi!",
      };
    }
    return result;
  }
  static async deleteFile(id) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInstance.delete(`/register/staff/upload/`, {
        data: { id: id },
      });
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: e.response.data.data
          ? e.response.data.data.error
          : "File ni o'chirib bo'lmadi!",
      };
    }
    return result;
  }
  static async getLeavingReasons() {
    let result = {
      status: false,
      data: null,
    };

    const response = await axiosInstance.get(
      `/register/constants/leaving-reasons/`
    );
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data.data };
    }
    return result;
  }
}
