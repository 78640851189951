import React from "react";
import { useTranslation } from "react-i18next";
import { useMonthlyReportsStore } from "../../../../Store/ReportsStore/useMonthlyReports";
import TableHead from "./TableHead";

function Table() {
  const { results, total, last_year } = useMonthlyReportsStore();
  const { t } = useTranslation();

  return (
    <table border={"1px"} className="reports-table">
      <TableHead />
      <tbody>
        <tr>
          <td colSpan={8} style={{ textAlign: "center", fontSize: "18px" }}>
            {t("Yil boshiga nisbatan qoldiqlar")}
          </td>
          <td style={{ textAlign: "center", fontSize: "18px" }}>
            {last_year.cash_left}
          </td>
          <td style={{ textAlign: "center", fontSize: "18px" }}>
            {last_year.terminal_left}
          </td>
          <td style={{ textAlign: "center", fontSize: "18px" }}>
            {last_year.online_transaction_left}
          </td>
        </tr>
        {results?.map((item, index) => {
          return (
            <tr className={`reports-extra-table-body`}>
              <td>{index + 1}</td>
              <td style={{ textAlign: "start" }}>{item.month_name}</td>
              <td>{item?.cash_payments_in} </td>
              <td>{item?.terminal_payments_in} </td>
              <td>{item?.online_transaction_payments_in} </td>
              <td>{item?.cash_expenditures_out} </td>
              <td>{item?.terminal_expenditures_out} </td>
              <td>{item?.online_transaction_expenditures_out} </td>
              <td>{item?.cash_left} </td>
              <td>{item?.terminal_left} </td>
              <td>{item?.online_transaction_left} </td>
            </tr>
          );
        })}
        <tr style={{ background: "orange" }}>
          <td colSpan={2}>{t("Jami")}</td>
          <td>{total?.cash_payments_in} </td>
          <td>{total?.terminal_payments_in} </td>
          <td>{total?.online_transaction_payments_in} </td>
          <td>{total?.cash_expenditures_out} </td>
          <td>{total?.terminal_expenditures_out} </td>
          <td>{total?.online_transaction_expenditures_out} </td>
          <td>{total?.cash_left} </td>
          <td>{total?.terminal_left} </td>
          <td>{total?.online_transaction_left} </td>
        </tr>
      </tbody>
    </table>
  );
}

export default Table;
