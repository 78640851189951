import React from "react";
import { useTranslation } from "react-i18next";
import BackButton from "./BackButton";

function TitleBar({
  title,
  createAction = null,
  groupBtns = null,
  permission,
}) {
  const { t } = useTranslation();
  return (
    <div className="build-title">
      <div className="title_bar_item">
        <BackButton />
        <h3 className="page-title">{title}</h3>
      </div>
      <div className="title_bar_btns">
        {groupBtns && groupBtns}
        {createAction && (
          <button
            className={`modalBtn enter ${!permission ? "none-create-btn" : ""}`}
            onClick={createAction}
          >
            {t("add_btn")}
          </button>
        )}
      </div>
    </div>
  );
}
export default React.memo(TitleBar);
