import { useTranslation } from "react-i18next";
import { useRoomAvailabilityStore } from "../../../../Store/GroupManagement/RoomAvailabilityStore";

export default function TableHeading() {
  const { data } = useRoomAvailabilityStore();
  const { t } = useTranslation();

  return (
    <div className="buildingJournal-head">
      <div className="buildingJournal-head__item stu-num"></div>
      <div className="buildingJournal-head__item room-title">
        {t("Bino va xonalar")}
      </div>
      <div className=" week-days">
        <div className="buildingJournal-head__item">{t("Dushanba")}</div>
        <div className="for-contain">
          {data?.availability?.[0]?.rooms?.[0]?.days &&
            data?.availability?.[0]?.rooms?.[0]?.days?.["1_3_5"].map(
              (item, index) => {
                return <span key={index}></span>;
              }
            )}
        </div>
      </div>
      <div className=" week-days">
        <div className="buildingJournal-head__item">{t("Seshanba")}</div>
        <div className="for-contain">
          {data?.availability?.[0]?.rooms?.[0]?.days &&
            data?.availability?.[0]?.rooms?.[0]?.days?.["1_3_5"].map(
              (item, index) => {
                return <span key={index}></span>;
              }
            )}
        </div>
      </div>
    </div>
  );
}
