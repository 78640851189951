import { toastError } from "../../../Helpers/toasts";
import TeacherAvailabilityRepository from "../../../Repositories/GroupManagement/TeacherAvailabilityRepsoitory";
import { useTeacherAvailabilityStore } from "../../../Store/GroupManagement/TeacherAvailabilityStore";

export const onReload = async (queryString) => {
  useTeacherAvailabilityStore.setState({
    data: null,
    lessonStyles: null,
    queryParams: { lesson_style: 1 },
  });
  const { data, status, nonFieldErrors } =
    await TeacherAvailabilityRepository.getTeacherAvailability(queryString);
  if (status) {
    useTeacherAvailabilityStore.setState({
      data: data,
      lessonStyles: data.lesson_styles,
    });
  }
  toastError(nonFieldErrors);
};
export const loadItems = async (queryString) => {
  const { data, status, nonFieldErrors } =
    await TeacherAvailabilityRepository.getTeacherAvailability(queryString);
  if (status) {
    useTeacherAvailabilityStore.setState({
      data: data,
    });
  }
  toastError(nonFieldErrors);
};
