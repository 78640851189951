import axiosInstance from "../AxiosInstance";
import { axiosInterceptor } from "../AxiosInterceptor";

export default class StudentAttachmentRepository {
  static async getAllStudents(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    const response = await axiosInterceptor.get(
      "/register/student/?" + queryString
    );
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data };
    }
    return result;
  }
  static async getGroupStudents(id) {
    let result = {
      status: false,
      data: null,
    };
    const response = await axiosInterceptor.get(
      `/management/group/${id}/?with_students=true`
    );
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data };
    }
    return result;
  }
  static async getAllBranches() {
    let result = {
      status: false,
      data: null,
    };

    const response = await axiosInstance.get("/config/branch/");
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data };
    }
    return result;
  }
  static async getAllStaffs(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    const response = await axiosInterceptor.get(
      "/register/staff/?" + queryString
    );
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data };
    }
    return result;
  }
}
