import React, { useCallback, useEffect } from "react";
import { useAttendanceStore } from "../../../../Store/AttendanceFormStore/AttendanceForm";
import { saveDiscount } from "../actions";
import ReactLoading from "react-loading";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getAttendanceListWithParam } from "../actions";
import { useLoaderStore } from "../../../../Store/Loader/LoaderStore";
import UserPermissionWidget from "../../../../UserPermissionWidget";

function DiscountModal({ studentId }) {
  const {
    discountId,
    data,
    discounts,
    updateAttendanceDates,
    updateDiscounts,
  } = useAttendanceStore();
  const { setLoader } = useLoaderStore();
  const [loader, setSubmitLoader] = useState(false);
  const Id = useParams();
  const groupId = Id.groupId;

  useEffect(() => {
    updateDiscounts(data?.discounts?.values?.[discountId]);
  }, [discountId]);

  const handleChange = useCallback(
    (e, key) => {
      useAttendanceStore.setState((state) => ({
        discounts: { ...state.discounts, [key]: e.target.value },
      }));
    },
    [discountId]
  );

  const cancelHandler = () => {
    useAttendanceStore.setState({
      discountId: null,
      discounts: {},
    });
    setSubmitLoader(false);
  };

  const handleSubmit = async () => {
    if (discounts !== {}) {
      setSubmitLoader(true);
      const discountData = {
        student: studentId,
        lesson_month: data?.meta?.lesson_month.id,
        discounts: discounts,
      };
      const status = await saveDiscount(discountData);
      if (status) {
        setLoader(true);
        await getAttendanceListWithParam(
          new URLSearchParams({
            sub_group: groupId,
            lesson_month: data?.meta?.lesson_month.month,
          }),
          updateAttendanceDates
        );
        setLoader(false);
      }
      setSubmitLoader(false);
    }
  };

  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <div
      id="modal"
      className={
        discountId === studentId
          ? "modal modal-select active"
          : "modal modal-select"
      }
    >
      <div className="attendanceDiscountModal">
        <h1 className="discountTitle">O'quvchiga chegirma kiritish</h1>
        <div className="discountContainer">
          {data?.discounts?.rows?.map((item) => {
            return (
              <div key={item.value} className="discountInput">
                <label htmlFor={item.value}>{item.name}</label>
                <input
                  placeholder="%"
                  type="number"
                  id={item.value}
                  value={discounts?.[item.value]}
                  onChange={(e) => handleChange(e, item.value)}
                />
              </div>
            );
          })}
        </div>
        <div className="discountBtns">
          <UserPermissionWidget
            hasPermission={user?.permissions?.give_discount}
          >
            <button
              type="submit"
              className="saveBtn"
              disabled={loader}
              onClick={() => handleSubmit()}
            >
              {loader ? (
                <ReactLoading
                  type={"spin"}
                  color="#ffffff"
                  className="delete_loader"
                  height={"20px"}
                  width={"20px"}
                />
              ) : (
                "Saqlash"
              )}
            </button>
          </UserPermissionWidget>
          <button type="button" className="cancelBtn" onClick={cancelHandler}>
            Bekor qilish
          </button>
        </div>
      </div>
    </div>
  );
}
export default React.memo(DiscountModal);
