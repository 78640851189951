import { Box, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import AdminLayout from "../../../AdminLayout";
import EmptyContent from "../../../Components/Empty-content/EmptyContent";
import ItemsLoader from "../../../Components/Loader/basic loader/ItemsLoader";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import { useProfileConfigStore } from "../../../Store/ProfileConfiguration/ProfileConfigStore";
import { onReload } from "./actions";
import SimpleAccordion from "./components/LessonAccardion";
import FilterSelects from "./components/ToggleButton";

const btns = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  margin: "10px 0",
};

export default function LessonManuals() {
  const { queryParams, updateQueryParams, materials, isLoadingMaterials } =
    useProfileConfigStore();
  const { setLoader } = useLoaderStore();
  const { month } = useParams();

  useEffect(() => {
    const queryString = new URLSearchParams({
      month: month,
    });
    updateQueryParams(parseInt(month));
    pageLoad(queryString);
  }, []);

  const pageLoad = async (queryString) => {
    setLoader(true);
    await onReload(queryString);
    setLoader(false);
  };

  return (
    <AdminLayout>
      <div className="container">
        <Box sx={btns}>
          <FilterSelects />
          {isLoadingMaterials ? (
            <ItemsLoader size={70} />
          ) : materials?.length > 0 ? (
            <SimpleAccordion />
          ) : (
            <EmptyContent />
          )}
        </Box>
      </div>
    </AdminLayout>
  );
}
