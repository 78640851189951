import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputField from "../../../../Components/InputField";
import PhotoUpdate from "./PhotoUpdate";
import SaveButton from "./SaveButton";
import { useProfileConfigStore } from "../../../../Store/ProfileConfiguration/ProfileConfigStore";
import { updatePassword } from "../actions";
import { useState } from "react";

export default function ProfileRight() {
  const { profile_details } = useProfileConfigStore();
  const [isLoading, setLoading] = useState(false);

  const initialValues = {
    old_password: "",
    new_password: "",
    again_password: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      new_password: Yup.string()
        .min(8, "Kamida 8 ta belgidan iborat parol kiriting!")
        .required("Xodim uchun parol kiriting!"),
      old_password: Yup.string()
        .min(8, "Kamida 8 ta belgidan iborat parol kiriting!")
        .required("Yangi parol kiriting!"),
      again_password: Yup.string()
        .oneOf([Yup.ref("new_password"), null], "Mos tushmadi!")
        .required("Yangi parolni tasdiqlang!"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const data = {
        old_password: values.old_password,
        new_password: values.new_password,
      };
      await updatePassword(data);
      setLoading(false);
    },
  });

  return (
    <>
      <div className="personalInfo">
        <div className="transfer-info__item">
          <div className="transfer-infos">Ismi :</div>
          <div className="transfer-infos">{profile_details?.first_name}</div>
        </div>
        <div className="transfer-info__item">
          <div className="transfer-infos">Familyasi :</div>
          <div className="transfer-infos">{profile_details?.last_name}</div>
        </div>
        <div className="transfer-info__item">
          <div className="transfer-infos">Yoshi :</div>
          <div className="transfer-infos">{profile_details?.age}</div>
        </div>
      </div>
      <div className="passwordContainer">
        <PhotoUpdate />
        <InputField name="old_password" label="Eski parol" formik={formik} />
        <InputField name="new_password" label="Yangi parol" formik={formik} />
        <InputField
          name="again_password"
          label="Parolni tasdiqlash"
          formik={formik}
        />
        <SaveButton isloading={isLoading} formik={formik} />
      </div>
    </>
  );
}
