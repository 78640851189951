import { axiosInterceptor } from "../AxiosInterceptor";

export default class useRefundRepository {
  static async getStudentShortLists(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        "/finance/students/?" + queryString
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      if (e.response.data.data.student) {
        result = {
          ...result,
          nonFieldErrors: e.response.data.data.student,
        };
      } else result = { ...result, nonFieldErrors: "Ma'lumot olib bo'lmadi!" };
    }
    return result;
  }
  static async getStudentInfo(studentId) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        `/finance/students/${studentId}/details/`
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      if (e.response.data.data.student) {
        result = {
          ...result,
          nonFieldErrors: e.response.data.data.student,
        };
      } else result = { ...result, nonFieldErrors: "Ma'lumot olib bo'lmadi!" };
    }
    return result;
  }
  static async getStudentDetail(queryString) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        "/finance/payments/return/?" + queryString
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      result = { ...result, nonFieldErrors: "Ma'lumot olib bo'lmadi!" };
    }
    return result;
  }
  static async expenditure(data) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.post(
        `/finance/payments/return/`,
        data
      );
      if (response.status === 200) {
        result = {
          ...result,
          status: response.data.ok,
          nonFieldErrors: response.data.ok
            ? response.data.message
            : response.data.error,
        };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: "Saqlab bo'lmadi",
      };
    }
    return result;
  }
}
