import React from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { EmployeeRegistrationContext } from "../../../../Contexts/RegistrationContex/EmployeeRegistrationContext";

const List = styled.ul`
  display: grid;
  grid-template-columns:
    385px repeat(${(props) => props.categoryFiltered?.length}, 170px)
    30px;

  @media (max-width: 1600px) {
    grid-template-columns:
      380px
      repeat(${(props) => props.categoryFiltered?.length}, 170px) 30px;
  }
  @media (max-width: 660px) {
    grid-template-columns:
      15px 80px repeat(${(props) => props.categoryFiltered?.length}, 100px)
      30px;
  }
`;
export default function TableHead() {
  const { employee, dispatchEmployee } = useContext(
    EmployeeRegistrationContext
  );
  const categoryFiltered = employee.categoryList.filter(
    (element) => element.isOpenCategory === true
  );
  const { t } = useTranslation();
  return (
    <List
      className="group-table build-table build-table--bold sticky_build_search_employee"
      categoryFiltered={categoryFiltered}
    >
      <li className="build-table__item  sticky_table">
        <ul className="employee_item">
          <li className="build-table__item ">№</li>
          <li className="build-table__item">{t("thead_title")}</li>
          <li className="build-table__item">{t("thead_id")}</li>
        </ul>
      </li>
      {employee.categoryList.map((element, index) => {
        return (
          <li
            key={index}
            className={element.isOpenCategory ? "build-table__item" : "d-none"}
          >
            {t(element.name)}
          </li>
        );
      })}
      <li className="build-table__item  build-table__item--7"></li>
    </List>
  );
}
