import React from "react";
import { useTranslation } from "react-i18next";

export default function TableHead() {
  const { t } = useTranslation();
  return (
    <ul className="transferJournal-table build-table build-table--bold ">
      <li className="build-table__item">№</li>
      <li className="build-table__item">{t("O‘quvchilarning F.I.Sh")}</li>
      <li className="build-table__item">{t("ID Kodi")}</li>
      <li className="build-table__item">{t("Sanasi")}</li>
      <li className="build-table__item">{t("Yoshi")}</li>
      <li className="build-table__item ">{t("Avvalgi guruh raqami")} </li>
      <li className="build-table__item ">{t("Avvalgi guruh darajasi")}</li>
      <li className="build-table__item ">{t("Avvalgi guruh oy")}</li>
      <li className="build-table__item ">
        {t("Avvalgi o‘qituvchining F.I.Sh")}
      </li>
      <li className="build-table__item ">{t("Yangi guruh raqami")}</li>
      <li className="build-table__item ">{t("Yangi guruh darajasi")}</li>
      <li className="build-table__item ">{t("Yangi guruh oy")}</li>
      <li className="build-table__item ">{t("Yangi o‘qituvchining F.I.Sh")}</li>
      <li className="build-table__item">{t("Izoh")}</li>
    </ul>
  );
}
