import { useTranslation } from "react-i18next";

export default function ListItem({ item, index }) {
  const { t } = useTranslation();

  return (
    <div className="list--config">
      <ul className="debet--grid build-table build-table--light build-table__item">
        <li className="build-table__item">{index + 1}</li>
        <li className="build-table__item">{item.fullname}</li>
        <li className="build-table__item">{item.group}</li>
        <li className="build-table__item">{item.branch}</li>
        <li className="build-table__item">{item.debt}</li>
        <li className="build-table__item">
          {item.debting_month}-{t("oy")}
        </li>
        <li className="build-table__item">{item.teacher}</li>
        <li className="build-table__item">{item.last_transaction_date}</li>
        <li className="build-table__item">{item.phone}</li>
      </ul>
    </div>
  );
}
