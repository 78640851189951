import { createContext, useReducer } from "react";
import { courseTemplateReducer } from "../../Reducers/ConfigReducers/CourseTemplateReducers";

export const CourseTemplateContext = createContext();

const initialState = {
  data: [],
  branches: [],
  subjects: [],
  lessonStyles: [],
  lessonTypes: [],
  groupTypes: [],
  lessonDays: [],
  item: {},
  isDeleteModalOpen: false,
  editingId: null,
  deletingId: null,
  isModalOpen: false,
  isCreating: false,
  isEditing: false,
  openContextMenuId: null,
  isOpenExportBlock: false,
  queryParams: {
    page: 1,
  },
  listId: null,
  isShowDeleteLoader: false,
};

const CourseTemplateProvider = (props) => {
  const [courseTemplate, dispatchCourseTemplate] = useReducer(
    courseTemplateReducer,
    {
      ...initialState,
    }
  );

  return (
    <CourseTemplateContext.Provider
      value={{ courseTemplate, dispatchCourseTemplate }}
    >
      {props.children}
    </CourseTemplateContext.Provider>
  );
};

export default CourseTemplateProvider;
