import { Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React, { useCallback } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { filterGroups, openFilterList } from "../actions";
import StudentAttachmentAction from "../../../Actions/StudentAttachment/StudentAttachmentAction";
import { StudentAttachmentContext } from "../../../Contexts/StudentAttachmentContext/StudentAttachmentContext";
import { useContext } from "react";
import { LoaderContext } from "../../../Contexts/LoaderContext";
import { useFormik } from "formik";

export default function SubHeaderComponent({
  connectionState,
  searchState: { search, setSearch },
  checkboxFirstState: { checkboxFirst, setCheckboxFirst },
  checkboxSecondState: { checkboxSecond, setCheckboxSecond },
}) {
  const { studentAttachment, dispatchStudentAttachment } = useContext(
    StudentAttachmentContext
  );
  const { dispatchLoader } = useContext(LoaderContext);

  const handleConnectionStatus = React.useCallback(
    async (e) => {
      dispatchStudentAttachment({
        type: StudentAttachmentAction.ADD_QUERY_PARAM,
        payload: {
          connection_status:
            studentAttachment.queryParams.connection_status == e.target.value
              ? ""
              : e.target.value,
          page: 1,
        },
      });
      await filterGroups(
        {
          ...studentAttachment?.queryParams,
          connection_status:
            studentAttachment.queryParams.connection_status == e.target.value
              ? ""
              : e.target.value,
          page: 1,
        },
        dispatchStudentAttachment,
        dispatchLoader
      );
    },
    [studentAttachment.queryParams]
  );

  const hasGroupAction = useCallback(
    async (check) => {
      dispatchStudentAttachment({
        type: StudentAttachmentAction.ADD_QUERY_PARAM,
        payload: {
          has_groups: check == true ? false : true,
          page: 1,
        },
      });
      await filterGroups(
        {
          ...studentAttachment?.queryParams,
          has_groups: check === true ? false : true,
          page: 1,
        },
        dispatchStudentAttachment,
        dispatchLoader
      );
    },
    [studentAttachment.queryParams]
  );

  const searchAction = useCallback(
    async (payload) => {
      dispatchStudentAttachment({
        type: StudentAttachmentAction.ADD_QUERY_PARAM,
        payload: { search_query: payload.term, page: 1 },
      });
      await filterGroups(
        {
          ...studentAttachment?.queryParams,
          search_query: payload.term,
          page: 1,
        },
        dispatchStudentAttachment,
        dispatchLoader
      );
    },
    [studentAttachment.queryParams]
  );

  const formik = useFormik({
    initialValues: {
      term: "",
    },
    onSubmit: (values) => {
      searchAction({
        term: values.term,
      });
    },
  });

  return (
    <div className="sub_header">
      <form action="" onSubmit={formik.handleSubmit}>
        <input
          value={formik.values.term}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="term"
          type="text"
          placeholder="Search here..."
          className="table_search"
        />
      </form>
      <Button
        onClick={() => openFilterList(dispatchStudentAttachment)}
        sx={{ height: "36px" }}
        size="small"
        variant="outlined"
        startIcon={<FilterAltIcon />}
      >
        Filter
      </Button>
      <br />
      <FormGroup row>
        <FormControlLabel
          size="small"
          label="Guruhsizlar"
          control={
            <Checkbox
              size="small"
              checked={
                studentAttachment.queryParams.has_groups === undefined
                  ? false
                  : studentAttachment.queryParams.has_groups === false
                  ? true
                  : false
              }
            />
          }
          onChange={(e) => hasGroupAction(e.target.checked)}
        />
        <FormControlLabel
          size="small"
          control={
            <Checkbox
              size="small"
              checked={studentAttachment.queryParams.connection_status == 1}
            />
          }
          value={1}
          checked={checkboxFirst}
          onChange={(e) => handleConnectionStatus(e)}
          label="Ijobiy aloqa"
        />
        <FormControlLabel
          size="small"
          value={2}
          checkboxSecond={checkboxSecond}
          control={
            <Checkbox
              size="small"
              checked={studentAttachment.queryParams.connection_status == 2}
            />
          }
          onChange={(e) => handleConnectionStatus(e)}
          label="Salbiy aloqa"
        />
      </FormGroup>
    </div>
  );
}
