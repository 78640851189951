import { create } from "zustand";
import { toastError } from "../../Helpers/toasts";
import IncomeCashRepository from "../../Repositories/CashControl/IncomeCashRepository";

export const useIncomeCashStore = create((set, get) => ({
  studentsShortLists: null,
  student_details: null,
  groups: null,
  lesson_month: null,
  transactions_history: null,
  payment_types: null,
  isLoading: false,
  confirmation: false,
  paymentId: null,
  acceptPayment: {
    student: null,
    group: null,
    amount: null,
    payment_type: null,
    discounts: [],
    amountPlaceholder: null,
  },
  updateAcceptPayment: (property, value) => {
    set((prevState) => ({
      acceptPayment: { ...prevState.acceptPayment, [property]: value },
    }));
  },
  setLoading: (status) => {
    set({
      isLoading: status,
    });
  },
  getStudentShortLists: async (queryString) => {
    const { data, status, nonFieldErrors } =
      await IncomeCashRepository.getStudentShortLists(queryString);
    if (status) {
      set({
        studentsShortLists: data,
      });
    } else toastError(nonFieldErrors);
  },

  getListItemDetail: async (id) => {
    const { acceptPayment } = get();
    const { data, status, nonFieldErrors } =
      await IncomeCashRepository.getListItemDetail(id);
    if (status) {
      set({
        student_details: data.student_details,
        groups: data.groups,
        transactions_history: data.transactions_history,
        payment_types: data.payment_types,
        acceptPayment: { ...acceptPayment, student: id },
      });
    } else toastError(nonFieldErrors);
  },
  resetData: () => {
    set({
      studentsShortLists: null,
      student_details: null,
      transactions_history: null,
      payment_types: null,
      groups: null,
      isLoading: false,
      paymentId: null,
      acceptPayment: {
        student: null,
        group: null,
        amount: null,
        payment_type: null,
        discounts: [],
      },
    });
  },
}));
