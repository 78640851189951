import React from "react";
import { useTranslation } from "react-i18next";
import { useAttendanceDetailsStore } from "../../../../Store/AttendanceFormStore/AttendanceDeatilsStore";

export default function TableHeading() {
  const { data } = useAttendanceDetailsStore();
  const { t } = useTranslation();
  return (
    <div className="desk-config details">
      <div className="desk-config__item">№</div>
      <div className="desk-config__item">
        {t("O'quvchining ismi familyasi")}
      </div>
      <div className="desk-config__item">{t("ID raqam")}</div>
      <div className="desk-config__item">{t("Kemlagan o'quvchilar")}</div>
      <div className="card_types">
        {data?.card_types?.numeric?.map((element) => {
          return (
            <div key={element.key} className="desk-config__item card">
              {element.name}
            </div>
          );
        })}
        <div className="desk-config__item card">{t("Uyga vazifalar")}</div>
      </div>
    </div>
  );
}
