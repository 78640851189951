import { useMemo } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import AdminLayout from "../../../AdminLayout";
import TitleBar from "../../../Components/TitleBar";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import { useComparisionStore } from "../../../Store/ReportsStore/ComparisionStore";
import ApexChart from "./components/ColumnChart";
import ExtraTable from "./components/ExtraTable";
import SelectField from "./components/Select";
import StaffsSelectSection from "./components/StaffsSelectSection";

export default function Comparison() {
  const { setLoader } = useLoaderStore();
  const { onReload, score_types, results } = useComparisionStore();

  const pageLoad = useCallback(async () => {
    setLoader(true);
    await onReload();
    setLoader(false);
  }, []);

  useEffect(() => {
    pageLoad();
  }, []);

  const handleExamTypeChange = useCallback(async (e) => {
    useComparisionStore.setState({
      score_type: e.value || null,
    });
  });
  const options = useMemo(() => {
    return (
      score_types?.map((element) => ({
        label: element?.name,
        value: element?.value,
      })) || []
    );
  }, [score_types]);

  const { t } = useTranslation();

  return (
    <AdminLayout>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginRight: "28px",
        }}
      >
        <TitleBar title={t("staff_statistics")} />
        <SelectField
          handleSelectChange={handleExamTypeChange}
          property={t("Imtixon turi")}
          param={options}
        />
      </div>
      <div className="container">
        <StaffsSelectSection />
        {results && (
          <>
            <ApexChart />
            <ExtraTable />
          </>
        )}
      </div>
    </AdminLayout>
  );
}
