import React from "react";
import BackButton from "../../../../Components/BackButton";
import { useStudentsBillingStore } from "../../../../Store/CashControlStore/StudentsBillingStore";
function TitleBar({ title }) {
  const { statistics } = useStudentsBillingStore();
  return (
    <div className="build-title">
      <div className="title_bar_item">
        <BackButton />
        <h3 className="page-title">{title}</h3>
      </div>
      <div className="title_bar_btns">
        <p className="debet-title title--background">
          Jami qarizdor o'quvchilar:{" "}
          <span>
            {String(statistics?.total_debting_students)
              .replace(/ /g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
            ta
          </span>
        </p>
        <p className="debet-title title--background">
          Jami qarizdorlik:{" "}
          <span>
            {String(statistics?.total_debt)
              .replace(/ /g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
            so'm
          </span>
        </p>
      </div>
    </div>
  );
}
export default React.memo(TitleBar);
