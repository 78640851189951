import React, { useContext, useState } from "react";
import LanguageActions from "../../Actions/ComponentsActions/LanguageActions";
import { LanguageContext } from "../../Contexts/LanguageContext";
import { useTranslation } from "react-i18next";

export default function HeaderLanguage() {
  const { language, dispatchLanguage } = useContext(LanguageContext);
  const { i18n } = useTranslation();

  const languageItems = language.other.map((item) => {
    let classes = "language__item c-pointer";
    if (language.isOpen === true) classes += " language-dropdown";
    return (
      <div
        className={classes}
        key={item.code}
        onClick={() => onChangeLang(item)}
      >
        {item.title}
      </div>
    );
  });

  let classes = "language__item";
  if (language.isOpen === true) classes += " active";

  const currentLanguage = (
    <button
      className={classes}
      onClick={() =>
        dispatchLanguage({
          type: LanguageActions.ISOPEN,
          payload: !language.isOpen,
        })
      }
    >
      {language.current.title}
    </button>
  );

  const onChangeLang = (item) => {
    const lang_code = item.code;
    i18n.changeLanguage(lang_code);
    dispatchLanguage({
      type: LanguageActions.CHANGE,
      payload: { item: item },
    });
  };

  return (
    <div className="language">
      {currentLanguage}
      {languageItems}
      <div className="language-arrow"></div>
    </div>
  );
}
