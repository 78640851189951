import { toastError, toastSuccess } from "../../../../Helpers/toasts";
import IncomeCashRepository from "../../../../Repositories/CashControl/IncomeCashRepository";
import { useIncomeCashStore } from "../../../../Store/CashControlStore/IncomeCashStore";
import ColorToggleButton from "./ToggleButton";
import TransactionsHistory from "./TransactionsHistory";
import ConfirmationModal from "../../Components/ConfirmationModal";
import PrintPDF from "./PrintPDF";
import { useTranslation } from "react-i18next";

export default function PaymentSection() {
  const {
    updateAcceptPayment,
    getListItemDetail,
    acceptPayment,
    paymentId,
    confirmation,
    setLoading,
  } = useIncomeCashStore();

  const handleChange = (e) => {
    useIncomeCashStore.setState({
      paymentId: null,
    });
    const inputValue = e.target.value.replace(/ /g, "");
    const formattedValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    updateAcceptPayment("amountPlaceholder", formattedValue);
    updateAcceptPayment("amount", e.target.value.replace(/ /g, ""));
  };

  const handleSubmit = async () => {
    const { status, data, nonFieldErrors } =
      await IncomeCashRepository.savePayment(acceptPayment);
    if (status) {
      toastSuccess(nonFieldErrors);
      setLoading(true);
      await getListItemDetail(acceptPayment.student);
      setLoading(false);
      useIncomeCashStore.setState({
        paymentId: data,
        confirmation: false,
        acceptPayment: {
          ...acceptPayment,
          group: null,
          amount: null,
          payment_type: null,
          discounts: [],
          amountPlaceholder: null,
        },
      });
    } else toastError(nonFieldErrors);
  };
  const { t } = useTranslation();

  const confirmationCallback = () => {
    if (!acceptPayment.group) {
      toastError(t("Guruhni tanlang!"));
    } else if (!acceptPayment.amount) {
      toastError(t("To'lovni kiriting!"));
    } else if (!acceptPayment.lesson_month) {
      toastError(t("To'lovni oyini tanlang!"));
    } else if (!acceptPayment.payment_type) {
      toastError(t("To'lov turini tanlang!"));
    } else {
      useIncomeCashStore.setState({
        confirmation: true,
      });
    }
  };

  const cancelCallback = () => {
    useIncomeCashStore.setState({
      confirmation: false,
    });
  };

  return (
    <div className="transfer__item">
      <div className="transfer-info transfer-info_huge">
        <div className="payment-place">
          <input
            className="expenditure-input income-input"
            type="text"
            placeholder={t("To'lov miqdori")}
            onChange={handleChange}
            value={acceptPayment.amountPlaceholder || ""}
          />
          <ColorToggleButton />
        </div>
        <div className="discountBtns payment-place--space">
          <PrintPDF paymentId={paymentId} />
          <button
            type="submit"
            className="saveBtn income__save--btn"
            onClick={confirmationCallback}
          >
            {t("To'lovni amalga oshirish")}
          </button>
        </div>
      </div>
      <TransactionsHistory />
      <ConfirmationModal
        confirmationCallback={() => handleSubmit()}
        cancelCallback={() => cancelCallback()}
        showModal={confirmation}
        title={t("To'lov amalga oshirilsinmi?")}
      />
    </div>
  );
}
