import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import React from "react";
import { useTranslation } from "react-i18next";
export default function ModalAddBtn({ handleAdd }) {
  const { t } = useTranslation();
  return (
    <div className="modal_add_btn">
      <Button
        onClick={(e) => handleAdd(e)}
        type="button"
        className="modal_icon_container"
        variant="outlined"
        color="primary"
        startIcon={<AddCircleIcon />}
      >
        {t("Guruh tarkibi")}
      </Button>
    </div>
  );
}
