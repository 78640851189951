import { useCallback, useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthenticationActions from "./Actions/AuthActions/AuthenticationActions";
import LanguageActions from "./Actions/ComponentsActions/LanguageActions";
import StudentAssign from "./Components/Student-attachment/StudentAttachment";
import { AuthenticationContext } from "./Contexts/AuthContext/AuthenticationContext";
import { LANGUAGES, LanguageContext } from "./Contexts/LanguageContext";
import AttendanceDetails from "./Pages/Attendance Form/AttendanceDetails/AttendanceDetails";
import AttendanceForm from "./Pages/Attendance Form/AttendanceList/AttendanceForm";
import LoginPage from "./Pages/Authentication/Login/LoginPage";
import EstimatedEmployeesSalary from "./Pages/Cash-control/Estimated-employees-salary/EstimatedEmployeesSalary";
import ExpenditureCash from "./Pages/Cash-control/Expenditure-cash/ExpenditureCash";
import ExpenditureList from "./Pages/Cash-control/Expenditure-list/ExpenditureList";
import IncomeCash from "./Pages/Cash-control/Income-cash/IncomeCash";
import SalaryAdvanceList from "./Pages/Cash-control/Salary-advance-list/SalaryAdvanceList";
import SalaryCalculations from "./Pages/Cash-control/Salary-calculations/SalaryCalculations";
import StudentsBilling from "./Pages/Cash-control/Students-billing/StudentsBilling";
import StudentsPaymentTransactions from "./Pages/Cash-control/Students-payment-transactions/StudentsPaymentTransactions";
import Branches from "./Pages/Config/Branches/Branches";
import CourseTemplate from "./Pages/Config/Course-template/CourseTemplate";
import GroupsAttendanceDivision from "./Pages/Group-management/Groups-attendance-division/GroupsAttendanceDivision";
import GroupsLevelup from "./Pages/Group-management/Groups-levelup/GroupsLevelup";
import RoomsAvailability from "./Pages/Group-management/Rooms-availability/RoomsAvailability";
import StudentTransferJournal from "./Pages/Group-management/Student-transfer-journal/StudentTransferJournal";
import StudentsAcquisition from "./Pages/Group-management/Students-acquisition/StudentsAcquisition";
import StudentsLessonAcquisition from "./Pages/Group-management/Students-lesson-acquisition/StudentsLessonAcquisition";
import StudentsTransfer from "./Pages/Group-management/Students-transfer/StudentsTransfer";
import TeachersAvailability from "./Pages/Group-management/Teachers-availability/TeachersAvailability";
import ArchivedGroups from "./Pages/Groups/Archived-groups/ArchivedGroups";
import ExamDetails from "./Pages/Groups/ExamDetails/ExamDetails";
import ExamSchedule from "./Pages/Groups/ExamSchedule/ExamSchedule";
import MainGroups from "./Pages/Groups/Main-groups/MainGroups";
import Home from "./Pages/Home/Home";
import LessonManuals from "./Pages/Profile-configuration/Lesson-manuals/LessonManuals";
import Profile from "./Pages/Profile-configuration/Personal-profile/Profile";
import EmployeeRegistration from "./Pages/Registration/Employee-registration/EmployeeRegistration";
import StudentRegistration from "./Pages/Registration/Student-registration/StudentRegistration";
import AbsentStudents from "./Pages/Reports/Absent-students/AbsentStudents";
import AgeGroupReports from "./Pages/Reports/AgeGroupReports/AgeGroupReports";
import Comparison from "./Pages/Reports/Comparison-staffs/Comparison";
import DaylyFinance from "./Pages/Reports/Dayly-finance/DaylyFinance";
import DiscountsReports from "./Pages/Reports/Discounts-reports/DiscountsReports";
import ExamFiledStudents from "./Pages/Reports/Exam-failed-students/ExamFiledStudents";
import ExcludedStudents from "./Pages/Reports/Excluded-students-reports/ExcludedStudents";
import FinishedStudents from "./Pages/Reports/Finished-students/FinishedStudents";
import MonthlyReports from "./Pages/Reports/Monthly-reaports/MonthlyReports";
import ReadyReports from "./Pages/Reports/Ready-reports/ReadyReports";
import SpentDiscountReports from "./Pages/Reports/Spented-discount-reports/SpentDiscountReports";
import StudentEarnedCards from "./Pages/Reports/Student-earned-cards/StudentEarnedCards";
import WeeklyReports from "./Pages/Reports/Weekly-reports/WeeklyReports";
import TrelloPage from "./Pages/Trello/Trello";
import PrivateRoute from "./PrivateRoute";
import { ProtectedRoute } from "./ProtectedRoute";
import AuthRepository from "./Repositories/AuthRepository";
import "./custom.css";
import ExpenditureReport from "./Pages/Reports/Expendature-reports/ExpendatureReports";
import TaskBookPage from "./Pages/TextbooksPage/TextBooks/Textbooks";
import TaskBookLists from "./Pages/TextbooksPage/TextBookLists/TextBookLists";
import DashboardPage from "./Pages/Dashboard/DashboardPage";

function App() {
  const { dispatchLanguage } = useContext(LanguageContext);
  const { dispatchAuth } = useContext(AuthenticationContext);

  useEffect(() => {
    configureUser();
    configureLanguage();
    document.body.addEventListener("click", handleClickClosePanelFromOutside);
    return () => {
      document.body.removeEventListener(
        "click",
        handleClickClosePanelFromOutside
      );
    };
  }, []);

  const configureLanguage = useCallback(() => {
    const languageData = localStorage.getItem("language")
      ? JSON.parse(localStorage.getItem("language"))
      : LANGUAGES;
    dispatchLanguage({
      type: LanguageActions.LOADED,
      payload: languageData,
    });
  });

  const configureUser = useCallback(async () => {
    const userData = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;

    const userResponse = await AuthRepository.getMe();

    if (userData === null || userResponse.status === false) {
      localStorage.removeItem("user");
      dispatchAuth({
        type: AuthenticationActions.LOGOUT,
      });
    } else {
      dispatchAuth({
        type: AuthenticationActions.LOGIN,
        payload: { user: userData },
      });
    }
  });

  const handleClickClosePanelFromOutside = (e) => {
    if (!e.target.className.includes("language__item")) {
      dispatchLanguage({
        type: LanguageActions.ISOPEN,
        payload: false,
      });
    }
    if (!e.target.className.includes("profile")) {
      dispatchLanguage({
        type: LanguageActions.IS_OPEN_PROFILE,
        payload: false,
      });
    }
  };
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route element={<PrivateRoute />}>
          <Route path="/profile" element={<Profile />} />
          <Route path="/lesson-manuals/:month" element={<LessonManuals />} />
          <Route
            path="/config/course-template/"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_course_templates}
                redirectPath="/"
              >
                <CourseTemplate />
              </ProtectedRoute>
            }
          />
          <Route
            path="/config/buildings"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_branches}
                redirectPath="/"
              >
                <Branches />
              </ProtectedRoute>
            }
          />
          <Route path="/" element={<Home />} />
          <Route
            path="/registration/student-registration"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_students}
                redirectPath="/"
              >
                <StudentRegistration />
              </ProtectedRoute>
            }
          />
          <Route
            path="/registration/employee-registration"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_staffs}
                redirectPath="/"
              >
                <EmployeeRegistration />
              </ProtectedRoute>
            }
          />
          <Route
            path="/groups/main-group"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_groups}
                redirectPath="/"
              >
                <MainGroups />
              </ProtectedRoute>
            }
          />
          <Route
            path="/groups/:groupId/attendance/"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_group_attendance}
                redirectPath="/"
              >
                <AttendanceForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/groups/:groupId/attendance/:date"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.update_group_attendance}
                redirectPath="/"
              >
                <AttendanceDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/group/:groupId/assign-students/"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_group_composition}
                redirectPath="/"
              >
                <StudentAssign />
              </ProtectedRoute>
            }
          />
          <Route path="/groups/archived-group" element={<ArchivedGroups />} />
          <Route
            path="/groups/exam-list"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_exams}
                redirectPath="/"
              >
                <ExamSchedule />
              </ProtectedRoute>
            }
          />
          <Route
            path="/groups/:groupId/exam-details/:lessonMonth"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_exam_details}
                redirectPath="/"
              >
                <ExamDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/group-management/group-levelup"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_group_ordinal_months}
                redirectPath="/"
              >
                <GroupsLevelup />
              </ProtectedRoute>
            }
          />
          <Route
            path="/group-management/groups-attendance-division"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_attendance_age_group}
                redirectPath="/"
              >
                <GroupsAttendanceDivision />
              </ProtectedRoute>
            }
          />
          <Route
            path="/group-management/students-transfer"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.move_student_to_other_group}
                redirectPath="/"
              >
                <StudentsTransfer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/group-management/students-transfer-lists"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.students_group_changes}
                redirectPath="/"
              >
                <StudentTransferJournal />
              </ProtectedRoute>
            }
          />
          <Route
            path="/group-management/rooms-availability"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_rooms_availability}
                redirectPath="/"
              >
                <RoomsAvailability />
              </ProtectedRoute>
            }
          />
          <Route
            path="/group-management/teachers-availability"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_teachers__availability}
                redirectPath="/"
              >
                <TeachersAvailability />
              </ProtectedRoute>
            }
          />
          <Route
            path="/group-management/students-lesson-acquisition"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_acquisition_lesson_months}
                redirectPath="/"
              >
                <StudentsLessonAcquisition />
              </ProtectedRoute>
            }
          />
          <Route
            path="/group-management/students-acquisition"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_acquisition_age_group}
                redirectPath="/"
              >
                <StudentsAcquisition />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cash-control/income-cash"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_student_payment_section}
                redirectPath="/"
              >
                <IncomeCash />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cash-control/students-payment-transactions"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_accepted_payments_view}
                redirectPath="/"
              >
                <StudentsPaymentTransactions />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cash-control/students-billing"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_debting_students_list}
                redirectPath="/"
              >
                <StudentsBilling />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cash-control/expenditure-cash"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_expenditures_page}
                redirectPath="/"
              >
                <ExpenditureCash />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cash-control/expenditure-list"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_expenditures_list}
                redirectPath="/"
              >
                <ExpenditureList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cash-control/estimated-employees-salary"
            element={
              <ProtectedRoute
                isAllowed={
                  true
                  // user?.permissions?.view_calculated_monthly_salary_list
                }
                redirectPath="/"
              >
                <EstimatedEmployeesSalary />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cash-control/salary-calculations/:year/:month/:id/details"
            element={
              <ProtectedRoute
                isAllowed={
                  user?.permissions?.view_calculated_monthly_salary_details
                }
                redirectPath="/"
              >
                <SalaryCalculations />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cash-control/salary-list"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_payrolls_list}
                redirectPath="/"
              >
                <SalaryAdvanceList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reports/all-reports"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_recipient_reports}
                redirectPath="/"
              >
                <ReadyReports />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reports/discount-earned-reports"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_discounted_recipients}
                redirectPath="/"
              >
                <DiscountsReports />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reports/discount-spent-reports"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_discounted_recipients}
                redirectPath="/"
              >
                <SpentDiscountReports />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reports/excluded-students"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_removed_from_group}
                redirectPath="/"
              >
                <ExcludedStudents />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reports/absent-students"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_missed_classes_students}
                redirectPath="/"
              >
                <AbsentStudents />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reports/statistics-staffs"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_staff_statistics}
                redirectPath="/"
              >
                <Comparison />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reports/reports-with-agegroup"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_age_report_groups}
                redirectPath="/"
              >
                <AgeGroupReports />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reports/student-earned-cards"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_active_inactive_students}
                redirectPath="/"
              >
                <StudentEarnedCards />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reports/exam-failed-students"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_students_failed_exams}
                redirectPath="/"
              >
                <ExamFiledStudents />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reports/finished-students"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_completed_course_students}
                redirectPath="/"
              >
                <FinishedStudents />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reports/monthly-finance/"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_monthly_income_reports}
                redirectPath="/"
              >
                <MonthlyReports />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reports/weekly-finance/"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_weekly_income_reports}
                redirectPath="/"
              >
                <WeeklyReports />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reports/daily-finance/"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_daily_income_reports}
                redirectPath="/"
              >
                <DaylyFinance />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reports/expenditure-finance/"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_daily_income_reports}
                redirectPath="/"
              >
                <ExpenditureReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="/trello-tasks"
            element={
              <ProtectedRoute
                isAllowed={user?.permissions?.view_assignments}
                redirectPath="/"
              >
                <TrelloPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/textbooks"
            element={
              <ProtectedRoute isAllowed={true} redirectPath="/">
                <TaskBookPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/textbooks/:id/details"
            element={
              <ProtectedRoute isAllowed={true} redirectPath="/">
                <TaskBookLists />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute isAllowed={user?.permissions?.view_assignments} redirectPath="/">
                <DashboardPage />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
