import { axiosInterceptor } from "../AxiosInterceptor";

export default class TrelloRepository {
  static async getTasks(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        "/register/tasks/?" + queryString
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch {
      result = { ...result, nonFieldErrors: "Ma'lumot olib bo'lmadi" };
    }
    return result;
  }
  static async getCardDetails(cardId) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(`/register/tasks/${cardId}/`);
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch {
      result = { ...result, nonFieldErrors: "Ma'lumot olib bo'lmadi" };
    }
    return result;
  }
  static async saveTask(data) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.post(`/register/tasks/`, data);
      if (response.status === 200) {
        result = {
          ...result,
          status: true,
          nonFieldErrors: "Topshiriq saqlandi",
        };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: "Saqlab bo'lmadi",
      };
    }
    return result;
  }
  static async updateTask(data, id) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.patch(
        `/register/tasks/${id}/`,
        data
      );
      if (response.status === 200) {
        result = {
          ...result,
          status: true,
          nonFieldErrors: "Topshiriq yangilandi",
        };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: "Saqlab bo'lmadi",
      };
    }
    return result;
  }
  static async deleteTask(id) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.delete(
        `/register/tasks/${id}/`
      );
      if (response.status === 200 || 204) {
        result = {
          ...result,
          status: true,
          nonFieldErrors: "Topshiriq o'chirildi",
        };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: "O'chirib bo'lmadi",
      };
    }
    return result;
  }
}
