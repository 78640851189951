import axiosInstance from "../AxiosInstance";
import { axiosInterceptor } from "../AxiosInterceptor";

export default class StudentsRepository {
  static async addStudent(data) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.post("/register/student/", data);
      if (response.status === 201) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      if (e.response.data.data) {
        if (e.response.data.data.username) {
          result = {
            ...result,
            nonFieldErrors: e.response.data.data.username?.[0],
          };
        }
        if (e.response.data.data.non_field_errors)
          result = {
            ...result,
            nonFieldErrors: e.response.data.data.non_field_errors?.[0],
          };
      } else
        result = {
          ...result,
          nonFieldErrors: "Yangi o'quvchi qo'shib bo'lmadi!",
        };
    }
    return result;
  }
  static async getAllStudents(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    const response = await axiosInterceptor.get(
      "/register/student/?" + queryString
    );
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data };
    }
    return result;
  }
  static async getEmptyStaffs(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    const response = await axiosInstance.get(
      `/management/selectables/teachers/?full=true&` + queryString
    );
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data };
    }
    return result;
  }
  static async getAllBranches() {
    let result = {
      status: false,
      data: null,
    };
    const response = await axiosInstance.get("/config/branch/");
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data };
    }
    return result;
  }
  static async deleteStudent(id) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInstance.delete(`/register/student/${id}/`);
      if (response.status === 204) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {}
    return result;
  }
  static async getStudentById(id) {
    let result = {
      status: false,
      data: null,
    };

    const response = await axiosInstance.get(`/register/student/${id}/`);
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data };
    }
    return result;
  }
  static async updateStudent(data, id) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.patch(
        `/register/student/${id}/`,
        data
      );

      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (error) {
      result = {
        ...result,
        nonFieldErrors: "O'quvchi ma'lumotlarini yangilab bo'lmadi!",
      };
    }
    return result;
  }
  static async excludedGroups(id) {
    let result = {
      status: false,
      data: null,
    };

    const response = await axiosInstance.get(
      `/register/student/${id}/excluded-groups/`
    );
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data.groups };
    }
    return result;
  }
  static async inclutionReason() {
    let result = {
      status: false,
      data: null,
    };

    const response = await axiosInstance.get(
      `/register/constants/inclusion-reasons/`
    );
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data.data };
    }
    return result;
  }
  static async includeGroup(data) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.post(
        `/register/student/include/`,
        data
      );

      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: e.response.data.data.error
          ? e.response.data.data.error
          : "Guruhga qo'shib bo'lmadi!",
      };
    }
    return result;
  }
}
