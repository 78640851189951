import { axiosInterceptor } from "../AxiosInterceptor";

export default class ProfileConfigRepository {
  static async getTeacherProfile() {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get("/register/teacher-profile/");
      if (response.status === 200) {
        result = {
          ...result,
          status: true,
          data: response.data,
        };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: e.response.data
          ? e.response.data.data.error
          : "Ma'lumot olib bo'lmadi!",
      };
    }
    return result;
  }
  static async uploadPhoto(formData) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.post(
        "/register/teacher-profile/photo/",
        formData
      );
      if (response.status === 200) {
        result = {
          ...result,
          status: true,
          nonFieldErrors: response.data.message,
        };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: e.response.data
          ? e.response.data.data.error
          : "Rasmni yuklab bo'lmadi!",
      };
    }
    return result;
  }
  static async updatePassword(data) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.post(
        "/register/teacher-profile/password/",
        data
      );
      if (response.status === 200) {
        result = {
          ...result,
          status: true,
          nonFieldErrors: response.data.error ? response.data.error : false,
          successField: response.data.message ? response.data.message : false,
        };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: e.response.data
          ? e.response.data.data.error
          : "Parolni yangilab  bo'lmadi!",
      };
    }
    return result;
  }
  static async getLessonDetails(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        "/materials/lessons/?" + queryString
      );
      if (response.status === 200) {
        result = {
          ...result,
          status: true,
          data: response.data,
        };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: e.response.data
          ? e.response.data.data.error
          : "Ma'lumot olib bo'lmadi!",
      };
    }
    return result;
  }
}
