import React from "react";
import Container from "../Loader";

export default function NextStageTabsLoader() {
  return (
    <div className="stage_tabs_loader">
      <Container size={60} />
    </div>
  );
}
