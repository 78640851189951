import { create } from "zustand";

export const useStudentAcquisitionStore = create((set) => ({
  age_groups: null,
  months: null,
  total_counts: null,
  years: null,
  queryYear: null,
  queryMonth: null,
  
}));
