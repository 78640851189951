import React from "react";
import { Link } from "react-router-dom";

export default function LessonStyleButton({ title, video_lesson }) {
  return (
    <Link to={`/groups/${video_lesson?.id}/attendance/`}>
      <div className="journal-box__item grey-box lessonStyleBtn">{title}</div>
    </Link>
  );
}
