import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useTrelloStore } from "../../../Store/Trello/TrellosStore";

export default function ColorToggleButton({ formik }) {
  const { priorities_list } = useTrelloStore();

  const handleChange = (event, newAlignment) => {
    formik.setFieldValue("priority", newAlignment ?? "");
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={formik.values.priority || ""}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      sx={{ w: "100%", m: "10px 0" }}
      size="small"
      name="priority"
    >
      {priorities_list?.map((item) => {
        return (
          <ToggleButton key={item.value} value={item.value}>
            {item.name}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}
