import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CourseTemplateActions from "../../../Actions/ConfigActions/CourseTemplateActions";
import AdminLayout from "../../../AdminLayout";
import DeleteConfirmation from "../../../Components/DeleteConfirmation";
import EmptyContent from "../../../Components/Empty-content/EmptyContent";
import PaginationComponent from "../../../Components/Pagination";
import TitleBar from "../../../Components/TitleBar";
import { CourseTemplateContext } from "../../../Contexts/ConfigContext/CourseTemplateContext";
import { LoaderContext } from "../../../Contexts/LoaderContext";
import UserPermissionWidget from "../../../UserPermissionWidget";
import {
  cancelDeleteAction,
  createAction,
  deleteAction,
  loadItems,
  onReload,
} from "./actions";
import ListItem from "./components/listItem";
import TableHead from "./components/TableHead";
import CourseTemplateModify from "./CourseTemplateModify";

export default function CourseTemplate() {
  const { courseTemplate, dispatchCourseTemplate } = useContext(
    CourseTemplateContext
  );
  const { dispatchLoader } = useContext(LoaderContext);
  const pageLoad = useCallback(async () => {
    await onReload(courseTemplate, dispatchCourseTemplate, dispatchLoader);
  });

  const paginateTo = useCallback((page) => {
    dispatchCourseTemplate({
      type: CourseTemplateActions.ADD_QUERY_PARAM,
      payload: { page: page },
    });
    loadItems({ page: page }, courseTemplate, dispatchCourseTemplate);
  });

  const handleClickClosePanelFromOutside = (e) => {
    if (!e.target.className.includes("build-table__item--7")) {
      dispatchCourseTemplate({
        type: CourseTemplateActions.CLOSE_CONTEXT_MENU,
      });
    }
    if (
      !e.target.className.includes(
        "build-export__dropdown build-export__dropdown_show"
      )
    ) {
      dispatchCourseTemplate({
        type: CourseTemplateActions.CLOSE_EXPORT_BLOCK,
      });
    }
  };

  useEffect(() => {
    dispatchCourseTemplate({
      type: CourseTemplateActions.CANCEL_BUTTON_CLICKED,
    });
    pageLoad();

    document.body.addEventListener("click", handleClickClosePanelFromOutside);
    return () => {
      document.body.removeEventListener(
        "click",
        handleClickClosePanelFromOutside
      );
    };
  }, [dispatchCourseTemplate, dispatchLoader]);
  const user = JSON.parse(localStorage.getItem("user"));

  const { t } = useTranslation();
  return (
    <AdminLayout>
      <TitleBar
        title={t("config_template_title")}
        createAction={() => createAction(dispatchCourseTemplate)}
        permission={user?.permissions?.create_course_templates}
      />
      <div className="container remove_scroll">
        <TableHead />
        {courseTemplate.data.length ? (
          courseTemplate.data.map((course, index) => {
            return <ListItem course={course} key={course.id} index={index} />;
          })
        ) : (
          <EmptyContent />
        )}
        <PaginationComponent context={courseTemplate} callback={paginateTo} />
      </div>
      <UserPermissionWidget
        hasPermission={user?.permissions?.create_course_templates}
      >
        <CourseTemplateModify />
      </UserPermissionWidget>
      <DeleteConfirmation
        deleteCallback={(e) =>
          deleteAction(
            e,
            courseTemplate,
            dispatchCourseTemplate,
            dispatchLoader
          )
        }
        cancelCallback={() => cancelDeleteAction(dispatchCourseTemplate)}
        showModal={courseTemplate}
      />
    </AdminLayout>
  );
}
