import {
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { useSalaryCalculationStore } from "../../../../Store/CashControlStore/SalaryCalculationStore";

function createData(
  name,
  sub_group_id,
  lesson_month_number,
  calories,
  fat,
  student_paid,
  carbs,
  protein
) {
  return {
    name,
    sub_group_id,
    lesson_month_number,
    calories,
    fat,
    student_paid,
    carbs,
    protein,
  };
}

export default function TeacherModal() {
  const { teacherDetails } = useSalaryCalculationStore();

  const rows = teacherDetails?.months?.map((group) =>
    createData(
      `${group.number}`,
      `${group.sub_group_id}`,
      `${group.lesson_month_number}`,
      `${group.lesson_month}`,
      `${group.students_must_pay} so'm`,
      `${group.student_paid} so'm`,
      `${group.teacher_fee} so'm`,
      `${group.teacher_fee_charged} so'm`
    )
  ) || [createData("Mavjud emas!")];

  if (!teacherDetails) {
    return null;
  }
  const handleClose = () => {
    useSalaryCalculationStore.setState({ teacherDetails: null });
  };

  return (
    <Modal
      open={!!teacherDetails}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          minWidth: 800,
          maxWidth: "90%", // Responsive width
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="start">
                  <Typography variant="body1">
                    O'quvchilar to'lashi kerak:{" "}
                  </Typography>
                  <Typography color="orange" variant="body2">
                    {teacherDetails?.students_must_pay || 0} so'm
                  </Typography>
                </TableCell>
                <TableCell align="start" colSpan={2}>
                  <Typography variant="body1">
                    To'lov qilgan o'quvchilardan ulushi{" "}
                  </Typography>
                  <Typography color="green" variant="body2">
                    {teacherDetails?.teacher_fee_charged} so'm
                  </Typography>
                </TableCell>
                <TableCell align="start" colSpan={2}>
                  <Typography variant="body1">Hissa qo'shgan</Typography>
                  <Typography color="blue" variant="body2">
                    {teacherDetails?.contributed || 0} so'm
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ color: "#000", fontWeight: "bold" }}
                  align="start"
                >
                  Guruhi
                </TableCell>
                <TableCell
                  sx={{ color: "#000", fontWeight: "bold" }}
                  align="start"
                >
                  Oy
                </TableCell>
                <TableCell
                  sx={{ color: "#000", fontWeight: "bold" }}
                  align="start"
                >
                  O'quvchilar to'lashi kerak
                </TableCell>
                <TableCell
                  sx={{ color: "#000", fontWeight: "bold" }}
                  align="start"
                >
                  O'quvchilar to'ladi
                </TableCell>
                <TableCell
                  sx={{ color: "#000", fontWeight: "bold" }}
                  align="start"
                >
                  Kutilayotgan ulush
                </TableCell>
                <TableCell
                  sx={{ color: "#000", fontWeight: "bold" }}
                  align="start"
                >
                  Jamg'arilgan ulush
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align="start">
                    <a
                      href={`/groups/${row?.sub_group_id}/attendance/?month=${row?.lesson_month_number}`}
                      target="_blank"
                      style={{ color: "blue", textDecoration: "underline" }}
                    >
                      {row.name}
                    </a>
                  </TableCell>
                  <TableCell align="start">{row.calories}</TableCell>
                  <TableCell align="start">{row.fat}</TableCell>
                  <TableCell align="start">{row.student_paid}</TableCell>
                  <TableCell align="start">{row.carbs}</TableCell>
                  <TableCell align="start">{row.protein}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
}
