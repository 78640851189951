import { create } from "zustand";
import { toastError } from "../../Helpers/toasts";
import EstimatedSalaryRepository from "../../Repositories/CashControl/EstimatedSalaryRepository";

export const useEstimatedSalaryStore = create((set, get) => ({
  results: null,
  meta: null,
  years_list: null,
  pagination: null,
  isLoading: false,
  queryParams: {
    page: 1,
  },
  setLoading: (status) => {
    set({
      isLoading: status,
    });
  },
  updateParams: (paramItem) => {
    set((prevState) => ({
      queryParams: { ...prevState.queryParams, ...paramItem },
    }));
  },
  onReload: async (queryString) => {
    const { data, status, nonFieldErrors } =
      await EstimatedSalaryRepository.getEstimatedSalary(queryString);
    if (status) {
      set({
        results: data.calculations,
        years_list: data.years_list,
        pagination: data.pagination,
        meta: data.meta,
      });
    } else toastError(nonFieldErrors);
  },
  loadItems: async (queryString) => {
    const { data, status, nonFieldErrors } =
      await EstimatedSalaryRepository.getEstimatedSalary(queryString);
    if (status) {
      set({
        results: data.calculations,
        pagination: data.pagination,
      });
    } else toastError(nonFieldErrors);
  },
}));
