import { memo, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useSalaryCalculationStore } from "../../../../Store/CashControlStore/SalaryCalculationStore";
import Selector from "./Select";
import TeacherModal from "./TeacherModal";

const customStyles = {
  header: {
    style: {
      borderTopLeftRadius: "15px !important",
      borderTopRightRadius: "15px !important",
    },
  },
  rows: {
    style: {
      minHeight: "50px",
      fontSize: "11px",
      pointerEvent: 0,
    },
  },
  columns: {
    style: {},
  },
  table: {
    style: {},
  },
};

function Table() {
  const { staff_list, updateStaffLists, no_change_students, roll_filter } =
    useSalaryCalculationStore();

  useEffect(() => {
    const result = staff_list?.filter((element) => {
      return element.roles?.name
        ?.toLowerCase()
        .match(roll_filter?.toLowerCase());
    });

    useSalaryCalculationStore.setState({
      staff_list: !roll_filter ? no_change_students : result,
    });
  }, [roll_filter]);

  const handleRowClick = (row) => {
    useSalaryCalculationStore.setState({
      teacherDetails: row.extra,
    });
  };

  const handleChange = (value, id, property) => {
    updateStaffLists(value, id, property);
    const staff = staff_list.find((item) => item.id === id);

    if (!staff) return;

    const parseValue = (val) => {
      const parsed = parseFloat(val);
      return isNaN(parsed) ? 0 : parsed / 100;
    };

    // Remove spaces and ensure total_salary is a number
    const total_salary = parseFloat(staff.total_salary.replace(/\s/g, ""));
    if (isNaN(total_salary)) {
      console.error("total_salary is not a number:", staff.total_salary);
      return;
    }

    const bonusMultiplier =
      property === "bonus_percent"
        ? parseValue(value)
        : parseValue(staff.bonus_percent);
    const fineMultiplier =
      property === "fine_percent"
        ? parseValue(value)
        : parseValue(staff.fine_percent);

    // Correct calculation using the sanitized total_salary
    const calculateSalary =
      total_salary +
      total_salary * bonusMultiplier -
      total_salary * fineMultiplier;

    if (!isNaN(calculateSalary)) {
      updateStaffLists(calculateSalary, id, "left_amount");
    }
  };

  const { t } = useTranslation();

  const columns = [
    {
      name: "№",
      maxWidth: "50px",
      minWidth: "0px",
      sortable: true,
      cell: (row, index) => (
        <div className="salary-table__item">{index + 1}</div>
      ),
    },
    {
      name: t("Ismi va Famliyasi"),
      pointerEvent: "none",
      selector: (row) => row.fullname,
      cell: (row) => <div className="salary-table__item">{row.fullname}</div>,
      sortable: true,
    },
    {
      name: t("Jami kiritilgan pullar"),
      selector: (row) => row.total_contributed_money,
      cell: (row) => (
        <div className="salary-table__item">
          {row.total_contributed_money} so'm
        </div>
      ),
      sortable: true,
    },
    {
      name: t("Fixed(O'zgarmas) oylik"),
      cell: (row) => (
        <div className="salary-table__item">{row.fixed_salary} so'm</div>
      ),
      selector: (row) => row.fixed_salary,
      sortable: true,
    },
    {
      name: t("KPI foizi"),
      maxWidth: "80px",
      cell: (row) => (
        <div className="salary-table__item">{row.kpi_percent} %</div>
      ),
      selector: (row) => row.kpi_percent,
      sortable: true,
    },
    {
      name: t("KPI asosida oylik"),
      display: "flex",
      cell: (row) => (
        <div className="salary-table__item">{row.kpi_based_salary} so'm</div>
      ),
      selector: (row) => row.kpi_based_salary,
    },
    {
      name: t("Jami ish haqi"),
      display: "flex",
      cell: (row) => (
        <div className="salary-table__item">{row.total_salary} so'm</div>
      ),
      selector: (row) => row.total_salary,
    },
    {
      name: t("Avans"),
      display: "flex",
      cell: (row) => (
        <div className="salary-table__item">{row.paid_amount} so'm</div>
      ),
      selector: (row) => row.total_salary,
    },
    {
      name: t("Bonus foizi"),
      display: "flex",
      maxWidth: "100px",
      cell: (row) => (
        <input
          className="salary-table__input"
          onChange={(e) =>
            handleChange(e.target.value, row.id, "bonus_percent")
          }
          type="number"
          defaultValue={row.bonus_percent}
          min={0}
        />
      ),
      selector: (row) => row.bonus_percent,
    },
    {
      name: t("Jarima foizi"),
      maxWidth: "100px",
      display: "flex",
      cell: (row) => (
        <input
          className="salary-table__input"
          onChange={(e) => handleChange(e.target.value, row.id, "fine_percent")}
          type="number"
          min={0}
          defaultValue={row.fine_percent}
        />
      ),
      selector: (row) => row.fine_percent,
    },
    {
      name: t("Yakuniy ish haqi"),
      display: "flex",
      cell: (row) => (
        <div className="salary-table__item">{row.left_amount} so'm</div>
      ),
      selector: (row) => row.left_amount,
    },
  ];

  return (
    <div className="salary__table--container">
      <DataTable
        data={staff_list || []}
        highlightOnHover
        pointerOnHover
        columns={columns}
        customStyles={customStyles}
        pagination
        fixedHeader
        onRowClicked={handleRowClick}
        subHeader
        subHeaderComponent={<Selector />}
        subHeaderAlign
      />
      <TeacherModal />
    </div>
  );
}
export default memo(Table);
