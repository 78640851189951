import DoNotDisturbOffOutlinedIcon from "@mui/icons-material/DoNotDisturbOffOutlined";
import { useStudentPaymentTransactions } from "../../../../Store/CashControlStore/StudentPaymentTransactions";
import UserPermissionWidget from "../../../../UserPermissionWidget";
import { useTranslation } from "react-i18next";

export default function ListItem({ student, index }) {
  const { openContextMenu } = useStudentPaymentTransactions();
  const user = JSON.parse(localStorage.getItem("user"));
  const { t } = useTranslation();

  return (
    <div className="list--config">
      <ul
        className="payment--grid build-table build-table--light build-table__item"
        style={{ background: student.bg_color }}
      >
        <li className="build-table__item">{index + 1}</li>
        <li className="build-table__item">{student.student}</li>
        <li className="build-table__item">{student.student_id}</li>
        <li className="build-table__item">{student.amount}</li>
        <li className="build-table__item">{student.branch}</li>
        <li className="build-table__item">{student.group}</li>
        <li className="build-table__item">{student.teachers}</li>
        <li className="build-table__item">{student.cashier}</li>
        <li className="build-table__item">{student.payment_type.name}</li>
        <li className="build-table__item">{student.payment_mode.name}</li>
        <li className="build-table__item">{student.date}</li>
        <li
          className="build-table__item  build-table__item--7"
          onClick={() =>
            useStudentPaymentTransactions.setState({
              openContextMenu: student.id,
            })
          }
        >
          <UserPermissionWidget
            hasPermission={user?.permissions?.cancel_accepted_payment}
          >
            <ul
              className={
                openContextMenu == student.id
                  ? "build-table__dropdown build-table__dropdown--active staff_menu"
                  : "build-table__dropdown"
              }
            >
              {student.cancelable ? (
                <li
                  className="build-table__dropdown-item btn-edit"
                  onClick={() =>
                    useStudentPaymentTransactions.setState({
                      deletingId: student.id,
                    })
                  }
                >
                  <DoNotDisturbOffOutlinedIcon
                    sx={{ marginRight: "10px", fontSize: "22px" }}
                  />
                  {t("Ushbu to'lovni bekor qilish")}
                </li>
              ) : (
                <li className="build-table__dropdown-item expenditure-expired--color">
                  {t("To'lovlarni faqat 1 kun ichida qaytarish mumkin")}
                </li>
              )}
            </ul>
          </UserPermissionWidget>
        </li>
      </ul>
    </div>
  );
}
