import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AdminLayout from "../../../AdminLayout";
import TitleBar from "../../../Components/TitleBar";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import { useTaskBookStore } from "../../../Store/TaskBookStore/TaskBookStore";

export default function TaskBookPage() {
  const { onReload, materials } = useTaskBookStore();
  const { setLoader } = useLoaderStore();

  const pageLoad = useCallback(async () => {
    setLoader(true);
    await onReload();
    setLoader(false);
  }, []);

  useEffect(() => {
    pageLoad();
  }, []);

  const { t } = useTranslation();

  return (
    <AdminLayout>
      <TitleBar title={t("Dars o'tish uchun o'quv qo'llanma")} />
      <div className="container">
        <section className="textbook-list">
          {materials?.map((item) => (
            <Link
              key={item.id}
              to={`/textbooks/${item.id}/details`}
              className="textbook-card"
            >
              <img src="/images/programming.webp" alt="" />
              <h1 className="textbook-card__title">{item.name}</h1>
            </Link>
          ))}
        </section>
      </div>
    </AdminLayout>
  );
}

{
  /* <Link to={"/textbooks/details"} className="textbook-card">
  <img src="/images/english.png" />
  <h1 className="textbook-card__title">Ingliz tili</h1>
</Link>
<Link to={"/textbooks/details"} className="textbook-card">
  <img src="/images/programming.webp" alt="" />
  <h1 className="textbook-card__title">IT dasturlash</h1>
</Link>
<Link to={"/textbooks/details"} className="textbook-card">
  <img src="/images/english.png" />
  <h1 className="textbook-card__title">Ingliz tili</h1>
</Link>
<Link to={"/textbooks/details"} className="textbook-card">
  <img src="/images/programming.webp" alt="" />
  <h1 className="textbook-card__title">IT dasturlash</h1>
</Link>
<Link to={"/textbooks/details"} className="textbook-card">
  <img src="/images/english.png" />
  <h1 className="textbook-card__title">Ingliz tili</h1>
</Link>
<Link to={"/textbooks/details"} className="textbook-card">
  <img src="/images/programming.webp" alt="" />
  <h1 className="textbook-card__title">IT dasturlash</h1>
</Link>
<Link to={"/textbooks/details"} className="textbook-card">
  <img src="/images/english.png" />
  <h1 className="textbook-card__title">Ingliz tili</h1>
</Link> */
}
