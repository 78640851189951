import { useFormik } from "formik";
import * as Yup from "yup";
import { useCallback, useState } from "react";
import ReactLoading from "react-loading";
import StaffModal from "./StaffModule";
import AddStaffModule from "./AddStaffModule";
import ComparisionStaffsRepository from "../../../../Repositories/Reports/ComporitionStaffsRepository";
import { useComparisionStore } from "../../../../Store/ReportsStore/ComparisionStore";
import { toastError } from "../../../../Helpers/toasts";
import { useTranslation } from "react-i18next";

export default function StaffsSelectSection() {
  const [saveLoader, setSaveLoader] = useState(false);
  const { score_type, setResults, setOrderNumber } = useComparisionStore();
  const { t } = useTranslation();

  const initialValues = {
    staffs: [
      {
        teacher_id: "",
        month: "",
        exam_id: "",
        months: [],
        groups: [],
      },
    ],
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      staffs: Yup.array().of(
        Yup.object().shape({
          teacher_id: Yup.string().required(t("Xodimni tanlash!")),
          month: Yup.string().required(t("O'quv oyini tanlang!")),
          exam_id: Yup.string().required(t("Guruhni tanlash!")),
          months: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required(""),
              value: Yup.string().required(""),
            })
          ),
          groups: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required(""),
              exam_id: Yup.number().required(""),
            })
          ),
        })
      ),
    }),
    onSubmit: async (values) => {
      setSaveLoader(true);
      const { data, status, nonFieldError } =
        await ComparisionStaffsRepository.getStatistics({
          score_type: score_type,
          teachers: [...values.staffs],
        });
      if (status) {
        setResults(data.results);
        setOrderNumber(data.ordering_numbers);
      } else {
        toastError(nonFieldError);
      }
      setSaveLoader(false);
    },
  });

  const cancelAction = useCallback(() => {
    formik.resetForm();
  });

  const handleAdded = useCallback((e) => {
    e.preventDefault();
    const subGroups = [
      ...formik.values.staffs,
      {
        teacher_id: "",
        month: "",
        exam_id: "",
      },
    ];
    formik.setFieldValue("staffs", subGroups);
  });

  return (
    <div id="modal" className={"modal-select active"}>
      <form
        className="modal-wrapper add-staff-section"
        onSubmit={formik.handleSubmit}
      >
        {/* <h4 className="modal__title group_modal_title">Xodimlarni belgilash</h4> */}
        <div className="group_modal">
          <div className="modal-inner">
            {formik.values.staffs?.map((element, index) => {
              return (
                <div key={index}>
                  <StaffModal
                    element={element}
                    formik={formik}
                    index={index}
                    key={index}
                  />
                  <div className="course_line"></div>
                </div>
              );
            })}
            <AddStaffModule handleAdd={(e) => handleAdded(e)} />
          </div>
        </div>
        <div className="input-buttons">
          <button
            id="modalClose"
            type="button"
            className="input-btn"
            onClick={() => cancelAction()}
          >
            {t("Bekor qilish")}
          </button>
          <button
            className="input-btn input-btn--submit"
            type="submit"
            disabled={saveLoader}
          >
            {saveLoader ? (
              <ReactLoading
                type={"spin"}
                color="#ffffff"
                className="delete_loader"
                height={"30px"}
                width={"30px"}
              />
            ) : (
              t("Saqlash")
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
