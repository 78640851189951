import Button from "@mui/material/Button";
import React, { useCallback, useContext } from "react";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Select from "react-select";
import OptionLoader from "../../../../Components/Loader/basic loader/OptionLoader";
import { LoaderContext } from "../../../../Contexts/LoaderContext";
import { MainGroupsContext } from "../../../../Contexts/GroupsContext/MainGroupsContext";
import { getRooms, getTimeSlots } from "../actions";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTranslation } from "react-i18next";

const btns = {
  marginTop: "-10px",
  marginBottom: "20px",
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
};

export default function GroupCompositionModal({ formik, index, days }) {
  const { t } = useTranslation();

  const { loader, dispatchLoader } = useContext(LoaderContext);
  const { mainGroups, dispatchMainGroups } = useContext(MainGroupsContext);
  const [alignment, setAlignment] = React.useState(0);

  const handleRemove = useCallback(() => {
    const updateSubGroups = formik.values.sub_groups?.filter(
      (element, i) => i !== index
    );
    formik.setFieldValue("sub_groups", updateSubGroups);
  });

  const handleChangeDays = useCallback(async (option) => {
    formik.setFieldValue(`sub_groups.${index}.time_range`, "");
    formik.setFieldValue(`sub_groups.${index}.room`, "");
    formik.setFieldValue(`sub_groups.${index}.recurring_days`, option.value);
    // formik.setFieldValue(`sub_groups.${index}.lesson_days`, option);

    if (
      formik.values.sub_groups[index]?.teacher &&
      formik.values.sub_groups[index]?.lesson_style
    ) {
      const queryString = new URLSearchParams({
        teacher_id: formik.values.sub_groups[index]?.teacher,
        lesson_style: formik.values.sub_groups[index]?.lesson_style,
        days: option.value,
      }).toString();
      await getTimeSlots(
        queryString,
        dispatchMainGroups,
        dispatchLoader,
        index
      );
    }
    if (
      formik.values.sub_groups[index]?.branch &&
      formik.values.sub_groups[index]?.time_range
    ) {
      const queryString = new URLSearchParams({
        branch_id: formik.values.sub_groups[index]?.branch,
        time_slot: formik.values.sub_groups[index]?.time_range,
        days: option.value,
      }).toString();
      await getRooms(queryString, dispatchMainGroups, dispatchLoader, index);
    }
  });

  const handleChangeTeacher = useCallback(async (e) => {
    formik.setFieldValue(`sub_groups.${index}.time_range`, "");
    formik.setFieldValue(`sub_groups.${index}.teacher`, e.target.value);
    if (
      formik.values.sub_groups[index]?.recurring_days.length > 0 &&
      formik.values.sub_groups[index]?.lesson_style
    ) {
      const queryString = new URLSearchParams({
        teacher_id: e.target.value,
        days: formik.values.sub_groups[index]?.recurring_days,
        lesson_style: formik.values.sub_groups[index]?.lesson_style,
      }).toString();
      await getTimeSlots(
        queryString,
        dispatchMainGroups,
        dispatchLoader,
        index
      );
    }
  });
  const handleChangeLesson = useCallback(async (element) => {
    formik.setFieldValue(`sub_groups.${index}.lesson_style`, element[0]);
    formik.setFieldValue(`sub_groups.${index}.time_range`, "");
    if (
      formik.values.sub_groups[index]?.recurring_days.length > 0 &&
      formik.values.sub_groups[index]?.teacher
    ) {
      const queryString = new URLSearchParams({
        lesson_style: element[0],
        days: formik.values.sub_groups[index]?.recurring_days,
        teacher_id: formik.values.sub_groups[index]?.teacher,
      }).toString();
      await getTimeSlots(
        queryString,
        dispatchMainGroups,
        dispatchLoader,
        index
      );
    }
  });

  const handleChangeTime = useCallback(async (e) => {
    formik.setFieldValue(`sub_groups.${index}.room`, "");
    formik.setFieldValue(`sub_groups.${index}.time_range`, e.target.value);
    if (
      formik.values.sub_groups[index]?.recurring_days.length > 0 &&
      formik.values.sub_groups[index]?.branch
    ) {
      const queryString = new URLSearchParams({
        branch_id: formik.values.sub_groups[index]?.branch,
        days: formik.values.sub_groups[index]?.recurring_days,
        time_slot: e.target.value,
      }).toString();
      await getRooms(queryString, dispatchMainGroups, dispatchLoader, index);
    }
  });

  const handleChangeBranch = useCallback(async (e) => {
    formik.setFieldValue(`sub_groups.${index}.room`, "");
    formik.setFieldValue(`sub_groups.${index}.branch`, e.target.value);
    if (
      formik.values.sub_groups[index]?.recurring_days.length > 0 &&
      formik.values.sub_groups[index]?.time_range
    ) {
      const queryString = new URLSearchParams({
        branch_id: e.target.value,
        days: formik.values.sub_groups[index]?.recurring_days,
        time_slot: formik.values.sub_groups[index]?.time_range,
      }).toString();
      await getRooms(queryString, dispatchMainGroups, dispatchLoader, index);
    }
  });

  const today = new Date().toISOString().substr(0, 10);

  const handleChange = React.useCallback((e, newAlignment) => {
    setAlignment(newAlignment);
  });

  return (
    <>
      <ToggleButtonGroup
        color="primary"
        size="small"
        sx={btns}
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
      >
        {mainGroups.lessonStyle?.map((element, i) => {
          return (
            <ToggleButton
              key={i}
              value={element[0]}
              onClick={() => handleChangeLesson(element)}
            >
              {element[1]}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>

      <div className="modal-select__item modal-four">
        <div className="modal-select__box ">
          <label htmlFor="birth_date">{t("O'qituvchi")}</label>
          <select
            onChange={(e) => handleChangeTeacher(e)}
            onBlur={formik.handleBlur}
            name={`sub_groups.${index}.teacher`}
            value={formik.values.sub_groups[index]?.teacher || ""}
            className={
              formik.touched.sub_groups?.[index]?.teacher &&
              formik.errors.sub_groups?.[index]?.teacher
                ? "modal-input error_input"
                : "modal-input"
            }
            id="teacher"
          >
            <option
              className="course_disabled_option"
              disabled
              hidden
              value={""}
            ></option>
            {mainGroups.staffs?.map((element) => {
              return (
                <option key={element.id} value={element.id}>
                  {element.fullname}
                </option>
              );
            })}
          </select>
          {formik.touched.sub_groups?.[index]?.teacher &&
          formik.errors.sub_groups?.[index]?.teacher ? (
            <p className="input-error-message ">
              {formik.errors.sub_groups?.[index]?.teacher}
            </p>
          ) : null}
        </div>
        <div className="modal-select__box">
          <label htmlFor="lessonType" className="course_label">
            {t("Dars kunlari")}
          </label>
          <Select
            id="vakolat"
            className={
              formik.touched.sub_groups?.[index]?.recurring_days &&
              formik.errors.sub_groups?.[index]?.recurring_days
                ? "modal-input multiselect error_input"
                : "modal-input multiselect"
            }
            options={days}
            name={`sub_groups.${index}.recurring_days`}
            onChange={(option) => handleChangeDays(option)}
            onBlur={formik.handleBlur}
          />
          {formik.touched.sub_groups?.[index]?.recurring_days &&
          formik.errors.sub_groups?.[index]?.recurring_days ? (
            <p className="input-error-message ">
              {formik.errors.sub_groups?.[index]?.recurring_days}
            </p>
          ) : null}
        </div>
        <div className="modal-select__box relative">
          {loader.isLoadingStaffsOption ? <OptionLoader /> : null}
          <label htmlFor="times">{t("Ma'qul dars vaqti")}</label>
          <select
            id="times"
            disabled={
              index === 0
                ? !mainGroups.times0
                  ? true
                  : false
                : !mainGroups.times1
                ? true
                : false
            }
            onChange={(e) => handleChangeTime(e)}
            onBlur={formik.handleBlur}
            name={`sub_groups.${index}.time_range`}
            value={formik.values.sub_groups[index]?.time_range || ""}
            className={
              formik.touched.sub_groups?.[index]?.time_range &&
              formik.errors.sub_groups?.[index]?.time_range
                ? "modal-input error_input"
                : "modal-input "
            }
          >
            <option
              className="course_disabled_option"
              disabled
              hidden
              value={""}
            ></option>
            {index === 0
              ? mainGroups.times0?.map((element) => {
                  return (
                    <option value={element.id} key={element.id}>
                      {element.starts_at} {element.ends_at}
                    </option>
                  );
                })
              : mainGroups.times1?.map((element) => {
                  return (
                    <option value={element.id} key={element.id}>
                      {element.starts_at} {element.ends_at}
                    </option>
                  );
                })}
          </select>
          {formik.touched.sub_groups?.[index]?.time_range &&
          formik.errors.sub_groups?.[index]?.time_range ? (
            <p className="input-error-message ">
              {formik.errors.sub_groups?.[index]?.time_range}
            </p>
          ) : null}
        </div>
        <div className="modal-select__box relative">
          <label htmlFor="start_date">{t("Boshlanish sanasi")}</label>
          <input
            id="start_date"
            type="date"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            min={today}
            name={`sub_groups.${index}.start_date`}
            value={formik.values.sub_groups[index]?.start_date || ""}
            className={
              formik.touched.sub_groups?.[index]?.start_date &&
              formik.errors.sub_groups?.[index]?.start_date
                ? "modal-input error_input"
                : "modal-input"
            }
          />
          {formik.touched.sub_groups?.[index]?.start_date &&
          formik.errors.sub_groups?.[index]?.start_date ? (
            <p className="input-error-message ">
              {formik.errors.sub_groups?.[index]?.start_date}
            </p>
          ) : null}
        </div>
      </div>
      <div className="modal-select__item modal-four margin_row">
        <div className="modal-select__box relative">
          <label htmlFor="branch">{t("Bino va filial")}</label>
          <select
            onChange={(e) => handleChangeBranch(e)}
            onBlur={formik.handleBlur}
            name={`sub_groups.${index}.branch`}
            value={formik.values.sub_groups[index]?.branch || ""}
            className={
              formik.touched.sub_groups?.[index]?.branch &&
              formik.errors.sub_groups?.[index]?.branch
                ? "modal-input error_input"
                : "modal-input"
            }
            id="branch"
          >
            <option
              className="course_disabled_option"
              disabled
              hidden
              value={""}
            ></option>
            {mainGroups.branches?.map((element) => {
              return (
                <option key={element.id} value={element.id}>
                  {element.title}
                </option>
              );
            })}
          </select>
          {formik.touched.sub_groups?.[index]?.branch &&
          formik.errors.sub_groups?.[index]?.branch ? (
            <p className="input-error-message ">
              {formik.errors.sub_groups?.[index]?.branch}
            </p>
          ) : null}
        </div>
        <div className="modal-select__box relative">
          {loader.isLoadingRoomsOption ? <OptionLoader /> : null}
          <label htmlFor="room">{t("Xona")}</label>
          <select
            disabled={
              index === 0
                ? !mainGroups.rooms0
                  ? true
                  : false
                : !mainGroups.rooms1
                ? true
                : false
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name={`sub_groups.${index}.room`}
            value={formik.values.sub_groups[index]?.room || ""}
            className={
              formik.touched.sub_groups?.[index]?.room &&
              formik.errors.sub_groups?.[index]?.room
                ? "modal-input error_input"
                : "modal-input"
            }
            id="room"
          >
            <option
              className="course_disabled_option"
              disabled
              hidden
              value={""}
            ></option>
            {index === 0
              ? mainGroups.rooms0?.map((element) => {
                  return (
                    <option key={element.id} value={element.id}>
                      {element.verbose_name}
                    </option>
                  );
                })
              : mainGroups.rooms1?.map((element) => {
                  return (
                    <option key={element.id} value={element.id}>
                      {element.verbose_name}
                    </option>
                  );
                })}
          </select>
          {formik.touched.sub_groups?.[index]?.room &&
          formik.errors.sub_groups?.[index]?.room ? (
            <p className="input-error-message ">
              {formik.errors.sub_groups?.[index]?.room}
            </p>
          ) : null}
        </div>
        <div className="modal-select__box relative">
          <label htmlFor="teacher">{t("Yordamchi o'qituvchi")}</label>
          <select
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name={`sub_groups.${index}.secondary_teacher`}
            value={formik.values.sub_groups[index]?.secondary_teacher || ""}
            className={
              formik.touched.sub_groups?.[index]?.secondary_teacher &&
              formik.errors.sub_groups?.[index]?.secondary_teacher
                ? "modal-input error_input"
                : "modal-input"
            }
            id="teacher"
          >
            <option
              className="course_disabled_option"
              disabled
              hidden
              value={""}
            ></option>
            {mainGroups.staffs?.map((element) => {
              return (
                <option key={element.id} value={element.id}>
                  {element.fullname}
                </option>
              );
            })}
          </select>
          {formik.touched.sub_groups?.[index]?.secondary_teacher &&
          formik.errors.sub_groups?.[index]?.secondary_teacher ? (
            <p className="input-error-message ">
              {formik.errors.sub_groups?.[index]?.secondary_teacher}
            </p>
          ) : null}
        </div>
        <div className="modal_delete_btn">
          <br />
          <Button
            onClick={() => handleRemove()}
            type="button"
            className="modal_icon_container"
            variant="outlined"
            color="warning"
            startIcon={<RemoveCircleIcon />}
            sx={{ height: "40px" }}
          >
            {t("delete")}
          </Button>
        </div>
      </div>
    </>
  );
}
