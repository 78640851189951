import { useTaskBookStore } from "../../../../Store/TaskBookStore/TaskBookStore";

export default function ListItem({ item, index }) {
  const openFileModal = () => {
    useTaskBookStore.setState({
      selectedFile: item.materials,
    });
  };

  return (
    <div className="list--config">
      <ul className="expenditure-list--grid build-table build-table--light build-table__item">
        <li className="build-table__item">{index + 1}</li>
        <li className="build-table__item">Lesson {item.number}</li>
        <li className="build-table__item">
          <a target={"_blank"} href={`/${item.video}`}>
            {item.video}
          </a>
        </li>
        <li className="build-table__item">{item.lesson_styles}</li>
        <li className="build-table__item">{item.age_groups}</li>
        <li className="build-table__item">{item.number}-oy</li>
        <li className="build-table__item">{item.subject}</li>
        <li onClick={() => openFileModal()} className="build-table__item">
          <img width="20px" src="/images/document.svg" alt="" />
        </li>
      </ul>
    </div>
  );
}
