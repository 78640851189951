import { toastError, toastSuccess } from "../../../Helpers/toasts";
import StudentTrasferRepository from "../../../Repositories/GroupManagement/StudentTransferRepository";
import { useStudentTransferStore } from "../../../Store/GroupManagement/StudentTransferStore";

export const loadItems = async (query) => {
  const response = await StudentTrasferRepository.getStudentsList(
    new URLSearchParams({
      search_query: query,
    })
  );
  return response;
};

export const loadGroupItems = async (query) => {
  const response = await StudentTrasferRepository.getGroupList(
    new URLSearchParams({
      search_query: query,
    })
  );
  return response;
};

export const getListItemDetail = async (result) => {
  useStudentTransferStore.setState({
    studentDetail: null,
    isLoading: true,
    student: null,
    comment: null,
  });

  const { data, status, nonFieldErrors } =
    await StudentTrasferRepository.getStudentDetails(result.id);

  if (status) {
    useStudentTransferStore.setState({
      student: result.id,
      studentDetail: data,
      isLoading: false,
    });
  } else toastError(nonFieldErrors);
  useStudentTransferStore.setState({
    isLoading: false,
  });
};

export const getGroupItemDetail = async (result) => {
  useStudentTransferStore.setState({
    groupDetail: null,
    isLoadingGroup: true,
    to_group: null,
  });
  const { data, status, nonFieldErrors } =
    await StudentTrasferRepository.getGroupDetails(result.id);
  if (status) {
    useStudentTransferStore.setState({
      groupDetail: data,
      to_group: result.id,
      isLoadingGroup: false,
      comment: null,
    });
  } else toastError(nonFieldErrors);
  useStudentTransferStore.setState({
    isLoadingGroup: false,
  });
};

export const handleSubmit = async (data) => {
  useStudentTransferStore.setState({
    isSubmitLoading: true,
  });
  const response = await StudentTrasferRepository.transferStudentAction(data);
  if (response.status) {
    toastSuccess("O'quvchi yangi guruhga qo'shildi");
  } else {
    toastError(response.nonFieldErrors);
  }
  useStudentTransferStore.setState({
    isSubmitLoading: false,
    studentDetail: null,
    groupDetail: null,
  });
};
