import React, { useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import SideBarActions from "../../Actions/ComponentsActions/SideBarActions";
import { SideBarContext } from "../../Contexts/SideBarContext";

export default function SideBarItem({title,link,icon}) {
  const { dispatchSideBar } = useContext(SideBarContext);

  const closeSideBar = useCallback(() => {
    dispatchSideBar({ type: SideBarActions.CLOSE });
  });

  return (
    <li className="menu__item" onClick={()=>closeSideBar()}>
      <Link to={link} className="menu__link">
        {icon}
        {title}
      </Link>
    </li>
  );
}
