import React from "react";
import { useTranslation } from "react-i18next";
import TabelSubHeading from "./TabelSubHeading";
import TableRow from "./TableRow";

export default function ListItem({ branch }) {
  const { t } = useTranslation();
  return (
    <>
      <TabelSubHeading title={branch.verbose_name} />
      {branch?.rooms.length == 0 && (
        <div className="room_availability_empty">
          {t("Binoda xonalar mavjud emas!")}
        </div>
      )}
      {branch?.rooms?.map((room, index) => {
        return <TableRow room={room} key={room.room_id} index={index} />;
      })}
    </>
  );
}
