import React from "react";
import { useTranslation } from "react-i18next";
import EmptyState from "../../../../Components/Empty-content/EmptyState";
import ItemsLoader from "../../../../Components/Loader/basic loader/ItemsLoader";
import { useStudentTransferStore } from "../../../../Store/GroupManagement/StudentTransferStore";
import SearchBarGroup from "../components/SearchBarGroup";
import SubmitButton from "../components/SubmitButton";
import Title from "../components/Title";
import GroupDetails from "./GroupDetails";
import TextArea from "./TextArea";

export default function TransferingGroupSection() {
  const { isLoadingGroup, groupDetail } = useStudentTransferStore();
  const { t } = useTranslation();
  return (
    <div className="transfer__item">
      <Title title={t("O‘qimoqchi bo‘layotgan guruhni topish")} />
      <SearchBarGroup title={t("Yangi guruhning restor raqami:")} />
      {isLoadingGroup ? (
        <ItemsLoader size={50} />
      ) : groupDetail ? (
        <>
          <GroupDetails />
          <TextArea />
          <SubmitButton />
        </>
      ) : (
        <EmptyState />
      )}
    </div>
  );
}
