import { create } from "zustand";

export const useRoomAvailabilityStore = create((set, get) => ({
  data: null,
  lessonStyles: null,
  queryParams: { lesson_style: 1 },
  loader: false,
  setLoader: (status) =>
    set({
      loader: status,
    }),
}));
