import LoaderActions from "../Actions/ComponentsActions/LoaderActions";

export const loaderReducer = (state, action) => {
  switch (action.type) {
    case LoaderActions.PAGE_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case LoaderActions.PAGE_LOADED:
      return {
        ...state,
        isLoading: false,
      };

    case LoaderActions.SELECT_LOADER:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
