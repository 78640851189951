import { useTranslation } from "react-i18next";
import BlockItem from "../../Components/BlockItem";
import UserPermissionWidget from "../../UserPermissionWidget";

function Trello() {
  const { t } = useTranslation();
  const token = JSON.parse(localStorage.getItem("token"));
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <div className={`home-cards-box`}>
      <h1 className="cards__title">
        <img
          className="cards__img trello-img"
          src="images/trello.png"
          alt="card box"
        />
        {t("others")}
      </h1>
      <section className="section-page config">
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_assignments}
        >
          <BlockItem
            link="/dashboard"
            title={t("Dashboard")}
            image="/images/dashboard.png"
          />
        </UserPermissionWidget>
        <UserPermissionWidget hasPermission={true}>
          <BlockItem
            link="/textbooks"
            title={t("textbooks")}
            image="/images/textbooks.png"
          />
        </UserPermissionWidget>
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_assignments}
        >
          <BlockItem
            link="/trello-tasks"
            title={t("home_tasks")}
            image="/images/trello.avif"
          />
        </UserPermissionWidget>
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_sms_notifications}
        >
          <a
            className="config-box"
            href={`https://admin.smartportal.uz/auth/bulk-sms/?token=${token.access}`}
            target="_blank"
          >
            <img
              className="config-img"
              src="/images/sms.jpg"
              alt="config img"
            />
            <h4 className="config-title">{t("SMS")}</h4>
          </a>
        </UserPermissionWidget>
      </section>
    </div>
  );
}

export default Trello;
