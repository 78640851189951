import React from "react";
import Pagination from "@mui/material/Pagination";

const customStyles = {
  backgroundColor: "", // Set the background color to red
  "& .MuiPaginationItem-root": {
    color: "#000", // Set the text color of the pagination items to white
  },
  "& .MuiPaginationItem-ellipsis": {
    color: "#000", // Set the text color of the ellipsis (...) to white
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    backgroundColor: "#429ffe", // Set the background color of the selected page item to blue
    color: "white", // Set the text color of the selected page item to white
    "&:hover": {
      backgroundColor: "#429ffe", // Set the background color of the selected page item on hover
    },
  },
};

export default function PaginationComponent({ context, callback }) {
  const { pagination, queryParams } = context;
  const pages = pagination?.page_count ?? pagination?.pages_count ?? 1;

  // const paginationItems = [...Array(pages)].map((_, i) => (
  //   <li key={i}>
  //     <div
  //       className={
  //         queryParams?.page === i + 1
  //           ? "pagination__item c-pointer pagination__item--active"
  //           : "pagination__item c-pointer"
  //       }
  //       onClick={queryParams?.page === i + 1 ? null : () => callback(i + 1)}
  //     >
  //       {i + 1}
  //     </div>
  //   </li>
  // ));

  const handleChange = (e, page) => {
    callback(page);
  };

  if (pages === 1) return null;
  return (
    <div className="pagination">
      <Pagination
        onChange={handleChange}
        count={pages}
        page={queryParams.page}
        variant="outlined"
        shape="rounded"
        color="primary"
        sx={customStyles}
      />
    </div>
    // <ul className="pagination">
    //   {queryParams?.page > 1 && (
    //     <li>
    //       <div
    //         className="pagination__item c-pointer"
    //         onClick={() => callback(queryParams?.page - 1)}
    //       >
    //         <img
    //           src="/images/pagination-arrow-left.svg"
    //           alt="pagination icon"
    //         />
    //       </div>
    //     </li>
    //   )}

    //   {paginationItems}

    //   {queryParams?.page < pages && (
    //     <li>
    //       <div
    //         className="pagination__item c-pointer"
    //         onClick={() => callback(queryParams?.page + 1)}
    //       >
    //         <img
    //           src="/images/pagination-arrow-right.svg"
    //           alt="pagination icon"
    //         />
    //       </div>
    //     </li>
    //   )}
    // </ul>
  );
}
