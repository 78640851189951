import React from "react";
import { useTranslation } from "react-i18next";

export default function TableHead() {
  const { t } = useTranslation();

  return (
    <div className="build-search_teacher level_statistic">
      <ul className="studentAttendance-table build-table build-table--bold ">
        <li className="build-table__item">№</li>
        <li className="build-table__item">{t("Oy")}</li>
        <li className="build-table__item">{t("Guruhlar")}</li>
        <li className="build-table__item">{t("O‘quvchi talabalar")}</li>
        <li className="build-table__item">{t("A'lochilar")}</li>
        <li className="build-table__item">{t("O’rtacha o’zlashtirganlar")}</li>
        <li className="build-table__item">{t("Passiv o’zlashtirganlar")}</li>
      </ul>
    </div>
  );
}
