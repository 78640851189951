import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useSalaryAdvanceStore } from "../../../../../Store/CashControlStore/SalaryAdvanceStore";

export default function ColorToggleButton() {
  const { payment_types, updateSalary, salary } = useSalaryAdvanceStore();

  const handleChange = (event, newAlignment) => {
    updateSalary({ payment_type: newAlignment });
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={salary?.payment_type}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      size="small"
    >
      {payment_types?.map((item) => {
        return (
          <ToggleButton
            size="small"
            key={item.value}
            value={item.value}
            sx={{
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: 400,
              height: "40px",
            }}
          >
            {item.name}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}
