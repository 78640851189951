import { toastError } from "../../../Helpers/toasts";
import StudentLessonAcuisitionRepository from "../../../Repositories/GroupManagement/StudentLessonAcuisitionRepository";
import { useStudentLessonAcquitationStore } from "../../../Store/GroupManagement/StudentLessonAcqusitationStore";

export const onReload = async () => {
  useStudentLessonAcquitationStore.setState({
    data: null,
    months: null,
    total_counts: null,
  });
  const { data, status, nonFieldErrors } =
    await StudentLessonAcuisitionRepository.getMonths();

  if (status) {
    useStudentLessonAcquitationStore.setState({
      data: data.data,
      months: data.months,
      total_counts: data.total_counts,
    });
  }
  toastError(nonFieldErrors);
};
export const loadItems = async (queryString) => {
  useStudentLessonAcquitationStore.setState({
    data: null,
    months: null,
    total_counts: null,
  });
  const { data, status, nonFieldErrors } =
    await StudentLessonAcuisitionRepository.getMonths(queryString);
  if (status) {
    useStudentLessonAcquitationStore.setState({
      data: data.data,
      months: data.months,
      total_counts: data.total_counts,
    });
  }
  toastError(nonFieldErrors);
};
