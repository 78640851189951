import { Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import * as React from "react";
import { useTranslation } from "react-i18next";
import StudentsRegistrationActions from "../../../../Actions/RegistrationActions/StudentsRegistrationActions";
import { LoaderContext } from "../../../../Contexts/LoaderContext";
import { StudentsRegistrationContext } from "../../../../Contexts/RegistrationContex/StudentRegistrationContext";
import { filterGroups } from "../actions";

export default function CheckboxLabels() {
  const { students, dispatchStudents } = React.useContext(
    StudentsRegistrationContext
  );
  const { dispatchLoader } = React.useContext(LoaderContext);
  const { t } = useTranslation();
  const handleConnectionStatus = React.useCallback(
    async (e) => {
      dispatchStudents({
        type: StudentsRegistrationActions.ADD_QUERY_PARAM,
        payload: {
          connection_status:
            students.queryParams.connection_status == e.target.value
              ? ""
              : e.target.value,
          page: 1,
        },
      });
      await filterGroups(
        {
          ...students?.queryParams,
          connection_status:
            students.queryParams.connection_status == e.target.value
              ? ""
              : e.target.value,
          page: 1,
        },
        dispatchStudents,
        dispatchLoader
      );
    },
    [students.queryParams]
  );
  const hasGroupAction = async (check) => {
    dispatchStudents({
      type: StudentsRegistrationActions.ADD_QUERY_PARAM,
      payload: {
        has_groups: check == true ? false : true,
        page: 1,
      },
    });
    await filterGroups(
      {
        ...students?.queryParams,
        has_groups: check === true ? false : true,
        page: 1,
      },
      dispatchStudents,
      dispatchLoader
    );
  };

  return (
    <FormGroup
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "220px",
      }}
      row
    >
      <FormControlLabel
        sx={{ my: 0 }}
        size="small"
        label={
          <Typography variant="body2" sx={{ fontSize: "14px" }}>
            {t("Ijobiy aloqa")}
          </Typography>
        }
        value={1}
        control={
          <Checkbox
            sx={{ padding: "2px" }}
            size="small"
            checked={students.queryParams.connection_status == 1}
          />
        }
        onChange={(e) => handleConnectionStatus(e)}
      />
      <FormControlLabel
        sx={{ my: 0 }}
        size="small"
        label={
          <Typography variant="body2" sx={{ fontSize: "14px" }}>
            {t("Guruhsizlar")}
          </Typography>
        }
        control={
          <Checkbox
            sx={{ padding: "2px" }}
            size="small"
            checked={students.queryParams.has_groups ? false : true}
          />
        }
        onChange={(e) => hasGroupAction(e.target.checked)}
      />
      <FormControlLabel
        sx={{ my: 0 }}
        size="small"
        label={
          <Typography variant="body2" sx={{ fontSize: "14px" }}>
            {t("Salbiy aloqa")}
          </Typography>
        }
        value={2}
        control={
          <Checkbox
            sx={{ padding: "2px" }}
            size="small"
            checked={students.queryParams.connection_status == 2}
          />
        }
        onChange={(e) => handleConnectionStatus(e)}
      />
    </FormGroup>
  );
}
