import AuthenticationActions from "../../../Actions/AuthActions/AuthenticationActions";
import { toastError, toastSuccess } from "../../../Helpers/toasts";
import AuthRepository from "../../../Repositories/AuthRepository";
import ProfileConfigRepository from "../../../Repositories/ProfileConfig/ProfileConfigRepository";
import { useProfileConfigStore } from "../../../Store/ProfileConfiguration/ProfileConfigStore";

export const onReload = async () => {
  const { data, status, nonFieldErrors } =
    await ProfileConfigRepository.getTeacherProfile();
  if (status) {
    useProfileConfigStore.setState({
      profile_details: data.profile_details,
      finance: data.finance,
      months: data.months,
      role_documents: data.role_documents,
    });
  } else toastError(nonFieldErrors);
};

export const uploadPhoto = async (formData, dispatchAuth) => {
  const { status, nonFieldErrors } = await ProfileConfigRepository.uploadPhoto(
    formData
  );
  if (status) {
    toastSuccess(nonFieldErrors);
    const profile = await AuthRepository.getMe();
    dispatchAuth({
      type: AuthenticationActions.LOGIN,
      payload: {
        user: { ...profile.data },
      },
    });
    localStorage.setItem("user", JSON.stringify(profile.data));
  } else toastError(nonFieldErrors);
};

export const updatePassword = async (data) => {
  const { nonFieldErrors, successField } =
    await ProfileConfigRepository.updatePassword(data);
  if (successField) {
    toastSuccess(successField);
  } else toastError(nonFieldErrors);
};
