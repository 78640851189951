import { useCallback, useEffect } from "react";
import AdminLayout from "../../AdminLayout";
import TitleBar from "../../Components/TitleBar";
import { useLoaderStore } from "../../Store/Loader/LoaderStore";
import { useTrelloStore } from "../../Store/Trello/TrellosStore";
import TrelloDashboard from "./components/TrelloDashboard";
import TrelloTable from "./components/Table";
import { Box, Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PermissionConfirmation from "./components/PermissionConfirmation";
import GroupAvatars from "./components/AvatarGroup";
import { useTranslation } from "react-i18next";

export default function TrelloPage() {
  const { onReload, isOpenModal } = useTrelloStore();
  const { setLoader } = useLoaderStore();

  const pageLoad = useCallback(async () => {
    setLoader(true);
    await onReload();
    setLoader(false);
  }, []);

  useEffect(() => {
    pageLoad();
  }, []);

  const cancelCallback = () => {
    useTrelloStore.setState({
      isOpenModal: false,
      updateId: null,
      cardDetails: null,
    });
  };

  const { t } = useTranslation();

  return (
    <AdminLayout>
      <TitleBar title={t("Trello")} groupBtns={<GroupAvatars />} />
      <div className="container">
        <TrelloDashboard />
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
            m: "20px 0",
          }}
        >
          <Button
            onClick={() => {
              useTrelloStore.setState({
                isOpenModal: true,
              });
            }}
            variant="contained"
            startIcon={<AddCircleIcon />}
          >
            {t("topshiriq qo'shish")}
          </Button>
        </Box>
        <TrelloTable />
        <PermissionConfirmation
          cancelCallback={cancelCallback}
          showModal={isOpenModal}
        />
      </div>
    </AdminLayout>
  );
}
