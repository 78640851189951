import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import StaffSelect from "./AutoSelect";
import ControlledSwitches from "./Switch";

const RoomSelect = styled(Autocomplete)({
  "&.MuiAutocomplete-root .MuiAutocomplete-inputRoot": {
    padding: "0px !important",
    fontSize: "13px",
    color: "black",
    fontFamily: "Inter !important",
    fontSize: "13px !important",
    fontWeight: "400 !important",
    border: "1px solid #cccccc",
    marginTop: "15px",
    background: "white",
    borderRadius: "8px !important",
  },
  "& .MuiAutocomplete-listbox": {
    border: "1px solid red !important",
    fontFamily: "Inter !important",
    fontSize: "13px !important",
  },
  "& .Mui-focused": {
    boxShadow: "0 0 5px rgba(81, 203, 238, 1)",
    border: "1px solid rgba(81, 203, 238, 1) !important",
  },
  "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
    top: "10px",
    fontSize: "13px !important",
    fontWeight: "400 !important",
    color: "#828282",
    fontFamily: "Inter !important",
    letterSpacing: "1px !important",
  },
  "& .MuiAutocomplete-endAdornment": {
    right: "5px !important",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#1E5EF3",
  },
});

export default function BranchRooms({ formik, staffs, room, index }) {
  const { t } = useTranslation();
  return (
    <div className="rooms_block">
      <div width="100%" className="row1">
        <div className="modal-input room_number">
          {formik.values.rooms?.[index].number}
        </div>

        <div className="modal-rooms room_title">
          <input
            name={`rooms.${index}.nickname`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.rooms?.[index].nickname || ""}
            type="text"
            placeholder={t("room_name")}
            className={
              formik.touched.rooms?.[index]?.nickname &&
              formik.errors.rooms?.[index]?.nickname
                ? "modal-input room_name error_input"
                : "modal-input room_name"
            }
          />
          {formik.touched.rooms?.[index]?.nickname &&
          formik.errors.rooms?.[index]?.nickname ? (
            <p className="input-error-message modal_rooms_error">
              {formik.errors.rooms?.[index]?.nickname}
            </p>
          ) : null}
        </div>
      </div>
      <div className="modal-rooms auto_select">
        <StaffSelect formik={formik} index={index} staffs={staffs} />
        {formik.touched.rooms?.[index]?.responsible_staff &&
        formik.errors.rooms?.[index]?.responsible_staff ? (
          <p className="input-error-message modal_rooms_error">
            {formik.errors.rooms?.[index]?.responsible_staff}
          </p>
        ) : null}
      </div>
      <div className="switch_section">
        <p>{t("is_lesson_room")}</p>
        <ControlledSwitches formik={formik} index={index} />
      </div>
    </div>
  );
}
