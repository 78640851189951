import { dateRangeFormat } from "../../../../Helpers/dateFormat";

export default function ListItems({ studentItems, index }) {
  const {
    from_group_teachers,
    from_group,
    from_group_month,
    to_group,
    to_group_month,
    to_group_teachers,
    student,
    comment,
    created_at,
  } = studentItems;
  return (
    <ul className="transferJournal-table build-table build-table--light build-table__item">
      <li className="build-table__item">{index + 1}</li>
      <li className="build-table__item">
        {student?.first_name} {student?.last_name}
      </li>
      <li className="build-table__item">{student?.student_id}</li>
      <li className="build-table__item">{dateRangeFormat(created_at)}</li>
      <li className="build-table__item transfer-student-age">{student?.age}</li>
      <li className="build-table__item">{from_group?.number}</li>
      <li className="build-table__item">{from_group?.age_group}</li>
      <li className="build-table__item">{from_group_month}</li>
      <li className="build-table__item">{from_group_teachers}</li>
      <li className="build-table__item transfer-student-age">
        {to_group?.number}
      </li>
      <li className="build-table__item">{to_group?.age_group}</li>
      <li className="build-table__item">{to_group_month}</li>
      <li className="build-table__item">{to_group_teachers}</li>
      <li className="build-table__item">{comment}</li>
    </ul>
  );
}
