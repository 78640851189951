import { Button } from "@mui/material";
import React, { useCallback } from "react";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useAttendanceDetailsStore } from "../../../../Store/AttendanceFormStore/AttendanceDeatilsStore";

function Counter(props) {
  const { students } = useAttendanceDetailsStore();
  const { img, item, element } = props;
  const card = item.numberic_cards?.[element.key];
  const count = card || 0;

  const handleIncrement = useCallback(() => {
    const updatedStudents = students?.map((student) =>
      student.id === item.id
        ? {
            ...student,
            numberic_cards: {
              ...student.numberic_cards,
              [element.key]: count + 1,
            },
          }
        : student
    );
    useAttendanceDetailsStore.setState({
      students: updatedStudents,
    });
  });

  const handleDecrement = useCallback(() => {
    const updatedStudents = students?.map((student) =>
      student.id === item.id
        ? {
            ...student,
            numberic_cards: {
              ...student.numberic_cards,
              [element.key]: count == 0 ? 0 : count - 1,
            },
          }
        : student
    );
    useAttendanceDetailsStore.setState({
      students: updatedStudents,
    });
  });

  return (
    <>
      <Button
        size="small"
        color="warning"
        variant="outlined"
        sx={{
          minWidth: "25px",
        }}
        onClick={handleDecrement}
      >
        <RemoveRoundedIcon fontSize="15px" />
      </Button>
      <div className="counter_coast">
        <span>{count} x </span>
        <img src={img} alt="card-image" className="attendance_detail_img" />
      </div>
      <Button
        variant="outlined"
        color="success"
        size="small"
        onClick={handleIncrement}
        sx={{
          minWidth: "25px",
        }}
      >
        <AddRoundedIcon fontSize="15px" />
      </Button>
    </>
  );
}

export default React.memo(Counter);
