import React from "react";
import "./style.css";

export default function CheckBoxComponent({ label, index, callback, checked }) {
  return (
    <div className="attendanceDetailsCheckbox">
      <label className="cl-checkbox">
        <input
          checked={checked}
          type="checkbox"
          id={index}
          onChange={callback}
        />
        <span></span>
      </label>
      <label>{label}</label>
    </div>
  );
}
