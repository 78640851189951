import LoaderActions from "../../../Actions/ComponentsActions/LoaderActions";
import EmployeeRegistrationAction from "../../../Actions/RegistrationActions/EmployeeRegistrationAction";
import { toastError, toastSuccess } from "../../../Helpers/toasts";
import EmployeeRepository from "../../../Repositories/Registration/EmployeeRepository";

export const onReload = async (context, dispatchMainGroups, dispatchLoader) => {
  // const pageSize = localStorage.getItem("pageSize") ?? 10;
  // const mainGroupsDate = await EmployeeRepository.getAllGroups(
  //   new URLSearchParams({
  //     ...context.queryParams,
  //     pageSize: pageSize,
  //   }).toString()
  // );
  dispatchLoader({ type: LoaderActions.PAGE_LOADING });
  const [getAllStaffs, getAllBranches, getAllPosition, getLeavingReasons] =
    await Promise.all([
      EmployeeRepository.getAllStaffs(
        new URLSearchParams({
          page: 1,
          search_query: "",
        }).toString()
      ),
      EmployeeRepository.getAllBranches(),
      EmployeeRepository.getStaffTypes(),
      EmployeeRepository.getLeavingReasons(),
    ]);
  dispatchMainGroups({
    type: EmployeeRegistrationAction.PAGE_LOADED,
    payload: {
      data: getAllStaffs.data.data.results,
      pagination: { pages_count: getAllStaffs.data.data.pages_count },
      staff_count: getAllStaffs.data.staff_count,
      branches: getAllBranches.data,
      staffType: getAllPosition.data,
      leavingReasons: getLeavingReasons.data,
      working_statuses: getAllStaffs.data.working_statuses,
    },
  });
  dispatchLoader({ type: LoaderActions.PAGE_LOADED });
};

export const loadItems = async (payload, context, dispatch, dispatchLoader) => {
  dispatchLoader({ type: LoaderActions.PAGE_LOADING });
  const response = await EmployeeRepository.getAllStaffs(
    new URLSearchParams({ ...context.queryParams, ...payload }).toString()
  );
  dispatch({
    type: EmployeeRegistrationAction.PAGE_LOADED,
    payload: {
      data: response.data.data.results,
      staff_count: response.data.staff_count,
      pagination: { pages_count: response.data.data.pages_count },
    },
  });
  dispatchLoader({ type: LoaderActions.PAGE_LOADED });
};

export const createAction = (e, dispatch) => {
  e.stopPropagation();
  dispatch({
    type: EmployeeRegistrationAction.CREATE_BUTTON_CLICKED,
  });
};
export const toggleContextMenu = (group, dispatch) => {
  dispatch({
    type: EmployeeRegistrationAction.TOGGLE_CONTEXT_MENU,
    payload: { id: group.id },
  });
};
export const deleteConfirmAction = (e, payment, dispatch) => {
  e.stopPropagation();
  dispatch({
    type: EmployeeRegistrationAction.DELETE_BUTTON_CLICKED,
    payload: { id: payment.id },
  });
};
export const permissionConfirmationAction = (e, payment, dispatch) => {
  e.stopPropagation();
  dispatch({
    type: EmployeeRegistrationAction.PERMISSION_BUTTON_CLICKED,
    payload: payment.id,
  });
};
export const updateSalaryStaff = (e, payment, dispatch) => {
  e.stopPropagation();
  dispatch({
    type: EmployeeRegistrationAction.UPDATE_SALARY,
    payload: payment.id,
  });
};
export const removeConfirmationAction = (e, payment, dispatch) => {
  e.stopPropagation();
  dispatch({
    type: EmployeeRegistrationAction.REMOVE_STAFF_BUTTON_CLICKED,
    payload: payment.id,
  });
};

export const updateAction = async (payment, dispatch, dispatchLoader) => {
  dispatchLoader({ type: LoaderActions.PAGE_LOADING });
  const response = await EmployeeRepository.getStaffById(payment.id);
  if (response.status === false) {
    toastError("Serverdan ma'lumot olib bo'lmadi");
    dispatch({ type: EmployeeRegistrationAction.CANCEL_BUTTON_CLICKED });
    return;
  }
  dispatch({
    type: EmployeeRegistrationAction.UPDATE_BUTTON_CLICKED,
    payload: { id: payment.id, item: response.data },
  });
  dispatchLoader({ type: LoaderActions.PAGE_LOADED });
};
export const openInfo = async (e, payment, dispatch, dispatchLoader) => {
  e.stopPropagation();
  dispatchLoader({ type: LoaderActions.PAGE_LOADING });
  const response = await EmployeeRepository.getStaffById(payment.id);
  if (response.status === false) {
    toastError("Serverdan ma'lumot olib bo'lmadi");
    dispatch({ type: EmployeeRegistrationAction.CANCEL_BUTTON_CLICKED });
    return;
  }
  dispatch({
    type: EmployeeRegistrationAction.STAFF_INFO,
    payload: { item: response.data },
  });
  dispatchLoader({ type: LoaderActions.PAGE_LOADED });
};

export const cancelDeleteAction = (dispatch) => {
  dispatch({
    type: EmployeeRegistrationAction.CANCEL_BUTTON_CLICKED,
  });
  dispatch({
    type: EmployeeRegistrationAction.SHOW_DELETE_LOADER,
    payload: false,
  });
};

export const deleteAction = async (e, context, dispatch, dispatchLoader) => {
  e.preventDefault();
  dispatch({
    type: EmployeeRegistrationAction.SHOW_DELETE_LOADER,
    payload: true,
  });
  const response = await EmployeeRepository.deleteStaff(context.deletingId);
  if (response.status === true) {
    toastSuccess("Xodim turi o'chirib yuborildi");
  } else {
    toastError("Xodim turi o'chirilmadi!");
  }
  dispatch({
    type: EmployeeRegistrationAction.SHOW_DELETE_LOADER,
    payload: false,
  });
  loadItems({}, context, dispatch, dispatchLoader);
  cancelDeleteAction(dispatch);
};
export const removeAction = async (e, context, dispatch, dispatchLoader) => {
  e.preventDefault();
  dispatch({
    type: EmployeeRegistrationAction.SHOW_DELETE_LOADER,
    payload: true,
  });
  const response = await EmployeeRepository.deleteStaff(context.deletingId);
  if (response.status === true) {
    toastSuccess("Xodim turi o'chirib yuborildi");
  } else {
    toastError("Xodim turi o'chirilmadi!");
  }
  dispatch({
    type: EmployeeRegistrationAction.SHOW_DELETE_LOADER,
    payload: false,
  });
  loadItems({}, context, dispatch, dispatchLoader);
  cancelDeleteAction(dispatch);
};
export const permissionAction = async (
  e,
  context,
  dispatch,
  dispatchLoader
) => {
  e.preventDefault();
  dispatch({
    type: EmployeeRegistrationAction.SHOW_DELETE_LOADER,
    payload: true,
  });
  const response = await EmployeeRepository.deleteStaff(context.deletingId);
  if (response.status === true) {
    toastSuccess("Xodim turi o'chirib yuborildi");
  } else {
    toastError("Xodim turi o'chirilmadi!");
  }
  dispatch({
    type: EmployeeRegistrationAction.SHOW_DELETE_LOADER,
    payload: false,
  });
  loadItems({}, context, dispatch, dispatchLoader);
  cancelDeleteAction(dispatch);
};

export const openFilterList = (dispatch) => {
  dispatch({ type: EmployeeRegistrationAction.TOGGLE_FILTER_LIST });
};
export const openCategories = (e, dispatch) => {
  e.stopPropagation();
  dispatch({ type: EmployeeRegistrationAction.TOGGLE_CATEGORIES });
};
export const filterStaffs = async (data, dispatch, dispatchLoader) => {
  dispatchLoader({ type: LoaderActions.PAGE_LOADING });
  dispatch({
    type: EmployeeRegistrationAction.CLOSE_FILTER_LIST,
  });
  const response = await EmployeeRepository.getAllStaffs(
    new URLSearchParams({
      working_status: data,
    }).toString()
  );
  dispatch({
    type: EmployeeRegistrationAction.PAGE_LOADED,
    payload: {
      data: response.data.data.results,
      staff_count: response.data.staff_count,
      pagination: { pages_count: response.data.data.pages_count },
    },
  });
  dispatchLoader({
    type: LoaderActions.PAGE_LOADED,
  });
  if (response.status === false) {
    toastError("Serverdan ma'lumot olishda xatolik yuz berdi!");
    return;
  }
};
