import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSalaryCalculationStore } from "../../../../Store/CashControlStore/SalaryCalculationStore";
import UserPermissionWidget from "../../../../UserPermissionWidget";

export default function OutlinedButtons() {
  const navigate = useNavigate();

  const handleSave = async (as_draft) => {
    useSalaryCalculationStore.setState({
      confirmation: true,
      as_draft: as_draft,
    });
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const { t } = useTranslation();

  return (
    <Stack direction="row" justifyContent="flex-end" spacing={2}>
      <Button
        variant="outlined"
        onClick={() => navigate(-1, { replace: true })}
      >
        {t("Bekor qilish")}
      </Button>
      <UserPermissionWidget
        hasPermission={user?.permissions?.save_monthly_salary_calculation}
      >
        <Button
          variant="contained"
          color="success"
          onClick={() => handleSave(true)}
        >
          {t("vaqtinchalik saqlash")}
        </Button>
        <Button variant="contained" onClick={() => handleSave(false)}>
          {t("Saqlash")}
        </Button>
      </UserPermissionWidget>
    </Stack>
  );
}
