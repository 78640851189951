import CommonHeading from "../../../../Components/CommonHeading";
import Selector from "./Select";
import TableHead from "./TableHead";
import { useFormik } from "formik";
import * as Yup from "yup";
import Searchbar from "../../../../Components/Searchbar";
import { useCallback } from "react";
import { useMemo } from "react";
import { useStudentTransferStore } from "../../../../Store/GroupManagement/StudentTransferStore";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ExportExcel from "../../../../Components/ExportExcel";

export default function Heading() {
  const {
    loadItems,
    uploadParams,
    queryParams,
    setLoading,
    ageGroups,
    teachersList,
    branches,
  } = useStudentTransferStore();

  const searchAction = useCallback(async (payload) => {
    uploadParams({ search_term: payload.term, page: 1 });
    setLoading(true);
    await loadItems(
      new URLSearchParams({
        ...queryParams,
        page: 1,
        search_term: payload.term,
      }).toString()
    );
    setLoading(false);
  });

  const { t } = useTranslation();

  const initialValues = {
    term: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      term: Yup.string().min(1, t("Qidiruvni kiriting!")),
    }),
    onSubmit: (values) => {
      searchAction({
        term: values.term,
      });
    },
  });
  const handleChange = (e) => {
    formik.setFieldValue("term", e.target.value);
    searchAction({
      term: e.target.value,
    });
  };

  const optionsGroups = useMemo(() => {
    return (
      ageGroups?.map((element) => ({
        label: element.name,
        value: element.id,
      })) || []
    );
  }, [ageGroups]);
  const optionsTeacher = useMemo(() => {
    return (
      teachersList?.map((element) => ({
        label: element.name,
        value: element.id,
      })) || []
    );
  }, [teachersList]);

  const queryString = new URLSearchParams({
    ...queryParams,
    download: "excel",
  }).toString();

  return (
    <CommonHeading
      topItems={
        <>
          <Searchbar
            value={formik.values.term}
            handleChange={(e) => handleChange(e)}
            handleSubmit={formik.handleSubmit}
          />
          <Selector options={optionsTeacher} property={t("O'qituvchilar")} />
          <Selector options={optionsGroups} property={t("Guruh turi")} />
          {/* <Link
            to={
              `https://admin.smartportal.uz/api/management/group/changes/?` +
              queryString
            }
            download
            target={"_blank"}
          >
            <ExportExcel />
          </Link> */}
        </>
      }
      tableHead={<TableHead />}
      extraClass="build-search_teacher"
    />
  );
}
