import { useContext } from "react";
import { useCallback } from "react";
import Select from "react-select";
import StudentsRegistrationActions from "../../../../Actions/RegistrationActions/StudentsRegistrationActions";
import { StudentsRegistrationContext } from "../../../../Contexts/RegistrationContex/StudentRegistrationContext";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "37px",
    minHeight: "37px",
    width: "250px",
    fontSize: "15px",
    fontFamily: "Inter",
    fontWeight: "400",
  }),
};

export default function Selector({ param, property, value }) {
  const { dispatchStudents } = useContext(StudentsRegistrationContext);
  const handleSelectChange = useCallback(async (e) => {
    if (property === "Fanlar") {
      dispatchStudents({
        type: StudentsRegistrationActions.ADD_QUERY_PARAM,
        payload: { page: 1, subject: e?.value == undefined ? "" : e?.value },
      });
    }
    if (property === "Guruh turlari") {
      dispatchStudents({
        type: StudentsRegistrationActions.ADD_QUERY_PARAM,
        payload: { page: 1, group_type: e?.value == undefined ? "" : e?.value },
      });
    }
    if (property === "Dars kunlari") {
      dispatchStudents({
        type: StudentsRegistrationActions.ADD_QUERY_PARAM,
        payload: {
          page: 1,
          suitable_days: e?.value == undefined ? "" : e?.value,
        },
      });
    }
    if (property === "Dars vaqtlari") {
      dispatchStudents({
        type: StudentsRegistrationActions.ADD_QUERY_PARAM,
        payload: {
          page: 1,
          suitable_time: e?.value == undefined ? "" : e?.value,
        },
      });
    }
    if (property === "Binolar") {
      dispatchStudents({
        type: StudentsRegistrationActions.ADD_QUERY_PARAM,
        payload: {
          page: 1,
          studying_place: e?.value == undefined ? "" : e?.value,
        },
      });
    }
    if (property === "Xodimlar") {
      dispatchStudents({
        type: StudentsRegistrationActions.ADD_QUERY_PARAM,
        payload: {
          page: 1,
          wanted_teacher: e?.value == undefined ? "" : e?.value,
        },
      });
    }
    if (property === "Level") {
      dispatchStudents({
        type: StudentsRegistrationActions.ADD_QUERY_PARAM,
        payload: {
          page: 1,
          study_levels: e?.value == undefined ? "" : e?.value,
        },
      });
    }
    if (property === "Guruh statusi") {
      dispatchStudents({
        type: StudentsRegistrationActions.ADD_QUERY_PARAM,
        payload: {
          page: 1,
          status: e?.value == undefined ? "" : e?.value,
        },
      });
    }
  });

  return (
    <Select
      // className="students-select"
      classNamePrefix="select"
      placeholder={property}
      styles={customStyles}
      options={param}
      isClearable
      value={value || null}
      onChange={handleSelectChange}
    />
  );
}
