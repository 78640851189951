import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useProfileConfigStore } from "../../../../Store/ProfileConfiguration/ProfileConfigStore";

export default function BasicButtons() {
  const { months } = useProfileConfigStore();
  return (
    <Stack gap="10px" direction="row" flexWrap="wrap" margin="10px 0">
      {months?.map((element) => {
        return (
          <Link key={element.id} to={`/lesson-manuals/${element.id}`}>
            <Button variant="outlined">{element.verbose}</Button>
          </Link>
        );
      })}
    </Stack>
  );
}
