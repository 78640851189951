import axios from "axios";
import { BASE_API_URL } from "./Constants";

const user = JSON.parse(localStorage.getItem("user"));
const branchId = user && user.branch && user.branch.length === 1 ? user.branch[0].id : null;

const axiosInstance = axios.create({
  baseURL: BASE_API_URL,
  withCredentials: true,
  credentials: "include",
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
  },
});

// axiosInstance.interceptors.request.use((config) => {
//   if (config.method.toLowerCase() === 'get' && branchId) {
//     if (!config.params) {
//       config.params = {};
//     }
//     config.params.branch = branchId;
//   }
//   return config;
// }, (error) => {
//   return Promise.reject(error);
// });

export default axiosInstance;
