import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useExcludedStudentsStore } from "../../../../Store/ReportsStore/ExcludedStudentsStore";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "37px",
    minHeight: "37px",
    fontSize: "13px",
    fontFamily: "Inter",
    fontWeight: "400",
  }),
};

export default function Selector({ options, property }) {
  const { updateParams, loadItems, queryParams, setLoading } =
    useExcludedStudentsStore();
  const { t } = useTranslation();

  const handleSelectChange = useCallback(async (e) => {
    setLoading(true);
    if (property === t("O'qituvchilar")) {
      updateParams({ teacher: e?.value == undefined ? "" : e?.value, page: 1 });
      await loadItems(
        new URLSearchParams({
          ...queryParams,
          teacher: e?.value == undefined ? "" : e?.value,
          page: 1,
        }).toString()
      );
    }
    if (property === t("Status")) {
      updateParams({
        reason: e?.value == undefined ? "" : e?.value,
        page: 1,
      });
      await loadItems(
        new URLSearchParams({
          ...queryParams,
          reason: e?.value == undefined ? "" : e?.value,
          page: 1,
        }).toString()
      );
    }
    if (property === t("Filiallar")) {
      updateParams({
        branch: e?.value == undefined ? "" : e?.value,
        page: 1,
      });
      await loadItems(
        new URLSearchParams({
          ...queryParams,
          branch: e?.value == undefined ? "" : e?.value,
          page: 1,
        }).toString()
      );
    }
    setLoading(false);
  });

  return (
    <Select
      classNamePrefix="select"
      placeholder={property}
      styles={customStyles}
      options={options}
      isClearable
      onChange={handleSelectChange}
    />
  );
}
