import React, { useCallback, useContext } from "react";
import EmployeeRegistrationAction from "../../../../Actions/RegistrationActions/EmployeeRegistrationAction";
import { EmployeeRegistrationContext } from "../../../../Contexts/RegistrationContex/EmployeeRegistrationContext";
import { openCategories } from "../actions";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";

export default function CategoriesBlock() {
  const { employee, dispatchEmployee } = useContext(
    EmployeeRegistrationContext
  );

  const handleChange = useCallback((e, element) => {
    if (element.isOpenCategory) {
      let editedUser = { isOpenCategory: false, name: element.name };
      const users = employee.categoryList.map((u) =>
        u.name !== editedUser.name ? u : editedUser
      );
      dispatchEmployee({
        type: EmployeeRegistrationAction.CATEGORY_LIST,
        payload: users,
      });
    } else {
      let editedUser = { isOpenCategory: true, name: element.name };
      const users = employee.categoryList.map((u) =>
        u.name !== editedUser.name ? u : editedUser
      );
      dispatchEmployee({
        type: EmployeeRegistrationAction.CATEGORY_LIST,
        payload: users,
      });
    }
  });
  const categoryFiltered = employee.categoryList.filter(
    (element) => element.isOpenCategory === true
  );
  return (
    <div
      className="categories"
      onClick={(e) => openCategories(e, dispatchEmployee)}
    >
      <ViewWeekIcon sx={{ fontSize: "20px" }} color="action" />
      <ul
        className={
          employee.isOpenCategories
            ? "categories-list categories-list_active"
            : "categories-list"
        }
      >
        {employee.categoryList.map((element, index) => {
          return (
            <li
              key={index}
              onClick={(e) => e.stopPropagation()}
              className={
                categoryFiltered?.length < 6 && element.isOpenCategory === true
                  ? "categories-list__item category_opacity"
                  : "categories-list__item"
              }
            >
              <input
                type="checkbox"
                name="category"
                disabled={
                  categoryFiltered?.length < 6 &&
                  element.isOpenCategory === true
                }
                onChange={(e) => handleChange(e, element)}
                checked={element.isOpenCategory}
                id={index}
              />
              <label htmlFor={index}>{element.name}</label>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
