import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import AdminLayout from "../../../AdminLayout";
import EmptyContent from "../../../Components/Empty-content/EmptyContent";
import TitleBar from "../../../Components/TitleBar";
import { LoaderContext } from "../../../Contexts/LoaderContext";
import { useAttendanceStore } from "../../../Store/AttendanceFormStore/AttendanceForm";
import UserPermissionWidget from "../../../UserPermissionWidget";
import { onReload } from "./actions";
import Details from "./components/Details";
import InfoTag from "./components/InfoTag";
import ListItem from "./components/ListItem";
import SignatureRow from "./components/SignatureRow";
import StudentInfoModal from "./components/StudentInfoModal";
import TableHeading from "./components/TableHeading";
import UnGroupModal from "./components/UnGroupModal";

export default function AttendanceForm() {
  const { data, updateAttendanceDates } = useAttendanceStore();
  const { dispatchLoader } = useContext(LoaderContext);
  const [searchParams] = useSearchParams();
  const params = useParams();

  const groupId = params.groupId;
  const monthId = searchParams.get("month") || null;
  console.log(monthId);

  const queryParams = !monthId
    ? { sub_group: groupId }
    : { sub_group: groupId, lesson_month: monthId };

  const pageReload = useCallback(async () => {
    await onReload(queryParams, updateAttendanceDates, dispatchLoader);
  }, [groupId]);

  useEffect(() => {
    pageReload();
  }, [groupId]);

  const user = JSON.parse(localStorage.getItem("user"));
  const { t } = useTranslation();

  return (
    <AdminLayout>
      <TitleBar title={t("Davomat jurnali")} createAction={false} />
      <div className="container">
        {data?.students ? (
          <div className="journal">
            <Details groupId={groupId} />
            <div className="desk">
              <TableHeading groupId={groupId} />
              {data?.students?.map((student) => (
                <ListItem student={student} key={student.id} />
              ))}
              <SignatureRow />
            </div>
            <InfoTag groupId={groupId} />
          </div>
        ) : (
          <EmptyContent />
        )}
        <UserPermissionWidget
          hasPermission={user?.permissions?.exclude_student}
        >
          <UnGroupModal />
        </UserPermissionWidget>
        <StudentInfoModal />
      </div>
    </AdminLayout>
  );
}
