import PaymentSection from "./PaymentSection";
import StaffInfo from "./StaffInfo";
import StaffSelector from "./Select";
import SalaryAdvanceRepository from "../../../../../Repositories/CashControl/SalaryAdvanceRepository";
import { toastError, toastSuccess } from "../../../../../Helpers/toasts";
import { useSalaryAdvanceStore } from "../../../../../Store/CashControlStore/SalaryAdvanceStore";
import { useMemo } from "react";
import { useCallback } from "react";
import ItemsLoader from "../../../../../Components/Loader/basic loader/ItemsLoader";
import EmptyState from "../../../../../Components/Empty-content/EmptyState";
import ConfirmationModal from "../../../Components/ConfirmationModal";
import TransactionsHistory from "./TransactionsHistory";
import { useTranslation } from "react-i18next";

export default function SalaryAdvance() {
  const {
    resetData,
    salary,
    staffs,
    getStaffDetails,
    select_value,
    setLoading,
    isLoading,
    details,
    confirmation,
    selectedMonth,
    data,
    calculated,
  } = useSalaryAdvanceStore();

  const { t } = useTranslation();

  const options = useMemo(() => {
    return (
      staffs?.map((element) => ({
        label: `${element.first_name}${" "}${element.last_name}${" "}${
          element.middle_name
        }`,
        value: element.id,
      })) || []
    );
  }, [staffs]);

  const handleChangeStaff = useCallback(async (e) => {
    if (e?.value) {
      useSalaryAdvanceStore.setState({
        select_value: e.value,
        isLoading: true,
      });
      await getStaffDetails(e.value);
      setLoading(false);
    } else resetData();
  }, []);

  const handleSubmit = async () => {
    const paySalary = {
      calculated: calculated,
      staff: data?.staff_details?.id,
      calculation_id: selectedMonth?.calculation_id,
      ...salary,
    };
    console.log(paySalary);
    const response = await SalaryAdvanceRepository.paySalary(paySalary);
    if (response.status) {
      toastSuccess(response.nonFieldErrors);
      useSalaryAdvanceStore.setState({
        paymentId: response.data,
        confirmation: false,
        salary: null,
        isLoading: true,
      });
      await getStaffDetails(data?.staff_details?.id);
      setLoading(false);
    } else toastError(response.nonFieldErrors);
  };

  const cancelCallback = () => {
    useSalaryAdvanceStore.setState({
      confirmation: false,
    });
  };

  return (
    <div className="salary-advance">
      <div className="salary-advance__search">
        <StaffSelector
          value={options?.find((item) => item.value == select_value) || null}
          options={options}
          handleChange={handleChangeStaff}
          placeholder={t("Qidiruv...")}
        />
      </div>
      {isLoading ? (
        <ItemsLoader size={60} />
      ) : details ? (
        <>
          <div className="transfer salary-transfer">
            <StaffInfo />
            <PaymentSection />
            {selectedMonth && <TransactionsHistory />}
          </div>
        </>
      ) : (
        <EmptyState />
      )}
      <ConfirmationModal
        confirmationCallback={() => handleSubmit()}
        cancelCallback={() => cancelCallback()}
        showModal={confirmation}
        title={t("To'lovni tasdiqlaysizmi?")}
      />
    </div>
  );
}
