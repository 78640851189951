import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useMainGroupStore } from "../../../../../Store/ManageGroupsStore/MainGroupStore";
import { getNextStageDetail } from "../../actions";
import { useMemo } from "react";

function ColorToggleButton({
  item,
  property,
  sub_groups_index,
  selectedStaffID,
  selectedBranchID,
  selectedTimeID,
  selectedRoomID,
  selectedDayID,
}) {
  const [alignment, setAlignment] = React.useState(
    item?.find((element) => element.is_selected === true)?.id || 0
  );

  const { updateTeacherData, subGroupsTeacher } = useMainGroupStore();

  const filtered = useMemo(() => {
    return subGroupsTeacher?.find((e, i) => i !== sub_groups_index);
  }, [subGroupsTeacher]);
  const teacher = useMemo(() => {
    return filtered?.teachers?.find((e) => e.is_selected === true);
  }, [filtered]);
  const branch = useMemo(() => {
    return filtered?.branches?.find((e) => e.is_selected === true);
  }, [filtered]);
  const day = useMemo(() => {
    return filtered?.days?.find((e) => e.is_selected === true);
  }, [filtered]);
  const time = useMemo(() => {
    return filtered?.time_slots?.find((e) => e.is_selected === true);
  }, [filtered]);
  const room = useMemo(() => {
    return filtered?.rooms?.find((e) => e.is_selected === true);
  }, [filtered]);

  const otherSubGroup = {
    subGroupID2: filtered?.id || null,
    teacherID2: teacher?.id || null,
    branchID2: branch?.id || null,
    dayID2: day?.recurring || null,
    timeID2: time?.id || null,
    roomID2: room?.id || null,
  };

  const handleChange = React.useCallback(async (e, newAlignment) => {
    setAlignment(newAlignment);
    const subGroupId = subGroupsTeacher[sub_groups_index].id;
    if (property == "days") {
      const recurringDays = subGroupsTeacher?.[sub_groups_index][
        property
      ]?.find((element) => element.id == e.target.value);
      let currentSubGroup = {
        subGroupID1: subGroupId || null,
        teacherID1: selectedStaffID || null,
        branchID1: selectedBranchID || null,
        dayID1: recurringDays.recurring || null,
        timeID1: selectedTimeID || null,
        roomID1: selectedRoomID || null,
      };
      await getNextStageDetail(
        updateTeacherData?.group_id,
        currentSubGroup,
        otherSubGroup
      );
    }
    if (property == "time_slots") {
      const time_slots = subGroupsTeacher?.[sub_groups_index][property]?.find(
        (element) => element.id == e.target.value
      );
      let currentSubGroup = {
        subGroupID1: subGroupId || null,
        teacherID1: selectedStaffID || null,
        branchID1: selectedBranchID || null,
        dayID1: selectedDayID || null,
        timeID1: time_slots?.id || null,
        roomID1: selectedRoomID || null,
      };
      await getNextStageDetail(
        updateTeacherData?.group_id,
        currentSubGroup,
        otherSubGroup
      );
    }

    const selectedFalse = subGroupsTeacher?.[sub_groups_index][property]?.map(
      (element) =>
        element.is_selected === true
          ? { ...element, is_selected: false }
          : element
    );
    const selectedTrue = selectedFalse?.map((element) =>
      element.id == e?.target.value
        ? { ...element, is_selected: true }
        : element
    );
    if (e) {
      useMainGroupStore.setState(({ subGroupsTeacher }) => ({
        subGroupsTeacher: subGroupsTeacher?.map((s, indexID) => {
          return indexID === sub_groups_index
            ? { ...s, [property]: selectedTrue }
            : s;
        }),
      }));
    }
  });
  return (
    <ToggleButtonGroup
      color="primary"
      size="small"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      sx={{
        display: "flex",
        flexWrap: "wrap",
        rowGap: 1,
      }}
    >
      {item?.map((element) => {
        return (
          <ToggleButton key={element.id} value={element.id}>
            {element.name}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}
export default React.memo(ColorToggleButton);
