import { useTranslation } from "react-i18next";

export default function TableHead() {
  const { t } = useTranslation();
  return (
    <ul className="build-search time-table build-table build-table--bold ">
      <li className="build-table__item build-table__item--1">№</li>
      <li className="build-table__item build-table__item--2">{t("subject")}</li>
      <li className="build-table__item build-table__item--5">
        {t("group_type")}
      </li>
      <li className="build-table__item build-table__item--6">
        {t("lesson_plan")}
      </li>
      <li className="build-table__item build-table__item--7">
        {t("course_duration")}
      </li>
      <li className="build-table__item build-table__item--7">
        {t("course_duration")}
      </li>
      <li className="build-table__item build-table__item--5"></li>
    </ul>
  );
}
