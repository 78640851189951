import React from "react";
import { useRoomAvailabilityStore } from "../../../../Store/GroupManagement/RoomAvailabilityStore";

function TabelSubHeading({ title }) {
  const { data } = useRoomAvailabilityStore();

  return (
    <div className="buildingJournal-redhead">
      <div className="buildingJournal-redhead__item stu-num">№</div>
      <div className="buildingJournal-redhead__item room-title">{title}</div>
      {data?.columns?.map((item, index) => {
        return (
          <div key={item.key} className="week-days">
            <div className="buildingJournal-redhead__item week-days-title">
              {item.verbose_name}
            </div>
            <div className="for-contain">
              {data.availability?.[0]?.rooms?.[0]?.days?.["1_3_5"].map(
                (item, index) => {
                  return <span key={index}></span>;
                }
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default React.memo(TabelSubHeading);
