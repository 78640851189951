import { Box } from "@mui/material";
import React from "react";
import { useMemo } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import EmptyState from "../../../../../Components/Empty-content/EmptyState";
import ItemsLoader from "../../../../../Components/Loader/basic loader/ItemsLoader";
import { useRefundStore } from "../../../../../Store/CashControlStore/RefundStore";
import Selector from "./Select";
import StudentDetails from "./StudentDetails";

function StudentSection() {
  const {
    studentDetails,
    students,
    getStudentShortLists,
    getStudentDetails,
    getStudentInfo,
    setLoading,
    isLoading,
    resetData,
    salary,
  } = useRefundStore();

  const inputChangeHandler = (e) => {
    const queryString = new URLSearchParams({
      search_query: e,
    }).toString();
    getStudentShortLists(queryString);
  };

  const handleChange = useCallback(async (e) => {
    const queryString = new URLSearchParams({
      student_id: e?.value,
    }).toString();
    setLoading(true);
    if (e?.value) {
      try {
        await Promise.all([
          getStudentDetails(queryString),
          getStudentInfo(e?.value),
        ]);
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      resetData();
    }
    setLoading(false);
  }, []);

  const studentOptions = useMemo(() => {
    return (
      students?.map((element) => ({
        label: `${element.first_name} ${element.last_name} ${element.middle_name}`,
        value: element.id,
      })) || []
    );
  }, [students]);

  const { t } = useTranslation();

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Selector
          options={studentOptions}
          property={t("O'quvchilar")}
          handleChange={(e) => handleChange(e)}
          inputChangeHandler={(e) => inputChangeHandler(e)}
        />
      </Box>
      {isLoading ? (
        <ItemsLoader size={60} />
      ) : studentDetails ? (
        <>
          <h1 className="student_information">{t("O'quvchi ma'lumotlari")}</h1>
          <StudentDetails />
        </>
      ) : (
        <EmptyState />
      )}
    </>
  );
}
export default React.memo(StudentSection);
