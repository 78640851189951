import React from "react";
import { useMemo } from "react";
import Select from "react-select";
import { useRoomAvailabilityStore } from "../../../../Store/GroupManagement/RoomAvailabilityStore";
import { loadItems } from "../actions";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "40px",
    minHeight: "40px",
    width: "200px",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: "400",
    marginRight: "24px",
  }),
};

export default function Selector() {
  const { lessonStyles, queryParams, setLoader } = useRoomAvailabilityStore();

  const lessonStyleOptions = useMemo(() => {
    return (
      lessonStyles?.map((element) => ({
        label: element.name,
        value: element.value,
      })) || []
    );
  }, [lessonStyles]);

  const defaultValue = lessonStyleOptions?.find(
    (item) => item.value === queryParams.lesson_style
  );

  const handleSelectChange = async (e) => {
    setLoader(true);
    useRoomAvailabilityStore.setState((state) => ({
      queryParams: { ...state.queryParams, lesson_style: e.value },
    }));
    await loadItems(
      new URLSearchParams({ ...queryParams, lesson_style: e.value })
    );
    setLoader(false);
  };

  return (
    <Select
      styles={customStyles}
      value={defaultValue}
      options={lessonStyleOptions}
      onChange={handleSelectChange}
    />
  );
}
