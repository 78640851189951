import React from "react";

function Listitem({ group, index }) {
  return (
    <ul className="attendance-table build-table build-table--light build-table__item">
      <li className="build-table__item">{index + 1}</li>
      <li className="build-table__item">
        <p>{group.verbose_name}</p>
      </li>
      <li className="build-table__item">{group.groups_count}</li>
      <li className="build-table__item">{group.students_count}</li>
      <li className="build-table__item">{group.never_missed_students}</li>
      <li className="build-table__item">{group.missed_with_reason}</li>
      <li className="build-table__item">{group.missed_without_reason}</li>
      <li className="build-table__item color-blue">
        {group.expelled_students_count}
      </li>
      <li className="build-table__item color-red">
        {group.black_listed_students_count}
      </li>
    </ul>
  );
}
export default React.memo(Listitem);
