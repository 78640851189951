import CourseTemplateActions from "../../../Actions/ConfigActions/CourseTemplateActions";
import LoaderActions from "../../../Actions/ComponentsActions/LoaderActions";
import { toastError, toastSuccess } from "../../../Helpers/toasts";
import CommonRepository from "../../../Repositories/Config/CommonRepository";
import CourseTemplateRepository from "../../../Repositories/Config/CourseTemplateRepository";
import MainGroupsRepository from "../../../Repositories/Groups/MainGroupsRepository";

export const loadItems = async (payload, context, dispatch, dispatchLoader) => {
  dispatchLoader({ type: LoaderActions.PAGE_LOADING });
  const queryString = new URLSearchParams({
    ...context.queryParams,
    ...payload,
  }).toString();
  const response = await CourseTemplateRepository.getAllCourses(queryString);
  dispatch({
    type: CourseTemplateActions.PAGE_LOADED,
    payload: {
      data: response.data.data.results,
    },
  });
  dispatchLoader({ type: LoaderActions.PAGE_LOADED });
};

export const onReload = async (context, dispatch, dispatchLoader) => {
  dispatchLoader({ type: LoaderActions.PAGE_LOADING });
  const [subjectsData, groupTypesData, allCoursesData, branches] = await Promise.all([
    CommonRepository.getSubjects(),
    CommonRepository.getGroupTypes(),
    CourseTemplateRepository.getAllCourses(),
    MainGroupsRepository.getAllBranches(),
  ]);

  const subjects = subjectsData.data || [];
  const groupTypes = groupTypesData.data?.data || [];
  const courses = allCoursesData.data?.data?.results || [];

  dispatch({
    type: CourseTemplateActions.PAGE_LOADED,
    payload: { subjects, groupTypes, data: courses, branches: branches.data },
  });
  dispatchLoader({ type: LoaderActions.PAGE_LOADED });
};

export const cancelDeleteAction = (dispatch) => {
  dispatch({
    type: CourseTemplateActions.CANCEL_BUTTON_CLICKED,
  });
};
export const deleteAction = async (e, context, dispatch, dispatchLoader) => {
  e.preventDefault();

  dispatch({ type: CourseTemplateActions.SHOW_DELETE_LOADER, payload: true });

  try {
    const deleteResponse = await CourseTemplateRepository.deleteCourse(
      context.deletingId
    );
    if (deleteResponse.status === true) {
      toastSuccess("Kurs o'chirildi !");
    } else {
      toastError("Kurs o'chirilmadi!");
    }
  } catch (error) {
    console.error(error);
    toastError("Xatolik yuz berdi");
  } finally {
    dispatch({
      type: CourseTemplateActions.SHOW_DELETE_LOADER,
      payload: false,
    });
  }
  loadItems({}, context, dispatch, dispatchLoader);
  cancelDeleteAction(dispatch);
};

export const deleteConfirmAction = (lesson, dispatch) => {
  dispatch({
    type: CourseTemplateActions.DELETE_BUTTON_CLICKED,
    payload: { id: lesson.id },
  });
};

export const updateAction = async (lesson, dispatch) => {
  const { status, data } = await CourseTemplateRepository.getCourseById(
    lesson.id
  );

  if (status === false) {
    toastError("Serverdan ma'lumot olib bo'lmadi");
    dispatch({ type: CourseTemplateActions.CANCEL_BUTTON_CLICKED });
  } else {
    dispatch({
      type: CourseTemplateActions.UPDATE_BUTTON_CLICKED,
      payload: { id: lesson.id, item: data },
    });
  }
};

export const toggleContextMenu = (lesson, dispatch, e, context) => {
  e.stopPropagation();
  dispatch({
    type: CourseTemplateActions.TOGGLE_CONTEXT_MENU,
    payload: context.openContextMenuId ? { id: null } : { id: lesson.id },
  });
};

export const openListTable = (context, id, dispatch) => {
  dispatch({
    type: CourseTemplateActions.TOGGLE_LIST_TABLE,
    payload: context.listId === id ? null : id,
  });
};

export const createAction = (dispatch) => {
  dispatch({
    type: CourseTemplateActions.CREATE_BUTTON_CLICKED,
  });
};
