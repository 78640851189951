import { toastError } from "../../../Helpers/toasts";
import GroupAttendanceDivisionRepository from "../../../Repositories/GroupManagement/GroupAttendanceDivisionRepository";
import { useGroupAttendanceDivisionStore } from "../../../Store/GroupManagement/GroupsAttendanceDivisionStore";

export const onReload = async () => {
  useGroupAttendanceDivisionStore.setState({
    age_groups: null,
    months: null,
    total_counts: null,
    years: null,
    queryYear: null,
    queryMonth: null,
  });
  const { data, status, nonFieldErrors } =
    await GroupAttendanceDivisionRepository.getGroups();

  if (status) {
    useGroupAttendanceDivisionStore.setState({
      age_groups: data.age_groups,
      months: data.months,
      total_counts: data.total_counts,
      years: data.years,
    });
  }
  toastError(nonFieldErrors);
};
export const loadItems = async (queryString) => {
  useGroupAttendanceDivisionStore.setState({
    age_groups: null,
    months: null,
    total_counts: null,
    years: null,
  });
  const { data, status, nonFieldErrors } =
    await GroupAttendanceDivisionRepository.getGroups(queryString);
  if (status) {
    useGroupAttendanceDivisionStore.setState({
      age_groups: data.age_groups,
      months: data.months,
      total_counts: data.total_counts,
      years: data.years,
    });
  }
  toastError(nonFieldErrors);
};
