import { Button } from "@mui/material";
import ReactLoading from "react-loading";

export default function SaveButton({
  isloading,
  callback,
  disabled,
  children,
}) {
  return (
    <Button
      variant="contained"
      onClick={callback}
      sx={{ minWidth: "120px", margin: "5px 0" }}
      disabled={disabled}
    >
      {isloading ? (
        <ReactLoading
          type={"spin"}
          color="#ffffff"
          className="delete_loader"
          height={"25px"}
          width={"25px"}
        />
      ) : (
        `${children}`
      )}
    </Button>
  );
}
