import React from "react";
import { useTranslation } from "react-i18next";
import EmptyState from "../../../../Components/Empty-content/EmptyState";
import ItemsLoader from "../../../../Components/Loader/basic loader/ItemsLoader";
import { useStudentTransferStore } from "../../../../Store/GroupManagement/StudentTransferStore";
import SearchBar from "../components/SearchBar";
import Title from "../components/Title";
import StudentDetails from "./StudentDetails";

export default function CurrentGroupSection() {
  const { isLoading, studentDetail } = useStudentTransferStore();
  const { t } = useTranslation();
  return (
    <div className="transfer__item">
      <Title title={t("O'quvchini izlash")} />
      <SearchBar title={t("Ism, familiya, telefon yoki ID raqami:")} />
      {isLoading ? (
        <ItemsLoader size={50} />
      ) : studentDetail ? (
        <StudentDetails />
      ) : (
        <EmptyState />
      )}
    </div>
  );
}
