import React, { Component, memo } from "react";
import Select from "react-select";

function StaffSelect({ formik, index, staffs }) {
  const staffsFilter = staffs.find(
    (item) => item.value === formik.values.rooms?.[index].responsible_staff
  );
  const id = `staff-select-${index}-${formik.values.rooms?.[index]?.responsible_staff}`;
  return (
    <Select
      isClearable
      name={`rooms.${index}.responsible_staff`}
      value={staffsFilter}
      onChange={(newValue) => {
        const newStaff =
          newValue && newValue.value ? newValue.value : newValue || "";
        formik.setFieldValue(`rooms.${index}.responsible_staff`, newStaff);
      }}
      onBlur={() => {
        formik.handleBlur({
          target: { name: `rooms.${index}.responsible_staff` },
        });
      }}
      options={staffs}
      id={id}
      className={
        formik.touched.rooms?.[index]?.responsible_staff &&
        formik.errors.rooms?.[index]?.responsible_staff
          ? "modal-input multiselect error_input"
          : "modal-input multiselect"
      }
    />
  );
}

export default memo(StaffSelect);
