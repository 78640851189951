import { create } from "zustand";
import { toastError } from "../../Helpers/toasts";
import StudentsPaymentTransactionsRepository from "../../Repositories/CashControl/StudentsPaymentTransactionsRepository";
import ComparisionStaffsRepository from "../../Repositories/Reports/ComporitionStaffsRepository";

export const useComparisionStore = create((set, get) => ({
  results: null,
  orderNumber: null,
  score_types: null,
  score_type: null,
  teachers: null,
  isLoading: false,
  queryParams: {
    page: 1,
  },
  setLoading: (status) => {
    set({
      isLoading: status,
    });
  },
  setOrderNumber: (value) => {
    set({
      orderNumber: value,
    });
  },
  setResults: (value) => {
    set({
      results: value,
    });
  },
  updateParams: (paramItem) => {
    set((prevState) => ({
      queryParams: { ...prevState.queryParams, ...paramItem },
    }));
  },
  onReload: async () => {
    const { data, status, nonFieldErrors } =
      await ComparisionStaffsRepository.examsComparison();
    if (status) {
      set({
        score_types: data.score_types,
        teachers: data.teachers,
        results: null,
        orderNumber: null,
        score_type: null,
      });
    } else toastError(nonFieldErrors);
  },

  loadItems: async (queryString) => {
    const { data, status, nonFieldErrors } =
      await ComparisionStaffsRepository.examsComparison(queryString);
    if (status) {
      set({
        score_types: data.score_types,
        teachers: data.teachers,
      });
    } else toastError(nonFieldErrors);
  },
}));
