import axios from "axios";
import { BASE_API_URL } from "./Constants";
import { isJwtExpired } from "jwt-check-expiration";
import { Navigate } from "react-router-dom";

export const axiosInterceptor = axios.create({
  baseURL: BASE_API_URL,
});

const RedirectLogin = () => {
  return <Navigate to={"/login"} />;
};

const setAuthHeader = (config, token) => {
  config.headers["Authorization"] = "Bearer " + token.access;
  config.headers["Content-Type"] = "application/json";
  return config;
};

axiosInterceptor.interceptors.request.use(
  async (config) => {
    const tokenString = localStorage.getItem("token");
    if (!tokenString) {
      return config;
    }
    const token = JSON.parse(tokenString);
    const checkToken = isJwtExpired(token.access);
    if (checkToken) {
      RedirectLogin();
      throw new Error("Access token expired");
    }
    config = setAuthHeader(config, token);

    // Retrieve the user and branch ID from localStorage
    // const userString = localStorage.getItem("user");
    // if (userString) {
    //   const user = JSON.parse(userString);
    //   if (user.branch && user.branch.length === 1) {
    //     const branchId = user.branch[0].id;
    //     if (config.method.toLowerCase() === "get") {
    //       if (!config.params) {
    //         config.params = {};
    //       }
    //       config.params.branch = branchId;
    //     }
    //   }
    // }

    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInterceptor;
