import { useTranslation } from "react-i18next";
import { useWeeklyReportsStore } from "../../../../Store/ReportsStore/WeeklyReportsStore";
import Selector from "./Select";

function Heading() {
  const { months, years } = useWeeklyReportsStore();

  const options = [
    { label: "2023-yil", value: 2023 },
    { label: "2024-yil", value: 2024 },
  ];

  const yearsOptions = years?.map((option) => ({
    label: option,
    value: option,
  }));

  const monthsOptions = months?.map((option) => ({
    label: option.name,
    value: option.key,
  }));

  const { t } = useTranslation();

  return (
    <div
      style={{
        marginBottom: "10px",
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        width: "fit-content",
        gap: "20px",
      }}
    >
      <Selector options={yearsOptions} property={t("Yillar")} />
      <Selector options={monthsOptions} property={t("Oylar")} />
    </div>
  );
}

export default Heading;
