import { axiosInterceptor } from "../AxiosInterceptor";

export default class StudentsPaymentTransactionsRepository {
  static async studentsPayments(queryString = "") {
    let result = {
      status: false,
      data: null,
    };

    try {
      const response = await axiosInterceptor.get(
        `/finance/payments/?` + queryString
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      if (e.response.data.data) {
        result = {
          ...result,
          nonFieldErrors: e.response.data.data,
        };
      } else result = { ...result, nonFieldErrors: "Ma'lumot olib bo'lmadi!" };
    }
    return result;
  }
  static async deleteTransaction(id, queryString) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.delete(
        `/finance/payments/${id}/?` + queryString
      );
      if (response.status === 200) {
        {
          response.data.error
            ? (result = {
              ...result,
              status: false,
              nonFieldErrors: response.data.error,
            })
            : (result = {
              ...result,
              status: true,
              nonFieldErrors: response.data.message,
            });
        }
      }
    } catch (e) { }
    return result;
  }
}
