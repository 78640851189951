import { axiosInterceptor } from "../AxiosInterceptor";

export default class AttendanceDetailsRepository {
  static async getDetaildData(queryString = "") {
    let result = {
      data: null,
      status: false,
    };
    try {
      const response = await axiosInterceptor.get(
        `/management/attendance/details/?` + queryString
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch {
      result = { ...result, nonFieldErrors: "Ma'lumot mavjud emas!" };
    }
    return result;
  }
  static async saveDetails(data) {
    let result = {
      data: null,
      status: false,
    };
    try {
      const response = await axiosInterceptor.post(
        "management/attendance/save/",
        data
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch {
      result = { ...result, nonFieldErrors: "Saqlab bo'lmadi!" };
    }
    return result;
  }
}
