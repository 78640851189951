import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EmployeeRegistrationAction from "../../../Actions/RegistrationActions/EmployeeRegistrationAction";
import AdminLayout from "../../../AdminLayout";
import DeleteConfirmation from "../../../Components/DeleteConfirmation";
import EmptyContent from "../../../Components/Empty-content/EmptyContent";
import PaginationComponent from "../../../Components/Pagination";
import TitleBar from "../../../Components/TitleBar";
import { LoaderContext } from "../../../Contexts/LoaderContext";
import { EmployeeRegistrationContext } from "../../../Contexts/RegistrationContex/EmployeeRegistrationContext";
import UserPermissionWidget from "../../../UserPermissionWidget";
import {
  cancelDeleteAction,
  createAction,
  deleteAction,
  loadItems,
  onReload,
} from "./actions";
import CreateUpdateModal from "./components/CreateUpdateModal";
import Heading from "./components/Heading";
import ListItem from "./components/ListItem";
import PermissionConfirmation from "./components/PermissionConfirmation";
import RemoveStaffModal from "./components/RemoveStaffModal";
import StaffInfo from "./components/StuffInfoModal";

export default function EmployeeRegistration() {
  const { employee, dispatchEmployee } = useContext(
    EmployeeRegistrationContext
  );
  const { dispatchLoader } = useContext(LoaderContext);
  const [branch, setBranch] = useState([]);
  const [position, setPosition] = useState([]);
  const { t } = useTranslation();

  const pageReload = async () => {
    dispatchEmployee({
      type: EmployeeRegistrationAction.ADD_QUERY_PARAM,
      payload: { page: 1, search_query: "" },
    });
    await onReload(employee, dispatchEmployee, dispatchLoader);
  };

  useEffect(() => {
    pageReload();
    document.body.addEventListener("click", handleClickClosePanelFromOutside);
    return () => {
      document.body.removeEventListener(
        "click",
        handleClickClosePanelFromOutside
      );
    };
  }, []);

  useEffect(() => {
    const handleChange = (branch) => {
      return {
        value: branch.id,
        label: branch.title,
      };
    };
    const handleSetStaffType = (branch) => {
      return {
        value: branch.id,
        label: branch.name,
      };
    };
    const createBranchObject = employee.branches?.map((branch) =>
      handleChange(branch)
    );
    const createPositionObject = employee.staffType?.map((staff) =>
      handleSetStaffType(staff)
    );
    setPosition(createPositionObject);
    setBranch(createBranchObject);
  }, [employee.branches]);

  const paginateTo = useCallback((page) => {
    dispatchEmployee({
      type: EmployeeRegistrationAction.ADD_QUERY_PARAM,
      payload: { page: page },
    });
    loadItems({ page: page }, employee, dispatchEmployee, dispatchLoader);
  });

  const handleClickClosePanelFromOutside = (e) => {
    if (!e.target.className.includes("build-table__item--7")) {
      dispatchEmployee({ type: EmployeeRegistrationAction.CLOSE_CONTEXT_MENU });
    }
    if (!e.target.className.includes("categories")) {
      dispatchEmployee({
        type: EmployeeRegistrationAction.CLOSE_CATEGORIES,
      });
    }
    if (!e.target.className.includes("filter")) {
      dispatchEmployee({
        type: EmployeeRegistrationAction.CLOSE_FILTER_LIST,
      });
    }
    if (!e.target.className.includes("modal-wrapper")) {
      dispatchEmployee({
        type: EmployeeRegistrationAction.CANCEL_BUTTON_CLICKED,
      });
    }
  };
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <AdminLayout>
      <TitleBar
        title={t("staff_title")}
        createAction={(e) => createAction(e, dispatchEmployee)}
        permission={user?.permissions?.create_staff}
      />
      <div className="container remove_scroll">
        <Heading />
        {employee.data?.length ? (
          employee.data.map((staff, index) => (
            <ListItem staff={staff} key={index} index={index} />
          ))
        ) : (
          <EmptyContent />
        )}
        <PaginationComponent context={employee} callback={paginateTo} />
      </div>
      <UserPermissionWidget hasPermission={user?.permissions?.create_staff}>
        <CreateUpdateModal branch={branch} position={position} />
      </UserPermissionWidget>
      <StaffInfo branch={branch} position={position} />
      <RemoveStaffModal
        cancelCallback={() => cancelDeleteAction(dispatchEmployee)}
        showModal={employee}
      />
      <UserPermissionWidget
        // hasPermission={user?.permissions?.hire_staff}
        hasPermission={true}
      >
        <PermissionConfirmation
          cancelCallback={() => cancelDeleteAction(dispatchEmployee)}
          showModal={employee}
        />
      </UserPermissionWidget>
      <UserPermissionWidget
        // hasPermission={user?.permissions?.delete_staff}
        hasPermission={true}
      >
        <DeleteConfirmation
          deleteCallback={(e) =>
            deleteAction(e, employee, dispatchEmployee, dispatchLoader)
          }
          cancelCallback={() => cancelDeleteAction(dispatchEmployee)}
          showModal={employee}
        />
      </UserPermissionWidget>
    </AdminLayout>
  );
}
