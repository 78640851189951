import React from "react";
import { useReadyReportStore } from "../../../../Store/ReportsStore/ReadyReportStore";
export default function ListItem({ item, index }) {
  const { columns } = useReadyReportStore();

  return (
    <ul className="reports_table_head build-table build-table--light build-table__item">
      <li className="build-table__item ">{index + 1}</li>
      <li className="build-table__item">{item?.name}</li>
      {columns?.map((column) => {
        return (
          <li key={column.key} className="build-table__item align_center">
            {item[column.key]}
          </li>
        );
      })}
      <li className="build-table__item align_center">{item.total_count}</li>
    </ul>
  );
}
