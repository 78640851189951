import { useAgeGroupReportStore } from "../../../../Store/ReportsStore/AgeGroupReportStore";
import TableFooter from "./TableFooter";
import TableHead from "./TableHead";
import TableItems from "./TableItems";

function Table() {
  const { results } = useAgeGroupReportStore();
  return (
    <table border={"1px"} className="reports-table">
      <TableHead />
      <tbody>
        {results?.map((element) => {
          return <TableItems key={element.id} element={element} />;
        })}
      </tbody>
      <TableFooter />
    </table>
  );
}

export default Table;
