import React from "react";
import { useAttendanceDetailsStore } from "../../../../Store/AttendanceFormStore/AttendanceDeatilsStore";
import TeacherTitle from "../../AttendanceList/components/TeacherTitle";
import DetailBtn from "./DateDetail";

function Details() {
  const { data } = useAttendanceDetailsStore();
  const meta = React.useMemo(() => data?.meta, [data]);

  if (!meta) {
    return null;
  }
  const {
    main_teacher,
    lesson_style,
    branch,
    group_number,
    age_group,
    recurring_days,
    starts_at,
    ends_at,
  } = meta;

  return (
    <div className="journal-config attendanceDetailsHeader">
      <div className="journal-box journal-right">
        <DetailBtn title={data?.date} />
        <DetailBtn title={main_teacher.fullname} />
        <DetailBtn title={group_number} />
        <DetailBtn title={lesson_style.verbose_name} />
        <DetailBtn title={recurring_days} />
        <DetailBtn title={`${starts_at} - ${ends_at}`} />
        <DetailBtn title={age_group.verbose_name} />
        <DetailBtn title={branch.name} />
      </div>
    </div>
  );
}
export default React.memo(Details);
