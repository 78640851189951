import React from "react";
import { useTranslation } from "react-i18next";
import { useStudentTransferStore } from "../../../../Store/GroupManagement/StudentTransferStore";

export default function GroupDetails() {
  const { groupDetail } = useStudentTransferStore();
  const { t } = useTranslation();
  return (
    <div className="transfer-info transfer-info_huge">
      <h1 className="student_information">{t("Guruh ma'lumotlari")}</h1>
      <div className="transfer-info__item">
        <div className="transfer-infos">{t("Ushbu guruh o‘qituvchisi:")}</div>
        <div className="transfer-infos">{groupDetail?.teachers}</div>
      </div>
      <div className="transfer-info__item">
        <div className="transfer-infos">{t("Guruh haqida ma’lumot:")}</div>
        <div className="transfer-infos">
          <span>{groupDetail?.number}</span>
          <span>{groupDetail?.age_group}</span>
          <span>{groupDetail?.month}</span>
        </div>
      </div>
      <div className="transfer-info__item">
        <div className="transfer-infos">{t("Tugallanmagan darslar soni:")}</div>
        <div className="transfer-infos">
          <span>{groupDetail?.unfinished_lessons_count}</span>
        </div>
      </div>
      <div className="transfer-info__item">
        <div className="transfer-infos">{t("Telefon raqam:")}</div>
        <div className="transfer-infos">{groupDetail?.phones}</div>
      </div>
    </div>
  );
}
