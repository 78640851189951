import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useReadyReportStore } from "../../../../Store/ReportsStore/ReadyReportStore";

export default function ColorToggleButton() {
  const { setLoading, loadItems, updateParams, filterset, queryParams } =
    useReadyReportStore();

  const handleChange = async (event, newAlignment) => {
    setLoading(true);
    updateParams({ subject: newAlignment });
    await loadItems(
      new URLSearchParams({
        ...queryParams,
        subject: newAlignment,
      }).toString()
    );
    setLoading(false);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={queryParams.subject}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      size="small"
      sx={{ marginBottom: "10px" }}
    >
      {filterset?.subjects?.map((item) => {
        return (
          <ToggleButton
            size="small"
            key={item.value}
            value={item.value || ""}
            sx={{
              fontSize: "13px",
              textTransform: "capitalize",
              fontFamily: "Inter",
              fontWeight: 400,
            }}
          >
            {item.name}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}
