import { Radio } from "@mui/material";
import { useFormik } from "formik";
import React, { useCallback, useContext, useState } from "react";
import ReactLoading from "react-loading";
import * as Yup from "yup";
import formatDate from "../../../../Components/FormatDate";
import { LoaderContext } from "../../../../Contexts/LoaderContext";
import { EmployeeRegistrationContext } from "../../../../Contexts/RegistrationContex/EmployeeRegistrationContext";
import { toastError, toastSuccess } from "../../../../Helpers/toasts";
import EmployeeRepository from "../../../../Repositories/Registration/EmployeeRepository";
import { loadItems } from "../actions";

let initialValues = {
  first_working_day: "",
  active_working_day: null,
  fixed_salary: "",
  kpi_percentage: "",
  radio_value: "",
};

const validationSchema = (updateSalaryId) => {
  return Yup.object().shape(
    {
      first_working_day: Yup.string(),
      active_working_day: Yup.string()
        .nullable()
        .when([], {
          is: () => !updateSalaryId, // Validation is applied when updateSalaryId is falsy
          then: Yup.string()
            .nullable()
            .required("Ushbu maydonni to'ldirish kerak"),
        }),
      fixed_salary: Yup.string().when("kpi_percentage", {
        is: (kpiPercentage) => !kpiPercentage || kpiPercentage.trim() === "",
        then: Yup.string().required("To'ldiring(KPI kiritilmasa)."),
      }),
      kpi_percentage: Yup.string().when("fixed_salary", {
        is: (fixedSalary) => !fixedSalary || fixedSalary.trim() === "",
        then: Yup.string().required("To'ldiring(Fixed oylik kiritilmasa)"),
      }),
    },
    ["kpi_percentage", "fixed_salary"]
  );
};

export default function PermissionConfirmation({ cancelCallback, showModal }) {
  const { employee, dispatchEmployee } = useContext(
    EmployeeRegistrationContext
  );
  const { dispatchLoader } = useContext(LoaderContext);
  const [loader, setLoader] = useState(false);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema(showModal.updateSalaryId),
    onSubmit: (values) => {
      setLoader(true);
      delete values.radio_value;
      if (values.fixed_salary === "") {
        values.fixed_salary = 0;
      }
      if (values.kpi_percentage === "") {
        values.kpi_percentage = 0;
      }
      if (formik.values.first_working_day === "") {
        const date = formatDate();
        const data = {
          ...values,
          working_status: 2,
          first_working_day: date,
        };
        updateItem(data);
      } else updateItem(values);
    },
  });
  const updateItem = useCallback(async (data) => {
    const responseData = await EmployeeRepository.updateStaffSalary(
      data,
      showModal.permissionId || showModal.updateSalaryId
    );

    setLoader(false);
    if (responseData.status === true) {
      toastSuccess("Ishga qabul qilindi");
      loadItems({}, employee, dispatchEmployee, dispatchLoader);
      cancelCallback();
      formik.resetForm();
    } else {
      toastError("Ishga qabul qilib bo'lmadi!");
    }
  });

  const radioHandler = (e) => {
    formik.setFieldValue("fixed_salary", "");
    formik.setFieldValue("kpi_percentage", "");
    formik.setFieldValue("radio_value", e.target.value);
  };

  const cancelBtn = () => {
    cancelCallback();
    setLoader(false);
    formik.resetForm();
  };

  return (
    <div
      id="modal"
      className={
        showModal.permissionId || showModal.updateSalaryId
          ? "modal modal-select active"
          : "modal modal-select"
      }
    >
      <form
        onClick={(e) => e.stopPropagation()}
        onSubmit={formik.handleSubmit}
        action=""
        className="modal-wrapper get--employee"
      >
        <div className="modal-inner blue_border">
          {employee.updateSalaryId ? (
            <h4 className="modal__title blue_background">
              Ushbu xodimni oyligini o'zgartirishga rozimisiz?
            </h4>
          ) : (
            <h4 className="modal__title blue_background">
              Ushbu xodimni ishga olishga rozimisiz?
            </h4>
          )}
          {showModal.updateSalaryId ? null : (
            <>
              <div className="modal-select__box remove_staff_modal">
                <label htmlFor="login">Ishga olingan sana</label>
                <input
                  className={
                    formik.touched.first_working_day &&
                    formik.errors.first_working_day
                      ? "modal-input error_input"
                      : "modal-input "
                  }
                  name="first_working_day"
                  id="login"
                  value={formik.values.first_working_day || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="date"
                />
                {formik.touched.first_working_day &&
                formik.errors.first_working_day ? (
                  <p className="input-error-message ">
                    {formik.errors.first_working_day}
                  </p>
                ) : null}
              </div>
              <div className="modal-select__box remove_staff_modal">
                <label htmlFor="active">Ishga olish</label>
                <input
                  className={
                    formik.touched.active_working_day &&
                    formik.errors.active_working_day
                      ? "modal-input error_input"
                      : "modal-input "
                  }
                  name="active_working_day"
                  id="active"
                  value={formik.values.active_working_day || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="date"
                />
                {formik.touched.active_working_day &&
                formik.errors.active_working_day ? (
                  <p className="input-error-message ">
                    {formik.errors.active_working_day}
                  </p>
                ) : null}
              </div>
            </>
          )}
          <div className={`student--dicount_place`}>
            <div
              className={`registir-discount__type ${
                formik.values.radio_value == "a" ? "checked-radio" : ""
              }`}
            >
              <Radio
                sx={{ padding: "0 8px" }}
                checked={formik.values.radio_value === "a"}
                onChange={(e) => radioHandler(e)}
                value="a"
                name="radio_value"
                inputProps={{ "aria-label": "A" }}
              />
              <div className="registir-discount__item">
                <div className="modal-select__box remove_staff_modal">
                  <label htmlFor="validity">Fixed oylik</label>
                  <input
                    className={
                      formik.touched.fixed_salary && formik.errors.fixed_salary
                        ? "modal-input error_input"
                        : "modal-input "
                    }
                    disabled={formik.values.radio_value == "a" ? false : true}
                    id="validity"
                    name="fixed_salary"
                    placeholder="so'm"
                    onChange={formik.handleChange}
                    value={formik.values.fixed_salary}
                    type="number"
                    {...formik.getFieldProps("fixed_salary")}
                  />
                  {formik.touched.fixed_salary && formik.errors.fixed_salary ? (
                    <p className="input-error-message ">
                      {formik.errors.fixed_salary}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div
              className={`registir-discount__type ${
                formik.values.radio_value == "b" ? "checked-radio" : ""
              }`}
            >
              <Radio
                sx={{ padding: "0 8px" }}
                checked={formik.values.radio_value === "b"}
                onChange={(e) => radioHandler(e)}
                value="b"
                name="radio_value"
                inputProps={{ "aria-label": "B" }}
              />
              <div className="registir-discount__item">
                <div className="modal-select__box remove_staff_modal">
                  <label htmlFor="month">KPI asosida (har bir guruhdan)</label>
                  <input
                    className={
                      formik.touched.kpi_percentage &&
                      formik.errors.kpi_percentage
                        ? "modal-input error_input"
                        : "modal-input "
                    }
                    id="month"
                    name="kpi_percentage"
                    placeholder="%"
                    value={formik.values.kpi_percentage}
                    onChange={formik.handleChange}
                    type="number"
                    disabled={formik.values.radio_value == "b" ? false : true}
                    {...formik.getFieldProps("kpi_percentage")}
                  />
                  {formik.touched.kpi_percentage &&
                  formik.errors.kpi_percentage ? (
                    <p className="input-error-message ">
                      {formik.errors.kpi_percentage}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="input-buttons">
          <button
            id="modalClose"
            type="button"
            className="input-btn"
            onClick={() => cancelBtn()}
          >
            Bekor qilish
          </button>
          <button
            className="input-btn input-btn--submit blue_background"
            type="submit"
            disabled={loader || !formik.isValid}
          >
            {loader ? (
              <ReactLoading
                type={"spin"}
                color="#ffffff"
                className="delete_loader"
                height={"30px"}
                width={"30px"}
              />
            ) : (
              "Ha, ishga olinsin"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
