import React, { useEffect } from "react";
import Logo from "../../../Components/Logo";
import LoginForm from "./Components/LoginForm";
import LoginFormImage from "./Components/LoginFormImage";
import LoginFormTitle from "./Components/LoginFormTitle";

export default function LoginPage() {
  useEffect(() => {
    localStorage.removeItem("user");
  }, []);

  return (
    <section className="wrapper">
      <div className="log-in">
        <div className="log-in__content">
          <Logo />
          <LoginFormTitle />
          <LoginForm />
        </div>
        <LoginFormImage />
      </div>
    </section>
  );
}
