import React from "react";

export default function ListItem({ month, index }) {
  return (
    <ul className="studentAttendance-table build-table build-table--light build-table__item">
      <li className="build-table__item">{index + 1}</li>
      <li className="build-table__item">
        <p>{month.name}</p>
      </li>
      <li className="build-table__item">{month.groups_count} ta</li>
      <li className="build-table__item">{month.students_count} ta</li>
      <li className="build-table__item">
        {month.high_scoring_students_count} ta
      </li>
      <li className="build-table__item">
        {month.medium_scoring_students_count} ta
      </li>
      <li className="build-table__item">
        {month.low_scoring_students_count} ta
      </li>
    </ul>
  );
}
