import React from "react";
import { useTranslation } from "react-i18next";
import { useAttendanceStore } from "../../../../Store/AttendanceFormStore/AttendanceForm";
import DetailsBtns from "./DetailsBtns";
import LessonStyleButton from "./LessonStyleButton";
import MonthSelector from "./MonthSelector";
import TeacherTitle from "./TeacherTitle";

function Details({ groupId }) {
  const { data } = useAttendanceStore();
  const meta = React.useMemo(() => data?.meta, [data]);

  if (!meta) {
    return null;
  }

  const {
    main_teacher,
    lesson_style,
    branch,
    group_number,
    age_group,
    video_lesson,
    group_id,
    recurring_days,
    starts_at,
  } = meta;
  const { t } = useTranslation();
  return (
    <div className="journal-config">
      <TeacherTitle main_teacher={main_teacher} />
      <div className="journal-box journal-right">
        <DetailsBtns title={age_group.verbose_name} />
        <MonthSelector groupId={groupId} />
        <DetailsBtns title={recurring_days} />
        <DetailsBtns title={starts_at} />
      </div>
      <div className="journal-box">
        <DetailsBtns title="Main teacher" />
        <DetailsBtns title={lesson_style.verbose_name} />
        <DetailsBtns title={branch.name} />
        <DetailsBtns title={group_number} />
        {video_lesson && (
          <LessonStyleButton
            title={t("Video darsga o'tish")}
            video_lesson={video_lesson}
          />
        )}
      </div>
    </div>
  );
}
export default React.memo(Details);
