export default class StudentAttachmentAction {
    static ADD_QUERY_PARAM = "ADD_QUERY_PARAM";
    static PAGE_LOADED = "PAGE_LOADED";
    static STUDENT_ADDED = "STUDENT_ADDED";
    static CHANGE_DATA = "CHANGE_DATA";
    static TOGGLE_FILTER_LIST = "TOGGLE_FILTER_LIST";
    static CLOSE_FILTER_LIST = "CLOSE_FILTER_LIST";
    static CHANGE_FILTERED_DATA = "CHANGE_FILTERED_DATA";
    static CHANGE_GROUP_STUDENT = "CHANGE_GROUP_STUDENT";
  }
  