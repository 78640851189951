import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { LoaderContext } from "../../../../Contexts/LoaderContext";
import { useAttendanceStore } from "../../../../Store/AttendanceFormStore/AttendanceForm";
import { onReload, removeStudentFromGroup } from "../actions";

export default function UnGroupModal() {
  const [loader, setLoader] = useState(false);
  const { removeId, exclusionReasons, data, updateAttendanceDates } =
    useAttendanceStore();
  const { dispatchLoader } = useContext(LoaderContext);
  const Id = useParams();
  const groupId = Id.groupId;

  const { t } = useTranslation();

  useEffect(() => {
    formik.resetForm();
  }, [removeId]);

  let initialValues = {
    comment: "",
    reason: "",
    next_contact_date: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: false,
    validationSchema: Yup.object({
      comment: Yup.string(),
      next_contact_date: Yup.string(),
      reason: Yup.string().required(t("O'quvchi haqida!")),
    }),
    onSubmit: async (values) => {
      setLoader(true);
      const dataForm = {
        group: data?.meta?.group_id,
        next_contact_date:
          formik.values.next_contact_date == ""
            ? null
            : formik.values.next_contact_date,
      };
      const status = await removeStudentFromGroup(removeId, {
        ...values,
        ...dataForm,
      });
      if (status) {
        await onReload(groupId, updateAttendanceDates, dispatchLoader);
      }
      setLoader(false);
    },
  });

  return (
    <div
      id="modal"
      className={removeId ? "modal modal-select active" : "modal modal-select"}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="modal-wrapper"
        style={{ width: "480px" }}
      >
        <div className="modal-inner red-border">
          <h4 className="modal__title red-background ">
            {t("Ushbu O'quvchi guruhdan chiqarilsinmi ?")}
          </h4>
          <div className="modal-select__box remove_staff_modal">
            <label htmlFor="reason">{t("O'quvchi haqida")}</label>
            <select
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.reason || ""}
              className={
                formik.touched.reason && formik.errors.reason
                  ? "modal-input error_input"
                  : "modal-input "
              }
              id="reason"
              name="reason"
            >
              <option
                className="course_disabled_option"
                disabled
                hidden
                value={""}
              ></option>
              {exclusionReasons?.map((element) => {
                return (
                  <option key={element.value} value={element.value}>
                    {element.verbose_name}
                  </option>
                );
              })}
            </select>
            {formik.touched.reason && formik.errors.reason ? (
              <p className="input-error-message ">{formik.errors.reason}</p>
            ) : null}
          </div>
          <div className="modal-select__box remove_staff_modal">
            <label htmlFor="comment">{t("Izoh")}</label>
            <textarea
              id="comment"
              className="commentRemoveStudent modal-input"
              name="comment"
              onChange={formik.handleChange}
              value={formik.values.comment}
            ></textarea>
          </div>
          <div className="modal-select__box remove_staff_modal next--contact">
            <label htmlFor="date">{t("Qayta bo'glanish")}</label>
            <input
              name="next_contact_date"
              id="date"
              className="modal-input"
              value={formik.values.next_contact_date || ""}
              onChange={formik.handleChange}
              type="date"
            />
          </div>
        </div>
        <div className="input-buttons">
          <button
            id="modalClose"
            type="button"
            className="input-btn"
            onClick={() =>
              useAttendanceStore.setState({
                removeId: null,
              })
            }
          >
            {t("Bekor qilish")}
          </button>
          <button
            className="input-btn input-btn--submit red-background "
            type="submit"
            disabled={loader}
            onClick={formik.handleSubmit}
          >
            {loader ? (
              <ReactLoading
                type={"spin"}
                color="#ffffff"
                className="delete_loader"
                height={"30px"}
                width={"30px"}
              />
            ) : (
              t("Guruhdan olish")
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
