import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useStudentLessonAcquitationStore } from "../../../../Store/GroupManagement/StudentLessonAcqusitationStore";
import { useLoaderStore } from "../../../../Store/Loader/LoaderStore";
import { loadItems } from "../actions";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "37px",
    minHeight: "37px",
    width: "100%",
    minWidth: "150px",
    fontSize: "13px",
    fontFamily: "Inter",
    fontWeight: "400",
  }),
};

export default function MonthSelector({ options, property }) {
  const { queryParams, uploadParams } = useStudentLessonAcquitationStore();
  const { setLoader } = useLoaderStore();
  const { t } = useTranslation();

  const handleSelectChange = async (e) => {
    setLoader(true);
    if (property === t("Oylar")) {
      let queryString = queryParams;
      if (e?.value == undefined) {
        delete queryString.month;
      } else {
        queryString = { ...queryParams, month: e?.value, page: 1 };
      }
      uploadParams(queryString);
      await loadItems(new URLSearchParams(queryString).toString());
    }
    if (property === t("Filiallar")) {
      let queryString = queryParams;
      if (e?.value == undefined) {
        delete queryString.branch;
      } else {
        queryString = { ...queryParams, branch: e?.value, page: 1 };
      }
      uploadParams(queryString);
      await loadItems(new URLSearchParams(queryString).toString());
    }
    if (property === t("Fanlar")) {
      let queryString = queryParams;
      if (e?.value == undefined) {
        delete queryString.subject;
      } else {
        queryString = { ...queryParams, subject: e?.value, page: 1 };
      }
      uploadParams(queryString);
      await loadItems(new URLSearchParams(queryString).toString());
    }
    setLoader(false);
  };

  return (
    <div className="normal-select-bar ">
      <Select
        className="basic-single manage_group_select"
        classNamePrefix="select"
        placeholder={property}
        styles={customStyles}
        options={options}
        isClearable
        onChange={handleSelectChange}
      />
    </div>
  );
}
