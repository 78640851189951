import React, { useCallback } from "react";
import { useAttendanceStore } from "../../../../Store/AttendanceFormStore/AttendanceForm";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import DiscountModal from "./DiscountModal";
import UserPermissionWidget from "../../../../UserPermissionWidget";
import IconDiscount2 from "./DiscountIcon";
import { useTranslation } from "react-i18next";

function ListItem({ student }) {
  const { data, getStudentInfo } = useAttendanceStore();
  const { estimated_dates } = data;
  const card_icons = JSON.parse(localStorage.getItem("card_icons"));
  const user = JSON.parse(localStorage.getItem("user"));

  const handleChangeDiscount = useCallback(() => {
    useAttendanceStore.setState({
      discountId: student._id,
    });
  });
  const studentInfoAction = async (id) => {
    useAttendanceStore.setState({
      modalLoader: true,
    });
    await getStudentInfo(id);
    useAttendanceStore.setState({
      modalLoader: false,
    });
  };

  const studentDiscounts = data?.discounts?.values?.[student?._id];
  const discount =
    studentDiscounts?.smart_card_winner ||
    studentDiscounts?.nailed_exam ||
    studentDiscounts?.special;
  const { t } = useTranslation();

  return (
    <div
      className={`desk-exam-config forHover ${discount && "lucky-student"}`}
      key={student.id}
    >
      <div className="desk-config__item stu-num">{student.id}</div>
      <div className="desk-config__item stu-fullname desk-fname">
        <span
          className="attendance-student-info"
          onClick={() => studentInfoAction(student._id)}
        >
          {student.fullname}
        </span>
        <UserPermissionWidget
          hasPermission={user?.permissions?.exclude_student}
        >
          <div
            onClick={() =>
              useAttendanceStore.setState({
                removeId: student._id,
              })
            }
            className="deleteStudentent"
          >
            <img src="/images/trash.png" alt="trash" />
          </div>
        </UserPermissionWidget>
      </div>
      <div className="desk-config__item stu-idnumber">{student.student_id}</div>
      <div className="desk-config__item stu-age">{student.age}</div>
      {estimated_dates?.map((week) => {
        return week.days.map((day, index) => {
          const attendance = student.attendance?.[day.date];
          return (
            <div
              key={index}
              className="desk-config__item desk-yellow desk-yellow_third for-attendance-cards "
            >
              {attendance ? (
                <div
                  className={`attendance_cards ${
                    attendance?.is_expelled ? "student-expelled" : ""
                  }`}
                >
                  {attendance?.is_missed && attendance?.with_reason ? (
                    <div className="card_icon_journal">
                      <img src="/images/sababli.png" alt="icon" />{" "}
                    </div>
                  ) : attendance?.is_missed && !attendance?.with_reason ? (
                    <div className="card_icon_journal">
                      <img src="/images/sababsiz.png" alt="icon" />{" "}
                    </div>
                  ) : !attendance?.is_missed &&
                    attendance?.is_missed !== undefined ? (
                    <div className="card_icon_journal">
                      <img src="/images/darsga keldi.png" alt="icon" />{" "}
                    </div>
                  ) : (
                    ""
                  )}
                  {Object.entries(attendance?.cards || {}).map(
                    (element, index) => {
                      return (
                        <div key={index} className="card_icon_journal">
                          <img src={card_icons?.[element[0]].icon} alt="icon" />{" "}
                          <CloseIcon fontSize="12px" />
                          {element[1]}
                        </div>
                      );
                    }
                  )}
                </div>
              ) : null}
              {(attendance || attendance?.exclusion_reason) && (
                <div
                  className={`attendance-cards ${
                    attendance.is_expelled ? "attendance-cards__none" : ""
                  }`}
                >
                  <p style={{ marginBottom: "2px" }}>{attendance?.date}</p>
                  {attendance?.is_missed && attendance?.with_reason && (
                    <div className="attendance-cards-list">
                      <img src="/images/sababli.png" alt="" />
                      <p>{t("Darsga sababli kelmadi")}</p>
                    </div>
                  )}
                  {attendance?.is_missed && !attendance?.with_reason && (
                    <div className="attendance-cards-list">
                      <img src="/images/sababsiz.png" alt="" />
                      <p>{t("Darsga sababsiz kelmadi")}</p>
                    </div>
                  )}
                  {attendance?.is_missed && attendance?.comment && (
                    <div className="attendance-cards-list">
                      <img src="/images/comment-icon.png" alt="" />
                      <p>{attendance?.comment}</p>
                    </div>
                  )}
                  {!attendance.is_missed &&
                    attendance?.is_missed !== undefined && (
                      <div className="attendance-cards-list">
                        <img src="/images/darsga keldi.png" alt="" />
                        <p>{t("Darsga keldi")}</p>
                      </div>
                    )}
                  {attendance.is_expelled && (
                    <div className="attendance-cards-list">
                      <p>{attendance.exclusion_reason}</p>
                    </div>
                  )}
                  {attendance?.cards &&
                    Object.entries(attendance?.cards).map(
                      ([key, value], index) => (
                        <div className="attendance-cards-list" key={index}>
                          {card_icons && card_icons[key] && (
                            <>
                              <img src={card_icons[key].icon} alt="icon" />{" "}
                              <p>
                                {" "}
                                {card_icons[key].verbose_name} - {value} ta
                              </p>
                            </>
                          )}
                        </div>
                      )
                    )}
                </div>
              )}
            </div>
          );
        });
      })}
      {data?.taken_exams?.map((item) => {
        return (
          <div
            key={item.key}
            className="desk-config__item desk-yellow desk-yellow_third"
          >
            {student.exam_results[item.key]}
          </div>
        );
      })}
      <div className="desk-config__item payment-status">
        <span className="desk-smart-yellow">
          {data?.smart_cards_count[student._id]}
        </span>
      </div>
      {data?.taken_exams.length > 0 ? (
        <div className="desk-config__item payment-status">
          <span className="desk-green">
            {data?.exam_results_overall[student._id]}
          </span>
        </div>
      ) : null}
      <div className="desk-config__item payment-status">
        <Button
          onClick={handleChangeDiscount}
          sx={{
            fontSize: "12px",
            width: "100%",
            height: "100%",
            borderRadius: "0",
          }}
          size="small"
        >
          <IconDiscount2 />
        </Button>
        <DiscountModal studentId={student._id} />
      </div>
      <div className="desk-config__item payment-status">
        {student.payment?.is_fully_paid ? (
          <span className="desk-green">{student.payment?.verbose}</span>
        ) : student.payment?.is_paid ? (
          <span className="desk-green">{student.payment?.verbose}</span>
        ) : (
          <span className="desk-red">{student.payment?.verbose}</span>
        )}
      </div>
    </div>
  );
}
export default React.memo(ListItem);
