import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";

export default function BackButton() {
  const navigate = useNavigate();
  return (
    <IconButton
      // className="back_btn"
      color="primary"
      edge="start"
      size="small"
      sx={{
        "@media (max-width: 660px)": {
          background: "#2192ee10 !important",
          marginLeft: "28px !important",
          fontSize: "10px !important",
          width: "32px",
          height: "32px",
        },
        "@media (max-width: 1600px)": {
          background: "#2192ee10 !important",
          marginLeft: "28px !important",
          fontSize: "18px",
        },
      }}
      onClick={() => navigate(-1, { replace: true })}
    >
      <ArrowBackIcon className="back_icon" />
    </IconButton>
  );
}
