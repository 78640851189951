import { useEffect } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import AdminLayout from "../../../AdminLayout";
import EmptyContent from "../../../Components/Empty-content/EmptyContent";
import ItemsLoader from "../../../Components/Loader/basic loader/ItemsLoader";
import TitleBar from "../../../Components/TitleBar";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import { useDailyReportsStore } from "../../../Store/ReportsStore/DaylyReportsStore";
import ExtraTable from "./components/ExtraTable";
import Heading from "./components/Heading";
import Table from "./components/Table";

function DaylyFinance() {
  const { onReload, isLoading, results } = useDailyReportsStore();
  const { setLoader } = useLoaderStore();
  const { t } = useTranslation();

  const pageLoad = useCallback(async () => {
    setLoader(true);
    await onReload();
    setLoader(false);
  }, []);

  useEffect(() => {
    pageLoad();
  }, []);

  return (
    <AdminLayout>
      <TitleBar title={t("Kunlik tushum hisobotlari")} />
      <div className="container">
        <Heading />
        {isLoading ? (
          <ItemsLoader size={70} />
        ) : results ? (
          <div className="daily-finance-tables">
            <Table />
            <ExtraTable />
          </div>
        ) : (
          <EmptyContent />
        )}
      </div>
    </AdminLayout>
  );
}

export default DaylyFinance;
