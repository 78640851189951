import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import StudentAttachmentAction from "../../Actions/StudentAttachment/StudentAttachmentAction";
import AdminLayout from "../../AdminLayout";
import { LoaderContext } from "../../Contexts/LoaderContext";
import { StudentAttachmentContext } from "../../Contexts/StudentAttachmentContext/StudentAttachmentContext";
import { filterGroups, onReload } from "./actions";
import DataTableStudents from "./components/DataTableStudents";
import FilteredModal from "./components/FilteredModal";

export default function StudentAssign() {
  const { dispatchLoader } = useContext(LoaderContext);
  const { studentAttachment, dispatchStudentAttachment } = useContext(
    StudentAttachmentContext
  );
  const [days, setDays] = useState(null);
  const [branches, setBranches] = useState(null);
  const [times, setTimes] = useState(null);
  const [subjects, setSubjects] = useState(null);
  const [groupTypes, setGroupTypes] = useState(null);
  const [staffs, setStaffs] = useState(null);

  let { groupId } = useParams();

  const pageReload = useCallback(async () => {
    dispatchStudentAttachment({
      type: StudentAttachmentAction.STUDENT_ADDED,
      payload: null,
    });
    await onReload(
      groupId,
      studentAttachment,
      dispatchStudentAttachment,
      dispatchLoader
    );
  });

  useEffect(() => {
    pageReload();
  }, []);

  useEffect(() => {
    if (studentAttachment) {
      const createObject = (data, handleChangeFunction) =>
        data?.map((item) => handleChangeFunction(item));

      setDays(
        createObject(studentAttachment.modalLessonDays, handleChangeDays)
      );
      setTimes(
        createObject(studentAttachment.modalLessonTimes, handleChangeTimes)
      );
      setBranches(
        createObject(studentAttachment.branches, handleChangeBranches)
      );
      setSubjects(createObject(studentAttachment.modalSubjects, handleChange));
      setGroupTypes(
        createObject(studentAttachment.modalGroupTypes, handleChangeGroupType)
      );
      setStaffs(createObject(studentAttachment.staffs, handleChangeStaffs));
    }
  }, [studentAttachment]);

  const handleChange = (props) => ({
    value: props.id,
    label: props.name,
  });

  const handleChangeDays = (props) => ({
    value: props.value,
    label: props.name,
  });

  const handleChangeBranches = (props) => ({
    value: props.id,
    label: props.title,
  });

  const handleChangeTimes = (props) => ({
    value: props.id,
    label: `${props.starts_at} - ${props.ends_at}`,
  });

  const handleChangeStaffs = (props) => ({
    value: props.id,
    label: `${props.first_name}  ${props.last_name}`,
  });

  const handleChangeGroupType = (type) => ({
    value: type[0],
    label: type[1],
  });

  const data = { branches, days, times, subjects, groupTypes, staffs };

  return (
    <AdminLayout>
      <div className="table_container">
        <DataTableStudents groupId={groupId} connectionState={[]} />
        <FilteredModal data={data} />
      </div>
    </AdminLayout>
  );
}
