export default class StudentsRegistrationActions {
  static CREATE_BUTTON_CLICKED = "CREATE_BUTTON_CLICKED";
  static ADD_QUERY_PARAM = "ADD_QUERY_PARAM";
  static PAGE_LOADED = "PAGE_LOADED";
  static TOGGLE_CONTEXT_MENU = "TOGGLE_CONTEXT_MENU";
  static CLOSE_CONTEXT_MENU = "CLOSE_CONTEXT_MENU";
  static DELETE_BUTTON_CLICKED = "DELETE_BUTTON_CLICKED";
  static CANCEL_BUTTON_CLICKED = "CANCEL_BUTTON_CLICKED";
  static UPDATE_BUTTON_CLICKED = "UPDATE_BUTTON_CLICKED";
  static TOGGLE_FILTER_LIST = "TOGGLE_FILTER_LIST";
  static CLOSE_FILTER_LIST = "CLOSE_FILTER_LIST";
  static TOGGLE_CATEGORIES = "TOGGLE_CATEGORIES";
  static CLOSE_CATEGORIES = "CLOSE_CATEGORIES";
  static SHOW_DELETE_LOADER = "SHOW_DELETE_LOADER";
  static CATEGORY_LIST = "CATEGORY_LIST";
  static FILTER_MODAL_RELOAD = "FILTER_MODAL_RELOAD";
  static EMPTY_STAFFS = "EMPTY_STAFFS";
  static CONNECT_MODAL = "CONNECT_MODAL";
  static RETURN_GROUP = "RETURN_GROUP";
  static CHANGE_DATE = "CHANGE_DATE";
  static CHANGE_HAS_GROUP = "CHANGE_HAS_GROUP";
  static STUDENT_INFO = "STUDENT_INFO";
  static CHANGE_CONNECTION_STATUS = "CHANGE_CONNECTION_STATUS";
}
