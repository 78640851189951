import React, { useContext } from "react";
import { StudentsRegistrationContext } from "../../../../Contexts/RegistrationContex/StudentRegistrationContext";
import { openFilterList } from "../actions";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

export default function FilterBlock() {
  const { dispatchStudents } = useContext(StudentsRegistrationContext);
  const handleChange = (e) => {
    e.stopPropagation();
    openFilterList(dispatchStudents);
  };
  return (
    <div className="filter" onClick={(e) => handleChange(e)}>
      <div className="filter-text">
        <FilterAltIcon sx={{ fontSize: "17px" }} color="action" />
        Filter
      </div>
    </div>
  );
}
