import React, { useContext, useCallback } from "react";
import SideBarActions from "../../Actions/ComponentsActions/SideBarActions";
import { SideBarContext } from "../../Contexts/SideBarContext";

export default function SideBarCloseBtn() {
  const { sideBar, dispatchSideBar } = useContext(SideBarContext);

  const toggleSideBar = useCallback(() => {
    dispatchSideBar({ type: SideBarActions.TOGGLE });
  });

  return (
    <div
      className={
        sideBar.open === true ? "btn-close d-block" : "btn-close d-none"
      }
      onClick={() => toggleSideBar()}
    >
      <img src="/images/remove.svg" alt="btn icon" />
    </div>
  );
}
