import { useTranslation } from "react-i18next";

function TableHead() {
  const { t } = useTranslation();
  return (
    <thead>
      <tr className="reports-extra-table-head">
        <th>№</th>
        <th>{t("Kunlar")}</th>
        <th>{t("Sana")}</th>
        <th style={{ background: "orange" }}>{t("Naqt pul tushum")}</th>
        <th style={{ background: "orange" }}>{t("Bank tushum")}</th>
        <th style={{ background: "orange" }}>{t("Click tushum")}</th>
        <th style={{ background: "red" }}>{t("Naqt pul chiqim")}</th>
        <th style={{ background: "red" }}>{t("Bank chiqim")}</th>
        <th style={{ background: "red" }}>{t("Click chiqim")}</th>
        <th style={{ background: "green" }}>{t("Naqt pul qoldi")}</th>
        <th style={{ background: "green" }}>{t("Bank qoldi")}</th>
        <th style={{ background: "green" }}>{t("Click qoldi")}</th>
      </tr>
    </thead>
  );
}

export default TableHead;
