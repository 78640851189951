import { useExamScheduleStore } from "../../../../Store/ManageGroupsStore/ExamScheduleStore";

export default function FilterBlock() {
  const { setModalToggler } = useExamScheduleStore();

  return (
    <div className="filter" onClick={() => setModalToggler(true)}>
      <div className="filter-text">
        <img className="filter-img" src="/images/filter.svg" alt="filter-img" />
        Filter{" "}
      </div>
    </div>
  );
}
