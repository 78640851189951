import React, { createContext, useReducer } from "react";
import { authenticationReducer } from "../../Reducers/AuthenticationReducers";

export const AuthenticationContext = createContext();

const initialState = {
  isAuthenticated: false,
  user: null,
};

const AuthenticationProvider = (props) => {
  const [auth, dispatchAuth] = useReducer(authenticationReducer, {
    ...initialState,
  });
  return (
    <AuthenticationContext.Provider value={{ auth, dispatchAuth }}>
      {props.children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
