import React from "react";
import { useMainGroupStore } from "../../../../Store/ManageGroupsStore/MainGroupStore";

export default function FilterBlock() {
  const { setModalToggler } = useMainGroupStore();

  return (
    <div className="filter" onClick={() => setModalToggler(true)}>
      <div className="filter-text">
        <img className="filter-img" src="/images/filter.svg" alt="filter-img" />
        Filter{" "}
      </div>
    </div>
  );
}
