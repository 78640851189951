import LoaderActions from "../../../Actions/ComponentsActions/LoaderActions";
import { toastError, toastSuccess } from "../../../Helpers/toasts";
import AttendanceFormRepositories from "../../../Repositories/AttendanceForm/AttandanceFormRepository";
import { useAttendanceStore } from "../../../Store/AttendanceFormStore/AttendanceForm";

export const onReload = async ({ ...params }, dispatch, dispatchLoader) => {
  dispatchLoader({ type: LoaderActions.PAGE_LOADING });
  const [{ data, status, nonFieldErrors }, exclusionReasons] =
    await Promise.all([
      AttendanceFormRepositories.getAttendanceFromData(
        new URLSearchParams({
          ...params
        }).toString()
      ),
      AttendanceFormRepositories.getExclusionReasons(),
    ]);
  if (status) {
    localStorage.setItem("card_icons", JSON.stringify(data.card_icons));
    dispatch(data);
    useAttendanceStore.setState({
      exclusionReasons: exclusionReasons.data,
      discountId: null,
      discounts: {},
    });
  } else toastError(nonFieldErrors);
  dispatchLoader({ type: LoaderActions.PAGE_LOADED });
};
export const getAttendanceListWithParam = async (queryString, dispatch) => {
  const { data, status, nonFieldErrors } =
    await AttendanceFormRepositories.getAttendanceFromData(queryString);
  if (status) {
    dispatch(data);
    useAttendanceStore.setState({
      discountId: null,
      discounts: {},
    });
    localStorage.setItem("card_icons", JSON.stringify(data.card_icons));
  }
  toastError(nonFieldErrors);
  return;
};
export const removeStudentFromGroup = async (studentId, data) => {
  const { status, nonFieldErrors } =
    await AttendanceFormRepositories.removeStudent(studentId, data);
  if (status) {
    toastSuccess(nonFieldErrors);
    useAttendanceStore.setState({
      removeId: null,
    });
  } else toastError(nonFieldErrors);
  return status;
};
export const saveDiscount = async (discountData) => {
  const { status, nonFieldErrors } =
    await AttendanceFormRepositories.saveDiscount(discountData);
  if (status) {
    toastSuccess("Chegirma kiritildi");
    useAttendanceStore.setState({
      discountId: null,
    });
  } else toastError(nonFieldErrors);
  return status;
};
