import React from "react";
import "./style.css";

export default function EmptyContent() {
  return (
    <div className="empty-state-basic">
      <img src="/images/empty state.png" alt="" />
    </div>
  );
}
