import { axiosInterceptor } from "../AxiosInterceptor";

export default class AgeGroupReportRepository {
  static async studentsList(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        "/reports/students-count-by-age-groups/?" + queryString
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      result = { ...result, nonFieldErrors: "Ma'lumot olib bo'lmadi" };
    }
    return result;
  }
}
