import ColorToggleButton from "./ToggleButton";
import { useSalaryAdvanceStore } from "../../../../../Store/CashControlStore/SalaryAdvanceStore";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import SaveButton from "../SaveButton";
import { toastError } from "../../../../../Helpers/toasts";
import StaffSelector from "./Select";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function PaymentSection() {
  const { updateSalary, calculated, months, selectedMonth, salary, resetData } =
    useSalaryAdvanceStore();

  const handleChange = (e) => {
    const inputValue = e.target.value.replace(/ /g, "");
    const formattedValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    updateSalary({ amountPlaceholder: formattedValue });
    updateSalary({ amount: e.target.value.replace(/ /g, "") });
  };

  const clearFormData = () => {
    resetData();
  };

  const { t } = useTranslation();

  const confirmationCallback = () => {
    if (!salary?.payment_type && !salary?.amount) {
      toastError(t("To'lov qiymatini va to'lov turi kiritilishi shart!"));
    } else if (!salary?.amount) {
      toastError(t("To'lov qiymatini  kiritish shart!"));
    } else if (!salary?.payment_type) {
      toastError(t("To'lov turi kiritilishi shart!"));
    } else if (calculated && !selectedMonth) {
      toastError(t("To'lov oyini tanlang!"));
    } else {
      useSalaryAdvanceStore.setState({
        confirmation: true,
      });
    }
  };

  const handleChecked = (e) => {
    useSalaryAdvanceStore.setState({
      calculated: e.target.checked,
      selectedMonth: null,
    });
  };

  const handleChangeStaff = useCallback(async (e) => {
    if (e?.value) {
      useSalaryAdvanceStore.setState({
        selectedMonth: e.value,
      });
    } else
      useSalaryAdvanceStore.setState({
        selectedMonth: null,
      });
  }, []);

  const options = useMemo(() => {
    return (
      months?.map((element) => ({
        label: element.salary_date,
        value: element,
      })) || []
    );
  }, [months]);
  console.log(months);
  return (
    <div className="transfer__item">
      <div className="transfer-info transfer-info_huge salary-advance__item">
        <div className="payment-place payment-expenditure--space direction-salary">
          <div className="salary-input--container">
            <FormControlLabel
              control={
                <Checkbox
                  id="calculated"
                  name="calculated"
                  checked={calculated || false}
                  onChange={handleChecked}
                />
              }
              label={t("Oylik ishlangan")}
              labelPlacement="end"
            />
            {/* <FormControlLabel
              labelPlacement="end"
              label="Avans sifatida"
              control={
                <Checkbox
                  id="is_advance_salary"
                  name="is_advance_salary"
                  checked={salary?.is_advance_salary || false}
                  onChange={handleChecked}
                />
              }
            /> */}
            <div style={{ width: "200px" }}>
              <StaffSelector
                value={
                  options?.find((item) => item.value == selectedMonth) || null
                }
                options={options}
                handleChange={handleChangeStaff}
                placeholder={t("Oylar")}
                disabled={!calculated}
              />
            </div>
          </div>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: "20px 30px" }}>
            <h1 className="salary-price">
              {t("Berilgan pul")}:{" "}
              <span className="debt--color">
                {selectedMonth?.important?.released_salary || 0} so'm
              </span>
            </h1>
            <h1 className="salary-price">
              {t("Qolgan haqi")}:{" "}
              <span className="fee--green ">
                {selectedMonth?.important?.left_amount || 0} so'm
              </span>
            </h1>
            <h1 className="salary-price">
              {t("Qarzdorligi")}:{" "}
              <span className="debt--color ">
                {selectedMonth?.important?.debt_amount || 0} so'm
              </span>
            </h1>
            <h1 className="salary-price">
              {t("Yakuniy oyligi")}:{" "}
              <span className="expenditure-expired--color">
                {selectedMonth?.important?.final_salary || 0} so'm
              </span>
            </h1>
            <Box
              sx={{
                // display: data?.calculated ? "flex" : "none",
                display: selectedMonth ? "flex" : "none",
                flexWrap: "wrap",
                gap: "20px 30px",
              }}
            >
              <h1 className="salary-price">
                {t("Jami kiritilgan pul")}:{" "}
                <span className="staff-details--color">
                  {selectedMonth?.extra?.total_contributed_money || 0} so'm
                </span>
              </h1>
              <h1 className="salary-price">
                {t("Fixed oylik sifatida")}:{" "}
                <span className="staff-details--color">
                  {selectedMonth?.extra?.fixed_salary || 0} so'm
                </span>
              </h1>
              {/* <h1 className="salary-price">
                KPI foizi:{" "}
                <span className="staff-details--color">
                  {selectedMonth?.extra?.kpi_percent || 0} %
                </span>
              </h1> */}
              <h1 className="salary-price">
                {t("KPIga asoslangan oylik")}:{" "}
                <span className="staff-details--color">
                  {selectedMonth?.extra?.kpi_based_salary || 0} so'm
                </span>
              </h1>
              <h1 className="salary-price">
                {t("Jami ish haqi")}:{" "}
                <span className="staff-details--color">
                  {selectedMonth?.extra?.total_salary || 0} so'm
                </span>
              </h1>
              <h1 className="salary-price">
                {t("Bonus")}:{" "}
                <span className="staff-details--color">
                  {selectedMonth?.extra?.bonus_percent || 0} %
                </span>
              </h1>
              <h1 className="salary-price">
                {t("Jarima va soliq")}:{" "}
                <span className="staff-details--color">
                  {selectedMonth?.extra?.fine_percent || 0} %
                </span>
              </h1>
            </Box>
          </Box>
        </div>
        <div className="payment-place payment-expenditure--space">
          <input
            className="expenditure-input"
            type="text"
            placeholder={t("To'lov miqdori")}
            onChange={handleChange}
            value={salary?.amountPlaceholder || ""}
          />
          <ColorToggleButton />
        </div>
      </div>
      <SaveButton
        callbackSubmit={() => confirmationCallback()}
        callbackCancel={() => clearFormData()}
        disabled={false}
        clearText={t("Tozalash")}
        saveText={t("Saqlash")}
      />
    </div>
  );
}
