import React, { useState } from "react";
import { TextField } from "@mui/material";
import { useMainGroupStore } from "../../../../Store/ManageGroupsStore/MainGroupStore";

const textarea = {
  borderRadius: "10px",
  margin: "10px 0",
};

const TextArea = ({ property }) => {
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    if (property === "exam") {
      useMainGroupStore.setState({
        examComment: event.target.value,
      });
    }
    if (property === "fineshGroup") {
      useMainGroupStore.setState({
        statusComment: event.target.value,
      });
    }
    setValue(event.target.value);
  };

  return (
    <TextField
      label="Izoh"
      multiline
      rows={5}
      id="outlined-basic"
      variant="filled"
      value={value}
      onChange={handleChange}
      fullWidth
      sx={textarea}
    />
  );
};

export default React.memo(TextArea);
