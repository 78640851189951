import { Box } from "@mui/material";
import React from "react";
import { useMemo } from "react";
import { useCallback } from "react";
import Select from "react-select";
import { useSalaryCalculationStore } from "../../../../Store/CashControlStore/SalaryCalculationStore";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "37px",
    minHeight: "37px",
    width: "200px",
    fontSize: "13px",
    fontFamily: "Inter",
    fontWeight: "400",
    zIndex: "99",
  }),
};

export default function Selector() {
  const { roles_list } = useSalaryCalculationStore();

  const options = useMemo(() => {
    return (
      roles_list?.map((element) => ({
        label: element.name,
        value: element.name,
      })) || []
    );
  }, [roles_list]);

  const handleSelectChange = useCallback(async (e) => {
    useSalaryCalculationStore.setState({
      roll_filter: e?.value == undefined ? "" : e?.value,
    });
  });

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "end",
        marginTop: "10px",
      }}
    >
      <Select
        className="basic-single "
        classNamePrefix="select"
        placeholder={"Lavozimlar"}
        styles={customStyles}
        options={options}
        isClearable
        onChange={handleSelectChange}
      />
    </Box>
  );
}
