import { Box } from "@mui/material";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";

export const DonutChart = ({ title, data, labels }) => {
  const [chartState, setChartState] = useState({
    series: data,
    options: {
      chart: {
        type: "donut",
      },
      labels: labels,
      title: {
        text: title,
        align: "center",
        style: {
          fontSize: "20px",
          fontWeight: "bold",
          fontFamily: "Inter",
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "top",
            },
          },
        },
      ],
      legend: {
        position: "top",
        offsetY: 0,
      },
    },
  });

  return (
    <Box sx={{ height: "auto" }}>
      <ReactApexChart
        options={chartState.options}
        series={chartState.series}
        type="donut"
      />
    </Box>
  );
};
