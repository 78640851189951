import React from "react";

export default function PageSizeChanger({ context, callback }) {
  return (
    <div className="build-export__number">
      <span>Show</span>
      <input
        type="number"
        name="number"
        value={context.queryParams?.pageSize ?? "10"}
        onChange={(e) => callback(e)}
        onKeyDown={(e) => callback(e)}
      />
    </div>
  );
}
