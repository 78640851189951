export default class BranchesActions {
    static PAGE_LOADED = "PAGE_LOADED";
    static CREATE_BUTTON_CLICKED = "CREATE_BUTTON_CLICKED";
    static UPDATE_BUTTON_CLICKED = "UPDATE_BUTTON_CLICKED";
    static DELETE_BUTTON_CLICKED = "DELETE_BUTTON_CLICKED";
    static CANCEL_BUTTON_CLICKED = "CANCEL_BUTTON_CLICKED";
    static TOGGLE_CONTEXT_MENU = "TOGGLE_CONTEXT_MENU";
    static CLOSE_CONTEXT_MENU = "CLOSE_CONTEXT_MENU";
    static ADD_QUERY_PARAM = "ADD_QUERY_PARAM";
    static SHOW_DELETE_LOADER="SHOW_DELETE_LOADER"
    static MODAL_RESPONSE_DATA="MODAL_RESPONSE_DATA"
    static TOGGLE_LIST_TABLE="TOGGLE_LIST_TABLE"
  }
  