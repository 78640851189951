import { create } from "zustand";
import StudentTrasferRepository from "../../Repositories/GroupManagement/StudentTransferRepository";
import MainGroupsRepository from "../../Repositories/Groups/MainGroupsRepository";

export const useStudentTransferStore = create((set, get) => ({
  isLoading: false,
  ageGroups: null,
  teachersList: null,
  studentLists: null,
  branches: null,
  pagination: null,
  queryParams: { page: 1 },
  uploadParams: (paramItem) => {
    set((prevState) => ({
      queryParams: { ...prevState.queryParams, ...paramItem },
    }));
  },
  setLoading: (status) => {
    set({
      isLoading: status,
    });
  },
  onReload: async () => {
    const { data, status, nonFieldErrors } =
      await StudentTrasferRepository.getTransferLists();
    if (status) {
      set({
        studentLists: data.data,
        pagination: { page_count: data.data.pages_count },
        ageGroups: data.age_groups_list,
        teachersList: data.teachers_list,
      });
    } else toastError(nonFieldErrors);
  },
  loadItems: async (queryString) => {
    const { data, status, nonFieldErrors } =
      await StudentTrasferRepository.getTransferLists(queryString);
    if (status) {
      set({
        studentLists: data.data,
        pagination: { page_count: data.data.pages_count },
      });
    } else toastError(nonFieldErrors);
  },
}));
