import ReactLoading from "react-loading";
import { useState } from "react";
import { useLoaderStore } from "../../../../Store/Loader/LoaderStore";
import { useFinishedStudents } from "../../../../Store/ReportsStore/FinishedStudentsStore";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useCallback } from "react";

const initialValues = {
  listening: "",
  writing: "",
  speaking: "",
  reading: "",
  overall: "",
  date: "",
  file: "",
};

function SetScoreModal() {
  const { studentId, onSubmit, loadItems, queryParams } = useFinishedStudents();
  const { setLoader } = useLoaderStore();
  const [loader, setSubmitLoader] = useState(false);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: false,
    validationSchema: Yup.object({
      listening: Yup.string().min(0, "Required").required("kiriting"),
      writing: Yup.string().min(0, "Required").required("kiriting"),
      speaking: Yup.string().min(0, "Required").required("kiriting"),
      reading: Yup.string().min(0, "Required").required("kiriting"),
      overall: Yup.string().min(0, "Required").required("kiriting"),
      date: Yup.string().min(0, "Required").required("kiriting"),
    }),

    onSubmit: async (values) => {
      setSubmitLoader(true);
      const formData = new FormData();
      formData.append("file", values.file);
      formData.append("listening", values.listening);
      formData.append("writing", values.writing);
      formData.append("speaking", values.speaking);
      formData.append("reading", values.reading);
      formData.append("overall", values.overall);
      formData.append("date", values.date);
      formData.append("student_id", studentId);

      await onSubmit(formData);
      setSubmitLoader(false);
      cancelHandler();
      setLoader(true);
      await loadItems(
        new URLSearchParams({
          ...queryParams,
        }).toString()
      );
      setLoader(false);
    },
  });

  const cancelHandler = useCallback(() => {
    formik.resetForm();
    useFinishedStudents.setState({
      studentId: null,
    });
    setSubmitLoader(false);
  }, [formik]);

  const handleChangeFileInput = (e) => {
    formik.setFieldValue("file", e.target.files[0]);
  };

  console.log(formik.errors);
  return (
    <div
      id="modal"
      className={studentId ? "modal modal-select active" : "modal modal-select"}
    >
      <div className="attendanceDiscountModal ielts-modal">
        <h1 className="discountTitle">IELTS Ballarni kiritish</h1>
        <form onSubmit={formik.handleSubmit} className="discountContainer ">
          <div className="discountInput">
            <label htmlFor="speaking">Speaking</label>
            <input
              className={`${
                formik.errors.speaking &&
                formik.touched.speaking &&
                "error-ielts-input"
              }`}
              type="number"
              id="speaking"
              name="speaking"
              value={formik.values.speaking}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="discountInput">
            <label htmlFor="listening">Listening</label>
            <input
              className={`${
                formik.errors.listening &&
                formik.touched.listening &&
                "error-ielts-input"
              }`}
              type="number"
              id="listening"
              name="listening"
              value={formik.values.listening}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="discountInput">
            <label htmlFor="reading">Reading</label>
            <input
              className={`${
                formik.errors.reading &&
                formik.touched.reading &&
                "error-ielts-input"
              }`}
              type="number"
              id="reading"
              name="reading"
              value={formik.values.reading}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="discountInput">
            <label htmlFor="writing">Writing</label>
            <input
              className={`${
                formik.errors.writing &&
                formik.touched.writing &&
                "error-ielts-input"
              }`}
              type="number"
              id="writing"
              name="writing"
              value={formik.values.writing}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="discountInput">
            <label htmlFor="writing">Overall</label>
            <input
              className={`${
                formik.errors.overall &&
                formik.touched.overall &&
                "error-ielts-input"
              }`}
              type="number"
              id="overall"
              name="overall"
              value={formik.values.overall}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="discountInput">
            <label
              style={{ width: "50%", display: "flex", alignItems: "center" }}
              htmlFor="date"
            >
              Sana
            </label>
            <input
              className={`${
                formik.errors.date && formik.touched.date && "error_input"
              } modal-input`}
              type="date"
              id="date"
              name="date"
              value={formik.values.date}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="finished-student-file">
            <label htmlFor="customFile" className="file-label">
              {formik.values.file ? formik.values.file?.name : "Choose a file"}
            </label>
            <input
              type="file"
              id="customFile"
              name="file"
              onChange={(e) => handleChangeFileInput(e)}
              onBlur={formik.handleBlur}
            />
          </div>
        </form>
        <div className="discountBtns">
          <button type="button" className="cancelBtn" onClick={cancelHandler}>
            Bekor qilish
          </button>
          <button
            type="submit"
            className="saveBtn"
            disabled={loader}
            onClick={formik.handleSubmit}
          >
            {loader ? (
              <ReactLoading
                type={"spin"}
                color="#ffffff"
                className="delete_loader"
                height={"20px"}
                width={"20px"}
              />
            ) : (
              "Saqlash"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
export default SetScoreModal;
