import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useStudentsBillingStore } from "../../../../Store/CashControlStore/StudentsBillingStore";
import { useCallback } from "react";

export default function FormControlLabelPosition() {
  const {
    loadItems,
    setLoading,
    checkbox_filterset,
    updateParams,
    queryParams,
  } = useStudentsBillingStore();

  const handleChange = useCallback(
    async (e) => {
      setLoading(true);
      updateParams({
        debting_month:
          e.target.value == queryParams.debting_month ? "" : e.target.value,
        page: 1,
      });
      await loadItems(
        new URLSearchParams({
          ...queryParams,
          debting_month:
            e.target.value == queryParams.debting_month ? "" : e.target.value,
          page: 1,
        }).toString()
      );
      setLoading(false);
    },
    [queryParams]
  );

  return (
    <FormControl component="fieldset">
      <FormGroup aria-label="position" row sx={{ fontSize: "10px" }}>
        {checkbox_filterset?.map((element) => {
          return (
            <FormControlLabel
              key={element.value}
              value={element.value}
              onChange={handleChange}
              control={
                <Checkbox
                  size="small"
                  checked={queryParams.debting_month == element.value}
                />
              }
              label={element.name}
              labelPlacement="end"
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
}
