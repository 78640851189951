import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useDiscountsReportStore } from "../../../../Store/ReportsStore/DiscountsReportStore";
import { Link } from "react-router-dom";

export default function BasicButtons() {
  const WeekButton = styled(Button)(({ isActive }) => ({
    boxShadow: "none",
    borderRadius: "5px",
    textTransform: "none",
    fontSize: 13,
    padding: "5px 20px",
    minWidth: "100px",
    border: "1px solid",
    color: isActive ? "white" : "black",
    backgroundColor: isActive ? "#3B9AFD" : "#fff",
    borderColor: "#3B9AFD",
    fontFamily: "Inter",
    "&:hover": {
      transition: "all 0s",
      background: isActive
        ? "#3B9AFD"
        : "linear-gradient(91.68deg, #45a1ff 0%, #0176ee 100%),linear-gradient(137.64deg, #e8eff4 0%, #e6ecf0 97.97%) #c4c4c4",
      borderColor: "#3B9AFD",
      boxShadow: "none",
      color: "white",
    },
    "&:active": {
      backgroundColor: "#3B9AFD",
      borderColor: "#3B9AFD",
      boxShadow: "none",
      color: "white",
    },
  }));

  // const isActive = item.days === queryParams.days;
  return (
    <Stack direction="row" gap={2} sx={{ mr: "24px" }}>
      <Link to={"/reports/discount-earned-reports"}>
        <WeekButton variant="outlined">Chegirmalar</WeekButton>
      </Link>
      <Link to={"/reports/discount-spent-reports"}>
        <WeekButton isActive={true} variant="outlined">
          Chegirma to'lovlari
        </WeekButton>
      </Link>
    </Stack>
  );
}
