import React, { createContext, useReducer } from "react";
import { studentAttachmentReducers } from "../../Reducers/StudentAttachmentReducers/StudentAttachmentReducers";

export const StudentAttachmentContext = createContext();

const initialState = {
  data: null,
  groupData: null,
  branches: null,
  staffs: null,
  modalSubjects: null,
  modalLessonDays: null,
  modalLessonTimes: null,
  modalGroupTypes: null,
  filteredData: null,
  isOpenFilterList: false,
  groupStudents: null,
  filteredGroupData: null,
  pagination: null,
  queryParams: {
    page: 1,
  },
};

const StudentAttachmentProvider = (props) => {
  const [studentAttachment, dispatchStudentAttachment] = useReducer(
    studentAttachmentReducers,
    {
      ...initialState,
    }
  );

  return (
    <StudentAttachmentContext.Provider
      value={{ studentAttachment, dispatchStudentAttachment }}
    >
      {props.children}
    </StudentAttachmentContext.Provider>
  );
};

export default StudentAttachmentProvider;
