import React, { useState } from "react";
import { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useComparisionStore } from "../../../../Store/ReportsStore/ComparisionStore";

const ApexChart = () => {
  const { results, orderNumber } = useComparisionStore();

  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});

  useEffect(() => {
    setSeries(
      results?.map((element) => {
        return {
          name: element.name,
          data: element.data.map((item) => item.score),
        };
      })
    );
    setOptions({
      chart: {
        type: "bar",
        height: 450,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "65%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [...orderNumber?.map((element) => element)],
      },
      yaxis: {
        title: {
          text: "Natijalar",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " ball";
          },
        },
      },
    });
  }, [results]);

  return (
    <div id="chart" className="bar-chart">
      <ReactApexChart
        options={options}
        series={series}
        height={450}
        type="bar"
      />
    </div>
  );
};

export default ApexChart;
