import React, { useContext } from "react";
import { SideBarContext } from "../../Contexts/SideBarContext";
import HeaderBurger from "./HeaderBurger";
import HeaderInfo from "./HeaderInfo";
import HeaderProfile from "./HeaderProfile";

export default function Header() {
  const { sideBar } = useContext(SideBarContext);

  return (
    <header className={sideBar.open === true ? "header menu-move" : "header"}>
      <HeaderBurger />
      <HeaderInfo />
      <HeaderProfile />
    </header>
  );
}
