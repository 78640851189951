import { useTranslation } from "react-i18next";

export default function TableHead() {
  const { t } = useTranslation();
  return (
    <ul className="expenditure-list--grid build-table build-table--bold ">
      <li className="build-table__item">№</li>
      <li className="build-table__item">{t("Xodim")}</li>
      <li className="build-table__item">{t("Summasi")}</li>
      <li className="build-table__item">{t("To'lov turi")}</li>
      <li className="build-table__item">{t("To'lov rejimi")}</li>
      <li className="build-table__item">{t("Filial")}</li>
      <li className="build-table__item">{t("Kassir")}</li>
      <li className="build-table__item">{t("Sana")}</li>
      <li className="build-table__item"></li>
    </ul>
  );
}
