import React from "react";
import { useRoomAvailabilityStore } from "../../../../Store/GroupManagement/RoomAvailabilityStore";

export default function TableRow({ room, index }) {
  const { data } = useRoomAvailabilityStore();

  return (
    <div className="buildingJournal-redhead buildingJournal-whitehead">
      <div className="buildingJournal-redhead__item stu-num">{index + 1}</div>
      <div className="buildingJournal-redhead__item room-title">
        {room.verbose_name}
      </div>
      {data?.columns?.map((item) => {
        return (
          <div
            key={item.key}
            className="buildingJournal-redhead__item buildingJournal-wrapper week-days"
          >
            {room?.days?.[item.key].map((element) => {
              return (
                <div
                  key={element.id}
                  className={`buildingJournal-box  ${
                    element.busy && "buildingJournal-box_orange"
                  }`}
                >
                  <h6>{element.verbose_name}</h6>
                  <span>{element.time}</span>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
