import React from "react";
import Container from "../Loader";

export default function BasicLoader() {
  return (
    <div className="basic_loader" style={{ zIndex: 10000 }}>
      <Container size={80} />
    </div>
  );
}
