import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useTeacherAvailabilityStore } from "../../../../Store/GroupManagement/TeacherAvailabilityStore";
import { loadItems } from "../actions";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "40px",
    minHeight: "40px",
    width: "200px",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: "400",
    marginRight: "24px",
  }),
};

export default function Selector({ options, property, defaultValue }) {
  const { queryParams, setLoader } = useTeacherAvailabilityStore();
  const { t } = useTranslation();
  const handleSelectChange = async (e) => {
    setLoader(true);
    if (property == t("Filiallar")) {
      useTeacherAvailabilityStore.setState((state) => ({
        queryParams: { ...state.queryParams, branch: e.value },
      }));
      await loadItems(new URLSearchParams({ ...queryParams, branch: e.value }));
    } else {
      useTeacherAvailabilityStore.setState((state) => ({
        queryParams: { ...state.queryParams, lesson_style: e.value },
      }));
      await loadItems(
        new URLSearchParams({ ...queryParams, lesson_style: e.value })
      );
    }
    setLoader(false);
  };

  return (
    <Select
      styles={customStyles}
      value={defaultValue}
      options={options}
      placeholder={property}
      onChange={handleSelectChange}
    />
  );
}
