import { useTranslation } from "react-i18next";
import { useReadyReportStore } from "../../../../Store/ReportsStore/ReadyReportStore";

export default function TableHead() {
  const { columns } = useReadyReportStore();
  const { t } = useTranslation();

  return (
    <div className="build-search ">
      <ul className="reports_table_head build-table build-table--bold ">
        <li className="build-table__item ">№</li>
        <li className="build-table__item report-text-center">
          {t("Hafta kunlari")}
        </li>
        {columns?.map((item) => {
          return (
            <li key={item.key} className="build-table__item report-text-center">
              {item.name}
            </li>
          );
        })}
        <li className="build-table__item report-text-center">{t("Jami")}</li>
      </ul>
    </div>
  );
}
