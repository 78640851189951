import { create } from "zustand";
import { toastError, toastSuccess } from "../../Helpers/toasts";
import MainGroupsRepository from "../../Repositories/Groups/MainGroupsRepository";
import AbsentStudentsRepository from "../../Repositories/Reports/AbsentStudentsRepository";

export const useAbsentStudentsStore = create((set, get) => ({
  results: null,
  reasoning: null,
  absences_count: null,
  startDate: null,
  endDate: null,
  pagination: null,
  branches: null,
  isLoading: false,
  queryParams: {
    page: 1,
  },

  setLoading: (status) => {
    set({
      isLoading: status,
    });
  },

  updateParams: (paramItem) => {
    set((prevState) => ({
      queryParams: { ...prevState.queryParams, ...paramItem },
    }));
  },

  onReload: async (queryString = "") => {
    const { data, status, nonFieldErrors } =
      await AbsentStudentsRepository.studentsList(queryString);
    if (status) {
      set({
        results: data.results.absences,
        absences_count: data.results.absences_count,
        reasoning: data.results.filterset.reasoning,
        pagination: { page_count: data.pages_count || 1 },
        queryParams: { page: 1 },
      });
    } else toastError(nonFieldErrors);
  },
  getBranches: async () => {
    const { data, status } = await MainGroupsRepository.getAllBranches()
    if (status) {
      set({
        branches: data,
      })
    }
  },
  loadItems: async (queryString) => {
    const { data, status, nonFieldErrors } =
      await AbsentStudentsRepository.studentsList(queryString);
    if (status) {
      set({
        results: data.results.absences,
        absences_count: data.results.absences_count,
        pagination: { page_count: data.pages_count || 1 },
      });
    } else toastError(nonFieldErrors);
  },
}));
