import { create } from "zustand";
import { toastError } from "../../Helpers/toasts";
import WeeklyReportRepository from "../../Repositories/Reports/WeeklyReportsRepository";

export const useWeeklyReportsStore = create((set) => ({
  results: null,
  reasoning: null,
  months: null,
  years: null,
  current_month_balance: null,
  previous_month_balance: null,
  isLoading: false,
  queryParams: {
    page: 1,
  },

  setLoading: (status) => {
    set({
      isLoading: status,
    });
  },

  updateParams: (paramItem) => {
    set((prevState) => ({
      queryParams: { ...prevState.queryParams, ...paramItem },
    }));
  },

  onReload: async (queryString) => {
    const { data, status, nonFieldErrors } =
      await WeeklyReportRepository.studentsList(queryString);
    if (status) {
      set({
        results: data.weekdays,
        current_month_balance: data.current_month_balance,
        previous_month_balance: data.previous_month_balance,
        months: data.months,
        totals: data.totals,
        years: data.years,
        queryParams: { page: 1 },
      });
    } else toastError(nonFieldErrors);
  },

  loadItems: async (queryString) => {
    const { data, status, nonFieldErrors } =
      await WeeklyReportRepository.studentsList(queryString);
    if (status) {
      set({
        results: data.weekdays,
        totals: data.totals,
        current_month_balance: data.current_month_balance,
        previous_month_balance: data.previous_month_balance,
      });
    } else toastError(nonFieldErrors);
  },
}));
