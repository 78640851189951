import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import FixedCosts from "./fixed-costs/FixedCosts";
import SalaryAdvance from "./salary-advance/SalaryAdvance";
import Refund from "./refund/Refund";
import UserPermissionWidget from "../../../../UserPermissionWidget";
import { useTranslation } from "react-i18next";

const container = {
  width: "100%",
  backgroundColor: "#fff",
  borderRadius: "15px",
  padding: "15px 30px",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, margin: "25px 0" }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const user = JSON.parse(localStorage.getItem("user"));

  const { t } = useTranslation();
  return (
    <Box sx={container}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          marginBottom: "20px",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label={t("Doimiy chiqimlar")} {...a11yProps(0)} />
          <Tab
            label={t("Avans/oylik")}
            {...a11yProps(
              user?.permissions?.make_expenditure === false ? 0 : 1
            )}
          />
          <Tab
            label={t("O'quvchilarga pul qaytarib berish")}
            {...a11yProps(
              !user?.permissions?.pay_salary &&
                !user?.permissions?.make_expenditure
                ? 0
                : user?.permissions?.pay_salary &&
                  !user?.permissions?.make_expenditure
                ? 1
                : 2
            )}
          />
        </Tabs>
      </Box>
      <UserPermissionWidget hasPermission={user?.permissions?.make_expenditure}>
        <TabPanel sx={{ padding: "0px" }} value={value} index={0}>
          <FixedCosts />
        </TabPanel>
      </UserPermissionWidget>
      <UserPermissionWidget hasPermission={user?.permissions?.pay_salary}>
        <TabPanel
          sx={{ padding: "0px" }}
          value={value}
          index={user?.permissions?.make_expenditure === false ? 0 : 1}
        >
          <SalaryAdvance />
        </TabPanel>
      </UserPermissionWidget>
      <UserPermissionWidget
        hasPermission={user?.permissions?.return_student_money}
      >
        <TabPanel
          sx={{ padding: "0px" }}
          value={value}
          index={
            !user?.permissions?.pay_salary &&
            !user?.permissions?.make_expenditure
              ? 0
              : user?.permissions?.pay_salary &&
                !user?.permissions?.make_expenditure
              ? 1
              : 2
          }
        >
          <Refund />
        </TabPanel>
      </UserPermissionWidget>
    </Box>
  );
}
