import React, { useCallback, useContext } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AdminLayout from "../../../AdminLayout";
import TitleBar from "../../../Components/TitleBar";
import { useStudentAcquisitionStore } from "../../../Store/GroupManagement/StudentAcquisitionStore";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import { onReload } from "./actions";
import Heading from "./components/Heading";
import ListItem from "./components/ListItem";
import TotalCounts from "./components/TotalCounts";

export default function StudentsAcquisition() {
  const { age_groups } = useStudentAcquisitionStore();
  const { setLoader } = useLoaderStore();

  const pageLoad = async () => {
    setLoader(true);
    await onReload();
    setLoader(false);
  };

  useEffect(() => {
    pageLoad();
  }, []);

  const { t } = useTranslation();
  return (
    <AdminLayout>
      <TitleBar
        title={t("Yosh turiga ko'ra talabalar o'zlashtirishi")}
        createAction={false}
      />
      <div className="container">
        <Heading />
        {age_groups?.map((ageGroup, index) => {
          return <ListItem key={ageGroup.key} group={ageGroup} index={index} />;
        })}
        <TotalCounts />
      </div>
    </AdminLayout>
  );
}
