export const dateFormat = (date) => {
  if (!date) {
    return "";
  }
  let dateObj = new Date(date);

  dateObj.setHours(dateObj.getHours() + 2);
  let month = dateObj.getMonth() + 1;
  let day = dateObj.getDate();
  let year = dateObj.getFullYear();
  let hour = dateObj.getHours();
  let minutes = dateObj.getMinutes();

  if (month < 10) {
    month = `0${month}`;
  }
  if (day < 10) {
    day = `0${day}`;
  }
  if (hour < 10) {
    hour = `0${hour}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  let newdate = year + "-" + month + "-" + day + "   " + hour + ":" + minutes;
  return newdate;
};

export const dateRangeFormat = (date) => {
  let dateObj = new Date(date);
  let month = dateObj.getMonth() + 1;
  let day = dateObj.getDate();
  let year = dateObj.getFullYear();
  if (month < 10) {
    month = `0${month}`;
  }
  if (day < 10) {
    day = `0${day}`;
  }
  let newdate = year + "-" + month + "-" + day;
  if (date) {
    return newdate;
  } else return "";
};
