function TableHead() {
  return (
    <thead>
      <tr className="reports-extra-table-head">
        <th>№</th>
        <th>Oylar</th>
        <th style={{ background: "orange" }}>Naqt pul tushum</th>
        <th style={{ background: "orange" }}>Bank tushum</th>
        <th style={{ background: "orange" }}>Click tushum</th>
        <th style={{ background: "red" }}>Naqt pul chiqim</th>
        <th style={{ background: "red" }}>Bank chiqim</th>
        <th style={{ background: "red" }}>Click chiqim</th>
        <th style={{ background: "green" }}>Naqt pul qoldi</th>
        <th style={{ background: "green" }}>Bank qoldi</th>
        <th style={{ background: "green" }}>Click qoldi</th>
      </tr>
    </thead>
  );
}

export default TableHead;
