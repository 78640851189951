import React from "react";
import { Link } from "react-router-dom";

export default function HeaderInfo() {
  return (
    <div className="header-info">
      {/* <Link
        className="header-info__item header-info__item--active"
        to="/cash-control/income-cash"
      >
        To‘lovlar
      </Link>
      <Link to="/cash-control/expenditure-cash" className="header-info__item">
        Xarajatlar
      </Link> */}
    </div>
  );
}
