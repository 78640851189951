import { create } from "zustand";
import CommonRepository from "../../Repositories/Config/CommonRepository";
import MainGroupsRepository from "../../Repositories/Groups/MainGroupsRepository";

export const useGroupLevelStore = create((set, get) => ({
  data: null,
  months: null,
  pagination: null,
  month: null,
  branches: null,
  start_date: null,
  end_date: null,
  subjects: null,
  queryParams: { page: 1 },
  updateParams: (item) => {
    set((prevState) => ({
      queryParams: { ...prevState.queryParams, ...item },
    }));
  },
  getBranches: async () => {
    const [branches, subjects] = await Promise.all([
      await MainGroupsRepository.getAllBranches(),
      await CommonRepository.getSubjects()
    ])
    set({
      branches: branches.data,
      subjects: subjects.data,
    })
  },
}));
