import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AdminLayout from "../../../AdminLayout";
import TitleBar from "../../../Components/TitleBar";
import { useGroupAttendanceDivisionStore } from "../../../Store/GroupManagement/GroupsAttendanceDivisionStore";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import { onReload } from "./actions";
import Heading from "./components/Heading";
import ListItem from "./components/ListItem";
import TotalCounts from "./components/TotalCounts";

export default function GroupsAttendanceDivision() {
  const { age_groups, resetData, getBranches } =
    useGroupAttendanceDivisionStore();
  const { setLoader } = useLoaderStore();

  const pageLoad = async () => {
    resetData();
    setLoader(true);
    await Promise.all([onReload(), getBranches()]);
    setLoader(false);
  };
  const { t } = useTranslation();

  useEffect(() => {
    pageLoad();
  }, []);

  return (
    <AdminLayout>
      <TitleBar
        title={t("Yosh turiga ko'ra talabalar statistikasi")}
        createAction={false}
      />
      <div className="container">
        <Heading />
        {age_groups?.map((ageGroup, index) => {
          return <ListItem key={ageGroup.key} group={ageGroup} index={index} />;
        })}
        <TotalCounts />
      </div>
    </AdminLayout>
  );
}
