import { useTranslation } from "react-i18next";

export default function TableHead() {
  const { t } = useTranslation();

  return (
    <ul className="estimated-salary--grid build-table build-table--bold ">
      <li className="build-table__item">№</li>
      <li className="build-table__item">{t("Yil")}</li>
      <li className="build-table__item">{t("Oy")}</li>
      <li className="build-table__item">{t("Jami xodimlar")}</li>
      <li className="build-table__item">{t("Jami oyliklar")}</li>
      <li className="build-table__item">{t("Jami fixed bo'yicha")}</li>
      <li className="build-table__item">{t("Jami KPI bo'yicha")}</li>
      <li className="build-table__item">{t("Jami kiritilgan pullar")}</li>
      <li className="build-table__item">{t("Avanslar")}</li>
      <li className="build-table__item">{t("To'lanmagan")}</li>
      <li className="build-table__item">{t("Kassir")}</li>
      <li className="build-table__item">{t("Sana")}</li>
      <li className="build-table__item build-table__item--7"></li>
    </ul>
  );
}
