import React from "react";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { photoUrl } from "../../../../Helpers/photoUrl";

const DownloadFileComponent = ({ defaultFileValue }) => {
  const downloadFile = (photo) => {
    const fileUrl = photoUrl(photo);
    const downloadLink = document.createElement("a");
    downloadLink.target = "blank";
    downloadLink.href = fileUrl;
    const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <>
      {defaultFileValue?.length > 0 && (
        <>
          <label htmlFor="roles">File yuklash</label>
          <div className="fileUpload">
            {defaultFileValue?.map((item, index) => (
              <div key={index} className="file-upload-container">
                <label
                  htmlFor={`fileInput-${index}`}
                  className="file-input-label"
                >
                  <span className="file-name">
                    {item.name.file.substring(
                      item.name?.file.lastIndexOf("/") + 1
                    )}
                  </span>
                </label>
                <div
                  className="deleteButton"
                  onClick={() => downloadFile(item.name.file)}
                >
                  <CloudDownloadIcon color="action" fontSize="large" />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default DownloadFileComponent;
