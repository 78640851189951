import { useTranslation } from "react-i18next";

export default function TableHead() {
  const { t } = useTranslation();
  return (
    <ul className="groupnow-table build-table build-table--bold ">
      <li className="build-table__item">№</li>
      <li className="build-table__item">{t("Guruh raqami")}</li>
      <li className="build-table__item">{t("O‘qituvchining ismi")}</li>
      <li className="build-table__item">{t("Dars kuni/soat")}</li>
      <li className="build-table__item">{t("Dars uslubi")}</li>
      <li className="build-table__item">{t("Guruh turi")}</li>
      <li className="build-table__item">{t("Oylik dasrlar")}</li>
      <li className="build-table__item">{t("Fani")}</li>
      <li className="build-table__item">{t("Bino/xona")}</li>
      <li className="build-table__item">{t("O‘quvchilar")}</li>

      <li className="build-table__item build-table__item--7"></li>
    </ul>
  );
}
