import React from "react";
import { useAgeGroupReportStore } from "../../../../Store/ReportsStore/AgeGroupReportStore";

function TableItems({ element }) {
  const { ageGroupsList } = useAgeGroupReportStore();
  return (
    <tr key={element.id} className="reports-table-body">
      <td>{element.month_name}</td>
      {ageGroupsList?.map((item) => {
        return <td>{element[item.value]}</td>;
      })}
      <td>{element?.groups_count}</td>
      <td>{element?.students_count}</td>
    </tr>
  );
}

export default TableItems;
