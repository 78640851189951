import { useTranslation } from "react-i18next";
import { useAgeGroupReportStore } from "../../../../Store/ReportsStore/AgeGroupReportStore";

function TableHead() {
  const { ageGroupsList } = useAgeGroupReportStore();

  const { t } = useTranslation();

  return (
    <thead>
      <tr className="reports-table-head">
        <th>{t("Oy")}</th>
        {ageGroupsList?.map((element) => {
          return <th key={element.value}>{element.name}</th>;
        })}
        <th>{t("Guruhlar")}</th>
        <th>{t("O'quvchilar")}</th>
      </tr>
    </thead>
  );
}

export default TableHead;
