import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AdminLayout from "../../../AdminLayout";
import EmptyContent from "../../../Components/Empty-content/EmptyContent";
import ItemsLoader from "../../../Components/Loader/basic loader/ItemsLoader";
import TitleBar from "../../../Components/TitleBar";
import { useIncomeCashStore } from "../../../Store/CashControlStore/IncomeCashStore";
import ResultContainer from "./components/ResultContainer";
import SearchSection from "./components/SearchSection";

export default function IncomeCash() {
  const { student_details, isLoading, resetData, acceptPayment, paymentId } =
    useIncomeCashStore();

  useEffect(() => {
    resetData();
  }, []);

  const { t } = useTranslation();

  return (
    <AdminLayout>
      <TitleBar title={t("Kirim kassa to‘lov oynasi")} />
      <div className="container scroll_none">
        <SearchSection />
        {isLoading ? (
          <ItemsLoader size={70} />
        ) : student_details ? (
          <ResultContainer />
        ) : (
          <EmptyContent />
        )}
      </div>
    </AdminLayout>
  );
}
