import { useTranslation } from "react-i18next";

export default function TableHead() {
  const { t } = useTranslation();
  return (
    <ul className="finished-students build-table build-table--bold ">
      <li className="build-table__item">№</li>
      <li className="build-table__item">{t("O'quvchi")} </li>
      <li className="build-table__item">{t("Guruh turi")}</li>
      <li className="build-table__item">{t("Guruhi")}</li>
      <li className="build-table__item">{t("Fani")}</li>
      <li className="build-table__item">{t("O'qituvchi")}</li>
      <li className="build-table__item">{t("IELTS natijasi")}</li>
      <li className="build-table__item">{t("Sana")}</li>
    </ul>
  );
}
