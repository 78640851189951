import axiosInstance from "../AxiosInstance";
import { axiosInterceptor } from "../AxiosInterceptor";

export default class BranchesRepository {
  static async getAllBranches(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    const response = await axiosInstance.get("/config/branch/?" + queryString);
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data };
    }
    return result;
  }

  static async createBranche(data) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInstance.post("/config/branch/", data);
      if (response.status === 201) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      if (e.response.data.data) {
        result = {
          ...result,
          nonFieldErrors: e.response.data.data.non_field_errors[0],
        };
      } else
        result = { ...result, nonFieldErrors: "Bino turini saqlab bo'lmadi!" };
    }
    return result;
  }
  static async updateBranch(data, id) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInstance.patch(`/config/branch/${id}/`, data);

      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      if (e.response.data.data) {
        result = {
          ...result,
          nonFieldErrors: e.response.data.data[0],
        };
      } else
        result = {
          ...result,
          nonFieldErrors: "Bino turini yangilab bo'lmadi!",
        };
    }
    return result;
  }

  static async deleteBranch(id) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInstance.delete(`/config/branch/${id}/`);
      if (response.status === 204) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {}
    return result;
  }
  static async getAllStaffs() {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(`/register/staff/?full=true`);
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {}
    return result;
  }
  static async getBranchById(id) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInstance.get(`/config/branch/${id}/`);
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (error) {
      result = {
        ...result,
        nonFieldErrors: "Xatolik! Bunday turdagi ma'lumot imavjud!",
      };
    }
    return result;
  }
}
