import EmployeeRegistrationAction from "../../Actions/RegistrationActions/EmployeeRegistrationAction";

export const employeeReducers = (state, action) => {
  switch (action.type) {
    case EmployeeRegistrationAction.CREATE_BUTTON_CLICKED:
      return {
        ...state,
        isModalOpen: true,
        isCreating: true,
        isEditing: false,
        item: null,
      };
    case EmployeeRegistrationAction.ADD_QUERY_PARAM:
      const params = { ...state.queryParams, ...action.payload };
      return {
        ...state,
        queryParams: params,
      };
    case EmployeeRegistrationAction.PAGE_LOADED:
      return {
        ...state,
        ...action.payload,
      };
    case EmployeeRegistrationAction.TOGGLE_CONTEXT_MENU:
      return { ...state, openContextMenuId: action.payload.id };
    case EmployeeRegistrationAction.CLOSE_CONTEXT_MENU:
      return {
        ...state,
        openContextMenuId: null,
      };
    case EmployeeRegistrationAction.DELETE_BUTTON_CLICKED:
      return {
        ...state,
        isDeleteModalOpen: true,
        deletingId: action.payload.id,
      };
    case EmployeeRegistrationAction.SHOW_DELETE_LOADER:
      return {
        ...state,
        isShowDeleteLoader: action.payload,
      };
    case EmployeeRegistrationAction.CANCEL_BUTTON_CLICKED:
      return {
        ...state,
        isModalOpen: false,
        isDeleteModalOpen: false,
        isCreating: false,
        isEditing: false,
        editingId: null,
        deletingId: null,
        permissionId: null,
        updateSalaryId: null,
        removeId: null,
        isOpenInfo: false,
      };
    case EmployeeRegistrationAction.UPDATE_BUTTON_CLICKED:
      return {
        ...state,
        isModalOpen: true,
        isCreating: false,
        isEditing: true,
        editingId: action.payload.id,
        item: action.payload.item,
      };
    case EmployeeRegistrationAction.STAFF_INFO:
      return {
        ...state,
        isOpenInfo: true,
        item: action.payload.item,
      };
    case EmployeeRegistrationAction.TOGGLE_FILTER_LIST:
      return { ...state, isOpenFilterList: !state.isOpenFilterList };
    case EmployeeRegistrationAction.TOGGLE_CATEGORIES:
      return { ...state, isOpenCategories: !state.isOpenCategories };
    case EmployeeRegistrationAction.CLOSE_FILTER_LIST:
      return { ...state, isOpenFilterList: false };
    case EmployeeRegistrationAction.CLOSE_CATEGORIES:
      return { ...state, isOpenCategories: false };
    case EmployeeRegistrationAction.CATEGORY_LIST:
      return {
        ...state,
        categoryList: action.payload,
      };
    case EmployeeRegistrationAction.PERMISSION_BUTTON_CLICKED:
      return {
        ...state,
        permissionId: action.payload,
      };
    case EmployeeRegistrationAction.UPDATE_SALARY:
      return {
        ...state,
        updateSalaryId: action.payload,
      };
    case EmployeeRegistrationAction.REMOVE_STAFF_BUTTON_CLICKED:
      return {
        ...state,
        removeId: action.payload,
      };
    default:
      return state;
  }
};
