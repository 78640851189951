import React from "react";
import { useTranslation } from "react-i18next";

export default function TableHead() {
  const { t } = useTranslation();
  return (
    <ul className="payment--grid build-table build-table--bold ">
      <li className="build-table__item">№</li>
      <li className="build-table__item">{t("O'quvchi")}</li>
      <li className="build-table__item">{t("ID")}</li>
      <li className="build-table__item">{t("To'lov summasi")}</li>
      <li className="build-table__item">{t("Filiali")}</li>
      <li className="build-table__item">{t("Guruhi")}</li>
      <li className="build-table__item">{t("O'qituvchi")}</li>
      <li className="build-table__item">{t("Kassir")}</li>
      <li className="build-table__item">{t("To'lov turi")}</li>
      <li className="build-table__item">{t("To'lov rejimi")}</li>
      <li className="build-table__item">{t("Sana")}</li>

      <li className="build-table__item build-table__item--7"></li>
    </ul>
  );
}
