import { toastError } from "../../../Helpers/toasts";
import ProfileConfigRepository from "../../../Repositories/ProfileConfig/ProfileConfigRepository";
import { useProfileConfigStore } from "../../../Store/ProfileConfiguration/ProfileConfigStore";

export const onReload = async (queryString) => {
  useProfileConfigStore.setState({
    age_groups: null,
    lesson_styles: null,
    materials: null,
    subjects: null,
  });
  const { data, status, nonFieldErrors } =
    await ProfileConfigRepository.getLessonDetails(queryString);
  if (status) {
    useProfileConfigStore.setState({
      age_groups: data.age_groups,
      lesson_styles: data.lesson_styles,
      materials: data.materials,
      subjects: data.subjects,
    });
  } else toastError(nonFieldErrors);
};

export const loadItems = async (queryParams) => {
  useProfileConfigStore.setState({
    isLoadingMaterials: true,
  });
  const { data, status, nonFieldErrors } =
    await ProfileConfigRepository.getLessonDetails(
      new URLSearchParams({
        ...queryParams,
      })
    );
  useProfileConfigStore.setState({
    isLoadingMaterials: false,
  });
  if (status) {
    useProfileConfigStore.setState({
      materials: data.materials,
    });
  } else toastError(nonFieldErrors);
};
