import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function ListItem({ result, index }) {
  const { t } = useTranslation();
  return (
    <Link
      to={`/cash-control/salary-calculations/${result?.year}/${result?.month}/${
        result?.id || null
      }/details`}
      className="list--config"
    >
      <ul className="estimated-salary--grid build-table build-table--light build-table__item">
        <li className="build-table__item">{index + 1}</li>
        <li className="build-table__item">
          {result.year}-{t("yil")}
        </li>
        <li className="build-table__item">{result.month}</li>
        <li className="build-table__item">{result.staff_count} ta</li>
        <li className="build-table__item">{result.total_final_salary} so'm</li>
        <li className="build-table__item">{result.total_fixed_salary} so'm</li>
        <li className="build-table__item">
          {result.total_kpi_based_salary} so'm
        </li>
        <li className="build-table__item">
          {result.total_contributed_money} so'm
        </li>
        <li className="build-table__item">
          {result.total_advance_salary} so'm
        </li>
        <li className="build-table__item">{result.total_unpaid_salary} so'm</li>
        <li className="build-table__item">{result.cashier}</li>
        <li className="build-table__item">{result.date}</li>
      </ul>
    </Link>
  );
}
