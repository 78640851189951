import { memo } from "react";
import DataTable from "react-data-table-component";
import { dateFormat } from "../../../../Helpers/dateFormat";
import "../../Personal-profile/muiTableStyle.css";

const customStyles = {
  rows: {
    fontSize: "15px",
  },
  headCells: {
    style: {
      width: "auto",
      fontSize: "15px",
    },
  },
  cells: {
    style: {
      width: "auto",
    },
  },
};

function LessonTable({ materials }) {
  const columns = [
    {
      name: "№",
      cell: (row, index) => <div>{index + 1}</div>,
    },
    {
      name: "Fan",
      selector: (row) => row.subject,
      cell: (row) => <div>{row.subject}</div>,
    },
    {
      name: "Guruh turi",
      selector: (row) => row.age_groups,
      cell: (row) => <div>{row.age_groups}</div>,
    },
    {
      name: "Dars uslubi",
      cell: (row) => <div>{row.lesson_styles}</div>,
      selector: (row) => row.lesson_styles,
    },
    {
      name: "Link",
      cell: (row) => (
        <a target="_blank" href={row.url}>
          {row.url}
        </a>
      ),
      selector: (row) => row.url,
    },
    {
      name: "Qo'yilgan vaqt",
      cell: (row) => <div>{dateFormat(row.created_at)}</div>,
      selector: (row) => row.created_at,
    },
  ];

  return (
    <div className="profileDataTable">
      <DataTable
        data={materials}
        columns={columns}
        highlightOnHover
        customStyles={customStyles}
      />
    </div>
  );
}
export default memo(LessonTable);
