import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AdminLayout from "../../../AdminLayout";
import EmptyContent from "../../../Components/Empty-content/EmptyContent";
import ItemsLoader from "../../../Components/Loader/basic loader/ItemsLoader";
import PaginationComponent from "../../../Components/Pagination";
import TitleBar from "../../../Components/TitleBar";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import { useDiscountsReportStore } from "../../../Store/ReportsStore/DiscountsReportStore";
import BasicButtons from "./components/BasicButtons";
import Heading from "./components/Heading";
import ListItem from "./components/ListItem";

export default function DiscountsReports() {
  const {
    loadItems,
    onReload,
    results,
    pagination,
    queryParams,
    updateParams,
    setLoading,
    isLoading,
  } = useDiscountsReportStore();
  const { setLoader } = useLoaderStore();

  const pageLoad = useCallback(async () => {
    setLoader(true);
    await onReload();
    setLoader(false);
  }, []);

  useEffect(() => {
    pageLoad();
  }, []);

  const paginateTo = useCallback(
    async (page) => {
      const queryString = new URLSearchParams({
        ...queryParams,
        page: page,
      }).toString();
      setLoading(true);
      updateParams({ page: page });
      await loadItems(queryString);
      setLoading(false);
    },
    [queryParams]
  );
  const { t } = useTranslation();
  return (
    <AdminLayout>
      <TitleBar
        permission={true}
        title={t("Chegirma olgan o'quvchilar")}
        groupBtns={<BasicButtons />}
      />
      <div className="container">
        <Heading />
        {isLoading ? (
          <ItemsLoader size={70} />
        ) : results?.length > 0 ? (
          results?.map((item, index) => {
            return <ListItem item={item} index={index} key={index} />;
          })
        ) : (
          <EmptyContent />
        )}
        <PaginationComponent
          context={{ pagination: pagination, queryParams: queryParams }}
          callback={paginateTo}
        />
      </div>
    </AdminLayout>
  );
}
