import { create } from "zustand";
import { toastError, toastSuccess } from "../../Helpers/toasts";
import SalaryAdvanceListRepository from "../../Repositories/CashControl/SalaryAdvanceListRepository";
import StudentsPaymentTransactionsRepository from "../../Repositories/CashControl/StudentsPaymentTransactionsRepository";

export const useSalaryAdvanceListStore = create((set, get) => ({
  results: null,
  branches_list: null,
  payment_types: null,
  staffs_list: null,
  deletingId: null,
  pagination: null,
  startDate: null,
  endDate: null,
  isLoading: false,
  isShowDeleteLoader: false,
  open_context_id: null,
  queryParams: {
    page: 1,
  },
  setLoading: (status) => {
    set({
      isLoading: status,
    });
  },

  updateParams: (paramItem) => {
    set((prevState) => ({
      queryParams: { ...prevState.queryParams, ...paramItem },
    }));
  },

  onReload: async (queryString = "") => {
    const { data, status, nonFieldErrors } =
      await SalaryAdvanceListRepository.expenditureList(queryString);
    if (status) {
      set({
        results: data.results,
        branches_list: data.branches_list,
        staffs_list: data.staffs_list,
        payment_types: data.payment_modes,
        pagination: { page_count: data.pages_count },
        queryParams: {
          page: 1,
        },
      });
    } else toastError(nonFieldErrors);
  },

  loadItems: async (queryString) => {
    const { data, status, nonFieldErrors } =
      await SalaryAdvanceListRepository.expenditureList(queryString);
    if (status) {
      set({
        results: data.results,
        pagination: { page_count: data.pages_count },
      });
    } else toastError(nonFieldErrors);
  },
  deleteTransaction: async (id, payment_mode) => {
    const { loadItems } = get();
    set({
      isShowDeleteLoader: true,
    });
    const queryString = new URLSearchParams({
      payment_mode: payment_mode,
    }).toString();
    const { status, nonFieldErrors } =
      await StudentsPaymentTransactionsRepository.deleteTransaction(
        id,
        queryString
      );
    if (status) {
      toastSuccess(nonFieldErrors);
      loadItems();
      set({
        deletingId: false,
        open_context_id: false,
      });
    } else toastError(nonFieldErrors);
    set({
      isShowDeleteLoader: false,
    });
  },
}));
