import React from "react";
import { useTranslation } from "react-i18next";
import { StudentsRegistrationContext } from "../../../../Contexts/RegistrationContex/StudentRegistrationContext";
import { useAttendanceStore } from "../../../../Store/AttendanceFormStore/AttendanceForm";
import { closeStudentInfo } from "../actions";

export default function StudentInfoModal() {
  const { modalLoader, studentData } = useAttendanceStore();
  const { t } = useTranslation();
  return (
    <div
      id="modal"
      className={
        studentData ? "modal active modal-select" : "modal  modal-select"
      }
    >
      <form
        action="#"
        className="modal-wrapper modal-wrapper_huge modal-wrapper-view modal-space"
      >
        <div className="modal-inner modal-inner_view">
          <h4 className="modal__title">
            {studentData?.first_name} {studentData?.last_name}{" "}
            {studentData?.middle_name}{" "}
          </h4>

          <div className="view">
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">{t("Ismi")}:</div>
                <div className="view-info">{studentData?.first_name ?? ""}</div>
              </div>
              <div className="view-box">
                <div className="view-title">{t("Bilim darajasi")}:</div>
                <div className="view-info">{studentData?.level}</div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">{t("Familyasi")}:</div>
                <div className="view-info">{studentData?.last_name}</div>
              </div>
              <div className="view-box">
                <div className="view-title">
                  {t("Talabaga ma’qul o’qish kunlari")}:
                </div>
                <div className="view-info">
                  {studentData?.suitable_days_readable[0].name}{" "}
                  {studentData?.suitable_time}
                </div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">{t("Otasining ismi")}:</div>
                <div className="view-info">{studentData?.middle_name}</div>
              </div>
              <div className="view-box">
                <div className="view-title">
                  {t("Gruppani yoshga doir holati")}:
                </div>
                <div className="view-info">
                  {studentData?.group_type_readable}
                </div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">{t("Jinsi")}:</div>
                <div className="view-info">
                  {studentData?.gender === 1 ? "Erkak" : "Ayol"}
                </div>
              </div>
              <div className="view-box">
                <div className="view-title">{t("Yashash joyi manzili")}:</div>
                <div className="view-info">{studentData?.address}</div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">{t("ID-kodi")}:</div>
                <div className="view-info">{studentData?.student_id}</div>
              </div>
              <div className="view-box">
                <div className="view-title">
                  {t("Qabulga yozilgan sanasi")}:
                </div>
                <div className="view-info">{studentData?.registered_date}</div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">{t("Ro’yxatdan o’tgan sana")}:</div>
                <div className="view-info">{studentData?.registered_date}</div>
              </div>
              <div className="view-box">
                <div className="view-title">{t("Telefon raqamlari")}:</div>
                <div className="view-info">{studentData?.phone_number}</div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">{t("Tug’ilgan sana")}:</div>
                <div className="view-info">{studentData?.birth_date}</div>
              </div>
              <div className="view-box">
                <div className="view-title">Login:</div>
                <div className="view-info">{studentData?.user?.username}</div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">{t("Talabaning yoshi")}:</div>
                <div className="view-info">{studentData?.age}</div>
              </div>
              <div className="view-box">
                <div className="view-title">{t("Aloqa")}:</div>
                <div className="view-info">
                  {studentData?.connection_status_readable}
                </div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">
                  {t("Taqdim qilgan hujjat turi")}:
                </div>
                <div className="view-info">{studentData?.document_type}</div>
              </div>
              <div className="view-box">
                <div className="view-title">
                  {t("Passport seriyasi va raqami")}:{" "}
                </div>
                <div className="view-info">
                  {studentData?.document_series} {studentData?.document_number}
                </div>
              </div>
            </div>
            <div className="view__item"></div>
          </div>
        </div>

        <div className="input-buttons input-export">
          <div
            onClick={() =>
              useAttendanceStore.setState({
                studentData: null,
              })
            }
            id="modal-close"
            className="input-btn"
          >
            {t("Ortga qaytish")}
          </div>
        </div>
      </form>
    </div>
  );
}
