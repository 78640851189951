import { useCallback } from "react";
import Select from "react-select";
import { useMonthlyReportsStore } from "../../../../Store/ReportsStore/useMonthlyReports";
import { useTranslation } from "react-i18next";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "37px",
    minHeight: "37px",
    width: "200px",
    fontSize: "13px",
    fontFamily: "Inter",
    fontWeight: "400",
  }),
};

export default function Selector({ options, property }) {
  const { updateParams, loadItems, queryParams, setLoading } =
    useMonthlyReportsStore();
  const { t } = useTranslation();

  const handleSelectChange = useCallback(async (e) => {
    setLoading(true);
    if (property == t("Yillar")) {
      updateParams({
        year: e?.value == undefined ? "" : e?.value,
      });
      await loadItems(
        new URLSearchParams({
          ...queryParams,
          year: e?.value == undefined ? "" : e?.value,
        }).toString()
      );
    }
    if (property == t("Filiallar")) {
      updateParams({
        branch: e?.value == undefined ? "" : e?.value,
      });
      await loadItems(
        new URLSearchParams({
          ...queryParams,
          branch: e?.value == undefined ? "" : e?.value,
        }).toString()
      );
    }
    setLoading(false);
  });

  return (
    <Select
      classNamePrefix="select"
      placeholder={property}
      styles={customStyles}
      options={options}
      isClearable
      onChange={handleSelectChange}
    />
  );
}
