import React, { createContext, useReducer } from "react";
import { sideBarReducer } from "../Reducers/SideBarReducers";

export const SideBarContext = createContext();

const initialState = {
  open: false,
};

const SideBarProvider = (props) => {
  const [sideBar, dispatchSideBar] = useReducer(sideBarReducer, {
    ...initialState,
  });

  return (
    <SideBarContext.Provider value={{ sideBar, dispatchSideBar }}>
      {props.children}
    </SideBarContext.Provider>
  );
};

export default SideBarProvider;
