import { useEffect } from "react";
import AdminLayout from "../../../AdminLayout";
import TableHead from "./components/TableHead.jsx";
import ListItem from "./components/ListItem.jsx";
import ReportsTitleBar from "./components/ReportsTitleBar";
import { useReadyReportStore } from "../../../Store/ReportsStore/ReadyReportStore";
import { useCallback } from "react";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import EmptyContent from "../../../Components/Empty-content/EmptyContent";
import ItemsLoader from "../../../Components/Loader/basic loader/ItemsLoader";
import ColorToggleButton from "./components/ToggleButton";
import { useTranslation } from "react-i18next";

export default function ReadyReports() {
  const { onReload, isLoading, results, statistics, getBranches } =
    useReadyReportStore();
  const { setLoader } = useLoaderStore();
  const { t } = useTranslation();

  const pageLoad = useCallback(async () => {
    setLoader(true);
    await Promise.all([onReload(), getBranches()]);
    setLoader(false);
  }, []);

  useEffect(() => {
    pageLoad();
  }, []);

  return (
    <AdminLayout>
      <ReportsTitleBar title={t("Qabulxona hisobotlar")} createAction={false} />
      <div className="container">
        <ColorToggleButton />
        <TableHead />
        {isLoading ? (
          <ItemsLoader size={70} />
        ) : results?.length > 0 ? (
          <>
            {results.map((item, index) => (
              <ListItem item={item} index={index} key={index} />
            ))}
            {/* <DailyReport /> */}
            <div className="midle_line"></div>
            {statistics &&
              statistics.map((item, index) => (
                <ListItem item={item} index={index} key={index} />
              ))}
          </>
        ) : (
          <EmptyContent />
        )}
      </div>
    </AdminLayout>
  );
}
