import { toastError } from "../../../Helpers/toasts";
import ExamScheduleRepository from "../../../Repositories/Groups/ExamScheduleRepository";
import { useExamScheduleStore } from "../../../Store/ManageGroupsStore/ExamScheduleStore";

export const onReload = async () => {
  useExamScheduleStore.setState({
    isLoading: true,
  });
  const { data, status, nonFieldErrors } =
    await ExamScheduleRepository.getExamLists();
  if (status) {
    useExamScheduleStore.setState({
      data: data.data.results,
      unmarkedExamsCount: data.data.unmarked_exams_count,
      total_exams_count: data.data.total_exams_count,
      filterset: data.data.filterset,
      isLoading: false,
      pagination: { pages_count: data.data.pages_count },
    });
  }
  toastError(nonFieldErrors);
  useExamScheduleStore.setState({
    isLoading: false,
  });
};
export const getExamListWithParam = async (queryString) => {
  const { data, status, nonFieldErrors } =
    await ExamScheduleRepository.getExamLists(queryString);
  if (status) {
    useExamScheduleStore.setState({
      data: data.data.results,
      unmarkedExamsCount: data.data.unmarked_exams_count,
      isLoading: false,
      pagination: { pages_count: data.data.pages_count },
      total_exams_count: data.data.total_exams_count,
    });
  }
  toastError(nonFieldErrors);
  return;
};
