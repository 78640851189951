import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BranchesActions from "../../../Actions/ConfigActions/BranchesActions";
import AdminLayout from "../../../AdminLayout";
import DeleteConfirmation from "../../../Components/DeleteConfirmation";
import EmptyContent from "../../../Components/Empty-content/EmptyContent";
import PaginationComponent from "../../../Components/Pagination";
import TitleBar from "../../../Components/TitleBar";
import { BranchesContext } from "../../../Contexts/ConfigContext/BranchesContext";
import { LoaderContext } from "../../../Contexts/LoaderContext";
import UserPermissionWidget from "../../../UserPermissionWidget";
import {
  cancelDeleteAction,
  createAction,
  deleteAction,
  loadItems,
  onReload,
} from "./actions";
import CreateUpdateModal from "./components/CreateUpdateModal";
import ListItem from "./components/ListItem";
import TableHead from "./components/TableHead";

export default function Branches() {
  const { branches, dispatchBranches } = useContext(BranchesContext);
  const { dispatchLoader } = useContext(LoaderContext);
  const [options, setOptions] = useState([]);
  const pageReload = async () => {
    await onReload(branches, dispatchBranches, dispatchLoader);
  };
  useEffect(() => {
    pageReload();
  }, []);
  useEffect(() => {
    setOptions(
      branches.staffs?.map((element) => ({
        label: element.fullname,
        value: element.id,
      })) || []
    );
  }, [branches.staffs]);

  const paginateTo = useCallback(
    (page) => {
      dispatchBranches({
        type: BranchesActions.ADD_QUERY_PARAM,
        payload: { page: page },
      });
      loadItems({ page: page }, branches, dispatchBranches, dispatchLoader);
    },
    [branches, dispatchBranches, dispatchLoader]
  );

  const user = JSON.parse(localStorage.getItem("user"));
  const { t } = useTranslation();

  return (
    <AdminLayout>
      <TitleBar
        title={t("branches_title")}
        createAction={() => createAction(dispatchBranches)}
        permission={user?.permissions?.create_branch}
      />
      <div className="container remove_scroll">
        <TableHead />
        {branches.data.length ? (
          branches.data.map((branch, index) => (
            <ListItem branch={branch} key={branch.id} index={index} />
          ))
        ) : (
          <EmptyContent />
        )}
        <PaginationComponent context={branches} callback={paginateTo} />
      </div>
      <UserPermissionWidget hasPermission={user?.permissions?.create_branch}>
        <CreateUpdateModal staffs={options} />
      </UserPermissionWidget>
      <DeleteConfirmation
        deleteCallback={(e) =>
          deleteAction(e, branches, dispatchBranches, dispatchLoader)
        }
        cancelCallback={() => cancelDeleteAction(dispatchBranches)}
        showModal={branches}
      />
    </AdminLayout>
  );
}
