import { Button, Stack } from "@mui/material";
import React from "react";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";

const back_btn = {
  height: "35px",
  margin: "10px 0",
  width: "48%",
};

export default function SaveButton({ isloading, formik }) {
  const navigate = useNavigate();
  const cancel = () => {
    navigate(-1, { replace: true });
    formik.resetForm();
  };
  return (
    <Stack flexDirection={"row"} justifyContent="space-between">
      <Button
        onClick={() => cancel()}
        variant="outlined"
        color="primary"
        sx={back_btn}
      >
        Ortga
      </Button>
      <Button variant="contained" sx={back_btn} onClick={formik.handleSubmit}>
        {isloading ? (
          <ReactLoading
            type={"spin"}
            color="#ffffff"
            className="delete_loader"
            height={"25px"}
            width={"25px"}
          />
        ) : (
          `Saqlash`
        )}
      </Button>
    </Stack>
  );
}
