import { create } from "zustand";
import MainGroupsRepository from "../../Repositories/Groups/MainGroupsRepository";
import EmployeeRepository from "../../Repositories/Registration/EmployeeRepository";
import StudentsRepository from "../../Repositories/Registration/StudentsRepository";
import MonthlyReportRepository from "../../Repositories/Reports/MonthlyReportsRepository";
import DashboardRepository from "../../Repositories/Trello/DashboardRepository";

export const useDashboardStore = create((set, get) => ({
    dailyFinance: null,
    dashboardReports: null,
    staffs: null,
    teachers: null,
    students: null,
    groups: null,
    subjects: null,
    monthlyFinance: null,
    isLoading: false,
    setLoading: (status) => {
        set({
            isLoading: status,
        });
    },
    onReload: async () => {
        const [finance, reports, subjects, monthlyFinance] = await Promise.all([
            DashboardRepository.getDailyFinance(),
            DashboardRepository.getDashboardReports(),
            MainGroupsRepository.getAllSubjects(),
            MonthlyReportRepository.studentsList(),
        ])
        set({
            dailyFinance: finance.data,
            dashboardReports: reports.data,
            subjects: subjects.data,
            monthlyFinance: monthlyFinance.data,
        });
    },

}));
