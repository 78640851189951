import React from "react";
import { useStudentAcquisitionStore } from "../../../../Store/GroupManagement/StudentAcquisitionStore";

function TotalCounts() {
  const { total_counts } = useStudentAcquisitionStore();

  return (
    <ul className="studentAttendance-table build-table build-table--light build-table__item">
      <li className="build-table__item"></li>
      <li className="build-table__item">{total_counts?.name}</li>
      <li className="build-table__item">{total_counts?.groups_count} ta</li>
      <li className="build-table__item">{total_counts?.students_count} ta</li>
      <li className="build-table__item">
        {total_counts?.high_scoring_students_count} ta
      </li>
      <li className="build-table__item">
        {total_counts?.medium_scoring_students_count} ta
      </li>
      <li className="build-table__item">
        {total_counts?.low_scoring_students_count} ta
      </li>
    </ul>
  );
}
export default React.memo(TotalCounts);
