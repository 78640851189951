import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useGroupLevelStore } from "../../../../Store/GroupManagement/GroupLevelUpStore";
import { useLoaderStore } from "../../../../Store/Loader/LoaderStore";
import { loadItems } from "../actions";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "38px",
    minHeight: "38px",
    width: "200px",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: "400",
  }),
};

export default function Selector({ property, options, value }) {
  const { queryParams, updateParams } = useGroupLevelStore();
  const { setLoader } = useLoaderStore();

  const { t } = useTranslation();

  const handleSelectChange = async (e) => {
    setLoader(true);
    if (property === t("Oylar")) {
      let queryString = queryParams;
      if (e?.value == undefined) {
        delete queryString.month;
      } else {
        queryString = { ...queryParams, month: e?.value, page: 1 };
      }
      updateParams(queryString);
      await loadItems(new URLSearchParams(queryString).toString());
    }
    if (property === t("Filiallar")) {
      let queryString = queryParams;
      if (e?.value == undefined) {
        delete queryString.branch;
      } else {
        queryString = { ...queryParams, branch: e?.value, page: 1 };
      }
      updateParams(queryString);
      await loadItems(new URLSearchParams(queryString).toString());
    }
    if (property === t("Fanlar")) {
      let queryString = queryParams;
      if (e?.value == undefined) {
        delete queryString.subject;
      } else {
        queryString = { ...queryParams, subject: e?.value, page: 1 };
      }
      updateParams(queryString);
      await loadItems(new URLSearchParams(queryString).toString());
    }
    setLoader(false);
  };

  return (
    <div className="normal-select-bar">
      <Select
        className="basic-single"
        classNamePrefix="select"
        placeholder={property}
        styles={customStyles}
        isClearable
        options={options}
        value={value}
        onChange={handleSelectChange}
      />
    </div>
  );
}
