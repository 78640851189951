import React from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useLoaderStore } from "../../../../Store/Loader/LoaderStore";
import { useExpenditureReportsStore } from "../../../../Store/ReportsStore/ExpenditureReportsStore";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "37px",
    minHeight: "37px",
    width: "100%",
    minWidth: "150px",
    fontSize: "13px",
    fontFamily: "Inter",
    fontWeight: "400",
  }),
};

function ExpenditureSelect({ param, property }) {
  const { queryParams, updateParams, loadItems } = useExpenditureReportsStore();
  const { setLoader } = useLoaderStore();
  const { t } = useTranslation();

  const options =
    param?.map((element) => ({
      label: element.name,
      value: element.value,
    })) || [];

  const handleSelectChange = useCallback(async (e) => {
    setLoader(true);
    if (property === "oylar") {
      updateParams({
        month: e?.value == undefined ? "" : e?.value,
      });
      await loadItems(
        new URLSearchParams({
          ...queryParams,
          month: e?.value == undefined ? "" : e?.value,
        }).toString()
      );
    } else {
      updateParams({
        year: e?.value == undefined ? "" : e?.value,
      });
      await loadItems(
        new URLSearchParams({
          ...queryParams,
          year: e?.value == undefined ? "" : e?.value,
        }).toString()
      );
    }
    setLoader(false);
  });

  return (
    <div className="normal-select-bar">
      <Select
        className="basic-single manage_group_select"
        classNamePrefix="select"
        placeholder={property == "oylar" ? t("Oylar") : t("Yillar")}
        styles={customStyles}
        options={options}
        isClearable
        onChange={handleSelectChange}
      />
    </div>
  );
}
export default React.memo(ExpenditureSelect);
