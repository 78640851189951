import { create } from "zustand";
import { toastError } from "../../Helpers/toasts";
import TaskBooksRepository from "../../Repositories/TaskBooks/TaskBooksRepository";

export const useTaskBookStore = create((set, get) => ({
  materials: null,
  pagination: null,
  selectedFile: null,
  ageGroup: null,
  lessonMonths: null,
  isLoading: false,
  queryParams: {
    page: 1,
  },
  subjectDetails: [],
  updateParams: (paramItem) => {
    set((prevState) => ({
      queryParams: { ...prevState.queryParams, ...paramItem },
    }));
  },

  setLoading: (status) => {
    set({
      isLoading: status,
    });
  },
  onReload: async () => {
    const { data, status, nonFieldErrors } =
      await TaskBooksRepository.getMaterials();
    if (status) {
      set({
        materials: data.subjects,
      });
    } else toastError(nonFieldErrors);
  },
  getSubjectDetails: async (queryString) => {
    const { data, status, nonFieldErrors } =
      await TaskBooksRepository.getSubject(queryString);
    if (data?.ok && status) {
      set({
        subjectDetails: data.data,
        pagination: { page_count: data.data.pages_count },
      });
    } else toastError(nonFieldErrors);
  },
  getAgeGroup: async () => {
    const { data, status, nonFieldErrors } =
      await TaskBooksRepository.getAgeGroup();
    if (status) {
      set({
        ageGroup: data.age_groups,
      });
    } else toastError(nonFieldErrors);
  },
  getLessonMonths: async () => {
    const { data, status, nonFieldErrors } =
      await TaskBooksRepository.getLessonsMonths();
    if (status) {
      set({
        lessonMonths: data.months,
      });
    } else toastError(nonFieldErrors);
  }
}));
