import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useExpendatureCashStore } from "../../../../../Store/CashControlStore/ExpendatureCashStore";
import ColorToggleButton from "./ToggleButton";

export default function PaymentSection() {
  const { updateFixedCosts, fixedCosts } = useExpendatureCashStore();

  const handleChange = (e) => {
    const inputValue = e.target.value.replace(/ /g, ""); // Remove empty spaces from the input value
    const formattedValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, " "); // Add empty spaces for formatting
    updateFixedCosts("amountPlaceholder", formattedValue);
    updateFixedCosts("amount", e.target.value.replace(/ /g, ""));
  };
  const { t } = useTranslation();
  return (
    <div className="payment-place payment-expenditure--space">
      <input
        className="expenditure-input"
        type="text"
        placeholder={t("To'lov miqdori")}
        onChange={handleChange}
        value={fixedCosts.amountPlaceholder || ""}
      />
      <ColorToggleButton />
    </div>
  );
}
