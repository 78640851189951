import React from "react";
import HeaderLanguage from "./HeaderLanguage";
import HeaderProfileDetail from "./HeaderProfileDetail";

export default function HeaderProfile() {
  return (
    <div className="header-profile">
      <HeaderLanguage />
      <HeaderProfileDetail />
    </div>
  );
}
