import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UserPermissionWidget from "../../../UserPermissionWidget";

export default function FormBtn({ updateItem, saveLoader }) {
  const navigate = useNavigate();

  const back_btn = {
    height: "30px",
    marginRight: "20px",
    background: "white",
    border: "none !important",
  };

  const handleUpdateItem = useCallback(() => {
    updateItem();
  }, [updateItem]);
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <div className="submit_btn_group_student">
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1, { replace: true })}
        variant="outlined"
        color="warning"
        sx={back_btn}
      >
        Ortga
      </Button>
      <UserPermissionWidget
        hasPermission={user?.permissions?.update_group_composition}
      >
        <Button
          onClick={handleUpdateItem}
          disabled={saveLoader}
          variant="contained"
          sx={{ width: 100, height: 30 }}
        >
          {saveLoader ? (
            <ReactLoading
              type={"spin"}
              color="#ffffff"
              className="delete_loader"
              height={"25px"}
              width={"25px"}
            />
          ) : (
            "Saqlash"
          )}
        </Button>
      </UserPermissionWidget>
    </div>
  );
}
