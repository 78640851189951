import React from "react";
import SearchResultSection from "./SearchResultSection";

export default function SearchSectionContainer() {
  return (
    <div className="transfer__item">
      <div className="transfer-info transfer-info_huge">
        <SearchResultSection />
      </div>
    </div>
  );
}
