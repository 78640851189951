import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { Box, IconButton } from "@mui/material";
import { memo, useCallback, useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import StudentAttachmentAction from "../../../Actions/StudentAttachment/StudentAttachmentAction";
import { LoaderContext } from "../../../Contexts/LoaderContext";
import { StudentAttachmentContext } from "../../../Contexts/StudentAttachmentContext/StudentAttachmentContext";
import { toastError, toastSuccess } from "../../../Helpers/toasts";
import MainGroupsRepository from "../../../Repositories/Groups/MainGroupsRepository";
import PaginationComponent from "../../Pagination";
import { changeGroupStudent, changeStudentsData, loadItems } from "../actions";
import FormBtn from "./FormBtn";
import SubHeaderComponent from "./SubHeaderComponent";

const customStyles = {
  header: {
    style: {
      borderTopLeftRadius: "15px !important",
      borderTopRightRadius: "15px !important",
    },
  },
  rows: {
    style: {
      fontSize: "11px",
      display: "grid",
      gap: "0 10px",
      gridTemplateColumns: "0.3fr 1fr 1fr 1fr 1fr 0.8fr",
    },
  },
  cells: {
    style: {
      padding: "0",
      display: "flex",
      justifyContent: "center",
      minWidth: "20px !important",
    },
  },
  headRow: {
    style: {
      display: "grid",
      gap: "0 10px",
      gridTemplateColumns: "0.3fr 1fr 1fr 1fr 1fr 0.8fr",
    },
  },
  headCells: {
    style: {
      minWidth: "0px !important",
      display: "flex",
      justifyContent: "center",
      paddingRight: "0",
    },
  },
};
const customStylesGroup = {
  header: {
    style: {
      borderTopLeftRadius: "15px !important",
      borderTopRightRadius: "15px !important",
    },
  },
  rows: {
    style: {
      fontSize: "11px",
      display: "grid",
      gap: "0 10px",
      gridTemplateColumns: "0.8fr 0.3fr 1fr 1fr 1fr 1fr",
    },
  },
  cells: {
    style: {
      padding: "0",
      display: "flex",
      justifyContent: "center",
      minWidth: "20px !important",
    },
  },
  headRow: {
    style: {
      display: "grid",
      gap: "0 10px",
      gridTemplateColumns: "0.8fr 0.3fr 1fr 1fr 1fr 1fr",
    },
  },
  headCells: {
    style: {
      minWidth: "0px !important",
      display: "flex",
      justifyContent: "center",
      paddingRight: "0",
    },
  },
};

function DataTableStudents({ groupId, connectionState }) {
  const { studentAttachment, dispatchStudentAttachment } = useContext(
    StudentAttachmentContext
  );
  const { dispatchLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  const [saveLoader, setSaveLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [checkboxFirst, setCheckboxFirst] = useState(false);
  const [checkboxSecond, setCheckboxSecond] = useState(false);
  const [searchGroup, setSearchGroup] = useState("");

  const checkboxFirstState = { checkboxFirst, setCheckboxFirst };
  const checkboxSecondState = { checkboxSecond, setCheckboxSecond };
  const searchState = { search, setSearch };

  useEffect(() => {
    const result = studentAttachment.groupStudents?.filter((element) => {
      return element.first_name.toLowerCase().match(searchGroup?.toLowerCase());
    });
    dispatchStudentAttachment({
      type: StudentAttachmentAction.CHANGE_GROUP_STUDENT,
      payload: result,
    });
  }, [searchGroup]);

  useEffect(() => {
    const result = studentAttachment.data?.filter((element) => {
      return element.first_name.toLowerCase().match(search?.toLowerCase());
    });
    dispatchStudentAttachment({
      type: StudentAttachmentAction.CHANGE_FILTERED_DATA,
      payload: result,
    });
  }, [search]);

  const groupStudent = [
    {
      name: "Qaytarish",
      cell: (row) => (
        <IconButton
          color="error"
          aria-label="add to shopping cart"
          onClick={(e) => removeGroupHandler(e, row)}
        >
          <ArrowCircleLeftIcon
            style={{
              fontSize: "30px",
              textAlign: "center",
            }}
          />
        </IconButton>
      ),
    },
    {
      name: "№",
      sortable: true,
      cell: (row, index) => (
        <Box sx={{ width: "100%", textAlign: "center" }}>{index + 1}</Box>
      ),
    },
    {
      name: "Ismi va Famliyasi",
      selector: (row) => row.first_name,
      cell: (row) => (
        <Box sx={{ width: "100%", textAlign: "center" }}>
          {row.first_name} {row.last_name}
        </Box>
      ),
      sortable: true,
    },
    {
      name: "Guruh turi",
      selector: (row) => row.group_type_readable,
      cell: (row) => (
        <Box sx={{ width: "100%", textAlign: "center" }}>
          {row.group_type_readable}
        </Box>
      ),
      sortable: true,
    },
    {
      name: "Telefon raqami",
      cell: (row) => (
        <Box sx={{ width: "100%", textAlign: "center" }}>
          {row.phone_number}
        </Box>
      ),
      selector: (row) => row.phone_number,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <Box sx={{ width: "100%", textAlign: "center" }}>
          {row.connection_status_readable}
        </Box>
      ),
      selector: (row) => row.connection_status_readable,
      sortable: true,
    },
  ];

  const columns = [
    {
      name: "№",
      sortable: true,
      cell: (row, index) => (
        <Box sx={{ width: "100%", textAlign: "center" }}>{index + 1}</Box>
      ),
    },
    {
      name: "Ismi va Famliyasi",
      selector: (row) => row.first_name,
      cell: (row) => (
        <Box sx={{ width: "100%", textAlign: "center" }}>
          {row.first_name} {row.last_name}
        </Box>
      ),
      sortable: true,
    },
    {
      name: "Guruh turi",
      selector: (row) => row.group_type_readable,
      cell: (row) => (
        <Box sx={{ width: "100%", textAlign: "center" }}>
          {row.group_type_readable}
        </Box>
      ),
      sortable: true,
    },
    {
      name: "Telefon raqami",
      cell: (row) => (
        <Box sx={{ width: "100%", textAlign: "center" }}>
          {row.phone_number}
        </Box>
      ),
      selector: (row) => row.phone_number,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <Box sx={{ width: "100%", textAlign: "center" }}>
          {row.connection_status_readable}
        </Box>
      ),
      selector: (row) => row.connection_status_readable,
      sortable: true,
    },
    {
      name: "Ko'chirish",
      cell: (row) => (
        <IconButton
          color="primary"
          aria-label="add to shopping cart"
          onClick={(e) => addGroupHandler(e, row)}
        >
          <ArrowCircleRightIcon
            style={{
              fontSize: "30px",
              textAlign: "center",
            }}
          />
        </IconButton>
      ),
    },
  ];

  const addGroupHandler = useCallback((e, element) => {
    e.preventDefault();
    const { groupStudents, filteredData } = studentAttachment;

    const foundItem = groupStudents?.find((item) => item.id === element.id);

    if (!foundItem) {
      const filteredList = [...(groupStudents ?? []), element];
      const filterData = filteredData.filter((item) => item.id !== element.id);

      changeStudentsData(filterData, dispatchStudentAttachment);
      changeGroupStudent(filteredList, dispatchStudentAttachment);
    }
  });
  const removeGroupHandler = useCallback((e, element) => {
    e.preventDefault();
    const { groupStudents, filteredData } = studentAttachment;

    const foundItem = groupStudents?.find((item) => item.id === element.id);

    const filter = groupStudents.filter(
      (element) => element.id !== foundItem.id
    );
    const filterData = [...filteredData, foundItem];

    changeStudentsData(filterData, dispatchStudentAttachment);
    changeGroupStudent(filter, dispatchStudentAttachment);
  });

  const updateItem = useCallback(async () => {
    const studentsId =
      studentAttachment.groupStudents?.map((element) => element.id) || [];
    const data = { student_ids: studentsId };
    setSaveLoader(true);

    const { status, nonFieldErrors: responseMessage } =
      await MainGroupsRepository.addStudents(data, groupId);

    setSaveLoader(false);

    if (status === true) {
      toastSuccess("O'quvchilar gruhga qo'shildi!");
      navigate(-1, { replace: true });
    } else {
      toastError(responseMessage);
    }
  });

  const paginateTo = useCallback(async (page) => {
    dispatchStudentAttachment({
      type: StudentAttachmentAction.ADD_QUERY_PARAM,
      payload: { page: page },
    });
    await loadItems(
      { page: page },
      studentAttachment,
      dispatchStudentAttachment,
      dispatchLoader
    );
  });

  return (
    <div className="data_table">
      <div className="data_table_block">
        <DataTable
          title="Ro'yhatdan o'tgan o'quvchilar"
          data={studentAttachment.filteredData ?? []}
          highlightOnHover
          columns={columns}
          customStyles={customStyles}
          fixedHeader
          subHeader
          subHeaderComponent={
            <SubHeaderComponent
              connectionState={connectionState}
              searchState={searchState}
              checkboxFirstState={checkboxFirstState}
              checkboxSecondState={checkboxSecondState}
              dispatchStudentAttachment={dispatchStudentAttachment}
            />
          }
          subHeaderAlign
        />
        <PaginationComponent
          context={studentAttachment}
          callback={paginateTo}
        />
      </div>
      <div className="data_table_block">
        <DataTable
          title={`${studentAttachment.groupData?.number} - guruh tarkibi`}
          highlightOnHover
          columns={groupStudent}
          data={studentAttachment.filteredGroupData ?? []}
          customStyles={customStylesGroup}
          pagination
          fixedHeader
          subHeader
          subHeaderComponent={
            <div className="sub_header">
              <input
                value={searchGroup}
                onChange={(e) => setSearchGroup(e.target.value)}
                type="text"
                placeholder="Search here..."
                className="table_search"
              />
            </div>
          }
          subHeaderAlign
        />
        <FormBtn updateItem={updateItem} saveLoader={saveLoader} />
      </div>
    </div>
  );
}
export default memo(DataTableStudents);
