import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AdminLayout from "../../../AdminLayout";
import EmptyContent from "../../../Components/Empty-content/EmptyContent";
import ItemsLoader from "../../../Components/Loader/basic loader/ItemsLoader";
import PaginationComponent from "../../../Components/Pagination";
import TitleBar from "../../../Components/TitleBar";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import { useTaskBookStore } from "../../../Store/TaskBookStore/TaskBookStore";
import FileModal from "./components/FIleModal";
import Heading from "./components/Heading";
import ListItem from "./components/ListItem";

export default function TaskBookLists() {
  const {
    getSubjectDetails,
    subjectDetails,
    getLessonMonths,
    getAgeGroup,
    pagination,
    updateParams,
    queryParams,
    setLoading,
    isLoading,
  } = useTaskBookStore();
  const { setLoader } = useLoaderStore();
  const { id } = useParams();

  const pageLoad = useCallback(async () => {
    setLoader(true);
    updateParams({
      subject_id: id,
    });
    const queryString = new URLSearchParams({
      ...queryParams,
      subject_id: id,
    }).toString();
    await Promise.all([
      getSubjectDetails(queryString),
      getAgeGroup(),
      getLessonMonths(),
    ]);
    setLoader(false);
  }, []);

  useEffect(() => {
    pageLoad();
  }, []);

  const paginateTo = useCallback(
    async (page) => {
      const queryString = new URLSearchParams({
        ...queryParams,
        page: page,
      }).toString();
      setLoading(true);
      updateParams({ page: page });
      await getSubjectDetails(queryString);
      setLoading(false);
    },
    [queryParams]
  );

  const { t } = useTranslation();

  return (
    <AdminLayout>
      <TitleBar title={t("Ingliz tilidan o'qitish metodikasi")} />
      <div className="container">
        <Heading />
        {isLoading ? (
          <ItemsLoader size={70} />
        ) : subjectDetails?.results?.lessons.length ? (
          subjectDetails?.results?.lessons?.map((row, index) => (
            <ListItem item={row} index={index} key={row.id} />
          ))
        ) : (
          <EmptyContent />
        )}
        <FileModal />
        <PaginationComponent
          context={{ pagination: pagination, queryParams: queryParams }}
          callback={paginateTo}
        />
      </div>
    </AdminLayout>
  );
}
