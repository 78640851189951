import React from "react";

export default function CommonHeading({
  topItems = null,
  rightItems = null,
  tableHead = null,
  extraClass = "",
}) {
  return (
    <div className={`build-search  ${extraClass}`}>
      <div className="build-top">
        <div className="build-top__item">{topItems}</div>
        <div className="build-top__item build-export__second">{rightItems}</div>
      </div>
      {tableHead}
    </div>
  );
}
