import React, { useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import SideBarActions from "../../Actions/ComponentsActions/SideBarActions";
import { SideBarContext } from "../../Contexts/SideBarContext";

export default function HeaderBurger() {
  const { sideBar, dispatchSideBar } = useContext(SideBarContext);

  const toggleSideBar = useCallback(() => {
    dispatchSideBar({ type: SideBarActions.TOGGLE });
  });

  return (
    <div className="btn-wrapper">
      {/* <div className="menu-btn">
        <div className="menu-line"></div>
        <div className="menu-line"></div>
        <div className="menu-line"></div>
      </div> */}
      <div className="home-logo">
        <Link to="/">
          <img className="home-logo__img" src="/images/logo.png" alt="logo" />
        </Link>
      </div>
    </div>
  );
}
