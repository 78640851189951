import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./Helpers/i18n";
import ArchivedGroupsProvider from "./Contexts/GroupsContext/ArchivedGroupsContext";
import AuthenticationProvider from "./Contexts/AuthContext/AuthenticationContext";
import BranchesProvider from "./Contexts/ConfigContext/BranchesContext";
import CourseTemplateProvider from "./Contexts/ConfigContext/CourseTemplateContext";
import LanguageProvider from "./Contexts/LanguageContext";
import LoaderProvider from "./Contexts/LoaderContext";
import MainGroupsProvider from "./Contexts/GroupsContext/MainGroupsContext";
import EmployeeProvider from "./Contexts/RegistrationContex/EmployeeRegistrationContext";
import StudentsProvider from "./Contexts/RegistrationContex/StudentRegistrationContext";
import SideBarProvider from "./Contexts/SideBarContext";
import StudentAttachmentProvider from "./Contexts/StudentAttachmentContext/StudentAttachmentContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LoaderProvider>
    <AuthenticationProvider>
      <LanguageProvider>
        <SideBarProvider>
          <CourseTemplateProvider>
            <BranchesProvider>
              <EmployeeProvider>
                <StudentsProvider>
                  <MainGroupsProvider>
                    <ArchivedGroupsProvider>
                      <StudentAttachmentProvider>
                        <BrowserRouter>
                          <App />
                        </BrowserRouter>
                      </StudentAttachmentProvider>
                    </ArchivedGroupsProvider>
                  </MainGroupsProvider>
                </StudentsProvider>
              </EmployeeProvider>
            </BranchesProvider>
          </CourseTemplateProvider>
        </SideBarProvider>
      </LanguageProvider>
    </AuthenticationProvider>
  </LoaderProvider>
);
