export default function Searchbox({ formik }) {
  const onSubmit = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <input
          type="text"
          name="term"
          placeholder="Search"
          className="search-box"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.term}
        />
      </form>
      <img
        onClick={onSubmit}
        className="build-search__img"
        src="/images/search.svg"
        alt="search"
      />
    </>
  );
}
