import { toastError } from "../../../Helpers/toasts";
import RoomAvailabilityRepository from "../../../Repositories/GroupManagement/RoomAvailabilityRepository";
import { useRoomAvailabilityStore } from "../../../Store/GroupManagement/RoomAvailabilityStore";

export const onReload = async (queryString) => {
  useRoomAvailabilityStore.setState({
    data: null,
    lessonStyles: null,
    queryParams: { lesson_style: 1 },
  });
  const { data, status, nonFieldErrors } =
    await RoomAvailabilityRepository.getRoomAvailability(queryString);
  if (status) {
    useRoomAvailabilityStore.setState({
      data: data,
      lessonStyles: data.lesson_styles,
    });
  }
  toastError(nonFieldErrors);
};
export const loadItems = async (queryString) => {
  const { data, status, nonFieldErrors } =
    await RoomAvailabilityRepository.getRoomAvailability(queryString);
  if (status) {
    useRoomAvailabilityStore.setState({
      data: data,
    });
  }
  toastError(nonFieldErrors);
};
