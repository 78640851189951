import { useTranslation } from "react-i18next";
import CommonHeading from "../../../../Components/CommonHeading";
import { useStudentAcquisitionStore } from "../../../../Store/GroupManagement/StudentAcquisitionStore";
import MonthSelector from "./Select";
import TableHead from "./TableHead";

export default function Heading() {
  const { years, months } = useStudentAcquisitionStore();
  const { t } = useTranslation();

  return (
    <CommonHeading
      topItems={
        <>
          <MonthSelector param={years} property={t("Yillar")} />
          <MonthSelector param={months} property={t("Oylar")} />
        </>
      }
      tableHead={<TableHead />}
    />
  );
}
