import React, { createContext, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { employeeReducers } from "../../Reducers/RegistrationReducers/EmployeeReducers";

export const EmployeeRegistrationContext = createContext();
const initialState = {
  data: null,
  branches: null,
  staff_count: null,
  staffType: null,
  filteredStaffs: null,
  item: null,
  isOpenInfo: false,
  isCreating: false,
  isEditing: false,
  editingId: null,
  permissionId: null,
  updateSalaryId: null,
  removeId: null,
  isDeleteModalOpen: false,
  isModalOpen: false,
  deletingId: null,
  openContextMenuId: null,
  isOpenFilterList: false,
  isOpenCategories: false,
  isShowDeleteLoader: false,
  categoryList: [
    { isOpenCategory: true, name: "Ro’yxatdan o’tgan sana" },
    { isOpenCategory: true, name: "Tug’ilgan sana" },
    { isOpenCategory: true, name: "Hujjat turikumi" },
    { isOpenCategory: true, name: "Hujjat seriya va raqami" },
    { isOpenCategory: true, name: "Lavozimi va xizmat vazifasi" },
    { isOpenCategory: true, name: "Ish faoliyati joyi" },
    { isOpenCategory: true, name: "Telefon raqam" },
    { isOpenCategory: true, name: "Oylik" },
    { isOpenCategory: true, name: "Qabul qilingan va bekor qilingan sana" },
    { isOpenCategory: true, name: "Sababi" },
  ],
  queryParams: {
    page: 1,
  },
};

const EmployeeProvider = (props) => {
  const [employee, dispatchEmployee] = useReducer(employeeReducers, {
    ...initialState,
  });

  return (
    <EmployeeRegistrationContext.Provider
      value={{ employee, dispatchEmployee }}
    >
      {props.children}
    </EmployeeRegistrationContext.Provider>
  );
};

export default EmployeeProvider;
