import React from "react";
import Select from "react-select";
import { useExamDetailsStore } from "../../../../Store/ManageGroupsStore/ExamDetailsStore";

const customStyles = {
  control: (provided) => ({
    ...provided,
    minWidth: "130px",
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: "400",
    zIndex: "10",
  }),
};

export default function ExaminerSelect({ props, examiner }) {
  const { examiners, setExamTypes } = useExamDetailsStore();

  const examinerTeachers =
    examiners.map((element) => ({
      value: element.id,
      label: element.fullname,
    })) || [];

  const checkedExaminer = examinerTeachers.find(
    (staff) => staff.value == examiner
  );
  return (
    <Select
      classNamePrefix="select"
      placeholder="Examiner"
      styles={customStyles}
      options={examinerTeachers}
      defaultValue={checkedExaminer}
      onChange={(e) => setExamTypes(e, props, "examiner")}
    />
  );
}
