import { axiosInterceptor } from "../AxiosInterceptor";

export default class SalaryAdvanceRepository {
  static async getStaffs() {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get("/finance/staffs/");
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch {
      result = { ...result, nonFieldErrors: "Ma'lumot olib bo'lmadi" };
    }
    return result;
  }
  static async getStaffDetail(id) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        `/finance/staffs/${id}/payroll_months/`
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      result = { ...result, nonFieldErrors: "Ma'lumot olib bo'lmadi!" };
    }
    return result;
  }
  static async paySalary(data) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.post(
        `/finance/payments/pay-salary/`,
        data
      );
      if (response.status === 200) {
        result = {
          ...result,
          status: response.data.ok,
          nonFieldErrors: response.data.ok
            ? response.data.message
            : response.data.error,
        };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: "Saqlab bo'lmadi",
      };
    }
    return result;
  }
}
