import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import "typeface-inter";
import { useTranslation } from "react-i18next";
export default function ListItemTable(items) {
  const { t } = useTranslation();
  const StyledTableCellHeader = styled(TableCell)({
    color: "#111",
    fontFamily: "Inter",
    padding: "7px 30px",
    fontSize: "12px",
    fontWeight: "700",
    textAlign: "center",
  });
  const StyledTableCell = styled(TableCell)({
    color: "#111",
    fontFamily: "Inter",
    fontSize: "11px",
    padding: "7px 32px",
    textAlign: "center",
  });

  return (
    <Paper sx={{ width: "99%" }}>
      <TableContainer sx={{ maxHeight: 210 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCellHeader align="center" colSpan={4}>
                {t("branch_rooms")}
              </StyledTableCellHeader>
            </TableRow>
            <TableRow>
              <StyledTableCellHeader>{t("room_number")}</StyledTableCellHeader>
              <StyledTableCellHeader>{t("room_name")}</StyledTableCellHeader>
              <StyledTableCellHeader>{t("room_teacher")}</StyledTableCellHeader>
              <StyledTableCellHeader>
                {t("is_lesson_room")}
              </StyledTableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.rooms.map((item, index) => {
              return (
                <TableRow key={item.id} hover role="checkbox" tabIndex={-1}>
                  <StyledTableCell>{index + 1}</StyledTableCell>
                  <StyledTableCell>{item.nickname}</StyledTableCell>
                  <StyledTableCell>
                    {item.responsible_staff_readable}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item.is_lesson_room ? t("yes") : t("no")}
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
