import { TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useFormik } from "formik";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
import * as Yup from "yup";
import { LoaderContext } from "../../../../Contexts/LoaderContext";
import { StudentsRegistrationContext } from "../../../../Contexts/RegistrationContex/StudentRegistrationContext";
import { toastError, toastSuccess } from "../../../../Helpers/toasts";
import StudentsRepository from "../../../../Repositories/Registration/StudentsRepository";
import { loadItems } from "../actions";

export default function ConnectionModal({ cancelCallback, showModal }) {
  const [loader, setLoader] = useState(false);
  const { students, dispatchStudents } = useContext(
    StudentsRegistrationContext
  );
  const { dispatchLoader } = useContext(LoaderContext);
  const { t } = useTranslation();

  let initialValues = {
    connection_status: null,
    comment: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: false,
    validationSchema: Yup.object({
      comment: Yup.string(),
      connection_status: Yup.number()
        .min(0, t("Aloqa turini kiriting!"))
        .required(t("Aloqa turini kiriting!")),
    }),
    onSubmit: (values) => {
      setLoader(true);
      updateItem(values);
    },
  });
  useEffect(() => {
    formik.resetForm();
  }, [showModal.connectId]);

  const updateItem = useCallback(async (data) => {
    const responseData = await StudentsRepository.updateStudent(
      data,
      showModal.connectId
    );
    setLoader(false);
    if (responseData.status === true) {
      toastSuccess(t("O'quvchi ma'lumotlari yangilandi"));
      loadItems({}, students, dispatchStudents, dispatchLoader);
      cancelCallback();
    } else {
      toastError(t("O'quvchi ma'lumotlari yangilandi"));
    }
    formik.setFieldValue("connection_status", null);
    formik.setFieldValue("comment", "");
    formik.resetForm();
  });

  return (
    <div
      id="modal"
      className={
        showModal.connectId ? "modal modal-select active" : "modal modal-select"
      }
    >
      <form
        onClick={(e) => e.stopPropagation()}
        className="modal-wrapper"
        style={{ width: "480px" }}
        onSubmit={formik.handleSubmit}
      >
        <div className="modal-inner orange_border">
          <h4 className="modal__title orange_background">
            {t("Aloqa natijasi")}
          </h4>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            style={{ alignItems: "center" }}
          >
            {students.connectionStatus?.map((element) => {
              return (
                <FormControlLabel
                  key={element[0]}
                  value={element[0]}
                  name="connection_status"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  control={<Radio sx={{ padding: "5px" }} />}
                  label={element[1]}
                  checked={formik.values.connection_status == element[0]}
                />
              );
            })}
          </RadioGroup>
          <TextField
            label={t("Izoh")}
            multiline
            rows={3}
            id="outlined-basic"
            variant="filled"
            name="comment"
            value={formik.values.comment || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
            sx={{ margin: "20px 0px" }}
          />
        </div>
        <div className="input-buttons">
          <button
            id="modalClose"
            type="button"
            className="input-btn"
            onClick={() => cancelCallback()}
          >
            {t("Bekor qilish")}
          </button>
          <button
            className="input-btn input-btn--submit orange_background"
            type="submit"
            disabled={loader || !formik.isValid}
          >
            {loader ? (
              <ReactLoading
                type={"spin"}
                color="#ffffff"
                className="delete_loader"
                height={"30px"}
                width={"30px"}
              />
            ) : (
              t("Saqlash")
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
