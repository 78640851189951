import DoneAllIcon from "@mui/icons-material/DoneAll";
import GroupIcon from "@mui/icons-material/Group";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { MainGroupsContext } from "../../../../Contexts/GroupsContext/MainGroupsContext";
import { useMainGroupStore } from "../../../../Store/ManageGroupsStore/MainGroupStore";
import UserPermissionWidget from "../../../../UserPermissionWidget";
import { openFilterList, toggleContextMenu } from "../actions";

const icon_connect = {
  fontSize: "19px",
  marginRight: "5px",
};

export default function ListItem({ group, index }) {
  const { mainGroups, dispatchMainGroups } = useContext(MainGroupsContext);
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user"));

  const permissionManageGroup =
    user?.permissions?.create_exam_request ||
    user?.permissions?.move_to_next_stage ||
    user?.permissions?.close_or_stop_group ||
    user?.permissions?.change_group_settings;

  return (
    <ul
      style={{
        background:
          group.status.value == "finished_successfully"
            ? "#dbeafe"
            : group.status.value == "closed_permanant"
            ? "#fee2e2"
            : group.status.value == "closed_temporary"
            ? "#fef9c3"
            : "#ffffff",
      }}
      className="groupnow-table build-table build-table--light build-table__item"
    >
      <li className="build-table__item">{index + 1}</li>
      <UserPermissionWidget
        hasPermission={user?.permissions?.view_group_attendance}
      >
        <li className="build-table__item">
          <Link to={`/groups/${group.id}/attendance/`}>{group?.number}</Link>
        </li>
      </UserPermissionWidget>
      {!user?.permissions?.view_group_attendance && <li>{group?.number}</li>}
      <li className="build-table__item">{group.main_teacher?.fullname}</li>
      <li className="build-table__item">
        {group.lesson_days} {group.time_slot}
      </li>
      <li style={{ color: group?.text_color }} className="build-table__item">
        {group?.lesson_style?.verbose_name}
      </li>
      <li className="build-table__item">{group?.age_group?.verbose_name}</li>
      <li className="build-table__item">{group?.lessons_per_month} ta dars</li>
      <li className="build-table__item">{group?.subject}</li>
      <li className="build-table__item">{group?.facility}</li>
      <li className="build-table__item">{group?.students_count} ta </li>
      <li
        className="build-table__item  build-table__item--7"
        onClick={() => toggleContextMenu(group, dispatchMainGroups)}
      >
        <ul
          className={
            mainGroups.openContextMenuId === group.id
              ? "build-table__dropdown build-table__dropdown--active staff_menu"
              : "build-table__dropdown"
          }
        >
          <UserPermissionWidget
            hasPermission={user?.permissions?.view_group_composition}
          >
            <li className="build-table__dropdown-item btn-edit">
              <Link to={`/group/${group.group_id}/assign-students`}>
                <button className="modalBtn">
                  <GroupIcon size="small" sx={icon_connect} />
                  {t("Guruh tarkibi")}
                </button>
              </Link>
            </li>
          </UserPermissionWidget>
          <UserPermissionWidget hasPermission={permissionManageGroup}>
            <li
              className={`build-table__dropdown-item btn-edit connection`}
              onClick={() =>
                openFilterList(group?.group_id, dispatchMainGroups)
              }
            >
              <button className="modalBtn">
                <ManageAccountsIcon sx={{ color: "#fbbf24" }} />
                <p>{t("Guruhni boshqarish")}</p>
              </button>
            </li>
          </UserPermissionWidget>
          <UserPermissionWidget hasPermission={permissionManageGroup}>
            <li
              onClick={() =>
                useMainGroupStore.setState({
                  continueId: group.group_id,
                })
              }
              className={`build-table__dropdown-item btn-edit back--group ${
                !group.status?.closed ? "d-none" : ""
              }`}
            >
              <button className="modalBtn">
                <PlayArrowIcon sx={{ color: "#009688" }} />
                <p>{t("Guruhni faollashtirish")}</p>
              </button>
            </li>
          </UserPermissionWidget>
          <UserPermissionWidget hasPermission={permissionManageGroup}>
            <li
              onClick={() =>
                useMainGroupStore.setState({
                  finishId: group?.group_id,
                })
              }
              className={`build-table__dropdown-item ${
                group.status?.finished_successfully ? "d-none" : ""
              }`}
            >
              <DoneAllIcon />
              <p>{t("Guruhni muvaffaqiyat yakunlash")}</p>
            </li>
          </UserPermissionWidget>
        </ul>
      </li>
    </ul>
  );
}
