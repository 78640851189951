import { useTranslation } from "react-i18next";
import { useWeeklyReportsStore } from "../../../../Store/ReportsStore/WeeklyReportsStore";
import TableHead from "./TableHead";

function Table() {
  const { results, totals, previous_month_balance, current_month_balance } =
    useWeeklyReportsStore();
  const { t } = useTranslation();

  return (
    <table border={"1px"} className="reports-table">
      <TableHead />
      <tbody>
        <tr>
          <td colSpan={9} style={{ textAlign: "center", fontSize: "18px" }}>
            {t("Avvalgi oydagi qoldiqlar")}
          </td>
          <td style={{ textAlign: "center", fontSize: "18px" }}>
            {previous_month_balance.cash_leftover}
          </td>
          <td style={{ textAlign: "center", fontSize: "18px" }}>
            {previous_month_balance.terminal_leftover}
          </td>
          <td style={{ textAlign: "center", fontSize: "18px" }}>
            {previous_month_balance.online_transaction_leftover}
          </td>
        </tr>
        {results?.map((item, index) => {
          return (
            <tr
              className={`reports-extra-table-body`}
              style={{
                opacity: !item.has_transactions && 0.3,
              }}
            >
              <td>{index + 1}</td>
              <td style={{ textAlign: "start" }}>{item.weekday}</td>
              <td>{item.date}</td>
              <td>{item?.income.cash} </td>
              <td>{item?.income.terminal} </td>
              <td>{item?.income.online_transaction} </td>
              <td>{item?.expenditure.cash} </td>
              <td>{item?.expenditure.terminal} </td>
              <td>{item?.expenditure.online_transaction} </td>
              <td>{item?.leftover.cash} </td>
              <td>{item?.leftover.terminal} </td>
              <td>{item?.leftover.online_transaction} </td>
            </tr>
          );
        })}
        <tr style={{ background: "orange" }}>
          <td style={{ textAlign: "center", fontSize: "20px" }}></td>
          <td style={{ textAlign: "center", fontSize: "20px" }}></td>
          <td style={{ textAlign: "center", fontSize: "20px" }}></td>
          <td style={{ textAlign: "center", fontSize: "20px" }}>
            {totals?.cash_in_total}
          </td>
          <td style={{ textAlign: "center", fontSize: "20px" }}>
            {totals?.terminal_in_total}
          </td>
          <td style={{ textAlign: "center", fontSize: "20px" }}>
            {totals?.online_transaction_in_total}
          </td>
          <td style={{ textAlign: "center", fontSize: "20px" }}>
            {totals?.cash_out_total}
          </td>
          <td style={{ textAlign: "center", fontSize: "20px" }}>
            {totals?.terminal_out_total}
          </td>
          <td style={{ textAlign: "center", fontSize: "20px" }}>
            {totals?.online_transaction_out_total}
          </td>
          <td style={{ textAlign: "center", fontSize: "20px" }}>
            {totals?.cash_left_total}
          </td>
          <td style={{ textAlign: "center", fontSize: "20px" }}>
            {totals?.terminal_left_total}
          </td>
          <td style={{ textAlign: "center", fontSize: "20px" }}>
            {totals?.online_transaction_left_total}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default Table;
