import React from "react";
import { useTranslation } from "react-i18next";
import { useSalaryAdvanceStore } from "../../../../../Store/CashControlStore/SalaryAdvanceStore";
import PaymentHistory from "./PaymentHistory";

export default function StaffInfo() {
  const { t } = useTranslation();
  const { details, data } = useSalaryAdvanceStore();

  return (
    <div className="transfer__item">
      <div className="transfer-info transfer-info_huge salary-advance__item">
        <h1 className="student_information">{t("Xodim ma'lumotlari")}</h1>
        <div className="transfer-info__item">
          <div className="transfer-infos">{t("F.I.Sh")}</div>
          <div className="transfer-infos">{details?.fullname}</div>
        </div>
        <div className="transfer-info__item">
          <div className="transfer-infos">{t("Xodim ID")}</div>
          <div className="transfer-infos">{details?.staff_id}</div>
        </div>
        <div className="transfer-info__item">
          <div className="transfer-infos">{t("Telefon raqami")}</div>
          <div className="transfer-infos">{details?.phone}</div>
        </div>
        <div className="transfer-info__item">
          <div className="transfer-infos">{t("Belgilangan ish haqi")}</div>
          <div className="transfer-infos">{details?.fixed_salary} so'm</div>
        </div>
        <div className="transfer-info__item">
          <div className="transfer-infos">{t("Yakuniy ish haqi")}</div>
          <div className="transfer-infos">{details?.final_salary} so'm</div>
        </div>
        {data?.history?.length > 0 && (
          <>
            <h1 className="student_information">{t("To'lovlar tarixi")}</h1>
            <PaymentHistory />
          </>
        )}
      </div>
    </div>
  );
}
