import React, { useContext, useState, useCallback } from "react";
import Logo from "../Logo";
import SideBarCloseBtn from "./SideBarCloseBtn";
import SideBarMenu from "./SideBarMenu";
import SideBarMenuItem from "./SideBarMenuItem";
import { SideBarContext } from "../../Contexts/SideBarContext";
import SideBarMenuSubItem from "./SideBarMenuSubItem";
import SideBarItem from "./SideBarItem";

export default function SideBar() {
  const [openMenuItem, setOpenMenuItem] = useState(null);
  const { sideBar } = useContext(SideBarContext);

  const toggleMenuItem = useCallback((title) => {
    if (openMenuItem === title) {
      setOpenMenuItem(null);
    } else {
      setOpenMenuItem(title);
    }
  });

  return (
    <aside
      className={sideBar.open === true ? "navbar navbar--visible" : "navbar"}
    >
      <Logo />
      <SideBarCloseBtn />
      <SideBarMenu>
        <SideBarItem
          title="Bosh sahifa"
          link="/"
          icon={
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 15.3333C4 16.4379 4.89543 17.3333 6 17.3333H12.6667C13.7712 17.3333 14.6667 16.4379 14.6667 15.3333V6C14.6667 4.89543 13.7712 4 12.6667 4H6C4.89543 4 4 4.89543 4 6V15.3333ZM4 26C4 27.1046 4.89543 28 6 28H12.6667C13.7712 28 14.6667 27.1046 14.6667 26V22C14.6667 20.8954 13.7712 20 12.6667 20H6C4.89543 20 4 20.8954 4 22V26ZM17.3333 26C17.3333 27.1046 18.2288 28 19.3333 28H26C27.1046 28 28 27.1046 28 26V16.6667C28 15.5621 27.1046 14.6667 26 14.6667H19.3333C18.2288 14.6667 17.3333 15.5621 17.3333 16.6667V26ZM19.3333 4C18.2288 4 17.3333 4.89543 17.3333 6V10C17.3333 11.1046 18.2288 12 19.3333 12H26C27.1046 12 28 11.1046 28 10V6C28 4.89543 27.1046 4 26 4H19.3333Z"
                fill="#637798"
              />
            </svg>
          }
        />
        <SideBarItem
          title="Qabulxonaga o'tish"
          link=""
          icon={
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.3337 21.3333H14.667C13.9337 21.3333 13.3337 20.7333 13.3337 20H4.01366V25.3333C4.01366 26.8 5.21366 28 6.68033 28H25.3337C26.8003 28 28.0003 26.8 28.0003 25.3333V20H18.667C18.667 20.7333 18.067 21.3333 17.3337 21.3333ZM26.667 9.33333H21.3337C21.3337 6.38667 18.947 4 16.0003 4C13.0537 4 10.667 6.38667 10.667 9.33333H5.33366C3.86699 9.33333 2.66699 10.5333 2.66699 12V16C2.66699 17.48 3.85366 18.6667 5.33366 18.6667H13.3337V17.3333C13.3337 16.6 13.9337 16 14.667 16H17.3337C18.067 16 18.667 16.6 18.667 17.3333V18.6667H26.667C28.1337 18.6667 29.3337 17.4667 29.3337 16V12C29.3337 10.5333 28.1337 9.33333 26.667 9.33333ZM13.3337 9.33333C13.3337 7.86667 14.5337 6.66667 16.0003 6.66667C17.467 6.66667 18.667 7.86667 18.667 9.33333H13.3203H13.3337Z"
                fill="#637798"
              />
            </svg>
          }
        />
        <SideBarMenuItem
          title="Guruhlar"
          onClick={() => toggleMenuItem("Guruhlar")}
          open={openMenuItem === "Guruhlar"}
          icon={
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.2227 17.5071C24.0493 18.7471 25.3293 20.4271 25.3293 22.6671V26.6671H30.6627V22.6671C30.6627 19.7604 25.9027 18.0404 22.2227 17.5071Z"
                fill="#637798"
              />
              <path
                d="M11.9974 15.9987C14.9429 15.9987 17.3307 13.6109 17.3307 10.6654C17.3307 7.71985 14.9429 5.33203 11.9974 5.33203C9.05188 5.33203 6.66406 7.71985 6.66406 10.6654C6.66406 13.6109 9.05188 15.9987 11.9974 15.9987Z"
                fill="#637798"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.996 15.9987C22.9427 15.9987 25.3293 13.612 25.3293 10.6654C25.3293 7.7187 22.9427 5.33203 19.996 5.33203C19.3693 5.33203 18.7827 5.46536 18.2227 5.65203C19.3293 7.02536 19.996 8.77203 19.996 10.6654C19.996 12.5587 19.3293 14.3054 18.2227 15.6787C18.7827 15.8654 19.3693 15.9987 19.996 15.9987Z"
                fill="#637798"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9987 17.332C8.4387 17.332 1.33203 19.1187 1.33203 22.6654V26.6654H22.6654V22.6654C22.6654 19.1187 15.5587 17.332 11.9987 17.332Z"
                fill="#637798"
              />
            </svg>
          }
        >
          <SideBarMenuSubItem
            title="Guruhlarni boshqarish"
            link="/group-management/"
          />
          <SideBarMenuSubItem
            title="Yangi guruhla tashkil etish"
            link="/groups/"
          />
          <SideBarMenuSubItem title="Sozlamalarni kiritish" link="/config/" />
        </SideBarMenuItem>
        <SideBarMenuItem
          title="Moliya"
          onClick={() => toggleMenuItem("Moliya")}
          open={openMenuItem === "Moliya"}
          icon={
            <svg
              width="30"
              height="32"
              viewBox="0 0 30 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.25 12.6667V16.6667C26.25 19.98 21.2125 22.6667 15 22.6667C8.7875 22.6667 3.75 19.98 3.75 16.6667V12.6667C3.75 15.98 8.7875 18.6667 15 18.6667C21.2125 18.6667 26.25 15.98 26.25 12.6667ZM3.75 19.3333C3.75 22.6467 8.7875 25.3333 15 25.3333C21.2125 25.3333 26.25 22.6467 26.25 19.3333V23.3333C26.25 26.6467 21.2125 29.3333 15 29.3333C8.7875 29.3333 3.75 26.6467 3.75 23.3333V19.3333ZM15 16C8.7875 16 3.75 13.3133 3.75 10C3.75 6.68667 8.7875 4 15 4C21.2125 4 26.25 6.68667 26.25 10C26.25 13.3133 21.2125 16 15 16Z"
                fill="#637798"
              />
            </svg>
          }
        >
          <SideBarMenuSubItem title="Hisobotlarni ko‘tarish" link="" />
          <SideBarMenuSubItem
            title="To'lov kassa"
            link="/cash-control/income-cash"
          />
          <SideBarMenuSubItem
            title="Chiqim kassa"
            link="/cash-control/expenditure-cash"
          />
          <SideBarMenuSubItem
            title="Kunlik kassa hisoboti"
            link="/cash-control/income-cash"
          />
          <SideBarMenuSubItem
            title="Oylik kassa hisoboti"
            link="/cash-control/income-cash"
          />
        </SideBarMenuItem>
        <SideBarMenuItem
          title="Sozlamalar"
          onClick={() => toggleMenuItem("Sozlamalar")}
          open={openMenuItem === "Sozlamalar"}
          icon={
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25.5215 17.2535C25.5748 16.8535 25.6015 16.4402 25.6015 16.0002C25.6015 15.5735 25.5748 15.1469 25.5081 14.7469L28.2148 12.6402C28.4548 12.4535 28.5215 12.0935 28.3748 11.8269L25.8148 7.4002C25.6548 7.10686 25.3215 7.01353 25.0281 7.10686L21.8414 8.38686C21.1748 7.88019 20.4681 7.45353 19.6814 7.13353L19.2014 3.74686C19.1481 3.42686 18.8814 3.2002 18.5614 3.2002H13.4414C13.1214 3.2002 12.8681 3.42686 12.8148 3.74686L12.3348 7.13353C11.5481 7.45353 10.8281 7.89353 10.1748 8.38686L6.98811 7.10686C6.69478 7.00019 6.36145 7.10686 6.20145 7.4002L3.65478 11.8269C3.49478 12.1069 3.54811 12.4535 3.81478 12.6402L6.52145 14.7469C6.45478 15.1469 6.40145 15.5869 6.40145 16.0002C6.40145 16.4135 6.42811 16.8535 6.49478 17.2535L3.78811 19.3602C3.54811 19.5469 3.48145 19.9069 3.62812 20.1735L6.18811 24.6002C6.34811 24.8935 6.68145 24.9869 6.97478 24.8935L10.1614 23.6135C10.8281 24.1202 11.5348 24.5469 12.3214 24.8669L12.8014 28.2535C12.8681 28.5735 13.1214 28.8002 13.4414 28.8002H18.5614C18.8814 28.8002 19.1481 28.5735 19.1881 28.2535L19.6681 24.8669C20.4548 24.5469 21.1748 24.1202 21.8281 23.6135L25.0148 24.8935C25.3081 25.0002 25.6415 24.8935 25.8015 24.6002L28.3615 20.1735C28.5215 19.8802 28.4548 19.5469 28.2015 19.3602L25.5215 17.2535ZM16.0014 20.8002C13.3614 20.8002 11.2014 18.6402 11.2014 16.0002C11.2014 13.3602 13.3614 11.2002 16.0014 11.2002C18.6414 11.2002 20.8014 13.3602 20.8014 16.0002C20.8014 18.6402 18.6414 20.8002 16.0014 20.8002Z"
                fill="#637798"
              />
            </svg>
          }
        >
          <SideBarMenuSubItem
            title="Dars soatlarini kiritish"
            link="/config/lesson-hours/"
          />
          <SideBarMenuSubItem
            title="O‘qituvchi va xodimlar turini kiritish"
            link="/config/staff-types/"
          />
          <SideBarMenuSubItem
            title="Oylik badal to‘lov turini kiritish"
            link="/config/monthly-payments/"
          />
        </SideBarMenuItem>
        <SideBarItem
          title="Chiqish"
          link=""
          icon={
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.6667 10.6667L20.7867 12.5467L22.8933 14.6667H12V17.3333H22.8933L20.7867 19.44L22.6667 21.3333L28 16L22.6667 10.6667ZM6.66667 6.66667H16V4H6.66667C5.2 4 4 5.2 4 6.66667V25.3333C4 26.8 5.2 28 6.66667 28H16V25.3333H6.66667V6.66667Z"
                fill="#637798"
              />
            </svg>
          }
        />
      </SideBarMenu>
    </aside>
  );
}
