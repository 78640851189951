import { create } from "zustand";
import { toastError } from "../../Helpers/toasts";
import MainGroupsRepository from "../../Repositories/Groups/MainGroupsRepository";

export const useGroupAttendanceDivisionStore = create((set, get) => ({
  age_groups: null,
  months: null,
  total_counts: null,
  branches: null,
  years: null,
  queryParams: {},
  updateParams: (item) => {
    set((prevState) => ({
      queryParams: { ...prevState.queryParams, ...item },
    }));
  },
  getBranches: async () => {
    const { data, status, nonFieldErrors } =
      await MainGroupsRepository.getAllBranches();
    if (status) {
      set({
        branches: data,
      });
    } else toastError(nonFieldErrors);
  },
  resetData: () => {
    set({
      queryParams: {},
    });
  },
}));
