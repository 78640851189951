import LanguageActions from "../Actions/ComponentsActions/LanguageActions";

export const languageReducer = (state, action) => {
  switch (action.type) {
    case LanguageActions.LOADED:
      localStorage.setItem("language", JSON.stringify(action.payload));
      return action.payload;
    case LanguageActions.ISOPEN:
      return { ...state, isOpen: action.payload };
    case LanguageActions.IS_OPEN_PROFILE:
      return { ...state, isOpenProfile: action.payload };

    case LanguageActions.CHANGE:
      const other = state.other.filter((item) => {
        return item !== action.payload.item;
      });

      if (other.includes(state.current) === false) other.push(state.current);

      const newState = {
        current: action.payload.item,
        other: other,
      };
      localStorage.setItem("language", JSON.stringify(newState));
      return newState;
    default:
      return state;
  }
};
