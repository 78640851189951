import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AdminLayout from "../../../AdminLayout";
import EmptyContent from "../../../Components/Empty-content/EmptyContent";
import ItemsLoader from "../../../Components/Loader/basic loader/ItemsLoader";
import TitleBar from "../../../Components/TitleBar";
import { useRoomAvailabilityStore } from "../../../Store/GroupManagement/RoomAvailabilityStore";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import { onReload } from "./actions";
import ListItem from "./components/ListItem";
import Selector from "./components/Select";
import TableHeading from "./components/TableHeading";

export default function RoomsAvailability() {
  const { setLoader } = useLoaderStore();
  const { data, loader } = useRoomAvailabilityStore();

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    setLoader(true);
    await onReload(
      new URLSearchParams({
        lesson_style: 1,
      })
    );
    setLoader(false);
  };

  const { t } = useTranslation();

  return (
    <AdminLayout>
      <TitleBar
        title={t("Bino va xonalarning bandlik jadvali")}
        createAction={false}
        groupBtns={<Selector />}
      />
      <div className="container">
        {loader ? (
          <ItemsLoader size={70} />
        ) : data?.availability?.length > 0 ? (
          <div className="buildingJournal">
            <TableHeading />
            {data?.availability?.map((item, index) => {
              return <ListItem key={index} branch={item} />;
            })}
          </div>
        ) : (
          <EmptyContent />
        )}
      </div>
    </AdminLayout>
  );
}
