import { Box } from "@mui/material";
import { useMemo } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useReadyReportStore } from "../../../../Store/ReportsStore/ReadyReportStore";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "37px",
    minHeight: "37px",
    width: "200px",
    fontSize: "13px",
    fontFamily: "Inter",
    fontWeight: "400",
  }),
};

export default function BasicSelect() {
  const {
    filterset,
    updateParams,
    branches,
    queryParams,
    loadItems,
    setLoading,
  } = useReadyReportStore();

  const options = useMemo(() => {
    return (
      filterset?.date_ranges?.map((element) => ({
        label: element.name,
        value: element.value,
      })) || []
    );
  }, [filterset]);

  const optionsBranches = useMemo(() => {
    return (
      branches?.map((element) => ({
        label: element.title,
        value: element.id,
      })) || []
    );
  }, [branches]);

  const handleSelectBranch = useCallback(async (e) => {
    setLoading(true);
    updateParams({
      branch: e?.value == undefined ? "" : e?.value,
    });
    await loadItems(
      new URLSearchParams({
        ...queryParams,
        branch: e?.value == undefined ? "" : e?.value,
      }).toString()
    );
    setLoading(false);
  });
  const handleSelectChange = useCallback(async (e) => {
    setLoading(true);
    updateParams({
      end_date: e?.value.end_date == undefined ? "" : e?.value.end_date,
      start_date: e?.value.start_date == undefined ? "" : e?.value.start_date,
      type: e?.value?.type == undefined ? "" : e?.value?.type,
    });
    useReadyReportStore.setState({
      startDate: e?.value?.start_date ? new Date(e?.value?.start_date) : null,
      endDate: e?.value?.end_date ? new Date(e?.value?.end_date) : null,
    });
    await loadItems(
      new URLSearchParams({
        ...queryParams,
        end_date: e?.value.end_date == undefined ? "" : e?.value.end_date,
        start_date: e?.value.start_date == undefined ? "" : e?.value.start_date,
        type: e?.value?.type == undefined ? "" : e?.value?.type,
      }).toString()
    );
    setLoading(false);
  });

  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", gap: "15px" }}>
      <Select
        placeholder={t("Filiallar")}
        styles={customStyles}
        options={optionsBranches}
        isClearable
        onChange={handleSelectBranch}
      />
      <Select
        placeholder={t("Hisobotlar")}
        styles={customStyles}
        options={options}
        isClearable
        onChange={handleSelectChange}
      />
    </Box>
  );
}
