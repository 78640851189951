import { useFormik } from "formik";
import { useCallback, useContext } from "react";
import CategoriesBlock from "./CategoriesBlock";
import CommonHeading from "../../../../Components/CommonHeading";
import FilterBlock from "./FilterBlock";
import Searchbox from "../../../../Components/Searchbox";
import { loadItems } from "../actions";
import TableHead from "./TableHead";
import { EmployeeRegistrationContext } from "../../../../Contexts/RegistrationContex/EmployeeRegistrationContext";
import EmployeeRegistrationAction from "../../../../Actions/RegistrationActions/EmployeeRegistrationAction";
import { LoaderContext } from "../../../../Contexts/LoaderContext";
import InfoModule from "../../../../Components/InfoModule";
import ExportExcel from "../../../../Components/ExportExcel";
import Selector from "./Select";
import { Link } from "react-router-dom";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function Heading() {
  const { employee, dispatchEmployee } = useContext(
    EmployeeRegistrationContext
  );
  const { dispatchLoader } = useContext(LoaderContext);

  const searchAction = useCallback(async (payload) => {
    dispatchEmployee({
      type: EmployeeRegistrationAction.ADD_QUERY_PARAM,
      payload: { search_query: payload.term, page: 1 },
    });
    await loadItems(
      { search_query: payload.term, page: 1 },
      employee,
      dispatchEmployee,
      dispatchLoader
    );
  });

  const formik = useFormik({
    initialValues: {
      term: "",
    },
    onSubmit: (values) => {
      searchAction({
        term: values.term,
      });
    },
  });

  const queryParams = new URLSearchParams({
    ...employee.queryParams,
    download: "excel",
  }).toString();

  const optionsRoles = useMemo(() => {
    return (
      employee.staffType?.map((element) => ({
        label: element.name,
        value: element.code_name,
      })) || []
    );
  }, [employee.staffType]);

  const optionsBranch = useMemo(() => {
    return (
      employee.branches?.map((element) => ({
        label: element.title,
        value: element.id,
      })) || []
    );
  }, [employee.branches]);

  const { t } = useTranslation();

  return (
    <CommonHeading
      topItems={
        <>
          <Searchbox formik={formik} />
          <FilterBlock />
          <Selector options={optionsRoles} property={t("Lavozimlar")} />
          <Selector options={optionsBranch} property={t("Filiallar")} />
          <InfoModule title={employee.staff_count || 0} />
        </>
      }
      rightItems={
        <>
          <CategoriesBlock />
          <Link
            to={
              `https://admin.smartportal.uz/api/register/staff/?` + queryParams
            }
            download
            target={"_blank"}
          >
            <ExportExcel />
          </Link>
        </>
      }
      tableHead={<TableHead />}
      extraClass={`build-search_teacher`}
    />
  );
}
