import React from "react";

function SideBarMenuItem({ title, children, onClick, open,icon }) {
  return (
    <li className="menu__item menu-group">
      <button
        className={
          open
            ? "menu__link menu-link--dropdown active"
            : "menu__link menu-link--dropdown"
        }
        style={{ width: "95%" }}
        onClick={onClick}
      >
        {icon}
        {title}
      </button>
      <ul className={open ? "menu-dropdown menu-group__item" : "menu-dropdown"}>
        {children}
      </ul>
    </li>
  );
}

export default SideBarMenuItem;
