import { axiosInterceptor } from "../AxiosInterceptor";

export default class TaskBooksRepository {
  static async getMaterials() {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        "/materials/subjects/"
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch {
      result = { ...result, nonFieldErrors: "Ma'lumot olib bo'lmadi" };
    }
    return result;
  }
  static async getAgeGroup() {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        "/materials/lessons/age_groups/"
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch {
      result = { ...result, nonFieldErrors: "Ma'lumot olib bo'lmadi" };
    }
    return result;
  }
  static async getLessonsMonths() {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        "/materials/lessons/months/"
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch {
      result = { ...result, nonFieldErrors: "Ma'lumot olib bo'lmadi" };
    }
    return result;
  }
  static async getSubject(queryString) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        "/materials/lessons/?" + queryString
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch {
      result = { ...result, nonFieldErrors: "Ma'lumot olib bo'lmadi" };
    }
    return result;
  }
}
