import { useContext } from "react";
import { useCallback } from "react";
import Select from "react-select";
import StudentAttachmentAction from "../../../Actions/StudentAttachment/StudentAttachmentAction";
import { StudentAttachmentContext } from "../../../Contexts/StudentAttachmentContext/StudentAttachmentContext";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "37px",
    minHeight: "37px",
    width: "250px",
    fontSize: "15px",
    fontFamily: "Inter",
    fontWeight: "400",
  }),
};

export default function Selector({ param, property, value }) {
  const { dispatchStudentAttachment } = useContext(StudentAttachmentContext);

  const handleSelectChange = useCallback(async (e) => {
    if (property === "Fanlar") {
      dispatchStudentAttachment({
        type: StudentAttachmentAction.ADD_QUERY_PARAM,
        payload: { page: 1, subject: e?.value == undefined ? "" : e?.value },
      });
    }
    if (property === "Guruh turlari") {
      dispatchStudentAttachment({
        type: StudentAttachmentAction.ADD_QUERY_PARAM,
        payload: { page: 1, group_type: e?.value == undefined ? "" : e?.value },
      });
    }
    if (property === "Dars kunlari") {
      dispatchStudentAttachment({
        type: StudentAttachmentAction.ADD_QUERY_PARAM,
        payload: {
          page: 1,
          suitable_days: e?.value == undefined ? "" : e?.value,
        },
      });
    }
    if (property === "Dars vaqtlari") {
      dispatchStudentAttachment({
        type: StudentAttachmentAction.ADD_QUERY_PARAM,
        payload: {
          page: 1,
          suitable_time: e?.value == undefined ? "" : e?.value,
        },
      });
    }
    if (property === "Binolar") {
      dispatchStudentAttachment({
        type: StudentAttachmentAction.ADD_QUERY_PARAM,
        payload: {
          page: 1,
          studying_place: e?.value == undefined ? "" : e?.value,
        },
      });
    }
    if (property === "Xodimlar") {
      dispatchStudentAttachment({
        type: StudentAttachmentAction.ADD_QUERY_PARAM,
        payload: {
          page: 1,
          wanted_teacher: e?.value == undefined ? "" : e?.value,
        },
      });
    }
  });

  return (
    <Select
      // className="students-select"
      classNamePrefix="select"
      placeholder={property}
      styles={customStyles}
      options={param}
      isClearable
      value={value}
      onChange={handleSelectChange}
    />
  );
}
