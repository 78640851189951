import React from "react";
import { useTaskBookStore } from "../../../../Store/TaskBookStore/TaskBookStore";

function FileModal() {
  const { selectedFile } = useTaskBookStore();

  const cancelHandler = () => {
    useTaskBookStore.setState({
      selectedFile: null,
    });
  };

  return (
    <div
      id="modal"
      className={
        selectedFile ? "modal modal-select active" : "modal modal-select"
      }
    >
      <div className="attendanceDiscountModal">
        <h1 className="discountTitle">Dars yuklanmalar</h1>
        <div className="textbook-modal-list">
          {selectedFile?.map((item, index) => (
            <ul key={item.id}>
              <span>{index + 1}</span>
              <li>
                <a href={item.file} target="_blank">
                  {item.file}
                </a>
              </li>
              <li>
                <a href={item.url} target="_blank">
                  {item.url}
                </a>
              </li>
            </ul>
          ))}
        </div>
        <div className="discountBtns">
          <button type="button" className="cancelBtn" onClick={cancelHandler}>
            Bekor qilish
          </button>
        </div>
      </div>
    </div>
  );
}
export default React.memo(FileModal);
