import Select from "react-select";
import { useExamScheduleStore } from "../../../../Store/ManageGroupsStore/ExamScheduleStore";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "37px",
    minHeight: "37px",
    width: "100%",
    fontSize: "13px",
    fontFamily: "Inter",
    fontWeight: "400",
  }),
};

export default function MonthSelector({ param, property, value }) {
  const { updateParams, setLoading } = useExamScheduleStore();

  const handleSelectChange = async (e) => {
    setLoading(true);
    if (property == "Oylar") {
      updateParams({ month: e?.value === undefined ? "" : e?.value });
    }
    if (property == "Fanlar") {
      updateParams({ subject: e?.value === undefined ? "" : e?.value });
    }
    if (property == "O'qituvchilar") {
      updateParams({ teacher: e?.value === undefined ? "" : e?.value });
    }
    if (property == "Filiallar") {
      updateParams({ branch: e?.value === undefined ? "" : e?.value });
    }
    if (property == "Guruh turlari") {
      updateParams({ age_group: e?.value === undefined ? "" : e?.value });
    }
    setLoading(false);
  };

  return (
    <Select
      className="students-select"
      classNamePrefix="select"
      placeholder={property}
      styles={customStyles}
      options={param}
      isClearable
      value={value}
      onChange={handleSelectChange}
    />
  );
}
