import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AdminLayout from "../../../AdminLayout";
import TitleBar from "../../../Components/TitleBar";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import { useExamDetailsStore } from "../../../Store/ManageGroupsStore/ExamDetailsStore";
import UserPermissionWidget from "../../../UserPermissionWidget";
import { onReload } from "./actions";
import DetailBtns from "./components/DetailBtns";
import ListItem from "./components/ListItem";
import SubmitSection from "./components/SubmitSection";
import TableHeading from "./components/TableHeading";

export default function ExamDetails() {
  const { students } = useExamDetailsStore();
  const { setLoader } = useLoaderStore();

  const params = useParams();
  const { groupId, lessonMonth } = params;

  const pageReload = useCallback(async () => {
    setLoader(true);
    await onReload(groupId, lessonMonth);
    setLoader(false);
  }, [groupId]);

  useEffect(() => {
    useExamDetailsStore.setState({
      data: null,
    });
    pageReload();
  }, [groupId]);

  const user = JSON.parse(localStorage.getItem("user"));

  const { t } = useTranslation();

  return (
    <AdminLayout>
      <TitleBar
        title={t("Imtixon natijalarini kiritish")}
        createAction={false}
      />
      <div className="container">
        <div className="journal detail_journal">
          <DetailBtns />
          <div className="desk">
            <TableHeading />
            {students &&
              students?.map((student) => (
                <ListItem student={student} key={student.id} />
              ))}
          </div>
          <UserPermissionWidget
            hasPermission={user?.permissions?.save_exam_updates}
          >
            <SubmitSection />
          </UserPermissionWidget>
        </div>
      </div>
    </AdminLayout>
  );
}
