import { axiosInterceptor } from "../AxiosInterceptor";

export default class DashboardRepository {
    static async getDailyFinance() {
        let result = {
            status: false,
            data: null,
        };
        try {
            const response = await axiosInterceptor.get(
                "/reports/daily-finance/?bypass_format=true"
            );
            if (response.status === 200) {
                result = { ...result, status: true, data: response.data };
            }
        } catch {
            result = { ...result, nonFieldErrors: "Ma'lumot olib bo'lmadi" };
        }
        return result;
    }
    static async getDashboardReports() {
        let result = {
            status: false,
            data: null,
        };
        try {
            const response = await axiosInterceptor.get(
                "/reports/dashboard-reports/"
            );
            if (response.status === 200) {
                result = { ...result, status: true, data: response.data };
            }
        } catch {
            result = { ...result, nonFieldErrors: "Ma'lumot olib bo'lmadi" };
        }
        return result;
    }
}
