import { useTranslation } from "react-i18next";
import { useExamDetailsStore } from "../../../../Store/ManageGroupsStore/ExamDetailsStore";
import ExaminerSelect from "./ExaminerSelect";

export default function TableHeading() {
  const { exam_types, setExamTypes } = useExamDetailsStore();
  const { t } = useTranslation();

  return (
    <div className="desk-config exam_details">
      <div className="desk-config__item">№</div>
      <div className="desk-config__item">{t("Ismi va familyasi")}</div>
      <div className="desk-config__item">{t("Raqami")}</div>
      <div className="card_types">
        {exam_types?.map((element) => {
          return (
            <div
              key={element.code_name}
              className="desk-config__item score_field exam_details_heading"
            >
              <div className="exam_max_score">
                <input
                  type="number"
                  className="max_score_field"
                  name={element.code_name}
                  defaultValue={element?.max_score || 0}
                  onChange={(e) =>
                    setExamTypes(e, element.code_name, "max_score")
                  }
                />
                <p>{element.name}</p>
              </div>
              <div className="examiners">
                <ExaminerSelect
                  props={element.code_name}
                  examiner={element.examiner}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
