import { useExpenditureReportsStore } from "../../../../Store/ReportsStore/ExpenditureReportsStore";
import ExpenditureSelect from "./Select";

function Heading() {
  const { years, months } = useExpenditureReportsStore();
  return (
    <div
      style={{
        marginBottom: "10px",
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        width: "fit-content",
        gap: "20px",
      }}
    >
      <ExpenditureSelect param={years} property="yillar" />
      <ExpenditureSelect param={months} property="oylar" />
    </div>
  );
}

export default Heading;
