import React from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useStudentAcquisitionStore } from "../../../../Store/GroupManagement/StudentAcquisitionStore";
import { useLoaderStore } from "../../../../Store/Loader/LoaderStore";
import { loadItems } from "../actions";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "37px",
    minHeight: "37px",
    width: "100%",
    minWidth: "150px",
    fontSize: "13px",
    fontFamily: "Inter",
    fontWeight: "400",
  }),
};

function MonthSelector({ param, property }) {
  const { queryMonth, queryYear } = useStudentAcquisitionStore();
  const { setLoader } = useLoaderStore();
  const { t } = useTranslation();

  const options =
    param?.map((element) => ({
      label: element.verbose_name,
      value: property == t("Oylar") ? element.month : element.year,
    })) || [];

  const handleSelectChange = useCallback(async (e) => {
    setLoader(true);
    if (property === t("Oylar")) {
      useStudentAcquisitionStore.setState({
        queryMonth: e.value,
      });
      const string = { month: e.value };
      const stringWithYear = { month: e.value, year: queryYear };
      await loadItems(
        new URLSearchParams(!queryYear ? string : stringWithYear).toString()
      );
    }
    if (property === t("Yillar")) {
      useStudentAcquisitionStore.setState({
        queryYear: e.value,
      });
      const string = { year: e.value };
      const stringWithMonth = { year: e.value, month: queryMonth };
      await loadItems(
        new URLSearchParams(!queryMonth ? string : stringWithMonth).toString()
      );
    }
    setLoader(false);
  });

  return (
    <div className="normal-select-bar">
      <Select
        className="basic-single manage_group_select"
        classNamePrefix="select"
        placeholder={property}
        styles={customStyles}
        options={options}
        onChange={handleSelectChange}
      />
    </div>
  );
}
export default React.memo(MonthSelector);
