import { useContext, useEffect } from "react";
import LoaderActions from "../../Actions/ComponentsActions/LoaderActions";
import AdminLayout from "../../AdminLayout";
import { LoaderContext } from "../../Contexts/LoaderContext";
import { useLoaderStore } from "../../Store/Loader/LoaderStore";
import CashControl from "./CashControl";
import ConfigModule from "./ConfigModule";
import GroupModule from "./GroupModule";
import GroupsStatistics from "./GroupsStatistics";
import RegistrationModule from "./RegistrationModule";
import Reports from "./Reports";
import Trello from "./Trello";

export default function Home() {
  const { dispatchLoader } = useContext(LoaderContext);
  const { setLoader } = useLoaderStore();

  useEffect(() => {
    setLoader(false);
    dispatchLoader({ type: LoaderActions.PAGE_LOADED });
  }, []);

  const user = JSON.parse(localStorage.getItem("user"));

  const config =
    user?.permissions?.view_branches ||
    user?.permissions?.view_course_templates;

  const registration =
    user?.permissions?.view_staffs || user?.permissions?.view_students;

  const groups =
    user?.permissions?.view_groups ||
    user?.permissions?.students_group_changes ||
    user?.permissions?.move_student_to_other_group ||
    user?.permissions?.move_student_to_other_group;

  const groupsStatistics =
    user?.permissions?.view_group_ordinal_months ||
    user?.permissions?.view_attendance_age_group ||
    user?.permissions?.view_acquisition_lesson_months ||
    user?.permissions?.view_rooms_availability ||
    user?.permissions?.view_teachers__availability ||
    user?.permissions?.view_acquisition_age_group;

  const cashControl =
    user?.permissions?.view_student_payment_section ||
    user?.permissions?.view_accepted_payments_view ||
    user?.permissions?.view_expenditures_page ||
    user?.permissions?.view_expenditures_list ||
    user?.permissions?.view_payrolls_list ||
    user?.permissions?.view_calculated_monthly_salary_list ||
    user?.permissions?.view_debting_students_list;
  const reportControl =
    user?.permissions?.view_recipient_reports ||
    user?.permissions?.view_discounted_recipients ||
    user?.permissions?.view_removed_from_group ||
    user?.permissions?.view_missed_classes_students ||
    user?.permissions?.view_active_inactive_students ||
    user?.permissions?.view_students_failed_exams ||
    user?.permissions?.view_income_reports ||
    user?.permissions?.view_staff_statistics ||
    user?.permissions?.view_age_report_groups ||
    user?.permissions?.view_completed_course_students ||
    user?.permissions?.view_monthly_income_reports ||
    user?.permissions?.view_daily_income_reports ||
    user?.permissions?.view_weekly_income_reports ||
    user?.permissions?.view_weekly_income_reports;

  return (
    <AdminLayout>
      <section className="section-page home-page-section">
        <div
          className={`section-page-1 ${
            config || registration || groups ? "" : "d-none"
          }`}
        >
          <ConfigModule viewModule={config} />
          <RegistrationModule viewModule={registration} />
          <GroupModule viewModule={groups} />
        </div>
        <GroupsStatistics viewModule={groupsStatistics} />
        <CashControl viewModule={cashControl} />
        <Reports viewModule={reportControl} />
        <Trello />
      </section>
    </AdminLayout>
  );
}
