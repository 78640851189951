import { create } from "zustand";
import { toastError, toastSuccess } from "../../Helpers/toasts";
import TrelloRepository from "../../Repositories/Trello/Trellorepository";

export const useTrelloStore = create((set, get) => ({
  lanes: null,
  staffs_list: null,
  backlog: null,
  priorities_list: null,
  isOpenModal: false,
  cardDetails: null,
  updateId: null,
  staff_ids: [],
  setLoading: (status) => {
    set({
      isLoading: status,
    });
  },

  onReload: async () => {
    const { data, status, nonFieldErrors } = await TrelloRepository.getTasks();
    if (status) {
      set({
        lanes: data.lanes,
        priorities_list: data.priorities_list,
        staffs_list: data.staffs_list,
        backlog: data.backlog,
        staffs_filter_list: data.staffs_filter_list,
        staff_ids: [],
      });
    } else toastError(nonFieldErrors);
  },
  getCardDetails: async (cardId) => {
    const { data, status, nonFieldErrors } =
      await TrelloRepository.getCardDetails(cardId);
    if (status) {
      set({
        isOpenModal: true,
        cardDetails: data,
      });
    } else toastError(nonFieldErrors);
  },

  loadItems: async (queryString) => {
    const { data, status, nonFieldErrors } = await TrelloRepository.getTasks(
      queryString
    );
    if (status) {
      set({
        lanes: data.lanes,
        backlog: data.backlog,
        staffs_filter_list: data.staffs_filter_list,
      });
    } else toastError(nonFieldErrors);
  },

}));
