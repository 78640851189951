import React from "react";

export default function SubInfo(group) {
  return (
    <div className="subject-line__item">
      {group?.data.name} {" - "}
      {group?.data.total_students} nafar
    </div>
  );
}
