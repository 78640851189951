import { useFormik } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import ReactLoading from "react-loading";
import * as Yup from "yup";
import StudentsRegistrationActions from "../../../../Actions/RegistrationActions/StudentsRegistrationActions";
import OptionLoader from "../../../../Components/Loader/basic loader/OptionLoader";
import { LoaderContext } from "../../../../Contexts/LoaderContext";
import { StudentsRegistrationContext } from "../../../../Contexts/RegistrationContex/StudentRegistrationContext";
import { toastError, toastSuccess } from "../../../../Helpers/toasts";
import StudentsRepository from "../../../../Repositories/Registration/StudentsRepository";
import { getEmptyStaffs, loadItems } from "../actions";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useTranslation } from "react-i18next";

export default function CreateUpdateModal({ days }) {
  const animatedComponents = makeAnimated();
  const { students, dispatchStudents } = useContext(
    StudentsRegistrationContext
  );
  const { t } = useTranslation();
  const { loader, dispatchLoader } = useContext(LoaderContext);
  const [saveLoader, setSaveLoader] = useState(false);

  const FORMIK_RULES = {
    first_name: Yup.string()
      .min(0, t("O'quvchining ismini kiriting!"))
      .max(50, t("Belgilar soni ortiqcha!"))
      .required(t("O'quvchining ismini kiriting!")),
    last_name: Yup.string()
      .min(0, t("Xodimni familiyasini kiriting!"))
      .max(50, t("Belgilar soni ortiqcha!"))
      .required(t("Xodimni familiyasini kiriting!")),
    middle_name: Yup.string().max(50, t("Belgilar soni ortiqcha!")),
    gender: Yup.number()
      .min(0, t("Xodim jinsini tanlang!"))
      .required(t("Xodim jinsini tanlang!")),
    birth_date: Yup.string()
      .min(0, t("Tug'ilgan sanasini kiriting !"))
      .required(t("Tug'ilgan sanasini kiriting !")),
    document_type: Yup.string(),
    document_series: Yup.string().max(10, t("Belgilar soni ortiqcha!")),
    document_number: Yup.string()
      .min(0, t("Passport raqamini kiriting!"))
      .max(7, t("Belgilar soni ortiqcha!")),
    studying_place: Yup.string()
      .min(0, t("Bino turini tanlang!"))
      .required(t("Bino turini tanlang!")),
    group_type: Yup.string()
      .min(1, t("Guruh turini tanlang"))
      .required(t("Guruh turini tanlang")),
    day: Yup.object().shape({
      label: Yup.string().required(t("Dars kunini tanlang")),
      value: Yup.string().required(t("Dars kunini tanlang")),
    }),
    subject: Yup.string()
      .min(1, t("O'tiladigan fanni tanlang"))
      .required(t("O'tiladigan fanni tanlang")),
    utm: Yup.string().max(255, t("Belgilar soni ortiqcha!")),
    school: Yup.string().max(255, t("Belgilar soni ortiqcha!")),
    state: Yup.string().max(255, t("Belgilar soni ortiqcha!")),
    wanted_teacher: Yup.string(),
    suitable_time: Yup.string()
      .min(1, t("Dars vaqtini tanlang"))
      .required(t("Dars vaqtini tanlang")),
    password: Yup.string()
      .min(8, t("Kamida 8 ta belgidan iborat parol kiriting!"))
      .required(t("Xodim uchun prol kiriting!")),
    address: Yup.string()
      .min(0, t("O'quvchining yashash manzilini kiriting!"))
      .max(255, t("Belgilar soni ortiqcha!"))
      .required(t("O'quvchining yashash manzilini kiriting!")),
    level: Yup.number(),
    phone_number: Yup.string()
      .min(13, t("Raqamini to'liq kiriting!"))
      .max(13, t("Belgilar soni ortiqcha!"))
      .required(t("O'quvchining  telefon raqamini kiriting!")),
    how_much_studied: Yup.number(),
    studied_before: Yup.string(),
  };

  const [formikRules, setFormikRules] = useState(FORMIK_RULES);

  useEffect(() => {
    formik.resetForm();
  }, [students.isModalOpen]);

  useEffect(() => {
    if (students.item === null) {
      setFormikRules(FORMIK_RULES);
    } else {
      setFormikRules({ ...FORMIK_RULES, username: null, password: null });
      for (const [key, value] of Object.entries(students.item ?? {})) {
        if (key === "wanted_teacher") {
          formik.setFieldValue(key, value?.id);
        } else if (key === "day") {
          formik.setFieldValue(
            key,
            days?.find((element) => element.value == value)
          );
        } else if (key === "studying_place") {
          formik.setFieldValue(key, value.id);
        } else if (key === "subject") {
          formik.setFieldValue(key, value.id);
        } else if (key === "password") {
          formik.setFieldValue(key, null, false);
        } else if (key === "studied_before") {
          formik.setFieldValue(key, value === true ? "1" : "2", false);
        } else if (key === "registered_staff") {
          formik.setFieldValue(key, value?.id || null, false);
        } else if (key === "connection_status") {
          formik.setFieldValue(key, value == "" ? null : value, false);
        } else {
          formik.setFieldValue(key, value === null ? "" : value, false);
        }
      }
    }
  }, [students.isEditing]);

  const initialValues = {
    first_name: "",
    last_name: "",
    middle_name: "",
    gender: "",
    birth_date: "",
    document_type: "",
    document_series: "",
    document_number: "",
    studying_place: "",
    password: "",
    wanted_teacher: "",
    address: "",
    level: "",
    phone_number: "",
    group_type: "",
    subject: "",
    day: "",
    suitable_time: "",
    state: "",
    utm: "",
    school: "",
    studied_before: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object(formikRules),
    onSubmit: (values) => {
      setSaveLoader(true);
      const studied_before =
        formik.values.studied_before === "1" ? true : false;
      let data = {
        ...values,
        studied_before: studied_before,
        day: values.day?.value,
        level: !formik.values.level == "" ? formik.values.level : 0,
        connection_status: !formik.values.connection_status
          ? null
          : formik.values.connection_status,
      };
      if (data.document_type === "") {
        data = { ...data, document_type: null };
      }
      if (data.how_much_studied === "") {
        data = { ...data, how_much_studied: 0 };
      }
      if (students.editingId) {
        data = { ...data, password: null, username: null };
        updateItem(data, students.editingId);
      } else createItem(data);
    },
  });
  const updateItem = useCallback(async (data, id) => {
    const responseData = await StudentsRepository.updateStudent(data, id);
    setSaveLoader(false);
    if (responseData.status === true) {
      toastSuccess(t("O'quvchi ma'lumotlari yangilandi!"));
      await loadItems({}, students, dispatchStudents, dispatchLoader);
      cancelAction();
    } else {
      toastError(responseData.nonFieldErrors);
    }
  });

  const createItem = useCallback(async (data) => {
    const responseData = await StudentsRepository.addStudent(data);
    setSaveLoader(false);
    if (responseData.status === true) {
      toastSuccess(t("O'quvchi kiritildi"));
      await loadItems({}, students, dispatchStudents, dispatchLoader);
      cancelAction();
    } else {
      toastError(responseData.nonFieldErrors);
    }
  });

  const cancelAction = useCallback(() => {
    dispatchStudents({
      type: StudentsRegistrationActions.CANCEL_BUTTON_CLICKED,
    });
    formik.resetForm();
    setSaveLoader(false);
  });

  const handleChandeBranch = useCallback(async (e) => {
    formik.setFieldValue("wanted_teacher", "");
    formik.setFieldValue("studying_place", e.target.value);
    await getEmptyStaffs(e.target.value, dispatchStudents, dispatchLoader);
  });

  return (
    <div
      id="modal"
      className={
        students.isEditing || students.isCreating
          ? "modal modal-select active "
          : "modal modal-select"
      }
    >
      <form
        action="#"
        className="modal-wrapper modal-wrapper_huge modal-space student_modal relative"
        onSubmit={formik.handleSubmit}
      >
        <h4 className="modal__title get_above">
          {t("O‘quvchi va talabalarni ro‘yhatga olish")}
        </h4>
        <div className="modal-inner students_modal_scroll ">
          <div className="modal-select__item modal-four">
            <div className="modal-select__box relative">
              <label htmlFor="first_name">{t("Ismi")}</label>
              <input
                id="first_name"
                className={
                  formik.touched.first_name && formik.errors.first_name
                    ? "modal-input error_input"
                    : "modal-input "
                }
                value={formik.values.first_name || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                name="first_name"
              />
              {formik.touched.first_name && formik.errors.first_name ? (
                <p className="input-error-message ">
                  {formik.errors.first_name}
                </p>
              ) : null}
            </div>
            <div className="modal-select__box relative">
              <label className="course_label" htmlFor="subject">
                {t("O‘tiladigan fanlar nomi")}
              </label>
              <select
                id="subject"
                className={
                  formik.touched.subject && formik.errors.subject
                    ? "modal-input error_input"
                    : "modal-input"
                }
                name="subject"
                value={formik.values.subject}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option
                  className="course_disabled_option"
                  value=""
                  disabled
                  hidden
                ></option>
                {students.modalSubjects?.map((subject) => (
                  <option key={subject.id} value={subject.id}>
                    {subject.name}
                  </option>
                ))}
              </select>
              {formik.touched.subject && formik.errors.subject ? (
                <p className="input-error-message">{formik.errors.subject}</p>
              ) : null}
            </div>
            <div className="modal-select__box relative ">
              <label htmlFor="studying_place">{t("Bino va filial")}</label>
              <select
                onChange={(e) => handleChandeBranch(e)}
                onBlur={formik.handleBlur}
                value={formik.values.studying_place || ""}
                className={
                  formik.touched.studying_place && formik.errors.studying_place
                    ? "modal-input error_input"
                    : "modal-input "
                }
                id="studying_place"
                name="studying_place"
              >
                <option
                  className="course_disabled_option"
                  disabled
                  hidden
                  value={""}
                ></option>
                {students.branches?.map((element) => {
                  return (
                    <option key={element.id} value={element.id}>
                      {element.title}
                    </option>
                  );
                })}
              </select>
              {formik.touched.studying_place && formik.errors.studying_place ? (
                <p className="input-error-message ">
                  {formik.errors.studying_place}
                </p>
              ) : null}
            </div>
            <div className="modal-select__box relative">
              <label className="course_label" htmlFor="utm">
                {t("Biz haqimizda qayerdan eshitgan")}
              </label>
              <select
                id="utm"
                className={
                  formik.touched.utm && formik.errors.utm
                    ? "modal-input error_input"
                    : "modal-input"
                }
                name="utm"
                value={formik.values.utm}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option
                  className="course_disabled_option"
                  value=""
                  disabled
                  hidden
                ></option>
                <option value="Reklama orqali">{t("Reklama orqali")}</option>
                <option value="Telegramm orqali">
                  {t("Telegramm orqali")}
                </option>
              </select>
              {formik.touched.utm && formik.errors.utm ? (
                <p className="input-error-message">{formik.errors.utm}</p>
              ) : null}
            </div>
          </div>
          <div className="modal-select__item modal-four">
            <div className="modal-select__box relative">
              <label htmlFor="surname">{t("Familyasi")}</label>
              <input
                id="surname"
                className={
                  formik.touched.last_name && formik.errors.last_name
                    ? "modal-input error_input"
                    : "modal-input "
                }
                value={formik.values.last_name || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                name="last_name"
              />
              {formik.touched.last_name && formik.errors.last_name ? (
                <p className="input-error-message ">
                  {formik.errors.last_name}
                </p>
              ) : null}
            </div>
            <div className="modal-select__box relative ">
              <label>{t("Ushbu fanni oldin o'qiganmi?")}</label>
              <div
                className={
                  formik.touched.studied_before && formik.errors.studied_before
                    ? "radio checkBox_style error_input"
                    : "radio checkBox_style "
                }
              >
                <div className="student_modal_checkbox">
                  <input
                    type="radio"
                    name="studied_before"
                    id="true"
                    value={1}
                    onChange={formik.handleChange}
                    checked={formik.values.studied_before == 1}
                  />
                  <label htmlFor="true">{t("Ha")}</label>
                </div>
                <div className="student_modal_checkbox">
                  <input
                    type="radio"
                    name="studied_before"
                    id="false"
                    value={2}
                    onChange={formik.handleChange}
                    checked={formik.values.studied_before == 2}
                  />
                  <label htmlFor="false">Yo'q</label>
                </div>
              </div>
              {formik.touched.studied_before && formik.errors.studied_before ? (
                <p className="input-error-message ">
                  {formik.errors.studied_before}
                </p>
              ) : null}
            </div>
            <div className="modal-select__box relative ">
              {loader.isLoadingStaffsOption ? <OptionLoader /> : null}
              <label htmlFor="wanted_teacher">
                {t("O'qimoqchi bo'lgan o'qituvchi")}
              </label>
              <select
                id="wanted_teacher"
                name="wanted_teacher"
                disabled={!formik.values.studying_place ? true : false}
                onChange={(e) =>
                  formik.setFieldValue("wanted_teacher", e.target.value)
                }
                onBlur={formik.handleBlur}
                value={formik.values.wanted_teacher || ""}
                className={
                  formik.touched.wanted_teacher && formik.errors.wanted_teacher
                    ? "modal-input error_input"
                    : "modal-input "
                }
              >
                <option
                  className="course_disabled_option"
                  disabled
                  hidden
                  value={""}
                ></option>
                {students.filteredStaffs?.map((staff) => {
                  return (
                    <option key={staff.id} value={staff.id}>
                      {staff.fullname}
                    </option>
                  );
                })}
              </select>
              {formik.touched.wanted_teacher && formik.errors.wanted_teacher ? (
                <p className="input-error-message ">
                  {formik.errors.wanted_teacher}
                </p>
              ) : null}
            </div>
            <div className="modal-select__box relative">
              <label>{t("Shaxsni tasdiqlovchi hujjat turi")}</label>
              <div
                className={
                  formik.touched.document_type && formik.errors.document_type
                    ? "radio checkBox_style error_input"
                    : "radio checkBox_style "
                }
                style={{
                  gridTemplateColumns: "0.3fr 0.7fr 0.3fr 0.7fr 0.3fr 0.7fr",
                }}
              >
                <input
                  type="radio"
                  name="document_type"
                  id="pass"
                  value={1}
                  onChange={formik.handleChange}
                  checked={formik.values.document_type == 1}
                />
                <label htmlFor="pass">{t("Passport")}</label>
                <input
                  type="radio"
                  name="document_type"
                  id="id"
                  value={2}
                  onChange={formik.handleChange}
                  checked={formik.values.document_type == 2}
                />
                <label htmlFor="id">{t("ID-Card")}</label>
                <input
                  type="radio"
                  name="document_type"
                  id="metirka"
                  value={3}
                  onChange={formik.handleChange}
                  checked={formik.values.document_type == 3}
                />
                <label htmlFor="metirka">{t("Metirka")}</label>
              </div>
              {formik.touched.document_type && formik.errors.document_type ? (
                <p className="input-error-message ">
                  {formik.errors.document_type}
                </p>
              ) : null}
            </div>
          </div>
          <div className="modal-select__item modal-four ">
            <div className="modal-select__box relative">
              <label htmlFor="middle_name">{t("Otasining ismi")}</label>
              <input
                id="middle_name"
                className={
                  formik.touched.middle_name && formik.errors.middle_name
                    ? "modal-input error_input"
                    : "modal-input "
                }
                value={formik.values.middle_name || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                name="middle_name"
              />
              {formik.touched.middle_name && formik.errors.middle_name ? (
                <p className="input-error-message ">
                  {formik.errors.middle_name}
                </p>
              ) : null}
            </div>
            <div className="modal-select__box relative">
              <label htmlFor="how_much_studied">
                {t("Qancha vaqt o'qigan?")}
              </label>
              <input
                disabled={formik.values.studied_before !== "1" ? true : false}
                className={
                  formik.touched.how_much_studied &&
                  formik.errors.how_much_studied
                    ? "modal-input error_input"
                    : "modal-input "
                }
                value={formik.values.how_much_studied || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
                name="how_much_studied"
                id="how_much_studied"
              />
              {formik.touched.how_much_studied &&
              formik.errors.how_much_studied ? (
                <p className="input-error-message ">
                  {formik.errors.how_much_studied}
                </p>
              ) : null}
            </div>
            <div className="modal-select__box relative">
              <label htmlFor="group-room">{t("Ma'qul dars kuni")}</label>
              <Select
                id="vakolat"
                className={
                  formik.touched.day && formik.errors.day
                    ? "modal-input multiselect error_input"
                    : "modal-input multiselect"
                }
                closeMenuOnSelect={false}
                components={animatedComponents}
                options={days}
                name="day"
                value={formik.values.day}
                onChange={(option) => {
                  formik.setFieldValue("day", option);
                }}
                onBlur={() => {
                  formik.setTouched({ day: true });
                }}
              />
              {formik.touched.day && formik.errors.day ? (
                <p className="input-error-message ">
                  {formik.errors.day.label}
                </p>
              ) : null}
            </div>
            <div className="modal-select__box relative">
              <label htmlFor="passport">
                {t("Passport seriyasi va raqami")}
              </label>
              <div className="modal-passport">
                <div className="passport_error modal-select__box">
                  <input
                    id="passport"
                    name="document_series"
                    type="text"
                    className={
                      formik.touched.document_series &&
                      formik.errors.document_series
                        ? "modal-input error_input"
                        : "modal-input "
                    }
                    value={formik.values.document_series || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.document_series &&
                  formik.errors.document_series ? (
                    <p className="input-error-message ">
                      {formik.errors.document_series}
                    </p>
                  ) : null}
                </div>
                <div className="passport_error modal-select__box">
                  <input
                    id="passport"
                    name="document_number"
                    type="text"
                    className={
                      formik.touched.document_number &&
                      formik.errors.document_number
                        ? "modal-input error_input"
                        : "modal-input "
                    }
                    value={formik.values.document_number || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.document_number &&
                  formik.errors.document_number ? (
                    <p className="input-error-message ">
                      {formik.errors.document_number}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-select__item modal-four">
            <div className="modal-select__box relative">
              <label htmlFor="gender">{t("Jinsi")}</label>
              <select
                name="gender"
                id="gender"
                onChange={(e) => formik.setFieldValue("gender", e.target.value)}
                onBlur={formik.handleBlur}
                value={formik.values.gender || ""}
                className={
                  formik.touched.gender && formik.errors.gender
                    ? "modal-input error_input"
                    : "modal-input "
                }
              >
                <option
                  className="course_disabled_option"
                  disabled
                  hidden
                  value={""}
                ></option>
                <option value="1">{t("Erkak")}</option>
                <option value="2">{t("Ayol")}</option>
              </select>
              {formik.touched.gender && formik.errors.gender ? (
                <p className="input-error-message ">{formik.errors.gender}</p>
              ) : null}
            </div>
            <div className="modal-select__box relative">
              <label htmlFor="level">{t("O‘quvchining bilim darajasi")}</label>
              <input
                className={
                  formik.touched.level && formik.errors.level
                    ? "modal-input error_input"
                    : "modal-input "
                }
                disabled={formik.values.studied_before !== "1" ? true : false}
                value={formik.values.level || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
                name="level"
                id="level"
              />
              {formik.touched.level && formik.errors.level ? (
                <p className="input-error-message ">{formik.errors.level}</p>
              ) : null}
            </div>
            <div className="modal-select__box relative">
              <label htmlFor="times">{t("Ma'qul dars vaqti")}</label>
              <select
                id="times"
                name="suitable_time"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.suitable_time || ""}
                className={
                  formik.touched.suitable_time && formik.errors.suitable_time
                    ? "modal-input error_input"
                    : "modal-input "
                }
              >
                <option
                  className="course_disabled_option"
                  disabled
                  hidden
                  value={""}
                ></option>
                {students.modalLessonTimes?.map((element) => {
                  return (
                    <option key={element.id} value={element.id}>
                      {element.starts_at} {"-"}
                      {element.ends_at}
                    </option>
                  );
                })}
              </select>
              {formik.touched.suitable_time && formik.errors.suitable_time ? (
                <p className="input-error-message ">
                  {formik.errors.suitable_time}
                </p>
              ) : null}
            </div>
            <div
              className={
                students.editingId ? "d-none" : "modal-select__box relative"
              }
            >
              <label htmlFor="password">{t("Parol")}</label>
              <div className="password-wrapper">
                <input
                  name="password"
                  id="password"
                  className={
                    formik.touched.password && formik.errors.password
                      ? "modal-input error_input"
                      : "modal-input "
                  }
                  value={formik.values.password || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"
                />
                {formik.touched.password && formik.errors.password ? (
                  <p className="input-error-message ">
                    {formik.errors.password}
                  </p>
                ) : null}
                <div className="password-icon"></div>
              </div>
            </div>
          </div>
          <div className="modal-select__item modal-four">
            <div className="modal-select__box relative">
              <label htmlFor="birth_date">{t("Tug'ilgan sanasi")}</label>
              <input
                id="birth_date"
                className={
                  formik.touched.birth_date && formik.errors.birth_date
                    ? "modal-input error_input"
                    : "modal-input "
                }
                type="date"
                name="birth_date"
                value={formik.values.birth_date || ""}
                onChange={(e) =>
                  formik.setFieldValue("birth_date", e.target.value)
                }
                onBlur={formik.handleBlur}
              />
              {formik.touched.birth_date && formik.errors.birth_date ? (
                <p className="input-error-message ">
                  {formik.errors.birth_date}
                </p>
              ) : null}
            </div>
            <div className="modal-select__box relative">
              <label htmlFor="school">{t("Maktabi")}</label>
              <div className="password-wrapper">
                <input
                  name="school"
                  id="school"
                  className={
                    formik.touched.school && formik.errors.school
                      ? "modal-input error_input"
                      : "modal-input "
                  }
                  value={formik.values.school || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"
                />
                {formik.touched.school && formik.errors.school ? (
                  <p className="input-error-message ">{formik.errors.school}</p>
                ) : null}
              </div>
            </div>
            <div className="modal-select__box relative">
              <label className="course_label" htmlFor="group">
                {t("Guruh turi")}
              </label>
              <select
                id="group"
                className={
                  formik.touched.group_type && formik.errors.group_type
                    ? "modal-input error_input"
                    : "modal-input"
                }
                name="group_type"
                value={formik.values.group_type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option
                  className="course_disabled_option"
                  value=""
                  disabled
                  hidden
                ></option>

                {students.modalGroupTypes?.map((groupType) => (
                  <option value={groupType[0]} key={groupType[0]}>
                    {groupType[1]}
                  </option>
                ))}
              </select>
              {formik.touched.group_type && formik.errors.group_type ? (
                <p className="input-error-message">
                  {formik.errors.group_type}
                </p>
              ) : null}
            </div>
          </div>
          <div className="modal-select__item modal-four">
            <div className="modal-select__box relative">
              <label htmlFor="address">{t("O'quvchining manzili")}</label>
              <input
                className={
                  formik.touched.address && formik.errors.address
                    ? "modal-input error_input"
                    : "modal-input "
                }
                value={formik.values.address || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                name="address"
                id="address"
              />
              {formik.touched.address && formik.errors.address ? (
                <p className="input-error-message ">{formik.errors.address}</p>
              ) : null}
            </div>
            <div className="modal-select__box relative">
              <label className="course_label" htmlFor="state">
                {t("O'quvchining holati")}
              </label>
              <select
                id="state"
                className={
                  formik.touched.state && formik.errors.state
                    ? "modal-input error_input"
                    : "modal-input"
                }
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option
                  className="course_disabled_option"
                  value=""
                  disabled
                  hidden
                ></option>
                <option value="1">Issiq o'quvchi</option>
                <option value="2">Iliq o'quvchi</option>
                <option value="3">Sovuq o'quvchi</option>
              </select>
              {formik.touched.state && formik.errors.state ? (
                <p className="input-error-message">{formik.errors.state}</p>
              ) : null}
            </div>
            <div className="modal-select__box relative">
              <label htmlFor="phone_number">{t("Telefon raqami")}</label>
              <input
                className={
                  formik.touched.phone_number && formik.errors.phone_number
                    ? "modal-input error_input"
                    : "modal-input "
                }
                value={formik.values.phone_number || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="tel"
                name="phone_number"
                id="phone_number"
              />
              {formik.touched.phone_number && formik.errors.phone_number ? (
                <p className="input-error-message ">
                  {formik.errors.phone_number}
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="input-buttons">
          <button
            onClick={() => cancelAction()}
            id="modalClose"
            type="button"
            className="input-btn"
          >
            {t("Bekor qilish")}
          </button>
          <button
            className="input-btn input-btn--submit"
            type="submit"
            disabled={saveLoader}
          >
            {saveLoader ? (
              <ReactLoading
                type={"spin"}
                color="#ffffff"
                className="delete_loader"
                height={"30px"}
                width={"30px"}
              />
            ) : (
              t("Saqlash")
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
