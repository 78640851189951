import React, { useCallback, useContext, useEffect } from "react";
import ArchivedGroupsActions from "../../../Actions/GroupsActions/ArchivedGroupsActions";
import AdminLayout from "../../../AdminLayout";
import DeleteConfirmation from "../../../Components/DeleteConfirmation";
import PaginationComponent from "../../../Components/Pagination";
import TitleBar from "../../../Components/TitleBar";
import { ArchivedGroupsContext } from "../../../Contexts/GroupsContext/ArchivedGroupsContext";
import SubjectToggleBox from "../Components/SubjectToggleBox";
import {
  cancelDeleteAction,
  createAction,
  deleteAction,
  loadItems,
  onReload,
} from "./actions";
import ArchiveInfoModal from "./components/ArchiveInfoModal";
import GroupInfoModal from "./components/GroupInfoModal";
import Heading from "./components/Heading.jsx";
import ListItem from "./components/ListItem";

export default function ArchivedGroups() {
  const { archivedGroups, dispatchArchivedGroups } = useContext(
    ArchivedGroupsContext
  );
  useEffect(() => {
    onReload(archivedGroups, dispatchArchivedGroups);
  }, []);

  const paginateTo = useCallback((page) => {
    dispatchArchivedGroups({
      type: ArchivedGroupsActions.ADD_QUERY_PARAM,
      payload: { page: page },
    });
    loadItems({ page: page }, archivedGroups, dispatchArchivedGroups);
  });

  const handleClickClosePanelFromOutside = (e) => {
    if (!e.target.className.includes("build-table__item--7")) {
      dispatchArchivedGroups({
        type: ArchivedGroupsActions.CLOSE_CONTEXT_MENU,
      });
    }
  };
  useEffect(() => {
    document.body.addEventListener("click", handleClickClosePanelFromOutside);
    return () => {
      document.body.removeEventListener(
        "click",
        handleClickClosePanelFromOutside
      );
    };
  });

  return (
    <AdminLayout>
      <TitleBar
        title="Tugatilgan va yopilgan guruhlarning arxiv jurnali"
        createAction={false}
      />
      <div className="container">
        <div className="subject-box">{/* <SubjectToggleBox /> */}</div>
        <Heading />
        {/* {archivedGroups.data.results && 
        archivedGroups.data.results.map((archivedGroup) => ( */}
        <ListItem
        // group={archivedGroup} key={archivedGroup.id}
        />
        {/* ))}/ */}
        <PaginationComponent context={archivedGroups} callback={paginateTo} />
      </div>
      <GroupInfoModal />
      <ArchiveInfoModal />
      <DeleteConfirmation
        deleteCallback={(e) =>
          deleteAction(e, archivedGroups, dispatchArchivedGroups)
        }
        cancelCallback={() => cancelDeleteAction(dispatchArchivedGroups)}
        showModal={archivedGroups.deletingId !== null}
      />
    </AdminLayout>
  );
}
