import React, { useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { useAttendanceDetailsStore } from "../../../../Store/AttendanceFormStore/AttendanceDeatilsStore";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function handleChangeComment(e, student, setStudent) {
  setStudent({ ...student, comment: e.target.value });
}
function handleChangeInput(e, student, setStudent) {
  setStudent({ ...student, with_reason: e.target.checked });
}

export default function CommentModal() {
  const { id, students } = useAttendanceDetailsStore();
  const [currentStudent, setCurrentStudent] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentStudent(students?.find((element) => element.id == id) || null);
  }, [id]);

  function handleNoClick(e) {
    e.preventDefault();
    useAttendanceDetailsStore.setState(({ students }) => ({
      id: null,
      students: students?.map((s) =>
        s.id === currentStudent.id
          ? { ...s, with_reason: false, comment: "" }
          : s
      ),
    }));
    setCurrentStudent(null);
  }

  function saveComment(e) {
    e.preventDefault();
    const comment = currentStudent.comment;
    const with_reason = currentStudent.with_reason;
    const updatedStudents = students?.map((student) =>
      student.id === id ? { ...student, comment, with_reason } : student
    );
    useAttendanceDetailsStore.setState({
      id: null,
      students: updatedStudents,
    });
    setCurrentStudent(null);
  }
  return (
    <form
      className={`modal-is_missed modal-delete_form ${
        id && "modal_delete_active"
      }`}
    >
      <Typography
        color="#ff9800"
        textAlign="center"
        fontSize="20px"
        fontFamily="Inter"
        margin="10px 0"
      >
        {t("Sabablimi?")}
      </Typography>
      <textarea
        className="modal-delete__input"
        name="Izoh"
        placeholder="Izoh"
        onChange={(e) =>
          handleChangeComment(e, currentStudent, setCurrentStudent)
        }
        value={currentStudent?.comment}
      ></textarea>
      <FormGroup sx={{ width: "250px" }}>
        <FormControlLabel
          sx={{ color: "#323232", fontSize: "12px" }}
          control={
            <Checkbox
              checked={currentStudent?.with_reason || false}
              onChange={(e) =>
                handleChangeInput(e, currentStudent, setCurrentStudent)
              }
            />
          }
          label="Sababli"
        />
      </FormGroup>
      <div className="modal-delete-btn">
        <div className="btn btn-blue" onClick={(e) => saveComment(e)}>
          {t("Belgilash")}
        </div>
        <div
          id="modal-delete-no"
          className="btn grey-box"
          style={{ cursor: "pointer" }}
          onClick={(e) => handleNoClick(e)}
        >
          {t("Yo'q")}
        </div>
      </div>
    </form>
  );
}
