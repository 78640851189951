import { toastError } from "../../../Helpers/toasts";
import GroupLevelUpRepository from "../../../Repositories/GroupManagement/GroupLevelUpRepository";
import { useGroupLevelStore } from "../../../Store/GroupManagement/GroupLevelUpStore";

export const onReload = async () => {
  useGroupLevelStore.setState({
    data: null,
    months: null,
    pagination: null,
    month: null,
  });
  const { data, status, nonFieldErrors } =
    await GroupLevelUpRepository.getGroups();
  if (status) {
    useGroupLevelStore.setState({
      data: data.data,
      months: data.months,
      pagination: data.pagination,
    });
  }
  toastError(nonFieldErrors);
};
export const loadItems = async (queryParams) => {
  const { data, status, nonFieldErrors } =
    await GroupLevelUpRepository.getGroups(queryParams);
  if (status) {
    useGroupLevelStore.setState({
      data: data.data,
      months: data.months,
      pagination: data.pagination,
    });
  }
  toastError(nonFieldErrors);
};
