import { useMemo } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AdminLayout from "../../../AdminLayout";
import EmptyContent from "../../../Components/Empty-content/EmptyContent";
import ItemsLoader from "../../../Components/Loader/basic loader/ItemsLoader";
import TitleBar from "../../../Components/TitleBar";
import { useTeacherAvailabilityStore } from "../../../Store/GroupManagement/TeacherAvailabilityStore";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import { onReload } from "./actions";
import Selector from "./components/Select";
import TabelSubHeading from "./components/TabelSubHeading";
import TableRow from "./components/TableRow";

export default function TeacherAvailability() {
  const { setLoader } = useLoaderStore();
  const { data, loader, queryParams, getBranches, branches, lessonStyles } =
    useTeacherAvailabilityStore();

  useEffect(() => {
    pageLoad();
  }, []);
  const { t } = useTranslation();
  const pageLoad = async () => {
    setLoader(true);
    await Promise.all([
      onReload(
        new URLSearchParams({
          lesson_style: 1,
        })
      ),
      getBranches(),
    ]);
    setLoader(false);
  };

  const lessonStyleOptions = useMemo(() => {
    return (
      lessonStyles?.map((element) => ({
        label: element.name,
        value: element.value,
      })) || []
    );
  }, [lessonStyles]);

  const branchesOptions = useMemo(() => {
    return (
      branches?.map((element) => ({
        label: element.title,
        value: element.id,
      })) || []
    );
  }, [branches]);

  const defaultValue = lessonStyleOptions?.find(
    (item) => item.value === queryParams.lesson_style
  );

  return (
    <AdminLayout>
      <TitleBar
        title={t(
          "O‘qituvchi xodimlarni dars jadvaliga asosan ish jarayonlari rejasi"
        )}
        createAction={false}
        groupBtns={
          <>
            <Selector
              options={lessonStyleOptions}
              property={t("Dars turi")}
              defaultValue={defaultValue}
            />
            <Selector options={branchesOptions} property={t("Filiallar")} />
          </>
        }
      />
      <div className="container">
        {loader ? (
          <ItemsLoader size={70} />
        ) : data?.availability?.length > 0 ? (
          <div className="buildingJournal">
            <TabelSubHeading />
            {data?.availability?.map((item, index) => {
              return (
                <TableRow teacher={item} key={item.teacher_id} index={index} />
              );
            })}
          </div>
        ) : (
          <EmptyContent />
        )}
      </div>
    </AdminLayout>
  );
}
