import { useEffect, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import AdminLayout from "../../AdminLayout";
import EmptyContent from "../../Components/Empty-content/EmptyContent";
import { useLoaderStore } from "../../Store/Loader/LoaderStore";
import { useDashboardStore } from "../../Store/Trello/DashboardStore";
import { DonutChart } from "./components/DonutChart";
import {
  Container,
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  Paper,
} from "@mui/material";
import { formatNumberWithSpaces } from "../../Helpers/formatNumber";
import { useTranslation } from "react-i18next";

const cardStyle = {
  padding: "10px",
  height: "150px",
  width: "150px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "10px",
  borderRadius: "10px",
};

const listCardStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "10px",
  padding: "20px",
  height: "min-content",
  borderRadius: "10px",
};
const listCard = {
  display: "flex",
  alignItems: "center",
  width: "100%",
  justifyContent: "space-between",
  gap: "30px",
  background: "#fef2f2",
  borderRadius: "10px",
  padding: "10px",
  color: "#7f1d1d",
  fontFamily: "Inter",
};

export default function DashboardPage() {
  const { onReload, monthlyFinance } = useDashboardStore();
  const { setLoader } = useLoaderStore();

  const sumPayments = (item, keys) => {
    return keys.reduce((total, key) => {
      const value = parseInt((item[key] || "0").toString().replace(/\s/g, ""));
      return total + (isNaN(value) ? 0 : value);
    }, 0);
  };

  // Total payments
  const totalPayments = (monthlyFinance?.months || []).map((item) => {
    return sumPayments(item, [
      "cash_payments_in",
      "online_transaction_payments_in",
      "terminal_payments_in",
    ]);
  });

  // Total expenditures
  const totalExpenditure = (monthlyFinance?.months || []).map((item) => {
    return sumPayments(item, [
      "cash_expenditures_out",
      "online_transaction_expenditures_out",
      "terminal_expenditures_out",
    ]);
  });

  const profit = monthlyFinance?.months?.map((item) => {
    const cashPaymentsIn = parseInt(
      (item.cash_left || "0").toString().replace(/\s/g, "")
    );
    const onlineTransactionPaymentsIn = parseInt(
      (item.online_transaction_left || "0").toString().replace(/\s/g, "")
    );
    const terminalPaymentsIn = parseInt(
      (item.terminal_left || "0").toString().replace(/\s/g, "")
    );

    return cashPaymentsIn + onlineTransactionPaymentsIn + terminalPaymentsIn;
  });

  const expenditure = [
    {
      name: "Tushum",
      data: totalPayments,
    },
    {
      name: "Xarajat",
      data: totalExpenditure,
    },
  ];
  const income = [
    {
      name: "Tushum",
      data: profit,
    },
  ];

  const pageLoad = async () => {
    setLoader(true);
    await onReload();
    setLoader(false);
  };

  useEffect(() => {
    pageLoad();
  }, []);

  const months = useMemo(
    () => monthlyFinance?.months?.map((item) => item.month_name),
    [monthlyFinance]
  );
  const { t } = useTranslation();
  return (
    <AdminLayout>
      <div className="container">
        <StatisticCards />
        <DonutContainer />
        <DashboardCards />
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2,1fr)",
            gap: "30px",
          }}
        >
          <LineChart
            series={income}
            title={t("Umumiy foyda")}
            colors={["#22c55e"]}
            months={months}
          />
          <LineChart
            series={expenditure}
            title={t("Tushum va xarajatlar")}
            colors={["#22c55e", "#eab308"]}
            months={months}
          />
        </Box>
      </div>
    </AdminLayout>
  );
}

function StatisticCards() {
  const { dashboardReports } = useDashboardStore();
  const { teachers, students, staff, groups } = dashboardReports?.numbers || {};
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        my: "30px",
        display: "flex",
        gap: "30px",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <MyCard icon={<IcRoundPerson />} title={t("Xodimlar")} count={staff} />
      <MyCard
        icon={<BiPeopleFill />}
        title={t("O'qituvchilar")}
        count={teachers}
      />
      <MyCard
        icon={<PhStudentBold />}
        title={t("O'quvchilar")}
        count={students}
      />
      <MyCard icon={<FormkitGroup />} title={t("Guruhlar")} count={groups} />
    </Box>
  );
}

function MyCard({ icon, title, count }) {
  return (
    <Card sx={cardStyle}>
      {icon}
      <Typography sx={{ fontFamily: "Inter" }} variant="h6">
        {title}
      </Typography>
      <Typography sx={{ fontFamily: "Inter", fontWeight: "bold" }} variant="h6">
        {count}
      </Typography>
    </Card>
  );
}

function IcRoundPerson(props) {
  return (
    <Box sx={{ padding: "5px", background: "#f3e8ff", borderRadius: "10px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="2em"
        height="2em"
        viewBox="0 0 24 24"
        {...props}
      >
        <path
          fill="#870099"
          d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4s-4 1.79-4 4s1.79 4 4 4m0 2c-2.67 0-8 1.34-8 4v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4"
        ></path>
      </svg>
    </Box>
  );
}
function BiPeopleFill(props) {
  return (
    <Box sx={{ padding: "5px", background: "#fef3c7", borderRadius: "10px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="2em"
        height="2em"
        viewBox="0 0 16 16"
        {...props}
      >
        <path
          fill="#ebcb00"
          d="M7 14s-1 0-1-1s1-4 5-4s5 3 5 4s-1 1-1 1zm4-6a3 3 0 1 0 0-6a3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5a2.5 2.5 0 0 0 0 5"
        ></path>
      </svg>
    </Box>
  );
}
function PhStudentBold(props) {
  return (
    <Box sx={{ padding: "5px", background: "#dcfce7", borderRadius: "10px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="2em"
        height="2em"
        viewBox="0 0 256 256"
        {...props}
      >
        <path
          fill="#63db00"
          d="m227.79 52.62l-96-32a11.85 11.85 0 0 0-7.58 0l-96 32A12 12 0 0 0 20 63.37a6 6 0 0 0 0 .63v80a12 12 0 0 0 24 0V80.65l23.71 7.9a67.92 67.92 0 0 0 18.42 85A100.36 100.36 0 0 0 46 209.44a12 12 0 1 0 20.1 13.11C80.37 200.59 103 188 128 188s47.63 12.59 61.95 34.55a12 12 0 1 0 20.1-13.11a100.36 100.36 0 0 0-40.18-35.92a67.92 67.92 0 0 0 18.42-85l39.5-13.17a12 12 0 0 0 0-22.76Zm-99.79-8L186.05 64L128 83.35L70 64ZM172 120a44 44 0 1 1-81.06-23.71l33.27 11.09a11.9 11.9 0 0 0 7.58 0l33.27-11.09A43.85 43.85 0 0 1 172 120"
        ></path>
      </svg>
    </Box>
  );
}
function FormkitGroup(props) {
  return (
    <Box sx={{ padding: "5px", background: "#dbeafe", borderRadius: "10px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="2em"
        height="2em"
        viewBox="0 0 16 16"
        {...props}
      >
        <path
          fill="#0088f0"
          d="M8 8.5c-.08 0-.15-.02-.22-.05L1.28 5.2a.5.5 0 0 1 0-.9l6.5-3.25c.14-.07.31-.07.45 0l6.5 3.25a.5.5 0 0 1 0 .9l-6.5 3.25c-.07.04-.15.05-.22.05ZM2.62 4.75L8 7.44l5.38-2.69L8 2.06z"
        ></path>
        <path
          fill="#0088f0"
          d="M8 11.75c-.08 0-.15-.02-.22-.05l-6.5-3.25a.5.5 0 0 1 0-.9l3.25-1.62c.25-.12.55-.02.67.22c.12.25.02.55-.22.67L2.62 8L8 10.69L13.38 8l-2.36-1.18a.49.49 0 0 1-.22-.67c.12-.25.42-.35.67-.22l3.25 1.62a.5.5 0 0 1 0 .9l-6.5 3.25c-.07.04-.15.05-.22.05"
        ></path>
        <path
          fill="#0088f0"
          d="M8 15c-.08 0-.15-.02-.22-.05l-6.5-3.25a.5.5 0 0 1 0-.9l3.25-1.62c.25-.12.55-.02.67.22c.12.25.02.55-.22.67l-2.36 1.18L8 13.94l5.38-2.69l-2.36-1.18a.49.49 0 0 1-.22-.67c.12-.25.42-.35.67-.22l3.25 1.62a.5.5 0 0 1 0 .9l-6.5 3.25c-.07.04-.15.05-.22.05"
        ></path>
      </svg>
    </Box>
  );
}

function DonutContainer() {
  const { dashboardReports, dailyFinance, subjects } = useDashboardStore();
  const subjectData = subjects?.data;
  const { absents, enrolled, updated, expelled, failed } =
    dashboardReports?.changes || {};
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(3,1fr)",
        gap: "30px",
        minHeight: "300px",
      }}
    >
      {dailyFinance?.payment_cash ||
      dailyFinance?.payment_terminal ||
      dailyFinance?.payment_online ? (
        <DonutChart
          data={[
            dailyFinance?.payment_cash,
            dailyFinance?.payment_terminal,
            dailyFinance?.payment_online,
          ]}
          title={t("Balans")}
          labels={["Naqt", "Plastik", "Online"]}
        />
      ) : (
        <EmptyContent />
      )}
      {subjectData?.length ? (
        <DonutChart
          data={[...subjectData?.map((item) => item?.age_groups?.length)]}
          title={t("Top fanlar")}
          labels={[...subjectData?.map((item) => item.name)]}
        />
      ) : (
        <EmptyContent />
      )}
      <Card sx={listCardStyle}>
        <Box sx={listCard}>
          <Typography fontFamily={"Inter"} fontSize="18px" variant="h6">
            {t("Guruhdan chiqarilgan soni")}
          </Typography>
          <Typography
            fontFamily={"Inter"}
            fontSize="23px"
            fontWeight={"bold"}
            variant="h4"
          >
            {expelled}
          </Typography>
        </Box>
        <Box sx={listCard}>
          <Typography fontFamily={"Inter"} fontSize="18px" variant="h6">
            {t("Imtihondan yiqilganlar")}
          </Typography>
          <Typography
            fontFamily={"Inter"}
            fontSize="23px"
            fontWeight={"bold"}
            variant="h4"
          >
            {failed}
          </Typography>
        </Box>
        <Box sx={listCard}>
          <Typography fontFamily={"Inter"} fontSize="18px" variant="h6">
            {t("Boshqa guruhi o'tkazilganlar")}
          </Typography>
          <Typography
            fontFamily={"Inter"}
            fontSize="23px"
            fontWeight={"bold"}
            variant="h4"
          >
            {updated}
          </Typography>
        </Box>
        <Box sx={listCard}>
          <Typography fontFamily={"Inter"} fontSize="18px" variant="h6">
            {t("Darsga kelmaganlar")}
          </Typography>
          <Typography
            fontFamily={"Inter"}
            fontSize="23px"
            fontWeight={"bold"}
            variant="h4"
          >
            {absents}
          </Typography>
        </Box>
        <Box sx={listCard}>
          <Typography fontFamily={"Inter"} fontSize="18px" variant="h6">
            {t("Qabulga yozilganlar")}
          </Typography>
          <Typography
            fontFamily={"Inter"}
            fontSize="23px"
            fontWeight={"bold"}
            variant="h4"
          >
            {enrolled}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}

function LineChart({ series, title, months, colors }) {
  const chartOptions = {
    chart: {
      height: 350,
      type: "line",
      dropShadow: {
        enabled: false,
      },
      zoom: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
    },
    colors: colors,
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: title,
      align: "left",
    },
    grid: {
      borderColor: "#d1d5db",
      row: {
        colors: ["white", "white"],
        opacity: 0.5,
      },
    },
    markers: {
      size: 2,
      labels: {
        show: true,
        formatter: function (val) {
          return `${(val || "0")
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} so'm`; // Format the y-axis label with 'so'm' currency
        },
      },
    },
    xaxis: {
      categories: months,
      tickAmount: months?.length, // Ensure all labels are shown
      labels: {
        show: true,
        rotateAlways: true, // Ensures labels are always rotated
        rotate: -45, // Rotate labels for better visibility
        style: {
          fontSize: "10px",
          fontWeight: "bold",
          fontFamily: "Inter",
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return `${(val || "0")
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} so'm`; // Format the y-axis label with 'so'm' currency
        },
        style: {
          fontSize: "10px",
          fontWeight: "bold",
          fontFamily: "Inter",
        },
        rotateAlways: true, // Ensures labels are always rotated
        rotate: -45, // Rotate labels for better visibility
      },
      axisBorder: {
        show: true, // Show or hide the axis line
        color: "#d1d5db", // Color of the axis line
      },
      axisTicks: {
        show: true, // Show or hide ticks along the axis
        color: "#333", // Color of the ticks
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  };

  return (
    <Box
      sx={{
        height: "auto",
        backgroundColor: "white",
        padding: "10px",
        borderRadius: "10px",
      }}
    >
      <ReactApexChart series={series} options={chartOptions} type="line" />
    </Box>
  );
}

function DashboardCard({ title, bg, colorTitle, colorAmount, value }) {
  return (
    <Paper
      elevation={0}
      sx={{ backgroundColor: "white", borderRadius: "10px" }}
    >
      <CardContent>
        <Typography
          variant="h5"
          sx={{ color: colorTitle, fontWeight: "bold", fontFamily: "Inter" }}
          component="div"
        >
          {title}
        </Typography>
        <Typography
          variant="h4"
          sx={{ color: colorAmount, fontWeight: "bold", fontFamily: "Inter" }}
          component="div"
        >
          {value}
        </Typography>
      </CardContent>
    </Paper>
  );
}

function DashboardCards() {
  const { dailyFinance } = useDashboardStore();
  const dailyRevenue = `${
    formatNumberWithSpaces(dailyFinance?.payments_in) || 0
  } so'm`;
  const dailyExpenses = `${
    formatNumberWithSpaces(dailyFinance?.expenditures) || 0
  } so'm`;
  const totalProfit = `${
    formatNumberWithSpaces(dailyFinance?.profit) || 0
  } so'm`;
  const { t } = useTranslation();
  return (
    <Container>
      <Grid sx={{ paddingY: "30px" }} container spacing={3}>
        <Grid item xs={12} sm={4}>
          <DashboardCard
            title={t("Kunlik Tushumlar")}
            bg={"#f7fee7"}
            colorTitle={"#84cc16"}
            value={dailyRevenue}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DashboardCard
            bg={"#fff1f2"}
            colorTitle={"#f43f5e"}
            title={t("Kunlik Chiqimlar")}
            value={dailyExpenses}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DashboardCard
            bg={"#f0fdfa"}
            colorTitle={"#06b6d4"}
            title={t("Umumiy Foyda")}
            value={totalProfit}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
