import { useTranslation } from "react-i18next";
import { useSalaryAdvanceStore } from "../../../../../Store/CashControlStore/SalaryAdvanceStore";

export default function TransactionsHistory() {
  const { selectedMonth } = useSalaryAdvanceStore();
  const { t } = useTranslation();

  return (
    <div style={{ padding: "0px" }} className="transfer-info ">
      <h1 className="income__detail--title">{t("To'lovlar tarixi")}</h1>
      <div className="cash-discount">
        <div className="cash-disount__list">
          <div className="cash-disount__item">
            <ul className="cash-disount__details">
              <li className="cash-disount__details-item history-tablehead number--payment">
                №
              </li>
              <li className="cash-disount__details-item history-tablehead">
                {t("To'lov turi")}
              </li>
              <li className="cash-disount__details-item history-tablehead">
                {t("Qiymati")}
              </li>
              <li className="cash-disount__details-item history-tablehead">
                {t("Kassir")}
              </li>
              <li className="cash-disount__details-item history-tablehead">
                {t("To'lov qilingan sana")}
              </li>
            </ul>
          </div>
          {selectedMonth?.history?.length > 0 ? (
            selectedMonth?.history?.map((item, index) => {
              return (
                <div key={item.id} className="cash-disount__item">
                  <ul className="cash-disount__details">
                    <li className="cash-disount__details-item number--payment">
                      {index + 1}
                    </li>
                    <li className="cash-disount__details-item">
                      {item.payment_mode}
                    </li>
                    <li className="cash-disount__details-item">
                      {item.amount} so'm
                    </li>
                    <li className="cash-disount__details-item">
                      {item.cashier}
                    </li>
                    <li className="cash-disount__details-item">{item.date}</li>
                  </ul>
                </div>
              );
            })
          ) : (
            <h3 className="transactions_history-empty">
              {t("To'lovlar mavjud emas!")}
            </h3>
          )}
        </div>
      </div>
    </div>
  );
}
