import { create } from "zustand";
import MainGroupsRepository from "../../Repositories/Groups/MainGroupsRepository";

export const useExamScheduleStore = create((set) => ({
  data: null,
  openContextMenu: null,
  filterset: null,
  branches: null,
  isLoading: false,
  isOpenModal: false,
  pagination: null,
  total_exams_count: null,
  start_date: null,
  end_date: null,
  queryParams: {
    page: 1,
  },
  setLoading: (status) => {
    set({
      isLoading: status,
    });
  },
  setModalToggler: (status) => {
    set({ isOpenModal: status });
  },
  getBranches: async () => {
    const { data, status, nonFieldErrors } =
      await MainGroupsRepository.getAllBranches();
    if (status) {
      set({
        branches: data,
      });
    } else toastError(nonFieldErrors);
  },
  updateParams: (paramItem) => {
    set((prevState) => ({
      queryParams: { ...prevState.queryParams, ...paramItem },
    }));
  },
}));
