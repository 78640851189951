import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import StudentsRegistrationActions from "../../../../Actions/RegistrationActions/StudentsRegistrationActions";
import { LoaderContext } from "../../../../Contexts/LoaderContext";
import { StudentsRegistrationContext } from "../../../../Contexts/RegistrationContex/StudentRegistrationContext";
import { filterGroups, openFilterList } from "../actions";
import Selector from "./Select";
const container = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "15px",
  outline: "none",
  padding: "30px 60px",
};
const style = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
};
const borderElement = {
  margin: "10px 0",
  display: "flex",
  flexDirection: "column",
  gap: "10px 0",
};
const btnSecton = {
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
};

export default function FilteredModal({ data }) {
  const { students, dispatchStudents } = useContext(
    StudentsRegistrationContext
  );
  const { t } = useTranslation();

  const { dispatchLoader } = useContext(LoaderContext);

  const handleSubmit = async () => {
    dispatchStudents({
      type: StudentsRegistrationActions.CLOSE_FILTER_LIST,
    });
    await filterGroups(
      { ...students?.queryParams, page: 1 },
      dispatchStudents,
      dispatchLoader
    );
  };

  const statuses = [
    { label: "Faol", value: "active" },
    { label: "To'xtatilgan (vaqtincha)", value: "closed_temporary" },
    { label: "To'xtatilgan (butunlay)", value: "closed_permanant" },
    { label: "Yakunlagan (muvaffaqiyatli)", value: "finished_successfully" },
  ];

  return (
    <div>
      <Modal
        className="filterModal"
        onClick={(e) => e.stopPropagation()}
        open={students.isOpenFilterList}
        onClose={() => openFilterList(dispatchStudents)}
        StudentsRegistrationContext
        aria-describedby="modal-modal-description"
      >
        <Box sx={container}>
          <Box sx={style}>
            <Box sx={borderElement}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={18}
              >
                {t("Fanlar bo'yicha saralash")}
              </Typography>
              <Selector
                property={t("Fanlar")}
                param={data?.subjects}
                value={data?.subjects?.find(
                  (item) => item.value == students.queryParams.subject
                )}
              />
            </Box>
            <Box sx={borderElement}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={18}
              >
                {t("Guruh turi bilan saralash")}
              </Typography>
              <Selector
                property={t("Guruh turlari")}
                param={data?.groupTypes}
                value={data?.groupTypes?.find(
                  (item) => item.value == students.queryParams.group_type
                )}
              />
            </Box>
            <Box sx={borderElement}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={18}
              >
                {t("Dars kunlari bilan saralash")}
              </Typography>
              <Selector
                property={t("Dars kunlari")}
                param={data?.days}
                value={data?.days?.find(
                  (item) => item.value == students.queryParams.suitable_days
                )}
              />
            </Box>
            <Box sx={borderElement}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={18}
              >
                {t("Dars vaqtlari bilan saralash")}
              </Typography>
              <Selector
                property={t("Dars vaqtlari")}
                param={data?.times}
                value={data?.times?.find(
                  (item) => item.value == students.queryParams.suitable_time
                )}
              />
            </Box>
            <Box sx={borderElement}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={18}
              >
                {t("Binolar bilan saralash")}
              </Typography>
              <Selector
                property={t("Binolar")}
                param={data?.branches}
                value={data?.branches?.find(
                  (item) => item.value == students.queryParams.studying_place
                )}
              />
            </Box>
            <Box sx={borderElement}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={18}
              >
                {t("Xodim bilan saralash")}
              </Typography>
              <Selector
                property={t("Xodimlar")}
                param={data?.staffs}
                value={data?.staffs?.find(
                  (item) => item.value == students.queryParams.wanted_teacher
                )}
              />
            </Box>
            <Box sx={borderElement}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={18}
              >
                {t("O'quvchilar bilim darajasi")}
              </Typography>
              <Selector
                property="Level"
                param={data?.studyLevels}
                value={data?.studyLevels?.find(
                  (item) => item.value == students.queryParams.study_levels
                )}
              />
            </Box>
            <Box sx={borderElement}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={18}
              >
                {t("Guruh holati bo'yicha")}
              </Typography>
              <Selector
                property="Guruh statusi"
                param={statuses}
                value={statuses?.find(
                  (item) => item.value == students.queryParams.status
                )}
              />
            </Box>
          </Box>
          <Box sx={btnSecton}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              endIcon={<FilterAltIcon />}
            >
              Filter
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
