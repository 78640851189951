import React from "react";
import { useTranslation } from "react-i18next";
import { useIncomeCashStore } from "../../../../Store/CashControlStore/IncomeCashStore";

export default function TransactionsHistory() {
  const { transactions_history } = useIncomeCashStore();
  const { t } = useTranslation();

  return (
    <div className="transfer-info transfer-info_huge">
      <h1 className="income__detail--title">{t("To'lovlar tarixi")}</h1>
      <div className="cash-discount">
        <div className="cash-disount__list">
          <div className="cash-disount__item">
            <ul className="cash-disount__details">
              <li className="cash-disount__details-item history-tablehead number--payment">
                №
              </li>
              <li className="cash-disount__details-item history-tablehead">
                {t("To'lov turi")}
              </li>
              <li className="cash-disount__details-item history-tablehead">
                {t("Qiymati")}
              </li>
              <li className="cash-disount__details-item history-tablehead">
                {t("Guruh")}
              </li>
              <li className="cash-disount__details-item history-tablehead">
                {t("Oy")}
              </li>
              <li className="cash-disount__details-item history-tablehead">
                {t("Kassir")}
              </li>
              <li className="cash-disount__details-item history-tablehead">
                {t("To'lov qilingan sana")}
              </li>
            </ul>
          </div>
          {transactions_history?.length > 0 ? (
            transactions_history?.map((item, index) => {
              return (
                <div key={item.id} className="cash-disount__item">
                  <ul className="cash-disount__details">
                    <li className="cash-disount__details-item number--payment">
                      {index + 1}
                    </li>
                    <li className="cash-disount__details-item">
                      {item.payment_type}
                    </li>
                    <li className="cash-disount__details-item">
                      {item.amount} so'm
                    </li>
                    <li className="cash-disount__details-item">{item.group}</li>
                    <li className="cash-disount__details-item">
                      {item.months}
                    </li>
                    <li className="cash-disount__details-item">
                      {item.cashier}
                    </li>
                    <li className="cash-disount__details-item">{item.date}</li>
                  </ul>
                </div>
              );
            })
          ) : (
            <h3 className="transactions_history-empty">
              To'lovlar mavjud emas!
            </h3>
          )}
        </div>
      </div>
    </div>
  );
}
