import { create } from "zustand";
import { toastError, toastSuccess } from "../../Helpers/toasts";
import MainGroupsRepository from "../../Repositories/Groups/MainGroupsRepository";
import ExcludedStudentsRepository from "../../Repositories/Reports/ExcludedStudentsRepository";

export const useExcludedStudentsStore = create((set, get) => ({
  results: null,
  reasons: null,
  students_count: null,
  staffs_list: null,
  startDate: null,
  endDate: null,
  pagination: null,
  branches: null,
  isLoading: false,
  queryParams: {
    page: 1,
  },

  setLoading: (status) => {
    set({
      isLoading: status,
    });
  },

  updateParams: (paramItem) => {
    set((prevState) => ({
      queryParams: { ...prevState.queryParams, ...paramItem },
    }));
  },

  onReload: async (queryString = "") => {
    const { data, status, nonFieldErrors } =
      await ExcludedStudentsRepository.studentsList(queryString);
    if (status) {
      set({
        results: data.results.students,
        students_count: data.results.students_count,
        reasons: data.results.filterset.reasons,
        staffs_list: data.results.filterset.teachers,
        pagination: { page_count: data.pages_count || 1 },
        queryParams: { page: 1 },
      });
    } else toastError(nonFieldErrors);
  },
  getBranches: async () => {
    const { data, status } = await MainGroupsRepository.getAllBranches()
    if (status) {
      set({
        branches: data,
      })
    }
  },
  loadItems: async (queryString) => {
    const { data, status, nonFieldErrors } =
      await ExcludedStudentsRepository.studentsList(queryString);
    if (status) {
      set({
        results: data.results.students,
        students_count: data.results.students_count,
        pagination: { page_count: data.pages_count || 1 },
      });
    } else toastError(nonFieldErrors);
  },
}));
