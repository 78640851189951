import React from "react";
import { useTranslation } from "react-i18next";
import { useRefundStore } from "../../../../../Store/CashControlStore/RefundStore";

export default function StudentDetails() {
  const { t } = useTranslation();
  const { studentDetails } = useRefundStore();

  return (
    <>
      <div className="transfer-info__item">
        <div className="transfer-infos">{t("F.I.SH")}</div>
        <div className="transfer-infos">{studentDetails?.fullname}</div>
      </div>
      <div className="transfer-info__item">
        <div className="transfer-infos">{t("Yoshi")}</div>
        <div className="transfer-infos">{studentDetails?.age}</div>
      </div>
      <div className="transfer-info__item">
        <div className="transfer-infos">{t("Yashash manzili")}</div>
        <div className="transfer-infos">{studentDetails?.address}</div>
      </div>
      <div className="transfer-info__item">
        <div className="transfer-infos">{t("Telefon raqami")}</div>
        <div className="transfer-infos">{studentDetails?.phone_number}</div>
      </div>
      <div className="transfer-info__item">
        <div className="transfer-infos">{t("Maktabi")} </div>
        <div className="transfer-infos">{studentDetails?.school}</div>
      </div>
    </>
  );
}
