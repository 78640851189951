import React, { useState } from "react";
import { TextField } from "@mui/material";
import { useExpendatureCashStore } from "../../../../../Store/CashControlStore/ExpendatureCashStore";

const textarea = {
  borderRadius: "10px",
  width: "50%",
};

const TextArea = () => {
  const { updateFixedCosts, fixedCosts } = useExpendatureCashStore();

  const handleChange = (event) => {
    updateFixedCosts("comment", event.target.value);
  };

  return (
    <TextField
      label="Izoh"
      multiline
      rows={3}
      id="outlined-basic"
      variant="filled"
      value={fixedCosts.comment || ""}
      onChange={handleChange}
      fullWidth
      sx={textarea}
    />
  );
};

export default React.memo(TextArea);
