import React from "react";
import Container from "../Loader";

export default function OptionLoader() {
  return (
    <div className="option_loader">
      <Container size={20} />
    </div>
  );
}
