import React from "react";
import { useMemo } from "react";
import { useCallback } from "react";
import Select from "react-select";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 99,
  }),
  control: (provided) => ({
    ...provided,
    height: "40px",
    minHeight: "40px",
    width: "300px",
    fontSize: "15px",
    fontFamily: "Inter",
    fontWeight: "400",
    zIndex: 0,
  }),
};

export default function SelectField({
  handleSelectChange,
  property,
  name,
  value,
  param,
}) {
  return (
    <Select
      classNamePrefix="select"
      placeholder={property}
      styles={customStyles}
      options={param || []}
      isClearable
      name={name}
      value={param?.find((element) => element.value == value)}
      onChange={handleSelectChange}
    />
  );
}
