import React from "react";
import DateSection from "./DateSection";

function Heading() {
  return (
    <div
      style={{
        marginBottom: "10px",
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        width: "fit-content",
        gap: "20px",
      }}
    >
      <DateSection />
    </div>
  );
}

export default Heading;
