import React, { useContext, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import AuthenticationActions from "../../Actions/AuthActions/AuthenticationActions";
import LanguageActions from "../../Actions/ComponentsActions/LanguageActions";
import { AuthenticationContext } from "../../Contexts/AuthContext/AuthenticationContext";
import { LanguageContext } from "../../Contexts/LanguageContext";

export default function HeaderProfileDetail() {
  const { auth, dispatchAuth } = useContext(AuthenticationContext);
  const { language, dispatchLanguage } = useContext(LanguageContext);

  const navigate = useNavigate();

  const logout = useCallback(() => {
    dispatchAuth({ type: AuthenticationActions.LOGOUT });
    navigate("/login", { replace: true });
  });

  const { t } = useTranslation();

  return (
    <div
      className={language.isOpenProfile ? "profile active" : "profile"}
      onClick={() =>
        dispatchLanguage({
          type: LanguageActions.IS_OPEN_PROFILE,
          payload: !language.isOpenProfile,
        })
      }
    >
      <img
        className="profile__img"
        src={
          auth.user?.user_photo
            ? auth.user?.user_photo
            : "/images/profilePhoto.png"
        }
        alt="profile-img"
      />
      <div className="profile__name">
        {auth.user ? (
          <>
            {auth.user.profile_details.first_name}{" "}
            {auth.user.profile_details.last_name}
          </>
        ) : (
          ""
        )}
      </div>
      <div
        className={
          language.isOpenProfile
            ? "profile-dropdown active"
            : "  profile-dropdown"
        }
      >
        <Link to={"/profile/"}>
          <div className="profile-dropdown__item c-pointer">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 10C5.33 10 0 11.34 0 14V15C0 15.55 0.45 16 1 16H15C15.55 16 16 15.55 16 15V14C16 11.34 10.67 10 8 10Z"
                fill="#414141"
              />
            </svg>
            {t("header_profile")}
          </div>
        </Link>
        {/* <div className="profile-dropdown__item c-pointer">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.4301 10.98C17.4701 10.66 17.5001 10.34 17.5001 10C17.5001 9.66 17.4701 9.34 17.4301 9.02L19.5401 7.37C19.7301 7.22 19.7801 6.95 19.6601 6.73L17.6601 3.27C17.5401 3.05 17.2701 2.97 17.0501 3.05L14.5601 4.05C14.0401 3.65 13.4801 3.32 12.8701 3.07L12.4901 0.42C12.4601 0.18 12.2501 0 12.0001 0H8.00008C7.75008 0 7.54008 0.18 7.51008 0.42L7.13008 3.07C6.52008 3.32 5.96008 3.66 5.44008 4.05L2.95008 3.05C2.72008 2.96 2.46008 3.05 2.34008 3.27L0.340084 6.73C0.210084 6.95 0.270084 7.22 0.460084 7.37L2.57008 9.02C2.53008 9.34 2.50008 9.67 2.50008 10C2.50008 10.33 2.53008 10.66 2.57008 10.98L0.460084 12.63C0.270084 12.78 0.220084 13.05 0.340084 13.27L2.34008 16.73C2.46008 16.95 2.73008 17.03 2.95008 16.95L5.44008 15.95C5.96008 16.35 6.52008 16.68 7.13008 16.93L7.51008 19.58C7.54008 19.82 7.75008 20 8.00008 20H12.0001C12.2501 20 12.4601 19.82 12.4901 19.58L12.8701 16.93C13.4801 16.68 14.0401 16.34 14.5601 15.95L17.0501 16.95C17.2801 17.04 17.5401 16.95 17.6601 16.73L19.6601 13.27C19.7801 13.05 19.7301 12.78 19.5401 12.63L17.4301 10.98ZM10.0001 13.5C8.07008 13.5 6.50008 11.93 6.50008 10C6.50008 8.07 8.07008 6.5 10.0001 6.5C11.9301 6.5 13.5001 8.07 13.5001 10C13.5001 11.93 11.9301 13.5 10.0001 13.5Z"
              fill="#414141"
            />
          </svg>
          {t("header_settings")}
        </div> */}
        <div
          className="profile-dropdown__item logOut c-pointer"
          onClick={() => logout()}
          href=""
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 5L12.59 6.41L14.17 8H6V10H14.17L12.59 11.58L14 13L18 9L14 5ZM2 2H9V0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H9V16H2V2Z"
              fill="#414141"
            />
          </svg>
          {t("header_exit")}
        </div>
      </div>
    </div>
  );
}
