import { create } from "zustand";
import { toastError } from "../../Helpers/toasts";
import AttendanceFormRepositories from "../../Repositories/AttendanceForm/AttandanceFormRepository";

export const useAttendanceStore = create((set) => ({
  data: null,
  exclusionReasons: null,
  studentInfoModal: null,
  deleteModal: null,
  discountId: null,
  removeId: null,
  studentData: null,
  modalLoader: false,
  discounts: {},
  updateDiscounts: (data) => set({ discounts: data }),
  updateAttendanceDates: (res) => set({ data: res }),
  getStudentInfo: async (id) => {
    const { data, status, nonFieldError } =
      await AttendanceFormRepositories.getStudentInfo(id);
    if (status) {
      set({ studentData: data });
    } else toastError(nonFieldError);
  },
}));
