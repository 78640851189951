import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AdminLayout from "../../../AdminLayout";
import EmptyContent from "../../../Components/Empty-content/EmptyContent";
import PaginationComponent from "../../../Components/Pagination";
import TitleBar from "../../../Components/TitleBar";
import { useGroupLevelStore } from "../../../Store/GroupManagement/GroupLevelUpStore";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import { loadItems, onReload } from "./actions";
import ListItem from "./components/ListItem";
import TableHead from "./components/TableHead.jsx";

export default function GroupsLevelup() {
  const { data, pagination, queryParams, getBranches, updateParams } =
    useGroupLevelStore();
  const { setLoader } = useLoaderStore();

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    setLoader(true);
    await Promise.all([onReload(), getBranches()]);
    setLoader(false);
  };

  const paginateTo = useCallback((page) => {
    updateParams({ page: page });
    loadItems(
      new URLSearchParams({
        ...queryParams,
        page: page,
      }).toString()
    );
  });

  const { t } = useTranslation();

  return (
    <AdminLayout>
      <TitleBar
        title={t("Guruhlarni tartiblanish jadvali")}
        createAction={false}
      />
      <div className="container">
        <TableHead />
        {data?.length > 0 ? (
          data.map((group, index) => (
            <ListItem key={group.id} group={group} index={index} />
          ))
        ) : (
          <EmptyContent />
        )}
        <PaginationComponent
          context={{ pagination, queryParams }}
          callback={paginateTo}
        />
      </div>
    </AdminLayout>
  );
}
