function TableHead() {
  return (
    <thead>
      <tr className="reports-table-head">
        <th>№</th>
        <th>To'lov nomlari</th>
        <th>To'lov turlari</th>
        <th>Umumiy summa</th>
        <th>Foiz</th>
      </tr>
    </thead>
  );
}

export default TableHead;
