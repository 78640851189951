import { create } from "zustand";
import { toastError, toastSuccess } from "../../Helpers/toasts";
import StudentsPaymentTransactionsRepository from "../../Repositories/CashControl/StudentsPaymentTransactionsRepository";

export const useStudentPaymentTransactions = create((set, get) => ({
  students: null,
  branches_list: null,
  teachers_list: null,
  payment_types: null,
  deletingId: false,
  openContextMenu: false,
  pagination: null,
  isLoading: false,
  startDate: null,
  total_income: 0,
  total_expense: 0,
  endDate: null,
  isShowDeleteLoader: false,
  queryParams: {
    page: 1,
  },
  setLoading: (status) => {
    set({
      isLoading: status,
    });
  },
  updateParams: (paramItem) => {
    set((prevState) => ({
      queryParams: { ...prevState.queryParams, ...paramItem },
    }));
  },
  onReload: async (queryString) => {
    const { data, status, nonFieldErrors } =
      await StudentsPaymentTransactionsRepository.studentsPayments(queryString);
    if (status) {
      set({
        students: data.data.results,
        branches_list: data.branches_list,
        teachers_list: data.teachers_list,
        payment_types: data.payment_modes,
        total_income: data.total_income,
        total_expense: data.total_expense,
        pagination: { pages_count: data.data.pages_count },
      });
    } else toastError(nonFieldErrors);
  },
  loadItems: async (queryString) => {
    const { data, status, nonFieldErrors } =
      await StudentsPaymentTransactionsRepository.studentsPayments(queryString);
    if (status) {
      set({
        students: data.data.results,
        pagination: { pages_count: data.data.pages_count },
        total_income: data.total_income,
        total_expense: data.total_expense,
      });
    } else toastError(nonFieldErrors);
  },
  deleteTransaction: async (id, payment_mode) => {
    const { loadItems, queryParams } = get();
    set({
      isShowDeleteLoader: true,
    });
    const queryString = new URLSearchParams({
      payment_mode: payment_mode.value,
    }).toString();
    const { status, nonFieldErrors } =
      await StudentsPaymentTransactionsRepository.deleteTransaction(
        id,
        queryString
      );
    if (status) {
      toastSuccess(nonFieldErrors);
      await loadItems(new URLSearchParams({ ...queryParams }).toString());
      set({
        deletingId: false,
        openContextMenu: false,
      });
    } else toastError(nonFieldErrors);
    set({
      isShowDeleteLoader: false,
    });
  },
}));
