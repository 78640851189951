import * as React from "react";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import NotificationsActiveRoundedIcon from "@mui/icons-material/NotificationsActiveRounded";
import { Alert, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getExamListWithParam } from "../actions";
import NotificationsOffRoundedIcon from "@mui/icons-material/NotificationsOffRounded";
import { useExamScheduleStore } from "../../../../Store/ManageGroupsStore/ExamScheduleStore";

const CostumAlert = styled(Alert)(({ show }) => ({
  display: show == "true" ? "flex" : "none",
  position: "absolute",
  left: "50px",
  bottom: "0px",
  width: "max-content",
  padding: "2px 10px",
  fontSize: "13px",
  lineHeight: "15px",
  alignItems: "center",
  zIndex: "99",
}));
const badge = {
  margin: "0 10px",
};

export default function ColorBadge() {
  const { unmarkedExamsCount, updateParams, queryParams, setLoading } =
    useExamScheduleStore();

  const [isHovered, setIsHovered] = React.useState(false);
  const [isCancel, setIsCancel] = React.useState(false);
  const [isClicked, setIsClicked] = React.useState(false);

  const handleChange = async () => {
    setIsClicked(true);
    setLoading(true);
    updateParams({ marked: false });
    await getExamListWithParam(
      new URLSearchParams({
        ...queryParams,
        marked: false,
      }).toString()
    );
    setLoading(false);
  };

  const handleCancel = async () => {
    setIsClicked(false);
    setLoading(true);
    updateParams({ marked: true });
    await getExamListWithParam(
      new URLSearchParams({
        ...queryParams,
        marked: true,
      }).toString()
    );
    setLoading(false);
  };

  return (
    unmarkedExamsCount > 0 &&
    (isClicked ? (
      <Stack
        sx={badge}
        onClick={handleCancel}
        onMouseEnter={() => setIsCancel(true)}
        onMouseLeave={() => setIsCancel(false)}
      >
        <CostumAlert severity="info" show={`${isCancel}`}>
          Barchasiga qaytish
        </CostumAlert>
        <IconButton aria-label="add to shopping cart">
          <Badge badgeContent={unmarkedExamsCount} color="warning">
            <NotificationsOffRoundedIcon color="action" />
          </Badge>
        </IconButton>
      </Stack>
    ) : (
      <Stack
        sx={badge}
        onClick={handleChange}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <CostumAlert severity="error" show={`${isHovered}`}>
          Imtixon baholanmaganlar
        </CostumAlert>
        <IconButton color="secondary" aria-label="add an alarm">
          <Badge badgeContent={unmarkedExamsCount} color="warning">
            <NotificationsActiveRoundedIcon color="action" />
          </Badge>
        </IconButton>
      </Stack>
    ))
  );
}
