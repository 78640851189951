import { create } from "zustand";
import { toastError } from "../../Helpers/toasts";
import StudentsBillingRepository from "../../Repositories/CashControl/StudentsBillingRepository";

export const useStudentsBillingStore = create((set, get) => ({
  students: null,
  checkbox_filterset: null,
  branches_list: null,
  teachers_list: null,
  statistics: null,
  pagination: null,
  isLoading: false,
  queryParams: {
    page: 1,
  },
  setLoading: (status) => {
    set({
      isLoading: status,
    });
  },

  updateParams: (paramItem) => {
    set((prevState) => ({
      queryParams: { ...prevState.queryParams, ...paramItem },
    }));
  },

  onReload: async (queryString) => {
    const { data, status, nonFieldErrors } =
      await StudentsBillingRepository.debtingStudents(queryString);
    if (status) {
      set({
        queryParams: {
          page: 1,
        },
        students: data.students,
        checkbox_filterset: data.checkbox_filterset,
        branches_list: data.branches_list,
        teachers_list: data.teachers_list,
        statistics: data.statistics,
        pagination: data.pagination,
      });
    } else toastError(nonFieldErrors);
  },

  loadItems: async (queryString) => {
    const { data, status, nonFieldErrors } =
      await StudentsBillingRepository.debtingStudents(queryString);
    if (status) {
      set({
        students: data.students,
        statistics: data.statistics,
        pagination: data.pagination,
      });
    } else toastError(nonFieldErrors);
  },
}));
