import { create } from "zustand";

export const useProfileConfigStore = create((set) => ({
  profile_details: null,
  finance: null,
  months: null,
  role_documents: null,
  age_groups: null,
  lesson_styles: null,
  materials: null,
  subjects: null,
  isUploading: false,
  queryParams: {},
  isLoadingMaterials: false,
  setUploading: (status) => set({ isUploading: status }),
  updateQueryParams: (month) => {
    set((state) => ({
      queryParams: { ...state.queryParams, month: month },
    }));
  },
}));
