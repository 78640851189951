import AuthenticationActions from "../Actions/AuthActions/AuthenticationActions";

export const authenticationReducer = (state, action) => {
  switch (action.type) {
    case AuthenticationActions.LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case AuthenticationActions.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};
