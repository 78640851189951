import { useFormik } from "formik";
import React, { useState } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useIncomeCashStore } from "../../../../Store/CashControlStore/IncomeCashStore";

const initialValues = {
  student_id: "",
};

export default function SearchSection() {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);
  const {
    getStudentShortLists,
    studentsShortLists,
    getListItemDetail,
    setLoading,
    resetData,
  } = useIncomeCashStore();

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      student_id: Yup.string(),
    }),
  });

  const handleInputChange = useCallback(async (event) => {
    setSelectedOptionIndex(-1);
    const query = event.target.value;
    formik.setFieldValue("student_id", query);
    setTimeout(async () => {
      await getStudentShortLists(
        new URLSearchParams({
          search_query: query,
        }).toString()
      );
    }, 100);
  }, []);

  const handleKeyDown = async (event) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      setSelectedOptionIndex((prevIndex) => {
        const newIndex = prevIndex + 1;
        return newIndex >= studentsShortLists?.length ? prevIndex : newIndex;
      });
    }
    if (event.key === "ArrowUp") {
      event.preventDefault();
      setSelectedOptionIndex((prevIndex) => {
        const newIndex = prevIndex - 1;
        return newIndex < 0 ? prevIndex : newIndex;
      });
    }
    if (event.key === "Enter") {
      const findItemWithIndex = studentsShortLists?.find(
        (item, index) => index === selectedOptionIndex
      );
      if (selectedOptionIndex > -1) {
        const name = `${findItemWithIndex?.first_name} ${findItemWithIndex?.last_name}`;
        formik.setFieldValue("student_id", name);
        useIncomeCashStore.setState({
          studentsShortLists: [],
        });
        resetData();
        setLoading(true);
        await getListItemDetail(findItemWithIndex.id);
        setLoading(false);
      }
    }
  };

  const listItemClick = useCallback(
    async (result) => {
      const name = `${result.first_name} ${result.last_name}`;
      formik.setFieldValue("student_id", name);
      useIncomeCashStore.setState({
        studentsShortLists: [],
      });
      resetData();
      setLoading(true);
      await getListItemDetail(result.id);
      setLoading(false);
    },
    [studentsShortLists]
  );
  const { t } = useTranslation();

  return (
    <div className="search-id" action="#">
      <div className="search-id__wrapper income-searchbar">
        <label htmlFor="student_id">
          {t("Ismi, Familiyasi yoki ID kodni kiriting")}
        </label>
        <div className="id_field icome-student_short_lists">
          <input
            name="student_id"
            id="search-id"
            className="modal-input search-id_input"
            type="text"
            autoComplete="off"
            value={formik.values.student_id}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          {studentsShortLists?.length > 0 && formik.values.student_id && (
            <ul className="students_list-transfer income__students-lists">
              {studentsShortLists?.map((result, index) => (
                <li
                  key={result.id}
                  onClick={() => listItemClick(result)}
                  className={
                    index === selectedOptionIndex ? "selected-option" : ""
                  }
                >
                  {result.first_name} {result.last_name} {result.middle_name}{" "}
                  {result.groups}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}
