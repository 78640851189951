import React from "react";
import PaymentSection from "./PaymentSection";
import SearchSectionContainer from "./SearchSectionContainer";

export default function ResultContainer() {
  return (
    <div className="transfer">
      <SearchSectionContainer />
      <PaymentSection />
    </div>
  );
}
