import { useContext, useState } from "react";
import SubInfo from "../../Components/SubInfo";
import SubjectToggleBox from "../../Components/SubjectToggleBox";
import SubStatusAll from "../../Components/SubStatusAll";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { MainGroupsContext } from "../../../../Contexts/GroupsContext/MainGroupsContext";
import { useTranslation } from "react-i18next";

function Statistics() {
  const [openField, setOpenField] = useState(false);
  const { mainGroups } = useContext(MainGroupsContext);
  const { t } = useTranslation();

  if (!mainGroups.statistics?.groups) {
    return null;
  }

  const renderGroup = (group, index) => (
    <div key={index}>
      <SubjectToggleBox group={group} />
    </div>
  );

  return (
    <div
      className={`group_sub_header${openField ? "" : " group_modal_hidden"}`}
    >
      <div
        className="group_modal_icon"
        onClick={() => setOpenField(!openField)}
      >
        <h4>{t("Barcha guruhlar haqida")}</h4>
        <MenuOpenIcon />
      </div>
      <div className="subject-box">
        {mainGroups.statistics.groups.map(renderGroup)}
      </div>
      <div className="subject-info">
        <div className="subject-line">
          {mainGroups.statistics.groups.map((group, index) => (
            <SubInfo data={group} key={index} />
          ))}
        </div>
        <div className="subject-stats">
          <SubStatusAll data={mainGroups.statistics.grand_total_students} />
        </div>
      </div>
    </div>
  );
}

export default Statistics;
