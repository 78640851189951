import React from "react";
import { useTranslation } from "react-i18next";

export default function TableHead() {
  const { t } = useTranslation();

  return (
    <ul className="exam_table_head  ">
      <li className="exam_head_item">№</li>
      <li className="exam_head_item">{t("Guruhi")}</li>
      <li className="exam_head_item">{"subject"}</li>
      <li className="exam_head_item">{t("Yosh turi")}</li>
      <li className="exam_head_item">{t("O'qituvchilari")}</li>
      <li className="exam_head_item">{t("Dars vaqtlari")}</li>
      <li className="exam_head_item">{t("Imtixon oyi")}</li>
      <li className="exam_head_item">{t("Imtihon so'rovi")}</li>
      <li className="exam_head_item">{t("Imtixon sana")}</li>
      <li className="exam_head_item">{t("Baholangan sana")}</li>
    </ul>
  );
}
