import { useTranslation } from "react-i18next";
import { toastError, toastSuccess } from "../../../../../Helpers/toasts";
import useRefundRepository from "../../../../../Repositories/CashControl/RefundRepository";
import { useRefundStore } from "../../../../../Store/CashControlStore/RefundStore";
import ConfirmationModal from "../../../Components/ConfirmationModal";
import GroupsContainer from "./GroupsContainer";
import StudentSection from "./StudentSection";

export default function Refund() {
  const { salary, confirmation } = useRefundStore();
  const { t } = useTranslation();

  const handleSubmit = async () => {
    const response = await useRefundRepository.expenditure(salary);
    if (response.status) {
      toastSuccess(t("Pul qaytarildi"));
      useRefundStore.setState({
        confirmation: false,
        salary: null,
        lesson_months: null,
        studentDetails: null,
        groupDetails: null,
      });
    } else toastError(response.nonFieldErrors);
  };

  const cancelCallback = () => {
    useRefundStore.setState({
      confirmation: false,
    });
  };
  return (
    <div className="transfer salary-transfer">
      <div className="transfer__item">
        <div className="transfer-info transfer-info_huge salary-advance__item">
          <StudentSection />
        </div>
      </div>
      <div className="transfer__item">
        <div className="transfer-info transfer-info_huge salary-advance__item">
          <GroupsContainer />
        </div>
      </div>
      <ConfirmationModal
        confirmationCallback={() => handleSubmit()}
        cancelCallback={() => cancelCallback()}
        showModal={confirmation}
        title={t("To'lovni tasdiqlaysizmi?")}
      />
    </div>
  );
}
