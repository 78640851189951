import axiosInstance from "../AxiosInstance";
export default class CourseTemplateRepository {
  static async getAllCourses(queryString = "") {
    let result = {
      status: false,
      data: null,
    };

    const response = await axiosInstance.get(
      "/management/course/?with_course_prices=true&" + queryString
    );
    if (response.status === 200) {
      result = { ...result, status: true, data: response.data };
    }
    return result;
  }
  static async createCourse(data) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInstance.post("/management/course/", data);
      if (response.status === 201) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      if (e.response.data.data) {
        result = {
          ...result,
          nonFieldErrors: e.response.data.data.non_field_errors[0],
        };
      } else
        result = {
          ...result,
          nonFieldErrors: "Xatolik! Bunday turdagi dars soatlari mavjud!",
        };
    }
    return result;
  }
  static async updateCourse(data, id) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInstance.patch(
        `/management/course/${id}/`,
        data
      );

      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (error) {
      result = {
        ...result,
        nonFieldErrors: "Kursni yangilab bo'lmadi!",
      };
    }
    return result;
  }
  static async deleteCourse(id) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInstance.delete(`/management/course/${id}`);
      if (response.status === 204) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {}
    return result;
  }
  static async getCourseById(id) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInstance.get(
        `/management/course/${id}/?with_course_prices=true&`
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (error) {
      result = {
        ...result,
        nonFieldErrors: "Xatolik! Bunday turdagi ma'lumot imavjud!",
      };
    }
    return result;
  }
}
