import React from "react";
import Select from "react-select";
import { useProfileConfigStore } from "../../../../Store/ProfileConfiguration/ProfileConfigStore";
import { getExamListWithParam } from "../../../Groups/ExamSchedule/actions";
import { loadItems } from "../actions";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "40px",
    minHeight: "40px",
    width: "200px",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: "400",
  }),
};

export default function Selector({ options, property }) {
  const { queryParams } = useProfileConfigStore();

  const handleSelectChange = async (e) => {
    if (property === "Fanlar") {
      useProfileConfigStore.setState((state) => ({
        queryParams: { ...state.queryParams, subject: e.value },
      }));
      await loadItems({ ...queryParams, ...{ subject: e.value } });
    }
    if (property === "Guruh turi") {
      useProfileConfigStore.setState((state) => ({
        queryParams: { ...state.queryParams, age_group: e.value },
      }));
      await loadItems({ ...queryParams, ...{ age_group: e.value } });
    }
    if (property === "Dars uslubi") {
      useProfileConfigStore.setState((state) => ({
        queryParams: { ...state.queryParams, lesson_style: e.value },
      }));
      await loadItems({ ...queryParams, ...{ lesson_style: e.value } });
    }
  };

  return (
    <Select
      className="basic-single "
      classNamePrefix="select"
      placeholder={property}
      styles={customStyles}
      options={options}
      onChange={handleSelectChange}
    />
  );
}
