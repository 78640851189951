import React from "react";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
import { useStudentTransferStore } from "../../../../Store/GroupManagement/StudentTransferStore";
import { handleSubmit } from "../actions";

export default function SubmitButton() {
  const { student, from_group, to_group, isSubmitLoading, comment } =
    useStudentTransferStore();

  const data = {
    student: student,
    to_group: to_group,
    from_group: from_group,
    comment: comment || "",
  };
  const { t } = useTranslation();
  return (
    <button className="transfer-submit" onClick={() => handleSubmit(data)}>
      {isSubmitLoading ? (
        <ReactLoading
          type={"spin"}
          color="#ffffff"
          className="delete_loader"
          height={"20px"}
          width={"20px"}
        />
      ) : (
        t(`O‘tkazish amaliyotni bajarish`)
      )}
    </button>
  );
}
