import React from "react";
import { TextField } from "@mui/material";
import { useStudentTransferStore } from "../../../../Store/GroupManagement/StudentTransferStore";
import { useTranslation } from "react-i18next";

const textarea = {
  borderRadius: "10px",
  margin: "0",
};
const labelStyle = {
  fontSize: "15px",
};
const TextArea = () => {
  const { comment } = useStudentTransferStore();

  const handleChange = (event) => {
    useStudentTransferStore.setState({
      comment: event.target.value,
    });
  };
  const { t } = useTranslation();

  return (
    <TextField
      label={t("Izoh")}
      InputLabelProps={{ style: labelStyle }}
      multiline
      rows={3}
      id="outlined-basic"
      variant="filled"
      size="small"
      value={comment || ""}
      onChange={handleChange}
      fullWidth
      sx={textarea}
    />
  );
};

export default React.memo(TextArea);
