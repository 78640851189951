import React from "react";
import { useTranslation } from "react-i18next";
import { useIncomeCashStore } from "../../../../Store/CashControlStore/IncomeCashStore";
import Discounts from "./Discounts";

export default function GroupDetails({ details, monthDetails }) {
  const { acceptPayment, updateAcceptPayment, student_details } =
    useIncomeCashStore();

  const calculateAmount = () => {
    const calculateFormattedValue = (value) => {
      const stringValue = String(value).replace(/ /g, "");
      return stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    let total_cost = 0;
    if (acceptPayment.discounts.length > 0) {
      total_cost = acceptPayment.discounts.reduce(
        (accumulator, element) => accumulator + (element?.amount || 0),
        0
      );
    }

    const finalTotalCost = monthDetails?.cost - total_cost;

    const formattedTotalCost = calculateFormattedValue(finalTotalCost);
    updateAcceptPayment("amountPlaceholder", formattedTotalCost);
    updateAcceptPayment("amount", String(finalTotalCost).replace(/ /g, ""));
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="student-details-group transfer-info">
        <div className="transfer-info__item">
          <div className="transfer-infos">{t("O’qiyotgan guruhi")} :</div>
          <div className="transfer-infos">
            <span>{details?.number}</span>
          </div>
        </div>
        <div className="transfer-info__item">
          <div className="transfer-infos">{t("O‘qituvchisi")} :</div>
          <div className="transfer-infos">{details?.teachers}</div>
        </div>
        <div className="transfer-info__item">
          <div className="transfer-infos">{t("Oldingi oylardan qarzi")}</div>
          <div className="transfer-infos">
            {details?.previous_months?.cost > 0 ? (
              <span className="income__debt--color">
                {details?.previous_months?.cost} so'm
              </span>
            ) : (
              <span>{t("Qarzi yo'q")}</span>
            )}
          </div>
        </div>
      </div>
      <h1 className="income__detail--title">{t("Hozirgi o'qiyotgan oyi")}</h1>
      <div className="student-details-group transfer-info">
        <div className="transfer-info__item">
          <div className="transfer-infos">{t("O’qiyotgan fani")} :</div>
          <div className="transfer-infos">
            <span>{monthDetails?.subject}</span>
          </div>
        </div>
        <div className="transfer-info__item">
          <div className="transfer-infos">{t("Oy")} :</div>
          <div className="transfer-infos">
            <span>{monthDetails?.name}</span>
          </div>
        </div>
        <div className="transfer-info__item">
          <div className="transfer-infos">{t("Jami darslar soni")} :</div>
          <div className="transfer-infos">
            <span>{monthDetails?.total_lessons} ta</span>
          </div>
        </div>
        <div className="transfer-info__item">
          <div className="transfer-infos">{t("Qatnashgan darslar soni")} :</div>
          <div className="transfer-infos">
            <span>{monthDetails?.attended_lessons_count} ta</span>
          </div>
        </div>
        <div className="transfer-info__item">
          <div className="transfer-infos">
            {t("Qatnashgan darslar narxi")} :
          </div>
          <div className="transfer-infos">
            <span>{monthDetails?.attended_lessons_cost} so'm</span>
          </div>
        </div>
        <div className="transfer-info__item">
          <div className="transfer-infos">
            {t("Sababsiz kelmagan darslar soni")} :
          </div>
          <div className="transfer-infos">
            <span>{monthDetails?.missed_lessons_count} ta</span>
          </div>
        </div>
        <div className="transfer-info__item">
          <div className="transfer-infos">
            {t("Sababli qoldirgan darslar soni")} :
          </div>
          <div className="transfer-infos">
            <span>{monthDetails?.reasonable_missed_lessons_count} ta</span>
          </div>
        </div>
        <div className="transfer-info__item">
          <div className="transfer-infos">
            {t("Sababli qoldirgan darslar narxi")} :
          </div>
          <div className="transfer-infos">
            <span>{monthDetails?.reasonable_missed_lessons_cost} so'm</span>
          </div>
        </div>
        <div className="transfer-info__item">
          <div className="transfer-infos">{t("Bitta dars narxi")} :</div>
          <div className="transfer-infos">
            <span>{monthDetails?.lesson_price} so'm</span>
          </div>
        </div>
        <div className="transfer-info__item">
          <div className="transfer-infos">{t("Ushbu oy narxi")} :</div>
          <div className="transfer-infos">
            <span>{monthDetails?.price} so'm</span>
          </div>
        </div>
        <div className="transfer-info__item">
          <div className="transfer-infos income__debt--color">
            {t("To'lanishi kerak bo'lgan narx")} :
          </div>
          <div className="transfer-infos income__debt--color">
            <span>{monthDetails?.cost} so'm</span>
          </div>
        </div>
        {/* <div className="transfer-info__item">
          <div className="transfer-infos income__debt--color">
            Jami qarzdorlik :
          </div>
          <div className="transfer-infos income__debt--color">
            <span>{monthDetails?.total_cost} so'm</span>
          </div>
        </div> */}
      </div>
      {details?.discounts.length > 0 && (
        <Discounts discounts={details?.discounts} />
      )}
      <div className="discountBtns payment-place--space">
        <button
          onClick={() => calculateAmount()}
          type="submit"
          className="saveBtn income__save--btn"
        >
          {t("Yakuniy qarzdorlikni belgilash")}
        </button>
      </div>
    </>
  );
}
