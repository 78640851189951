import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useCallback } from "react";
import Searchbar from "../../../../Components/Searchbar";
import { getExamListWithParam } from "../actions";
import ColorBadge from "./NotificationIcon";
import TableHead from "./TableHead";
import { useExamScheduleStore } from "../../../../Store/ManageGroupsStore/ExamScheduleStore";
import DateSection from "./DateSection";
import InfoModule from "../../../../Components/InfoModule";
import FilterBlock from "./FilterBlock";
import { useTranslation } from "react-i18next";

function Heading() {
  const { updateParams, total_exams_count, queryParams, setLoading } =
    useExamScheduleStore();
  const { t } = useTranslation();

  const searchAction = useCallback(
    async (payload) => {
      updateParams({ search_query: payload.term });
      setLoading(true);
      await getExamListWithParam(
        new URLSearchParams({
          ...queryParams,
          search_query: payload.term,
        }).toString()
      );
      setLoading(false);
    },
    [queryParams]
  );

  const initialValues = {
    term: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      term: Yup.string().min(1, t("Qidiruvni kiriting!")),
    }),
    onSubmit: (values) => {
      searchAction({
        term: values.term,
      });
    },
  });

  return (
    <div className="build-search exam_heading">
      <div className="exam_input_field">
        <ColorBadge />
        <InfoModule title={total_exams_count || 0} />
        <FilterBlock />
        <Searchbar
          value={formik.values.term}
          handleChange={formik.handleChange}
          handleSubmit={formik.handleSubmit}
        />
        {/* <MonthSelector param={monthsOptions} property="Oylar" />
        <MonthSelector param={subjectsOptions} property="Fanlar" />
        <MonthSelector param={teachersOptions} property="O'qituvchilar" />
        <MonthSelector param={ageGroupOptions} property="Guruh turlari" /> */}
        <DateSection />
      </div>
      <TableHead />
    </div>
  );
}
export default React.memo(Heading);
