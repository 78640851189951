import { axiosInterceptor } from "../AxiosInterceptor";

export default class ComparisionStaffsRepository {
  static async examsComparison(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        "/reports/exams-comparison/teachers/?" + queryString
      );
      if (response.status === 200) {
        result = { ...result, status: response.data.ok, data: response.data };
      }
    } catch (e) {
      result = { ...result, nonFieldErrors: "Ma'lumot olib bo'lmadi" };
    }
    return result;
  }
  static async getMonths(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        "/reports/exams-comparison/months/?" + queryString
      );
      if (response.status === 200) {
        result = { ...result, status: response.data.ok, data: response.data };
      }
    } catch (e) {
      result = { ...result, nonFieldErrors: "Ma'lumot olib bo'lmadi" };
    }
    return result;
  }
  static async getGroups(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        "/reports/exams-comparison/groups/?" + queryString
      );
      if (response.status === 200) {
        result = { ...result, status: response.data.ok, data: response.data };
      }
    } catch (e) {
      result = { ...result, nonFieldErrors: "Ma'lumot olib bo'lmadi" };
    }
    return result;
  }
  static async getStatistics(data) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.post(
        "/reports/exams-comparison/",
        data
      );
      if (response.status === 200) {
        result = { ...result, status: response.data.ok, data: response.data };
      }
    } catch (e) {
      result = { ...result, nonFieldErrors: "Ma'lumot olib bo'lmadi" };
    }
    return result;
  }
}
