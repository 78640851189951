import * as React from "react";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { useTrelloStore } from "../../../Store/Trello/TrellosStore";
import PersonIcon from "@mui/icons-material/Person";

export default function GroupAvatars() {
  const { staffs_filter_list, staff_ids, loadItems } = useTrelloStore();
  const colors = [
    "orange",
    "teal",
    "crimson",
    "deeppink",
    "greenyellow",
    "purple",
  ];

  const handleChange = React.useCallback(
    async (id) => {
      const find = staff_ids?.includes(id);
      const filter = staff_ids?.filter((item) => item !== id);
      useTrelloStore.setState({
        staff_ids: find ? filter : [...staff_ids, id],
      });
      {
        filter.length || staff_ids.length == 0 > 0
          ? await loadItems(
              new URLSearchParams({
                staff_ids: find ? filter : [...staff_ids, id],
              }).toString()
            )
          : await loadItems();
      }
    },
    [staff_ids]
  );

  return (
    <AvatarGroup
      max={10}
      sx={{
        marginRight: "24px",
        "@media (max-width: 600px)": {
          marginRight: "0",
        },
      }}
    >
      {staffs_filter_list?.map((item, index) => {
        const avatarBgColor = colors[index % colors.length];
        return (
          <Avatar
            onClick={() => handleChange(item.id)}
            key={item.id}
            alt={item.fullname}
            src={item.photo}
            sx={{
              width: "35px",
              height: "35px",
              fontSize: "13px",
              cursor: "pointer",
              bgcolor:
                item.short && !staff_ids?.includes(item?.id)
                  ? avatarBgColor
                  : "none",
              "@media (max-width: 600px)": {
                width: "30px",
                height: "30px",
                fontSize: "12px",
              },
            }}
          >
            {item.short ?? <PersonIcon />}
          </Avatar>
        );
      })}
    </AvatarGroup>
  );
}
