import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AdminLayout from "../../../AdminLayout";
import TitleBar from "../../../Components/TitleBar";
import { useSalaryCalculationStore } from "../../../Store/CashControlStore/SalaryCalculationStore";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import UserPermissionWidget from "../../../UserPermissionWidget";
import ConfirmationModal from "../Components/ConfirmationModal";
import OutlinedButtons from "./components/Buttons";
import Table from "./components/Table";

export default function SalaryCalculations() {
  const {
    saveDetails,
    onReload,
    confirmation,
    staff_list,
    year,
    month,
    calculation,
    as_draft,
  } = useSalaryCalculationStore();
  const { setLoader } = useLoaderStore();
  const param = useParams();

  const pageLoad = useCallback(async () => {
    const queryParams =
      param.id == "null"
        ? { year: param.year, month: param.month }
        : { calculation_id: param.id };
    const queryString = new URLSearchParams({ ...queryParams }).toString();
    setLoader(true);
    await onReload(queryString);
    setLoader(false);
  }, []);

  useEffect(() => {
    pageLoad();
  }, []);

  const handleSubmit = async () => {
    const data = {
      as_draft: as_draft,
      year: year,
      month: month,
      calculation: calculation,
      staff_list: staff_list,
    };
    await saveDetails(data);
  };
  const cancelCallback = () => {
    useSalaryCalculationStore.setState({
      confirmation: false,
    });
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const { t } = useTranslation();

  return (
    <AdminLayout>
      <TitleBar title={t("Xodimlarga oylik ishlash")} />
      <div className="container">
        <Table />
        {staff_list?.length && param.id == "null" && <OutlinedButtons />}
      </div>
      <UserPermissionWidget
        hasPermission={user?.permissions?.save_monthly_salary_calculation}
      >
        <ConfirmationModal
          confirmationCallback={() => handleSubmit()}
          cancelCallback={() => cancelCallback()}
          showModal={confirmation}
          title={t("Ma'lumotlar saqlansinmi?")}
        />
      </UserPermissionWidget>
    </AdminLayout>
  );
}
