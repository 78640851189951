export default class MainGroupsActions {
  static CREATE_BUTTON_CLICKED = "CREATE_BUTTON_CLICKED";
  static ADD_QUERY_PARAM = "ADD_QUERY_PARAM";
  static PAGE_LOADED = "PAGE_LOADED";
  static TOGGLE_CONTEXT_MENU = "TOGGLE_CONTEXT_MENU";
  static CLOSE_CONTEXT_MENU = "CLOSE_CONTEXT_MENU";
  static DELETE_BUTTON_CLICKED = "DELETE_BUTTON_CLICKED";
  static CANCEL_BUTTON_CLICKED = "CANCEL_BUTTON_CLICKED";
  static UPDATE_BUTTON_CLICKED = "UPDATE_BUTTON_CLICKED";
  static TOGGLE_FILTER_LIST = "TOGGLE_FILTER_LIST";
  static CLOSE_FILTER_LIST = "CLOSE_FILTER_LIST";
  static TOGGLE_CATEGORIES = "TOGGLE_CATEGORIES";
  static CLOSE_CATEGORIES = "CLOSE_CATEGORIES";
  static SET_CURRENT_SUBJECT = "SET_CURRENT_SUBJECT";
  static SET_CURRENT_AGE_GROUP = "SET_CURRENT_AGE_GROUP";
  static SET_CURRENT_DURATIONS = "SET_CURRENT_DURATIONS";
  static SET_START_MONTH = "SET_START_MONTH";
  static SHOW_DELETE_LOADER = "SHOW_DELETE_LOADER";
  static CATEGORY_LIST = "CATEGORY_LIST";
  static BRANCH_ROOMS0 = "BRANCH_ROOMS0";
  static BRANCH_ROOMS1 = "BRANCH_ROOMS1";
  static RESET_MODAL_STATE = "RESET_MODAL_STATE";
  static TIMES0 = "TIMES0";
  static TIMES1 = "TIMES1";
  static FILTER_MODAL_RELOAD = "FILTER_MODAL_RELOAD";
  static TOGGLE_LIST_TABLE = "TOGGLE_LIST_TABLE";
}
