import React from "react";

export default function InputField({ name, label, formik }) {
  return (
    <div className="modal-input__item">
      <input
        id={name}
        name={name}
        value={formik.values[name] || ""}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder={label}
        className={
          formik.touched[name] && formik.errors[name]
            ? "modal-input error_input"
            : "modal-input"
        }
        type="text"
      />
      {formik.touched[name] && formik.errors[name] ? (
        <p className="input-error-message modal_error_value">
          {formik.errors[name]}
        </p>
      ) : null}
    </div>
  );
}
