import React from "react";
import { useCallback } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dateRangeFormat } from "../../../../Helpers/dateFormat";
import { useExamFiledStudentsStore } from "../../../../Store/ReportsStore/ExamFiledStudentsStore";

const DateSection = () => {
  const {
    queryParams,
    updateParams,
    startDate,
    endDate,
    loadItems,
    setLoading,
  } = useExamFiledStudentsStore();

  const dateHandler = useCallback(
    async (date) => {
      updateParams({ start_date: dateRangeFormat(date[0]), page: 1 });
      updateParams({ end_date: dateRangeFormat(date[1]), page: 1 });
      useExamFiledStudentsStore.setState({
        startDate: date[0],
        endDate: date[1],
      });
      if ((date[0] && date[1]) || (!date[0] && !date[1])) {
        setLoading(true);
        await loadItems(
          new URLSearchParams({
            ...queryParams,
            start_date: dateRangeFormat(date[0]) ?? "",
            end_date: dateRangeFormat(date[1]) ?? "",
            page: 1,
          }).toString()
        );
        setLoading(false);
      }
    },
    [queryParams]
  );

  return (
    <DatePicker
      isClearable
      clearButtonClassName="date_clear-btn"
      className="payment-datepicker"
      placeholderText="mm/dd/yy - mm/dd/yy"
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={(date) => dateHandler(date)}
    />
  );
};
export default React.memo(DateSection);
