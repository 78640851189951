import React, { useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import SideBarActions from "../../Actions/ComponentsActions/SideBarActions";
import { SideBarContext } from "../../Contexts/SideBarContext";

export default function SideBarMenuSubItem({ title, link }) {
  const { dispatchSideBar } = useContext(SideBarContext);

  const closeSideBar = useCallback(() => {
    dispatchSideBar({ type: SideBarActions.CLOSE });
  });

  return (
    <li className="menu-dropdown__item" onClick={()=>closeSideBar()}>
      <Link to={link}>{title}</Link>
    </li>
  );
}
