import { create } from "zustand";
import { toastError } from "../../Helpers/toasts";
import ExpenditureCashRepository from "../../Repositories/CashControl/ExpendatureCashRepository";

export const useExpendatureCashStore = create((set, get) => ({
  categories: null,
  branch_staffs: null,
  payment_types: null,
  expenditureTypes: null,
  confirmation: false,
  fixedCosts: {
    category: null,
    amountPlaceholder: null,
    amount: null,
    payment_type: null,
    expenditure: null,
    comment: null,
    reasonponsible_staff: null,
  },
  updateFixedCosts: (property, value) => {
    set((prevState) => ({
      fixedCosts: { ...prevState.fixedCosts, [property]: value },
    }));
  },
  onReload: async () => {
    const { data, status, nonFieldErrors } =
      await ExpenditureCashRepository.getDetails();
    if (status) {
      set({
        categories: data.categories,
        branch_staffs: data.branch_staffs,
        payment_types: data.payment_types,
      });
    } else toastError(nonFieldErrors);
  },
}));
