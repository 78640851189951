import { useCallback, useContext } from "react";
import CourseTemplateActions from "./Actions/ConfigActions/CourseTemplateActions";
import Header from "./Components/Header/Header";
import BasicLoader from "./Components/Loader/basic loader/BasicLoader";
import SideBar from "./Components/SideBar/SideBar";
import { CourseTemplateContext } from "./Contexts/ConfigContext/CourseTemplateContext";
import { LoaderContext } from "./Contexts/LoaderContext";
import { SideBarContext } from "./Contexts/SideBarContext";
import { useLoaderStore } from "./Store/Loader/LoaderStore";

export default function AdminLayout({ children }) {
  const { sideBar } = useContext(SideBarContext);
  const { dispatchCourseTemplate } = useContext(CourseTemplateContext);
  const { loader } = useContext(LoaderContext);
  const { isLoading } = useLoaderStore();

  const closeFilterBlock = useCallback(() => {
    dispatchCourseTemplate({ type: CourseTemplateActions.CLOSE_EXPORT_BLOCK });
  }, []);

  return (
    <>
      {loader.isLoading || isLoading ? <BasicLoader /> : null}
      <div className="content" onClick={() => closeFilterBlock()}>
        <SideBar />
        <Header />
        <div
          className={
            sideBar.open === true ? "content-item menu-move" : "content-item"
          }
        >
          {children}
        </div>
      </div>
    </>
  );
}
