import { useAttendanceStore } from "../../../../Store/AttendanceFormStore/AttendanceForm";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Link } from "react-router-dom";
import UserPermissionWidget from "../../../../UserPermissionWidget";
import { dateFormat } from "../../../../Helpers/dateFormat";
import { useTranslation } from "react-i18next";
export default function TableHeading({ groupId }) {
  const { data } = useAttendanceStore();
  const user = JSON.parse(localStorage.getItem("user"));
  const { t } = useTranslation();

  return (
    <div className="desk-exam-config">
      <div className="desk-config__item stu-num">№</div>
      <div className="desk-config__item stu-fullname">{t("Ism familyasi")}</div>
      <div className="desk-config__item stu-idnumber">{t("ID raqami")}</div>
      <div className="desk-config__item stu-age">Age</div>
      {data?.estimated_dates?.map((element, index) => {
        return (
          <div key={index} className="desk-config__item desk-week">
            {element?.days?.length > 0 && (
              <div className="desk-week-text desk-week-text_one">
                {element.name}
              </div>
            )}
            <div className="dateContainerHeader">
              {element.days.map((item, index) => {
                return (
                  <div key={index} className="date-lesson">
                    <div
                      className={
                        item.is_current
                          ? "desk-week-date current_date"
                          : item.is_passed
                          ? "desk-week-date passed_date"
                          : "desk-week-date"
                      }
                    >
                      {item.date}
                    </div>
                    <div className="desk-week-lesson desk-week-lesson_1">
                      {item.is_current && !item.is_passed ? (
                        <UserPermissionWidget
                          hasPermission={
                            user?.permissions?.update_group_attendance
                          }
                        >
                          <Link
                            to={`/groups/${groupId}/attendance/${item.date}`}
                          >
                            <AddCircleIcon />
                          </Link>
                        </UserPermissionWidget>
                      ) : item.is_passed ? (
                        item.verbose_name
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      {data?.taken_exams.length > 0 && (
        <div className="desk-config__item desk-week desk-exam">
          <div className="desk-week-text desk-week-text_one">
            {t("Imtihon")}
          </div>
          <div style={{ width: "100%" }}>
            <div className="desk-week-date exam_date">
              {dateFormat(data?.exam_date)}
            </div>
          </div>
          <div className="desk_exam_type ">
            {data?.taken_exams?.map((item) => {
              return (
                <div
                  className="desk-week-lesson desk-week-lesson_1"
                  key={item.key}
                >
                  {item.verbose_name}
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="desk-config__item payment-status">Smart cards</div>
      {data?.taken_exams.length > 0 ? (
        <div className="desk-config__item payment-status">
          {t("O'rtacha ball")}
        </div>
      ) : null}
      <div className="desk-config__item payment-status">{t("Chegirma")}</div>
      <div className="desk-config__item payment-status">
        {t("To'lov holati")}
      </div>
    </div>
  );
}
