import React, { createContext, useReducer } from "react";
import { languageReducer } from "../Reducers/LanguageReducers";

export const LanguageContext = createContext();

const initialState = {
  current: {
    code: "uz",
    title: "O'zbek",
  },
  other: [
    {
      code: "en",
      title: "English",
    },
  ],
  isOpen: false,
  isOpenProfile: false,
};

export const LANGUAGES = { ...initialState };

const LanguageProvider = (props) => {
  const [language, dispatchLanguage] = useReducer(languageReducer, {
    ...initialState,
  });

  return (
    <LanguageContext.Provider value={{ language, dispatchLanguage }}>
      {props.children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
