import { create } from "zustand";
import { toastError } from "../../Helpers/toasts";
import ExpenditureReportRepository from "../../Repositories/Reports/ExpenditureReportsRepository";

export const useExpenditureReportsStore = create((set) => ({
  data: null,
  months: null,
  years: null,
  isLoading: false,
  startDate: null,
  endDate: null,
  setLoading: (status) => {
    set({
      isLoading: status,
    });
  },
  queryParams: {},
  updateParams: (paramItem) => {
    set((prevState) => ({
      queryParams: { ...prevState.queryParams, ...paramItem },
    }));
  },
  onReload: async (queryString) => {
    const { data, status, nonFieldErrors } =
      await ExpenditureReportRepository.getExpenditures(queryString);
    if (status) {
      set({
        data: data.data,
        months: data.months,
        years: data.years,
      });
    } else toastError(nonFieldErrors);
  },
  loadItems: async (queryString) => {
    const { data, status, nonFieldErrors } =
      await ExpenditureReportRepository.getExpenditures(queryString);
    if (status) {
      set({
        data: data.data,
      });
    } else toastError(nonFieldErrors);
  },
}));
