import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";

export default function DeleteConfirmation({
  deleteCallback,
  cancelCallback,
  showModal,
}) {
  const { t } = useTranslation();
  const handleDelete = async (e) => {
    await deleteCallback(e);
  };

  const modalClass = showModal.deletingId
    ? "modal modal-select active"
    : "modal modal-select";

  return (
    <div id="modal" className={modalClass}>
      <form
        action=""
        className="modal-wrapper"
        style={{ width: "480px" }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-inner red-border">
          <h4 className="modal__title red-background">{t("confirm")}</h4>
          <p>{t("agree_delete")}</p>
        </div>
        <div className="input-buttons">
          <button
            id="modalClose"
            type="button"
            className="input-btn"
            onClick={cancelCallback}
          >
            {t("cancel")}
          </button>
          <button
            className="input-btn input-btn--submit red-background"
            type="submit"
            disabled={showModal.isShowDeleteLoader}
            onClick={handleDelete}
          >
            {showModal.isShowDeleteLoader ? (
              <ReactLoading
                type={"spin"}
                color="#ffffff"
                className="delete_loader"
                height={"30px"}
                width={"30px"}
              />
            ) : (
              `${t("delete")}`
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
