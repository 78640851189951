import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useReadyReportStore } from "../../../../Store/ReportsStore/ReadyReportStore";

export default function BasicButtons() {
  const { filterset, updateParams, queryParams, loadItems, setLoading } =
    useReadyReportStore();

  const handleClick = async (days) => {
    setLoading(true);
    updateParams({
      days: days == queryParams.days ? "" : days,
    });
    await loadItems(
      new URLSearchParams({
        ...queryParams,
        days: days == queryParams.days ? "" : days,
      }).toString()
    );
    setLoading(false);
  };

  const WeekButton = styled(Button)(({ isActive }) => ({
    boxShadow: "none",
    borderRadius: "5px",
    textTransform: "none",
    fontSize: 13,
    padding: "5px 20px",
    minWidth: "200px",
    border: "1px solid",
    color: isActive ? "white" : "black",
    backgroundColor: isActive ? "#3B9AFD" : "#fff",
    borderColor: "#3B9AFD",
    fontFamily: "Inter",
    "&:hover": {
      transition: "all 0s",
      background: isActive
        ? "#3B9AFD"
        : "linear-gradient(91.68deg, #45a1ff 0%, #0176ee 100%),linear-gradient(137.64deg, #e8eff4 0%, #e6ecf0 97.97%) #c4c4c4",
      borderColor: "#3B9AFD",
      boxShadow: "none",
      color: "white",
    },
    "&:active": {
      backgroundColor: "#3B9AFD",
      borderColor: "#3B9AFD",
      boxShadow: "none",
      color: "white",
    },
  }));

  return (
    <Stack direction="row" gap={2}>
      {filterset?.days?.map((item) => {
        const isActive = item.days === queryParams.days;
        return (
          <WeekButton
            onClick={() => handleClick(item.days)}
            key={item.days}
            variant="outlined"
            isActive={isActive}
          >
            {item.name}
          </WeekButton>
        );
      })}
    </Stack>
  );
}
