import { useTranslation } from "react-i18next";

export default function Searchbar({
  value,
  handleChange,
  handleSubmit,
  minWidth = false,
}) {
  const onSubmit = (event) => {
    event.preventDefault();
    handleSubmit();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };
  const { t } = useTranslation();
  return (
    <div className={`search_bar_form ${minWidth ? "search_minWidth" : ""}`}>
      <input
        id="search"
        className="modal-input search_bar"
        type="text"
        onChange={handleChange}
        value={value}
        name="term"
        placeholder={t("Qidiriv...")}
        onKeyDown={handleKeyDown}
      />
      <img src="/images/search.svg" alt="" onClick={onSubmit} />
    </div>
  );
}
