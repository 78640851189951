import { useTranslation } from "react-i18next";
import { useExpenditureReportsStore } from "../../../../Store/ReportsStore/ExpenditureReportsStore";

function ExtraTable() {
  const { data } = useExpenditureReportsStore();
  const { t } = useTranslation();

  return (
    <table border={"1px"} className="reports-table">
      <thead>
        <tr className="reports-extra-table-head">
          <th>№</th>
          <th>{t("Xarajatlar nomi")}</th>
          <th>{t("Naqd")}</th>
          <th>{t("Click")}</th>
          <th>{t("Bank")}</th>
          <th>{t("Jami")}</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((row, index) => (
          <tr
            key={index}
            className="total-expenditure  reports-extra-table-body"
          >
            <td>{index + 1}</td>
            <td>{row.name}</td>
            <td>{row.cash}</td>
            <td>{row.online_transaction}</td>
            <td>{row.terminal}</td>
            <td>{row.total}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default ExtraTable;
