import { create } from "zustand";
import { toastError } from "../../Helpers/toasts";
import SalaryAdvanceRepository from "../../Repositories/CashControl/SalaryAdvanceRepository";

export const useSalaryAdvanceStore = create((set, get) => ({
  staffs: null,
  details: null,
  data: null,
  selectedMonth: null,
  payment_types: null,
  select_value: null,
  salary: {},
  calculated: false,
  isLoading: false,
  confirmation: false,
  setLoading: (status) => {
    set({
      isLoading: status,
    });
  },
  updateSalary: (item) => {
    set((prevState) => ({
      salary: { ...prevState.salary, ...item },
    }));
  },
  onReloadSalary: async () => {
    const { data, status, nonFieldErrors } =
      await SalaryAdvanceRepository.getStaffs();
    if (status) {
      set({
        staffs: data,
      });
    } else toastError(nonFieldErrors);
  },
  getStaffDetails: async (id) => {
    const { data, status, nonFieldErrors } =
      await SalaryAdvanceRepository.getStaffDetail(id);
    if (status) {
      set({
        details: data.staff_details,
        payment_types: data.payment_types,
        data: data,
        calculated: false,
        months: data.months,
        selectedMonth: null,
        salary: null,
      });
    } else toastError(nonFieldErrors);
  },
  resetData: () => {
    set({
      salary: null,
      details: null,
      payment_types: null,
      select_value: null,
      data: null,
      calculated: false,
      months: null,
      selectedMonth: null
    });
  },
}));
