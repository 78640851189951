import { Box } from "@mui/material";
import React, { memo } from "react";
import { useMemo } from "react";
import { useCallback } from "react";
import Select from "react-select";
import NextStageTabsLoader from "../../../../../Components/Loader/basic loader/NextStageTabsLoader";
import { useMainGroupStore } from "../../../../../Store/ManageGroupsStore/MainGroupStore";
import { getSubTeacherDetail } from "../../actions";
import ColorToggleButton from "./ToogleButton";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "40px",
    minHeight: "40px",
    width: "100%",
    fontSize: "15px",
  }),
};
const select = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const select_container = {
  display: "flex",
  flexDirection: "column",
  width: "49%",
  zIndex: 99,
};
const days = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  margin: "10px 0",
};

const AdvancegroupNextLevel = memo(({ element, index }) => {
  const { updateTeacherData, subGroupsTeacher, isLoadingTabs } =
    useMainGroupStore();

  const staffs =
    element?.teachers?.map((element) => ({
      label: element.fullname,
      value: element.id,
      is_selected: element.is_selected,
    })) || [];
  const branches =
    element?.branches?.map((element) => ({
      label: element.name,
      value: element.id,
      is_selected: element.is_selected,
    })) || [];

  const selectedStaff = useMemo(() => {
    return staffs?.find((item) => item.is_selected === true);
  }, [staffs]);
  const selectedBranch = useMemo(() => {
    return branches?.find((item) => item.is_selected === true);
  }, [branches]);

  const weekdays = element?.days || [];
  const branchrooms = element?.rooms || [];
  const timeslots = element?.time_slots || [];

  const selectedDay = useMemo(() => {
    return weekdays?.find((item) => item.is_selected === true);
  }, [weekdays]);
  const selectedTime = useMemo(() => {
    return timeslots?.find((item) => item.is_selected === true);
  }, [timeslots]);
  const selectedRoom = useMemo(() => {
    return branchrooms?.find((item) => item.is_selected === true);
  }, [branchrooms]);

  const filtered = useMemo(() => {
    return subGroupsTeacher?.find((e, i) => i !== index);
  }, [subGroupsTeacher]);
  const teacher = useMemo(() => {
    return filtered?.teachers?.find((e) => e.is_selected === true);
  }, [filtered]);
  const branch = useMemo(() => {
    return filtered?.branches?.find((e) => e.is_selected === true);
  }, [filtered]);
  const day = useMemo(() => {
    return filtered?.days?.find((e) => e.is_selected === true);
  }, [filtered]);
  const time = useMemo(() => {
    return filtered?.time_slots?.find((e) => e.is_selected === true);
  }, [filtered]);
  const room = useMemo(() => {
    return filtered?.rooms?.find((e) => e.is_selected === true);
  }, [filtered]);

  const otherSubGroup = {
    subGroupID2: filtered?.id || null,
    teacherID2: teacher?.id || null,
    branchID2: branch?.id || null,
    dayID2: day?.recurring || null,
    timeID2: time?.id || null,
    roomID2: room?.id || null,
  };

  const handleSelectChange = useCallback((property) => (e) => {
    const subGroupId = subGroupsTeacher[index].id;
    const selectedFalse = subGroupsTeacher?.[index][property]?.map((element) =>
      element.is_selected === true
        ? { ...element, is_selected: false }
        : element
    );
    const selectedTrue = selectedFalse?.map((element) =>
      element.id == e?.value ? { ...element, is_selected: true } : element
    );
    if (e) {
      useMainGroupStore.setState(({ subGroupsTeacher }) => ({
        subGroupsTeacher: subGroupsTeacher?.map((s, indexID) =>
          indexID === index ? { ...s, [property]: selectedTrue } : s
        ),
      }));
      if (property == "teachers" && e) {
        let currentSubGroup = {
          subGroupID1: subGroupId || null,
          teacherID1: e?.value || null,
          branchID1: selectedBranch?.value || null,
          dayID1: selectedDay?.recurring || null,
          timeID1: selectedTime?.id || null,
          roomID1: selectedRoom?.id || null,
        };
        getSubTeacherDetail(
          updateTeacherData?.group_id,
          currentSubGroup,
          otherSubGroup
        );
      }
      if (property == "branches" && e) {
        let currentSubGroup = {
          subGroupID1: subGroupId || null,
          teacherID1: selectedStaff?.value || null,
          branchID1: e?.value || updateTeacherData,
          dayID1: selectedDay?.recurring || null,
          timeID1: selectedTime?.id || null,
          roomID1: selectedRoom?.id || null,
        };
        getSubTeacherDetail(
          updateTeacherData?.group_id,
          currentSubGroup,
          otherSubGroup
        );
      }
    }
  });
  return (
    <>
      {isLoadingTabs && <NextStageTabsLoader />}
      <Box>
        <Box sx={select}>
          <Box sx={select_container}>
            <Select
              id="staff-select"
              className="basic-single manage_group_select"
              classNamePrefix="select"
              isClearable
              isSearchable
              defaultValue={selectedStaff}
              options={staffs}
              styles={customStyles}
              onChange={handleSelectChange("teachers")}
            />
          </Box>
          <Box sx={select_container}>
            <Select
              id="staff-select2"
              className="basic-single manage_group_select"
              classNamePrefix="select"
              defaultValue={selectedBranch}
              options={branches}
              styles={customStyles}
              onChange={handleSelectChange("branches")}
              isClearable
              isSearchable
            />
          </Box>
        </Box>
        <Box sx={days}>
          <label className="select_label_group">Hafta kunlari</label>
          <ColorToggleButton
            item={weekdays}
            property="days"
            sub_groups_index={index}
            selectedStaffID={selectedStaff?.value}
            selectedBranchID={selectedBranch?.value}
            selectedTimeID={selectedTime?.id}
            selectedRoomID={selectedRoom?.id}
            selectedDayID={selectedDay?.recurring}
          />
        </Box>
        <Box sx={days}>
          <label className="select_label_group">Dars vaqtlari</label>
          <ColorToggleButton
            item={timeslots}
            property="time_slots"
            sub_groups_index={index}
            selectedStaffID={selectedStaff?.value}
            selectedDayID={selectedDay?.recurring}
            selectedBranchID={selectedBranch?.value}
            selectedTimeID={selectedTime?.id}
            selectedRoomID={selectedRoom?.id}
          />
        </Box>
        <Box sx={days}>
          <label className="select_label_group">Bo'sh xonalar</label>
          <ColorToggleButton
            item={branchrooms}
            property="rooms"
            sub_groups_index={index}
          />
        </Box>
      </Box>
    </>
  );
});

export default AdvancegroupNextLevel;
