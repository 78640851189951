import { create } from "zustand";
import { toastError, toastSuccess } from "../../Helpers/toasts";
import SalaryCalculationRepository from "../../Repositories/CashControl/SalaryCalculationRepository";

export const useSalaryCalculationStore = create((set, get) => ({
  staff_list: null,
  no_change_students: null,
  roles_list: null,
  year: null,
  month: null,
  roll_filter: null,
  month_verbose: null,
  calculation: null,
  teacherDetails: null,
  isLoading: false,
  confirmation: false,
  setLoading: (status) => {
    set({
      isLoading: status,
    });
  },
  updateStaffLists: (value, id, property) => {
    set((prevState) => ({
      staff_list: prevState.staff_list.map((item) =>
        item.id === id
          ? {
            ...item,
            [property]: value === "" ? 0 : value,
          }
          : item
      ),
    }));
  },
  onReload: async (queryString) => {
    const { data, status, nonFieldErrors } =
      await SalaryCalculationRepository.getDetails(queryString);
    if (status) {
      set({
        staff_list: data.staff_list,
        no_change_students: data.staff_list,
        roles_list: data.roles_list,
        year: data.year,
        teacherDetails: null,
        month: data.month,
        month_verbose: data.month_verbose,
        calculation: data.calculation,
      });
    } else toastError(nonFieldErrors);
  },
  saveDetails: async (list) => {
    const { data, status, nonFieldErrors } =
      await SalaryCalculationRepository.saveDetails(list);
    if (status) {
      toastSuccess(nonFieldErrors);
      set({
        confirmation: false,
      });
    } else toastError(nonFieldErrors);
  },
}));
