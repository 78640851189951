import { toastError } from "../../../Helpers/toasts";
import ExamDetailsRepository from "../../../Repositories/Groups/ExamDetailsRepository";
import { useExamDetailsStore } from "../../../Store/ManageGroupsStore/ExamDetailsStore";

export const onReload = async (groupId, month) => {
  useExamDetailsStore.setState({
    data: null,
    students: null,
    exam_types: null,
    examiners: null,
    subject: null,
  });
  const queryString = new URLSearchParams({
    group_id: groupId,
    lesson_month_id: month,
  }).toString();

  const { data, status, nonFieldErrors } =
    await ExamDetailsRepository.getExamDetails(queryString);
  if (status) {
    useExamDetailsStore.setState({
      data: {
        exam_id: data.exam_id,
        group_id: data.group_id,
        lesson_month: data.lesson_month,
        number: data.number,
        total_average_percent: data.total_average_percent,
        high_passing_score: data.high_passing_score,
        medium_passing_score: data.medium_passing_score,
      },
      can_access: data.can_access,
      students: data.students,
      exam_types: data.exam_types,
      examiners: data.examiners,
      subject: data.subject,
    });
  }
  toastError(nonFieldErrors);
};
