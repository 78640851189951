import { useFormik } from "formik";
import React, { useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import StudentsRegistrationActions from "../../../../Actions/RegistrationActions/StudentsRegistrationActions";
import CommonHeading from "../../../../Components/CommonHeading";
import ExportExcel from "../../../../Components/ExportExcel";
import InfoModule from "../../../../Components/InfoModule";
import Searchbox from "../../../../Components/Searchbox";
import { LoaderContext } from "../../../../Contexts/LoaderContext";
import { StudentsRegistrationContext } from "../../../../Contexts/RegistrationContex/StudentRegistrationContext";
import { loadItems } from "../actions";
import CategoriesBlock from "./CategoriesBlock";
import CheckboxLabels from "./CheckboxLabels";
import DateSection from "./DateSection";
import FilterBlock from "./FilterBlock";
import TableHead from "./TableHead";

export default function Heading() {
  const { students, dispatchStudents } = useContext(
    StudentsRegistrationContext
  );
  const { dispatchLoader } = useContext(LoaderContext);

  const searchAction = useCallback((payload) => {
    dispatchStudents({
      type: StudentsRegistrationActions.ADD_QUERY_PARAM,
      payload: { search_query: payload.term, page: 1 },
    });
    loadItems(
      { search_query: payload.term, page: 1 },
      students,
      dispatchStudents,
      dispatchLoader
    );
  });

  const formik = useFormik({
    initialValues: {
      term: "",
    },
    onSubmit: (values) => {
      searchAction({
        term: values.term,
      });
    },
  });

  const queryParams = new URLSearchParams({
    ...students.queryParams,
    download: "excel",
  }).toString();

  return (
    <CommonHeading
      topItems={
        <>
          <Searchbox formik={formik} />
          <FilterBlock />
          <DateSection />
          <InfoModule title={students.students_count || 0} />
          <CheckboxLabels />
        </>
      }
      rightItems={
        <>
          <CategoriesBlock />
          <Link
            to={
              `https://admin.smartportal.uz/api/register/student/?` +
              queryParams
            }
            download
            target={"_blank"}
          >
            <ExportExcel />
          </Link>
        </>
      }
      tableHead={<TableHead />}
      extraClass={`build-search_teacher`}
    />
  );
}
