import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useStudentTransferStore } from "../../../../Store/GroupManagement/StudentTransferStore";

export default function StudentDetails() {
  const { studentDetail } = useStudentTransferStore();
  const [details, setDetails] = useState(null);

  useEffect(() => {
    setDetails(null);
  }, [studentDetail]);

  const handleChange = useCallback((e) => {
    useStudentTransferStore.setState({
      from_group: e.target.value,
    });
    setDetails(
      studentDetail?.groups?.find((item) => item.id == e.target.value)
    );
  });
  const { t } = useTranslation();
  return (
    <div className="transfer-info transfer-info_huge">
      <h1 className="student_information">{t("O'quvchi ma'lumotlari")}</h1>
      <div className="transfer-info__item">
        <div className="transfer-infos">{t("F.I.Sh")}</div>
        <div className="transfer-infos">{studentDetail?.fullname}</div>
      </div>
      <div className="transfer-info__item">
        <div className="transfer-infos">{t("Tug‘ilgan sanasi va yoshi:")}</div>
        <div className="transfer-infos">
          <span>{studentDetail?.birth_date}</span>
          <span>
            {studentDetail?.age} {t("yosh")}
          </span>
        </div>
      </div>
      <div className="transfer-info__item">
        <div className="transfer-infos">{t("Telefon raqami")}</div>
        <div className="transfer-infos">{studentDetail?.phones}</div>
      </div>
      <div className="transfer-student-select">
        <label htmlFor="times">{t("O'qiyotgan guruh")}</label>
        <select
          id="times"
          name="groups"
          className="modal-input transfer-select"
          onChange={handleChange}
          value={details?.id || ""}
        >
          <option
            className="course_disabled_option"
            disabled
            hidden
            value={""}
          ></option>
          {studentDetail?.groups?.map((element) => {
            return (
              <option key={element.id} value={element.id}>
                {element.number}
                {"  "}
                {element.age_group}
                {"  "}
                {element.month}
              </option>
            );
          })}
        </select>
      </div>
      {details && (
        <div className="student-details-group transfer-info">
          <div className="transfer-info__item">
            <div className="transfer-infos">{t("O’qiyotgan guruhi:")}</div>
            <div className="transfer-infos">
              <span>{details?.number}</span>
              <span>{details?.age_group}</span>
              <span>{details?.month}</span>
            </div>
          </div>
          <div className="transfer-info__item">
            <div className="transfer-infos">{t("O‘qituvchisi:")}</div>
            <div className="transfer-infos">{details?.teachers}</div>
          </div>
          <div className="transfer-info__item">
            <div className="transfer-infos">
              {t("Qatnashmagan darslar soni")}
            </div>
            <div className="transfer-infos">
              <span>{details?.unattended_lessons_count}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
