import { axiosInterceptor } from "../AxiosInterceptor";

export default class SalaryCalculationRepository {
  static async getDetails(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        "/finance/salary/details/?" + queryString
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      result = { ...result, nonFieldErrors: "Ma'lumot olib bo'lmadi" };
    }
    return result;
  }
  static async saveDetails(data) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.post(
        `/finance/salary/save/`,
        data
      );
      if (response.status === 200) {
        {
          response.data.error
            ? (result = {
                ...result,
                status: false,
                nonFieldErrors: response.data.error,
              })
            : (result = {
                ...result,
                status: true,
                nonFieldErrors: response.data.message,
              });
        }
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: "Saqlab bo'lmadi",
      };
    }
    return result;
  }
}
