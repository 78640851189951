import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CourseTemplateContext } from "../../../../Contexts/ConfigContext/CourseTemplateContext";
import UserPermissionWidget from "../../../../UserPermissionWidget";
import {
  deleteConfirmAction,
  openListTable,
  toggleContextMenu,
  updateAction,
} from "../actions";
import ListItemTablePrice from "./table/ListItemTabePrice";

function ListItem({ course, index }) {
  const { courseTemplate, dispatchCourseTemplate } = useContext(
    CourseTemplateContext
  );

  const { t } = useTranslation();

  const user = JSON.parse(localStorage.getItem("user"));
  const permission =
    user?.permissions?.delete_course_templates ||
    user?.permissions?.update_course_templates;
  return (
    <div
      className={
        courseTemplate.listId === course
          ? "lesson_item_full build-table--light lesson_item_active"
          : "lesson_item_full build-table--light"
      }
      onClick={() =>
        openListTable(courseTemplate, course, dispatchCourseTemplate)
      }
    >
      <ul className="time-table build-table build-table--light build-table__item lesson_ul">
        <li className="build-table__item build-table__item--1">{index + 1}</li>
        <li className="build-table__item build-table__item--2">
          {course.subject_readable}
        </li>
        <li className="build-table__item build-table__item--5">
          {course.age_group_readable}
        </li>
        <li className="build-table__item build-table__item--6">
          {course.lessons_per_week}
        </li>
        <li className="build-table__item build-table__item--6">
          {course.duration}
        </li>
        <li className="build-table__item build-table__item--6">
          {course.number_of_lessons}
        </li>
        <li
          className="build-table__item build-table__item--7"
          onClick={(e) =>
            toggleContextMenu(course, dispatchCourseTemplate, e, courseTemplate)
          }
        >
          <ul
            className={
              courseTemplate.openContextMenuId === course.id && permission
                ? "build-table__dropdown build-table__dropdown--active"
                : "build-table__dropdown"
            }
          >
            <UserPermissionWidget
              hasPermission={user?.permissions?.update_course_templates}
            >
              <li
                className="build-table__dropdown-item btn-edit"
                onClick={() => updateAction(course, dispatchCourseTemplate)}
              >
                <button
                  className="modalBtn"
                  onClick={() => updateAction(course, dispatchCourseTemplate)}
                >
                  <img src="/images/dropdown-edit.svg" alt="dropdown icon" />
                  {t("edit")}
                </button>
              </li>
            </UserPermissionWidget>
            <UserPermissionWidget
              hasPermission={user?.permissions?.delete_course_templates}
            >
              <li
                className="build-table__dropdown-item"
                onClick={() =>
                  deleteConfirmAction(course, dispatchCourseTemplate)
                }
              >
                <img src="/images/dropdown-delete.svg" alt="dropdown icon" />
                {t("delete")}
              </li>
            </UserPermissionWidget>
          </ul>
        </li>
      </ul>
      <div
        className={
          courseTemplate.listId === course
            ? "table_lesson table_lesson_active"
            : "table_lesson"
        }
      >
        <ListItemTablePrice course_price={course.course_prices} />
      </div>
    </div>
  );
}

export default ListItem;
