import { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { StudentsRegistrationContext } from "../../../../Contexts/RegistrationContex/StudentRegistrationContext";
import {
  connectAction,
  deleteConfirmAction,
  openStudentInfo,
  returnGroup,
  toggleContextMenu,
  updateAction,
} from "../actions";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { dateRangeFormat } from "../../../../Helpers/dateFormat";
import UserPermissionWidget from "../../../../UserPermissionWidget";
import { useTranslation } from "react-i18next";
const List = styled.ul`
  display: grid;
  height: auto;
  grid-template-columns:
    385px repeat(${(props) => props.categoryFiltered?.length}, 170px)
    30px;
  @media (max-width: 1600px) {
    height: auto;
    grid-template-columns:
      380px
      repeat(${(props) => props.categoryFiltered?.length}, 170px) 30px;
  }
  @media (max-width: 660px) {
    grid-template-columns:
      120px repeat(${(props) => props.categoryFiltered?.length}, 100px)
      30px;
  }
`;
const icon_connect = {
  fontSize: "19px",
  color: "#eda202",
  marginRight: "8px",
};
const back_group = {
  fontSize: "19px",
  color: "#009688",
  marginRight: "8px",
};

export default function ListItem({ student, index }) {
  const { students, dispatchStudents } = useContext(
    StudentsRegistrationContext
  );
  const categoryFiltered = students.categoryList.filter(
    (element) => element.isOpenCategory === true
  );
  const user = JSON.parse(localStorage.getItem("user"));
  const { t } = useTranslation();
  return (
    <List
      categoryFiltered={categoryFiltered}
      className="group-table build-table build-table--light build-table__item sticky_list_employee"
    >
      <li className="build-table__item sticky_table">
        <ul className="employee_item">
          <li className="build-table__item list_padding_sticky">{index + 1}</li>
          <li className="build-table__item list_padding_sticky">
            <Link
              to=""
              onClick={() => openStudentInfo(student, dispatchStudents)}
            >
              {student.first_name} {student.last_name} {student.middle_name}
            </Link>
          </li>
          <li className="build-table__item list_padding_sticky">
            {student.student_id}
          </li>
        </ul>
      </li>
      <li
        className={
          students.categoryList.find(
            (element) => element.name === "Ro’yxatdan o’tgan sana"
          )?.isOpenCategory
            ? "build-table__item list_padding_sticky"
            : "d-none"
        }
      >
        {dateRangeFormat(student.created_at)}
      </li>
      <li
        className={
          students.categoryList.find(
            (element) => element.name === "Talabaning yoshi"
          )?.isOpenCategory
            ? "build-table__item list_padding_sticky"
            : "d-none"
        }
      >
        {student.age}
      </li>
      <li
        className={
          students.categoryList.find((element) => element.name === "Guruh turi")
            ?.isOpenCategory
            ? "build-table__item list_padding_sticky"
            : "d-none"
        }
      >
        {student.group_type_readable}
      </li>
      <li
        className={
          students.categoryList.find((element) => element.name === "Fani")
            ?.isOpenCategory
            ? "build-table__item list_padding_sticky"
            : "d-none"
        }
      >
        {student.subject.name}
      </li>
      <li
        className={
          students.categoryList.find(
            (element) => element.name === "O'qiyotgan guruhi"
          )?.isOpenCategory
            ? "build-table__item list_padding_sticky"
            : "d-none"
        }
      >
        {student.groups.length > 0 ? student.groups[0] : "---------"}
      </li>
      <li
        className={
          students.categoryList.find((element) => element.name === "O'qituvchi")
            ?.isOpenCategory
            ? "build-table__item list_padding_sticky"
            : "d-none"
        }
      >
        {student?.group_teachers}
      </li>
      <li
        className={
          students.categoryList.find((element) => element.name === "Dars vaqti")
            ?.isOpenCategory
            ? "build-table__item list_padding_sticky"
            : "d-none"
        }
      >
        {student.suitable_days_readable[0].name} {student.suitable_time}
      </li>
      <li
        className={
          students.categoryList.find(
            (element) => element.name === "Bilim darajasi"
          )?.isOpenCategory
            ? "build-table__item list_padding_sticky"
            : "d-none"
        }
      >
        {student.level}
      </li>
      <li
        className={
          students.categoryList.find(
            (element) => element.name === "Filial va bo‘lim"
          )?.isOpenCategory
            ? "build-table__item list_padding_sticky list_btns"
            : "d-none"
        }
      >
        {student.studying_place.title}
      </li>
      <li
        className={
          students.categoryList.find(
            (element) => element.name === "O'quvchining manzili"
          )?.isOpenCategory
            ? "build-table__item list_padding_sticky"
            : "d-none"
        }
      >
        {student.address}
      </li>
      <li
        className={
          students.categoryList.find(
            (element) => element.name === "Telefon nomeri"
          )?.isOpenCategory
            ? "build-table__item list_padding_sticky"
            : "d-none"
        }
      >
        {student.phone_number}
      </li>
      <li
        className={
          students.categoryList.find((element) => element.name === "Maktabi")
            ?.isOpenCategory
            ? "build-table__item list_padding_sticky"
            : "d-none"
        }
      >
        {student.school ? student.school : "---------"}
      </li>
      <li
        className={
          students.categoryList.find((element) => element.name === "Xodim")
            ?.isOpenCategory
            ? "build-table__item list_padding_sticky"
            : "d-none"
        }
      >
        {student.registered_staff?.first_name}{" "}
        {student.registered_staff?.last_name}
      </li>
      <li
        className={
          students.categoryList.find(
            (element) => element.name === "Aloqa natijasi"
          )?.isOpenCategory
            ? `build-table__item list_padding_sticky ${
                student.connection_status_readable && "comment-field"
              }`
            : "d-none"
        }
      >
        {student.connection_status_readable} <br />
        <div className="comment-container">{student.comment}</div>
      </li>
      <li
        className="build-table__item  build-table__item--7"
        onClick={() => toggleContextMenu(student, dispatchStudents)}
      >
        <ul
          className={
            students.openContextMenuId === student.id
              ? "build-table__dropdown build-table__dropdown--active staff_menu"
              : "build-table__dropdown"
          }
        >
          <UserPermissionWidget
            hasPermission={user?.permissions?.update_student}
          >
            <li
              className="build-table__dropdown-item btn-edit"
              onClick={() => updateAction(student, dispatchStudents)}
            >
              <button className="modalBtn">
                <img src="/images/dropdown-edit.svg" alt="dropdown icon" />
                {t("Taxrirlash")}
              </button>
            </li>
          </UserPermissionWidget>
          <li
            className="build-table__dropdown-item btn-edit connection"
            onClick={() => connectAction(student, dispatchStudents)}
          >
            <button className="modalBtn">
              <PermPhoneMsgIcon size="small" sx={icon_connect} />
              <p>{t("Aloqa")}</p>
            </button>
          </li>
          <li
            className="build-table__dropdown-item btn-edit back--group"
            onClick={() => returnGroup(student, dispatchStudents)}
          >
            <button className="modalBtn">
              <GroupAddIcon size="small" sx={back_group} />
              <p>{t("Guruhga qaytarish")}</p>
            </button>
          </li>
          <li
            className="build-table__dropdown-item"
            onClick={(e) => deleteConfirmAction(student, dispatchStudents)}
          >
            <img src="/images/dropdown-delete.svg" alt="dropdown icon" />
            {t("O‘chirish")}
          </li>
        </ul>
      </li>
    </List>
  );
}
