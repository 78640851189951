import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LoaderContext } from "../../../../Contexts/LoaderContext";
import { useAttendanceDetailsStore } from "../../../../Store/AttendanceFormStore/AttendanceDeatilsStore";
import { saveAttendanceDetails } from "../actions";

function InfoTag() {
  const { students, data } = useAttendanceDetailsStore();
  const { dispatchLoader } = useContext(LoaderContext);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const { date, sub_group } = data;
    const details = {
      date: date,
      sub_group: sub_group,
      students: students,
    };
    await saveAttendanceDetails(details, dispatchLoader, goBackNavigate);
  };
  const goBackNavigate = () => {
    navigate(-1, { replace: true });
  };
  const { t } = useTranslation();

  return (
    <div className="journal-bottom button_position_container">
      <div className="journal-icons journal-icons_feedback"></div>
      {data?.editable ? (
        <button
          className="btn btn-blue submit_btn_position"
          onClick={handleSubmit}
        >
          {t("Saqlash")}
        </button>
      ) : (
        ""
      )}
    </div>
  );
}
export default React.memo(InfoTag);
