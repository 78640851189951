import { useTranslation } from "react-i18next";

export default function TableHead() {
  const { t } = useTranslation();

  return (
    <ul className="expenditure-list--grid build-table build-table--bold ">
      <li className="build-table__item">№</li>
      <li className="build-table__item">{t("Darslar")}</li>
      <li className="build-table__item">{t("Darslik havolasi")}</li>
      <li className="build-table__item">{t("Dars uslubi")}</li>
      <li className="build-table__item">{t("Guruh turi")}</li>
      <li className="build-table__item">{t("Oylik darslar")}</li>
      <li className="build-table__item">{t("Fan")}</li>
      <li className="build-table__item">{t("Dars yuklamalari")}</li>
      <li className="build-table__item"></li>
    </ul>
  );
}
