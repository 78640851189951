import { create } from "zustand";
import CommonRepository from "../../Repositories/Config/CommonRepository";
import MainGroupsRepository from "../../Repositories/Groups/MainGroupsRepository";

export const useStudentLessonAcquitationStore = create((set, get) => ({
  data: null,
  months: null,
  total_counts: null,
  branches: null,
  subjects: null,
  queryParams: {},
  uploadParams: (state) => {
    const { queryParams } = get();
    set({
      queryParams: { ...queryParams, ...state },
    });
  },
  getBranches: async () => {
    const [branches, subjects] = await Promise.all([
      await MainGroupsRepository.getAllBranches(),
      await CommonRepository.getSubjects()
    ])
    set({
      branches: branches.data,
      subjects: subjects.data,
    })
  },
}));
