import React, { useContext } from "react";
import { ArchivedGroupsContext } from "../../../../Contexts/GroupsContext/ArchivedGroupsContext";
import { openCategories } from "../../Archived-groups/actions";

export default function CategoriesBlock() {
  const { archivedGroups, dispatchArchivedGroups } = useContext(
    ArchivedGroupsContext
  );
  return (
    <div
      className="categories"
      onClick={(e) => openCategories(e, dispatchArchivedGroups)}
    >
      Categories
      <ul
        className={
          archivedGroups.isOpenCategories
            ? "categories-list categories-list_active"
            : "categories-list"
        }
      >
        <li className="categories-list__item">
          <input type="checkbox" name="category" id="cat-1" />
          <label for="cat-1">Guruhning restor raqami</label>
        </li>
        <li className="categories-list__item">
          <input type="checkbox" name="category" id="cat-2" />
          <label for="cat-2">O‘qituvchining ismi va maqomi</label>
        </li>
        <li className="categories-list__item">
          <input type="checkbox" name="category" id="cat-3" />
          <label for="cat-3">Dars o‘tiladigan hafta kuni va soat</label>
        </li>
        <li className="categories-list__item">
          <input type="checkbox" name="category" id="cat-4" />
          <label for="cat-4">Guruhning turi</label>
        </li>
        <li className="categories-list__item">
          <input type="checkbox" name="category" id="cat-13" />
          <label for="cat-13">Guruhning uslubi</label>
        </li>
        <li className="categories-list__item">
          <input type="checkbox" name="category" id="cat-5" />
          <label for="cat-5">Qaysi fandan</label>
        </li>
        <li className="categories-list__item">
          <input type="checkbox" name="category" id="cat-6" />
          <label for="cat-6">Bino va filial hamda dars xona raqami</label>
        </li>
        <li className="categories-list__item">
          <input type="checkbox" name="category" id="cat-7" />
          <label for="cat-7">Birinchi dars boshlangan sana</label>
        </li>
        <li className="categories-list__item">
          <input type="checkbox" name="category" id="cat-8" />
          <label for="cat-8">Tugatilgan va yopilgan sana</label>
        </li>
        <li className="categories-list__item">
          <input type="checkbox" name="category" id="cat-9" />
          <label for="cat-9">O‘quvchilar soni</label>
        </li>
        <li className="categories-list__item">
          <input type="checkbox" name="category" id="cat-10" />
          <label for="cat-10">Tugatilgan va yopilgan guruhlarga sabablar</label>
        </li>
      </ul>
    </div>
  );
}
