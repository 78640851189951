import React from "react";
import { useTranslation } from "react-i18next";
import { useRefundStore } from "../../../../../Store/CashControlStore/RefundStore";

export default function GroupInfo() {
  const { groupDetails } = useRefundStore();
  const { t } = useTranslation();

  return (
    <>
      <div className="transfer-info__item">
        <div className="transfer-infos">{t("Guruhi")}</div>
        <div className="transfer-infos">
          {groupDetails?.number}
          {"  "}({groupDetails?.name})
        </div>
      </div>
      <div className="transfer-info__item">
        <div className="transfer-infos">{t("Oylik to'lov miqdori")}</div>
        <div className="transfer-infos">
          {groupDetails?.total_month_cost} so'm
        </div>
      </div>
      <div className="transfer-info__item">
        <div className="transfer-infos">{t("Jami to'langan")}</div>
        <div className="transfer-infos">{groupDetails?.total_paid} so'm</div>
      </div>
      <div className="transfer-info__item">
        <div className="transfer-infos">{t("Jami darslar soni")}</div>
        <div className="transfer-infos">
          {groupDetails?.total_lessons_count} ta
        </div>
      </div>
      <div className="transfer-info__item">
        <div className="transfer-infos">{t("Qatnashgan darslar soni")}</div>
        <div className="transfer-infos">
          {groupDetails?.attended_lessons_count} ta
        </div>
      </div>
      <div className="transfer-info__item">
        <div className="transfer-infos">{t("Qatnashgan darslar narxi")}</div>
        <div className="transfer-infos">
          {groupDetails?.attended_lessons_price} so'm
        </div>
      </div>
      <div className="transfer-info__item">
        <div className="transfer-infos expenditure-expired--color">
          {t("Ortiqcha to'lov")}
        </div>
        <div className="transfer-infos  expenditure-expired--color">
          {groupDetails?.extra_money} so'm
        </div>
      </div>
    </>
  );
}
