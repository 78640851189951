import { Box, Button, Popover, Typography } from "@mui/material";
import { useState } from "react";
import { useFinishedStudents } from "../../../../Store/ReportsStore/FinishedStudentsStore";

const popoverItem = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: 5,
  px: 2,
  py: 1,
};

export default function ListItem({ item, index }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const openModal = () => {
    useFinishedStudents.setState({
      studentId: item.id,
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="list--config">
      <ul className="finished-students build-table build-table--bold build-table--light build-table__item">
        <li className="build-table__item">{index + 1}</li>
        <li className="build-table__item">{item.fullname}</li>
        <li className="build-table__item">{item.age_group}</li>
        <li className="build-table__item">{item.group}</li>
        <li className="build-table__item">{item.subject}</li>
        <li className="build-table__item">{item.teachers}</li>
        <li className="build-table__item">
          {item.ielts_score ? (
            <Box sx={{ position: "relative" }}>
              <Button
                onClick={handleClick}
                aria-describedby={id}
                size="small"
                variant="text"
                color="success"
                sx={{ fontWeight: "bold" }}
              >
                {item.ielts_score}
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Box sx={popoverItem}>
                  <Typography>Speaking</Typography>
                  <Typography>{item?.individual_scores.speaking}</Typography>
                </Box>
                <Box sx={popoverItem}>
                  <Typography>Listening</Typography>
                  <Typography>{item?.individual_scores.listening}</Typography>
                </Box>
                <Box sx={popoverItem}>
                  <Typography>Reading</Typography>
                  <Typography>{item?.individual_scores.reading}</Typography>
                </Box>
                <Box sx={popoverItem}>
                  <Typography>Writing</Typography>
                  <Typography>{item?.individual_scores.writing}</Typography>
                </Box>
                <Box sx={popoverItem}>
                  <Typography>Overall</Typography>
                  <Typography>{item?.individual_scores.overall}</Typography>
                </Box>
                {item?.file && (
                  <Box sx={popoverItem}>
                    <a
                      style={{ color: "blue", textDecoration: "underline" }}
                      target="_blank"
                      href={`${item?.file}`}
                    >
                      Fayl
                    </a>
                  </Box>
                )}
              </Popover>
            </Box>
          ) : (
            <Button onClick={openModal} size="small" variant="outlined">
              Kritish
            </Button>
          )}
        </li>
        <li className="build-table__item">{item.date}</li>
      </ul>
    </div>
  );
}
