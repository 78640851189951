import React, { useContext, useState } from "react";
import { useMemo } from "react";
import { AuthenticationContext } from "../../../../Contexts/AuthContext/AuthenticationContext";
import { useProfileConfigStore } from "../../../../Store/ProfileConfiguration/ProfileConfigStore";
import { uploadPhoto } from "../actions";

function PhotoUpdate() {
  const { profile_details, setUploading, isUploading } =
    useProfileConfigStore();
  const [profilePhoto, setProfilePhoto] = useState(null);
  const { dispatchAuth } = useContext(AuthenticationContext);

  const defaultPhoto = useMemo(() => {
    return profile_details?.photo;
  }, [profile_details]);

  const handleProfilePhotoChange = async (event) => {
    const file = event.target.files[0];
    setProfilePhoto(file);

    const formData = new FormData();
    formData.append("photo", file);
    setUploading(true);
    await uploadPhoto(formData, dispatchAuth);
    setUploading(false);
  };

  return (
    <label htmlFor="profilePhoto" className="profile-photo-container">
      <img
        className={`profile-photo ${isUploading ? "isUploading" : ""}`}
        src={
          profilePhoto
            ? URL.createObjectURL(profilePhoto)
            : defaultPhoto
            ? defaultPhoto
            : "/images/profilePhoto.png"
        }
        alt="Profile"
      />
      {isUploading ? (
        <p className="uploadPhoto">Uploading...</p>
      ) : (
        <input
          type="file"
          id="profilePhoto"
          accept="image/jpeg,image/jpg, image/png, image/gif"
          onChange={handleProfilePhotoChange}
        />
      )}
    </label>
  );
}

export default PhotoUpdate;
