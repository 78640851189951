import React from "react";
import CommonHeading from "../../../../Components/CommonHeading";
import Selector from "./Select";
import TableHead from "./TableHead";
import { useFormik } from "formik";
import * as Yup from "yup";
import Searchbar from "../../../../Components/Searchbar";
import { useCallback } from "react";
import { useExpenditureListStore } from "../../../../Store/CashControlStore/ExpenditureListStore";
import DateSection from "./DateSection";
import ExportExcel from "../../../../Components/ExportExcel";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Heading() {
  const {
    loadItems,
    updateParams,
    staffs_list,
    branches_list,
    queryParams,
    expenditures_list,
    setLoading,
  } = useExpenditureListStore();

  const searchAction = useCallback(async (payload) => {
    updateParams({ search_query: payload.term, page: 1 });
    setLoading(true);
    await loadItems(
      new URLSearchParams({
        ...queryParams,
        page: 1,
        search_query: payload.term,
      }).toString()
    );
    setLoading(false);
  });

  const { t } = useTranslation();

  const initialValues = {
    term: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      term: Yup.string().min(1, t("Qidiruvni kiriting!")),
    }),
    onSubmit: (values) => {
      searchAction({
        term: values.term,
      });
    },
  });

  const handleChange = (e) => {
    formik.setFieldValue("term", e.target.value);
    searchAction({
      term: e.target.value,
    });
  };

  const user = JSON.parse(localStorage.getItem("token"));

  const queryString = new URLSearchParams({
    ...queryParams,
    download: "excel",
    token: user.access || "",
  }).toString();

  return (
    <CommonHeading
      topItems={
        <>
          <Searchbar
            value={formik.values.term}
            handleChange={(e) => handleChange(e)}
            handleSubmit={formik.handleSubmit}
          />
          <Selector param={staffs_list} property={t("O'qituvchilar")} />
          <Selector param={branches_list} property={t("Filiallar")} />
          <Selector param={expenditures_list} property={t("Harajat turlari")} />
          <DateSection />
        </>
      }
      rightItems={
        <Link
          to={
            `https://admin.smartportal.uz/api/finance/payments/expenditure/?` +
            queryString
          }
          download
          target={"_blank"}
        >
          <ExportExcel />
        </Link>
      }
      tableHead={<TableHead />}
    />
  );
}
