import React from "react";
import { useTranslation } from "react-i18next";

export default function SubStatusAll(props) {
  const { t } = useTranslation();

  return (
    <div className="subject-stats__all">
      {t("Jami")} -{props.data} {t("nafar")}
    </div>
  );
}
