import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "40px",
    minHeight: "40px",
    fontSize: "15px",
    fontWeight: "400",
  }),
};
function Selector({
  value,
  options,
  property,
  handleChange,
  disabled = false,
}) {
  const { t } = useTranslation();

  return (
    <Select
      className={`expendeture-select ${
        property == t("Biriktirilgan xodim(ixtiyoriy)")
          ? "expendeture--attached_employee"
          : ""
      }`}
      classNamePrefix="select"
      placeholder={property}
      styles={customStyles}
      options={options}
      value={value}
      isDisabled={disabled}
      isClearable
      onChange={handleChange}
    />
  );
}
export default React.memo(Selector);
