import PersonRemoveAlt1Icon from "@mui/icons-material/PersonRemoveAlt1";
import { Button } from "@mui/material";
import { FaDollarSign } from "react-icons/fa";
import React, { useContext } from "react";
import styled from "styled-components";
import { LoaderContext } from "../../../../Contexts/LoaderContext";
import { EmployeeRegistrationContext } from "../../../../Contexts/RegistrationContex/EmployeeRegistrationContext";
import { dateFormat } from "../../../../Helpers/dateFormat";
import UserPermissionWidget from "../../../../UserPermissionWidget";
import {
  deleteConfirmAction,
  openInfo,
  permissionConfirmationAction,
  removeConfirmationAction,
  toggleContextMenu,
  updateAction,
  updateSalaryStaff,
} from "../actions";
import { useTranslation } from "react-i18next";

const List = styled.ul`
  display: grid;
  height: auto;
  grid-template-columns:
    385px repeat(${(props) => props.categoryFiltered?.length}, 170px)
    30px;
  @media (max-width: 1600px) {
    height: auto;
    grid-template-columns:
      380px
      repeat(${(props) => props.categoryFiltered?.length}, 170px) 30px;
  }
  @media (max-width: 660px) {
    grid-template-columns:
      120px repeat(${(props) => props.categoryFiltered?.length}, 100px)
      30px;
  }
`;
const icon_remove = {
  fontSize: "19px",
  color: "#eda202",
  marginRight: "8px",
};

export default function ListItem({ staff, index }) {
  const { employee, dispatchEmployee } = useContext(
    EmployeeRegistrationContext
  );
  const { t } = useTranslation();
  const { dispatchLoader } = useContext(LoaderContext);

  const categoryFiltered = employee.categoryList.filter(
    (element) => element.isOpenCategory === true
  );

  const user = JSON.parse(localStorage.getItem("user"));
  const permissionContextMenu =
    user?.permissions?.fire_staff ||
    user?.permissions?.create_staff ||
    user?.permissions?.delete_staff;
  return (
    <List
      categoryFiltered={categoryFiltered}
      className={`group-table build-table build-table--light build-table__item sticky_list_employee ${
        staff?.leaving_reason == "negative" ? "badStaff" : ""
      }`}
    >
      <li className="build-table__item sticky_table">
        <ul
          className={`employee_item ${
            staff?.leaving_reason == "negative" ? "badStaff" : ""
          }`}
        >
          <li className="build-table__item list_padding_sticky">{index + 1}</li>
          <li
            onClick={(e) =>
              openInfo(e, staff, dispatchEmployee, dispatchLoader)
            }
            className="build-table__item list_padding_sticky lessonStyleBtn"
          >
            {staff.first_name} {staff.last_name} {staff.middle_name}
          </li>
          <li className="build-table__item list_padding_sticky">
            {staff.staff_id}
          </li>
        </ul>
      </li>
      <li
        className={
          employee.categoryList.find(
            (element) => element.name === "Ro’yxatdan o’tgan sana"
          )?.isOpenCategory
            ? "build-table__item list_padding_sticky "
            : "d-none"
        }
      >
        {dateFormat(staff.created_at)}
      </li>
      <li
        className={
          employee.categoryList.find(
            (element) => element.name === "Tug’ilgan sana"
          )?.isOpenCategory
            ? "build-table__item list_padding_sticky"
            : "d-none"
        }
      >
        {staff.birth_date}
      </li>
      <li
        className={
          employee.categoryList.find(
            (element) => element.name === "Hujjat turikumi"
          )?.isOpenCategory
            ? "build-table__item list_padding_sticky"
            : "d-none"
        }
      >
        {staff.document_type_readable
          ? staff.document_type_readable
          : "--------"}
      </li>
      <li
        className={
          employee.categoryList.find(
            (element) => element.name === "Hujjat seriya va raqami"
          )?.isOpenCategory
            ? "build-table__item list_padding_sticky"
            : "d-none"
        }
      >
        {staff.passport_series ? staff.passport_series : "--------"}{" "}
        {staff.passport_number ? staff.passport_number : ""}
      </li>
      <li
        className={
          employee.categoryList.find(
            (element) => element.name === "Lavozimi va xizmat vazifasi"
          )?.isOpenCategory
            ? "build-table__item list_padding_sticky"
            : "d-none"
        }
      >
        {staff.roles.length > 0 ? staff.roles[0]?.name : "--------"}
      </li>
      <li
        className={
          employee.categoryList.find(
            (element) => element.name === "Ish faoliyati joyi"
          )?.isOpenCategory
            ? "build-table__item list_padding_sticky"
            : "d-none"
        }
      >
        {staff.working_places_detailed[0]?.title}
      </li>
      <li
        className={
          employee.categoryList.find(
            (element) => element.name === "Telefon raqam"
          )?.isOpenCategory
            ? "build-table__item list_padding_sticky"
            : "d-none"
        }
      >
        {staff.phone}
      </li>
      <li
        className={
          employee.categoryList.find((element) => element.name === "Oylik")
            ?.isOpenCategory
            ? "build-table__item list_padding_sticky"
            : "d-none"
        }
      >
        {staff.fixed_salary
          ? `${staff.fixed_salary} so'm`
          : staff.kpi_percentage
          ? `${staff.kpi_percentage} %`
          : "--------"}
      </li>
      <li
        className={
          employee.categoryList.find(
            (element) =>
              element.name === "Qabul qilingan va bekor qilingan sana"
          )?.isOpenCategory
            ? "build-table__item list_padding_sticky list_btns"
            : "d-none"
        }
      >
        {staff.first_working_day ? (
          <p>{staff.first_working_day}</p>
        ) : staff.working_status.value == "applied_candidate" ? (
          <Button
            onClick={(e) =>
              permissionConfirmationAction(e, staff, dispatchEmployee)
            }
            size="small"
            variant="outlined"
          >
            {t("Ishga olish")}
          </Button>
        ) : (
          ""
        )}
        <p>{staff.last_working_day ? staff.last_working_day : ""}</p>
      </li>
      <li
        className={
          employee.categoryList.find((element) => element.name === "Sababi")
            ?.isOpenCategory
            ? "build-table__item list_padding_sticky"
            : "d-none"
        }
      >
        {staff.leaving_comment ? staff.leaving_comment : "--------"}
      </li>
      <li
        className="build-table__item  build-table__item--7"
        onClick={() => toggleContextMenu(staff, dispatchEmployee)}
      >
        <ul
          className={
            employee.openContextMenuId === staff.id && permissionContextMenu
              ? "build-table__dropdown build-table__dropdown--active staff_menu"
              : "build-table__dropdown"
          }
        >
          <UserPermissionWidget hasPermission={user?.permissions?.fire_staff}>
            <li
              className={
                staff.working_status.value == "active_working"
                  ? "build-table__dropdown-item remove_icon"
                  : "d-none"
              }
              onClick={(e) =>
                removeConfirmationAction(e, staff, dispatchEmployee)
              }
            >
              <PersonRemoveAlt1Icon size="small" sx={icon_remove} />
              <p>{t("Ishdan olish")}</p>
            </li>
          </UserPermissionWidget>
          <UserPermissionWidget hasPermission={user?.permissions?.fire_staff}>
            <li
              className={
                staff.working_status.value == "active_working"
                  ? "build-table__dropdown-item btn-edit"
                  : "d-none"
              }
              onClick={(e) => updateSalaryStaff(e, staff, dispatchEmployee)}
            >
              <FaDollarSign fontSize="20px" />
              <p style={{ fontWeight: 500 }}>{t("Oylikni o'zgartirish")}</p>
            </li>
          </UserPermissionWidget>
          <UserPermissionWidget hasPermission={user?.permissions?.create_staff}>
            <li
              className={
                staff.working_status.value === "reapplied_candidate"
                  ? "d-none"
                  : "build-table__dropdown-item btn-edit"
              }
              onClick={() =>
                updateAction(staff, dispatchEmployee, dispatchLoader)
              }
            >
              <button className="modalBtn">
                <img src="/images/dropdown-edit.svg" alt="dropdown icon" />
                {t("Taxrirlash")}
              </button>
            </li>
          </UserPermissionWidget>
          <UserPermissionWidget hasPermission={user?.permissions?.delete_staff}>
            <li
              className="build-table__dropdown-item"
              onClick={(e) => deleteConfirmAction(e, staff, dispatchEmployee)}
            >
              <img src="/images/dropdown-delete.svg" alt="dropdown icon" />
              {t("O‘chirish")}
            </li>
          </UserPermissionWidget>
        </ul>
      </li>
    </List>
  );
}
