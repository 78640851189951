import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIncomeCashStore } from "../../../../Store/CashControlStore/IncomeCashStore";
import GroupDetails from "./GroupDetails";

export default function SearchResultSection() {
  const { student_details, groups, updateAcceptPayment } = useIncomeCashStore();
  const [details, setDetails] = useState(null);
  const [monthDetails, setMonthDetails] = useState(null);

  useEffect(() => {
    setDetails(null);
  }, [student_details]);

  const handleChange = useCallback((e) => {
    const checkedStudent = groups?.find((item) => item.id == e.target.value);
    const mainTypeDiscounts = checkedStudent?.discounts.filter(
      (item) => item.mandatory === true
    );
    setMonthDetails(null);
    updateAcceptPayment("group", e.target.value);
    updateAcceptPayment("discounts", mainTypeDiscounts);
    setDetails(checkedStudent);
    useIncomeCashStore.setState({
      paymentId: null,
    });
  });
  const handleChangeMonth = useCallback((e) => {
    const checkedStudent = details?.lesson_months?.find(
      (item) => item.id == e.target.value
    );
    useIncomeCashStore.setState({
      paymentId: null,
    });
    updateAcceptPayment("lesson_month", e.target.value);
    setMonthDetails(checkedStudent);
  });
  const { t } = useTranslation();

  return (
    <>
      <h1 className="student_information">{t("O'quvchi ma'lumotlari")}</h1>
      <div className="transfer-info__item">
        <div className="transfer-infos">{t("F.I.Sh")}</div>
        <div className="transfer-infos">{student_details?.fullname}</div>
      </div>
      <div className="transfer-info__item">
        <div className="transfer-infos">{t("Yoshi")}:</div>
        <div className="transfer-infos">
          <span>
            {student_details?.age} {t("yosh")}
          </span>
        </div>
      </div>
      <div className="transfer-info__item">
        <div className="transfer-infos">{t("Telefon raqami")}</div>
        <div className="transfer-infos">{student_details?.phone_number}</div>
      </div>
      <div className="transfer-student-select">
        <label htmlFor="times">{t("O'qiyotgan guruh")}</label>
        <select
          id="times"
          name="groups"
          className="modal-input transfer-select"
          onChange={handleChange}
          value={details?.id || ""}
        >
          <option
            className="course_disabled_option"
            disabled
            hidden
            value={""}
          ></option>
          {groups?.map((element) => {
            return (
              <option key={element.id} value={element.id}>
                {element.number}
                {"    "}
                {element.teachers}
              </option>
            );
          })}
        </select>
      </div>
      {details && (
        <>
          <div className="transfer-student-select">
            <label htmlFor="timesa">{t("To'lov oylari")}</label>
            <select
              id="timesa"
              name="groups-month"
              className="modal-input transfer-select"
              onChange={handleChangeMonth}
              value={monthDetails?.id || ""}
            >
              <option
                className="course_disabled_option"
                disabled
                hidden
                selected
                value={""}
              ></option>
              {details?.lesson_months?.map((element, index) => {
                return (
                  <option key={element.id} value={element.id}>
                    {element.name}
                  </option>
                );
              })}
            </select>
          </div>
          {monthDetails && (
            <GroupDetails monthDetails={monthDetails} details={details} />
          )}
        </>
      )}
    </>
  );
}
