import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useExamScheduleStore } from "../../../../Store/ManageGroupsStore/ExamScheduleStore";
import { getExamListWithParam } from "../actions";
import MonthSelector from "./MonthSelector";
const container = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "15px",
  outline: "none",
  padding: "30px 60px",
};
const style = {
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: "30px",
};
const borderElement = {
  margin: "0",
  display: "flex",
  flexDirection: "column",
  gap: "10px 0",
};
const btnSecton = {
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
};

export default function FilteredModal() {
  const {
    isOpenModal,
    setLoading,
    setModalToggler,
    branches,
    filterset,
    queryParams,
  } = useExamScheduleStore();

  const handleSubmit = async () => {
    setModalToggler(false);
    setLoading(true);
    await getExamListWithParam(
      new URLSearchParams({
        ...queryParams,
        page: 1,
      }).toString()
    );
    setLoading(false);
  };

  const monthsOptions =
    useMemo(() => {
      return filterset?.months?.map((element) => ({
        label: element.verbose_name,
        value: element.id,
      }));
    }) || [];

  const subjectsOptions =
    useMemo(() => {
      return filterset?.subjects?.map((element) => ({
        label: element.name,
        value: element.id,
      }));
    }) || [];

  const ageGroupOptions =
    useMemo(() => {
      return filterset?.age_groups?.map((element) => ({
        label: element.name,
        value: element.value,
      }));
    }) || [];

  const branchesOptions =
    useMemo(() => {
      return branches?.map((element) => ({
        label: element.title,
        value: element.id,
      }));
    }) || [];

  const teachersOptions =
    useMemo(() => {
      return filterset?.teachers?.map((element) => ({
        label: element.fullname,
        value: element.id,
      }));
    }) || [];
  const { t } = useTranslation();
  return (
    <div>
      <Modal
        className="filterModal"
        onClick={(e) => e.stopPropagation()}
        open={isOpenModal}
        onClose={() => handleSubmit()}
        aria-describedby="modal-modal-description"
      >
        <Box sx={container}>
          <Box sx={style}>
            <Box sx={borderElement}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={18}
              >
                {t("Oylar bo'yicha")}
              </Typography>
              <MonthSelector
                param={monthsOptions}
                property={t("months")}
                value={monthsOptions?.find(
                  (item) => item.value == queryParams.month
                )}
              />
            </Box>
            <Box sx={borderElement}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={18}
              >
                {t("Fanlar bo'yicha saralash")}
              </Typography>
              <MonthSelector
                param={subjectsOptions}
                property={t("subject")}
                value={subjectsOptions?.find(
                  (item) => item.value == queryParams.subject
                )}
              />
            </Box>
            <Box sx={borderElement}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={18}
              >
                {t("Guruh turi bilan saralash")}
              </Typography>
              <MonthSelector
                param={ageGroupOptions}
                property={t("Guruh turlari")}
                value={ageGroupOptions?.find(
                  (item) => item.value == queryParams.age_group
                )}
              />
            </Box>
            <Box sx={borderElement}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={18}
              >
                {t("Binolar bilan saralash")}
              </Typography>
              <MonthSelector
                param={branchesOptions}
                property={t("branch")}
                value={branchesOptions?.find(
                  (item) => item.value == queryParams.branch
                )}
              />
            </Box>
            <Box sx={borderElement}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={18}
              >
                {t("Xodim bilan saralash")}
              </Typography>
              <MonthSelector
                param={teachersOptions}
                property={t("home_teachers")}
                value={teachersOptions?.find(
                  (item) => item.value == queryParams.teacher
                )}
              />
            </Box>
          </Box>
          <Box sx={btnSecton}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              endIcon={<FilterAltIcon />}
            >
              Filter
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
