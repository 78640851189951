import { useCallback, useEffect } from "react";
import AdminLayout from "../../../AdminLayout";
import TitleBar from "../../../Components/TitleBar";
import { useExamScheduleStore } from "../../../Store/ManageGroupsStore/ExamScheduleStore";
import Heading from "./components/Heading";
import ListItem from "./components/ListItem";
import { getExamListWithParam, onReload } from "./actions";
import EmptyContent from "../../../Components/Empty-content/EmptyContent";
import ItemsLoader from "../../../Components/Loader/basic loader/ItemsLoader";
import PaginationComponent from "../../../Components/Pagination";
import FilteredModal from "./components/FilteredModal";
import { useTranslation } from "react-i18next";

export default function ExamSchedule() {
  const {
    data,
    isLoading,
    getBranches,
    queryParams,
    updateParams,
    setLoading,
    pagination,
  } = useExamScheduleStore();

  const pageLoad = useCallback(async () => {
    await Promise[(onReload(), getBranches())];
    useExamScheduleStore.setState({
      queryParams: { page: 1 },
    });
  }, []);

  useEffect(() => {
    pageLoad();
    document.body.addEventListener("click", handleClickClosePanelFromOutside);
    return () => {
      document.body.removeEventListener(
        "click",
        handleClickClosePanelFromOutside
      );
    };
  }, []);

  const handleClickClosePanelFromOutside = (e) => {
    if (!e.target.className.includes("build-table__item--7")) {
      useExamScheduleStore.setState({
        openContextMenu: null,
      });
    }
  };

  const paginateTo = useCallback(
    async (page) => {
      const queryString = new URLSearchParams({
        ...queryParams,
        page: page,
      }).toString();
      setLoading(true);
      updateParams({ page: page });
      await getExamListWithParam(queryString);
      setLoading(false);
    },
    [queryParams]
  );
  const { t } = useTranslation();

  return (
    <AdminLayout>
      <TitleBar title={t("Imtixon olish jadvali")} createAction={false} />
      <div className="container">
        <Heading />
        {isLoading ? (
          <ItemsLoader size={70} />
        ) : data?.length ? (
          data.map((group, index) => (
            <ListItem item={group} key={group.id} index={index} />
          ))
        ) : (
          <EmptyContent />
        )}
        <PaginationComponent
          context={{ pagination, queryParams }}
          callback={paginateTo}
        />
        <FilteredModal />
      </div>
    </AdminLayout>
  );
}
