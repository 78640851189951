import { axiosInterceptor } from "../AxiosInterceptor";

export default class ExpenditureCashRepository {
  static async getDetails(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        "finance/expenditures/?" + queryString
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch {
      result = { ...result, nonFieldErrors: "Ma'lumot olib bo'lmadi" };
    }
    return result;
  }
  static async expenditure(data) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.post(
        `/finance/expenditures/make/`,
        data
      );
      if (response.status === 200) {
        result = {
          ...result,
          status: true,
          nonFieldErrors: response.data.message,
        };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: "Saqlab bo'lmadi",
      };
    }
    return result;
  }
}
