import React from "react";
import Container from "../Loader";

export default function NextStageModalLoader() {
  return (
    <div className="stage_loader">
      <Container size={70} />
    </div>
  );
}
