import BranchesActions from "../../../Actions/ConfigActions/BranchesActions";
import LoaderActions from "../../../Actions/ComponentsActions/LoaderActions";
import { toastError, toastSuccess } from "../../../Helpers/toasts";
import BranchesRepository from "../../../Repositories/Config/BranchesRepository";

export const loadItems = async (payload, context, dispatch, dispatchLoader) => {
  dispatchLoader({ type: LoaderActions.PAGE_LOADING });
  const response = await BranchesRepository.getAllBranches(
    new URLSearchParams({ ...context.queryParams, ...payload }).toString()
  );
  dispatch({
    type: BranchesActions.PAGE_LOADED,
    payload: {
      data: response.data,
    },
  });
  dispatchLoader({ type: LoaderActions.PAGE_LOADED });
};
export const onReload = async (context, dispatch, dispatchLoader) => {
  // const pageSize = localStorage.getItem("pageSize") ?? 10;
  // const lessonHoursData = await LessonHourRepository.getLessonHours(
  //   new URLSearchParams({
  //     ...context.queryParams,
  //     pageSize: pageSize,
  //   }).toString()
  // );
  dispatchLoader({ type: LoaderActions.PAGE_LOADING });
  const allBranchs = await BranchesRepository.getAllBranches();
  const allStaffs = await BranchesRepository.getAllStaffs();
  dispatch({
    type: BranchesActions.PAGE_LOADED,
    payload: {
      data: allBranchs.data,
      staffs: allStaffs.data,
    },
  });
  dispatchLoader({ type: LoaderActions.PAGE_LOADED });
};
export const cancelDeleteAction = (dispatch) => {
  dispatch({
    type: BranchesActions.CANCEL_BUTTON_CLICKED,
  });
};

export const createAction = (dispatch) => {
  dispatch({
    type: BranchesActions.CREATE_BUTTON_CLICKED,
  });
};

export const deleteAction = async (e, context, dispatch, dispatchLoader) => {
  e.preventDefault();
  dispatch({
    type: BranchesActions.SHOW_DELETE_LOADER,
    payload: true,
  });
  const response = await BranchesRepository.deleteBranch(context.deletingId);
  if (response.status === true) {
    toastSuccess("Bino turi o'chirib yuborildi");
  } else {
    toastError("Bino turi o'chirilmadi!");
  }
  dispatch({
    type: BranchesActions.SHOW_DELETE_LOADER,
    payload: false,
  });
  loadItems({}, context, dispatch, dispatchLoader);
  cancelDeleteAction(dispatch);
};

export const deleteConfirmAction = (branch, dispatch) => {
  dispatch({
    type: BranchesActions.DELETE_BUTTON_CLICKED,
    payload: { id: branch.id },
  });
};

export const updateAction = async (branch, dispatch) => {
  const response = await BranchesRepository.getBranchById(branch.id);
  if (response.status === false) {
    toastError("Serverdan ma'lumot olib bo'lmadi");
    dispatch({ type: BranchesActions.CANCEL_BUTTON_CLICKED });
    return;
  }
  dispatch({
    type: BranchesActions.UPDATE_BUTTON_CLICKED,
    payload: { id: branch.id, item: response.data },
  });
};

export const toggleContextMenu = (branch, dispatch, e, context) => {
  e.stopPropagation();
  dispatch({
    type: BranchesActions.TOGGLE_CONTEXT_MENU,
    payload: context.openContextMenuId ? { id: null } : { id: branch.id },
  });
};

export const openListTable = (context, id, dispatch) => {
  dispatch({
    type: BranchesActions.TOGGLE_LIST_TABLE,
    payload: context.listId === id ? null : id,
  });
};
