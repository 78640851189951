import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AdminLayout from "../../../AdminLayout";
import DeleteConfirmation from "../../../Components/DeleteConfirmation";
import EmptyContent from "../../../Components/Empty-content/EmptyContent";
import ItemsLoader from "../../../Components/Loader/basic loader/ItemsLoader";
import PaginationComponent from "../../../Components/Pagination";
import TitleBar from "../../../Components/TitleBar";
import { useSalaryAdvanceListStore } from "../../../Store/CashControlStore/SalaryAdvanceListStore";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import Heading from "./components/Heading";
import ListItem from "./components/ListItem";

export default function SalaryAdvanceList() {
  const {
    loadItems,
    onReload,
    results,
    pagination,
    queryParams,
    updateParams,
    setLoading,
    isLoading,
    deleteTransaction,
    isShowDeleteLoader,
    deletingId,
  } = useSalaryAdvanceListStore();

  const { setLoader } = useLoaderStore();

  const paginateTo = useCallback(
    async (page) => {
      const queryString = new URLSearchParams({
        ...queryParams,
        page: page,
      }).toString();
      setLoading(true);
      updateParams({ page: page });
      await loadItems(queryString);
      setLoading(false);
    },
    [queryParams]
  );

  const pageLoad = useCallback(async () => {
    setLoader(true);
    await onReload();
    setLoader(false);
  }, []);

  useEffect(() => {
    pageLoad();

    document.body.addEventListener("click", handleClickClosePanelFromOutside);
    return () => {
      document.body.removeEventListener(
        "click",
        handleClickClosePanelFromOutside
      );
    };
  }, []);

  const handleClickClosePanelFromOutside = (e) => {
    if (!e.target.className.includes("build-table__item--7")) {
      useSalaryAdvanceListStore.setState({
        open_context_id: null,
      });
    }
  };
  const deleteAction = async (e) => {
    e.preventDefault();
    const findTransaction = results?.find((item) => item.id === deletingId);
    await deleteTransaction(deletingId, findTransaction?.payment_mode);
  };

  const cancelConfirmation = (e) => {
    e.preventDefault();
    useSalaryAdvanceListStore.setState({
      deletingId: null,
      isShowDeleteLoader: false,
    });
  };

  const { t } = useTranslation();

  return (
    <AdminLayout>
      <TitleBar title={t("Xodimlarga ish haqqi va avans berish ro'yxati")} />
      <div className="container">
        <Heading />
        {isLoading ? (
          <ItemsLoader size={70} />
        ) : results?.length > 0 ? (
          results?.map((item, index) => {
            return <ListItem item={item} index={index} key={index} />;
          })
        ) : (
          <EmptyContent />
        )}
        <DeleteConfirmation
          showModal={{
            deletingId: deletingId,
            isShowDeleteLoader: isShowDeleteLoader,
          }}
          deleteCallback={deleteAction}
          cancelCallback={cancelConfirmation}
        />
        <PaginationComponent
          context={{ pagination: pagination, queryParams: queryParams }}
          callback={paginateTo}
        />
      </div>
    </AdminLayout>
  );
}
