import { useFormik } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import ReactLoading from "react-loading";
import * as Yup from "yup";
import formatDate from "../../../../Components/FormatDate";
import { LoaderContext } from "../../../../Contexts/LoaderContext";
import { EmployeeRegistrationContext } from "../../../../Contexts/RegistrationContex/EmployeeRegistrationContext";
import { toastError, toastSuccess } from "../../../../Helpers/toasts";
import EmployeeRepository from "../../../../Repositories/Registration/EmployeeRepository";
import { loadItems } from "../actions";

export default function RemoveStaffModal({ cancelCallback, showModal }) {
  const [loader, setLoader] = useState(false);
  const { employee, dispatchEmployee } = useContext(
    EmployeeRegistrationContext
  );
  const { dispatchLoader } = useContext(LoaderContext);

  useEffect(() => {
    formik.resetForm();
  }, [showModal]);

  let initialValues = {
    leaving_comment: "",
    leaving_reason: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: false,
    validationSchema: Yup.object({
      leaving_comment: Yup.string()
        .min(0, "Ishdan olishga sababni kiriting !")
        .required("Ishdan olishga sababni kiriting !"),
      leaving_reason: Yup.string()
        .min(0, "Xodimning ish fa'oliyati haqida !")
        .required("Xodimning ish fa'oliyati haqida !"),
    }),
    onSubmit: (values) => {
      setLoader(true);
      const date = formatDate();
      const data = {
        working_status: 3,
        last_working_day: date,
      };
      updateItem({ ...values, ...data });
    },
  });

  const updateItem = useCallback(async (data) => {
    const responseData = await EmployeeRepository.updateStaff(
      data,
      showModal.removeId
    );
    setLoader(false);
    if (responseData.status === true) {
      toastSuccess("Xodim ishdan olindi");
      loadItems({}, employee, dispatchEmployee, dispatchLoader);
      cancelCallback();
    } else {
      toastError("Xodimni ishdan olib bolmadi!");
    }
  });

  return (
    <div
      id="modal"
      className={
        showModal.removeId ? "modal modal-select active" : "modal modal-select"
      }
    >
      <form
        onClick={(e) => e.stopPropagation()}
        className="modal-wrapper"
        style={{ width: "480px" }}
        onSubmit={formik.handleSubmit}
      >
        <div className="modal-inner orange_border">
          <h4 className="modal__title orange_background">
            Ushbu xodimni ishdan olinsinmi ?
          </h4>
          <div className="modal-select__box remove_staff_modal">
            <label htmlFor="leaving_reason">Xodim haqida</label>
            <select
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.leaving_reason || ""}
              className={
                formik.touched.leaving_reason && formik.errors.leaving_reason
                  ? "modal-input error_input"
                  : "modal-input "
              }
              id="leaving_reason"
              name="leaving_reason"
            >
              <option
                className="course_disabled_option"
                disabled
                hidden
                value={""}
              ></option>
              {employee.leavingReasons?.map((element) => {
                return (
                  <option key={element.value} value={element.value}>
                    {element.verbose_name}
                  </option>
                );
              })}
            </select>
            {formik.touched.leaving_reason && formik.errors.leaving_reason ? (
              <p className="input-error-message ">
                {formik.errors.leaving_reason}
              </p>
            ) : null}
          </div>
          <div className="modal-select__box remove_staff_modal">
            <label htmlFor="login">Ishdan olishga sababni kiriting</label>
            <input
              name="leaving_comment"
              id="login"
              className={
                formik.touched.leaving_comment && formik.errors.leaving_comment
                  ? "modal-input error_input"
                  : "modal-input "
              }
              value={formik.values.leaving_comment || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="text"
            />
            {formik.touched.leaving_comment && formik.errors.leaving_comment ? (
              <p className="input-error-message ">
                {formik.errors.leaving_comment}
              </p>
            ) : null}
          </div>
        </div>
        <div className="input-buttons">
          <button
            id="modalClose"
            type="button"
            className="input-btn"
            onClick={() => cancelCallback()}
          >
            Bekor qilish
          </button>
          <button
            className="input-btn input-btn--submit orange_background"
            type="submit"
            disabled={loader || !formik.isValid}
          >
            {loader ? (
              <ReactLoading
                type={"spin"}
                color="#ffffff"
                className="delete_loader"
                height={"30px"}
                width={"30px"}
              />
            ) : (
              "Ishdan olish"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
