import { Box, Button } from "@mui/material";
import React from "react";

const container = {
  width: "100%",
  display: "flex",
  alighnItems: "center",
  justifyContent: "end",
  gap: "0 20px  ",
};

export default function SaveButton({
  callbackSubmit,
  callbackCancel,
  disabled,
  clearText,
  saveText,
}) {
  return (
    <Box sx={container}>
      <Button
        variant="outlined"
        sx={{ minWidth: "120px", margin: "5px 0" }}
        onClick={callbackCancel}
      >
        {clearText}
      </Button>
      <Button
        variant="contained"
        onClick={callbackSubmit}
        sx={{ minWidth: "120px", margin: "5px 0" }}
        disabled={disabled}
      >
        {saveText}
      </Button>
    </Box>
  );
}
