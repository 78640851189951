import axiosInstance from "../AxiosInstance";
import { axiosInterceptor } from "../AxiosInterceptor";

export default class ExamDetailsRepository {
  static async getExamDetails(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInstance.get(
        `/management/group/assess-exam/?` + queryString
      );

      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: e.response.data
          ? e.response.data.data.error
          : "Ma'lumot olib bo'lmadi!",
      };
    }
    return result;
  }
  static async submitExamResults(data) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.post(
        "/management/group/assess-exam/",
        data
      );
      if (response.status === 200) {
        result = {
          ...result,
          status: true,
          nonFieldErrors: response.data.message
            ? response.data.message
            : "Imtixon natijalari saqlandi",
        };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: e.response.data
          ? e.response.data.error
          : "Imtixon natijalarini saqlab bo'lmadi!",
      };
    }
    return result;
  }
}
