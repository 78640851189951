import React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAgeGroupReportStore } from "../../../../Store/ReportsStore/AgeGroupReportStore";
import Selector from "./Select";

function Heading() {
  const { teachersList, subjects, branches } = useAgeGroupReportStore();

  const options = useMemo(() => {
    return (
      teachersList?.map((element) => ({
        label: element.fullname,
        value: element.id,
      })) || []
    );
  }, [teachersList]);

  const optionsBranches = useMemo(() => {
    return (
      branches?.map((element) => ({
        label: element.title,
        value: element.id,
      })) || []
    );
  }, [branches]);

  const optionsSubjects = useMemo(() => {
    return (
      subjects?.map((element) => ({
        label: element.name,
        value: element.id,
      })) || []
    );
  }, [subjects]);
  const { t } = useTranslation();
  return (
    <div style={{ marginBottom: "10px", display: "flex", gap: "20px" }}>
      <Selector options={optionsBranches} property={t("Filiallar")} />
      <Selector options={optionsSubjects} property={t("Fanlar")} />
      <Selector options={options} property={t("O'qituvchilar")} />
    </div>
  );
}

export default Heading;
