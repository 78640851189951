import React from "react";
import { useGroupAttendanceDivisionStore } from "../../../../Store/GroupManagement/GroupsAttendanceDivisionStore";

function TotalCounts() {
  const { total_counts } = useGroupAttendanceDivisionStore();
  return (
    <ul className="attendance-table build-table build-table--light build-table__item">
      <li className="build-table__item"></li>
      <li className="build-table__item">{total_counts?.verbose_name}</li>
      <li className="build-table__item">{total_counts?.groups_count}</li>
      <li className="build-table__item">{total_counts?.students_count}</li>
      <li className="build-table__item">
        {total_counts?.never_missed_students}
      </li>
      <li className="build-table__item">{total_counts?.missed_with_reason}</li>
      <li className="build-table__item">
        {total_counts?.missed_without_reason}
      </li>
      <li className="build-table__item color-blue">
        {total_counts?.expelled_students_count}
      </li>
      <li className="build-table__item color-red">
        {total_counts?.black_listed_students_count}
      </li>
    </ul>
  );
}
export default React.memo(TotalCounts);
