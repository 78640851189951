import { t } from "i18next";
import React from "react";
import BlockItem from "../../Components/BlockItem";
import UserPermissionWidget from "../../UserPermissionWidget";

function GroupsStatistics({ viewModule }) {
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <div className={`home-cards-box ${!viewModule ? "d-none" : ""}`}>
      <h1 className="cards__title">
        <img className="cards__img" src="images/cards-4.png" alt="card box" />
        {t("home_groups_statistics")}
      </h1>
      <section className="section-page config">
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_group_ordinal_months}
        >
          <BlockItem
            link="/group-management/group-levelup"
            title={t("home_groups_level_up")}
            image="/images/config-img-1.png"
          />
        </UserPermissionWidget>
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_attendance_age_group}
        >
          <BlockItem
            link="/group-management/groups-attendance-division"
            title={t("home_statistics_by_age_group")}
            image="/images/config-img-3.png"
          />
        </UserPermissionWidget>
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_acquisition_age_group}
        >
          <BlockItem
            link="/group-management/students-acquisition"
            title={t("home_acquisition_by_age_group")}
            image="/images/config-img-5.png"
          />
        </UserPermissionWidget>
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_acquisition_lesson_months}
        >
          <BlockItem
            link="/group-management/students-lesson-acquisition"
            title={t("home_statistics_by_lesson_month")}
            image="/images/config-img-5.png"
          />
        </UserPermissionWidget>
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_rooms_availability}
        >
          <BlockItem
            link="/group-management/rooms-availability"
            title={t("home_rooms_availability")}
            image="/images/config-img-2.png"
          />
        </UserPermissionWidget>
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_teachers__availability}
        >
          <BlockItem
            link="/group-management/teachers-availability"
            title={t("home_teachers_availability")}
            image="/images/config-img-3.png"
          />
        </UserPermissionWidget>
      </section>
    </div>
  );
}

export default GroupsStatistics;
