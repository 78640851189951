import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AdminLayout from "../../../AdminLayout";
import EmptyContent from "../../../Components/Empty-content/EmptyContent";
import ItemsLoader from "../../../Components/Loader/basic loader/ItemsLoader";
import PaginationComponent from "../../../Components/Pagination";
import TitleBar from "../../../Components/TitleBar";
import { useStudentTransferStore } from "../../../Store/GroupManagement/StudentTransferStore";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import Heading from "./components/Heading";
import ListItems from "./components/ListItems";

export default function StudentTransferJournal() {
  const {
    studentLists,
    loadItems,
    uploadParams,
    queryParams,
    setLoading,
    isLoading,
    onReload,
    pagination,
  } = useStudentTransferStore();
  const { setLoader } = useLoaderStore();

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    setLoader(true);
    await onReload();
    setLoader(false);
  };

  const paginateTo = async (page) => {
    uploadParams({ page: page });
    setLoading(true);
    await loadItems(
      new URLSearchParams({ page: page, ...queryParams }).toString()
    );
    setLoading(false);
  };
  const { t } = useTranslation();
  return (
    <AdminLayout>
      <TitleBar
        title={t("O'quvchilarni boshqa guruhga olib o'tish jadvali")}
        createAction={false}
      />
      <div className="container">
        <Heading />
        {isLoading ? (
          <ItemsLoader size={70} />
        ) : studentLists?.results ? (
          studentLists?.results?.map((student, index) => (
            <ListItems key={index} studentItems={student} index={index} />
          ))
        ) : (
          <EmptyContent />
        )}
        <PaginationComponent
          context={{ pagination, queryParams }}
          callback={paginateTo}
        />
      </div>
    </AdminLayout>
  );
}
