import React from "react";
import { useTranslation } from "react-i18next";

export default function TableHead() {
  const { t } = useTranslation();

  return (
    <ul className="debet--grid build-table build-table--bold ">
      <li className="build-table__item">№</li>
      <li className="build-table__item">{t("O'quvchi")}</li>
      <li className="build-table__item">{t("Guruhi")}</li>
      <li className="build-table__item">{t("Filiali")}</li>
      <li className="build-table__item">{t("Summasi")}</li>
      <li className="build-table__item">{t("Nechichi oy qarz")}</li>
      <li className="build-table__item">{t("O'qituvchi")}</li>
      <li className="build-table__item">{t("Oxirgi to'lov qilgan sana")}</li>
      <li className="build-table__item">{t("Tel raqami")}</li>
    </ul>
  );
}
