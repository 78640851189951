import React from "react";
import Container from "../Loader";

export default function ItemsLoader({ size }) {
  return (
    <div className="stage_loader relativeLoader">
      <Container size={size} />
    </div>
  );
}
