import { useFormik } from "formik";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import MainGroupsActions from "../../../../Actions/GroupsActions/MainGroupsActions";
import CommonHeading from "../../../../Components/CommonHeading";
import InfoModule from "../../../../Components/InfoModule";
import PageSizeChanger from "../../../../Components/PageSizeChanger";
import Searchbox from "../../../../Components/Searchbox";
import { MainGroupsContext } from "../../../../Contexts/GroupsContext/MainGroupsContext";
import { loadItems } from "../actions";
import FilterBlock from "./FilterBlock";
import ColorBadge from "./NotificationIcon";
import TableHead from "./TableHead";

export default function Heading() {
  const { mainGroups, dispatchMainGroups } = useContext(MainGroupsContext);
  const { t } = useTranslation();

  const changePageSize = useCallback((e) => {
    dispatchMainGroups({
      type: MainGroupsActions.ADD_QUERY_PARAM,
      payload: { pageSize: e.target.value },
    });

    localStorage.setItem("pageSize", e.target.value);

    if (e.key === "Enter") {
      e.target.value &&
        loadItems({ pageSize: e.target.value }, mainGroups, dispatchMainGroups);
    }
  });

  const searchAction = useCallback((payload) => {
    dispatchMainGroups({
      type: MainGroupsActions.ADD_QUERY_PARAM,
      payload: { ...payload, page: 1 },
    });
    loadItems({ ...payload, page: 1 }, mainGroups, dispatchMainGroups);
  });

  const initialValues = {
    term: mainGroups.queryParams.search_query,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      term: Yup.string().min(1, t("Qidiruvni kiriting!")),
    }),
    onSubmit: (values) => {
      searchAction({
        search_query: values.term,
      });
    },
  });

  return (
    <CommonHeading
      topItems={
        <>
          <Searchbox formik={formik} />
          <FilterBlock />
          <ColorBadge />
          <InfoModule title={mainGroups.total_groups_count || 0} />
        </>
      }
      rightItems={
        <>
          <PageSizeChanger context={mainGroups} callback={changePageSize} />
        </>
      }
      tableHead={<TableHead />}
    />
  );
}
