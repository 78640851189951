import { useTranslation } from "react-i18next";

export default function TableHead() {
  const { t } = useTranslation();
  return (
    <ul className="expenditure-list--grid-with_commit build-table build-table--bold ">
      <li className="build-table__item">№</li>
      <li className="build-table__item">{t("Chiqim turi")}</li>
      <li className="build-table__item">{t("Summasi")}</li>
      <li className="build-table__item">{t("To'lov turi")}</li>
      <li className="build-table__item">{t("Filiali")}</li>
      <li className="build-table__item">{t("Masul xodim")}</li>
      <li className="build-table__item">{t("Kassir")}</li>
      <li className="build-table__item">{t("Sana")}</li>
      <li className="build-table__item">{t("Izoh")}</li>
      <li className="build-table__item"></li>
    </ul>
  );
}
