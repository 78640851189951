import BranchesActions from "../../Actions/ConfigActions/BranchesActions";
import CourseTemplateActions from "../../Actions/ConfigActions/CourseTemplateActions";

export const branchesReducer = (state, action) => {
  switch (action.type) {
    case BranchesActions.PAGE_LOADED:
      return {
        ...state,
        ...action.payload,
      };
    case BranchesActions.CREATE_BUTTON_CLICKED:
      return {
        ...state,
        isModalOpen: true,
        isCreating: true,
        isEditing: false,
      };
    case BranchesActions.UPDATE_BUTTON_CLICKED:
      return {
        ...state,
        isModalOpen: true,
        isCreating: false,
        isEditing: true,
        editingId: action.payload.id,
        item: action.payload.item,
      };
    case BranchesActions.DELETE_BUTTON_CLICKED:
      return {
        ...state,
        isDeleteModalOpen: true,
        deletingId: action.payload.id,
      };
    case BranchesActions.CANCEL_BUTTON_CLICKED:
      return {
        ...state,
        isModalOpen: false,
        isDeleteModalOpen: false,
        isCreating: false,
        isEditing: false,
        editingId: null,
        deletingId: null,
        modalResponseData: [],
      };
    case BranchesActions.TOGGLE_CONTEXT_MENU:
      return {
        ...state,
        openContextMenuId: action.payload.id,
      };
    case BranchesActions.CLOSE_CONTEXT_MENU:
      return {
        ...state,
        openContextMenuId: null,
      };
    case BranchesActions.ADD_QUERY_PARAM:
      const params = { ...state.queryParams, ...action.payload };
      return {
        ...state,
        queryParams: params,
      };
    case BranchesActions.SHOW_DELETE_LOADER:
      return {
        ...state,
        isShowDeleteLoader: action.payload,
      };
    case BranchesActions.MODAL_RESPONSE_DATA:
      return {
        ...state,
        modalResponseData: action.payload,
      };
    case BranchesActions.TOGGLE_LIST_TABLE:
      return {
        ...state,
        listId: action.payload,
      };

    default:
      return state;
  }
};
