import LoaderActions from "../../../Actions/ComponentsActions/LoaderActions";
import MainGroupsActions from "../../../Actions/GroupsActions/MainGroupsActions";
import { toastError, toastSuccess } from "../../../Helpers/toasts";
import CommonRepository from "../../../Repositories/Config/CommonRepository";
import MainGroupsRepository from "../../../Repositories/Groups/MainGroupsRepository";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import { useMainGroupStore } from "../../../Store/ManageGroupsStore/MainGroupStore";

export const onReload = async (context, dispatchMainGroups, dispatchLoader) => {
  const pageSize = localStorage.getItem("pageSize") ?? 10;
  dispatchLoader({ type: LoaderActions.PAGE_LOADING });
  const [
    getAllGroups,
    getSubjects,
    getBranches,
    lessonDaysData,
    lessonStyles,
    staffs,
    filterGroupTypes,
  ] = await Promise.all([
    MainGroupsRepository.getAllGroups(
      new URLSearchParams({
        ...context.queryParams,
        page: 1,
        pageSize: pageSize,
      }).toString()
    ),
    MainGroupsRepository.getAllSubjects(),
    MainGroupsRepository.getAllBranches(),
    MainGroupsRepository.groupWeekDays(),
    CommonRepository.getLessonStyles(),
    CommonRepository.getTeacher(),
    CommonRepository.getGroupTypes(),
  ]);
  dispatchMainGroups({
    type: MainGroupsActions.PAGE_LOADED,
    payload: {
      data: getAllGroups.data?.sub_groups,
      months: getAllGroups.data?.months,
      pagination: getAllGroups.data?.pagination,
      approaching_exams_count: getAllGroups.data?.approaching_exams_count,
      total_groups_count: getAllGroups.data?.total_groups_count,
      lesson_styles: getAllGroups.data?.lesson_styles,
      statistics: getAllGroups.data?.statistics,
      group_statuses: getAllGroups.data?.group_statuses,
      subjects: getSubjects.data.data,
      branches: getBranches.data,
      weekDays: lessonDaysData.data,
      lessonStyle: lessonStyles.data.data,
      staffs: staffs.data,
      filterGroupsType: filterGroupTypes.data.data,
    },
  });
  dispatchLoader({ type: LoaderActions.PAGE_LOADED });
};

export const loadItems = async (payload, context, dispatch) => {
  useLoaderStore.setState({
    isLoading: true,
  });
  const response = await MainGroupsRepository.getAllGroups(
    new URLSearchParams({ ...context.queryParams, ...payload }).toString()
  );
  if (response.status) {
    dispatch({
      type: MainGroupsActions.PAGE_LOADED,
      payload: {
        data: response.data?.sub_groups,
        pagination: response.data?.pagination,
        total_groups_count: response.data?.total_groups_count,
      },
    });
  }
  useLoaderStore.setState({
    isLoading: false,
  });
};

export const createAction = (dispatch) => {
  dispatch({
    type: MainGroupsActions.CREATE_BUTTON_CLICKED,
  });
};
export const toggleContextMenu = (group, dispatch) => {
  dispatch({
    type: MainGroupsActions.TOGGLE_CONTEXT_MENU,
    payload: { id: group.id },
  });
};
export const deleteConfirmAction = (payment, dispatch) => {
  dispatch({
    type: MainGroupsActions.DELETE_BUTTON_CLICKED,
    payload: { id: payment.id },
  });
};

export const updateAction = async (payment, dispatch) => {
  const response = await MainGroupsRepository.getGroupById(payment.id);
  if (response.status === false) {
    toastError("Serverdan ma'lumot olib bo'lmadi");
    dispatch({ type: MainGroupsActions.CANCEL_BUTTON_CLICKED });
    return;
  }
  dispatch({
    type: MainGroupsActions.UPDATE_BUTTON_CLICKED,
    payload: { id: payment.id, item: response.data },
  });
};
export const cancelDeleteAction = (dispatch) => {
  dispatch({
    type: MainGroupsActions.CANCEL_BUTTON_CLICKED,
  });
};

export const deleteAction = async (e, context, dispatch, dispatchLoader) => {
  e.preventDefault();
  dispatch({
    type: MainGroupsActions.SHOW_DELETE_LOADER,
    payload: true,
  });
  const response = await MainGroupsRepository.deleteGroup(context.deletingId);
  if (response.status === true) {
    toastSuccess("Guruh turi o'chirib yuborildi");
  } else {
    toastError("Guruh turi o'chirilmadi!");
  }
  dispatch({
    type: MainGroupsActions.SHOW_DELETE_LOADER,
    payload: false,
  });
  loadItems({}, context, dispatch, dispatchLoader);
  cancelDeleteAction(dispatch);
};

export const openCategories = (e, dispatch) => {
  e.stopPropagation();
  dispatch({ type: MainGroupsActions.TOGGLE_CATEGORIES });
};

export const getTimeSlots = async (
  queryString,
  dispatch,
  dispatchLoader,
  index
) => {
  dispatchLoader({
    type: LoaderActions.SELECT_LOADER,
    payload: { isLoadingRoomsOption: false, isLoadingStaffsOption: true },
  });
  const { data, status } = await CommonRepository.getAvailableTime(queryString);
  {
    index === 0
      ? dispatch({
          type: MainGroupsActions.TIMES0,
          payload: data,
        })
      : dispatch({
          type: MainGroupsActions.TIMES1,
          payload: data,
        });
  }
  dispatchLoader({
    type: LoaderActions.SELECT_LOADER,
    payload: { isLoadingRoomsOption: false, isLoadingStaffsOption: false },
  });
  if (status === false) {
    toastError("Xodimni bo'sh vaqtlarini olib bo'lmadi");
    return;
  }
};
export const getRooms = async (
  queryString,
  dispatch,
  dispatchLoader,
  index
) => {
  dispatchLoader({
    type: LoaderActions.SELECT_LOADER,
    payload: { isLoadingRoomsOption: true, isLoadingStaffsOption: false },
  });
  const { data, status } = await CommonRepository.getRooms(queryString);
  {
    index === 0
      ? dispatch({
          type: MainGroupsActions.BRANCH_ROOMS0,
          payload: data,
        })
      : dispatch({
          type: MainGroupsActions.BRANCH_ROOMS1,
          payload: data,
        });
  }
  dispatchLoader({
    type: LoaderActions.SELECT_LOADER,
    payload: { isLoadingRoomsOption: false, isLoadingStaffsOption: false },
  });
  if (status === false) {
    toastError("Bo'sh xonalarni olib bo'lmadi");
    return;
  }
};

export const openFilterModal = async (dispatch) => {
  const [subjectsData, lessonStylesData, groupTypesData] = await Promise.all([
    CommonRepository.getSubjects(),
    CommonRepository.getLessonStyles(),
    CommonRepository.getLessonStyles(),
  ]);
  dispatch({
    type: MainGroupsActions.FILTER_MODAL_RELOAD,
    payload: {
      modalSubjects: subjectsData.data,
      modalLessonStyles: lessonStylesData.data.data,
      modalGroupTypes: groupTypesData.data.data,
    },
  });
};
export const filterGroups = async (data, dispatch, dispatchLoader) => {
  dispatchLoader({ type: LoaderActions.PAGE_LOADING });
  const response = await MainGroupsRepository.filterGroups(
    new URLSearchParams({ ...data }).toString()
  );
  dispatch({
    type: MainGroupsActions.PAGE_LOADED,
    payload: {
      data: response.data?.sub_groups,
      pagination: response.data?.pagination,
      total_groups_count: response.data?.total_groups_count,
    },
  });
  dispatchLoader({
    type: LoaderActions.PAGE_LOADED,
  });
  if (response.status === false) {
    toastError("Serverdan ma'lumot olishda xatolik yuz berdi!");
    return;
  }
};
export const openListTable = (context, id, dispatch) => {
  dispatch({
    type: MainGroupsActions.TOGGLE_LIST_TABLE,
    payload: context.listId === id ? null : id,
  });
};

export const openFilterList = async (id, dispatch) => {
  useMainGroupStore.setState({ groupID: id });
  dispatch({ type: MainGroupsActions.TOGGLE_FILTER_LIST });
  useMainGroupStore.setState({
    data: null,
    sub_groups: null,
    months: null,
    updateTeacherData: null,
    subGroupsTeacher: null,
    monthsTeacher: null,
    organizeExam: null,
    isLoading: false,
  });
  if (id) {
    useMainGroupStore.setState({ isLoading: true });

    const [stage, update, organize] = await Promise.all([
      MainGroupsRepository.groupStage(
        new URLSearchParams({
          group_id: id,
        }).toString()
      ),
      MainGroupsRepository.groupUpdateTeacher(
        new URLSearchParams({
          group_id: id,
        }).toString()
      ),
      MainGroupsRepository.groupOrganizeExam(
        new URLSearchParams({
          group_id: id,
        }).toString()
      ),
    ]);

    useMainGroupStore.setState({
      data: stage.data,
      sub_groups: stage.data.sub_groups,
      months: stage.data.months,
      updateTeacherData: update.data,
      subGroupsTeacher: update.data.sub_groups,
      monthsTeacher: update.data.months,
      organizeExam: organize.data,
      isLoading: false,
    });
  }
};

export const getNextStageDetail = async (
  groupID,
  { subGroupID1, teacherID1, branchID1, dayID1, timeID1, roomID1 },
  { subGroupID2, teacherID2, branchID2, dayID2, timeID2, roomID2 }
) => {
  useMainGroupStore.setState({ isLoadingTabs: true });
  const { data, status } = await MainGroupsRepository.groupStage(
    new URLSearchParams({
      group_id: groupID,
      [`sub_group${subGroupID1}_teacher_id`]: teacherID1,
      [`sub_group${subGroupID1}_branch_id`]: branchID1,
      [`sub_group${subGroupID1}_days`]: dayID1,
      [`sub_group${subGroupID1}_time_slot_id`]: timeID1,
      [`sub_group${subGroupID1}_room_id`]: roomID1,
      [`sub_group${subGroupID2}_teacher_id`]: teacherID2,
      [`sub_group${subGroupID2}_branch_id`]: branchID2,
      [`sub_group${subGroupID2}_days`]: dayID2,
      [`sub_group${subGroupID2}_time_slot_id`]: timeID2,
      [`sub_group${subGroupID2}_room_id`]: roomID2,
    }).toString()
  );
  if (status) {
    useMainGroupStore.setState({
      data: data,
      sub_groups: data.sub_groups,
      months: data.months,
    });
  }
  if (!status) {
    toastError("Ma'lumot olib bo'lmadi");
  }
  useMainGroupStore.setState({ isLoadingTabs: false });
};

export const getSubTeacherDetail = async (
  groupID,
  { subGroupID1, teacherID1, branchID1, dayID1, timeID1, roomID1 },
  { subGroupID2, teacherID2, branchID2, dayID2, timeID2, roomID2 }
) => {
  useMainGroupStore.setState({ isLoadingTabs: true });
  const { data, status } = await MainGroupsRepository.groupStage(
    new URLSearchParams({
      group_id: groupID,
      [`sub_group${subGroupID1}_teacher_id`]: teacherID1,
      [`sub_group${subGroupID1}_branch_id`]: branchID1,
      [`sub_group${subGroupID1}_days`]: dayID1,
      [`sub_group${subGroupID1}_time_slot_id`]: timeID1,
      [`sub_group${subGroupID1}_room_id`]: roomID1,
      [`sub_group${subGroupID2}_teacher_id`]: teacherID2,
      [`sub_group${subGroupID2}_branch_id`]: branchID2,
      [`sub_group${subGroupID2}_days`]: dayID2,
      [`sub_group${subGroupID2}_time_slot_id`]: timeID2,
      [`sub_group${subGroupID2}_room_id`]: roomID2,
    }).toString()
  );
  if (status) {
    useMainGroupStore.setState({
      updateTeacherData: data,
      subGroupsTeacher: data.sub_groups,
      monthsTeacher: data.months,
    });
  }
  if (!status) {
    toastError("Ma'lumot olib bo'lmadi");
  }
  useMainGroupStore.setState({ isLoadingTabs: false });
};

export const approachingExams = async (dispatchMainGroups) => {
  const queryString = new URLSearchParams({
    approaching_exams: true,
  }).toString();
  const getAllGroups = await MainGroupsRepository.getAllGroups(queryString);
  dispatchMainGroups({
    type: MainGroupsActions.PAGE_LOADED,
    payload: {
      data: getAllGroups.data.sub_groups,
      approaching_exams_count: getAllGroups.data.approaching_exams_count,
      total_groups_count: getAllGroups.data?.total_groups_count,
    },
  });
  return getAllGroups.status;
};
export const subGroups = async (dispatchMainGroups) => {
  const getAllGroups = await MainGroupsRepository.getAllGroups();
  dispatchMainGroups({
    type: MainGroupsActions.PAGE_LOADED,
    payload: {
      data: getAllGroups.data.sub_groups,
      approaching_exams_count: getAllGroups.data.approaching_exams_count,
      total_groups_count: getAllGroups.data?.total_groups_count,
    },
  });
  return getAllGroups.status;
};
export const changeTeacherClicked = async (id) => {
  useMainGroupStore.setState({ isLoadingUpdateTeacher: true });
  const { data, status, nonFieldErrors } =
    await MainGroupsRepository.groupUpdateTeacher(
      new URLSearchParams({
        group_id: id,
      }).toString()
    );
  if (status) {
    useMainGroupStore.setState({
      isLoadingUpdateTeacher: false,
      updateTeacherData: data,
      subGroupsTeacher: data.sub_groups,
      monthsTeacher: data.months,
    });
  }
  toastError(nonFieldErrors);
  useMainGroupStore.setState({ isLoadingUpdateTeacher: false });
};

export const organizExam = async (id) => {
  useMainGroupStore.setState({ isLoadingOrganizeExam: true });
  const { data, status, nonFieldErrors } =
    await MainGroupsRepository.groupOrganizeExam(
      new URLSearchParams({
        group_id: id,
      }).toString()
    );
  if (status) {
    useMainGroupStore.setState({
      isLoadingOrganizeExam: false,
      organizeExam: data,
    });
  }
  toastError(nonFieldErrors);
  useMainGroupStore.setState({ isLoadingOrganizeExam: false });
};
