export default class CourseTemplateActions {
  static PAGE_LOADED = "PAGE_LOADED";
  static UPDATE_BUTTON_CLICKED = "UPDATE_BUTTON_CLICKED";
  static DELETE_BUTTON_CLICKED = "DELETE_BUTTON_CLICKED";
  static CANCEL_BUTTON_CLICKED = "CANCEL BUTTON_CLICKED";
  static TOGGLE_CONTEXT_MENU = "TOGGLE_CONTEXT_MENU";
  static CLOSE_CONTEXT_MENU = "CLOSE_CONTEXT_MENU";
  static ADD_QUERY_PARAM = "ADD_QUERY_PARAM";
  static TOGGLE_EXPORT_BLOCK = "TOGGLE_EXPORT_BLOCK";
  static CLOSE_EXPORT_BLOCK = "CLOSE_EXPORT_BLOCK";
  static TOGGLE_LIST_TABLE = "TOGGLE_LIST_TABLE";
  static SHOW_DELETE_LOADER = "SHOW_DELETE_LOADER";
  static CREATE_BUTTON_CLICKED = "CREATE_BUTTON_CLICKED";
  static CANCEL_BUTTON_CLICKED = "CANCEL_BUTTON_CLICKED";
  static COURSE_PRICE_REMOVED = "COURSE_PRICE_REMOVED";
  static TIME_RANGE_REMOVED = "TIME_RANGE_REMOVED";
}
