import React, { useContext } from "react";
import { FaPlus } from "react-icons/fa";

export default function AddPriceItem({ coursePrice }) {
  let number = coursePrice.course_prices?.length;
  return (
    <div
      className="price_block add_price"
      onClick={() =>
        coursePrice.course_prices.push({ month: number + 1, price: "" })
      }
    >
      <div className="price_center">
        <FaPlus className="plus_icon" />
      </div>
    </div>
  );
}
