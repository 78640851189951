import StudentAttachmentAction from "../../Actions/StudentAttachment/StudentAttachmentAction";

export const studentAttachmentReducers = (state, action) => {
  switch (action.type) {
    case StudentAttachmentAction.ADD_QUERY_PARAM:
      const params = { ...state.queryParams, ...action.payload };
      return {
        ...state,
        queryParams: params,
      };
    case StudentAttachmentAction.PAGE_LOADED:
      return {
        ...state,
        ...action.payload,
      };
    case StudentAttachmentAction.STUDENT_ADDED:
      return {
        ...state,
        groupStudents: action.payload,
        filteredGroupData: action.payload,
      };
    case StudentAttachmentAction.CHANGE_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case StudentAttachmentAction.TOGGLE_FILTER_LIST:
      return { ...state, isOpenFilterList: !state.isOpenFilterList };
    case StudentAttachmentAction.CHANGE_FILTERED_DATA:
      return { ...state, filteredData: action.payload };
    case StudentAttachmentAction.CHANGE_GROUP_STUDENT:
      return { ...state, filteredGroupData: action.payload };
    case StudentAttachmentAction.CLOSE_FILTER_LIST:
      return { ...state, isOpenFilterList: false };
  }
};
