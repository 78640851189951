import React, { createContext, useReducer } from "react";
import { studentsReducers } from "../../Reducers/RegistrationReducers/StudentsRedusers";

export const StudentsRegistrationContext = createContext();

const initialState = {
  data: null,
  studyLevels: null,
  students_count: null,
  modalLessonTimes: null,
  modalLessonDays: null,
  modalSubjects: null,
  modalGroupTypes: null,
  branches: null,
  staffs: null,
  startDate: null,
  endDate: null,
  hasGroup: false,
  connectionStatus: null,
  studentInfo: null,
  connectionStatusFilter: [],
  dateRange: [null, null],
  filteredStaffs: null,
  item: null,
  isCreating: false,
  connectId: null,
  returnGroupId: null,
  isEditing: false,
  editingId: null,
  isDeleteModalOpen: false,
  isModalOpen: false,
  deletingId: null,
  openContextMenuId: null,
  isOpenFilterList: false,
  isOpenCategories: false,
  isShowDeleteLoader: false,
  categoryList: [
    { isOpenCategory: true, name: "Ro’yxatdan o’tgan sana" },
    { isOpenCategory: true, name: "Talabaning yoshi" },
    { isOpenCategory: true, name: "Guruh turi" },
    { isOpenCategory: true, name: "Fani" },
    { isOpenCategory: true, name: "O'qiyotgan guruhi" },
    { isOpenCategory: true, name: "O'qituvchi" },
    { isOpenCategory: true, name: "Dars vaqti" },
    { isOpenCategory: true, name: "Bilim darajasi" },
    { isOpenCategory: true, name: "Filial va bo‘lim" },
    { isOpenCategory: true, name: "O'quvchining manzili" },
    { isOpenCategory: true, name: "Telefon nomeri" },
    { isOpenCategory: true, name: "Maktabi" },
    { isOpenCategory: true, name: "Xodim" },
    { isOpenCategory: true, name: "Aloqa natijasi" },
  ],
  queryParams: {
    page: 1,
    has_groups: true,
  },
};

const StudentsProvider = (props) => {
  const [students, dispatchStudents] = useReducer(studentsReducers, {
    ...initialState,
  });

  return (
    <StudentsRegistrationContext.Provider
      value={{ students, dispatchStudents }}
    >
      {props.children}
    </StudentsRegistrationContext.Provider>
  );
};

export default StudentsProvider;
