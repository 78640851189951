import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AdminLayout from "../../../AdminLayout";
import EmptyContent from "../../../Components/Empty-content/EmptyContent";
import ItemsLoader from "../../../Components/Loader/basic loader/ItemsLoader";
import PaginationComponent from "../../../Components/Pagination";
import { useStudentsBillingStore } from "../../../Store/CashControlStore/StudentsBillingStore";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import Heading from "./components/Heading";
import ListItem from "./components/ListItem";
import TitleBar from "./components/TitleBar";

export default function StudentsBilling() {
  const {
    loadItems,
    onReload,
    students,
    pagination,
    queryParams,
    updateParams,
    setLoading,
    isLoading,
  } = useStudentsBillingStore();
  const { setLoader } = useLoaderStore();

  const paginateTo = useCallback(
    async (page) => {
      const queryString = new URLSearchParams({
        ...queryParams,
        page: page,
      }).toString();
      setLoading(true);
      updateParams({ page: page });
      await loadItems(queryString);
      setLoading(false);
    },
    [queryParams]
  );

  const pageLoad = useCallback(async () => {
    const queryString = new URLSearchParams({
      ...queryParams,
    }).toString();
    setLoader(true);
    await onReload(queryString);
    setLoader(false);
  }, []);

  useEffect(() => {
    pageLoad();
  }, []);

  const { t } = useTranslation();

  return (
    <AdminLayout>
      <TitleBar title={t("Qarzdor o'quvchilar ro'yxati")} />
      <div className="container">
        <Heading />
        {isLoading ? (
          <ItemsLoader size={70} />
        ) : students?.length > 0 ? (
          students?.map((item, index) => {
            return <ListItem item={item} index={index} key={item.id} />;
          })
        ) : (
          <EmptyContent />
        )}
        <PaginationComponent
          context={{ pagination: pagination, queryParams: queryParams }}
          callback={paginateTo}
        />
      </div>
    </AdminLayout>
  );
}
