import React, { useContext } from "react";
import { ArchivedGroupsContext } from "../../../../Contexts/GroupsContext/ArchivedGroupsContext";
import { openArchiveInfo, openGroupInfo } from "../actions";

export default function ListItem({ group }) {
  const { archivedGroups, dispatchArchivedGroups } = useContext(
    ArchivedGroupsContext
  );
  return (
    <ul className="archieve-table build-table build-table--light build-table__item">
      <li className="build-table__item">1</li>
      <li
        className="build-table__item"
        onClick={() => openGroupInfo(dispatchArchivedGroups)}
      >
        <a style={{ cursor: "pointer" }}>G - 00387</a>
      </li>
      <li
        className="build-table__item"
        onClick={() => openArchiveInfo(dispatchArchivedGroups)}
      >
        <a style={{ cursor: "pointer" }}>Muhammad Siddiq Main teacher</a>
      </li>
      <li className="build-table__item">Monday 14:00</li>
      <li className="build-table__item">Adults</li>
      <li className="build-table__item">Innovation</li>
      <li className="build-table__item">Ingliz tili</li>
      <li className="build-table__item">Bosh bino 1.4-xona</li>
      <li className="build-table__item">26.03.2021 Juma</li>

      <li className="build-table__item">08.03.2021 Dushanba</li>
      <li className="build-table__item">15 ta</li>
      <li className="build-table__item">
        Belgilangan 12 ta dars soati tugatildi
      </li>
    </ul>
  );
}
