import React from "react";
import { useProfileConfigStore } from "../../../../Store/ProfileConfiguration/ProfileConfigStore";
import DocumentsToggle from "./DocumentsToggle";
import Table from "./Table";
import ColorToggleButton from "./ToggleButton";

export default function Textbooks() {
  const { finance, months, role_documents } = useProfileConfigStore();
  return (
    <div className="textbooks">
      {months?.length > 0 ? (
        <>
          <h1 className="textBookTitle">Dars qo'llanmalari</h1>
          <ColorToggleButton />
        </>
      ) : null}
      {role_documents?.length > 0 ? (
        <>
          <h1 className="textBookTitle">Hujjatlar</h1>
          <DocumentsToggle />
        </>
      ) : null}
      {finance?.payments?.length > 0 ? (
        <>
          <div className="staffPay">
            <h1 className="textBookTitle">To'lovlar</h1>
            <h1 className="textBookTitle">
              Balansim: <span>{finance?.balance} so'm</span>
            </h1>
          </div>
        </>
      ) : null}
      <Table />
    </div>
  );
}
