import { useCallback, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import AdminLayout from "../../../AdminLayout";
import TitleBar from "../../../Components/TitleBar";
import { LoaderContext } from "../../../Contexts/LoaderContext";
import { useAttendanceDetailsStore } from "../../../Store/AttendanceFormStore/AttendanceDeatilsStore";
import { onReload } from "./actions";
import InfoTag from "./components/InfoTag";
import ListItem from "./components/ListItem";
import TableHeading from "./components/TableHeading";
import Details from "./components/Details";
import { useTranslation } from "react-i18next";

export default function AttendanceDetails() {
  const { students, updateStudents, updateAttendanceDates } =
    useAttendanceDetailsStore();
  const { dispatchLoader } = useContext(LoaderContext);
  const Id = useParams();

  const pageReload = useCallback(async () => {
    await onReload(Id, updateStudents, updateAttendanceDates, dispatchLoader);
  }, [Id]);

  useEffect(() => {
    pageReload();
  }, []);

  const { t } = useTranslation();

  return (
    <AdminLayout>
      <TitleBar title={t("Bugungi davomat")} createAction={false} />
      <div className="container">
        <div className="journal detail_journal">
          <Details />
          <div className="desk">
            <TableHeading />
            {students &&
              students?.map((student) => (
                <ListItem student={student} key={student.id} />
              ))}
          </div>
          <InfoTag />
        </div>
      </div>
    </AdminLayout>
  );
}
