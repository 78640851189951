import { create } from "zustand";
import { toastError } from "../../Helpers/toasts";
import MainGroupsRepository from "../../Repositories/Groups/MainGroupsRepository";
import ReadyReportsRepository from "../../Repositories/Reports/ReadyReportsRepository";

export const useReadyReportStore = create((set, get) => ({
  results: null,
  isLoading: false,
  filterset: null,
  statistics: null,
  startDate: null,
  endDate: null,
  subject: null,
  branches: null,
  queryParams: {},
  setLoading: (status) => {
    set({
      isLoading: status,
    });
  },
  updateParams: (paramItem) => {
    set((prevState) => ({
      queryParams: { ...prevState.queryParams, ...paramItem },
    }));
  },
  onReload: async (queryString = "") => {
    const { data, status, nonFieldErrors } =
      await ReadyReportsRepository.getResults(queryString);
    if (status) {
      set({
        results: data.results,
        filterset: data.filterset,
        statistics: data.statistics,
        columns: data.columns,
        start_date: data.start_date,
        end_date: data.end_date,
        subject: data.subject,
        queryParams: {},
      });
    } else toastError(nonFieldErrors);
  },
  loadItems: async (queryString) => {
    const { data, status, nonFieldErrors } =
      await ReadyReportsRepository.getResults(queryString);
    if (status) {
      set({
        results: data.results,
        statistics: data.statistics,
      });
    } else toastError(nonFieldErrors);
  },
  getBranches: async () => {
    const { data, status } = await MainGroupsRepository.getAllBranches()
    if (status) {
      set({
        branches: data,
      })
    }
  }
}));
