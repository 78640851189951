import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useCallback } from "react";
import { useFinishedStudents } from "../../../../Store/ReportsStore/FinishedStudentsStore";
import { useTranslation } from "react-i18next";

export default function FormControlLabelPosition() {
  const { loadItems, setLoading, updateParams, queryParams } =
    useFinishedStudents();

  const handleChange = useCallback(
    async (e) => {
      setLoading(true);
      updateParams({
        has_certificate: e.target.checked,
        page: 1,
      });
      await loadItems(
        new URLSearchParams({
          ...queryParams,
          has_certificate: e.target.checked,
          page: 1,
        }).toString()
      );
      setLoading(false);
    },
    [queryParams]
  );
  const { t } = useTranslation();
  return (
    <FormGroup aria-label="position" row sx={{ fontSize: "10px" }}>
      <FormControlLabel
        onChange={handleChange}
        control={
          <Checkbox size="small" checked={queryParams.has_certificate} />
        }
        label={t("Sertifikati borlar o'quvchilar")}
        labelPlacement="end"
      />
    </FormGroup>
  );
}
