import StudentsRegistrationActions from "../../Actions/RegistrationActions/StudentsRegistrationActions";

export const studentsReducers = (state, action) => {
  switch (action.type) {
    case StudentsRegistrationActions.CREATE_BUTTON_CLICKED:
      return {
        ...state,
        isModalOpen: true,
        isCreating: true,
        isEditing: false,
      };
    case StudentsRegistrationActions.ADD_QUERY_PARAM:
      const params = { ...state.queryParams, ...action.payload };
      return {
        ...state,
        queryParams: params,
      };
    case StudentsRegistrationActions.PAGE_LOADED:
      return {
        ...state,
        ...action.payload,
      };
    case StudentsRegistrationActions.TOGGLE_CONTEXT_MENU:
      return { ...state, openContextMenuId: action.payload.id };
    case StudentsRegistrationActions.CONNECT_MODAL:
      return { ...state, connectId: action.payload.id };
    case StudentsRegistrationActions.RETURN_GROUP:
      return { ...state, returnGroupId: action.payload.id };
    case StudentsRegistrationActions.CLOSE_CONTEXT_MENU:
      return {
        ...state,
        openContextMenuId: null,
      };
    case StudentsRegistrationActions.DELETE_BUTTON_CLICKED:
      return {
        ...state,
        isDeleteModalOpen: true,
        deletingId: action.payload.id,
      };
    case StudentsRegistrationActions.CHANGE_DATE:
      return {
        ...state,
        dateRange: action.payload,
      };
    case StudentsRegistrationActions.CHANGE_HAS_GROUP:
      return {
        ...state,
        hasGroup: action.payload,
      };
    case StudentsRegistrationActions.SHOW_DELETE_LOADER:
      return {
        ...state,
        isShowDeleteLoader: action.payload,
      };
    case StudentsRegistrationActions.CANCEL_BUTTON_CLICKED:
      return {
        ...state,
        isModalOpen: false,
        isDeleteModalOpen: false,
        isCreating: false,
        isEditing: false,
        editingId: null,
        deletingId: null,
        connectId: null,
        returnGroupId: null,
      };
    case StudentsRegistrationActions.UPDATE_BUTTON_CLICKED:
      return {
        ...state,
        isModalOpen: true,
        isCreating: false,
        isEditing: true,
        editingId: action.payload.id,
        item: action.payload.item,
      };
    case StudentsRegistrationActions.TOGGLE_FILTER_LIST:
      return { ...state, isOpenFilterList: !state.isOpenFilterList };
    case StudentsRegistrationActions.TOGGLE_CATEGORIES:
      return { ...state, isOpenCategories: !state.isOpenCategories };
    case StudentsRegistrationActions.CLOSE_FILTER_LIST:
      return { ...state, isOpenFilterList: false };
    case StudentsRegistrationActions.CLOSE_CATEGORIES:
      return { ...state, isOpenCategories: false };
    case StudentsRegistrationActions.CATEGORY_LIST:
      return {
        ...state,
        categoryList: action.payload,
      };
    case StudentsRegistrationActions.FILTER_MODAL_RELOAD:
      return {
        ...state,
        ...action.payload,
      };
    case StudentsRegistrationActions.EMPTY_STAFFS:
      return {
        ...state,
        filteredStaffs: action.payload,
      };
    case StudentsRegistrationActions.CHANGE_CONNECTION_STATUS:
      return {
        ...state,
        connectionStatusFilter: action.payload,
      };
    case StudentsRegistrationActions.STUDENT_INFO:
      return {
        ...state,
        studentInfo: action.payload,
      };
    default:
      return state;
  }
};
