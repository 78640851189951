import { toastError, toastSuccess } from "../../../Helpers/toasts";
import LoaderActions from "../../../Actions/ComponentsActions/LoaderActions";
import CommonRepository from "../../../Repositories/Config/CommonRepository";
import StudentsRepository from "../../../Repositories/Registration/StudentsRepository";
import StudentsRegistrationActions from "../../../Actions/RegistrationActions/StudentsRegistrationActions";

export const onReload = async (context, dispatch, dispatchLoader) => {
  // const pageSize = localStorage.getItem("pageSize") ?? 10;
  // const mainGroupsDate = await StudentsRepository.getAllGroups(
  //   new URLSearchParams({
  //     ...context.queryParams,
  //     pageSize: pageSize,
  //   }).toString()
  // );
  dispatchLoader({ type: LoaderActions.PAGE_LOADING });
  const [
    students,
    subjectsData,
    lessonDaysData,
    lessonTimesData,
    connectionStatus,
    getAllBranches,
    staffs,
    groupTypesData,
  ] = await Promise.all([
    StudentsRepository.getAllStudents(
      new URLSearchParams({ page: 1, has_group: true }).toString()
    ),
    CommonRepository.getSubjects(),
    CommonRepository.getLessonDays(),
    CommonRepository.getAvailableTime(),
    CommonRepository.getConnectionStatus(),
    StudentsRepository.getAllBranches(),
    StudentsRepository.getEmptyStaffs(),
    CommonRepository.getGroupTypes(),
  ]);
  dispatch({
    type: StudentsRegistrationActions.PAGE_LOADED,
    payload: {
      data: students.data.data.results,
      studyLevels: students.data.study_levels,
      branches: getAllBranches.data,
      modalSubjects: subjectsData.data,
      modalLessonDays: lessonDaysData.data,
      modalLessonTimes: lessonTimesData.data,
      connectionStatus: connectionStatus.data.data,
      staffs: staffs.data?.teachers,
      modalGroupTypes: groupTypesData.data.data,
      pagination: { pages_count: students.data.data.pages_count },
      students_count: students.data.students_count,
    },
  });
  dispatchLoader({ type: LoaderActions.PAGE_LOADED });
};

export const loadItems = async (payload, context, dispatch, dispatchLoader) => {
  dispatchLoader({ type: LoaderActions.PAGE_LOADING });
  const response = await StudentsRepository.getAllStudents(
    new URLSearchParams({ ...context.queryParams, ...payload }).toString()
  );
  dispatch({
    type: StudentsRegistrationActions.PAGE_LOADED,
    payload: {
      data: response.data.data.results,
      pagination: { pages_count: response.data.data.pages_count },
      students_count: response.data.students_count,
    },
  });
  dispatchLoader({ type: LoaderActions.PAGE_LOADED });
};

export const createAction = (dispatch) => {
  dispatch({
    type: StudentsRegistrationActions.CREATE_BUTTON_CLICKED,
  });
};
export const toggleContextMenu = (student, dispatch) => {
  dispatch({
    type: StudentsRegistrationActions.TOGGLE_CONTEXT_MENU,
    payload: { id: student.id },
  });
};
export const connectAction = (student, dispatch) => {
  dispatch({
    type: StudentsRegistrationActions.CONNECT_MODAL,
    payload: { id: student.id },
  });
};
export const returnGroup = (student, dispatch) => {
  dispatch({
    type: StudentsRegistrationActions.RETURN_GROUP,
    payload: { id: student.id },
  });
};
export const deleteConfirmAction = (payment, dispatch) => {
  dispatch({
    type: StudentsRegistrationActions.DELETE_BUTTON_CLICKED,
    payload: { id: payment.id },
  });
};
export const hasGroupAction = (e, dispatch) => {
  dispatch({
    type: StudentsRegistrationActions.CHANGE_HAS_GROUP,
    payload: e,
  });
};
export const updateAction = async (payment, dispatch) => {
  const response = await StudentsRepository.getStudentById(payment.id);
  if (response.status === false) {
    toastError("Serverdan ma'lumot olib bo'lmadi");
    dispatch({ type: StudentsRegistrationActions.CANCEL_BUTTON_CLICKED });
    return;
  }
  dispatch({
    type: StudentsRegistrationActions.UPDATE_BUTTON_CLICKED,
    payload: { id: payment.id, item: response.data },
  });
};
export const cancelDeleteAction = (dispatch) => {
  dispatch({
    type: StudentsRegistrationActions.CANCEL_BUTTON_CLICKED,
  });
  dispatch({
    type: StudentsRegistrationActions.SHOW_DELETE_LOADER,
    payload: false,
  });
};
export const openStudentInfo = (data, dispatch) => {
  dispatch({
    type: StudentsRegistrationActions.STUDENT_INFO,
    payload: data,
  });
};
export const closeStudentInfo = (dispatch) => {
  dispatch({
    type: StudentsRegistrationActions.STUDENT_INFO,
    payload: null,
  });
};

export const deleteAction = async (e, context, dispatch, dispatchLoader) => {
  e.preventDefault();
  dispatch({
    type: StudentsRegistrationActions.SHOW_DELETE_LOADER,
    payload: true,
  });
  const response = await StudentsRepository.deleteStudent(context.deletingId);
  if (response.status === true) {
    toastSuccess("O'quvchi o'chirib yuborildi");
    dispatch({
      type: StudentsRegistrationActions.SHOW_DELETE_LOADER,
      payload: false,
    });
    cancelDeleteAction(dispatch);
    await loadItems({}, context, dispatch, dispatchLoader);
  } else {
    toastError("O'quvchi o'chirilmadi!");
  }
};

export const openFilterList = (dispatch) => {
  dispatch({ type: StudentsRegistrationActions.TOGGLE_FILTER_LIST });
};
export const openCategories = (e, dispatch) => {
  e.stopPropagation();
  dispatch({ type: StudentsRegistrationActions.TOGGLE_CATEGORIES });
};
export const getEmptyStaffs = async (
  working_place,
  dispatch,
  dispatchLoader
) => {
  dispatchLoader({
    type: LoaderActions.SELECT_LOADER,
    payload: { isLoadingRoomsOption: false, isLoadingStaffsOption: true },
  });
  const response = await StudentsRepository.getEmptyStaffs(
    new URLSearchParams({
      working_place: working_place,
    }).toString()
  );
  dispatch({
    type: StudentsRegistrationActions.EMPTY_STAFFS,
    payload: response?.data?.teachers,
  });
  dispatch({
    type: StudentsRegistrationActions.CHANGE_BRANCH,
    payload: true,
  });
  dispatchLoader({
    type: LoaderActions.SELECT_LOADER,
    payload: { isLoadingRoomsOption: false, isLoadingStaffsOption: false },
  });
  if (response.status === false) {
    toastError("Xodimlarni olib bo'lmadi");
    return;
  }
};
export const openFilterModal = async (dispatch) => {
  const subjectsData = await CommonRepository.getSubjects();
  const groupTypesData = await CommonRepository.getGroupTypes();
  dispatch({
    type: StudentsRegistrationActions.FILTER_MODAL_RELOAD,
    payload: {
      modalSubjects: subjectsData.data.data.results,
      modalGroupTypes: groupTypesData.data.data,
    },
  });
};

export const filterGroups = async (data, dispatch, dispatchLoader) => {
  dispatchLoader({ type: LoaderActions.PAGE_LOADING });
  const response = await StudentsRepository.getAllStudents(
    new URLSearchParams({ ...data }).toString()
  );
  dispatch({
    type: StudentsRegistrationActions.PAGE_LOADED,
    payload: {
      data: response.data.data.results,
      pagination: { pages_count: response.data.data.pages_count },
      students_count: response.data.students_count,
    },
  });
  dispatchLoader({
    type: LoaderActions.PAGE_LOADED,
  });
  if (response.status === false) {
    toastError("Serverdan ma'lumot olishda xatolik yuz berdi!");
    return;
  }
};
export async function includeStudent(includeGroupData) {
  const { status, nonFieldErrors } = await StudentsRepository.includeGroup(
    includeGroupData
  );
  if (!status) {
    toastError(nonFieldErrors);
  } else {
    toastSuccess("Guruhga qayta qo'shildi");
  }
  return status;
}
