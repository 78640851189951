import React, { useCallback, useContext } from "react";
import ArchivedGroupsActions from "../../../../Actions/GroupsActions/ArchivedGroupsActions";
import ExportBlock from "../../../../Components/ExportBlock";
import { ArchivedGroupsContext } from "../../../../Contexts/GroupsContext/ArchivedGroupsContext";

export default function ArchiveInfoModal() {
    const { archivedGroups, dispatchArchivedGroups } = useContext(
        ArchivedGroupsContext
      );
    const cancelAction = useCallback(() => {
        dispatchArchivedGroups({
          type: ArchivedGroupsActions.CANCEL_BUTTON_CLICKED,
        });
      });
  return (
    <div
      id="modal"
      className={
        archivedGroups.isModalOpen
          ? "modal active modal-select"
          : "modal modal-select"
      }
    >
      <form
        action="#"
        className="modal-wrapper modal-wrapper_huge modal-wrapper-view modal-space"
        method="POST"
      >
        <div className="modal-inner modal-inner_view">
          <h4 className="modal__title">Arxiv ma’lumotlari</h4>

          <div className="view">
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">Asosiy o‘qituvchi:</div>
                <div className="view-info">Akramov Muhmmad Siddiq</div>
              </div>
              <div className="view-box">
                <div className="view-title">Guruh ochilgan sana:</div>
                <div className="view-info">26.03.2021 Juma</div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">
                  Ro‘yhatga olingan guruh raqami:
                </div>
                <div className="view-info">G-00387</div>
              </div>
              <div className="view-box">
                <div className="view-title">
                  Guruh tugagan yoki yopilgan sana:
                </div>
                <div className="view-info">19.03.2021 Dushanba</div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">Ushbu guruh nechanchi oy:</div>
                <div className="view-info">5-oy</div>
              </div>
              <div className="view-box">
                <div className="view-title">
                  Guruh tugatish yoki yopish sababi:
                </div>
                <div className="view-info">
                  Belgilangan 12 ta dars soati tugadi
                </div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">O‘quv badal to‘lov summasi:</div>
                <div className="view-info">260 000 so‘m</div>
              </div>
              <div className="view-box">
                <div className="view-title">
                  Guruh ochilganda talabalar soni:
                </div>
                <div className="view-info">18-nafar</div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">
                  Dars o‘tilgan hafta kuni va soati:
                </div>
                <div className="view-info">Monday 14:00</div>
              </div>
              <div className="view-box">
                <div className="view-title">
                  Guruh tugatilganda talabalar soni:
                </div>
                <div className="view-info">18-nafar</div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">Guruhning turi:</div>
                <div className="view-info">Adults</div>
              </div>
              <div className="view-box">
                <div className="view-title">To‘lov qilgan talabalar soni:</div>
                <div className="view-info">16-nafar</div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">Guruhning uslubi:</div>
                <div className="view-info">Innovation</div>
              </div>
              <div className="view-box">
                <div className="view-title">
                  To‘lov qilmagan talabalar soni:
                </div>
                <div className="view-info">2-nafar</div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">Dars o‘tgan fani:</div>
                <div className="view-info">Ingliz tili</div>
              </div>
              <div className="view-box">
                <div className="view-title">To‘lov qilmaganligi sababi:</div>
                <div className="view-info">Maxsus chegirma olgan</div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">Dars o‘tilgan xona:</div>
                <div className="view-info">Bosh bino 1.4-xona</div>
              </div>
              <div className="view-box">
                <div className="view-title">O‘qituvchi ish haqqi olganmi:</div>
                <div className="view-info">To‘langan</div>
              </div>
            </div>
          </div>
        </div>

        <div className="input-buttons input-export print-btn">
          <div className="build-top__item build-export__second">
            <ExportBlock />
          </div>
          <div className="grey-btn">
            <img src="/images/print.svg" alt="grey img" />
            Chop etish
          </div>
          <div
            id="modal-close"
            className="input-btn"
            onClick={() => cancelAction()}
          >
            Ortga qaytish
          </div>
        </div>
      </form>
    </div>
  );
}
