import { useTranslation } from "react-i18next";

export default function TableHead() {
  const { t } = useTranslation();
  return (
    <ul className="build-search building build-table build-table--bold">
      <li className="build-table__item build-table__item--1">№</li>
      <li className="build-table__item build-table__item--2">
        {t("branch_name")}
      </li>
      <li className="build-table__item build-table__item--3">
        {t("branch_address")}
      </li>
      <li className="build-table__item build-table__item--4">
        {t("room_count")}
      </li>
      <li className="build-table__item build-table__item--5">
        {t("short_name")}
      </li>
      <li className="build-table__item build-table__item--6">
        {t("created_date")}
      </li>
      <li className="build-table__item build-table__item--7"></li>
    </ul>
  );
}
