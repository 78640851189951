import { useTranslation } from "react-i18next";
import { toastError } from "../../../../../Helpers/toasts";
import { useRefundStore } from "../../../../../Store/CashControlStore/RefundStore";
import SaveButton from "../SaveButton";
import ColorToggleButton from "./ToggleButton";

export default function PaymentSection() {
  const { updateSalary, resetData, salary, groupDetails } = useRefundStore();

  const handleChange = (e) => {
    const inputValue = e.target.value.replace(/ /g, ""); // Remove empty spaces from the input value
    const formattedValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, " "); // Add empty spaces for formatting
    updateSalary({ amountPlaceholder: formattedValue });
    updateSalary({ amount: e.target.value.replace(/ /g, "") });
  };

  const clearFormData = () => {
    resetData();
  };

  const { t } = useTranslation();

  const confirmationCallback = () => {
    if (!groupDetails && !salary.payment_type && !salary.amount) {
      toastError(
        t("Haqdor guruhni, to'lov qiymatini va to'lov turi kiritilishi shart!")
      );
    } else if (!groupDetails) {
      toastError(t("to'lov miqdorini kiritish shart!"));
    } else if (!salary.amount) {
      toastError(t("Ortiqcha mablag'ni kiritish shart!"));
    } else if (!salary.payment_type) {
      toastError(е("To'lov turi kiritilishi shart!"));
    } else {
      useRefundStore.setState({
        confirmation: true,
      });
    }
  };

  return (
    <>
      <div className="payment-place payment-expenditure--space">
        <input
          className="expenditure-input"
          type="text"
          placeholder={t("To'lov miqdori")}
          onChange={handleChange}
          value={salary?.amountPlaceholder || ""}
        />
        <ColorToggleButton />
      </div>
      <SaveButton
        callbackSubmit={() => confirmationCallback()}
        callbackCancel={() => clearFormData()}
        disabled={false}
        clearText={t("Tozalash")}
        saveText={t("Saqlash")}
      />
    </>
  );
}
