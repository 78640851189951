import React, { useCallback, useContext } from "react";
import ArchivedGroupsActions from "../../../../Actions/GroupsActions/ArchivedGroupsActions";
import { ArchivedGroupsContext } from "../../../../Contexts/GroupsContext/ArchivedGroupsContext";

export default function GroupInfoModal() {
  const { archivedGroups, dispatchArchivedGroups } = useContext(
    ArchivedGroupsContext
  );
  const cancelAction = useCallback(() => {
    dispatchArchivedGroups({
      type: ArchivedGroupsActions.CANCEL_BUTTON_CLICKED,
    });
  });
  return (
    <div
      id="modal"
      className={
        archivedGroups.isInfoModalOpen
          ? "modal active modal-select"
          : "modal  modal-select"
      }
    >
      <form
        action="#"
        className="modal-wrapper modal-wrapper_huge modal-wrapper-view modal-space"
        method="POST"
      >
        <div className="modal-inner modal-inner_view">
          <h4 className="modal__title">Ma‘lumotlari</h4>
          <div className="view">
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">Asosiy o‘qituvchi:</div>
                <div className="view-info">Akramov Muhmmad Siddiq</div>
              </div>
              <div className="view-box">
                <div className="view-title">
                  Guruhdan chiqarib yuborilgan o‘quvchilar:
                </div>
                <div className="view-info">1 nafar</div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">
                  Ro‘yhatga olingan guruh raqami:
                </div>
                <div className="view-info">G-00387</div>
              </div>
              <div className="view-box">
                <div className="view-title">
                  Eng ko‘p Smart karta olgan o‘quvchi:{" "}
                </div>
                <div className="view-info">1 nafar</div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">Guruhning turi:</div>
                <div className="view-info">Adults</div>
              </div>
              <div className="view-box">
                <div className="view-title">Warning karta olgan o‘quvchi: </div>
                <div className="view-info">3 nafar</div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">
                  Guruhdagi jami o‘quvchilar soni:
                </div>
                <div className="view-info">18 nafar</div>
              </div>
              <div className="view-box">
                <div className="view-title">Guruh ochilgan sana:</div>
                <div className="view-info">26.03.2021 Juma</div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">Doimiy faol o‘quvchilar:</div>
                <div className="view-info">12 nafar</div>
              </div>
              <div className="view-box">
                <div className="view-title">Birinchi dars boshlangan sana:</div>
                <div className="view-info">26.03.2021 Juma</div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">
                  Sababli dars qoldirgan o‘quvchilar:
                </div>
                <div className="view-info">3 nafar</div>
              </div>
              <div className="view-box">
                <div className="view-title">Oxirgi dars tugash sanasi:</div>
                <div className="view-info">26.04.2021 Juma</div>
              </div>
            </div>
            <div className="view__item">
              <div className="view-box">
                <div className="view-title">
                  Sababsiz dars qoldirgan o‘quvchilar:
                </div>
                <div className="view-info">2 nafar</div>
              </div>
              <div className="view-box">
                <div className="view-title">
                  To‘lov uchun chegirma olgan o‘quvchilar:
                </div>
                <div className="view-info">1 nafar</div>
              </div>
            </div>
          </div>
        </div>

        <div className="input-buttons">
          <div
            id="modal-close"
            className="input-btn"
            onClick={() => cancelAction()}
          >
            Bekor qilish
          </div>
        </div>
      </form>
    </div>
  );
}
