import Board from "react-trello";
import { toastError } from "../../../Helpers/toasts";
import TrelloRepository from "../../../Repositories/Trello/Trellorepository";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import { useTrelloStore } from "../../../Store/Trello/TrellosStore";

const boardStyle = {
  backgroundColor: "#fff",
  borderRadius: "10px",
  minHeight: "50vh",
  height: "auto",
};

const lineStyle = {
  backgroundColor: "#f1f5f5",
};

const cardStyle = {
  backgroundColor: "#ffffff",
};

function TrelloDashboard() {
  const { lanes, loadItems, getCardDetails } = useTrelloStore();
  const { setLoader } = useLoaderStore();
  const data = { lanes: lanes || [] };

  const onCardMoveAcrossLanes = async (fromLaneId, toLaneId, cardId, index) => {
    const { status, nonFieldErrors } = await TrelloRepository.updateTask(
      { status: toLaneId },
      cardId
    );
    if (status) {
      setLoader(true);
      await loadItems();
      setLoader(false);
    } else {
      toastError(nonFieldErrors);
    }
  };

  const onCardClick = async (cardId) => {
    setLoader(true);
    await getCardDetails(cardId);
    setLoader(false);
  };

  return (
    <Board
      data={data || []}
      draggable={true}
      collapsibleLanes={true}
      hideCardDeleteIcon={true}
      onCardMoveAcrossLanes={onCardMoveAcrossLanes}
      onCardClick={onCardClick}
      style={boardStyle}
      laneStyle={lineStyle}
      cardStyle={cardStyle}
    />
  );
}

export default TrelloDashboard;
