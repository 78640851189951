import { createContext, useReducer } from "react";
import { mainGroupsReducers } from "../../Reducers/GroupsReducers/MainGroupsReducers";

export const MainGroupsContext = createContext();
const initialState = {
  data: null,
  lesson_styles: null,
  months: null,
  group_statuses: null,
  statistics: null,
  subjects: [],
  approaching_exams_count: 0,
  positions: null,
  times: null,
  listId: null,
  lessonStyle: null,
  currentSubject: null,
  currentAgeGroup: null,
  currentDurations: null,
  durationMonths: null,
  rooms: null,
  staffs: null,
  weekDays: null,
  item: null,
  isCreating: false,
  isEditing: false,
  editingId: null,
  isDeleteModalOpen: false,
  isModalOpen: false,
  deletingId: null,
  openContextMenuId: null,
  isOpenFilterList: false,
  isOpenCategories: false,
  isShowDeleteLoader: false,
  queryParams: {
    page: 1,
    search_query: "",
  },
};
export default function MainGroupsProvider(props) {
  const [mainGroups, dispatchMainGroups] = useReducer(mainGroupsReducers, {
    ...initialState,
  });
  return (
    <MainGroupsContext.Provider value={{ mainGroups, dispatchMainGroups }}>
      {props.children}
    </MainGroupsContext.Provider>
  );
}
