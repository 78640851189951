import React from "react";

export default function SubjectToggleBox(data) {
  const group = data.group;
  return (
    <div className="subject-box__item subject-box__item_active">
      <h4 className="subject-box__title">
        {group.name} {group.total_groups}
      </h4>
      <h6 className="subject-box__info">
        {group.lesson_styles?.map((element) => (
          <div className="subject-item" key={element.id}>
            {element.name}{" "}
            {element.count}
          </div>
        ))}
      </h6>
    </div>
  );
}
