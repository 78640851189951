import { useState } from "react";
import { toastError } from "../../../../Helpers/toasts";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getListItemDetail, loadItems } from "../actions";
import { useTranslation } from "react-i18next";

export default function SearchBar({ title }) {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);
  const [searchResults, setSearchResults] = useState([]);
  const { t } = useTranslation();
  const initialValues = { term: "" };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      term: Yup.string().min(1, t("Qidiruvni kiriting!")),
    }),
    onSubmit: (values) => {},
  });

  const handleInputChange = async (event) => {
    setSelectedOptionIndex(-1);
    const query = event.target.value;
    formik.setFieldValue("term", query);
    setTimeout(async () => {
      const { data, status, nonFieldErrors } = await loadItems(query);
      setSearchResults(data);
      if (!status) {
        toastError(nonFieldErrors);
      }
    }, 500);
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      setSelectedOptionIndex((prevIndex) => {
        const newIndex = prevIndex + 1;
        return newIndex >= searchResults?.length ? prevIndex : newIndex;
      });
    }
    if (event.key === "ArrowUp") {
      event.preventDefault();
      setSelectedOptionIndex((prevIndex) => {
        const newIndex = prevIndex - 1;
        return newIndex < 0 ? prevIndex : newIndex;
      });
    }
    if (event.key === "Enter") {
      const findItemWithIndex = searchResults?.find(
        (item, index) => index === selectedOptionIndex
      );
      if (selectedOptionIndex > -1) {
        const name = `${findItemWithIndex.first_name} ${findItemWithIndex.last_name}`;
        formik.setFieldValue("term", name);
        setSearchResults([]);
        getListItemDetail(findItemWithIndex);
      }
    }
  };

  const listItemClick = async (result) => {
    const name = `${result.first_name} ${result.last_name}`;
    formik.setFieldValue("term", name);
    setSearchResults([]);
    getListItemDetail(result);
  };

  return (
    <div className="transfer-box">
      <label className="transfer-label" htmlFor="term">
        {title}
      </label>
      <div className="transfer-search-container">
        <input
          className="transfer-input"
          autoComplete="off"
          id="term"
          type="text"
          name="term"
          placeholder={t("Qidiring")}
          value={formik.values.term}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        {searchResults?.length > 0 && (
          <ul className="students_list-transfer">
            {searchResults?.map((result, index) => (
              <li
                key={result.id}
                onClick={() => listItemClick(result)}
                className={
                  index === selectedOptionIndex ? "selected-option" : ""
                }
              >
                {result.first_name} {result.last_name}
              </li>
            ))}
          </ul>
        )}
      </div>
      {/* <div className="transfer-btn">
        <img src="/images/transfer-search.png" alt="transfer-search" />
      </div> */}
    </div>
  );
}
