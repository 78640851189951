import React from "react";
import Select from "react-select";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "40px",
    minHeight: "40px",
    fontSize: "15px",
    fontWeight: "400",
  }),
};
function Selector({
  value,
  options,
  property,
  handleChange,
  inputChangeHandler,
  disabled = false,
}) {
  return (
    <Select
      className="expendeture-select"
      classNamePrefix="select"
      placeholder={property}
      styles={customStyles}
      options={options}
      value={value}
      isDisabled={disabled}
      isClearable
      onChange={handleChange}
      onInputChange={inputChangeHandler}
    />
  );
}
export default React.memo(Selector);
