import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dateRangeFormat } from "../../../../Helpers/dateFormat";
import { useExamScheduleStore } from "../../../../Store/ManageGroupsStore/ExamScheduleStore";
import { getExamListWithParam } from "../actions";

const DateSection = () => {
  const { updateParams, queryParams, setLoading, start_date, end_date } =
    useExamScheduleStore();

  const changeDateRange = async (date) => {
    updateParams({
      start_date: dateRangeFormat(date[0]) ?? "",
      end_date: dateRangeFormat(date[1]) ?? "",
      page: 1,
    });
    useExamScheduleStore.setState({
      start_date: date[0] ?? "",
      end_date: date[1] ?? "",
    });
    if ((date[0] && date[1]) || (!date[0] && !date[1])) {
      setLoading(true);
      await getExamListWithParam(
        new URLSearchParams({
          ...queryParams,
          start_date: dateRangeFormat(date[0]) ?? "",
          end_date: dateRangeFormat(date[1]) ?? "",
          page: 1,
        }).toString()
      );
    }
    setLoading(false);
  };

  return (
    <div>
      <DatePicker
        className="student-registr--date exam_date-style"
        placeholderText="mm/dd/yy - mm/dd/yy"
        selectsRange={true}
        startDate={start_date}
        endDate={end_date}
        isClearable
        clearButtonClassName="date_clear-btn"
        onChange={(date) => changeDateRange(date)}
      />
    </div>
  );
};

export default React.memo(DateSection);
