import { create } from "zustand";
import { toastError } from "../../Helpers/toasts";
import MainGroupsRepository from "../../Repositories/Groups/MainGroupsRepository";

export const useTeacherAvailabilityStore = create((set, get) => ({
  data: null,
  branches: null,
  lessonStyles: null,
  loader: false,
  queryParams: { lesson_style: 1 },
  setLoader: (status) =>
    set({
      loader: status,
    }),
  getBranches: async () => {
    const { data, status, nonFieldErrors } =
      await MainGroupsRepository.getAllBranches();
    if (status) {
      set({
        branches: data,
      });
    } else toastError(nonFieldErrors);
  },
}));
