import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BackButton from "../../../../Components/BackButton";
import { useEstimatedSalaryStore } from "../../../../Store/CashControlStore/EstimatedSalaryaStore";
import UserPermissionWidget from "../../../../UserPermissionWidget";

function TitleBar({ title }) {
  const { meta } = useEstimatedSalaryStore();
  const user = JSON.parse(localStorage.getItem("user"));
  const { t } = useTranslation();
  return (
    <div className="build-title">
      <div className="title_bar_item">
        <BackButton />
        <h3 className="page-title">{title}</h3>
      </div>
      <UserPermissionWidget
        hasPermission={
          user?.permissions?.view_calculated_monthly_salary_details
        }
      >
        {meta?.show_calculation_button && (
          <div className="title_bar_btns">
            {meta?.has_present_calculation ? (
              <Link
                to={`/cash-control/salary-calculations/${meta?.year}/${
                  meta?.month
                }/${meta?.calculation_id || null}/details`}
              >
                <button className="modalBtn enter">{t("Davom etish")}</button>
              </Link>
            ) : (
              <Link
                to={`/cash-control/salary-calculations/${meta?.year}/${
                  meta?.month
                }/${meta?.calculation_id || null}/details`}
              >
                <button className="modalBtn enter">{t("Oylik ishlash")}</button>
              </Link>
            )}
          </div>
        )}
      </UserPermissionWidget>
    </div>
  );
}
export default React.memo(TitleBar);
