import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AdminLayout from "../../../AdminLayout";
import TitleBar from "../../../Components/TitleBar";
import { useStudentLessonAcquitationStore } from "../../../Store/GroupManagement/StudentLessonAcqusitationStore";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import { onReload } from "./actions";
import Heading from "./components/Heading";
import ListItem from "./components/ListItem";
import TotalCounts from "./components/TotalCounts";

export default function StudentsLessonAcquisition() {
  const { setLoader } = useLoaderStore();
  const { data, getBranches } = useStudentLessonAcquitationStore();

  const pageLoad = async () => {
    setLoader(true);
    await Promise.all([onReload(), getBranches()]);
    setLoader(false);
  };

  useEffect(() => {
    pageLoad();
  }, []);

  const { t } = useTranslation();

  return (
    <AdminLayout>
      <TitleBar
        title={t("Guruh darajasiga ko'ra statistikasi")}
        createAction={false}
      />
      <div className="container">
        <Heading />
        {data?.map((month, index) => (
          <ListItem month={month} key={month.id} index={index} />
        ))}
        <TotalCounts />
      </div>
    </AdminLayout>
  );
}
