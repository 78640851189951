import { useComparisionStore } from "../../../../Store/ReportsStore/ComparisionStore";

function ExtraTable() {
  const { results } = useComparisionStore();
  return (
    <table
      style={{
        marginTop: "20px",
        background: "white",
        border: "1px solid gray",
        width: "max",
        overflow: "auto",
      }}
      border={"1px"}
      className="reports-table"
    >
      {results.map((element, index) => (
        <>
          <tr key={index}>
            <td rowSpan={2}>{element.name}</td>
            {element.data.map((item, indexItem) => (
              <td style={{ fontSize: "10px" }} key={indexItem}>
                {item.student}{" "}
              </td>
            ))}
          </tr>
          <tr>
            {element.data.map((item, indexItem) => (
              <td key={indexItem}>{item.score} </td>
            ))}
          </tr>
        </>
      ))}
    </table>
  );
}

export default ExtraTable;
