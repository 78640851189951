import { create } from "zustand";

export const useAttendanceDetailsStore = create((set) => ({
  data: null,
  students: null,
  id: null,
  currentComment: {},
  updateAttendanceDates: (res) => set({ data: res }),
  updateStudents: (res) => set({ students: res }),
  updateComment: (item) => {
    set((prevState) => ({
      currentComment: { ...prevState.currentComment, ...item },
    }));
  },
}));
