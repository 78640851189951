import React from "react";
import Select from "react-select";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "40px",
    minHeight: "40px",
    fontSize: "15px",
    fontWeight: "400",
  }),
};
function StaffSelector({
  value,
  placeholder,
  disabled = false,
  options,
  handleChange,
}) {
  return (
    <Select
      className="salary-advance__select"
      classNamePrefix="select"
      placeholder={placeholder}
      styles={customStyles}
      options={options}
      value={value}
      isClearable
      isDisabled={disabled}
      onChange={handleChange}
    />
  );
}
export default React.memo(StaffSelector);
