import React from "react";

export default function TableHead() {
  return (
    <ul className="archieve-table build-table build-table--bold ">
      <li className="build-table__item">№</li>
      <li className="build-table__item">Guruhning restor raqami</li>
      <li className="build-table__item">O‘qituvchining ismi va maqomi</li>
      <li className="build-table__item">Dars o‘tiladigan hafta kuni va soat</li>
      <li className="build-table__item">Guruhning turi</li>
      <li className="build-table__item">Guruhning uslubi</li>
      <li className="build-table__item">Qaysi fandan</li>
      <li className="build-table__item">
        Bino va filial hamda dars xona raqami
      </li>
      <li className="build-table__item">Birinchi dars boshlangan sana</li>
      <li className="build-table__item">Tugatilgan va yopilgan sana</li>

      <li className="build-table__item">O‘quvchilar soni</li>
      <li className="build-table__item">
        Tugatilgan va yopilgan guruhlarga sabablar
      </li>

      <li className="build-table__item build-table__item--7"></li>
    </ul>
  );
}
