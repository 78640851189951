import { Box, Checkbox, FormControlLabel } from "@mui/material";
import Select from "react-select";
import React from "react";
import { useMainGroupStore } from "../../../../../Store/ManageGroupsStore/MainGroupStore";
import { useCallback } from "react";
import MainGroupsRepository from "../../../../../Repositories/Groups/MainGroupsRepository";
import { toastError, toastSuccess } from "../../../../../Helpers/toasts";
import { useContext } from "react";
import { MainGroupsContext } from "../../../../../Contexts/GroupsContext/MainGroupsContext";
import SaveButton from "../SaveButton";
import { loadItems, openFilterList } from "../../actions";
import { useTranslation } from "react-i18next";

const container = {
  width: "100%",
  padding: "0px 25px",
  marginTop: "15px",
  display: "flex",
  justifyContent: "space-between",
  zIndex: -99,
};
const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "40px",
    minHeight: "40px",
    width: "100%",
    fontSize: "15px",
    margin: "5px 0",
    zIndex: 99,
  }),
};

function SelectMonth({ selectMonths, monthsOptions }) {
  const { data, sub_groups, months, isLoadingSave } = useMainGroupStore();
  const { mainGroups, dispatchMainGroups } = useContext(MainGroupsContext);
  const selectedMonth = monthsOptions?.find(
    (item) => item.is_selected === true
  );
  const { t } = useTranslation();

  const handleSelectChange = useCallback((e) => {
    const selectedFalse = selectMonths?.map((element) =>
      element.is_selected === true
        ? { ...element, is_selected: false }
        : element
    );
    const selectedTrue = selectedFalse?.map((element) =>
      element.month == e.value ? { ...element, is_selected: true } : element
    );
    useMainGroupStore.setState({ months: selectedTrue });
  });

  const handleSubmit = async () => {
    const submitData = {
      can_change_stage: data.can_change_stage,
      exam_taken: data.exam_taken,
      group_id: data.group_id,
      name: data.name,
      group_number: data.group_number,
      sub_groups: sub_groups,
      months: months,
    };
    useMainGroupStore.setState({ isLoadingSave: true });
    const response = await MainGroupsRepository.stageNextMonth(submitData);
    if (!response.status) {
      toastError(response.nonFieldErrors);
    }
    if (response.status) {
      toastSuccess(t("Guruh keyingi oyga o'tkazildi"));
      await loadItems({}, mainGroups, dispatchMainGroups);
      openFilterList(null, dispatchMainGroups);
    }
    useMainGroupStore.setState({ isLoadingSave: false });
  };
  const handleChange = (e) => {
    useMainGroupStore.setState(({ data }) => ({
      data: {
        ...data,
        exam_taken: !data.exam_taken,
        can_change_stage: !data.can_change_stage,
      },
    }));
  };
  return (
    <>
      <Box sx={{ width: "100%", padding: "0px 25px", zIndex: 999 }}>
        <label className="select_label_group" htmlFor="month-select">
          {t("Keyingi oyi")}
        </label>
        <Select
          id="month-select"
          // className="basic-single manage_group_select"
          classNamePrefix="select"
          options={monthsOptions}
          defaultValue={selectedMonth}
          styles={customStyles}
          onChange={handleSelectChange}
          isDisabled={!data?.can_change_stage}
        />
      </Box>
      <Box sx={container}>
        <FormControlLabel
          checked={data?.exam_taken || false}
          control={<Checkbox onClick={handleChange} />}
          label={t("Avvalgi oylar yopilgan")}
        />
        <SaveButton
          isloading={isLoadingSave}
          callback={handleSubmit}
          disabled={!data?.can_change_stage}
        >
          {t("O'tkazish")}
        </SaveButton>
      </Box>
    </>
  );
}
export default React.memo(SelectMonth);
