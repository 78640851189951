import GroupsIcon from "@mui/icons-material/Groups";

function InfoModule({ title, showIcon = true }) {
  return (
    <div className="info-module">
      {showIcon && <GroupsIcon />}
      <h1>{title}</h1>
    </div>
  );
}

export default InfoModule;
