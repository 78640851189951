import React, { createContext, useReducer } from "react";
import { branchesReducer } from "../../Reducers/ConfigReducers/BranchesReducers";

export const BranchesContext = createContext();

const initialState = {
  data: [],
  isModalOpen: false,
  isDeleteModalOpen: false,
  isCreating: false,
  isEditing: false,
  editingId: null,
  item: [],
  listId: null,
  deletingId: null,
  openContextMenuId: null,
  isShowDeleteLoader: null,
  modalResponseData: [],
  isRoomsShow: false,
  staffs: [],
  queryParams: {
    page: 1,
  },
};

const BranchesProvider = (props) => {
  const [branches, dispatchBranches] = useReducer(branchesReducer, {
    ...initialState,
  });

  return (
    <BranchesContext.Provider value={{ branches, dispatchBranches }}>
      {props.children}
    </BranchesContext.Provider>
  );
};

export default BranchesProvider;
