import React from "react";
import { useNavigate } from "react-router-dom";
import { toastError, toastSuccess } from "../../../../Helpers/toasts";
import ExamDetailsRepository from "../../../../Repositories/Groups/ExamDetailsRepository";
import { useExamDetailsStore } from "../../../../Store/ManageGroupsStore/ExamDetailsStore";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";

function SubmitSection() {
  const {
    students,
    data,
    can_access,
    exam_types,
    setSubmitLoader,
    isSubmitLoading,
  } = useExamDetailsStore();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const details = {
    group_id: data?.group_id,
    lesson_month_id: data?.lesson_month?.id,
    exam_types: exam_types,
    students: students,
    high_passing_score: data?.high_passing_score,
    medium_passing_score: data?.medium_passing_score,
  };

  const goBackNavigate = () => {
    navigate(-1, { replace: true });
  };

  const handleSubmit = async () => {
    if (data?.medium_passing_score && data?.high_passing_score) {
      setSubmitLoader(true);
      const { status, nonFieldErrors } =
        await ExamDetailsRepository.submitExamResults(details);
      setSubmitLoader(false);
      if (status) {
        goBackNavigate();
        toastSuccess(nonFieldErrors);
      } else toastError(nonFieldErrors);
    } else toastError(t("O'tish ballarini kiriting"));
  };

  return (
    <div className="journal-bottom button_position_container">
      <div className="journal-icons journal-icons_feedback"></div>
      {can_access ? (
        <button
          disabled={!can_access}
          className="btn btn-blue submit_btn_position"
          onClick={handleSubmit}
        >
          {isSubmitLoading ? (
            <ReactLoading
              type={"spin"}
              color="#ffffff"
              className="delete_loader"
              height={"25px"}
              width={"25px"}
            />
          ) : (
            t("Saqlash")
          )}
        </button>
      ) : null}
    </div>
  );
}
export default React.memo(SubmitSection);
