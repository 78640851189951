import React from "react";
import BackButton from "../../../../Components/BackButton";
import { useStudentPaymentTransactions } from "../../../../Store/CashControlStore/StudentPaymentTransactions";
function TitleBar({ title }) {
  const { total_income, total_expense } = useStudentPaymentTransactions();
  return (
    <div className="build-title">
      <div className="title_bar_item">
        <BackButton />
        <h3 className="page-title">{title}</h3>
      </div>
      <div className="title_bar_btns">
        <p className="debet-title title--background">
          Jami kirimlar: <span>{total_income}</span>
        </p>
        <p className="debet-title title--background">
          Jami chiqimlar: <span>{total_expense}</span>
        </p>
      </div>
    </div>
  );
}
export default React.memo(TitleBar);
