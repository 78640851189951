import { useFormik } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
import Select from "react-select";
import * as Yup from "yup";
import EmployeeRegistrationAction from "../../../../Actions/RegistrationActions/EmployeeRegistrationAction";
import { LoaderContext } from "../../../../Contexts/LoaderContext";
import { EmployeeRegistrationContext } from "../../../../Contexts/RegistrationContex/EmployeeRegistrationContext";
import { toastError, toastSuccess } from "../../../../Helpers/toasts";
import EmployeeRepository from "../../../../Repositories/Registration/EmployeeRepository";
import { loadItems } from "../actions";
import FileUploadField from "./FileUploadField";

export default function CreateUpdateModal({ branch, position }) {
  const { employee, dispatchEmployee } = useContext(
    EmployeeRegistrationContext
  );
  const { dispatchLoader } = useContext(LoaderContext);
  const [saveLoader, setSaveLoader] = useState(false);
  const [defaultFileValue, setDefaultFilesValue] = useState([]);
  const { t } = useTranslation();
  const FORMIK_RULES = {
    first_name: Yup.string()
      .min(0, t("Xodimni ismini kiriting!"))
      .max(50, t("Belgilar soni ortiqcha!"))
      .required(t("Xodimni ismini kiriting!")),
    last_name: Yup.string()
      .min(0, t("Xodimni familiyasini kiriting!"))
      .max(50, t("Belgilar soni ortiqcha!"))
      .required(t("Xodimni familiyasini kiriting!")),
    middle_name: Yup.string()
      .min(0, t("Xodimni otasini ismi kiriting!"))
      .max(50, t("Belgilar soni ortiqcha!")),
    gender: Yup.number()
      .min(0, t("Xodim jinsini tanlang!"))
      .required(t("Xodim jinsini tanlang!")),
    birth_date: Yup.string()
      .min(0, t("Tug'ilgan sanasini kiriting !"))
      .required(t("Tug'ilgan sanasini kiriting !")),
    document_type: Yup.string().min(0, t("Hujjat turini tanlang!")),
    passport_series: Yup.string().max(20, t("Belgilar soni ortiqcha!")),
    passport_number: Yup.string()
      .min(0, t("Passport raqamini kiriting!"))
      .max(7, t("Belgilar soni ortiqcha!")),
    phone: Yup.string()
      .min(1, t("Telefon raqamini kiriting!"))
      .required(t("Telefon raqamini kiriting!")),
    working_places: Yup.array().min(1, t("Bino turini tanlang!")),
    contract_number: Yup.string().min(
      0,
      t("Buyuruq va mehnat shartnomasi kiritilsin!")
    ),
    password: Yup.string()
      .min(8, t("Kamida 8 ta belgidan iborat parol kiriting!"))
      .required(t("Xodim uchun parol kiriting!")),
  };
  const [formikRules, setFormikRules] = useState(FORMIK_RULES);

  useEffect(() => {
    formik.resetForm();
  }, [employee.isModalOpen]);

  useEffect(() => {
    if (employee.item === null) {
      setFormikRules(FORMIK_RULES);
    } else {
      setFormikRules({ ...FORMIK_RULES, username: null, password: null });
      for (const [key, value] of Object.entries(employee.item ?? {})) {
        switch (key) {
          case "working_places_detailed":
            const setBranchId = value.map((element) =>
              branch.find((item) => item?.value === element.id)
            );
            formik.setFieldValue("working_places", setBranchId, false);
            break;
          case "roles":
            const setPosition = value.map((element) =>
              position.find((item) => item?.value === element.id)
            );
            formik.setFieldValue("roles", setPosition, true);
            break;
          case "documents":
            const setDefaultFiles = value.map((element) => element?.id);
            formik.setFieldValue("documents", setDefaultFiles, true);
            setDefaultFilesValue([
              ...value.map((element) => ({
                name: element?.file.substring(
                  element?.file.lastIndexOf("/") + 1
                ),
              })),
            ]);
            break;
          case "password":
            formik.setFieldValue(key, null, false);
            break;
          case "active_working_day":
            formik.setFieldValue(key, value ? value : null, false);
            break;
          default:
            formik.setFieldValue(key, value ?? "", false);
        }
      }
    }
  }, [employee.item]);

  const initialValues = {
    first_name: "",
    last_name: "",
    middle_name: "",
    gender: "",
    birth_date: "",
    document_type: "",
    passport_series: "",
    passport_number: "",
    working_places: [],
    password: "",
    roles: [],
    phone: "",
    contract_number: "",
    documents: [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object(formikRules),
    onSubmit: (values) => {
      setSaveLoader(true);
      const filterBranchValue = [];
      const filterPositionValue = [];
      values.working_places?.forEach((element) => {
        filterBranchValue.push(element.value);
      });
      values.roles?.forEach((element) => {
        filterPositionValue.push(element.value);
      });
      let data = {
        ...values,
        working_places: filterBranchValue,
        roles: filterPositionValue.filter((value) => value !== null),
      };
      if (data.document_type === "") {
        data = { ...data, document_type: null };
      }
      if (employee.editingId) {
        data = { ...data, password: null };
        if (data.reason_for_leaving === "") {
          data = { ...data, reason_for_leaving: null };
        }
        if (data.first_working_day === "") {
          data = { ...data, first_working_day: null };
        }
        if (data.last_working_day === "") {
          data = { ...data, last_working_day: null };
        }
        updateItem(data, employee.editingId);
      } else createItem(data);
    },
  });

  const updateItem = useCallback(async (data, id) => {
    const formData = {
      ...data,
      leaving_comment: data.leaving_comment == "" ? null : data.leaving_comment,
      photo: data.photo == "" ? null : data.photo,
    };
    const responseData = await EmployeeRepository.updateStaff(formData, id);
    setSaveLoader(false);
    if (responseData.status === true) {
      toastSuccess("Xodim turi yangilandi!");
      loadItems({}, employee, dispatchEmployee, dispatchLoader);
      cancelAction();
    } else {
      toastError(responseData.nonFieldErrors);
    }
  });

  const createItem = useCallback(async (data) => {
    const responseData = await EmployeeRepository.addStaff(data);
    setSaveLoader(false);
    if (responseData.status === true) {
      toastSuccess("Xodim turi kiritildi");
      loadItems({}, employee, dispatchEmployee, dispatchLoader);
      cancelAction();
    } else {
      toastError(responseData.nonFieldErrors);
    }
  });

  const cancelAction = useCallback(() => {
    dispatchEmployee({
      type: EmployeeRegistrationAction.CANCEL_BUTTON_CLICKED,
    });
    formik.resetForm();
    setSaveLoader(false);
  });

  return (
    <div
      id="modal"
      className={
        employee.isEditing || employee.isCreating
          ? "modal modal-select active"
          : "modal modal-select"
      }
    >
      <form
        onClick={(e) => e.stopPropagation()}
        onSubmit={formik.handleSubmit}
        className="modal-wrapper modal-wrapper_huge"
      >
        <div className="modal-inner">
          <h4 className="modal__title">{t("staff_title")}</h4>
          <div className="modal-select__item modal-three">
            <div className="modal-select__box">
              <label htmlFor="first_name">{t("Ismi")}</label>
              <input
                id="first_name"
                className={
                  formik.touched.first_name && formik.errors.first_name
                    ? "modal-input error_input"
                    : "modal-input "
                }
                value={formik.values.first_name || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                name="first_name"
              />
              {formik.touched.first_name && formik.errors.first_name ? (
                <p className="input-error-message ">
                  {formik.errors.first_name}
                </p>
              ) : null}
            </div>
            <div className="modal-select__box">
              <label htmlFor="birth_date">{t("Tug'ilgan sanasi")}</label>
              <input
                id="birth_date"
                className={
                  formik.touched.birth_date && formik.errors.birth_date
                    ? "modal-input error_input"
                    : "modal-input "
                }
                type="date"
                name="birth_date"
                value={formik.values.birth_date || ""}
                onChange={(e) =>
                  formik.setFieldValue("birth_date", e.target.value)
                }
                onBlur={formik.handleBlur}
              />
              {formik.touched.birth_date && formik.errors.birth_date ? (
                <p className="input-error-message ">
                  {formik.errors.birth_date}
                </p>
              ) : null}
            </div>
            <div className="modal-select__box ">
              <label>{t("Shaxsni tasdiqlovchi hujjat turi")}</label>
              <div
                className={
                  formik.touched.document_type && formik.errors.document_type
                    ? "radio checkBox_style error_input"
                    : "radio checkBox_style "
                }
              >
                <div className="student_modal_checkbox">
                  <input
                    type="radio"
                    name="document_type"
                    id="pass"
                    value={1}
                    onChange={formik.handleChange}
                    checked={formik.values.document_type == 1}
                  />
                  <label htmlFor="pass">{t("Passport")}</label>
                </div>
                <div className="student_modal_checkbox">
                  <input
                    type="radio"
                    name="document_type"
                    id="id"
                    value={2}
                    onChange={formik.handleChange}
                    checked={formik.values.document_type == 2}
                  />
                  <label htmlFor="id">{t("ID-Card")}</label>
                </div>
              </div>
              {formik.touched.document_type && formik.errors.document_type ? (
                <p className="input-error-message ">
                  {formik.errors.document_type}
                </p>
              ) : null}
            </div>
          </div>
          <div className="modal-select__item modal-three">
            <div className="modal-select__box">
              <label htmlFor="surname">{t("Familyasi")}</label>
              <input
                id="surname"
                className={
                  formik.touched.last_name && formik.errors.last_name
                    ? "modal-input error_input"
                    : "modal-input "
                }
                value={formik.values.last_name || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                name="last_name"
              />
              {formik.touched.last_name && formik.errors.last_name ? (
                <p className="input-error-message ">
                  {formik.errors.last_name}
                </p>
              ) : null}
            </div>
            <div className="modal-select__box">
              <label htmlFor="gender">{t("Jinsi")}</label>
              <select
                name="gender"
                id="gender"
                onChange={(e) => formik.setFieldValue("gender", e.target.value)}
                onBlur={formik.handleBlur}
                value={formik.values.gender || ""}
                className={
                  formik.touched.gender && formik.errors.gender
                    ? "modal-input error_input"
                    : "modal-input "
                }
              >
                <option
                  className="course_disabled_option"
                  disabled
                  hidden
                  value={""}
                ></option>
                <option value="1">{t("Erkak")}</option>
                <option value="2">{t("Ayol")}</option>
              </select>
              {formik.touched.gender && formik.errors.gender ? (
                <p className="input-error-message ">{formik.errors.gender}</p>
              ) : null}
            </div>

            <div className="modal-select__box">
              <label htmlFor="passport">
                {t("Passport seriyasi va raqami")}
              </label>
              <div className="modal-passport">
                <div className="passport_error modal-select__box">
                  <input
                    id="passport"
                    name="passport_series"
                    type="text"
                    className={
                      formik.touched.passport_series &&
                      formik.errors.passport_series
                        ? "modal-input error_input"
                        : "modal-input "
                    }
                    value={formik.values.passport_series || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.passport_series &&
                  formik.errors.passport_series ? (
                    <p className="input-error-message ">
                      {formik.errors.passport_series}
                    </p>
                  ) : null}
                </div>
                <div className="passport_error modal-select__box">
                  <input
                    id="passport"
                    name="passport_number"
                    type="text"
                    className={
                      formik.touched.passport_number &&
                      formik.errors.passport_number
                        ? "modal-input error_input"
                        : "modal-input "
                    }
                    value={formik.values.passport_number || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.passport_number &&
                  formik.errors.passport_number ? (
                    <p className="input-error-message ">
                      {formik.errors.passport_number}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-select__item modal-three">
            <div className="modal-select__box">
              <label htmlFor="middle_name">{t("Otasining ismi")}</label>
              <input
                id="middle_name"
                className={
                  formik.touched.middle_name && formik.errors.middle_name
                    ? "modal-input error_input"
                    : "modal-input "
                }
                value={formik.values.middle_name || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                name="middle_name"
              />
              {formik.touched.middle_name && formik.errors.middle_name ? (
                <p className="input-error-message ">
                  {formik.errors.middle_name}
                </p>
              ) : null}
            </div>
            <div className="modal-select__box">
              <label htmlFor="contract_number">
                {t("Buyuruq va mehnat shartnomasi")}
              </label>
              <input
                name="contract_number"
                id="contract_number"
                className={
                  formik.touched.contract_number &&
                  formik.errors.contract_number
                    ? "modal-input error_input"
                    : "modal-input "
                }
                value={formik.values.contract_number || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
              />
              {formik.touched.contract_number &&
              formik.errors.contract_number ? (
                <p className="input-error-message ">
                  {formik.errors.contract_number}
                </p>
              ) : null}
            </div>

            <div className="modal-select__box">
              <label htmlFor="surname">{t("Nomeri")}</label>
              <input
                id="phone"
                className={
                  formik.touched.phone && formik.errors.phone
                    ? "modal-input error_input"
                    : "modal-input "
                }
                value={formik.values.phone || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                name="phone"
              />
              {formik.touched.phone && formik.errors.phone ? (
                <p className="input-error-message ">{formik.errors.phone}</p>
              ) : null}
            </div>
          </div>
          <div className="modal-select__item modal-three">
            <div className="modal-select__box">
              <label htmlFor="workplace">{t("Ish faoliyat joyi")}</label>
              <Select
                id="vakolat"
                className={
                  formik.touched.working_places && formik.errors.working_places
                    ? "modal-input multiselect error_input"
                    : "modal-input multiselect"
                }
                closeMenuOnSelect={false}
                isMulti
                options={branch}
                name="working_places"
                value={formik.values.working_places}
                onChange={(option) => {
                  formik.setFieldValue("working_places", option);
                }}
                onBlur={() => {
                  formik.setTouched({ working_places: true });
                }}
              />
              {formik.touched.working_places && formik.errors.working_places ? (
                <p className="input-error-message ">
                  {formik.errors.working_places}
                </p>
              ) : null}
            </div>
            <div className="modal-select__box">
              <label htmlFor="roles">{t("Xodimning lavozimi")}</label>
              <Select
                id="vakolat"
                className={
                  formik.touched.roles && formik.errors.roles
                    ? "modal-input multiselect error_input"
                    : "modal-input multiselect"
                }
                closeMenuOnSelect={false}
                isMulti
                options={position}
                name="roles"
                value={formik.values.roles}
                onChange={(option) => {
                  formik.setFieldValue("roles", option);
                }}
                onBlur={() => {
                  formik.setTouched({ roles: true });
                }}
              />
              {formik.touched.roles && formik.errors.roles ? (
                <p className="input-error-message ">{formik.errors.roles}</p>
              ) : null}
            </div>

            <div
              className={employee.editingId ? "d-none" : "modal-select__box"}
            >
              <label htmlFor="password">{t("Parol")}</label>
              <div className="password-wrapper">
                <input
                  name="password"
                  id="password"
                  className={
                    formik.touched.password && formik.errors.password
                      ? "modal-input error_input"
                      : "modal-input "
                  }
                  value={formik.values.password || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"
                />
                {formik.touched.password && formik.errors.password ? (
                  <p className="input-error-message ">
                    {formik.errors.password}
                  </p>
                ) : null}
                <div className="password-icon"></div>
              </div>
            </div>
          </div>
          <label htmlFor="roles">{t("File yuklash")}</label>
          <FileUploadField
            formik={formik}
            employee={employee}
            defaultFileValue={defaultFileValue}
          />
        </div>
        <div className="input-buttons">
          <button
            onClick={() => cancelAction()}
            id="modalClose"
            type="button"
            className="input-btn"
          >
            {t("Bekor qilish")}
          </button>
          <button
            className="input-btn input-btn--submit"
            type="submit"
            disabled={saveLoader}
          >
            {saveLoader ? (
              <ReactLoading
                type={"spin"}
                color="#ffffff"
                className="delete_loader"
                height={"30px"}
                width={"30px"}
              />
            ) : (
              t("Saqlash")
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
