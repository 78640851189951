import * as React from "react";
import Switch from "@mui/material/Switch";

export default function ControlledSwitches({ formik, index }) {
  const [checked, setChecked] = React.useState(
    formik.values.rooms?.[index].is_lesson_room
  );

  const handleChange = (event) => {
    setChecked(event.target.checked);
    formik.setFieldValue(`rooms.${index}.is_lesson_room`, event.target.checked);
  };

  return (
    <Switch size="small" checked={checked} onChange={(e) => handleChange(e)} />
  );
}
