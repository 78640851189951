import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";
import { useMainGroupStore } from "../../../../../Store/ManageGroupsStore/MainGroupStore";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function BasicAlerts() {
  const { organizeExam } = useMainGroupStore();
  const { t } = useTranslation();
  return (
    <Stack sx={{ width: "100%", zIndex: 1 }} spacing={2}>
      {organizeExam?.scheduled_exams?.map((item) => {
        return (
          <Alert
            key={item.id}
            action={
              <Button color="inherit" size="small">
                <Link
                  to={`/groups/${organizeExam.group_id}/exam-details/${item.lesson_month_id}`}
                >
                  {t("baholash")}
                </Link>
              </Button>
            }
            severity="warning"
          >
            {item.verbose_name}
          </Alert>
        );
      })}
    </Stack>
  );
}
