import React, { useCallback, useContext, useEffect } from "react";
import AdminLayout from "../../../AdminLayout";
import TitleBar from "../../../Components/TitleBar";
import { useLoaderStore } from "../../../Store/Loader/LoaderStore";
import { useProfileConfigStore } from "../../../Store/ProfileConfiguration/ProfileConfigStore";
import { onReload } from "./actions";
import ProfileLeft from "./components/ProfileLeft";
import ProfileRight from "./components/ProfileRight";

export default function Profile() {
  const { profile_details } = useProfileConfigStore();
  const { setLoader } = useLoaderStore();

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    setLoader(true);
    await onReload();
    setLoader(false);
  };

  return (
    <AdminLayout>
      <div className="container">
        <div className="profileContainer">
          <div className="profileLeft">
            <ProfileLeft />
          </div>
          <div className="profileRight">
            <ProfileRight />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}
