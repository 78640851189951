import React from "react";
import { useTranslation } from "react-i18next";
import { useAttendanceStore } from "../../../../Store/AttendanceFormStore/AttendanceForm";

export default function SignatureRow() {
  const { data } = useAttendanceStore();
  const { t } = useTranslation();

  return (
    <div className="desk-exam-config">
      <div className="desk-config__item stu-num"></div>
      <div className="desk-config__item stu-fullname">{t("Imzo")}</div>
      <div className="desk-config__item stu-idnumber"></div>
      <div className="desk-config__item stu-age"></div>
      {data?.estimated_dates?.map((element, index) => {
        return element.days.map((item, index) => {
          return (
            <div className="desk-config__item desk-yellow" key={index}>
              <p className="signature_teacher">{data?.signatures[item.date]}</p>
            </div>
          );
        });
      })}
      {data?.taken_exams?.map((item) => {
        return (
          <div key={item.key} className="desk-config__item desk-yellow"></div>
        );
      })}
      <div className="desk-config__item payment-status"></div>
      {data?.taken_exams.length > 0 ? (
        <>
          <div className="desk-config__item payment-status"></div>
          <div className="desk-config__item payment-status"></div>
        </>
      ) : null}
      <div className="desk-config__item payment-status"></div>
    </div>
  );
}
