import Button from "@mui/material/Button";
import React, { useCallback, useContext } from "react";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import SelectField from "./Select";
import { Box } from "@mui/material";
import { useComparisionStore } from "../../../../Store/ReportsStore/ComparisionStore";
import ComparisionStaffsRepository from "../../../../Repositories/Reports/ComporitionStaffsRepository";
import { toastError } from "../../../../Helpers/toasts";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function StaffModal({ formik, element, index }) {
  const { teachers } = useComparisionStore();

  const optionsTeacher = useMemo(() => {
    return (
      teachers?.map((element) => ({
        label: element.fullname,
        value: element.id,
      })) || []
    );
  }, [teachers]);
  const optionsMonth = useMemo(() => {
    return (
      formik.values.staffs[index]?.months?.map((element) => ({
        label: element.name,
        value: element.value,
      })) || []
    );
  }, [formik.values.staffs[index]?.months]);

  const optionsGroups = useMemo(() => {
    return (
      formik.values.staffs[index]?.groups?.map((element) => ({
        label: element.name,
        value: element.exam_id,
      })) || []
    );
  }, [formik.values.staffs[index]?.groups]);

  const handleRemove = useCallback(() => {
    const updateSubGroups = formik.values.staffs?.filter(
      (element, i) => i !== index
    );
    formik.setFieldValue("staffs", updateSubGroups);
  });

  const handleStaffChange = async (e) => {
    const { data, nonFieldError, status } =
      await ComparisionStaffsRepository.getMonths(
        new URLSearchParams({ teacher_id: e.value }).toString()
      );
    if (status) {
      formik.setFieldValue(`staffs.${index}.months`, data.months);
    } else {
      toastError(nonFieldError);
    }
    formik.setFieldValue(`staffs.${index}.teacher_id`, e?.value || null);
  };
  const handleMonthChange = async (e) => {
    const { data, nonFieldError, status } =
      await ComparisionStaffsRepository.getGroups(
        new URLSearchParams({
          teacher_id: formik.values.staffs[index]?.teacher_id,
          month: e?.value,
        }).toString()
      );
    if (status) {
      formik.setFieldValue(`staffs.${index}.groups`, data.groups);
    } else {
      toastError(nonFieldError);
    }
    formik.setFieldValue(`staffs.${index}.month`, e?.value || null);
  };
  const handleGroupChange = (e) => {
    formik.setFieldValue(`staffs.${index}.exam_id`, e?.value || null);
  };
  const { t } = useTranslation();
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "25px 0",
        }}
      >
        <SelectField
          name={`staffs.${index}.teacher_id`}
          value={element?.teacher_id || ""}
          param={optionsTeacher}
          property={t("Xodimlar")}
          handleSelectChange={handleStaffChange}
        />
        <SelectField
          name={`staffs.${index}.month`}
          value={element?.month || ""}
          param={optionsMonth}
          property={t("Oylar")}
          handleSelectChange={handleMonthChange}
        />
        <SelectField
          param={optionsGroups}
          name={`staffs.${index}.exam_id`}
          value={element?.exam_id || ""}
          property={t("Guruhlar")}
          handleSelectChange={handleGroupChange}
        />
        <Button
          onClick={() => handleRemove()}
          type="button"
          className="modal_icon_container"
          variant="outlined"
          color="error"
          startIcon={<RemoveCircleIcon />}
          sx={{ height: "40px" }}
        >
          {t("O'chirish")}
        </Button>
      </div>
    </>
  );
}
