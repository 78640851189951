import CourseTemplateActions from "../../Actions/ConfigActions/CourseTemplateActions";

export const courseTemplateReducer = (state, action) => {
  switch (action.type) {
    case CourseTemplateActions.PAGE_LOADED:
      return {
        ...state,
        ...action.payload,
      };
    case CourseTemplateActions.UPDATE_BUTTON_CLICKED:
      return {
        ...state,
        isModalOpen: true,
        isCreating: false,
        isEditing: true,
        editingId: action.payload.id,
        item: {
          ...action.payload.item,
          removed_course_prices: [],
          removed_time_ranges: [],
        },
      };
    case CourseTemplateActions.COURSE_PRICE_REMOVED:
      // const filter = state.item.removed_course_prices.filter(
      //   (id) => id !== action.payload.id
      // );
      return {
        ...state,
        item: {
          ...state.item,
          removed_course_prices: [
            ...state.item.removed_course_prices,
            action.payload.id,
          ],
        },
      };
    case CourseTemplateActions.TIME_RANGE_REMOVED:
      // const filtertime = state.item.removed_time_ranges.filter(
      //   (id) => id !== action.payload.id
      // );

      return {
        ...state,
        item: {
          ...state.item,
          removed_time_ranges: [
            ...state.item.removed_time_ranges,
            action.payload.id,
          ],
        },
      };
    case CourseTemplateActions.CREATE_BUTTON_CLICKED:
      return {
        ...state,
        isModalOpen: true,
        isCreating: true,
        isEditing: false,
      };
    case CourseTemplateActions.DELETE_BUTTON_CLICKED:
      return {
        ...state,
        isDeleteModalOpen: true,
        deletingId: action.payload.id,
      };

    case CourseTemplateActions.CANCEL_BUTTON_CLICKED:
      return {
        ...state,
        isModalOpen: false,
        isDeleteModalOpen: false,
        isCreating: false,
        isEditing: false,
        editingId: null,
        deletingId: null,
      };
    case CourseTemplateActions.TOGGLE_CONTEXT_MENU:
      return {
        ...state,
        openContextMenuId: action.payload.id,
      };
    case CourseTemplateActions.CLOSE_CONTEXT_MENU:
      return {
        ...state,
        openContextMenuId: null,
      };
    case CourseTemplateActions.ADD_QUERY_PARAM:
      const params = { ...state.queryParams, ...action.payload };
      return {
        ...state,
        queryParams: params,
      };
    case CourseTemplateActions.TOGGLE_EXPORT_BLOCK:
      return {
        ...state,
        isOpenExportBlock: !state.isOpenExportBlock,
      };
    case CourseTemplateActions.CLOSE_EXPORT_BLOCK:
      return {
        ...state,
        isOpenExportBlock: false,
      };

    case CourseTemplateActions.TOGGLE_LIST_TABLE:
      return {
        ...state,
        listId: action.payload,
      };

    case CourseTemplateActions.SHOW_DELETE_LOADER:
      return {
        ...state,
        isShowDeleteLoader: action.payload,
      };
    default:
      return state;
  }
};
