import { useFormik } from "formik";
import * as Yup from "yup";
import { useCallback, useContext, useEffect, useState } from "react";
import { BranchesContext } from "../../../../Contexts/ConfigContext/BranchesContext";
import { loadItems } from "../actions";
import BranchesRepository from "../../../../Repositories/Config/BranchesRepository";
import BranchesActions from "../../../../Actions/ConfigActions/BranchesActions";
import ReactLoading from "react-loading";
import { toastError, toastSuccess } from "../../../../Helpers/toasts";
import { LoaderContext } from "../../../../Contexts/LoaderContext";
import BranchRooms from "./modal/BranchRooms";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { useTranslation } from "react-i18next";

export default function CreateUpdateModal({ staffs }) {
  const { branches, dispatchBranches } = useContext(BranchesContext);
  const { dispatchLoader } = useContext(LoaderContext);
  const [saveLoader, setSaveLoader] = useState(false);
  const [openField, setOpenField] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    setOpenField(true);
    if (branches.isModalOpen == true) {
      formik.resetForm();
    }
  }, [branches.isModalOpen]);

  useEffect(() => {
    for (const [key, value] of Object.entries(branches.item)) {
      if (key === "rooms") {
        value.forEach((element, index) => {
          formik.setFieldValue(`rooms.${index}.id`, element["id"], false);
          formik.setFieldValue(
            `rooms.${index}.responsible_staff`,
            element["responsible_staff"],
            false
          );
          formik.setFieldValue(
            `rooms.${index}.nickname`,
            element["nickname"],
            false
          );
          formik.setFieldValue(
            `rooms.${index}.is_lesson_room`,
            element["is_lesson_room"],
            false
          );
          formik.setFieldValue(
            `rooms.${index}.number`,
            element["number"],
            false
          );
        });
      } else {
        formik.setFieldValue(key, branches.item?.[key], false);
      }
    }
  }, [branches.isEditing]);

  const initialValues = {
    rooms: [],
    address: "",
    title: "",
    short_title: "",
    available_rooms: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      address: Yup.string()
        .min(1, t("required_address"))
        .max(200, t("address_limit"))
        .required(t("required_address")),
      title: Yup.string()
        .min(1, t("required_branch_name"))
        .max(100, t("branch_name_limit"))
        .required(t("required_branch_name")),
      short_title: Yup.string()
        .min(1, t("required_short_name"))
        .max(50, t("short_name_limit"))
        .required(t("required_short_name")),
      available_rooms: Yup.number().required(t("required_branch_room_count")),
      rooms: Yup.array().of(
        Yup.object().shape({
          nickname: Yup.string()
            .min(1, t("required_room_name"))
            .required(t("required_room_name")),
          responsible_staff: Yup.number()
            .min(1, t("required_staff"))
            .required(t("required_staff")),
          is_lesson_room: Yup.boolean(),
        })
      ),
    }),
    onSubmit: (values) => {
      if (
        branches.modalResponseData.length > 0 ||
        branches.isEditing === true
      ) {
        setSaveLoader(true);
        updateItem(values, branches.editingId);
      } else {
        setSaveLoader(true);
        createItem(values);
      }
    },
  });

  const handlePush = useCallback(() => {
    if (branches.modalResponseData.length > 0) {
      let item = [];
      branches.modalResponseData.forEach((element) => {
        const { id, responsible_staff, username, is_lesson_room, number } =
          element;
        item.push({ id, responsible_staff, username, is_lesson_room, number });
      });
      formik.values.rooms = [...item];
    }
  }, [branches.modalResponseData.length]);

  handlePush();

  const createItem = useCallback(async (data) => {
    const responseData = await BranchesRepository.createBranche(data);
    setSaveLoader(false);
    setOpenField(false);
    if (responseData.status === true) {
      toastSuccess(t("success_brach_add"));
      dispatchBranches({
        type: BranchesActions.MODAL_RESPONSE_DATA,
        payload: responseData.data.rooms,
      });
    } else {
      toastError(responseData.nonFieldErrors);
    }
  });
  const updateItem = useCallback(async (data, id) => {
    const responseData = await BranchesRepository.updateBranch(
      data,
      branches.isEditing ? id : branches.modalResponseData[0]?.branch
    );
    setSaveLoader(false);
    if (responseData.status === true) {
      toastSuccess(t("success_branch_update"));
      loadItems({}, branches, dispatchBranches, dispatchLoader);
      cancelAction();
    } else {
      toastError(responseData.nonFieldErrors);
    }
  });

  const cancelAction = useCallback(() => {
    dispatchBranches({
      type: BranchesActions.CANCEL_BUTTON_CLICKED,
    });
    dispatchBranches({
      type: BranchesActions.MODAL_RESPONSE_DATA,
      payload: [],
    });
  });

  return (
    <div id="modal" className={branches.isModalOpen ? "modal active" : "modal"}>
      <form action="#" className="modal-wrapper" onSubmit={formik.handleSubmit}>
        <div className="modal-inner">
          <h4 className="modal__title">{t("branches_title")}</h4>
          <div
            className="branch_modal_icon"
            onClick={() => setOpenField(!openField)}
          >
            <MenuOpenIcon />
          </div>
          <div
            className={
              openField
                ? "input_container"
                : "branch_modal_hidden input_container"
            }
          >
            <div className="modal_input_item_container">
              <div className="modal-input__item">
                <label htmlFor="address">{t("branch_address")}</label>
                <input
                  disabled={branches.modalResponseData.length}
                  id="address"
                  className={
                    formik.touched.address && formik.errors.address
                      ? "modal-input error_input"
                      : "modal-input"
                  }
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                  name="address"
                  placeholder={t("address_placeholder")}
                />
                {formik.touched.address && formik.errors.address ? (
                  <p className="input-error-message modal_error_value">
                    {formik.errors.address}
                  </p>
                ) : null}
              </div>
              <div className="modal-input__item">
                <label htmlFor="name">{t("branch_name")}</label>
                <input
                  disabled={branches.modalResponseData.length}
                  id="name"
                  className={
                    formik.touched.title && formik.errors.title
                      ? "modal-input error_input"
                      : "modal-input"
                  }
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                  name="title"
                  autoComplete="on"
                  placeholder={t("branch_name_placeholder")}
                />
                {formik.touched.title && formik.errors.title ? (
                  <p className="input-error-message modal_error_value">
                    {formik.errors.title}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="modal-input__item modal-box">
              <div className="modal-input__box">
                <label htmlFor="nickname">{t("short_name")}</label>
                <input
                  disabled={branches.modalResponseData.length}
                  id="nickname"
                  className={
                    formik.touched.short_title && formik.errors.short_title
                      ? "modal-input error_input"
                      : "modal-input"
                  }
                  type="text"
                  name="short_title"
                  value={formik.values.short_title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={t("short_name_placeholder")}
                />
                {formik.touched.short_title && formik.errors.short_title ? (
                  <p className="input-error-message modal_error_value">
                    {formik.errors.short_title}
                  </p>
                ) : null}
              </div>
              <div className="modal-input__box">
                <label htmlFor="staff-number">{t("branch_rooms")}</label>
                <input
                  disabled={branches.modalResponseData.length}
                  id="staff-number"
                  className={
                    formik.touched.available_rooms &&
                    formik.errors.available_rooms
                      ? "modal-input error_input"
                      : "modal-input"
                  }
                  type="number"
                  autoComplete="on"
                  name="available_rooms"
                  value={formik.values.available_rooms}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.available_rooms &&
                formik.errors.available_rooms ? (
                  <p className="input-error-message modal_error_value">
                    {formik.errors.available_rooms}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            formik.values.rooms.length > 0
              ? `modal_rooms ${
                  openField ? "modal_rooms_active" : "modal_rooms_active_height"
                } `
              : "modal_rooms"
          }
        >
          {formik.values.rooms.map((item, index) => {
            return (
              <BranchRooms
                formik={formik}
                key={item.id}
                staffs={staffs}
                room={item}
                index={index}
              />
            );
          })}
        </div>

        <div className="input-buttons">
          <button
            id="modalClose"
            type="button"
            className="input-btn"
            onClick={() => cancelAction(dispatchBranches)}
          >
            {t("cancel")}
          </button>
          <button
            className="input-btn input-btn--submit"
            type="submit"
            disabled={saveLoader}
          >
            {saveLoader ? (
              <ReactLoading
                type={"spin"}
                color="#ffffff"
                className="delete_loader"
                height={"30px"}
                width={"30px"}
              />
            ) : (
              `${t("submit")}`
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
