import { create } from "zustand";
import { toastError } from "../../Helpers/toasts";
import DailyReportRepository from "../../Repositories/Reports/DailyReportsRepository";

export const useDailyReportsStore = create((set) => ({
  results: null,
  isLoading: false,
  startDate: null,
  endDate: null,
  setLoading: (status) => {
    set({
      isLoading: status,
    });
  },
  queryParams: {
    page: 1,
  },

  updateParams: (paramItem) => {
    set((prevState) => ({
      queryParams: { ...prevState.queryParams, ...paramItem },
    }));
  },

  onReload: async (queryString) => {
    const { data, status, nonFieldErrors } =
      await DailyReportRepository.studentsList(queryString);
    if (status) {
      set({
        results: data,
      });
    } else toastError(nonFieldErrors);
  },

  loadItems: async (queryString) => {
    const { data, status, nonFieldErrors } =
      await DailyReportRepository.studentsList(queryString);
    if (status) {
      set({
        results: data,
      });
    } else toastError(nonFieldErrors);
  },
}));
