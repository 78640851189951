import React from "react";
import { useIncomeCashStore } from "../../../../Store/CashControlStore/IncomeCashStore";

export default function Discounts({ discounts }) {
  const { updateAcceptPayment, acceptPayment } = useIncomeCashStore();

  const handleChange = (e, id, type) => {
    const findDiscount = discounts?.find(
      (item) => item.id === id && item.type === type
    );
    const filterDiscountState = acceptPayment.discounts.filter(
      (item) => !(item.id === id && item.type === type)
    );
    if (e.target.checked) {
      updateAcceptPayment("discounts", [
        ...acceptPayment.discounts,
        findDiscount,
      ]);
    } else {
      updateAcceptPayment("discounts", filterDiscountState);
    }
  };
  return (
    <>
      <h1 className="income__detail--title">Chegirmalar</h1>
      <div className="cash-discount">
        <div className="cash-disount__list">
          {discounts?.map((item) => {
            return (
              <label
                key={`${item.id}${item.type}`}
                className="cash-disount__item"
                htmlFor={`${item.id}${item.type}`}
              >
                <input
                  type="checkbox"
                  id={`${item.id}${item.type}`}
                  disabled={item.mandatory}
                  defaultChecked={item.mandatory}
                  onChange={(e) => handleChange(e, item.id, item.type)}
                />
                <ul className="cash-disount__details">
                  <li className="cash-disount__details-item">{item.name}</li>
                  <li className="cash-disount__details-item">{item.month}</li>
                  <li className="cash-disount__details-item">{item.percent}</li>
                  <li className="cash-disount__details-item">{item.date}</li>
                </ul>
              </label>
            );
          })}
        </div>
      </div>
    </>
  );
}
