import React from "react";

export default function ListItem({ item, index }) {
  return (
    <div className="list--config">
      <ul className="attendance-table build-table build-table--bold build-table--light build-table__item">
        <li className="build-table__item">{index + 1}</li>
        <li className="build-table__item">{item.student}</li>
        <li className="build-table__item">{item.student_number}</li>
        <li className="build-table__item">{item.group_number}</li>
        <li className="build-table__item">{item.studying_place}</li>
        <li className="build-table__item">{item.teachers}</li>
        <li className="build-table__item">{item.type}</li>
        <li className="build-table__item">{item.amount}</li>
        <li className="build-table__item">{item.date}</li>
      </ul>
    </div>
  );
}
