export default class ArchivedGroupsActions{
    static CREATE_BUTTON_CLICKED = "CREATE_BUTTON_CLICKED";
    static ADD_QUERY_PARAM = "ADD_QUERY_PARAM";
    static PAGE_LOADED = "PAGE_LOADED";
    static TOGGLE_CONTEXT_MENU = "TOGGLE_CONTEXT_MENU";
    static CLOSE_CONTEXT_MENU = "CLOSE_CONTEXT_MENU";
    static DELETE_BUTTON_CLICKED = "DELETE_BUTTON_CLICKED";
    static CANCEL_BUTTON_CLICKED = "CANCEL_BUTTON_CLICKED";
    static OPEN_INFO_MODAL = "OPEN_INFO_MODAL";
    static OPEN_ARCHIVE_INFO_MODAL = "OPEN_ARCHIVE_INFO_MODAL";
    static UPDATE_BUTTON_CLICKED = "UPDATE_BUTTON_CLICKED";
    static TOGGLE_CATEGORIES = "TOGGLE_CATEGORIES";
    static CLOSE_CATEGORIES = "CLOSE_CATEGORIES";
}
