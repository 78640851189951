import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import StudentsRegistrationActions from "../../../Actions/RegistrationActions/StudentsRegistrationActions";
import AdminLayout from "../../../AdminLayout";
import DeleteConfirmation from "../../../Components/DeleteConfirmation";
import EmptyContent from "../../../Components/Empty-content/EmptyContent";
import PaginationComponent from "../../../Components/Pagination";
import TitleBar from "../../../Components/TitleBar";
import { LoaderContext } from "../../../Contexts/LoaderContext";
import { StudentsRegistrationContext } from "../../../Contexts/RegistrationContex/StudentRegistrationContext";
import {
  cancelDeleteAction,
  createAction,
  deleteAction,
  loadItems,
  onReload,
} from "./actions";
import ConnectionModal from "./components/ConnectionModal";
import CreateUpdateModal from "./components/CreateUpdateModal";
import FilteredModal from "./components/FilteredModal";
import Heading from "./components/Heading";
import ListItem from "./components/ListItem";
import ReturnGroupModal from "./components/ReturnGroupModal";
import StudentInfoModal from "./components/StudentInfoModal";

export default function StudentRegistration() {
  const { students, dispatchStudents } = useContext(
    StudentsRegistrationContext
  );
  const { dispatchLoader } = useContext(LoaderContext);

  const [days, setDays] = useState(null);
  const [studyLevels, setStudyLevels] = useState(null);
  const [branches, setBranches] = useState(null);
  const [times, setTimes] = useState(null);
  const [subjects, setSubjects] = useState(null);
  const [groupTypes, setGroupTypes] = useState(null);
  const [staffs, setStaffs] = useState(null);

  const pageReload = async () => {
    dispatchStudents({
      type: StudentsRegistrationActions.ADD_QUERY_PARAM,
      payload: {
        page: 1,
        subject: "",
        study_levels: "",
        status: "",
        suitable_time: "",
        suitable_days: "",
        group_type: "",
        studying_place: "",
        wanted_teacher: "",
        connection_status: "",
        start_date: "",
        end_date: "",
        has_groups: true,
      },
    });
    await onReload(students, dispatchStudents, dispatchLoader);
  };

  useEffect(() => {
    pageReload();
    document.body.addEventListener("click", handleClickClosePanelFromOutside);
    return () => {
      document.body.removeEventListener(
        "click",
        handleClickClosePanelFromOutside
      );
    };
  }, []);

  const paginateTo = useCallback((page) => {
    dispatchStudents({
      type: StudentsRegistrationActions.ADD_QUERY_PARAM,
      payload: { page: page },
    });
    loadItems({ page: page }, students, dispatchStudents, dispatchLoader);
  });

  const handleClickClosePanelFromOutside = (e) => {
    if (!e.target.className.includes("build-table__item--7")) {
      dispatchStudents({
        type: StudentsRegistrationActions.CLOSE_CONTEXT_MENU,
      });
    }
    if (!e.target.className.includes("categories")) {
      dispatchStudents({
        type: StudentsRegistrationActions.CLOSE_CATEGORIES,
      });
    }
    // if (!e.target.className.includes("active")) {
    //   dispatchStudents({
    //     type: StudentsRegistrationActions.STUDENT_INFO,
    //     payload: null,
    //   });
    // }
  };

  useEffect(() => {
    const createDaysObject = students.modalLessonDays?.map((day) =>
      handleChangeDays(day)
    );
    const createStudyLevelsObject = students?.studyLevels?.map((level) =>
      handleChangeDays(level)
    );
    const createTimesObject = students.modalLessonTimes?.map((time) =>
      handleChangeTimes(time)
    );
    const createBranchesObject = students.branches?.map((branch) =>
      handleChangeBranches(branch)
    );
    const createSubjectsObject = students.modalSubjects?.map((subject) =>
      handleChange(subject)
    );
    const createGroupTypesObject = students.modalGroupTypes?.map((type) =>
      handleChangeGroupType(type)
    );
    const createStaffsObject = students.staffs?.map((staff) =>
      handleChangeStaffs(staff)
    );
    setDays(createDaysObject);
    setTimes(createTimesObject);
    setBranches(createBranchesObject);
    setSubjects(createSubjectsObject);
    setGroupTypes(createGroupTypesObject);
    setStaffs(createStaffsObject);
    setStudyLevels(createStudyLevelsObject);
  }, [students.modalGroupTypes]);

  const handleChange = useCallback((props) => {
    return {
      value: props.id,
      label: props.name,
    };
  });
  const handleChangeDays = useCallback((props) => {
    return {
      value: props.value,
      label: props.name,
    };
  });
  const handleChangeBranches = useCallback((props) => {
    return {
      value: props.id,
      label: props.title,
    };
  });
  const handleChangeTimes = useCallback((props) => {
    return {
      value: props.id,
      label: `${props.starts_at} - ${props.ends_at}`,
    };
  });
  const handleChangeStaffs = useCallback((props) => {
    return {
      value: props.id,
      label: props.fullname,
    };
  });
  const handleChangeGroupType = useCallback((type) => {
    return {
      value: type[0],
      label: type[1],
    };
  });

  const data = {
    studyLevels,
    branches,
    days,
    times,
    subjects,
    groupTypes,
    staffs,
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const { t } = useTranslation();

  return (
    <AdminLayout>
      <TitleBar
        title={t("O‘quvchi va talabalarni ro‘yhatga olish")}
        createAction={() => createAction(dispatchStudents)}
        permission={user.permissions.create_student}
      />
      <div className="container remove_scroll">
        <Heading />
        {students.data?.length ? (
          students.data.map((student, index) => (
            <ListItem student={student} key={index} index={index} />
          ))
        ) : (
          <EmptyContent />
        )}
        <PaginationComponent context={students} callback={paginateTo} />
      </div>
      <FilteredModal data={data} />
      <CreateUpdateModal days={days} />
      <StudentInfoModal />
      <ConnectionModal
        cancelCallback={() => cancelDeleteAction(dispatchStudents)}
        showModal={students}
      />
      <ReturnGroupModal
        cancelCallback={() => cancelDeleteAction(dispatchStudents)}
        showModal={students}
      />
      <DeleteConfirmation
        deleteCallback={(e) =>
          deleteAction(e, students, dispatchStudents, dispatchLoader)
        }
        cancelCallback={() => cancelDeleteAction(dispatchStudents)}
        showModal={students}
      />
    </AdminLayout>
  );
}
