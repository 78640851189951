import React from "react";
import { useExamDetailsStore } from "../../../../Store/ManageGroupsStore/ExamDetailsStore";
import DetailsBtns from "../../../Attendance Form/AttendanceList/components/DetailsBtns";
import PassingScores from "./PassingScores";

export default function DetailBtns() {
  const { data, subject, lesson_month } = useExamDetailsStore();

  return (
    <div className="journal-box">
      <DetailsBtns title={data?.number} />
      <DetailsBtns title={subject?.name} />
      <DetailsBtns title={data?.lesson_month?.verbose_name} />
      <PassingScores />
    </div>
  );
}
