import React, { createContext, useReducer } from "react";
import { loaderReducer } from "../Reducers/LoaderReducers";

export const LoaderContext = createContext();

const initialState = {
  isLoading: false,
  isLoadingRoomsOption: false,
  isLoadingStaffsOption:false
};

const LoaderProvider = (props) => {
  const [loader, dispatchLoader] = useReducer(loaderReducer, {
    ...initialState,
  });

  return (
    <LoaderContext.Provider value={{ loader, dispatchLoader }}>
      {props.children}
    </LoaderContext.Provider>
  );
};

export default LoaderProvider;
