import React from "react";
import GetAppIcon from "@mui/icons-material/GetApp";

function ExportExcel() {
  return (
    <div className="export-excel">
      <GetAppIcon sx={{ color: "white" }} fontSize="20px" />
      <h1>Excel</h1>
    </div>
  );
}

export default ExportExcel;
