import axiosInstance from "../AxiosInstance";
import { axiosInterceptor } from "../AxiosInterceptor";

export default class AttendanceFormRepositories {
  static async getAttendanceFromData(queryString = "") {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        "/management/attendance/?" + queryString
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: e.response.data.data
          ? e.response.data.data.error
          : "Guruh mavjud emas!",
      };
    }
    return result;
  }
  static async getStudentInfo(studentId) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInstance.get(
        `/register/student/${studentId}/`
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: "O'quvchi mavjud emas!",
      };
    }
    return result;
  }
  static async getExclusionReasons() {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get(
        "/register/constants/exclusion-reasons/"
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data.data };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: "exclusion-reasons-ma'lumot mavjud emas!",
      };
    }
    return result;
  }
  static async removeStudent(studentId, data) {
    let result = {
      data: null,
      status: false,
    };
    try {
      const response = await axiosInterceptor.post(
        `/register/student/${studentId}/exclude/`,
        data
      );
      if (response.status === 200) {
        result = {
          ...result,
          status: true,
          nonFieldErrors: response.data.message,
        };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: e.response.data.data.detail
          ? e.response.data.data.detail
          : e.response.data.data.error
          ? e.response.data.data.error
          : "Saqlab bo'lmadi!",
      };
    }
    return result;
  }
  static async saveDiscount(discountData) {
    let result = {
      data: null,
      status: false,
    };
    try {
      const response = await axiosInterceptor.post(
        `/management/attendance/discount/`,
        discountData
      );
      if (response.status === 200) {
        result = {
          ...result,
          status: true,
          nonFieldErrors: response.data.message,
        };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: e.response.data.data.detail
          ? e.response.data.data.detail
          : e.response.data.data.error
          ? e.response.data.data.error
          : "Saqlab bo'lmadi!",
      };
    }
    return result;
  }
}
