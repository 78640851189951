import { Link } from "react-router-dom";
import { dateFormat } from "../../../../Helpers/dateFormat";
import { useExamScheduleStore } from "../../../../Store/ManageGroupsStore/ExamScheduleStore";
import UserPermissionWidget from "../../../../UserPermissionWidget";
import GetAppIcon from "@mui/icons-material/GetApp";
import { useTranslation } from "react-i18next";

export default function ListItem({ item, index }) {
  const { openContextMenu } = useExamScheduleStore();
  const { group, lesson_month, requester, created_at, date, shouldbe_taken } =
    item;
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <ul className="exam_table_head exam_items build-table--light">
      <li className="exam_head_item">{index + 1}</li>
      <UserPermissionWidget
        hasPermission={user?.permissions?.view_exam_details}
      >
        <li className="exam_head_item">
          <Link to={`/groups/${group.id}/exam-details/${lesson_month.id}`}>
            {group.number}
          </Link>
        </li>
      </UserPermissionWidget>
      <li className="exam_head_item">{group.subject}</li>
      <li className="exam_head_item">{group.age_group}</li>
      <li className="exam_head_item">{group.teachers}</li>
      <li className="exam_head_item">{group.lessons}</li>
      <li className="exam_head_item">{lesson_month.verbose_name}</li>
      <li className="exam_head_item">{requester.first_name}</li>
      <li className="exam_head_item">{shouldbe_taken}</li>
      <li className="exam_head_item">
        {date ? dateFormat(date) : t("Baholanmagan")}
      </li>
      <li
        className="build-table__item  build-table__item--7"
        onClick={() =>
          useExamScheduleStore.setState({
            openContextMenu: item.id,
          })
        }
      >
        <ul
          className={
            openContextMenu == item.id
              ? "build-table__dropdown build-table__dropdown--active"
              : "build-table__dropdown"
          }
        >
          <Link
            to={`https://admin.smartportal.uz/api/management/group/exam-requests/${item.id}/?download=excel`}
            download
            target={"_blank"}
          >
            <li className="build-table__dropdown-item btn-edit back--group excel-menu--item">
              <button className="modalBtn">
                <GetAppIcon
                  sx={{
                    fontSize: "18px",
                    marginRight: "8px",
                    color: "#009688",
                  }}
                />
                <p>Excel</p>
              </button>
            </li>
          </Link>
        </ul>
      </li>
    </ul>
  );
}
