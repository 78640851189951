import React, { useCallback, useEffect, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import EmployeeRepository from "../../../../Repositories/Registration/EmployeeRepository";
import { toastError, toastSuccess } from "../../../../Helpers/toasts";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DotSpinner from "../../../../Components/Loader/dot-spinner/DotSpinner";

function FileUploadField({ formik, employee, defaultFileValue }) {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    setFiles([...defaultFileValue]);
  }, [defaultFileValue]);

  useEffect(() => {
    setFiles([]);
    setUploading(false);
  }, [employee.isModalOpen]);

  const handleFileChange = (event, index) => {
    const updatedFiles = [...files];
    updatedFiles[index] = event.target.files[0];
    setFiles(updatedFiles);
    handleUpload(event.target.files[0], index);
  };

  const handleUpload = useCallback(
    async (file, index) => {
      const formData = new FormData();
      formData.append("file", file);

      setUploading(true);

      const { data, status, nonFieldErrors } =
        await EmployeeRepository.uploadFile(formData);

      if (status) {
        let value = formik.values.documents;
        formik.setFieldValue("documents", [...value, data.id]);
        setUploading(false);
      } else {
        toastError(nonFieldErrors);
        const updatedFiles = [...files];
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles);
        setUploading(false);
      }
    },
    [defaultFileValue, employee, formik]
  );

  const handleAddFileUpload = () => {
    setFiles([...files, null]);
  };

  const handleRemoveFileUpload = async (index) => {
    setUploading(true);
    const updatedFiles = [...formik.values.documents];
    const findDocumentId = updatedFiles.find((item, i) => i === index);
    const updatedValue = updatedFiles?.filter(
      (item) => item !== findDocumentId
    );

    const updateState = [...files];
    const findDocument = updateState.find((item, i) => i === index);
    const updatedValueState = updateState?.filter(
      (item) => item !== findDocument
    );
    const { status, nonFieldErrors, data } =
      await EmployeeRepository.deleteFile(findDocumentId);

    if (status) {
      updatedFiles.splice(index, 1);
      setFiles(updatedValueState);
      toastSuccess(data.message);
      setUploading(false);
      formik.setFieldValue("documents", updatedValue);
    } else {
      toastError(nonFieldErrors);
      setUploading(false);
    }
  };

  return (
    <div className="fileUpload">
      {files.map((file, index) => (
        <div key={index} className="file-upload-container">
          <label htmlFor={`fileInput-${index}`} className="file-input-label">
            {uploading || (
              <input
                type="file"
                id={`fileInput-${index}`}
                className={`file-input`}
                onChange={(event) => handleFileChange(event, index)}
              />
            )}
            <span className="file-input-icon">
              <CloudUploadIcon />
            </span>
            {file ? (
              <span className="file-name">{file.name}</span>
            ) : (
              <span className="file-input-text">File qo'shish</span>
            )}
          </label>
          {file && (
            <div
              className={`deleteButton ${uploading && "d-none"}`}
              onClick={() => handleRemoveFileUpload(index)}
            >
              <DeleteIcon color="action" fontSize="large" />
            </div>
          )}
        </div>
      ))}
      {uploading ? (
        <div className="upload_loader">
          <DotSpinner />
        </div>
      ) : (
        <IconButton
          sx={{
            margin: "0",
            borderRadius: "4px",
            background: "rgba(170, 170, 170, 0.1)",
          }}
          aria-label="add file upload"
          onClick={handleAddFileUpload}
        >
          <AddCircleIcon fontSize="large" />
        </IconButton>
      )}
    </div>
  );
}

export default React.memo(FileUploadField);
