import { useFormik } from "formik";
import * as Yup from "yup";
import { useCallback, useContext, useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import MainGroupsActions from "../../../../Actions/GroupsActions/MainGroupsActions";
import { LoaderContext } from "../../../../Contexts/LoaderContext";
import { MainGroupsContext } from "../../../../Contexts/GroupsContext/MainGroupsContext";
import { toastError, toastSuccess } from "../../../../Helpers/toasts";
import { loadItems } from "../actions";
import GroupCompositionModal from "./GroupCompositionModal";
import ModalAddBtn from "./ModalAddBtn";
import MainGroupsRepository from "../../../../Repositories/Groups/MainGroupsRepository";
import { useTranslation } from "react-i18next";

export default function CreateUpdateModal({ days }) {
  const { mainGroups, dispatchMainGroups } = useContext(MainGroupsContext);
  const { loader, dispatchLoader } = useContext(LoaderContext);
  const [saveLoader, setSaveLoader] = useState(false);

  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    if (mainGroups.isEditing === false) {
      dispatchMainGroups({ type: MainGroupsActions.RESET_MODAL_STATE });
    }
    formik.resetForm();
  }, [mainGroups.isModalOpen]);

  useEffect(() => {
    const filteredAgGroup = mainGroups.currentSubject?.age_groups.find(
      (element) => element.id === formik.values.age_group
    );
    dispatchMainGroups({
      type: MainGroupsActions.SET_CURRENT_AGE_GROUP,
      payload: filteredAgGroup,
    });
  }, [mainGroups.currentSubject]);

  useEffect(() => {
    const filteredDuration = mainGroups.currentAgeGroup?.durations.find(
      (element) => element === formik.values.duration
    );
    dispatchMainGroups({
      type: MainGroupsActions.SET_CURRENT_DURATIONS,
      payload: filteredDuration,
    });
  }, [mainGroups.currentAgeGroup]);

  useEffect(() => {
    const setStartMonth = [];
    for (let i = 1; i <= mainGroups.currentDurations; i++) {
      setStartMonth.push(i);
    }
    dispatchMainGroups({
      type: MainGroupsActions.SET_START_MONTH,
      payload: setStartMonth,
    });
  }, [mainGroups.currentDurations]);

  const initialValues = {
    subject: "",
    duration: "",
    age_group: "",
    lessons_per_week: "",
    starting_month: "",
    sub_groups: [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      subject: Yup.string()
        .min(0, t("Kursni tanlang!"))
        .required(t("Kursni tanlang!")),
      duration: Yup.number()
        .min(1, t("Kurs davomiyligini kiriting!"))
        .required(t("Kurs davomiyligini kiriting!")),
      age_group: Yup.number()
        .min(1, t("Guruh turini kiriting!"))
        .required(t("Guruh turini kiriting!")),
      starting_month: Yup.number(),
      lessons_per_week: Yup.number(),
      sub_groups: Yup.array().of(
        Yup.object().shape({
          lesson_style: Yup.number()
            .min(1, t("Dars turini tanlang!"))
            .required(t("Dars turini tanlang!")),
          time_range: Yup.string().required(t("Dars vaqtini kiriting!")),
          recurring_days: Yup.array().min(1, t("Dars kunlarini kiriting!")),
          start_date: Yup.string().required(t("Boshlanish sanasini kiriting!")),
          teacher: Yup.string().required(t("O'ituvchini tanlang!")),
          secondary_teacher: Yup.string(),
          branch: Yup.string().required(t("Filial nomini tanlang!")),
          room: Yup.string().required(t("Xona tanlang!")),
        })
      ),
    }),
    onSubmit: (values) => {
      setSaveLoader(true);
      createItem(values);
    },
  });

  const createItem = useCallback(async (data) => {
    const responseData = await MainGroupsRepository.createGroup(data);
    setSaveLoader(false);
    if (responseData.status === true) {
      toastSuccess("Guruh turi kiritildi");
      loadItems({}, mainGroups, dispatchMainGroups);
      cancelAction();
      navigate(`/group/${responseData.data?.id}/assign-students/`);
    } else {
      toastError(responseData.nonFieldErrors);
    }
  });

  const handleChangeSubject = useCallback((e) => {
    dispatchMainGroups({
      type: MainGroupsActions.SET_CURRENT_AGE_GROUP,
      payload: null,
    });
    dispatchMainGroups({
      type: MainGroupsActions.SET_CURRENT_DURATIONS,
      payload: null,
    });
    dispatchMainGroups({
      type: MainGroupsActions.SET_START_MONTH,
      payload: null,
    });
    formik.setFieldValue("age_group", "");
    formik.setFieldValue("duration", "");
    formik.setFieldValue("starting_month", "");
    formik.setFieldValue("lessons_per_week", "");

    const currentSubject = mainGroups.subjects.find(
      (element) => element.subject_id === parseInt(e.target.value)
    );
    dispatchMainGroups({
      type: MainGroupsActions.SET_CURRENT_SUBJECT,
      payload: currentSubject,
    });
    formik.setFieldValue("subject", e.target.value || "");
  });

  const handleChangeAgeGroup = useCallback((e) => {
    dispatchMainGroups({
      type: MainGroupsActions.SET_CURRENT_DURATIONS,
      payload: null,
    });
    dispatchMainGroups({
      type: MainGroupsActions.SET_START_MONTH,
      payload: null,
    });
    formik.setFieldValue("duration", "");
    formik.setFieldValue("starting_month", "");
    const currentAgeGroup = mainGroups.currentSubject?.age_groups.find(
      (element) => element.id === parseInt(e.target.value)
    );
    dispatchMainGroups({
      type: MainGroupsActions.SET_CURRENT_AGE_GROUP,
      payload: currentAgeGroup,
    });

    formik.setFieldValue("age_group", e.target.value || "");
    formik.setFieldValue("lessons_per_week", currentAgeGroup?.lessons_per_week);
  });

  const handleChangeDuration = useCallback((e) => {
    formik.setFieldValue("starting_month", "");
    dispatchMainGroups({
      type: MainGroupsActions.SET_START_MONTH,
      payload: null,
    });
    const currentDuration = mainGroups.currentAgeGroup?.durations.find(
      (element) => element === parseInt(e.target.value)
    );
    const setStartMonth = [];
    for (let i = 1; i <= parseInt(e.target.value); i++) {
      setStartMonth.push(i);
    }
    dispatchMainGroups({
      type: MainGroupsActions.SET_CURRENT_DURATIONS,
      payload: currentDuration,
    });
    dispatchMainGroups({
      type: MainGroupsActions.SET_START_MONTH,
      payload: setStartMonth,
    });
    formik.setFieldValue("duration", e.target.value || "");
  });

  const cancelAction = useCallback(() => {
    dispatchMainGroups({
      type: MainGroupsActions.CANCEL_BUTTON_CLICKED,
    });
    formik.resetForm();
  });

  const handleAdded = useCallback((e) => {
    e.preventDefault();
    const subGroups = [
      ...formik.values.sub_groups,
      {
        lesson_style: "",
        time_range: "",
        start_date: "",
        teacher: "",
        recurring_days: [],
        lesson_days: [],
        secondary_teacher: "",
        branch: "",
        room: "",
      },
    ];
    formik.setFieldValue("sub_groups", subGroups);
  });

  return (
    <div
      id="modal"
      className={
        mainGroups.isEditing || mainGroups.isCreating
          ? "modal modal-select active"
          : "modal modal-select"
      }
    >
      <form className="modal-wrapper " onSubmit={formik.handleSubmit}>
        <h4 className="modal__title group_modal_title">
          {t("Yangi guruh tashkil etish")}
        </h4>
        <div className="group_modal">
          <div className="modal-inner">
            <div className="modal-select__item modal-four margin_row">
              <div className="modal-select__box">
                <label htmlFor="subject">{t("subject")}</label>
                <select
                  className={
                    formik.touched.subject && formik.errors.subject
                      ? "modal-input error_input"
                      : "modal-input "
                  }
                  value={formik.values.subject || ""}
                  onChange={(e) => handleChangeSubject(e)}
                  onBlur={formik.handleBlur}
                  name="subject"
                  id="subject"
                >
                  <option
                    className="course_disabled_option"
                    disabled
                    hidden
                    value={""}
                  ></option>
                  {mainGroups.subjects.map((subject, index) => {
                    return (
                      <option key={index} value={subject.subject_id}>
                        {subject.name}
                      </option>
                    );
                  })}
                </select>
                {formik.touched.subject && formik.errors.subject ? (
                  <p className="input-error-message ">
                    {formik.errors.subject}
                  </p>
                ) : null}
              </div>
              <div className="modal-select__box ">
                <label htmlFor="times">{t("Guruh turi")}</label>
                <select
                  id="times"
                  name="age_group"
                  disabled={!formik.values.subject ? true : false}
                  onChange={(e) => handleChangeAgeGroup(e)}
                  onBlur={formik.handleBlur}
                  value={formik.values.age_group || ""}
                  className={
                    formik.touched.age_group && formik.errors.age_group
                      ? "modal-input error_input"
                      : "modal-input "
                  }
                >
                  <option
                    className="course_disabled_option"
                    disabled
                    hidden
                    value={""}
                  ></option>
                  {mainGroups.currentSubject?.age_groups.map((element) => {
                    return (
                      <option key={element.id} value={element.id}>
                        {element.name}
                      </option>
                    );
                  })}
                </select>
                {formik.touched.age_group && formik.errors.age_group ? (
                  <p className="input-error-message ">
                    {formik.errors.age_group}
                  </p>
                ) : null}
              </div>
              <div className="modal-select__box">
                <label htmlFor="times">{t("Kurs davomiyligi")}</label>
                <select
                  id="times"
                  name="duration"
                  disabled={!formik.values.age_group ? true : false}
                  onChange={(e) => handleChangeDuration(e)}
                  onBlur={formik.handleBlur}
                  value={formik.values.duration || ""}
                  className={
                    formik.touched.duration && formik.errors.duration
                      ? "modal-input error_input"
                      : "modal-input "
                  }
                >
                  <option
                    className="course_disabled_option"
                    disabled
                    hidden
                    value={""}
                  ></option>
                  {mainGroups.currentAgeGroup?.durations.map(
                    (element, index) => {
                      return (
                        <option key={index} value={element}>
                          {element} oy
                        </option>
                      );
                    }
                  )}
                </select>
                {formik.touched.duration && formik.errors.duration ? (
                  <p className="input-error-message ">
                    {formik.errors.duration}
                  </p>
                ) : null}
              </div>
              <div className="modal-select__box">
                <label htmlFor="times">{t("Boshlanish oyi")}</label>
                <select
                  id="times"
                  name="starting_month"
                  disabled={!formik.values.duration ? true : false}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.starting_month || ""}
                  className={
                    formik.touched.starting_month &&
                    formik.errors.starting_month
                      ? "modal-input error_input"
                      : "modal-input "
                  }
                >
                  <option
                    className="course_disabled_option"
                    disabled
                    hidden
                    value={""}
                  ></option>
                  {mainGroups.durationMonths?.map((element, index) => {
                    return (
                      <option key={index} value={element}>
                        {element} - {t("oy")}
                      </option>
                    );
                  })}
                </select>
                {formik.touched.starting_month &&
                formik.errors.starting_month ? (
                  <p className="input-error-message ">
                    {formik.errors.starting_month}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="course_line"></div>
            {formik.values.sub_groups?.map((element, index) => {
              return (
                <>
                  <GroupCompositionModal
                    formik={formik}
                    index={index}
                    days={days}
                    key={index}
                  />
                  <div className="course_line"></div>
                </>
              );
            })}
            <ModalAddBtn handleAdd={(e) => handleAdded(e)} />
          </div>
        </div>
        <div className="input-buttons">
          <button
            id="modalClose"
            type="button"
            className="input-btn"
            onClick={() => cancelAction()}
          >
            {t("Bekor qilish")}
          </button>
          <button
            className="input-btn input-btn--submit"
            type="submit"
            disabled={saveLoader}
          >
            {saveLoader ? (
              <ReactLoading
                type={"spin"}
                color="#ffffff"
                className="delete_loader"
                height={"30px"}
                width={"30px"}
              />
            ) : (
              t("Saqlash")
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
