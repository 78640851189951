import { create } from "zustand";
import { toastError } from "../../Helpers/toasts";
import useRefundRepository from "../../Repositories/CashControl/RefundRepository";

export const useRefundStore = create((set, get) => ({
  students: null,
  lesson_months: null,
  studentDetails: null,
  groupDetails: null,
  payment_types: null,
  salary: {},
  isLoading: false,
  confirmation: false,
  setLoading: (status) => {
    set({
      isLoading: status,
    });
  },
  updateSalary: (item) => {
    set((prevState) => ({
      salary: { ...prevState.salary, ...item },
    }));
  },
  getStudentShortLists: async (queryString) => {
    const { data, status, nonFieldErrors } =
      await useRefundRepository.getStudentShortLists(queryString);
    if (status) {
      set({
        students: data,
      });
    } else toastError(nonFieldErrors);
  },
  getStudentDetails: async (queryString) => {
    const { data, status, nonFieldErrors } =
      await useRefundRepository.getStudentDetail(queryString);
    if (status) {
      set({
        payment_types: data.payment_types,
        groupDetails: null,
        lesson_months: data.lesson_months,
        has_overcharge: data.has_overcharge,
      });
    } else toastError(nonFieldErrors);
  },
  getStudentInfo: async (studentId) => {
    const { salary } = get();
    const { data, status, nonFieldErrors } =
      await useRefundRepository.getStudentInfo(studentId);
    if (status) {
      set({
        studentDetails: data.student_details,
        // lesson_months: data.groups,
        salary: { ...salary, student: data.student_details.id },
      });
    } else toastError(nonFieldErrors);
  },
  resetData: () => {
    set({
      lesson_months: null,
      groupDetails: null,
      studentDetails: null,
      students: null,
      salary: {},
    });
  },
}));
