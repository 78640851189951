import { useTranslation } from "react-i18next";
import CommonHeading from "../../../../Components/CommonHeading";
import { useGroupAttendanceDivisionStore } from "../../../../Store/GroupManagement/GroupsAttendanceDivisionStore";
import MonthSelector from "./Select";
import TableHead from "./TableHead";

export default function Heading() {
  const { years, months, branches } = useGroupAttendanceDivisionStore();

  const optionsYears =
    years?.map((element) => ({
      label: element.verbose_name,
      value: element.year,
    })) || [];

  const optionsMonths =
    months?.map((element) => ({
      label: element.verbose_name,
      value: element.month,
    })) || [];

  const optionsBranches =
    branches?.map((element) => ({
      label: element.title,
      value: element.id,
    })) || [];
  const { t } = useTranslation();
  return (
    <CommonHeading
      topItems={
        <>
          <MonthSelector options={optionsYears} property={t("Yillar")} />
          <MonthSelector options={optionsMonths} property={t("Oylar")} />
          <MonthSelector options={optionsBranches} property={t("Filiallar")} />
        </>
      }
      tableHead={<TableHead />}
    />
  );
}
