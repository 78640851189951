import MainGroupsActions from "../../Actions/GroupsActions/MainGroupsActions";
export const mainGroupsReducers = (state, action) => {
  switch (action.type) {
    case MainGroupsActions.CREATE_BUTTON_CLICKED:
      return {
        ...state,
        isModalOpen: true,
        isCreating: true,
        isEditing: false,
      };
    case MainGroupsActions.ADD_QUERY_PARAM:
      const params = { ...state.queryParams, ...action.payload };
      return {
        ...state,
        queryParams: params,
      };
    case MainGroupsActions.PAGE_LOADED:
      return {
        ...state,
        ...action.payload,
      };
    case MainGroupsActions.TOGGLE_CONTEXT_MENU:
      return { ...state, openContextMenuId: action.payload.id };
    case MainGroupsActions.CLOSE_CONTEXT_MENU:
      return {
        ...state,
        openContextMenuId: null,
      };
    case MainGroupsActions.DELETE_BUTTON_CLICKED:
      return {
        ...state,
        isDeleteModalOpen: true,
        deletingId: action.payload.id,
      };
    case MainGroupsActions.SHOW_DELETE_LOADER:
      return {
        ...state,
        isShowDeleteLoader: action.payload,
      };
    case MainGroupsActions.CANCEL_BUTTON_CLICKED:
      return {
        ...state,
        isModalOpen: false,
        isDeleteModalOpen: false,
        isCreating: false,
        isEditing: false,
        editingId: null,
        deletingId: null,
        currentSubject: null,
        currentAgeGroup: null,
        durationMonths: null,
        currentDurations: null,
        times: null,
        rooms: null,
      };
    case MainGroupsActions.UPDATE_BUTTON_CLICKED:
      return {
        ...state,
        isModalOpen: true,
        isCreating: false,
        isEditing: true,
        editingId: action.payload.id,
        item: action.payload.item,
      };
    case MainGroupsActions.TOGGLE_FILTER_LIST:
      return { ...state, isOpenFilterList: !state.isOpenFilterList };
    case MainGroupsActions.TOGGLE_CATEGORIES:
      return { ...state, isOpenCategories: !state.isOpenCategories };
    case MainGroupsActions.CLOSE_FILTER_LIST:
      return { ...state, isOpenFilterList: false };
    case MainGroupsActions.CLOSE_CATEGORIES:
      return { ...state, isOpenCategories: false };
    case MainGroupsActions.SET_CURRENT_SUBJECT:
      return { ...state, currentSubject: action.payload };
    case MainGroupsActions.SET_CURRENT_DURATIONS:
      return { ...state, currentDurations: action.payload };
    case MainGroupsActions.SET_START_MONTH:
      return { ...state, durationMonths: action.payload };
    case MainGroupsActions.SET_CURRENT_AGE_GROUP:
      return { ...state, currentAgeGroup: action.payload };
    case MainGroupsActions.BRANCH_ROOMS0:
      return { ...state, rooms0: action.payload };
    case MainGroupsActions.BRANCH_ROOMS1:
      return { ...state, rooms1: action.payload };
    case MainGroupsActions.TIMES0:
      return { ...state, times0: action.payload };
    case MainGroupsActions.TIMES1:
      return { ...state, times1: action.payload };

    case MainGroupsActions.CATEGORY_LIST:
      return {
        ...state,
        categoryList: action.payload,
      };
    case MainGroupsActions.RESET_MODAL_STATE:
      return {
        ...state,
        currentSubject: null,
        currentAgeGroup: null,
        durationMonths: null,
        currentDurations: null,
      };
    case MainGroupsActions.FILTER_MODAL_RELOAD:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
