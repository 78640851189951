import React from "react";

function Listitem({ group, index }) {
  return (
    <ul className="studentAttendance-table build-table build-table--light build-table__item">
      <li className="build-table__item">{index + 1}</li>
      <li className="build-table__item">
        <p>{group.verbose_name}</p>
      </li>
      <li className="build-table__item">{group.groups_count} ta</li>
      <li className="build-table__item">{group.students_count} ta</li>
      <li className="build-table__item">
        {group.high_scoring_students_count} ta
      </li>
      <li className="build-table__item">
        {group.medium_scoring_students_count} ta
      </li>
      <li className="build-table__item">
        {group.low_scoring_students_count} ta
      </li>
    </ul>
  );
}
export default React.memo(Listitem);
