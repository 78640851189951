import React from "react";
import BlockItem from "../../Components/BlockItem";
import UserPermissionWidget from "../../UserPermissionWidget";
import { useTranslation } from "react-i18next";

function ConfigModule({ viewModule }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const { t } = useTranslation();

  return (
    <div className={`home-cards-box ${!viewModule ? "d-none" : ""}`}>
      <h1 className="cards__title">
        <img className="cards__img" src="/images/cards-1.png" alt="card box" />
        {t("home_config")}
      </h1>
      <section className="section-page config">
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_course_templates}
        >
          <BlockItem
            link="/config/course-template"
            title={t("home_courses")}
            image="/images/config-img-1.png"
          />
        </UserPermissionWidget>
        <UserPermissionWidget hasPermission={user?.permissions?.view_branches}>
          <BlockItem
            link="/config/buildings"
            title={t("home_branches")}
            image="/images/config-img-4.png"
          />
        </UserPermissionWidget>
      </section>
    </div>
  );
}

export default ConfigModule;
