import React, { useState } from "react";
import PaymentSection from "./PaymentSection";
import ReactLoading from "react-loading";
import Selector from "./Select";
import TextArea from "./TextArea";
import SaveButton from "../SaveButton";
import { Box } from "@mui/material";
import { useExpendatureCashStore } from "../../../../../Store/CashControlStore/ExpendatureCashStore";
import { useMemo } from "react";
import { useCallback } from "react";
import ExpenditureCashRepository from "../../../../../Repositories/CashControl/ExpendatureCashRepository";
import { toastError, toastSuccess } from "../../../../../Helpers/toasts";
import ConfirmationModal from "../../../Components/ConfirmationModal";
import { useTranslation } from "react-i18next";

const box_container1 = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: "0px 50px",
};
const box_container2 = {
  width: "50%",
  height: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  gap: "20px 0px",
};

function FixedCosts() {
  const {
    categories,
    branch_staffs,
    expenditureTypes,
    updateFixedCosts,
    fixedCosts,
    confirmation,
  } = useExpendatureCashStore();

  const categoriesOptions = useMemo(() => {
    return (
      categories?.map((element) => ({
        label: element.name,
        value: element.id,
      })) || []
    );
  }, [categories]);

  const staffOptions = useMemo(() => {
    return (
      branch_staffs?.map((element) => ({
        label: element.fullname,
        value: element.id,
      })) || []
    );
  }, [branch_staffs]);

  const expenditureTypesOptions = useMemo(() => {
    return (
      expenditureTypes?.map((element) => ({
        label: element.name,
        value: element.id,
      })) || []
    );
  }, [expenditureTypes]);

  const categoriesHandler = useCallback(
    (e) => {
      updateFixedCosts("expenditure", null);
      updateFixedCosts("category", e?.value);
      const filtered = categories?.find((item) => item.id === e?.value);
      useExpendatureCashStore.setState({
        expenditureTypes: filtered?.expenditures,
      });
    },
    [categories]
  );

  const expenditureTypeHandler = useCallback((e) => {
    updateFixedCosts("expenditure", e?.value);
  }, []);
  const staffHandler = useCallback(
    (e) => {
      updateFixedCosts("reasonponsible_staff", e?.value);
    },
    [branch_staffs]
  );

  const clearFormData = () => {
    useExpendatureCashStore.setState({
      fixedCosts: {
        category: null,
        amountPlaceholder: null,
        amount: null,
        payment_type: null,
        expenditure: null,
        comment: null,
        reasonponsible_staff: null,
      },
    });
  };

  const handleSubmit = async () => {
    const { status, nonFieldErrors } =
      await ExpenditureCashRepository.expenditure(fixedCosts);
    if (status) {
      toastSuccess(nonFieldErrors);
      clearFormData();
      useExpendatureCashStore.setState({
        confirmation: false,
      });
    } else toastError(nonFieldErrors);
  };

  const cancelCallback = () => {
    useExpendatureCashStore.setState({
      confirmation: false,
    });
  };

  const { t } = useTranslation();

  const confirmationCallback = () => {
    if (
      !fixedCosts.expenditure &&
      !fixedCosts.payment_type &&
      !fixedCosts.amount
    ) {
      toastError(t("Chiqim turi, qiymati va to'lov turi kiritilishi shart!"));
    } else if (!fixedCosts.expenditure) {
      toastError(t("Chiqim turi kiritilishi shart!"));
    } else if (!fixedCosts.amount) {
      toastError(t("Chiqim qiymati kiritilishi shart!"));
    } else if (!fixedCosts.payment_type) {
      toastError(t("To'lov turi kiritilishi shart!"));
    } else {
      useExpendatureCashStore.setState({
        confirmation: true,
      });
    }
  };
  return (
    <div className="fixed-costs">
      <Box sx={box_container1}>
        <Selector
          value={
            categoriesOptions?.find(
              (item) => item.value == fixedCosts.category
            ) || null
          }
          options={categoriesOptions}
          property={t("Kategoriya")}
          handleChange={categoriesHandler}
        />
        <Selector
          value={
            expenditureTypesOptions?.find(
              (item) => item.value == fixedCosts.expenditure
            ) || null
          }
          options={expenditureTypesOptions}
          property={t("Chiqim turi")}
          handleChange={expenditureTypeHandler}
          disabled={!expenditureTypes}
        />
      </Box>
      <Box sx={box_container1}>
        <TextArea />
        <Box sx={box_container2}>
          <PaymentSection />
          <Selector
            value={
              staffOptions?.find(
                (item) => item.value == fixedCosts.reasonponsible_staff
              ) || null
            }
            options={staffOptions}
            property={t("Biriktirilgan xodim(ixtiyoriy)")}
            handleChange={staffHandler}
          />
        </Box>
      </Box>
      <SaveButton
        callbackSubmit={() => confirmationCallback()}
        callbackCancel={() => clearFormData()}
        disabled={false}
        clearText={t("Tozalash")}
        saveText={t("Saqlash")}
      />
      <ConfirmationModal
        confirmationCallback={() => handleSubmit()}
        cancelCallback={() => cancelCallback()}
        showModal={confirmation}
        title={t("Chiqim amalga oshirilsinmi?")}
      />
    </div>
  );
}
export default React.memo(FixedCosts);
