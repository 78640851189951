import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";
import LessonTable from "./LessonTable";
import { useProfileConfigStore } from "../../../../Store/ProfileConfiguration/ProfileConfigStore";

const accardion = {
  width: "100%",
  margin: "10px 0",
};

export default function SimpleAccordion() {
  const { materials } = useProfileConfigStore();
  return (
    <Box sx={accardion}>
      {materials?.map((element) => {
        return (
          <Accordion key={element.id}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{element.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <LessonTable materials={element.materials} />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}
