import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useCallback, useContext } from "react";
import StudentAttachmentAction from "../../../Actions/StudentAttachment/StudentAttachmentAction";
import { LoaderContext } from "../../../Contexts/LoaderContext";
import { StudentAttachmentContext } from "../../../Contexts/StudentAttachmentContext/StudentAttachmentContext";
import { filterGroups, openFilterList } from "../actions";
import Selector from "./Select";
const container = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "15px",
  outline: "none",
  padding: "30px 60px",
};
const style = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
};
const borderElement = {
  margin: "10px 0",
  display: "flex",
  flexDirection: "column",
  gap: "10px 0",
};
const btnSecton = {
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
};

export default function FilteredModal({ data }) {
  const { studentAttachment, dispatchStudentAttachment } = useContext(
    StudentAttachmentContext
  );

  const { dispatchLoader } = useContext(LoaderContext);

  const handleSubmit = async () => {
    dispatchStudentAttachment({
      type: StudentAttachmentAction.CLOSE_FILTER_LIST,
    });
    await filterGroups(
      { ...studentAttachment?.queryParams, page: 1 },
      dispatchStudentAttachment,
      dispatchLoader
    );
  };

  return (
    <div>
      <Modal
        className="filterModal"
        onClick={(e) => e.stopPropagation()}
        open={studentAttachment.isOpenFilterList}
        onClose={() => openFilterList(dispatchStudentAttachment)}
        StudentsRegistrationContext
        aria-describedby="modal-modal-description"
      >
        <Box sx={container}>
          <Box sx={style}>
            <Box sx={borderElement}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={18}
              >
                Fanlar bo'yicha saralash
              </Typography>
              <Selector
                property="Fanlar"
                param={data?.subjects}
                value={data?.subjects?.find(
                  (item) => item.value == studentAttachment.queryParams.subject
                )}
              />
            </Box>
            <Box sx={borderElement}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={18}
              >
                Guruh turi bilan saralash
              </Typography>
              <Selector
                property="Guruh turlari"
                param={data?.groupTypes}
                value={data?.groupTypes?.find(
                  (item) =>
                    item.value == studentAttachment.queryParams.group_type
                )}
              />
            </Box>
            <Box sx={borderElement}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={18}
              >
                Dars kunlari bilan saralash
              </Typography>
              <Selector
                property="Dars kunlari"
                param={data?.days}
                value={data?.days?.find(
                  (item) =>
                    item.value == studentAttachment.queryParams.suitable_days
                )}
              />
            </Box>
            <Box sx={borderElement}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={18}
              >
                Dars vaqtlari bilan saralash
              </Typography>
              <Selector
                property="Dars vaqtlari"
                param={data?.times}
                value={data?.times?.find(
                  (item) =>
                    item.value == studentAttachment.queryParams.suitable_time
                )}
              />
            </Box>
            <Box sx={borderElement}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={18}
              >
                Binolar bilan saralash
              </Typography>
              <Selector
                property="Binolar"
                param={data?.branches}
                value={data?.branches?.find(
                  (item) =>
                    item.value == studentAttachment.queryParams.studying_place
                )}
              />
            </Box>
            <Box sx={borderElement}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={18}
              >
                Xodim bilan saralash
              </Typography>
              <Selector
                property="Xodimlar"
                param={data?.staffs}
                value={data?.staffs?.find(
                  (item) =>
                    item.value == studentAttachment.queryParams.wanted_teacher
                )}
              />
            </Box>
          </Box>
          <Box sx={btnSecton}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              endIcon={<FilterAltIcon />}
            >
              Filter
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
