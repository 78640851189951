import { useFormik } from "formik";
import * as Yup from "yup";
import React from "react";
import { useExamDetailsStore } from "../../../../Store/ManageGroupsStore/ExamDetailsStore";
import { useTranslation } from "react-i18next";

export default function PassingScores() {
  const { data } = useExamDetailsStore();

  const handleChange = (e, property) => {
    useExamDetailsStore.setState((state) => ({
      ...state,
      data: {
        ...state.data,
        [property]: e.target.value ? parseInt(e.target.value) : null,
      },
    }));
  };

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      passing1: "",
      passing2: "",
    },
    validationSchema: Yup.object({
      month: Yup.string().min(1, t("Qidiruvni kiriting!")),
    }),
  });

  return (
    <div className="passing-scores">
      <div className="passing-scores__item">
        <label htmlFor="passing-scores">{t("A'loga o'tish ball")}</label>
        <input
          type="number"
          id="passing1"
          name="passing1"
          defaultValue={data?.high_passing_score || ""}
          onChange={(e) => handleChange(e, "high_passing_score")}
        />
      </div>
      <div className="passing-scores__item">
        <label htmlFor="passing-scores">{t("O'rtaga o'tish ball")} </label>
        <input
          type="number"
          id="passing2"
          name="passing2"
          defaultValue={data?.medium_passing_score || ""}
          onChange={(e) => handleChange(e, "medium_passing_score")}
        />
      </div>
    </div>
  );
}
