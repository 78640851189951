import axiosInstance from "./AxiosInstance";
import { axiosInterceptor } from "./AxiosInterceptor";

export default class AuthRepository {
  static async login(username, password) {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInstance.post("/auth/token/", {
        username,
        password,
      });
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      result = {
        ...result,
        nonFieldErrors: e.response.data.data.error
          ? e.response.data.data.error
          : "Login yoki parol xato!",
      };
    }
    return result;
  }

  static async getMe() {
    let result = {
      status: false,
      data: null,
    };
    try {
      const response = await axiosInterceptor.get("/auth/me/");
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      result = { ...result, nonFieldErrors: "Foydalanuvchi mavjud emas!" };
    }
    return result;
  }
}
