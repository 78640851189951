import { Checkbox, FormControlLabel, FormGroup, Radio } from "@mui/material";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import ReactLoading from "react-loading";
import * as Yup from "yup";
import OptionLoader from "../../../../Components/Loader/basic loader/OptionLoader";
import { StudentsRegistrationContext } from "../../../../Contexts/RegistrationContex/StudentRegistrationContext";
import { toastError } from "../../../../Helpers/toasts";
import StudentsRepository from "../../../../Repositories/Registration/StudentsRepository";
import { includeStudent, loadItems } from "../actions";

const check_disount = {
  display: "flex",
  alignItems: "center",
  margin: "0",
};

function ReturnGroupModal({ cancelCallback, showModal }) {
  const { students, dispatchStudents } = useContext(
    StudentsRegistrationContext
  );
  const [loader, setLoader] = useState(false);
  const [selectLoader, setSelectLoader] = useState(false);
  const [checked, setChecked] = useState(false);
  const [excludedGroups, setExcludedGroups] = useState([]);
  const [inclutionReason, setInclutionReason] = useState([]);

  const getInclutionData = async () => {
    setSelectLoader(true);
    const [excludedGroups, inclutionReason] = await Promise.all([
      StudentsRepository.excludedGroups(showModal.returnGroupId),
      StudentsRepository.inclutionReason(),
    ]);
    setExcludedGroups(excludedGroups.data);
    setInclutionReason(inclutionReason.data);
    setSelectLoader(false);
  };

  useEffect(() => {
    setLoader(false);
    if (showModal.returnGroupId) {
      getInclutionData();
    } else {
      setExcludedGroups([]);
      setInclutionReason([]);
    }
  }, [showModal.returnGroupId]);

  let initialValues = {
    comment: "",
    reason: "",
    radio_value: "",
    group: "",
    discount_percent: 0,
    discount_expiry_date: "",
    discount_expiry_month: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: false,
    validationSchema: Yup.object({
      comment: Yup.string(),
      discount_percent: Yup.number(),
      reason: Yup.string(),
      discount_expiry_month: Yup.string(),
      discount_expiry_date: Yup.string(),
      group: Yup.string().required("Guruhni tanlang!"),
    }),
    onSubmit: async (values) => {
      if (
        formik.values.discount_percent > 0 &&
        !formik.values.discount_expiry_date &&
        !formik.values.discount_expiry_month
      ) {
        toastError("Chegirma amal qilish muddatini kiriting");
      }
      if (
        ((formik.values.discount_percent <= 0 || "") &&
          formik.values.discount_expiry_date) ||
        (formik.values.discount_expiry_month &&
          formik.values.discount_percent <= 0)
      ) {
        toastError("Chegirmani kiriting");
      }
      if (
        formik.values.discount_percent > 0 &&
        (formik.values.discount_expiry_date ||
          formik.values.discount_expiry_month)
      ) {
        const data = {
          student: showModal.returnGroupId,
          discount_expiry_month:
            formik.values.discount_expiry_month === ""
              ? null
              : formik.values.discount_expiry_month,
          discount_expiry_date:
            formik.values.discount_expiry_date === ""
              ? null
              : formik.values.discount_expiry_date,
        };
        setLoader(true);
        const status = await includeStudent({
          ...values,
          ...data,
        });
        if (status) {
          cancelHandler();
          loadItems({}, students, dispatchStudents);
        }
        setLoader(false);
      }
      if (
        (formik.values.discount_percent === 0 ||
          formik.values.discount_percent === "") &&
        !formik.values.discount_expiry_date &&
        !formik.values.discount_expiry_month
      ) {
        const data = {
          student: showModal.returnGroupId,
          discount_percent:
            formik.values.discount_percent === "" ||
            formik.values.discount_percent < 0
              ? 0
              : formik.values.discount_percent,
          discount_expiry_month:
            formik.values.discount_expiry_month === ""
              ? null
              : formik.values.discount_expiry_month,
          discount_expiry_date:
            formik.values.discount_expiry_date === ""
              ? null
              : formik.values.discount_expiry_date,
        };
        setLoader(true);
        const status = await includeStudent({
          ...values,
          ...data,
        });
        if (status) {
          cancelHandler();
          loadItems({}, students, dispatchStudents);
        }
        setLoader(false);
      }
    },
  });
  const cancelHandler = () => {
    formik.resetForm();
    cancelCallback();
    setChecked(false);
  };

  const handleCheckbox = (e) => {
    formik.setFieldValue("discount_expiry_date", "");
    formik.setFieldValue("discount_expiry_month", "");
    formik.setFieldValue("radio_value", "");
    setChecked(e.target.checked);
  };

  const radioHandler = (e) => {
    formik.setFieldValue("discount_expiry_month", "");
    formik.setFieldValue("discount_expiry_date", "");
    formik.setFieldValue("radio_value", e.target.value);
  };

  return (
    <div
      id="modal"
      className={
        showModal?.returnGroupId
          ? "modal modal-select active"
          : "modal modal-select"
      }
    >
      <div className="attendanceDiscountModal">
        <h1 className="discount-modal-header">O'quvchini guruhga qaytarish</h1>
        <div className="discountContainer discountContainer--box">
          <div className="modal-select__box remove_staff_modal relative">
            {selectLoader ? <OptionLoader /> : null}
            <label htmlFor="group">O'qigan guruhlari (oxirgi 1 oyda)</label>
            <select
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.group || ""}
              className={
                formik.touched.group && formik.errors.group
                  ? "modal-input error_input"
                  : "modal-input "
              }
              id="group"
              name="group"
            >
              <option
                className="course_disabled_option"
                disabled
                hidden
                value={""}
              ></option>
              {excludedGroups?.map((item, index) => {
                return (
                  <option value={item.id} key={index}>
                    {item.number} {item.teachers} {item.date}
                  </option>
                );
              })}
            </select>
            {formik.touched.group && formik.errors.group ? (
              <p className="input-error-message ">{formik.errors.group}</p>
            ) : null}
          </div>
          <div className="modal-select__box remove_staff_modal relative">
            {selectLoader ? <OptionLoader /> : null}
            <label htmlFor="reason">Guruhga qayta qo'shish sababi</label>
            <select
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.reason || ""}
              className={
                formik.touched.reason && formik.errors.reason
                  ? "modal-input error_input"
                  : "modal-input "
              }
              id="reason"
              name="reason"
            >
              <option
                className="course_disabled_option"
                disabled
                hidden
                value={""}
              ></option>
              {inclutionReason?.map((item) => {
                return (
                  <option value={item.value} key={item.value}>
                    {item.verbose_name}
                  </option>
                );
              })}
            </select>
            {formik.touched.reason && formik.errors.reason ? (
              <p className="input-error-message ">{formik.errors.reason}</p>
            ) : null}
          </div>
          <div className="modal-select__box remove_staff_modal">
            <label htmlFor="comment">Izoh</label>
            <textarea
              id="comment"
              className="commentRemoveStudent modal-input"
              name="comment"
              onChange={formik.handleChange}
              value={formik.values.comment}
            ></textarea>
          </div>
          <div style={check_disount}>
            <Checkbox
              sx={{ padding: "0", marginRight: "10px" }}
              id="check"
              checked={checked}
              onChange={(e) => handleCheckbox(e)}
            />
            <label style={{ cursor: "pointer" }} htmlFor="check">
              Chegirma kiritish
            </label>
          </div>
          <input
            className={`modal-input ${!checked ? "d-none" : ""}`}
            name="discount_percent"
            type="number"
            placeholder="Chegirma(%)"
            onChange={formik.handleChange}
            value={formik.values.discount_percent}
          />
          <div className={`student--dicount_place ${!checked ? "d-none" : ""}`}>
            <div
              className={`registir-discount__type ${
                formik.values.radio_value == "a" ? "checked-radio" : ""
              }`}
            >
              <Radio
                sx={{ padding: "0 8px" }}
                checked={formik.values.radio_value === "a"}
                onChange={(e) => radioHandler(e)}
                value="a"
                name="radio_value"
                inputProps={{ "aria-label": "A" }}
              />
              <div className="registir-discount__item">
                <div className="modal-select__box remove_staff_modal">
                  <label htmlFor="validity">Amal qilish muddati</label>
                  <input
                    className="modal-input"
                    disabled={formik.values.radio_value == "a" ? false : true}
                    id="validity"
                    name="discount_expiry_date"
                    onChange={formik.handleChange}
                    value={formik.values.discount_expiry_date}
                    type="date"
                  />
                </div>
              </div>
            </div>
            <div
              className={`registir-discount__type ${
                formik.values.radio_value == "b" ? "checked-radio" : ""
              }`}
            >
              <Radio
                sx={{ padding: "0 8px" }}
                checked={formik.values.radio_value === "b"}
                onChange={(e) => radioHandler(e)}
                value="b"
                name="radio_value"
                inputProps={{ "aria-label": "B" }}
              />
              <div className="registir-discount__item">
                <div className="modal-select__box remove_staff_modal">
                  <label htmlFor="month">Nechchi oy amal qiladi</label>
                  <input
                    className="modal-input"
                    id="month"
                    name="discount_expiry_month"
                    value={formik.values.discount_expiry_month}
                    onChange={formik.handleChange}
                    type="text"
                    disabled={formik.values.radio_value == "b" ? false : true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="discountBtns">
          <button
            type="submit"
            className="saveBtn"
            disabled={loader}
            onClick={formik.handleSubmit}
          >
            {loader ? (
              <ReactLoading
                type={"spin"}
                color="#ffffff"
                className="delete_loader"
                height={"20px"}
                width={"20px"}
              />
            ) : (
              "Guruhga qaytarish"
            )}
          </button>
          <button type="button" className="cancelBtn" onClick={cancelHandler}>
            Bekor qilish
          </button>
        </div>
      </div>
    </div>
  );
}
export default React.memo(ReturnGroupModal);
