import { axiosInterceptor } from "../AxiosInterceptor";

export default class ReadyReportsRepository {
  static async getResults(queryString = "") {
    let result = {
      status: false,
      data: null,
    };

    try {
      const response = await axiosInterceptor.get(
        `/reports/enrollments/?` + queryString
      );
      if (response.status === 200) {
        result = { ...result, status: true, data: response.data };
      }
    } catch (e) {
      if (e.response.data.data) {
        result = {
          ...result,
          nonFieldErrors: e.response.data.data,
        };
      } else result = { ...result, nonFieldErrors: "Ma'lumot olib bo'lmadi!" };
    }
    return result;
  }
}
