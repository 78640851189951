import React from "react";
import { useCallback } from "react";
import { useExamDetailsStore } from "../../../../Store/ManageGroupsStore/ExamDetailsStore";

function ListItem({ student }) {
  const { exam_types, setStudentsScore } = useExamDetailsStore();

  const handleChangeInput = useCallback(
    (e, id, code_name) => {
      if (e.target.value === "") {
        setStudentsScore(0, id, code_name);
      } else {
        setStudentsScore(e.target.value.replace(/^0+/, ""), id, code_name);
      }
    },
    [student]
  );

  return (
    <div className="desk-config desk-table exam_details" key={student.id}>
      <div className="desk-config__item">{student.ord}</div>
      <div className="desk-config__item desk-fname">{student.fullname}</div>
      <div className="desk-config__item">{student.student_id}</div>
      <div className="card_types">
        {exam_types?.map((element, index) => {
          return (
            <div
              key={index}
              className="desk-config__item  desk-yellow_third score_field"
            >
              <span
                className={
                  !student[
                    element.code_name == "overall_score"
                      ? "total_score"
                      : element.code_name
                  ] &&
                  student[
                    element.code_name == "overall_score"
                      ? "total_score"
                      : element.code_name
                  ] !== 0
                    ? "d-none"
                    : "studentScore"
                }
              >
                {
                  student[
                    element.code_name == "overall_score"
                      ? "total_score"
                      : element.code_name
                  ]
                }
              </span>
              <input
                disabled
                type="number"
                className="student_exam_score"
                value={
                  student[
                    element.code_name == "overall_score"
                      ? "total_score"
                      : element.code_name
                  ]
                    .toString()
                    .replace(/^0+/, "") || 0
                }
                onChange={(e) =>
                  handleChangeInput(
                    e,
                    student.id,
                    element.code_name == "overall_score"
                      ? "total_score"
                      : element.code_name
                  )
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default React.memo(ListItem);
