import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import React, { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import AuthenticationActions from "../../../../Actions/AuthActions/AuthenticationActions";
import { AuthenticationContext } from "../../../../Contexts/AuthContext/AuthenticationContext";
import { toastError } from "../../../../Helpers/toasts";
import AuthRepository from "../../../../Repositories/AuthRepository";

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const { dispatchAuth } = useContext(AuthenticationContext);

  const navigate = useNavigate();

  const login = useCallback(async (username, password) => {
    setLoading(true);
    const loginData = await AuthRepository.login(username, password);
    if (loginData.status === true) {
      localStorage.setItem("token", JSON.stringify(loginData.data));
    } else {
      toastError(loginData.nonFieldErrors);
      setLoading(false);
      return;
    }
    const profile = await AuthRepository.getMe();
    dispatchAuth({
      type: AuthenticationActions.LOGIN,
      payload: {
        user: { ...profile.data },
      },
    });

    localStorage.setItem("user", JSON.stringify(profile.data));
    navigate("/", { replace: true });

    setLoading(false);
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Login kiritilishi shart"),
      password: Yup.string().required("Parol kiritilishi shart"),
    }),
    onSubmit: async (values) => {
      await login(values.username, values.password);
    },
  });

  return (
    <form className="log-in__form" action="" onSubmit={formik.handleSubmit}>
      <div style={{ position: "relative" }} className="relative">
        <label className="login-label" htmlFor="login">
          Login
        </label>
        <input
          className={
            formik.touched.username && formik.errors.username
              ? "log-in__form-item error_input"
              : "log-in__form-item "
          }
          type="text"
          name="username"
          autoComplete="off"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.errors.username ? (
          <p className="input-error-message login-error-text ">
            {formik.errors.username}
          </p>
        ) : null}
      </div>
      <div style={{ position: "relative" }} className="relative">
        <label className="password-label" htmlFor="password">
          Parol
        </label>
        <div className="password-wrapper">
          <input
            className={
              formik.touched.password && formik.errors.password
                ? "log-in__form-item error_input"
                : "log-in__form-item "
            }
            type={showPassword === true ? "text" : "password"}
            name="password"
            autoComplete="off"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div
            className="password-icon"
            onClick={() => setShowPassword(!showPassword)}
          ></div>
        </div>
        {formik.errors.password ? (
          <p className="input-error-message login-error-text">
            {formik.errors.password}
          </p>
        ) : null}
      </div>
      <button
        className={loading ? "log-in__btn c-disabled" : "log-in__btn"}
        type="submit"
        name="submit"
      >
        {loading ? (
          <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
        ) : (
          "Kirish"
        )}
      </button>
      <a
        className="reset-password"
        href="https://admin.smartportal.uz/auth/reset-password/"
      >
        Parolni yangilash
      </a>
    </form>
  );
}
