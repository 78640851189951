import { useTranslation } from "react-i18next";
import BlockItem from "../../Components/BlockItem";
import UserPermissionWidget from "../../UserPermissionWidget";

function Reports({ viewModule }) {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <div className={`home-cards-box ${!viewModule ? "d-none" : ""}`}>
      <h1 className="cards__title">
        <img className="cards__img" src="/images/cards-6.png" alt="card box" />
        {t("reports_header")}
      </h1>
      <section className="section-page config">
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_recipient_reports}
        >
          <BlockItem
            link="/reports/all-reports"
            title={t("reception_reports")}
            image="/images/all-reports.avif"
          />
        </UserPermissionWidget>
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_discounted_recipients}
        >
          <BlockItem
            link="/reports/discount-earned-reports"
            title={t("discount_reports")}
            image="/images/income.jpg"
          />
        </UserPermissionWidget>
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_removed_from_group}
        >
          <BlockItem
            link="/reports/excluded-students"
            title={t("excluded_from_group")}
            image="/images/config-img-6.png"
          />
        </UserPermissionWidget>
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_missed_classes_students}
        >
          <BlockItem
            link="/reports/absent-students"
            title={t("missed_class")}
            image="/images/config-img-5.png"
          />
        </UserPermissionWidget>
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_active_inactive_students}
        >
          <BlockItem
            link="/reports/student-earned-cards"
            title={t("active_passive_readers")}
            image="/images/all-reports.avif"
          />
        </UserPermissionWidget>
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_students_failed_exams}
        >
          <BlockItem
            link="/reports/exam-failed-students"
            title={t("exam_failed_students")}
            image="/images/config-img-5.png"
          />
        </UserPermissionWidget>

        <UserPermissionWidget
          hasPermission={user?.permissions?.view_staff_statistics}
        >
          <BlockItem
            link="/reports/statistics-staffs"
            title={t("staff_statistics")}
            image="/images/chart.avif"
          />
        </UserPermissionWidget>
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_age_report_groups}
        >
          <BlockItem
            link="/reports/reports-with-agegroup"
            title={t("reports_with_agegroup")}
            image="/images/config-img-5.png"
          />
        </UserPermissionWidget>
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_completed_course_students}
        >
          <BlockItem
            link="/reports/finished-students"
            title={t("finished_students")}
            image="/images/finished-students.jpg"
          />
        </UserPermissionWidget>
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_monthly_income_reports}
        >
          <BlockItem
            link="/reports/monthly-finance"
            title={t("monthly_reports")}
            image="/images/income.jpg"
          />
        </UserPermissionWidget>
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_daily_income_reports}
        >
          <BlockItem
            link="/reports/daily-finance/"
            title={t("daily_reports")}
            image="/images/income.jpg"
          />
        </UserPermissionWidget>
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_weekly_income_reports}
        >
          <BlockItem
            link="/reports/weekly-finance/"
            title={t("weekly_reports")}
            image="/images/income.jpg"
          />
        </UserPermissionWidget>
        <UserPermissionWidget
          hasPermission={user?.permissions?.view_weekly_income_reports}
        >
          <BlockItem
            link="/reports/expenditure-finance/"
            title={t("expenditure_reports")}
            image="/images/income.jpg"
          />
        </UserPermissionWidget>
      </section>
    </div>
  );
}

export default Reports;
