import { useContext } from "react";
import { useCallback } from "react";
import Select from "react-select";
import { loadItems } from "../actions";
import EmployeeRegistrationAction from "../../../../Actions/RegistrationActions/EmployeeRegistrationAction";
import { LoaderContext } from "../../../../Contexts/LoaderContext";
import { EmployeeRegistrationContext } from "../../../../Contexts/RegistrationContex/EmployeeRegistrationContext";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: "9999",
  }),
  control: (provided) => ({
    ...provided,
    height: "37px",
    minHeight: "37px",
    width: "200px",
    fontSize: "13px",
    fontFamily: "Inter",
    fontWeight: "400",
    backgroundColor: "#f6f6f6",
    zIndex: "0",
  }),
};

export default function Selector({ property, options }) {
  const { employee, dispatchEmployee } = useContext(
    EmployeeRegistrationContext
  );
  const { dispatchLoader } = useContext(LoaderContext);

  const handleSelectChange = useCallback(async (e) => {
    if (property === "Lavozimlar") {
      dispatchEmployee({
        type: EmployeeRegistrationAction.ADD_QUERY_PARAM,
        payload: { roles: e?.value == undefined ? "" : e?.value, page: 1 },
      });
      await loadItems(
        { roles: e?.value == undefined ? "" : e?.value, page: 1 },
        employee,
        dispatchEmployee,
        dispatchLoader
      );
    }
    if (property === "Filiallar") {
      dispatchEmployee({
        type: EmployeeRegistrationAction.ADD_QUERY_PARAM,
        payload: { branch: e?.value == undefined ? "" : e?.value, page: 1 },
      });
      await loadItems(
        { branch: e?.value == undefined ? "" : e?.value, page: 1 },
        employee,
        dispatchEmployee,
        dispatchLoader
      );
    }
  });

  return (
    <div className="normal-select-bar">
      <Select
        className="basic-single manage_group_select"
        classNamePrefix="select"
        placeholder={property}
        styles={customStyles}
        options={options}
        isClearable
        onChange={handleSelectChange}
      />
    </div>
  );
}
